import {getStore} from "./index";

const defaultState = {
  account: null,
  employee: null,
  permissions: [],
  publicKey: null,
  ranks: [],
};

const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
const UPDATE_PUBLIC_KEY = "UPDATE_PUBLIC_KEY";

export function updateUser(payload) {
  return {type: UPDATE_USER, payload};
}

export function updatePublicKey(payload) {
  return {type: UPDATE_PUBLIC_KEY, payload};
}

export function updateUserAccount(payload) {
  return {type: UPDATE_USER_ACCOUNT, payload};
}

export function hasPermission(permissions) {
  const {permissions: userPermissions} = getStore().getState().user;

  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  permissions.unshift("ADMIN_FULL");

  return permissions.some((item) => {
    return userPermissions.includes(item);
  });
}

export const userReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_USER:
      return {...state, ...payload};
    case UPDATE_USER_ACCOUNT:
      return {...state, account: payload};
    case UPDATE_PUBLIC_KEY:
      return {...state, publicKey: payload};
  }
};
