import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class PayrollImportTable extends Component {
  render() {
    let {currentStepValue, stepsDict, hideHeader, startRow, maxRows} = this.props;
    let {exportData} = this.props.payroll.importData;

    exportData = exportData.slice(startRow, startRow + maxRows)

    return <div className="overflow-x-auto overflow-y-auto">
      <table
        style={{
          tableLayout: "fixed",
        }}
        className={"border-separate border-spacing-0 "}
      >
        <tbody>
        {exportData.map((row, rowIndex) => {
          return <tr className="border-separate">
            {Object.keys(row).map((key, colIndex) => {
              let isSelected = stepsDict[rowIndex + " " + colIndex];
              let currentSelection = currentStepValue?.row === rowIndex && currentStepValue?.col === colIndex;

              return (
                <td
                  className={classNames("hover:bg-gray-50 cursor-pointer text-sm border", isSelected && !currentSelection && "border-indigo-500", currentSelection && "border-black")}
                  onClick={() => {
                    let {handleClick} = this.props;

                    handleClick(rowIndex, colIndex);
                  }}
                >{row[key].substring(0, 35)}</td>
              )
            })
            }
          </tr>
        })}
        </tbody>
      </table>
    </div>
  }
}

export default setupReduxConnection(["payroll"])(
  PayrollImportTable
);
