import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class Dropdown extends Component {
  render() {
    const {label, sections = []} = this.props;

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            {label}

            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {sections.map((section) => {
              const {items, className} = section;

              return (
                <div className={classNames(className)}>
                  {items.map((item) => {
                    const {label, icon, onClick} = item;

                    return (
                      <Menu.Item>
                        {({active}) => (
                          <a
                            onClick={onClick}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            {icon && (
                              <FontAwesomeIcon
                                icon={icon}
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}

                            {label}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,

  sections: PropTypes.shape({
    className: PropTypes.string,

    items: PropTypes.arrayOf({
      onClick: PropTypes.func,
      label: PropTypes.string,
      icon: PropTypes.string,

      divider: PropTypes.bool,
    }).isRequired,
  }),
};

export default Dropdown;
