import React, {Component} from "react";
import {Menu} from "@headlessui/react";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PdfDropdown extends Component {
  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);
  }

  renderOptions() {
    const {data, onChange} = this.props;

    return (
      <div className="flex flex-wrap flex-col justify-items-start">
        {data.map((item) => {
          return (
            <Menu.Item>
              <div
                className={
                  "px-2 py-1 font-bold text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-600"
                }
                onClick={() => {
                  onChange && onChange(item);
                }}
              >
                {item.label}
              </div>
            </Menu.Item>
          );
        })}
      </div>
    );
  }

  renderButton(value, open) {
    return (
      <Menu.Button
        className={classNames(
          "flex flex-row hover:cursor-pointer bg-indigo-400 border-indigo-700 hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700",
          "focus:bg-opacity-50 focus:rounded-none hover:bg-opacity-50 placeholder:text-gray-700 text-sm",
          "justify-between align-items-top text-left align-text-top font-medium px-2 py-1 border-opacity-25 bg-opacity-25",
          " border-2 text-black text-opacity-100 transition-colors ease-in-out duration-200"
        )}
      >
        <div>
          {value?.label ?? "Select"}
          {"  "}
          <FontAwesomeIcon
            icon={open ? "square-chevron-down" : "square-chevron-right"}
            className={"pl-3"}
          />
        </div>
      </Menu.Button>
    );
  }

  render() {
    const {relative, value} = this.props;

    return (
      <div id={this.id}>
        <Manager>
          <Menu as="div" className="relative inline-block text-left">
            {({open}) => {
              return (
                <>
                  <Reference>
                    {({ref}) => (
                      <div className={"flex flex-row"} ref={ref}>
                        {this.renderButton(value, open)}
                      </div>
                    )}
                  </Reference>

                  {open &&
                    ReactDOM.createPortal(
                      <Popper
                        innerRef={(e) => (this.popperRef = e)}
                        placement={"bottom-start"}
                        strategy={"absolute"}
                      >
                        {({ref, style, placement}) => (
                          <Menu.Items
                            ref={ref}
                            data-placement={placement}
                            style={style}
                            unmount={false}
                            className={classNames(
                              relative ? "relative" : "absolute",
                              "rounded-md origin w-full top-right mt-1 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                            )}
                          >
                            {this.renderOptions()}
                          </Menu.Items>
                        )}
                      </Popper>,
                      document.querySelector("#" + this.id)
                    )}
                </>
              );
            }}
          </Menu>
        </Manager>
      </div>
    );
  }
}

export default PdfDropdown;
