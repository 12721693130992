import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";

class EmailSendTestModal extends Component {
  // TODO add replacement inputs here
  state = {loading: false};

  open() {
    this.setState({loading: false}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async send({email, cc, bcc}) {
    this.setState({loading: true});

    cc = cc?.split(",") ?? null;
    bcc = bcc?.split(",") ?? null;

    await this.props.sendEmail(email, cc, bcc);

    this.setState({loading: false});
    this.modal.close();
  }

  render() {
    const {label, showCC} = this.props;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={"Send"}
        formikOnClick={() => this.formikRef}
        label={label ?? "Send Test Email"}
      >
        <Formik
          onSubmit={this.send.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            email: null,
            cc: null,
            bcc: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .nullable()
              .required("Email is required")
              .matches(/^\S+@\S+[.]\S+$/, "Email incorrect format."),
            cc: Yup.string().nullable(),
            bcc: Yup.string().nullable(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="email" label="Email" options={formikOptions} />

                {showCC && (
                  <>
                    <FormInput
                      name="cc"
                      label="CC"
                      hint="Optional"
                      options={formikOptions}
                    />
                    <FormInput
                      name="bcc"
                      label="BCC"
                      hint="Optional"
                      options={formikOptions}
                    />
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmailSendTestModal;
