import React, {Component} from "react";
import {withRouter} from "../../../utils/navigation";

import {
  Tab,
  Card,
  Loading,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import CardAlert from "../../../features/card-alert";
import {request} from "../../../utils/request";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ContactsTable from "../../../tables/operations/invoices/contacts-table";
import ContactModal from "../../../modals/operations/invoices/contact-modal";
import InvoicesTable from "../../../tables/operations/invoices/invoices-table";

class InvoicingPage extends Component {
  state = {invoices: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let invoices = await request("invoices", "GET", null);
    let customers = await request("contacts", "GET", null);

    this.setState({invoices, contacts: customers});
  }

  render() {
    const {invoices, contacts} = this.state;

    if (invoices === null) {
      return <Loading />;
    }

    const lastThirty = invoices.filter((item) => {
      return item.TICKET.DATE_CREATED > moment().subtract(1, "month").valueOf();
    });

    const pending = lastThirty.filter((item) => {
      return (
        item.STATUS === "OPEN" && (!item.DATE_DUE || Date.now() < item.DATE_DUE)
      );
    });

    const due = lastThirty.filter((item) => {
      return (
        item.STATUS === "OPEN" && !!item.DATE_DUE && Date.now() > item.DATE_DUE
      );
    });

    const paid = lastThirty.filter((item) => item.STATUS === "PAID");

    return (
      <div>
        <ContactModal
          ref={(e) => (this.contactModal = e)}
          addState={(contact) => {
            this.props.router.navigate("/contact/" + contact.UNIQUE_ID);
          }}
        />

        <PageHeadings
          label="Invoice Dashboard"
          description="View and create invoices and contacts"
          buttons={[
            {
              theme: "primary",
              label: "Create",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "invoice") {
                  this.props.router.navigate("/invoice");
                }

                if (id === "contact") {
                  this.contactModal.open();
                }
              },
              items: [
                {
                  id: "invoice",
                  label: "Invoice",
                  description: "Create a new invoice to send to contacts.",
                },
                {
                  id: "contact",
                  label: "Contact",
                  description: "Create a new contact to send invoices to.",
                },
              ],
            },
          ]}
        />

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CardAlert
            subtext="last 30 days"
            link="/invoices?tab=outstanding"
            label="Outstanding Invoices"
            value={
              "$" +
              toDollars(
                pending.reduce((accum, item) => {
                  return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                }, 0)
              ) +
              ` (${pending.length})`
            }
          />

          <CardAlert
            subtext="last 30 days"
            link="/invoices?tab=due"
            label="Past Due"
            value={
              "$" +
              toDollars(
                due.reduce((accum, item) => {
                  return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                }, 0)
              ) +
              ` (${due.length})`
            }
          />

          <CardAlert
            link="/invoices?tab=paid"
            subtext="last 30 days"
            label="Paid"
            value={
              "$" +
              toDollars(
                paid.reduce((accum, item) => {
                  return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                }, 0)
              ) +
              ` (${paid.length})`
            }
          />
        </div>

        <Tab
          data={[
            {id: "contacts", label: "Contacts"},
            {id: "invoices", label: "Invoices"},
          ]}
        >
          {(tab) => {
            if (tab === "contacts") {
              return (
                <Card label="Contacts">
                  <ContactsTable invoices={invoices} contacts={contacts} />
                </Card>
              );
            }

            if (tab === "invoices") {
              return (
                <Card label="Invoices">
                  <InvoicesTable data={invoices} />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InvoicingPage);
