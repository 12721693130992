import React, {Component} from "react";
import ReactSlider from "react-slider";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  Button,
  Loading,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {GlobeAltIcon, ScaleIcon, CheckIcon} from "@heroicons/react/solid";
import {request} from "../../../utils/request";
import {
  faChartLineUp,
  faFolderXmark,
  faMessageDollar,
  faMoneyBillTransfer,
  faTruckField,
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const features = [
  {
    name: "No lengthy application",
    description:
      "There’s no personal credit check and no complicated application process. Eligibility is based on factors like your payment volume and history on Dripos Capital.",
    icon: faFolderXmark,
  },
  {
    name: "Get funds quickly",
    description:
      "Funds typically arrive in as soon as 2 business days, directly into your Stripe account.",
    icon: faTruckField,
  },
  {
    name: "One flat fee",
    description:
      "Pay one flat fee that never changes. There are no compounding interest charges or late fees.",
    icon: faMessageDollar,
  },
  {
    name: "Pay when you get paid",
    description:
      "Repayment is fully automated and adjusts to your daily sales. Stripe deducts a fixed percentage of your sales until the total owed is repaid.",
    icon: faMoneyBillTransfer,
  },
  {
    name: "Accelerate your growth",
    description:
      "Businesses that accepted a Capital offer saw an +114% increase in payment volume growth rate.",
    icon: faChartLineUp,
  },
];
const howItWorks = [
  {
    title: "Check your eligibility",
    description:
      "Check your email to see if you’re eligible for an offer based on factors that include your payment volume and history on Dripos.",
  },
  {
    title: "Select your offer",
    description:
      "Select the amount that’s right for your business needs. The loan fee and repayment rate are set based on the loan amount.",
  },
  {
    title: "Receive your funds",
    description:
      "After our team reviews your selection, the funds will be deposited into your Stripe account, typically as soon as 2 business days after approval.",
  },
  {
    title: "Pay down as you earn",
    description:
      "You’ll automatically repay the loan with a fixed percentage of your daily sales, until the total amount is repaid.",
  },
];

class CapitalsPage extends Component {
  state = {value: 1000, offers: null, summary: null};

  componentDidMount() {
    request("capital/offers", "GET").then((offers) => {
      request("capital/summary", "GET").then((summary) => {
        this.setState({summary});
      });

      this.setState({offers});
    });
  }

  render() {
    const {value, offers, summary} = this.state;

    if (offers === null) {
      return <Loading />;
    }

    const deliveredOffer = offers.find((item) => {
      return item.status === "delivered";
    });

    let refillOffer = offers.find((item) => {
      return item.status === "delivered" && item.product_type === "refill";
    });

    const acceptedOffer = offers.find((item) => {
      return item.status === "accepted";
    });

    const inProgressOffer = offers.find((item) => {
      return item.status === "paid_out";
    });

    if (inProgressOffer) {
      const {advance_amount, fee_amount, withhold_rate} =
        inProgressOffer.accepted_terms;

      return (
        <div>
          <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
            <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
              {refillOffer && (
                <div>
                  <div className="relative">
                    <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                      You’re eligible for a refill offer
                    </h2>
                    <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
                      Based on your business’s continued strong performance,
                      you’ve pre-qualified for a new loan offer.
                    </p>
                  </div>

                  <TwoColumnList
                    label="Offered Terms"
                    data={[
                      {
                        label: "Loan amount",
                        value:
                          "$" +
                          toDollars(refillOffer.offered_terms.advance_amount),
                      },
                      {
                        label: "Fixed fee",
                        value:
                          "$" + toDollars(refillOffer.offered_terms.fee_amount),
                      },
                      {
                        label: "Repayment rate",
                        value:
                          parseFloat(
                            refillOffer.offered_terms.withhold_rate * 100
                          ).toFixed(2) + "% per transaction",
                      },
                      {
                        label: "Total amount owed",
                        value:
                          "$" +
                          toDollars(
                            refillOffer.offered_terms.advance_amount +
                              refillOffer.offered_terms.fee_amount
                          ),
                      },
                      {
                        span: true,
                        custom: () => (
                          <Button
                            ref={(e) => (this.buttonRef = e)}
                            onClick={() => {
                              this.buttonRef.startLoading();

                              request("capital/offer", "POST", {
                                refresh_url: window.location.href,
                                return_url: window.location.href,
                              }).then((url) => {
                                this.buttonRef.stopLoading();

                                window.open(url, "_blank");
                              });
                            }}
                            flex
                            label="Continue to Offer"
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              )}

              <div className="relative mt-20">
                <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  You're enrolled with Dripos Capital
                </h2>
                <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
                  Use the link below to view the status on your current loan.
                </p>
              </div>

              <TwoColumnList
                label="Accepted Terms"
                data={[
                  {
                    label: "Amount Paid",
                    value: "$" + toDollars(summary?.details?.paid_amount),
                  },
                  {
                    label: "Remaining Amount",
                    value: "$" + toDollars(summary?.details?.remaining_amount),
                  },
                  {
                    label: "Loan amount",
                    value: "$" + toDollars(advance_amount),
                  },
                  {
                    label: "Fixed fee",
                    value: "$" + toDollars(fee_amount),
                  },
                  {
                    label: "Repayment rate",
                    value:
                      parseFloat(withhold_rate * 100).toFixed(2) +
                      "% per transaction",
                  },
                  {
                    label: "Total amount owed",
                    value: "$" + toDollars(advance_amount + fee_amount),
                  },
                  {
                    span: true,
                    custom: () => {
                      return (
                        <Button
                          ref={(e) => (this.buttonRef = e)}
                          onClick={() => {
                            this.buttonRef.startLoading();

                            request("capital/reporting", "POST", {
                              refresh_url: window.location.href,
                              return_url: window.location.href,
                            }).then((url) => {
                              this.buttonRef.stopLoading();

                              window.open(url, "_blank");
                            });
                          }}
                          flex
                          label="View Status"
                        />
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      );
    }

    if (acceptedOffer) {
      const {advance_amount, fee_amount, withhold_rate} =
        acceptedOffer.accepted_terms;

      return (
        <div>
          <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
            <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
              <div className="relative">
                <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  Your application is being reviewed
                </h2>
                <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
                  You’ll receive an email with our decision, normally within 3
                  business days. We may also email you for additional
                  information.
                </p>
              </div>

              <TwoColumnList
                label="Accepted Terms"
                data={[
                  {
                    label: "Loan amount",
                    value: "$" + toDollars(advance_amount),
                  },
                  {
                    label: "Fixed fee",
                    value: "$" + toDollars(fee_amount),
                  },
                  {
                    label: "Repayment rate",
                    value:
                      parseFloat(withhold_rate * 100).toFixed(2) +
                      "% per transaction",
                  },
                  {
                    label: "Total amount owed",
                    value: "$" + toDollars(advance_amount + fee_amount),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      );
    }

    if (deliveredOffer) {
      const {advance_amount, fee_amount, withhold_rate} =
        deliveredOffer.offered_terms;

      return (
        <div>
          <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
            <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
              <svg
                className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={784}
                  fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
                />
              </svg>

              <div className="relative">
                <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  You’re pre-qualified for a loan
                </h2>
                <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
                  Dripos Capital provides access to fast, flexible financing so
                  you can manage cash flow and invest in growth.
                </p>
              </div>

              <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-16">
                <div className="relative">
                  <dl className="mt-10 space-y-10">
                    {features.map((item) => (
                      <div key={item.id} className="relative">
                        <dt>
                          <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                            <FontAwesomeIcon icon={item.icon} className="h-6 w-6" />
                          </div>
                          <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                            {item.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          {item.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>

                <div className="relative mt-10 lg:mt-0" aria-hidden="true">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Benefits of Dripos Capital
                  </h3>
                  <p className="mt-3 text-lg text-gray-500">
                    Here are some benefits of using Dripos Capital
                  </p>

                  <TwoColumnList
                    className="mt-10"
                    label="Offered Terms"
                    data={[
                      {
                        label: "Loan amount",
                        value: "$" + toDollars(advance_amount),
                      },
                      {
                        label: "Fixed fee",
                        value: "$" + toDollars(fee_amount),
                      },
                      {
                        label: "Repayment rate",
                        value:
                          parseFloat(withhold_rate * 100).toFixed(2) +
                          "% per transaction",
                      },
                      {
                        label: "Total amount owed",
                        value: "$" + toDollars(advance_amount + fee_amount),
                      },
                      {
                        span: true,
                        custom: () => {
                          return (
                            <Button
                              ref={(e) => (this.buttonRef = e)}
                              onClick={() => {
                                this.buttonRef.startLoading();

                                request("capital/offer", "POST", {
                                  refresh_url: window.location.href,
                                  return_url: window.location.href,
                                }).then((url) => {
                                  this.buttonRef.stopLoading();

                                  window.open(url, "_blank");
                                });
                              }}
                              flex
                              label="Continue to Offer"
                            />
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="sm:text-center mt-20">
                <h2 className="text-lg font-semibold leading-8 text-indigo-600">
                  Dripos Capital
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  How it works.
                </p>
              </div>

              <div className="mt-10 grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                {howItWorks.map((item, index) => (
                  <div className="relative">
                    <dt>
                      <p className="ml-10 text-lg font-semibold leading-8 text-gray-900">
                        {index + 1}. {item.title}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-10 text-base leading-7 text-gray-600">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </div>

              <div className="mt-20 ml-10 text-base leading-7 text-gray-600">
                Eligibility is based on a combination of factors, such as your
                payment history on Dripos. If you’re eligible for an offer,
                we’ll send you an email. If you haven’t received any
                notification from us, that means you’re not eligible for an
                offer at this time.
              </div>

              <div className="mt-10 ml-10 text-base leading-7 text-gray-600">
                If you’d like to receive a financing offer, but aren’t eligible
                at this time, we recommend migrating more of your business’
                payments to Dripos. While not guaranteed, increased payment
                volume and your history with Dripos might increase your
                likelihood of getting a financing offer.
              </div>

              <div className="mt-10 ml-10 text-base leading-7 text-gray-600">
                Loans are issued by Celtic Bank. All loans subject to credit
                approval.
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (offers.length > 1000) {
      return (
        <Table
          pagination
          data={offers}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View Offer",
              onClick: (row) => {
                this.props.router.navigate("/offer/" + row.id);
              },
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Loan Amount",
            },
            {
              value: "RATE",
              label: "Rate",
            },
            {
              value: "IS_DEFAULT",
              label: "Is Default",
              type: "bool",
            },
          ]}
        />
      );
    }

    return (
      <div className="bg-white py-24 sm:py-32 lg:py-40">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="sm:text-center">
            <h2 className="text-lg font-semibold leading-8 text-indigo-600">
              Dripos Capital
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Fast, flexible financing so you invest in growth.
            </p>
            <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
              Access to capital can be one of the most limiting growth factors.
              With Dripos Capital, you can help jump start your business with
              flexible, on-demand financing. No lengthy application or personal
              credit check required—eligible businesses are automatically
              pre-qualified and can receive funds in as soon as 2 business days
              once approved. Get the financing you need today with Capital.
            </p>

            <Button
              className="mt-4"
              label="Check here for loan eligibility"
              ref={(e) => (this.buttonRef = e)}
              onClick={() => {
                this.buttonRef.startLoading();

                request("capital/offer", "POST", {
                  refresh_url: window.location.href,
                  return_url: window.location.href,
                }).then((url) => {
                  this.buttonRef.stopLoading();

                  window.open(url, "_blank");
                });
              }}
            />
          </div>

          <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
            <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row"
                >
                  <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0">
                    <FontAwesomeIcon
                      icon={feature.icon}
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="sm:min-w-0 sm:flex-1">
                    <p className="text-lg font-semibold leading-8 text-gray-900">
                      {feature.name}
                    </p>
                    <p className="mt-2 text-base leading-7 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sm:text-center mt-20">
            <h2 className="text-lg font-semibold leading-8 text-indigo-600">
              Dripos Capital
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              How it works.
            </p>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            {howItWorks.map((item, index) => (
              <div className="relative">
                <dt>
                  <p className="ml-10 text-lg font-semibold leading-8 text-gray-900">
                    {index + 1}. {item.title}
                  </p>
                </dt>
                <dd className="mt-2 ml-10 text-base leading-7 text-gray-600">
                  {item.description}
                </dd>
              </div>
            ))}
          </div>

          <div className="mt-20 ml-10 text-base leading-7 text-gray-600">
            Eligibility is based on a combination of factors, such as your
            payment history on Dripos. If you’re eligible for an offer, we’ll
            send you an email. If you haven’t received any notification from us,
            that means you’re not eligible for an offer at this time.
          </div>

          <div className="mt-10 ml-10 text-base leading-7 text-gray-600">
            If you’d like to receive a financing offer, but aren’t eligible at
            this time, we recommend migrating more of your business’ payments to
            Dripos. While not guaranteed, increased payment volume and your
            history with Dripos might increase your likelihood of getting a
            financing offer.
          </div>

          <div className="mt-10 ml-10 text-base leading-7 text-gray-600">
            Loans are issued by Celtic Bank. All loans subject to credit
            approval.
          </div>
        </div>
      </div>
    );
  }
}

export default CapitalsPage;
