import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ProductInventoryModal from "../../../modals/operations/inventory/product-inventory-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import DangerBadge from "../../../components/badges/danger-badge";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import InvoicesTable from "../../../tables/operations/invoices/invoices-table";

class InvoicesPage extends Component {
  state = {invoices: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let invoices = await request("invoices", "GET", null);

    invoices.reverse();

    this.setState({invoices});
  }

  render() {
    const {invoices} = this.state;

    if (invoices === null) {
      return <Loading />;
    }

    const pending = invoices.filter((item) => item.STATUS === "OPEN");
    const due = invoices.filter(
      (item) =>
        item.STATUS === "OPEN" && !!item.DATE_DUE && Date.now() > item.DATE_DUE
    );
    const draft = invoices.filter((item) => item.STATUS === "DRAFT");
    const paid = invoices.filter((item) => item.STATUS === "PAID");

    return (
      <div>
        <PageHeadings
          label="Invoices"
          description="Track and view your invoices"
          button={{
            label: "Create Invoice",
            onClick: () => {
              this.props.router.navigate("/invoice");
            },
          }}
        />

        <Tab
          data={[
            {label: "All Invoices (" + invoices.length + ")", id: "all"},
            {label: "Draft (" + draft.length + ")", id: "draft"},
            {label: "Outstanding (" + pending.length + ")", id: "outstanding"},
            {label: "Past Due (" + due.length + ")", id: "due"},
            {label: "Paid (" + paid.length + ")", id: "paid"},
          ]}
        >
          {(tab) => {
            let data = invoices;
            if (tab === "draft") {
              data = draft;
            } else if (tab === "due") {
              data = due;
            } else if (tab === "draft") {
              data = draft;
            } else if (tab === "paid") {
              data = paid;
            } else if (tab === "outstanding") {
              data = pending;
            }

            return <InvoicesTable searchParams className="mt-4" data={data} />;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InvoicesPage);
