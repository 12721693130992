import React, {Component} from "react";
import {
  CELL_TYPES,
  PAYMENT_METHODS_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {setupReduxConnection} from "../../redux";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {AUDIT_PAYMENT_METHODS_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";

class PaymentMethodsReportPage extends Component {
  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      let customTypeRows = Object.keys(reportData.CUSTOM_TYPE_BREAKDOWN).map(
        (key) => ({
          label: reportData.CUSTOM_TYPE_BREAKDOWN[key].PAYMENT_TYPE,
          numbers: reportData.CUSTOM_TYPE_BREAKDOWN[key],
          style: [CELL_TYPES.SECONDARY],
        })
      );

      let customTypeTotalNumbers = Object.keys(
        reportData.CUSTOM_TYPE_BREAKDOWN
      ).reduce(
        (obj, key) => {
          obj.NET_PAYMENT += reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_PAYMENT;
          obj.NET_TOTAL += reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_TOTAL;
          obj.PAYMENT_COUNT +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_PAYMENT;
          obj.PROCESSING_FEES +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].PROCESSING_FEES;
          obj.REFUND_AMOUNT +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].REFUND_AMOUNT;
          obj.TOTAL_COLLECTED +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].TOTAL_COLLECTED;

          return obj;
        },
        {
          NET_PAYMENT: 0,
          NET_TOTAL: 0,
          PAYMENT_COUNT: 0,
          PROCESSING_FEES: 0,
          REFUND_AMOUNT: 0,
          TOTAL_COLLECTED: 0,
        }
      );

      let customTypeTotalRow = {
        label: "Custom Types",
        numbers: customTypeTotalNumbers,
      };

      rows = [
        {
          label: "Cash",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CASH,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Check",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CHECK,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CARDS,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Card Present",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CARD_PRESENT,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Card Not Present",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CARD_NOT_PRESENT,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Payout Fees",
          numbers: reportData.PAYMENT_METHOD_RECORDS.BANK_PAYOUT,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Loan Paydown",
          numbers: reportData.PAYMENT_METHOD_RECORDS.PAYDOWN,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Gift Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.GIFT_CARDS,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Digital Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.GIFT_CARD,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Physical Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.E_GIFT_CARD,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Loyalty Rewards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.REWARD,
          style: [CELL_TYPES.REGULAR],
        },
        customTypeTotalRow,
        ...customTypeRows,
        {
          label: "Net Accounts Receivable",
          numbers: reportData.PAYMENT_METHOD_RECORDS.NET_AR,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Billing Accounts",
          numbers: reportData.PAYMENT_METHOD_RECORDS.BILLING,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "Invoicing",
          numbers: reportData.PAYMENT_METHOD_RECORDS.INVOICE,
          style: [CELL_TYPES.SECONDARY],
        },
        {
          label: "All Payments",
          numbers: reportData.TOTALS,
          style: [CELL_TYPES.BOLD],
        },
      ];

      if (!reportData.PAYMENT_METHOD_RECORDS.CHECK?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Check");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS.BANK_PAYOUT.PROCESSING_FEES) {
        rows = rows.filter((row) => row.label !== "Payout Fees");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS?.NET_AR?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Net Accounts Receivable");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS?.BILLING?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Billing Accounts");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS?.INVOICE?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Invoicing");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS?.PAYDOWN?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Loan Paydown");
      }
    }

    return rows;
  };

  render() {
    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    let columns = PAYMENT_METHODS_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      columns = AUDIT_PAYMENT_METHODS_COLUMNS;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Payment Methods Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="payment-methods"
          endpoint="report/paymentmethods"
          constantColumns={columns}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          enableStripePayouts={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(
  PaymentMethodsReportPage
);
