import React, {Component} from "react";
import FormElement from "./../form/form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {ChevronRightIcon} from "@heroicons/react/solid";

export const TOKEN_YUP = Yup.string().matches(
  /\d{3}\s\d{3}/,
  "Please enter a valid token"
);

class FormToken extends Component {
  render() {
    const {name, placeholder} = this.props;

    return (
      <FormElement
        {...this.props}
        leadingIcon={
          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        }
      >
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <InputMask
              mask="999 999"
              maskChar={null}
              type="text"
              id={name}
              name={name}
              value={value}
              onBlur={onBlur}
              autoComplete="one-time-code"
              placeholder="123 456"
              onChange={(e) => {
                onChange(e);

                setTimeout(() => {
                  onChangeSoft && onChangeSoft(e.target.value);
                }, 1);
              }}
              className={classNames(
                error
                  ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                "block w-full pr-10 focus:outline-none sm:text-sm rounded-md",
                extraStyle
              )}
              aria-describedby="email-error"
              aria-invalid="true"
            />
          );
        }}
      </FormElement>
    );
  }
}

FormToken.propTypes = {
  ...FormElement.propTypes,
};

export default FormToken;
