import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

class ReaderPlansPage extends Component {
  state = {plans: null};

  componentDidMount() {
    request("readers/deployments/plans", "GET").then((plans) => {
      this.setState({plans});
    });
  }

  render() {
    const {plans} = this.state;

    if (plans === null) {
      return <Loading />;
    }

    return (
      <>
        <PageHeadings
          label="Groups"
          description="Create and manage companies"
        />

        <Table
          pagination
          data={plans}
          className="mt-4"
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/admin/company/" + row.ID);
              },
            },
          ]}
          columns={[
            {
              width: 1,
              value: "id",
              label: "ID",
            },
            {
              width: 1,
              value: "device_deploy_group",
              label: "Deployment Group",
            },
            {
              width: 1,
              value: "device_asset_versions_to_install",
              label: "Asset Versions",
              format: (arr) => arr.join(", "),
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(ReaderPlansPage);
