import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import LoyaltyTab from "../../../features/marketing/loyalty-tab";

class RulesPage extends Component {
  render() {
    return (
      <>
        <PageHeadings
          label="Rules"
          description="Advanced Loyalty Rules for earning and redeeming points"
        />

        <LoyaltyTab {...this.props} />
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(RulesPage);
