class TableData {
  data = [];
  count = 0;
  cursor = null;
  hasMore = false;
  hasPrevious = false;
  filters = [];
  sort = null;
  limit = 10;

  fetchInitial() {}

  fetchNext() {}

  fetchPrevious() {}
}

export default TableData;
