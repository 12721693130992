import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import ChecklistModal from "../../../modals/operations/checklists/checklist-modal";
import ChecklistTaskModal from "../../../modals/operations/checklists/checklist-task-modal";
import {updateStateDelegator} from "../../../utils/util";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import ChecklistRuleModal from "../../../modals/operations/checklists/checklist-rule-modal";

function fetchChecklistType(item) {
  if (item.ISO_WEEK !== null) {
    return (
      "Every " +
      moment().startOf("isoWeek").add(item.ISO_WEEK, "days").format("dddd")
    );
  }

  if (item.ISO_BI_WEEK !== null) {
    return (
      "Every other " +
      moment().startOf("isoWeek").add(item.ISO_BI_WEEK, "days").format("dddd")
    );
  }

  if (item.ISO_MONTH !== null) {
    return (
      "Every month on the " +
      moment().startOf("month").add(item.ISO_MONTH, "days").format("Do")
    );
  }

  if (item.ISO_DATE !== null) {
    return "Once on " + moment(item.ISO_DATE).format("ddd MMM Do");
  }

  return "Daily";
}

class ChecklistPage extends Component {
  state = {checklist: null, tasks: null, rules: null, instances: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let checklist = await request("checklists/" + id, "GET", null);

    request("checklists/" + id + "/items", "GET").then((tasks) => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);

      this.setState({tasks});
    });

    request("checklists/" + id + "/rules", "GET").then((rules) => {
      this.setState({rules});
    });

    request("checklists/" + id + "/instances", "GET").then((instances) => {
      this.setState({instances});
    });

    this.setState({checklist});
  }

  createInstance() {
    let {checklist, tasks} = this.state;

    showLoadingConfirmAlert(
      "Create Checklist Instance",
      "Do you want to create a checklist instance right now?"
    ).then(async (close) => {
      await request("checklists/" + checklist.ID + "/instances", "POST", {});

      close();

      showSuccessNotification(
        "Checklist Created",
        "You can view it on the point of sale"
      );
    });
  }

  async archiveChecklist() {
    let {checklist, tasks} = this.state;

    showLoadingConfirmAlert(
      "Delete Checklist",
      "Are you sure you want to delete this checklist?"
    ).then(async (close) => {
      await request("checklists/" + checklist.ID, "DELETE", {});

      this.props.router.navigate("/checklists");

      close();

      showSuccessNotification(
        "Checklist Deleted",
        "We have deleted the checklist"
      );
    });
  }

  saveSequence() {
    const {tasks, checklist} = this.state;

    request("checklists/" + checklist.ID + "/item", "PATCH", {
      ITEMS: tasks.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  render() {
    const {checklist, tasks, rules, instances} = this.state;

    if (checklist === null) {
      return <Loading />;
    }

    tasks?.sort((a, b) => a.SEQ - b.SEQ);

    return (
      <div>
        <PageHeadings
          label={checklist.NAME}
          buttons={[
            {
              label: "Create Instance",
              onClick: () => this.createInstance(),
            },
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Checklist",
                      onClick: () => this.archiveChecklist(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Checklists Page", url: "/checklists"},
          ]}
        />

        <ChecklistModal
          updateState={(checklist) => this.setState({checklist})}
          ref={(e) => (this.checklistModal = e)}
        />

        <ChecklistTaskModal
          checklist={checklist}
          ref={(e) => (this.taskModal = e)}
          addState={(task) => this.setState({tasks: [...tasks, task]})}
          updateState={(id, line) => {
            this.setState({
              tasks: updateStateDelegator(tasks, id, line),
            });
          }}
        />

        <ChecklistRuleModal
          checklist={checklist}
          ref={(e) => (this.ruleModal = e)}
          addState={(rule) => this.setState({rules: [...rules, rule]})}
          updateState={(id, rule) => {
            this.setState({
              rules: updateStateDelegator(rules, id, rule),
            });
          }}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.checklistModal.open(checklist);
              },
            },
          ]}
          label="Checklist Information"
          description="Modify the details of this checklist"
          data={[
            {label: "Name", value: checklist.NAME},
            {label: "Active", value: checklist.ENABLED ? "yes" : "no"},
            {
              type: "bool",
              label: "Require Employee Pin",
              value: checklist.REQUIRE_PIN,
            },
            {label: "Duration (minutes)", value: checklist.DURATION},
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Tasks", id: "tasks"},
            {label: "Rules", id: "rules"},
            {label: "Instances", id: "instances"},
          ]}
        >
          {(id) => {
            if (id === "instances") {
              return (
                <Card
                  label="Checklist Instances"
                  description="View the individual instances of this checklist that have been created"
                >
                  <Table
                    data={instances}
                    columns={[
                      {
                        value: "DATE_START_BY",
                        label: "Checklist Day",
                        format: (value) =>
                          moment(value).format("ddd MMM Do YYYY"),
                      },
                      {
                        value: "DATE_START_BY",
                        label: "Start By",
                        format: (value) => moment(value).format("h:mm a"),
                      },
                      {
                        value: "DATE_START",
                        label: "Date Started",
                        format: (value) =>
                          value
                            ? moment(value).format("h:mm a")
                            : "Not started",
                      },
                      {
                        value: "DATE_END_BY",
                        label: "Complete By",
                        format: (value) =>
                          value
                            ? moment(value).format("h:mm a")
                            : "No target end",
                      },
                      {
                        value: "DATE_END",
                        label: "Date Completed",
                        format: (value) =>
                          value
                            ? moment(value).format("h:mm a")
                            : "Not completed",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "rules") {
              return (
                <Card
                  label="Checklist Rules"
                  description="Edit the frequency at which this checklist is created"
                  button={{
                    label: "Add Rule",
                    onClick: () => this.ruleModal.open(null),
                  }}
                >
                  <Table
                    data={rules}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.ruleModal.open(row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (_, item) =>
                          moment(item.RESET_TIME, "HH:mm").format("hh:mm a"),
                      },
                      {
                        value: "TYPE",
                        label: "Type",
                        format: (_, row) => fetchChecklistType(row),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "tasks") {
              const seq = tasks
                ? Math.max(...tasks.map((item) => item.SEQ), 0) + 1
                : 1;

              return (
                <Card
                  label="Checklist Tasks"
                  description="Edit individual task items for this checklist"
                  button={{
                    label: "Add Task",
                    onClick: () => this.taskModal.open(null, seq),
                  }}
                >
                  <Table
                    draggable
                    data={tasks}
                    onDrag={() => this.saveSequence()}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.taskModal.open(row, seq),
                      },
                    ]}
                    columns={[
                      {
                        width: 1,
                        value: "NAME",
                        label: "Name",
                      },
                      {
                        width: 1,
                        value: "TYPE",
                        label: "Type",
                      },
                      {
                        value: "OPTIONS",
                        label: "Options",
                        format: (options, _) => {
                          if (!options) {
                            return "None";
                          }

                          return options.map((item) => item.NAME).join(", ");
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }

            return <div>Yo</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(ChecklistPage);
