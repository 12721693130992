import React, {Component} from "react";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  FormInput,
  FormSelect,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {MODIFIER_TYPE_FIELDS} from "../../utils/product-constants";
import ModifierOptions from "../sales/modifier-options";
import PropTypes from "prop-types";
import Banner from "../../components/banner";
import {showLoadingConfirmAlert} from "../../utils/alert-helper";
import {showSuccessNotification} from "../../utils/notification-helper";
import FormRow from "../../components/form-row";

class Div extends Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

class Modifier extends Component {
  state = {
    tableInvalid: false,
    rawModifier: null,
    modifier: null,
    product: null,
    invalid: true,
    create: false,
  };

  componentDidMount() {
    const {modal, modifier = null, product = null} = this.props;

    if (modal) return;

    this.setupModifier(modifier, product);
  }

  open(modifier = null, product = null, create = false) {
    this.setState({create}, () => {
      this.setupModifier(modifier, product);
    });
  }

  setupModifier(modifier, product) {
    const modifierClone = JSON.parse(JSON.stringify(modifier));
    const {modal} = this.props;

    const modifierDefaults = {};
    if (product?.DEFAULTS) {
      for (let modDefault of product?.DEFAULTS) {
        modifierDefaults[modDefault.OPTION_ID] = modDefault;
      }
    }

    for (let option of modifierClone.OPTIONS) {
      option.DEFAULT = modifierDefaults[option.ID] ? "1" : "0";
      option.DEFAULT_OBJECT = modifierDefaults[option.ID];
      option.PRICE = toDollars(option.PRICE);
    }

    this.setState({product, modifier: modifierClone}, () => {
      this.formikRef.resetForm();

      if (modifier.ID === null) {
        this.formikRef.setTouched({});
      }

      if (modal) {
        this.slide && this.slide.open();
      }
    });
  }

  checkValid() {
    const {tableInvalid} = this.state;

    if (tableInvalid || !this.formikRef.isValid) {
      return this.setState({tableInvalid: true});
    }

    this.setState({tableInvalid: false});
  }

  async outsideValidate() {
    const val = await this.formikRef.validateForm();
    const {tableInvalid} = this.state;

    if (Object.keys(val).length === 0 && !tableInvalid) {
      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  outsideSave() {
    this.formikRef.submitForm();
  }

  outsideFetch() {
    const {modifier, product} = this.state;
    let {
      name,
      type,
      max,
      min,
      tag,
      enabled,
      required,
      defaultOption: defaultOptions,
    } = this.formikRef.values;

    const modifierClone = JSON.parse(JSON.stringify(modifier));

    if (isNaN(defaultOptions) && !("" + defaultOptions).startsWith("tid")) {
      defaultOptions = null;
    }

    const defaultObj = modifierClone.OPTIONS.find((item) => {
      return (item.ID || item.tid) === defaultOptions;
    });

    if (!defaultObj) {
      defaultOptions = null;
    }

    modifierClone.OPTIONS.forEach((item) => {
      item.PRICE = decimalToDollars("" + item.PRICE);
    });

    let seq = 0;
    if (modifierClone.ID === null) {
      if (product) {
        const customizations = product.CUSTOMIZATIONS.map(
          (item) => item.SEQ || 0
        );

        seq =
          customizations && customizations.length > 0
            ? Math.max(...customizations) + 1
            : 0;
      }
    }

    return {
      ...modifierClone,
      NAME: name,
      TYPE: type,
      MAX_SELECTIONS: parseInt(max),
      MIN_SELECTIONS: parseInt(min),
      DEFAULT_OPTION: defaultOptions,
      INTERNAL_NAME: tag,
      ENABLED: enabled,
      REQUIRED: required,
      PRODUCT_ID: product?.ID,
      SEQ: seq,
    };
  }

  async saveModifier({
    name,
    type,
    min,
    max,
    tag,
    enabled,
    required,
    price,
    defaultOption: defaultOptions,
  }) {
    const {updateState, preset} = this.props;
    const {modifier, product} = this.state;

    const modifierClone = JSON.parse(JSON.stringify(modifier));

    if (isNaN(defaultOptions) && !("" + defaultOptions).startsWith("tid")) {
      defaultOptions = null;
    }

    const defaultObj = modifierClone.OPTIONS.find((item) => {
      return (item.ID || item.tid) === defaultOptions;
    });

    if (!defaultObj) {
      defaultOptions = null;
    }

    modifierClone.OPTIONS.forEach((item) => {
      item.PRICE = decimalToDollars("" + item.PRICE);
    });

    if (parseInt("" + type) === 4 && price !== null) {
      modifierClone.OPTIONS.forEach((option) => {
        option.PRICE = decimalToDollars("" + price);
      });
    }

    if (modifierClone.ID === null) {
      let seq = 0;
      if (product) {
        const customizations = product.CUSTOMIZATIONS.map(
          (item) => item.SEQ || 0
        );

        seq =
          customizations && customizations.length > 0
            ? Math.max(...customizations) + 1
            : 0;
      }

      let modifier = await request("modifiers", "POST", {
        ...modifierClone,
        NAME: name,
        TYPE: type,
        MAX_SELECTIONS: parseInt(max),
        MIN_SELECTIONS: parseInt(min),
        DEFAULT_OPTION: defaultOptions,
        INTERNAL_NAME: tag,
        ENABLED: enabled,
        REQUIRED: required,
        PRODUCT_ID: product?.ID,
        SEQ: seq,
      });

      this.setState({modifier}, () => {
        this.props.addState(modifier);
      });

      return this.slide && this.slide.close && this.slide.close();
    }

    let serverModifier = await request(
      "modifiers/" + modifierClone.ID,
      "PATCH",
      {
        ...modifierClone,
        NAME: name,
        TYPE: type,
        MAX_SELECTIONS: parseInt(max),
        MIN_SELECTIONS: parseInt(min),
        DEFAULT_OPTION: defaultOptions,
        INTERNAL_NAME: tag,
        ENABLED: enabled,
        REQUIRED: required,
      }
    );

    if (modifierClone.PRESET_ID) {
      serverModifier.PRESET_ID = modifierClone.PRESET_ID;
    }

    updateState && updateState(serverModifier.ID, serverModifier);

    this.slide && this.slide.close && this.slide.close();
  }

  async removeModifier() {
    const {product} = this.props;
    const {modifier} = this.state;

    let deleteLabel = "Delete Modifier";
    let deleteDescription = "Are you sure you want to delete this modifier?";

    if (modifier.PRESET_ID) {
      deleteLabel = "Remove Pre-Made Modifier";
      deleteDescription =
        "Are you sure you want to remove this pre-made modifier from this product?";
    } else if (modifier.PRESET) {
      deleteLabel = "Delete Pre-Made Modifier";
      deleteDescription =
        "Are you sure you want to delete this pre-made modifier? This will delete it from every product it's attached to";
    }

    showLoadingConfirmAlert(deleteLabel, deleteDescription).then(
      async (close) => {
        if (modifier.PRESET_ID) {
          await request(
            `preset/${modifier.ID}/${product.ID}/remove`,
            "POST",
            {}
          );
        } else if (modifier.PRESET) {
          await request(
            "customization/" + modifier.ID + "/preset",
            "DELETE",
            {}
          );
        } else {
          await request("customization/" + modifier.ID, "DELETE", {});
        }

        close();
        this.slide && this.slide.close && this.slide.close();
        this.props.updateState && this.props.updateState(modifier.ID);

        showSuccessNotification(
          "Modifier Deleted",
          "The modifier has been deleted"
        );
      }
    );
  }

  render() {
    const {modifier, product, tableInvalid} = this.state;
    const {modal, variant, preset} = this.props;

    let ContainerComponent = Div;

    if (modal) {
      ContainerComponent = Modal;
    }

    return (
      <Formik
        enableReinitialize
        onSubmit={this.saveModifier.bind(this)}
        innerRef={(e) => (this.formikRef = e)}
        validationSchema={Yup.object({
          name: Yup.string().required("Modifier name is required"),
        })}
        initialValues={{
          name: modifier?.NAME,
          type: modifier?.TYPE,
          defaultOption: modifier?.DEFAULT_OPTION,
          tag: modifier?.INTERNAL_NAME,
          enabled: modifier?.ENABLED,
          required: modifier?.REQUIRED,
          min: modifier?.MIN_SELECTIONS,
          max: modifier?.MAX_SELECTIONS,
          modifier: modifier?.OPTIONS.some((item) => item.MODIFIER) ? "1" : "0",
          price:
            modifier?.OPTIONS && modifier?.OPTIONS[0]
              ? modifier.OPTIONS[0].PRICE
              : toDollars(0),
        }}
      >
        {(formikOptions) => {
          const {values, isValid, handleSubmit, setFieldValue} = formikOptions;

          return (
            <ContainerComponent
              large
              tooltip={{
                label: "Modifiers",
                data: "A modifier is an option that can be added as a modification to a product. Modifiers added to products will appear as selectable options on the POS, order and mobile apps.",
              }}
              buttonLabel="Save"
              label="Edit Modifier"
              description="Create a modifier that can be applied to menu items"
              ref={(e) => (this.slide = e)}
              buttonDisabled={tableInvalid || !isValid}
              buttonOnClick={handleSubmit}
              deleteLabel={
                modifier?.ID
                  ? modifier.PRESET_ID
                    ? "Remove Modifier"
                    : "Delete"
                  : null
              }
              deleteOnClick={this.removeModifier.bind(this)}
            >
              {modifier && (
                <div>
                  {modifier?.PRESET_ID && (
                    <Banner
                      className="mt-4"
                      label="This modifier is a preset"
                      description="This modifier is used across multiple products and editing the modifier here will also edit it on other products. The only thing that will not sync across is defaults modifier fields."
                    />
                  )}

                  <>
                    <FormInput
                      label="Name"
                      name="name"
                      placeholder="Modifier name"
                      options={formikOptions}
                      tooltip={{
                        data: 'The name of the modifier that will be displayed (e.g. "Size")',
                        label: "Name",
                      }}
                      onChangeSoft={(value) => {
                        modifier.NAME = value;
                        this.setState({modifier});
                      }}
                    />

                    <FormInput
                      className="mt-2"
                      placeholder="Internal tag"
                      label="Internal Tag"
                      hint="Optional"
                      name="tag"
                      options={formikOptions}
                      tooltip={{
                        label: "Internal Tag",
                        data: "If two modifiers have the same name, this is used to uniquely identify them. This name is not displayed on the POS.",
                      }}
                      onChangeSoft={(value) => {
                        modifier.INTERNAL_NAME = value;
                        this.setState({modifier});
                      }}
                    />

                    {parseInt(modifier.TYPE) !== 5 && (
                      <FormSelect
                        name="type"
                        className="mt-2"
                        secondaryBlock
                        label="Modifier Type"
                        options={formikOptions}
                        tooltip={{
                          data: [
                            {
                              label: "Modifier Type",
                              data: "The type of add-on to a product.",
                            },
                            {
                              label: "Single Select",
                              data: "With this modifier type, you can only select one option within the modifier group.",
                            },
                            {
                              label: "Single Select w/ Quantity",
                              data: "With this modifier type, you can select multiple quantities of a single option within the modifier group.",
                            },
                            {
                              label: "Multi-Select",
                              data: "With this modifier type, you can select multiple options.",
                            },
                            {
                              label: "Multi-Select w/ Quantity",
                              data: "With this modifier type, you can select multiple quantities of one or more options.",
                            },
                            {
                              label: "Multi-Select w/ Fixed Price",
                              data: "With this modifier type, you can select as many options as you'd like for one fixed price.",
                            },
                          ],
                        }}
                        data={MODIFIER_TYPE_FIELDS}
                      />
                    )}

                    {parseInt(values.type) === 4 && (
                      <FormInput
                        name="price"
                        className="mt-2"
                        secondaryBlock
                        tooltip="The fixed price of this modifier."
                        label="Modifier Price"
                        options={formikOptions}
                      />
                    )}

                    {[2, 3, 4].includes(values.type) && (
                      <FormRow>
                        <FormInput
                          label="Minimum Selections"
                          options={formikOptions}
                          tooltip="The minimum amount of options a customer needs to select. Set as 0 if disabled."
                          name="min"
                          flex
                        />

                        <FormInput
                          label="Maximum Selections"
                          options={formikOptions}
                          tooltip="The maximum amount of options a customer needs to select. Set as 0 if disabled."
                          name="max"
                          flex
                        />
                      </FormRow>
                    )}

                    {parseInt(modifier.TYPE) === 5 && (
                      <FormBoolean
                        secondaryBlock
                        name="modifier"
                        className="mt-2"
                        options={formikOptions}
                        tooltip="A modifier multiple will have the modifiers of this product multiplied by the amount inputted"
                        label="Modifier Multiples"
                        onChangeSoft={({value}) => {
                          if (value === "1") {
                            modifier.OPTIONS.forEach((item) => {
                              item.MODIFIER = "1";
                            });
                          } else if (value === "0") {
                            modifier.OPTIONS.forEach((item) => {
                              item.MODIFIER = null;
                            });
                          }

                          this.setState({modifier});
                        }}
                      />
                    )}

                    {values.type < 2 || values.type === 5 ? (
                      <FormSelect
                        name="defaultOption"
                        className="mt-2"
                        options={formikOptions}
                        label="Default Option"
                        data={[
                          {value: null, label: "No Default Option"},
                          ...modifier.OPTIONS.map((item) => {
                            return {
                              value: item.ID || item.tid,
                              label: item.NAME,
                            };
                          }),
                        ]}
                      />
                    ) : (
                      <div />
                    )}

                    {!variant && (
                      <FormRow>
                        <FormBoolean
                          flex
                          name="required"
                          className="mt-2"
                          secondaryBlock
                          label="Required"
                          options={formikOptions}
                          tooltip={{
                            label: "Required",
                            data: "If enabled, the modifier will be required when adding this product to a cart on all platform.",
                          }}
                        />

                        <FormBoolean
                          flex
                          name="enabled"
                          className="mt-2"
                          secondaryBlock
                          label="Enabled"
                          tooltip={{
                            label: "Enabled",
                            data: "If disabled, the modifier will not display on the mobile app or order website. They will still be displayed on the POS.",
                          }}
                          options={formikOptions}
                        />
                      </FormRow>
                    )}

                    <ModifierOptions
                      showModifier={values.modifier === "1"}
                      setState={this.setState.bind(this)}
                      modifier={modifier}
                      product={product}
                    />
                  </>
                </div>
              )}
            </ContainerComponent>
          );
        }}
      </Formik>
    );
  }
}

Modifier.propTypes = {
  variant: PropTypes.bool,
  preset: PropTypes.bool,
};

export default Modifier;
