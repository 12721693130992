import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import ProductDropdown from "../../../dropdowns/sales/product-dropdown";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";

class PrizeModal extends Component {
  state = {prize: null};

  open(prize = null) {
    this.setState({prize}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createPrize({name, type, content, quantity, description}) {
    const {task} = this.props;

    let serverPrize = await request(
      "rewards/tasks/" + task.ID + "/prize",
      "POST",
      {
        NAME: name,
        TYPE: type,
        LOGO: null,

        DESCRIPTION: description,

        QUANTITY: quantity,
        CONTENT: content,
      }
    );

    this.props.addState(serverPrize);
    this.modal.close();
  }

  async savePrize({name, type, content, quantity, description}) {
    const {prize} = this.state;
    const {task} = this.props;

    let serverPrize = await request(
      "rewards/tasks/" + task.ID + "/prize/" + prize.ID,
      "PATCH",
      {
        NAME: name,
        TYPE: type,
        LOGO: null,

        DESCRIPTION: description,

        QUANTITY: quantity,
        CONTENT: content,
      }
    );

    this.props.updateState(serverPrize.ID, serverPrize);
    this.modal.close();
  }

  async removePrize() {
    const {prize} = this.state;
    const {task} = this.props;

    let serverPrize = await request(
      "rewards/tasks/" + task.ID + "/prize/" + prize.ID,
      "DELETE",
      {}
    );

    this.props.updateState(serverPrize.ID);
    this.modal.close();
  }

  renderContent(formikOptions) {
    const {values} = formikOptions;

    if (values.type === "FREE_PRODUCT") {
      return (
        <ProductDropdown
          options={formikOptions}
          label="Task Product"
          name="content"
          unique
          flex
        />
      );
    }

    if (values.type === "FREE_CATEGORY") {
      return (
        <CategoryDropdown
          options={formikOptions}
          label="Task Category"
          name="content"
          unique
          flex
        />
      );
    }
  }

  render() {
    const {prize} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={prize ? "Save" : "Create"}
        label={prize ? "Edit Prize" : "Create Prize"}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.removePrize()}
        deleteLabel={prize && "Delete"}
      >
        <Formik
          onSubmit={
            prize ? this.savePrize.bind(this) : this.createPrize.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: prize?.NAME ?? "",
            description: prize?.DESCRIPTION ?? "",
            logo: prize?.LOGO ?? null,
            type: prize?.TYPE ?? "POINTS",
            content: prize?.CONTENT ?? null,
            quantity: prize?.QUANTITY ?? "1",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" label="Name" options={formikOptions} />

                <FormSelect
                  name="type"
                  label="Type"
                  options={formikOptions}
                  data={[
                    {label: "Points", value: "POINTS"},
                    {label: "Free Product", value: "FREE_PRODUCT"},
                    {
                      label: "Free Product from Category",
                      value: "FREE_CATEGORY",
                    },
                  ]}
                />

                {this.renderContent(formikOptions)}

                <FormInput
                  name="quantity"
                  label="Prize Quantity"
                  options={formikOptions}
                />

                <FormTextArea
                  name="description"
                  label="Description"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PrizeModal;
