import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketsTable from "../../../tables/sales/tickets-table";
import GiftCardModal from "../../../modals/marketing/gift-cards/gift-card-modal";
import {setupReduxConnection} from "../../../redux";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

class GiftCardPage extends Component {
  state = {card: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID} = this.props.router.params;

    const card = await request("cards/" + ID, "GET", null);
    this.setState({card});
  }

  async deleteGiftCard() {
    const {card} = this.state;
    showLoadingConfirmAlert(
      "Delete Gift Card",
      "Are you sure you want to delete this gift card? This action cannot be undone."
    ).then(async (close) => {
      await request("cards/partner/gift/" + card.ID, "DELETE");
      this.props.router.navigate("/gifts");
      close();
    });
  }

  render() {
    const {card} = this.state;
    const {permissions} = this.props.user;
    const hasEditPermission =
      permissions.indexOf("ADMIN_FULL") !== -1 ||
      permissions.indexOf("GIFT_CARDS_EDIT_FULL") !== -1;

    if (!card) {
      return <Loading />;
    }

    return (
      <div>
        <GiftCardModal
          ref={(e) => (this.cardModal = e)}
          updateState={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label={"Gift Card"}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Gift Cards", url: "/gifts"},
          ]}
          buttons={
            hasEditPermission
              ? [
                  {
                    type: "dropdown",
                    label: "Actions",
                    sections: [
                      {
                        items: [
                          {
                            label: "Delete Gift Card",
                            onClick: this.deleteGiftCard.bind(this),
                          },
                        ],
                      },
                    ],
                  },
                ]
              : undefined
          }
        />

        <TwoColumnList
          label="Gift Card Information"
          button={
            hasEditPermission
              ? {
                  label: "Edit Gift Card",
                  onClick: () => this.cardModal.open(card),
                }
              : undefined
          }
          data={[
            {
              label: "Patron",
              value: card?.ACCOUNT_ID ? (
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {card?.FULL_NAME ? card.FULL_NAME : "Unnamed Patron"}
                  </div>

                  {card.PHONE ? (
                    <div className="text-sm text-gray-500">
                      {getFormattedPhone(card.PHONE)}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                "-"
              ),
            },
            {
              label: "PAN ID",
              value: card.PAN_ID ?? "-",
            },
            {
              label: "Date Created",
              value: card?.DATE_CREATED
                ? moment(card.DATE_CREATED).format("MM/DD/YYYY")
                : "",
            },
            {
              label: "Last Used",
              value: card?.DATE_UPDATED
                ? moment(card.DATE_UPDATED).format("MM/DD/YYYY")
                : "-",
            },
            {
              label: "Balance",
              value: `$${toDollars(card.BALANCE)}`,
            },
            {
              label: "Notes",
              value: card?.NOTES ?? "-",
            },
          ]}
        />

        <Card
          className="mt-4"
          label="Tickets"
          description="All of the orders that have been charged to this gift card"
        >
          <TicketsTable tickets={card.TICKETS} />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(GiftCardPage));
