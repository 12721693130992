import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  PageHeadings,
  Filter,
  Table,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import LabelCategoryModal from "../../../modals/operations/labels/label-category-modal";
import IngredientUnitModal from "../../../modals/operations/ingredients/ingredient-unit-modal";
import {updateStateDelegator} from "../../../utils/util";

class LabelCategoriesPage extends Component {
  state = {
    isLoading: true,
    categories: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let categories = await request("labels/categories", "GET", {});

    this.setState({isLoading: false, categories});
  }

  render() {
    const {isLoading, categories} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Label Categories"
          description="Create categories for your product labels"
          button={{
            label: "Create Category",
            onClick: () => this.labelModal.open(),
          }}
        />

        <LabelCategoryModal
          ref={(e) => (this.labelModal = e)}
          addState={(unit) =>
            this.setState({categories: [unit, ...categories]})
          }
          updateState={(unit) => {
            this.setState({
              categories: updateStateDelegator(categories, unit.ID, unit),
            });
          }}
        />

        <Table
          pagination
          className="mt-4"
          data={categories}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.labelModal.open(row),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              width: 1,
              label: "Created",
              value: "DATE_CREATED",
              format: (value, row) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              width: 1,
              label: "Updated",
              value: "DATE_UPDATED",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(LabelCategoriesPage));
