import React, {Component} from "react";
import {request} from "../../../utils/request";
import {showErrorAlert} from "../../../utils/alert-helper";
import {Loading, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import LocationPartnersTab from "./company-structure-page-tabs/location-partners-tab";
import CompanyPartnersTab from "./company-structure-page-tabs/company-partners-tab";
import SuperPartnersTab from "./company-structure-page-tabs/super-partners-tab";

class CompanyAdminsPage extends Component {
  state = {companyStructure: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UMBRELLA_ID} = this.props.shop.location;

    request(`umbrellas/${UMBRELLA_ID}`, "GET")
      .then((companyStructure) => {
        this.setState({companyStructure});
      })
      .catch((err) => {
        showErrorAlert("Error loading company structure", err);
        return false;
      });
  }

  render() {
    const {companyStructure} = this.state;

    if (!companyStructure) {
      return <Loading />;
    }

    const {partners, locations, companies, people} = companyStructure;
    const {locationPartners, companyPartners, superPartners} = partners;

    return (
      <div>
        <Tab
          data={[
            {
              id: "superPartners",
              label: "Super Partners",
              badge: superPartners ? `${superPartners.length}` : "0",
            },

            {
              id: "companyPartners",
              label: "Company Partners",
              badge: companyPartners ? `${companyPartners.length}` : "0",
            },
            {
              id: "locationPartners",
              label: "Location Partners",
              badge: locationPartners ? `${locationPartners.length}` : "0",
            },
          ]}
        >
          {(tab) => {
            if (tab === "locationPartners") {
              return (
                <LocationPartnersTab
                  partners={locationPartners}
                  locations={locations}
                  companies={companies}
                  people={people}
                  syncState={() => this.syncState()}
                />
              );
            } else if (tab === "companyPartners") {
              return (
                <CompanyPartnersTab
                  partners={companyPartners}
                  companies={companies}
                  people={people}
                  syncState={() => this.syncState()}
                />
              );
            } else {
              return (
                <SuperPartnersTab
                  partners={superPartners}
                  people={people}
                  companies={companies}
                  syncState={() => this.syncState()}
                />
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(CompanyAdminsPage));
