import React, {Component} from "react";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {Portal as Second} from "react-portal";

class Button extends Component {
  state = {isLoading: false, isDisabled: false, error: null};

  constructor(props) {
    super(props);

    this.id = "but_" + randomString(24);
  }

  startLoading() {
    this.setState({isLoading: true});
  }

  stopLoading() {
    this.setState({isLoading: false});
  }

  disable() {
    this.setState({isDisabled: true});
  }

  enable() {
    this.setState({isDisabled: false});
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  setError(error) {
    this.setState({error});
  }

  renderIcon() {
    const {icon, type, ignoreIconMargin} = this.props;

    if (icon) {
      return (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(
            type === "gray" ? "text-gray-400" : "text-indigo-300",
            ignoreIconMargin ? "" : "-ml-1 mr-2",
            "h-5 w-5"
          )}
        />
      );
    }

    return <div className="h-5" />;
  }

  render() {
    const {isLoading, isDisabled, error} = this.state;
    const {
      errorMessage = null,
      type = "button",
      className = "",
      autoLoad,
      disabled,
      onClick,
      label,
      flex,
    } = this.props;

    let classStyle =
      "border-transparent text-white bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-500";
    if (type === "gray") {
      classStyle =
        "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500";
    } else if (type === "error" || type === "delete") {
      classStyle = "bg-red-600 text-white hover:bg-red-700 focus:ring-red-500";
    }

    if (errorMessage || disabled || isDisabled) {
      classStyle = "bg-gray-100 text-neutral-text cursor-not-allowed";
    }

    return (
      <>
        <Second>
          <ReactTooltip
            id={"tool-" + this.id}
            place="top"
            type="light"
            effect="solid"
            delayHide={250}
            border={true}
            borderColor="#d1d5db"
            className="solid-tooltip shadow-sm"
            afterHide={() => {
              console.log("HIDE");

              this.setState({error: null});
            }}
          />
        </Second>

        <button
          key={error}
          data-tip={error}
          data-for={"tool-" + this.id}
          data-event="mouseenter"
          data-event-off="mouseleave"
          type={type}
          disabled={errorMessage !== null || disabled || isDisabled}
          onClick={() => {
            if (isLoading || errorMessage || isDisabled) {
              return;
            }

            if (autoLoad) {
              this.startLoading();
            }

            onClick && onClick();
          }}
          className={classNames(
            className,
            flex && "w-full justify-center",
            "relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border",
            "focus:outline-none focus:ring-2 focus:ring-offset-2",
            classStyle
          )}
        >
          {isLoading && (
            <FontAwesomeIcon icon="spinner-third" className="fast-spin mr-2" />
          )}

          {this.renderIcon()}
          {errorMessage ?? label}
        </button>
      </>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string.isRequired,

  errorMessage: PropTypes.string,
  onClick: PropTypes.func,

  className: PropTypes.string,
  type: PropTypes.string,

  slim: PropTypes.bool,
  flex: PropTypes.bool,

  loading: PropTypes.bool,
};

export default Button;
