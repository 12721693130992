import React, {Component} from "react";
import {
  CELL_TYPES,
  MODIFIER_SALES_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  PageHeadings,
  Tooltip,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";

class ModifierSalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let mappedModifiers = this.mapModifierSalesData(reportData);

    let rows = mappedModifiers.map((entry) => ({
      // label: entry.modifier ? (
      //   <div className="flex flex-row items-center">
      //     <div>{entry.name}</div> <Tooltip className="pl-2" label="hi" />
      //   </div>
      // ) : (
      //   entry.name
      // ),
      label: entry.name,
      numbers: entry,
      style: entry.modifier ? CELL_TYPES.BOLD : CELL_TYPES.SECONDARY,
    }));

    return rows;
  };

  mapModifierSalesData = (data) => {
    let finalData = [];

    for (let modifier of data) {
      let {
        MODIFIER_NAME,
        OPTIONS,
        TOTAL_ORDER_COUNT,
        TOTAL_SALES,
        LOCATION_NAME,
      } = modifier;
      finalData.push({
        name: MODIFIER_NAME,
        location: LOCATION_NAME,
        orderCount: TOTAL_ORDER_COUNT,
        totalSales: TOTAL_SALES,
        modifier: true,
      });

      finalData.push(
        ...OPTIONS.map((option) => {
          let {ORDER_COUNT, PRICE} = option;

          return {
            name: option.OPTION_NAME,
            location: option.LOCATION_NAME,
            orderCount: option.ORDER_COUNT,
            price: option.PRICE,
            totalSales: ORDER_COUNT * PRICE,
            modifier: false,
          };
        })
      );
    }

    return finalData;
  };

  render() {
    const {REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;
    const {locationIdArray, groupReports} = this.props.reporting;

    const isMultiLocation = locationIdArray.length > 1;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label="Modifier Sales Report" />
        </div>

        <FixedColumnReport
          filenamePrefix="modifier-sales"
          endpoint="report/modifiersales"
          constantColumns={
            isMultiLocation
              ? [
                  {label: "Location", columnSelector: "location"},
                  ...MODIFIER_SALES_COLUMNS,
                ]
              : MODIFIER_SALES_COLUMNS
          }
          convertDataToRows={this.convertDataToRows}
          searchText={"Search Modifiers by Name"}
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          groupbyFilter={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(
  ModifierSalesReportPage
);
