import React, {Component} from "react";
import {
  FormInput,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ColorDropdown from "../../features/team/color-dropdown";
import {request} from "../../utils/request";
import {showConfirmAlert} from "../../utils/alert-helper";
import * as Yup from "yup";

class ShiftTagsDetailsModal extends Component {
  state = {shiftTag: null};

  open(shiftTag) {
    this.setState({shiftTag});
    this.modal.open();
  }

  createShiftTag = async ({name, color}) => {
    const shiftTagPayload = {
      NAME: name,
      COLOR: color,
    };

    await request("team/shift-tags", "POST", shiftTagPayload);

    await this.props.syncState();

    this.modal.close();
  };

  saveShiftTag = async ({name, color}) => {
    let {shiftTag} = this.state;

    const shiftTagPayload = {
      NAME: name,
      COLOR: color,
    };

    await request(
      `team/shift-tags/${shiftTag.UNIQUE_ID}`,
      "PATCH",
      shiftTagPayload
    );

    await this.props.syncState();

    this.modal.close();
  };

  deleteShiftTag = () => {
    let {UNIQUE_ID, NAME} = this.state.shiftTag;

    showConfirmAlert(
      `Deleting ${NAME}`,
      `Are you sure you want to delete ${NAME}? \nThis will remove ${NAME} from all previous and future shifts`
    ).then(async (r) => {
      await request(`team/shift-tags/${UNIQUE_ID}`, "DELETE");

      this.modal.close();

      await this.props.syncState();
    });
  };

  render() {
    let {shiftTag} = this.state;

    return (
      <Modal
        buttonLabel={shiftTag ? "Save" : "Create"}
        label={shiftTag ? "Edit Shift Tag" : "Create Shift Tag"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        deleteLabel={"Delete"}
        deleteOnClick={this.deleteShiftTag}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: shiftTag?.NAME,
            color: shiftTag?.COLOR,
          }}
          onSubmit={shiftTag ? this.saveShiftTag : this.createShiftTag}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is a required field"),
            color: Yup.string().required(
              "Please select a color for the shift tag"
            ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label={"Name"}
                  name={"name"}
                  options={formikOptions}
                />

                <ColorDropdown
                  label={"Color"}
                  name={"color"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ShiftTagsDetailsModal;
