import React, {Component} from "react";
import {Rnd} from "react-rnd";
import PdfDropdown from "./pdf-dropdown";

class DropdownField extends Component {
  onChange(value) {
    const {field, onChange} = this.props;
    field.VALUE = value;
    onChange && onChange(value);
  }

  handleSubmit() {}

  render() {
    const {name, field} = this.props;

    const options = field.OPTIONS.map((option) => {
      return {label: option.NAME, value: option.ID};
    });

    return (
      <Rnd
        default={{
          x: field?.X_COORDINATE,
          y: field?.Y_COORDINATE,
          width: field?.WIDTH,
          height: field?.HEIGHT,
        }}
        className={"hover:cursor-pointer"}
        bounds="parent"
        disableDragging={true}
        enableResizing={false}
        key={field.UNIQUE_ID}
      >
        <PdfDropdown
          className={"z-40"}
          label={name}
          data={options}
          onChange={(value) => this.onChange(value)}
          value={field.VALUE}
        />
      </Rnd>
    );
  }
}

export default DropdownField;
