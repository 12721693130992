import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import StepNavCard from "../../../features/step-nav-card";
import moment from "moment/moment";
import ApproveTimesheetsComponent from "../../../features/payroll/run/regular/approve-timesheets-component";
import LoadingSpinner from "../../../components/loading-spinner";
import DraftPayrollComponent from "../../../features/payroll/run/regular/draft-payroll-component";
import ReviewPayrollComponent from "../../../features/payroll/run/regular/review-payroll-component";
import ConfirmationComponent from "../../../features/payroll/run/regular/confirmation-component";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import ChooseHoursOffCycleComponent from "../../../features/payroll/run/off-cycle/off-cycle-choose-hours-component";
import {RUN_MODES} from "../../../redux/payroll";
import LateChoosePaydayComponent from "../../../features/payroll/run/regular/late-choose-payday-component";
import OffCycleChoosePaydayComponent from "../../../features/payroll/run/off-cycle/off-cycle-choose-payday-component";
import ContractorsChoosePaymentDateComponent
  from "../../../features/payroll/run/contractor/contractors-choose-payment-date-component";
import ContractorsChoosePaymentsComponent
  from "../../../features/payroll/run/contractor/contractors-choose-payments-component";

class RunPayrollPage extends Component {
  state = {steps: null, titleString: null};

  constructor(props) {
    super(props);
    let {currentPayrun} = this.props.payroll;

    if (!currentPayrun) {
      this.props.router.navigate("/payroll");
    }
  }

  componentDidMount() {
    let {currentPayrun, runMode} = this.props.payroll;

    let titleString, steps;

    if (runMode === RUN_MODES.REGULAR || runMode === RUN_MODES.MISSED) {
      let {PERIOD_START, PERIOD_END} = currentPayrun;
      let periodStr =
        moment(PERIOD_START).format("MMM D") +
        " - " +
        moment(PERIOD_END).format("MMM D, YYYY");

      titleString = `${
        runMode === RUN_MODES.REGULAR ? "Regular Payroll" : "Late Payroll"
      } (${periodStr})`;

      steps = [
        {
          label: "Approve Time Cards",
          component: ApproveTimesheetsComponent,
        },
        {
          label: "Hours & Pay",
          hideBack: true,
          component: DraftPayrollComponent,
        },
        {
          label: "Review & Submit",
          nextText: "Submit",
          component: ReviewPayrollComponent,
        },
        {
          label: "Confirmation",
          hideBack: true,
          component: ConfirmationComponent,
        },
      ];

      if (runMode === RUN_MODES.MISSED) {
        steps.unshift({
          label: "Choose Payday",
          component: LateChoosePaydayComponent,
        });
      }
    } else if (runMode === RUN_MODES.CONTRACTORS) {
      titleString = "Pay Contractors";

      steps = [
        {
          label: "Payment Date",
          component: ContractorsChoosePaymentDateComponent
        },
        {
          label: "Create Payments",
          component: ContractorsChoosePaymentsComponent
        },
        {
          label: "Review & Submit",
          component: ReviewPayrollComponent,
          nextButtonText: "Submit",
        },
        {
          label: "Confirmation",
          component: ConfirmationComponent,
          hideBack: true,
        },
      ];
    } else {
      if (runMode === RUN_MODES.BONUS) {
        titleString = "Bonus Payroll";
      } else {
        titleString = "Off-Cycle Payroll";
      }

      steps = [
        {
          label: "Pay Period & Payday",
          component: OffCycleChoosePaydayComponent,
        },
        {
          label: "Hours & Pay",
          component: ChooseHoursOffCycleComponent,
        },
        // // {
        // //   title: "Time Off",
        // //   component: PendingTimeOffComponent,
        // // },
        {
          label: "Review & Submit",
          component: ReviewPayrollComponent,
          nextButtonText: "Submit",
        },
        {
          label: "Confirmation",
          component: ConfirmationComponent,
          hideBack: true,
        },
      ];
    }

    this.setState({titleString, steps});
  }

  render() {
    let {titleString, steps} = this.state;

    if (!steps) return <Loading />;

    return (
      <div>
        <PageHeadings label="Run Payroll" />

        <StepNavCard label={titleString} steps={steps} />
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(RunPayrollPage));
