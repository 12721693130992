import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

class FormDaySelect extends Component {
  render() {
    const {iso = false} = this.props;

    const data = iso
      ? [
          {value: "0", label: "Monday"},
          {value: "1", label: "Tuesday"},
          {value: "2", label: "Wednesday"},
          {value: "3", label: "Thursday"},
          {value: "4", label: "Friday"},
          {value: "5", label: "Saturday"},
          {value: "6", label: "Sunday"},
        ]
      : [
          {value: "0", label: "Sunday"},
          {value: "1", label: "Monday"},
          {value: "2", label: "Tuesday"},
          {value: "3", label: "Wednesday"},
          {value: "4", label: "Thursday"},
          {value: "5", label: "Friday"},
          {value: "6", label: "Saturday"},
        ];

    return <FormSelect {...this.props} data={data} />;
  }
}

export default FormDaySelect;
