import React, {Component} from "react";
import LocationFilterDropdown from "../../components/location-filter-dropdown";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import CompareBlock from "../../features/reporting/compare/compare-block";
import {setupReduxConnection} from "../../redux";
import {showErrorAlert} from "../../utils/alert-helper";

class CompareSalesReport extends Component {
  state = {};

  componentDidMount() {
    const {REPORTING_MODE} = this.props.shop.settings;

    if (REPORTING_MODE === "LEGACY") {
      showErrorAlert("Legacy Not Supported", "The comparison sales report is only available Audit reporting. Switch to Audit in your shop settings or reach out to success to learn more!")
    }
  }

  renderCompareBlock() {
    return <CompareBlock/>;
  }

  render() {
    const {REPORTING_MODE} = this.props.shop.settings;

    if (REPORTING_MODE === "LEGACY") {
      return <div><PageHeadings label={"Compare Sales"}/></div>;
    }

    return (
      <div>
        <PageHeadings label={"Compare Sales"}/>

        <LocationFilterDropdown
          className={"mt-3"}
          onChange={(e) => this.props.updateLocationIdArray(e)}
        />

        <div className="my-6 h-0.5 w-full bg-gray-200"></div>

        {this.renderCompareBlock()}
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop", "reporting"])(
  CompareSalesReport
);
