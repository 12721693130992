import React, {Component} from "react";
import AdminModal from "../../../../modals/shop/company-structure/admin-modal";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import PartnersTable from "../../../../tables/shop/company-structure/partners-table";

class LocationPartnersTab extends Component {
  render() {
    const {partners, syncState, locations, people, companies} = this.props;

    return (
      <div>
        <AdminModal
          ref={(e) => (this.modalRef = e)}
          locations={locations}
          people={people}
          syncState={syncState}
          level={"LOCATION"}
        />

        <Card
          label={"Location Partners"}
          button={{
            label: "Add Admin+",
            onClick: () => this.modalRef.open(),
          }}
        >
          <PartnersTable
            partners={partners}
            syncState={syncState}
            companies={companies}
            people={people}
            locations={locations}
            type={"LOCATION"}
          />
        </Card>
      </div>
    );
  }
}

export default LocationPartnersTab;
