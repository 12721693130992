import React, {Component} from "react";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateSpecify from "../../../../../components/form-date-specify";

class W4CompanyInfoPage extends Component {
  render() {
    const {options} = this.props;

    return (
      <form>
        <FormInput
          label={"Employer Address"}
          name={"employerAddress"}
          options={options}
        />

        <FormInput
          label={"Employer Name"}
          name={"employerName"}
          options={options}
        />

        <FormInput
          label={"Employer Identification Number"}
          name={"ein"}
          options={options}
        />

        <FormDateSpecify
          mdy
          label={"First date of employment"}
          name={"firstDateOfEmployment"}
          options={options}
        />
      </form>
    );
  }
}

export default W4CompanyInfoPage;
