import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import TaskModal from "../../../modals/marketing/tasks/task-modal";
import Banner from "../../../components/banner";

class EventsPage extends Component {
  state = {tasks: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    request("rewards/tasks", "GET").then((tasks) => {
      this.setState({tasks});
    });
  }

  render() {
    const {tasks} = this.state;

    if (tasks === null) {
      return <Loading />;
    }

    return (
      <div>
        <TaskModal
          ref={(e) => (this.taskModal = e)}
          addState={(task) => {
            this.props.router.navigate("/task/" + task.UNIQUE_ID);
          }}
        />

        <PageHeadings
          label="Tasks"
          description="Create tasks that customers can complete to receive loyalty points"
          button={{
            label: "Add Task",
            onClick: () => this.taskModal.open(),
          }}
        />

        <Banner
          className="my-4"
          label="Loyalty Tasks are under construction!"
          description="Loyalty Tasks are currently under construction and will be available to use soon!"
        />

        <Table
          pagination
          data={tasks}
          actionButtons={[
            {
              label: "View",
              onClick: (row) =>
                this.props.router.navigate("/task/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "DESCRIPTION",
              label: "Description",
            },
            {
              value: "LOCATION_NAME",
              label: "Location",
              format: (name) => (name ? name : "All Locations"),
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                if (value === null) {
                  return <></>;
                }
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(EventsPage));
