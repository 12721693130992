import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {fetchPayrollPreview} from "../../../../utils/payroll-helper";
import PayrollInformationComponent from "../../payroll-information-component";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment/moment";

class ReviewPayrollComponent extends Component {
  state = {isLoading: true, preview: null, totals: null, employeeBreakdown: null};

  componentDidMount() {
    let {runMode} = this.props.payroll;

    fetchPayrollPreview(runMode).then((preview) => {
      let {EMPLOYEE_BREAKDOWN, TOTALS, PAYDAY} = preview;

      this.setState({
        preview,
        totals: TOTALS,
        employeeBreakdown: EMPLOYEE_BREAKDOWN,
        payday: PAYDAY,
        isLoading: false,
      });
    });
  }

  handleClick(){
    let {isLoading} = this.state;

    return !isLoading;
  }

  renderNumberReview() {
    let {payday, totals} = this.state;
    let {AMOUNT_SUBTOTAL, AMOUNT_DEBIT, AMOUNT_CHECK} = totals;

    let arr = [
      {
        title: "Total Payroll",
        value: toDollars(AMOUNT_SUBTOTAL, true),
      },
      {title: "Debit Amount", value: toDollars(AMOUNT_DEBIT, true)},
      {title: "Paper Check Amount", value: toDollars(AMOUNT_CHECK, true)},
      {title: "Pay Date", value: moment(payday).format("MM/DD/yyyy")},
    ];

    return (
      <div className="flex flex-row mt-5 mb-5">
        {arr.map((entry) => {
          let {title, value} = entry;

          return (
            <div className="flex flex-col mr-4">
              <span className="text-sm">{title}</span>
              <span className="text-sm font-semibold">{value}</span>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let {isLoading, preview} = this.state;

    if (isLoading) {
      return (
        <div className="py-10">
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <div className="m-6">
        <div className="text-lg font-semibold">Quick Review Before You Submit</div>
        <div className="text-sm text-gray-800 mt-0.5">
          Please spend a brief moment reviewing these numbers before you submit
        </div>

        {this.renderNumberReview()}

        <PayrollInformationComponent payroll={preview} />
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(ReviewPayrollComponent);
