import React, {Component} from "react";
import {withRouter} from "../../utils/navigation";

class FourZeroThree extends Component {
  render() {
    return (
      <div className="min-h-full pt-16 pb-12 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>

          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
                403 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Insufficient Permissions
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, it seems you don't have permission to access this page.
              </p>

              <div className="mt-6">
                <div
                  onClick={() => this.props.router.navigate("/")}
                  className="cursor-pointer text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Go home<span aria-hidden="true"> &rarr;</span>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <a
              target="_blank"
              href="https://support.dripos.com/"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Contact Support
            </a>

            <span
              className="inline-block border-l border-gray-300"
              aria-hidden="true"
            />

            <a
              target="_blank"
              href="https://status.dripos.com"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Status
            </a>

            <span
              className="inline-block border-l border-gray-300"
              aria-hidden="true"
            />
            <a
              target="_blank"
              href="https://twitter.com/get_dripos"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Twitter
            </a>
          </nav>
        </footer>
      </div>
    );
  }
}

export default withRouter(FourZeroThree);
