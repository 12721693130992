import React, {Component} from "react";
import {SlideOver, ButtonRow} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {
  showConfirmAlert,
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import AdminModal from "./admin-modal";
import {setupReduxConnection} from "../../../redux";

class CompanyPermissionsModal extends Component {
  state = {
    partner: null,
  };

  open(partner) {
    this.setState({partner}, () => {
      this.slideMenu.open();
    });
  }

  async deleteLocationPartner(location) {
    const {partner} = this.state;
    const {syncState} = this.props;
    const {ID} = location;

    showLoadingConfirmAlert(
      `Are you sure you want to remove ${partner.FULL_NAME} as a location partner?`
    )
      .then(async (close) => {
        try {
          await request("umbrellas/partner/location", "DELETE", {
            ACCOUNT_ID: partner.ID,
            LOCATION_ID: ID,
          });
        } catch (e) {
          close();
          throw e;
        }

        syncState();
        close();
      })
      .catch((err) => {
        showErrorAlert(
          "You do not have permission to delete a location partner."
        );
      });
  }

  async deleteCompanyPartner(company) {
    const {partner} = this.state;
    const {syncState} = this.props;
    const {ID} = company;

    showLoadingConfirmAlert(
      `Are you sure you want to remove ${partner.FULL_NAME} as a company partner?`
    )
      .then(async (close) => {
        try {
          await request("umbrellas/partner/company", "DELETE", {
            ACCOUNT_ID: partner.ID,
            COMPANY_ID: ID,
          });
        } catch (e) {
          close();
          throw e;
        }

        syncState();
        close();
      })
      .catch(() => {
        showErrorAlert(
          "You do not have permission to delete a company partner."
        );
      });
  }

  async deleteSuperPartner() {
    const {partner} = this.state;
    const {UMBRELLA_ID} = this.props.shop.location;
    const {syncState} = this.props;

    showLoadingConfirmAlert(
      `Are you sure you want to remove ${partner.FULL_NAME} as a super partner?`
    )
      .then(async (close) => {
        try {
          await request("/umbrellas/partner", "DELETE", {
            ACCOUNT_ID: partner.ID,
            UMBRELLA_ID,
          });

          syncState();
          close();
        } catch (e) {
          close();
          throw e;
        }
      })
      .catch(() => {
        showErrorAlert("You do not have permission to delete a super partner.");
      });
  }

  render() {
    const {partner} = this.state;
    const permissions = partner?.PERMISSIONS;
    const {companies, locations, type, people, syncState} = this.props;

    return (
      <>
        <AdminModal
          ref={(e) => (this.modalRef = e)}
          level={type}
          companies={companies}
          locations={locations}
          people={people}
          syncState={syncState}
        />

        <SlideOver
          label={"Permissions"}
          buttons={[
            {
              label: "Close",
              type: "gray",
              onClick: () => this.slideMenu.close(),
            },
          ]}
          ref={(e) => (this.slideMenu = e)}
        >
          {type === "LOCATION" && permissions?.length > 0 && (
            <div>
              <div className={"flex flex-row justify-between border px-6 "}>
                <div className={"bg-white py-4 text-md font-medium"}>
                  Location Permissions
                </div>

                <div className={"flex flex-col justify-center"}>
                  <button
                    className={
                      "bg-indigo-500 text-white font-medium text-sm border rounded-lg py-2 px-4 hover:bg-indigo-700 transition-colors duration-250 ease-in-out"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.slideMenu.close();
                      this.modalRef.open(partner);
                    }}
                  >
                    Add+
                  </button>
                </div>
              </div>

              <div className={"space-y-4 px-6 py-2"}>
                {permissions.map((permission) => {
                  return (
                    <div className={"flex flex-row justify-between"}>
                      <div className={"flex flex-col"}>
                        <div className={"border-0 text-sm font-medium"}>
                          {permission.NAME}
                        </div>

                        <div className={"text-sm text-indigo-500"}>
                          {permission.ADDRESS}, {permission.CITY},{" "}
                          {permission.STATE}
                        </div>
                      </div>

                      <div>
                        <button
                          className={
                            "text-sm text-white px-2.5 py-1.5 rounded-md bg-red-600 hover:bg-red-700 transition-all duration-200 ease-in-out"
                          }
                          onClick={async (e) => {
                            e.preventDefault();
                            await this.deleteLocationPartner(permission);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {type === "COMPANY" && permissions?.length > 0 && (
            <div>
              <div className={"flex flex-row justify-between border px-6"}>
                <div className={"bg-white py-4 text-md font-medium"}>
                  Company Permissions
                </div>

                <div className={"flex flex-col justify-center"}>
                  <button
                    className={
                      "bg-indigo-500 text-white font-medium text-sm border rounded-lg py-2 px-4 hover:bg-indigo-700 transition-colors duration-250 ease-in-out"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.slideMenu.close();
                      this.modalRef.open(partner);
                    }}
                  >
                    Add+
                  </button>
                </div>
              </div>

              <div className={"space-y-4 px-6 py-2"}>
                {permissions.map((permission) => {
                  return (
                    <div className={"flex flex-row justify-between"}>
                      <div className={"flex flex-col"}>
                        <div className={"border-0 text-sm font-medium"}>
                          {permission.NAME}
                        </div>

                        <div className={"text-sm text-indigo-500"}>
                          All Locations
                        </div>
                      </div>

                      <div>
                        <button
                          className={
                            "text-sm text-white px-2.5 py-1.5 rounded-md bg-red-600 hover:bg-red-700 transition-all duration-200 ease-in-out"
                          }
                          onClick={async (e) => {
                            e.preventDefault();
                            await this.deleteCompanyPartner(permission);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {type === "SUPER" && (
            <div>
              <div
                className={
                  "flex flex-row bg-white py-4 px-6 border text-md font-medium justify-between align-middle"
                }
              >
                <div>All Company Permissions Granted</div>
                <button
                  className={
                    "text-sm text-white px-2.5 py-1.5 rounded-md bg-red-600 hover:bg-red-700 transition-all duration-200 ease-in-out"
                  }
                  onClick={async (e) => {
                    e.preventDefault();
                    await this.deleteSuperPartner();
                  }}
                >
                  Remove
                </button>
              </div>

              <div className={"space-y-4 px-6 py-2"}>
                {companies.map((company) => {
                  return (
                    <div>
                      <div className={"border-0 text-sm font-medium"}>
                        {company.COMPANY_NAME}
                      </div>

                      <div className={"text-sm text-indigo-500"}>
                        All Locations
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </SlideOver>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(CompanyPermissionsModal);
