import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  Card,
  Table,
  FormInput,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import BankAccountModal from "../../../modals/banking/bank-account-modal";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import DangerBadge from "../../../components/badges/danger-badge";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";
import OtherPayoutSettingsModal from "../../../modals/banking/other-payout-settings-modal";
import CreateStripeLoadingModal from "../../../modals/finance/create-stripe-loading-modal";
import EmailModal from "../../../modals/general/email-modal";

class PayoutSettingsPage extends Component {
  state = {
    loading: true,
    stripeAccount: null,
    isStripeAccountComplete: null,
    bankAccount: null,
  };

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let {stripeAccount, isStripeAccountComplete, bankAccount} = await request(
      "stripe/payouts/settings",
      "GET"
    );

    this.setState({
      loading: false,
      stripeAccount,
      isStripeAccountComplete,
      bankAccount,
    });
  };

  async openSettingsLink() {
    let {stripeAccount} = this.state;

    if (!stripeAccount) {
      this.createStripeLoadingModal.open();

      await request("stripe/connect/partner", "POST").then((data) => {
        window.open(data.url, "_self").focus();
      });
    } else {
      request("stripe/connect/partner", "GET", null).then((data) => {
        window.open(data, "_self").focus();
      });
    }
  }

  saveStatementDescriptor = (values) => {
    request("stripe/connect/update", "POST", values)
      .then((data) => {
        // SweetAlert.fire({
        //   title: "Account Updated",
        //   text: `Account settings have been updated`,
        //   icon: "success",
        // });
      })
      .catch((reason) => {});
  };

  render() {
    let {loading, stripeAccount, isStripeAccountComplete, bankAccount} =
      this.state;
    const {account} = this.props.user;
    const NEED_EMAIL = !account.EMAIL || account.EMAIL.length === 0;

    let {PAYOUT_STATEMENT_DESCRIPTOR} = this.props.shop.settings;

    let statementDescriptor =
      stripeAccount?.settings?.payments?.statement_descriptor;

    if (loading) {
      return <Loading />;
    }

    let stripeStr = stripeAccount
      ? "Open Stripe Settings"
      : NEED_EMAIL
      ? "Please Add an Email"
      : "Create Stripe Account";
    let bankStr = bankAccount ? "Change Bank Account" : "Add Bank Account";
    let stripeAccountBadgeStr = !stripeAccount ? "Not Started" : "Incomplete";

    let bankAccButton = isStripeAccountComplete
      ? [
          {
            label: bankStr,
            onClick: () => this.bankAccountModal.open(),
          },
        ]
      : [];

    return (
      <div>
        <CreateStripeLoadingModal
          ref={(e) => (this.createStripeLoadingModal = e)}
        />

        <BankAccountModal
          ref={(e) => (this.bankAccountModal = e)}
          refresh={this.refresh}
        />

        <EmailModal
          ref={(e) => (this.emailModal = e)}
          refresh={(EMAIL) => {
            this.props.updateUserAccount({...account, EMAIL});
            this.refresh();
          }}
          account={account}
        />

        <OtherPayoutSettingsModal
          ref={(e) => (this.otherPayoutSettingsModal = e)}
          refresh={this.refresh}
        />

        <PageHeadings label="Payout Settings" />

        <Card
          label={
            <div>
              {"Stripe Account"}
              {!isStripeAccountComplete && (
                <DangerBadge className={"ml-4"}>
                  {stripeAccountBadgeStr}
                </DangerBadge>
              )}
            </div>
          }
          buttons={[
            {
              label: stripeStr,
              disabled: stripeAccount ? false : NEED_EMAIL,
              onClick: () => this.openSettingsLink(),
            },
          ]}
        />

        {stripeAccount && (
          <Card
            label={
              <div>
                {"Bank Account"}
                {!bankAccount && (
                  <DangerBadge className={"ml-4"}>
                    {isStripeAccountComplete
                      ? `Incomplete`
                      : "Complete Stripe Onboarding First"}
                  </DangerBadge>
                )}
              </div>
            }
            buttons={bankAccButton}
          >
            {bankAccount && (
              <Table
                data={bankAccount}
                columns={[
                  {
                    label: "Bank Name",
                    value: "bankName",
                  },
                  {
                    label: "Account Number",
                    value: "accountNumber",
                  },
                  {
                    label: "Routing Number",
                    value: "routingNumber",
                  },
                ]}
              />
            )}
          </Card>
        )}

        {stripeAccount && (
          <TwoColumnList
            label={"Other"}
            buttons={[
              {
                label: "Edit",
                onClick: () =>
                  this.otherPayoutSettingsModal.open(stripeAccount),
              },
            ]}
            data={[
              {
                label: "Payment Statement Descriptor",
                value: statementDescriptor,
              },
              {
                label: "Payout Statement Descriptor",
                value: PAYOUT_STATEMENT_DESCRIPTOR,
              },
            ]}
          />
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["banking", "user", "shop"])(
  withRouter(PayoutSettingsPage)
);
