import React, {Component} from "react";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TailComponent extends Component {
  render() {
    const {marginTop, classNames: extraClasses} = this.props;

    const componentClasses = [];

    if (marginTop) {
      componentClasses.push("mt-5");
    }

    return (
      <div className={classNames(extraClasses, ...componentClasses)}>
        {this.props.children}
      </div>
    );
  }
}

TailComponent.propTypes = {
  classNames: PropTypes.string,

  marginTop: PropTypes.bool,
};

export default TailComponent;
