import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import TipBucketModal from "../../modals/banking/tip-bucket-modal";

class TipBucketsTable extends Component {
  render() {
    const {buckets, refresh} = this.props;

    return (
      <>
        <TipBucketModal
          ref={(e) => (this.tipBucketModal = e)}
          updateState={refresh}
        />

        <Table
          striped
          pagination
          data={buckets}
          ref={(e) => (this.tableRef = e)}
          onClick={(row) => {
            this.tipBucketModal.open(row);
          }}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.tipBucketModal.open(row),
            },
          ]}
          columns={[
            {
              value: "EMPLOYEE_NAME",
              label: "Pay-In Employee",
            },
            {
              value: "AMOUNT",
              label: "Amount",
              format: (value) => {
                return toDollars(value, true);
              },
            },
            {
              value: "DEVICE_NAME",
              label: "Pay-In Device Name",
              format: (value) => {
                return value ? value : "No Cash Drawer";
              },
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, row) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
          {...this.props}
        />
      </>
    );
  }
}

TipBucketsTable.propTypes = {
  buckets: PropTypes.array.isRequired,
};

export default withRouter(TipBucketsTable);
