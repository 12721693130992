import React, {Component} from "react";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../../utils/request";
import ProductIngredientModal from "../../../../../modals/sales/product/product-ingredient-modal";
import {updateStateDelegator} from "../../../../../utils/util";

class ProductIngredientsTab extends Component {
  state = {productIngredients: [], ingredients: []};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    const {product} = this.props;

    request("ingredients/product/" + product.ID, "GET").then(
      (productIngredients) => {
        this.setState({productIngredients});
      }
    );

    request("ingredients", "GET").then((ingredients) => {
      this.setState({ingredients});
    });
  }

  render() {
    const {productIngredients} = this.state;
    const {product} = this.props;

    const seq = Math.max(...productIngredients.map((item) => item.SEQ), 0);

    return (
      <Card
        label="Ingredients"
        description="Configure the ingredients for this product"
        button={{
          label: "Add Ingredient",
          onClick: () => this.productIngredientModal.open(null, seq),
        }}
      >
        <ProductIngredientModal
          product={product}
          ref={(e) => (this.productIngredientModal = e)}
          addState={(state) =>
            this.setState({productIngredients: [state, ...productIngredients]})
          }
          updateState={(id, state) => {
            this.setState({
              productIngredients: updateStateDelegator(
                productIngredients,
                id,
                state
              ),
            });
          }}
        />

        <Table
          pagination
          data={productIngredients.filter((item) => item.DATE_ARCHIVED === null)}
          columns={[
            {
              value: "INGREDIENT_NAME",
              label: "Ingredient",
            },
            {
              value: "QUANTITY",
              label: "Quantity",
              format: (value, row) => {
                return row.QUANTITY + ` (${row.INGREDIENT_UNIT_NAME})`;
              },
            },
            {
              value: "DATE_CREATED",
              label: "Status",
              width: "150px",
              type: "date",
            },
          ]}
          actionButtons={[
            {
              label: "Edit",
              onClick: (item) => this.productIngredientModal.open(item, seq),
            },
          ]}
        />
      </Card>
    );
  }
}

export default ProductIngredientsTab;
