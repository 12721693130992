import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  PageHeadings,
  Loading,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import TaxModal from "../../../modals/sales/tax/tax-modal";
import RevenueCenterModal from "../../../modals/sales/tables/revenue-center-modal";

class RevenueCentersPage extends Component {
  state = {isLoading: true, centers: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const centers = await request("centers", "GET");

    this.setState({isLoading: false, centers});
  }

  render() {
    const {isLoading, centers} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <RevenueCenterModal
          ref={(e) => (this.centerModal = e)}
          addState={(item) =>
            this.props.router.navigate("/center/" + item.UNIQUE_ID)
          }
        />

        <PageHeadings
          label="Revenue Centers"
          description="Capture greater reporting and tip breakdowns with revenue centers"
          button={{
            label: "Create Revenue Center",
            onClick: () => this.centerModal.open(),
          }}
        />

        <Table
          pagination
          data={centers}
          className="mt-2"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => {
                this.props.router.navigate("/center/" + row.UNIQUE_ID);
              },
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "RATE",
              label: "Rate",
            },
            {
              value: "IS_DEFAULT",
              label: "Is Default",
              type: "bool",
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(RevenueCentersPage));
