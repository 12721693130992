import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class ShowImageModal extends Component {
  state = {url: null};

  open(url) {
    this.setState({url}, () => this.modal.open());
  }

  render() {
    const {url} = this.state;

    return (
      <Modal ref={(e) => (this.modal = e)}>
        {!url && <div>No photo to display.</div>}

        {!!url && <img className="flex" src={url} alt="" />}
      </Modal>
    );
  }
}

export default ShowImageModal;
