import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {setupReduxConnection} from "../../../redux";
import {SERVICE_CODES, WEIGHT_UNITS} from "../../../utils/constants";
import {request} from "../../../utils/request";

class LabelModal extends Component {
  state = {label: null};

  open(label = null) {
    this.setState({label}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createLabel({code, address}) {
    const {ecommerce, packages} = this.props;

    packages.forEach((pack) => {
      delete pack.name;
      delete pack.tid;
      delete pack.id;
    });

    let body = {
      SERVICE_CODE: code,
      ECOMMERCE_ORDER_ID: ecommerce.ID,
      SHIP_FROM_SHIPPING_ADDRESS_ID: address,
      PACKAGES: packages,
    };

    const serverLabel = await request("ecommerce/shippinglabel", "POST", body);

    this.props.addState(serverLabel);
    this.modal.close();
  }

  async saveLabel({unit, weight, box}) {
    const {pack} = this.state;
    const {boxes} = this.props;
    const boxObject = boxes.find((item) => {
      return item.ID === box;
    });

    const boxPayload = {
      id: pack.id,
      shippingBoxId: boxObject.ID,
      name: boxObject.NAME,
      weight: {
        value: weight,
        unit: unit,
      },
      dimensions: {
        height: boxObject.HEIGHT,
        width: boxObject.WIDTH,
        length: boxObject.LENGTH,
        unit: "inch",
      },
    };

    this.props.updateState(boxPayload.id, boxPayload);
    this.modal.close();
  }

  async removePackage() {
    const {pack} = this.state;

    this.props.updateState(pack.id);
    this.modal.close();
  }

  render() {
    const {addresses} = this.props;
    const {label} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={label ? "Save" : "Add"}
        label={label ? "Edit Label" : "Create Label"}
        deleteOnClick={() => this.removePackage()}
        formikOnClick={() => this.formikRef}
        deleteLabel={label && "Delete"}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            code: label?.weight?.unit ?? SERVICE_CODES[0].value,
            address: label?.weight?.unit ?? addresses[0]?.ID,
          }}
          validationSchema={Yup.object({
            code: Yup.string().required("Code is required"),
          })}
          onSubmit={
            label ? this.saveLabel.bind(this) : this.createLabel.bind(this)
          }
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="code"
                  label="Service Carriers"
                  options={formikOptions}
                  data={SERVICE_CODES}
                />

                <FormSelect
                  name="address"
                  label="Return Address"
                  options={formikOptions}
                  data={addresses.map((item) => {
                    return {
                      value: item.ID,
                      label:
                        item.ADDRESS_IDENTIFIER +
                        ` (${item.ADDRESS.LINE_ONE} ${item.ADDRESS.LINE_TWO})`,
                    };
                  })}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(LabelModal);
