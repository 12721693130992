export function getLastFourPaymentString(payment) {
  if (!payment) {
    return "";
  }

  if (payment.TYPE === "CARD_NOT_PRESENT" || payment.TYPE === "CARD_PRESENT") {
    return payment.CARD ? " ENDING IN " + payment.CARD.LAST_FOUR : "";
  }

  return "";
}
