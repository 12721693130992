import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import Modifier from "./modifier";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import * as Yup from "yup";
import PresetDropdown from "../../dropdowns/sales/preset-dropdown";
import TaxDropdown from "../../dropdowns/sales/tax-dropdown";

export const PRODUCT_PRICING_YUP = Yup.lazy((values) => {
  if (values?.type === "2") {
    return Yup.object({});
  } else {
    return Yup.object({
      price: Yup.string().required("Price is required"),
    });
  }
});

class ProductPricing extends Component {
  async outsideValidate() {
    if (!this.variantRef) return true;

    return this.variantRef.outsideValidate();
  }

  outsideVariant() {
    const {formikOptions} = this.props;

    if (
      formikOptions &&
      formikOptions.values.type === "2" &&
      formikOptions.values.global === "custom"
    ) {
      formikOptions.setFieldValue("variant", this.variantRef.outsideFetch());
    }
  }

  renderPricing({type, variant}, formikOptions) {
    if (parseInt(type) === 0) {
      return (
        <FormInput
          name="price"
          placeholder="2.50"
          options={formikOptions}
          label="Product Price"
        />
      );
    }

    if (parseInt(type) === 1) {
      return (
        <FormInput
          name="price"
          placeholder="2.50"
          options={formikOptions}
          label="Base Price"
        />
      );
    }

    if (parseInt(type) === 2) {
      if (variant) {
        return (
          <div>
            <FormSelect
              label="Pricing Modifier Type"
              options={formikOptions}
              onChangeSoft={() =>
                formikOptions.setFieldValue("variant", {
                  NAME: "Sizes",
                  INTERNAL_NAME: formikOptions.values.name + " Sizes",
                  MAX_SELECTIONS: 0,
                  MIN_SELECTIONS: 0,
                  DEFAULT_OPTION: null,
                  TYPE: 5,
                  REQUIRED: 1,
                  ENABLED: 1,
                  OPTIONS: [],
                  PRESET: 0,
                })
              }
              name="global"
              data={[
                {
                  label: "Pre-Made Pricing Modifier",
                  value: "premade",
                },
                {
                  label: "Custom Pricing Modifier",
                  value: "custom",
                },
              ]}
            />

            {formikOptions.values.global === "premade" ? (
              <PresetDropdown
                label="Pre-Built Pricing Modifier"
                options={formikOptions}
                name="variant"
                type={5}
              />
            ) : (
              <Modifier
                ref={(e) => (this.variantRef = e)}
                modifier={variant}
                variant
              />
            )}
          </div>
        );
      }
    }

    if (parseInt(type) === 3) {
      return (
        <div>
          <FormInput
            name="price"
            placeholder="2.50"
            options={formikOptions}
            label="Base Price"
            tooltip={{
              label: "Base Price",
              data: "This is the base price of the product. The total cost of the product will be this price plus the weighted price.",
            }}
          />

          <FormInput
            name="weightPrice"
            placeholder={"0.10"}
            options={formikOptions}
            label="Price Per Unit Weight"
            tooltip={{
              label: "Weighted Price",
              data: "This is the price per unit of weight. The total cost of the product will be this price times the weight of the item plus the base price. ",
            }}
          />
        </div>
      );
    }

    return <div>Hi</div>;
  }

  render() {
    const {formikOptions} = this.props;
    const {values} = formikOptions;

    return (
      <div className="mt-4">
        <FormSelect
          name="type"
          secondaryBlock
          options={formikOptions}
          label="Pricing Type"
          tooltip={{
            data: [
              {
                label: "Pricing Type",
                data: "The pricing type determines how the price of a product is ultimately calculated. There are a few options for the pricing type.",
              },
              {
                label: "Fixed Pricing",
                data: "Fixed priced items are given a single base price. Modifiers added to this type of item are summed to form the final price.",
              },
              {
                label: "Variable Pricing",
                data: "Variable priced items are assigned a price at checkout on the POS. These should not be used on kiosk, mobile app or order website.",
              },
              {
                label: "Sized Pricing",
                data: "Sized priced items have different prices based on the size of the item. You may customize the available sizes and their prices. Sizes are displayed as a required modifier to the item.",
              },
              {
                label: "Weight Pricing",
                data: "Weight priced items are given a base price and a price per unit. At checkout, a weight must be given on the POS. The final price of the item will be the base price + the price per unit * the weight + price of any modifiers.",
              },
            ],
          }}
          onChangeSoft={({value}) => {
            this.outsideVariant();

            if (value === "2" && values.variant === null) {
              formikOptions.setFieldValue("variant", {
                ID: null,
                TYPE: 5,
                REQUIRED: 1,
                ENABLED: 1,
                OPTIONS: [{NAME: "", PRICE: "", ENABLED: 1}],
              });
            }
          }}
          data={[
            {
              value: "0",
              label: "Fixed Pricing",
              secondary: "Base price of the product is a fixed rate",
            },
            {
              value: "2",
              label: "Sized Pricing",
              secondary:
                "Set multiple sizes that will change the default price of the product",
            },
            {
              value: "1",
              label: "Variable Pricing",
              secondary:
                "Price will be set at checkout on the point of sale. Should not be used for mobile products.",
            },
            {
              value: "3",
              label: "Weight Pricing",
              secondary: "Price is based on weight of the product",
            },
          ]}
        />

        {this.renderPricing(values, formikOptions)}

        <TaxDropdown
          hint={"Optional"}
          tooltip={{
            label: "Tax Selection",
            data: "Select any additional tax you would like to include on this product. Note that the category tax is automatically included.",
          }}
          name={"tax"}
          options={formikOptions}
        />
      </div>
    );
  }
}

export default ProductPricing;
