import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class EmailSendModal extends Component {
  state = {loading: false};

  open() {
    this.setState({loading: false}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async send() {
    this.setState({loading: true});
    await this.props.sendCampaign();
    this.setState({loading: false});
    this.modal.close();
  }

  render() {
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={"Send"}
        buttonOnClick={() => this.send()}
        label={"Send email campaign"}
      >
        <div className="text-gray-600 text-sm">
          Are you sure you would like to send this email campaign?
        </div>
      </Modal>
    );
  }
}

export default EmailSendModal;
