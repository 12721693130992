import React, {Component} from "react";
import {CalendlyEventListener, InlineWidget} from "react-calendly";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {Check} from "react-feather";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";

class PayrollCalendlyScheduleComponent extends Component {
  state = {scheduled: false};

  handleClick = () => {
    let {scheduled} = this.state;
    let {PAYROLL_WHITELISTED} = this.props.shop.location;

    if (PAYROLL_WHITELISTED || scheduled) {
      return true;
    }

    showErrorAlert(
      "Schedule Consultation",
      "Schedule a consultation to move onto the next step.",
      "Ok"
    );

    return false;
  };

  onSchedule = async () => {
    await request("location", "PATCH", {PAYROLL_WHITELISTED: 1});
    this.setState({scheduled: true});
  };

  render() {
    let {PAYROLL_WHITELISTED} = this.props.shop.location;

    if (PAYROLL_WHITELISTED) {
      return (
        <div
          className="py-20 flex flex-row justify-center text-2xl font-semibold items-center"
          style={{height: 750}}
        >
          <Check className="mr-3" />
          Your payroll onboarding consultation has been scheduled!
        </div>
      );
    }

    return (
      <div>
        <CalendlyEventListener
          onEventScheduled={() => {
            this.onSchedule();
          }}
        />

        <InlineWidget
          styles={{
            height: "750px",
          }}
          url="https://calendly.com/dripos_nicole/dripos-payroll-onboarding"
          pageSettings={{
            primaryColor: "#6365f1",
            textColor: "6365f1",
          }}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(PayrollCalendlyScheduleComponent);
