import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import CouponModal from "../../../../modals/marketing/promotions/coupon-modal";
import PromotionModal from "../../../../modals/marketing/promotions/promotion-modal";
import CouponCard from "../../../../features/marketing/coupon-card";
import PromotionCard from "../../../../features/marketing/promotion-card";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import DangerBadge from "../../../../components/badges/danger-badge";
import Banner from "../../../../components/banner";

class CouponPage extends Component {
  state = {coupon: null, promotion: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const coupon = await request("promotions/coupons/" + id, "GET");

    this.setState({promotion: coupon.PROMOTION, coupon});
  }

  async deleteCoupon() {
    const {coupon} = this.state;

    showLoadingConfirmAlert(
      "Delete Coupon",
      "Are you sure you want to delete this coupon?"
    ).then(async (close) => {
      await request("promotions/coupons/" + coupon.ID, "DELETE", {});

      close();

      this.props.router.navigate("/coupons");
      this.modal.close();
    });
  }

  render() {
    const {coupon, promotion} = this.state;

    if (coupon === null) {
      return <Loading />;
    }

    return (
      <div>
        <CouponModal
          updateState={(coupon) => this.setState({coupon})}
          ref={(e) => (this.couponModal = e)}
        />

        <PromotionModal
          updateState={(promotion) => this.setState({promotion})}
          ref={(e) => (this.promotionModal = e)}
        />

        <PageHeadings
          label={
            coupon.PROMOTION.DATE_ARCHIVED ? (
              <div className={"flex flex-row items-center"}>
                <div>{coupon.CODE}</div>
                <DangerBadge className="ml-2 mt-2 h-1/2">Archived</DangerBadge>
              </div>
            ) : (
              coupon.CODE
            )
          }
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Coupon",
                      onClick: () => this.deleteCoupon(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Deals Page", url: "/deals"},
            {label: "Coupons Page", url: "/coupons"},
          ]}
        />

        {!!promotion.TEXT_CAMPAIGN_ID && (
          <Banner
            className="mt-4"
            label="Text Campaign Coupon"
            description="This coupon was sent out via text campaign. You should NOT change the code since the current code has already been sent out to the customer!"
          />
        )}

        <CouponCard
          handleCard={() => this.couponModal.open(coupon)}
          coupon={coupon}
        />

        <PromotionCard
          handleCard={() => this.promotionModal.open(promotion)}
          promotion={promotion}
        />

        <Card
          label="Coupon Usages"
          description="Tickets that have used this coupon code"
        >
          <Table
            pagination
            columns={[
              {
                label: "Ticket Number",
                value: "TICKET_NUMBER",
              },
              {
                label: "Amount",
                value: "AMOUNT_DISCOUNT",
                format: (amount) => "$" + toDollars(amount),
              },
              {label: "Name", value: "NAME"},
              {
                label: "Platform",
                value: "PLATFORM",
                format: (platform, _) => {
                  return `${platform ? platform.capitalize() : "POS"}`;
                },
              },
              {
                label: "Date Created",
                value: "DATE_CREATED",
                format: (val, row) => moment(val).format("h:mma ddd MMM Do"),
              },
            ]}
            placeholders={{noDataTitle: "No Participants"}}
            actionButtons={[
              {
                label: "View",
                onClick: (ticket) => {
                  this.props.router.navigate("/ticket/" + ticket.UNIQUE_ID);
                },
              },
            ]}
            data={promotion.USAGES.filter(
              (usage) =>
                !promotion.TEXT_CAMPAIGN || usage.COUPON_ID == coupon.ID
            )}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(CouponPage);
