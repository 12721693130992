import React, {Component} from "react";
import {Tab, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import DevicesTable from "../../../tables/shop/devices-table";
import LabelersTable from "../../../tables/shop/labelers-table";
import PrintersTable from "../../../tables/shop/printers-table";

class DevicesPage extends Component {
  state = {devices: null, printers: null, kiosks: null, labelers: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    request("partner/devices", "GET").then((devices) => {
      this.setState({devices});
    });

    request("partner/printers", "GET").then((printers) => {
      this.setState({printers});
    });

    request("labels/devices", "GET", null).then((labelers) => {
      this.setState({labelers});
    });
  }

  render() {
    const {devices, labelers, printers} = this.state;

    return (
      <div>
        <PageHeadings
          label={"Devices"}
          description={"Manage all of the devices associated with your shop"}
        />

        <Tab
          data={[
            {
              id: "pos",
              label: "Points of Sale",
              badge: devices ? "" + devices.length : "0",
            },
            {
              id: "printers",
              label: "Wifi Printers",
              badge: printers ? "" + printers.length : "0",
            },
            {
              id: "labelers",
              label: "Label Printers",
              badge: labelers ? "" + labelers.length : "0",
            },
          ]}
        >
          {(tab) => {
            if (tab === "pos") {
              return (
                <DevicesTable
                  devices={devices}
                  updateState={(deviceId, deviceName) => {
                    this.syncState();
                  }}
                />
              );
            }

            if (tab === "labelers") {
              return (
                <LabelersTable
                  labelers={labelers}
                  updateState={(labeler) => {
                    this.syncState();
                  }}
                />
              );
            }

            if (tab === "printers") {
              return (
                <PrintersTable
                  printers={printers}
                  updateState={(printer) => this.syncState()}
                />
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(DevicesPage);
