import React, {Component} from "react";
import {Modal, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {formatDatesOff} from "../../pages/team/approvals/tabs/time-off-tab";
import moment from "moment";
import {request} from "../../utils/request";
import {
  OPEN_SHIFT_APPROVAL_TYPES,
  TIME_OFF_REQUEST_STATUSES,
} from "../../utils/team-constants";
import WarningBadge from "../../components/badges/warning-badge";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import {formatShiftString} from "../../utils/team-helper";

class OpenShiftApprovalModal extends Component {
  state = {request: null, notes: null};

  open(request) {
    this.setState({request}, () => {
      this.modal.open();
    });
  }

  approveRequest(isAccepted) {
    let {request, notes} = this.state;

    this.props.approveRequest(request.ID, isAccepted, notes);
    this.modal.close();
  }

  renderHeader() {
    let {request} = this.state;

    return (
      <div className="flex flex-row">
        <div>{`${request?.FULL_NAME}'s Request`}</div>

        <div className="ml-10">
          {request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.pending.value && (
            <WarningBadge>Pending</WarningBadge>
          )}

          {request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.approved.value && (
            <SuccessBadge>Approved</SuccessBadge>
          )}

          {request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.denied.value && (
            <DangerBadge>Denied</DangerBadge>
          )}
        </div>
      </div>
    );
  }

  render() {
    let {request} = this.state;

    let actionableRequest =
      request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.pending.value;

    let reviewerResponse =
      request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.approved.value ||
      request?.STATUS === OPEN_SHIFT_APPROVAL_TYPES.denied.value;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        label={this.renderHeader()}
        buttonLabel={actionableRequest && "Approve"}
        buttonOnClick={() => this.approveRequest(true)}
        deleteLabel={actionableRequest && "Reject"}
        deleteOnClick={() => this.approveRequest(false)}
      >
        <div className="text-sm">
          <div>
            <div className="block text-sm font-medium text-gray-700">
              Shift Role
            </div>

            <div>
              <span className="font-medium">{request?.ROLE_NAME}</span>
            </div>
          </div>

          <div className="mt-3">
            <div className="block text-sm font-medium text-gray-700">
              Shift Details
            </div>

            <div className="font-medium">
              {" "}
              {request &&
                formatShiftString(request.DATE_START, request.DATE_END)}
            </div>
          </div>

          <div>
            <div className="block text-sm font-medium text-gray-700 mt-3">
              Requested On
            </div>
            <div>{moment(request?.DATE_CREATED).format("ddd, MMM D")}</div>
          </div>

          {actionableRequest && (
            <div>
              <div className="h-0.5 w-full my-10 bg-gray-300 mt-2" />
              <FormTextArea
                className="mt-3"
                label="Reviewer's Notes"
                placeholder="Example: Unfortunately, I cannot approve this request because..."
                onChange={(e) => this.setState({notes: e.target.value})}
              />
            </div>
          )}

          {reviewerResponse && (
            <div>
              {request?.REVIEWER_NAME && (
                <div>
                  <div className="block text-sm font-medium text-gray-700 mt-3">
                    Reviewed By
                  </div>

                  <div>{request?.REVIEWER_NAME}</div>
                </div>
              )}

              {request?.REVIEWER_NOTES && (
                <div>
                  <div className="block text-sm font-medium text-gray-700 mt-3">
                    Reviewer Notes
                  </div>

                  <div>{request?.REVIEWER_NOTES}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default OpenShiftApprovalModal;
