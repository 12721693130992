import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import SubscriptionListDetailsModal from "../../../../modals/marketing/subscription-lists/subscription-list-details-modal";

class SubscriptionListsPage extends Component {
  state = {isLoading: true, lists: []};

  componentDidMount() {
    this.setState({isLoading: true});

    this.syncState();
  }

  async syncState() {
    const lists = await request("text/marketing/lists", "GET");

    this.setState({lists, isLoading: false});
  }

  renderBody() {
    const {lists} = this.state;

    return (
      <>
        <SubscriptionListDetailsModal
          ref={(e) => (this.subscriptionModal = e)}
          onSubmit={this.syncState.bind(this)}
        />

        <PageHeadings
          label={"Subscriber Lists"}
          description={`Manage your subscriber lists for your text campaigns`}
          // breadcrumbs={[
          //   {label: "Marketing", url: "/"},
          //   {label: "Campaigns Page", url: "/campaigns"},
          //   {label: "Retention Campaigns Page", url: "/campaigns/retention"},
          // ]}
        />

        <Card
          label={"Patron Lists"}
          description={
            "Lists of subscribers that you can send text campaigns to"
          }
          tooltip={{
            data: [
              {
                data: "Subscriber Lists allow you to send messages to a subset of your subscribed patrons.",
                label: "Subscriber Lists",
              },
              {
                data:
                  "Your default list will contain all of your patrons who have manually subscribed to text" +
                  " marketing.",
                label: "Default List",
              },
              {
                data:
                  "Additional lists can contain some or all of the subscribed patrons from the default list. Patrons" +
                  " can be on more than one additional list.",
                label: "Additional Lists",
              },
            ],
          }}
          buttons={[
            {
              label: "Create List",
              onClick: () => this.subscriptionModal.open(),
            },
          ]}
        >
          <Table
            columns={[
              {label: "Name", value: "NAME"},
              {
                label: "Default",
                value: "IS_DEFAULT",
                format: (isDefault) =>
                  isDefault ? (
                    <SuccessBadge>Default</SuccessBadge>
                  ) : (
                    <DangerBadge yellow>Additional</DangerBadge>
                  ),
              },
              {
                label: "Number of Patrons",
                value: "PATRON_COUNT",
              },
            ]}
            data={lists}
            actionButtons={[
              {
                label: "View",
                onClick: (row) =>
                  this.props.router.navigate("/subscriber-list/" + row.ID),
              },
            ]}
            pagination
          />
        </Card>
      </>
    );
  }

  render() {
    const {isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return this.renderBody();
  }
}

export default withRouter(SubscriptionListsPage);
