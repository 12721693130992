import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormRow from "../../../../../components/form-row";
import FormDateSpecify from "../../../../../components/form-date-specify";

class DocumentVerificationForm extends Component {
  render() {
    const {formikOptions} = this.props;

    return (
      <form>
        <a
          className="text-sm font-medium text-indigo-600 hover:text-indigo-800 hover:cursor-pointer mt-2"
          href="https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents"
          target="_blank"
        >
          List of acceptable documents
        </a>

        <FormSelect
          flex
          label={"Document Type"}
          name={"documentType"}
          options={formikOptions}
          data={[
            {label: "Document from List A", value: "A"},
            {
              label: "One Document from List B and One Document from List C",
              value: "BC",
            },
          ]}
        />

        {formikOptions.values.documentType === "A" && (
          <div>
            <div className={"mt-2 block text-sm font-medium text-gray-700"}>
              <FontAwesomeIcon
                icon="fa-regular fa-file-contract"
                className={"mr-1"}
              />
              Document from List A Details
            </div>

            <FormRow>
              <FormInput
                name={"listADocumentTitle"}
                label={"Document Title"}
                flex
                options={formikOptions}
              />

              <FormInput
                name={"listADocumentIssuingAuthority"}
                label={"Document Issuing Authority"}
                options={formikOptions}
                flex
              />

              <FormInput
                name={"listADocumentNumber"}
                label={"Document Number"}
                flex
                options={formikOptions}
              />

              <FormDateSpecify
                name={"listADocumentExpirationDate"}
                label={"Expiration Date (if any)"}
                options={formikOptions}
                mdy
                flex
              />
            </FormRow>
          </div>
        )}

        {formikOptions.values.documentType === "BC" && (
          <div>
            <div className={"mt-2 block text-sm font-medium text-gray-700"}>
              <FontAwesomeIcon
                icon="fa-regular fa-file-contract"
                className={"mr-1"}
              />
              Document from List B Details
            </div>

            <FormRow>
              <FormInput
                name={"listBDocumentTitle"}
                label={"Document Title"}
                flex
                options={formikOptions}
              />

              <FormInput
                name={"listBDocumentIssuingAuthority"}
                label={"Document Issuing Authority"}
                options={formikOptions}
                flex
              />

              <FormInput
                name={"listBDocumentNumber"}
                label={"Document Number"}
                flex
                options={formikOptions}
              />

              <FormDateSpecify
                name={"listBDocumentExpirationDate"}
                label={"Expiration Date (if any)"}
                options={formikOptions}
                mdy
                flex
              />
            </FormRow>

            <div className={"mt-2 block text-sm font-medium text-gray-700"}>
              <FontAwesomeIcon
                icon="fa-regular fa-file-contract"
                className={"mr-1"}
              />
              Document from List C Details
            </div>

            <FormRow>
              <FormInput
                name={"listCDocumentTitle"}
                label={"Document Title"}
                flex
                options={formikOptions}
              />

              <FormInput
                name={"listCDocumentIssuingAuthority"}
                label={"Document Issuing Authority"}
                options={formikOptions}
                flex
              />

              <FormInput
                name={"listCDocumentNumber"}
                label={"Document Number"}
                flex
                options={formikOptions}
              />

              <FormDateSpecify
                name={"listCDocumentExpirationDate"}
                label={"Expiration Date (if any)"}
                options={formikOptions}
                mdy
                flex
              />
            </FormRow>
          </div>
        )}
      </form>
    );
  }
}

export default DocumentVerificationForm;
