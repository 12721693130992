import React, {Component} from "react";
import * as Yup from "yup";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDaySelect from "../../../components/form-day-select";
import TimeSelect from "../../../components/form-elements/time-select";
import {Formik} from "formik";
import {showConfirmAlert} from "../../../utils/alert-helper";
import {request} from "../../../utils/request";

class CategorySchedulingModal extends Component {
  state = {availability: null};

  open(availability = null) {
    this.setState({availability}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async deleteCategoryAvailability() {
    const {availability} = this.state;

    showConfirmAlert(
      "Archive this Availability",
      "Are you sure you wanted to delete this category availability"
    ).then(async () => {
      await request("category/" + availability.ID + "/schedule", "DELETE");
      await this.props.syncState();
      this.modal.close();
    });
  }

  async addCategoryAvailability({start, end, type, day}) {
    const {category} = this.props;

    start = parseInt(start) * 60 * 1000;
    end = parseInt(end) * 60 * 1000;
    day = parseInt(day);

    await request("category/" + category.ID + "/schedule", "POST", {
      DATE_OPEN: start ?? "0",
      DATE_CLOSE: end ?? "0",
      ISO_DAY: day,
    });

    await this.props.syncState();
    this.modal.close();
  }

  async saveCategoryAvailability({start, end, type, day}) {
    const {availability} = this.state;

    start = parseInt(start) * 60 * 1000;
    end = parseInt(end) * 60 * 1000;
    day = parseInt(day);

    let payload = {
      DATE_OPEN: start,
      DATE_CLOSE: end,
      ISO_DAY: day,
    };

    await request(
      "category/" + availability.ID + "/schedule",
      "PATCH",
      payload
    );

    await this.props.syncState();
    this.modal.close();
  }

  parseAvailability(availability) {
    if (!availability) {
      return {dateStart: null, dateEnd: null, isoDay: null, type: null};
    }

    let {
      DATE_OPEN: dateStart,
      DATE_CLOSE: dateEnd,
      ISO_DAY: isoDay,
      TYPE: type,
    } = availability;

    dateStart = dateStart / 1000 / 60 + "";
    dateEnd = dateEnd / 1000 / 60 + "";
    isoDay = isoDay + "";

    return {dateStart, dateEnd, isoDay, type};
  }

  render() {
    const {availability} = this.state;

    const {dateStart, dateEnd, isoDay, type} =
      this.parseAvailability(availability);

    return (
      <Modal
        deleteOnClick={() => this.deleteCategoryAvailability()}
        deleteLabel={availability && "Archive"}
        buttonLabel={availability ? "Save" : "Add"}
        label={
          availability
            ? "Edit Category Availability"
            : "Add Category Availability"
        }
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            availability
              ? (values) => this.saveCategoryAvailability(values)
              : (values) => this.addCategoryAvailability(values)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            type: type ?? "RANGE",
            start: dateStart ?? "0",
            end: dateEnd ?? "0",
            day: isoDay,
          }}
          validationSchema={Yup.object({
            type: Yup.string().nullable().required(),
            start: Yup.string()
              .nullable()
              .required("Please select a start date or time."),
            end: Yup.string()
              .nullable()
              .required("Please select an end date or time."),
            day: Yup.string()
              .nullable()
              .test(
                "valid day",
                "Please choose a day.",
                (value, ctx) =>
                  ctx.parent.type !== "RECUR" ||
                  (value !== null && value !== undefined)
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const {type} = values;

            return (
              <form onSubmit={handleSubmit}>
                <div className={"flex flex-col"}>
                  <FormDaySelect
                    options={formikOptions}
                    hint="Optional"
                    tooltip={{
                      label: "Category Day",
                      data: "The day of the week for which the category will be scheduled. If blank, the category will be scheduled for all days.",
                    }}
                    name={"day"}
                    label={"Day of the Week"}
                    className={"my-3"}
                    iso
                  />

                  <div>
                    <div className={"flex flex-col mb-2"}>
                      <div className={"text-sm font-medium text-gray-700"}>
                        Start
                      </div>

                      <TimeSelect
                        options={formikOptions}
                        name={"start"}
                        label={"Start Time"}
                        className={"w-full mr-2"}
                      />
                    </div>

                    <div className={"flex flex-col"}>
                      <div className={"text-sm font-medium text-gray-700"}>
                        End
                      </div>

                      <TimeSelect
                        options={formikOptions}
                        name={"end"}
                        label={"End Time"}
                        className={"w-full"}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CategorySchedulingModal;
