import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {setupReduxConnection} from "../../../redux";
import {WEIGHT_UNITS} from "../../../utils/constants";

class PackageModal extends Component {
  state = {pack: null};

  open(pack = null) {
    this.setState({pack}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createPackage({unit, weight, box}) {
    const {boxes} = this.props;
    const boxObject = boxes.find((item) => {
      return item.ID === box;
    });

    const boxPayload = {
      id: Math.round(Math.random() * 10000),
      shippingBoxId: boxObject.ID,
      name: boxObject.NAME,
      weight: {
        value: weight,
        unit: unit,
      },
      dimensions: {
        height: boxObject.HEIGHT,
        width: boxObject.WIDTH,
        length: boxObject.LENGTH,
        unit: "inch",
      },
    };

    this.props.addState(boxPayload);
    this.modal.close();
  }

  async savePackage({unit, weight, box}) {
    const {pack} = this.state;
    const {boxes} = this.props;
    const boxObject = boxes.find((item) => {
      return item.ID === box;
    });

    const boxPayload = {
      id: pack.id,
      shippingBoxId: boxObject.ID,
      name: boxObject.NAME,
      weight: {
        value: weight,
        unit: unit,
      },
      dimensions: {
        height: boxObject.HEIGHT,
        width: boxObject.WIDTH,
        length: boxObject.LENGTH,
        unit: "inch",
      },
    };

    this.props.updateState(boxPayload.id, boxPayload);
    this.modal.close();
  }

  async removePackage() {
    const {pack} = this.state;

    this.props.updateState(pack.id);
    this.modal.close();
  }

  render() {
    const {boxes} = this.props;
    const {pack} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={pack ? "Save" : "Add"}
        label={pack ? "Edit Package" : "Create Package"}
        deleteOnClick={() => this.removePackage()}
        formikOnClick={() => this.formikRef}
        deleteLabel={pack && "Delete"}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            weight: pack?.weight?.value ?? "",
            unit: pack?.weight?.unit ?? WEIGHT_UNITS[0].value,
            box: pack?.shippingBoxId ?? boxes[0]?.ID,
          }}
          validationSchema={Yup.object({
            box: Yup.string().required("Box is required"),
            unit: Yup.string().required("Unit is required"),
            weight: Yup.string().required("Weight is required"),
          })}
          onSubmit={
            pack ? this.savePackage.bind(this) : this.createPackage.bind(this)
          }
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="weight"
                  options={formikOptions}
                  label="Weight"
                  placeholder="5.3"
                />

                <FormSelect
                  name="unit"
                  options={formikOptions}
                  label="Weight Unit"
                  data={WEIGHT_UNITS}
                />

                <FormSelect
                  name="box"
                  options={formikOptions}
                  label="Boxes"
                  data={boxes.map((item) => {
                    return {
                      label: `${item.NAME} -- L: ${item.LENGTH} W: ${item.WIDTH}  H: ${item.HEIGHT}`,
                      value: item.ID,
                    };
                  })}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(PackageModal);
