import React, {Component} from "react";
import {
  Filter,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import PatronDetailsModal from "../../../modals/marketing/patron-details-modal";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import ImportPatronsModal from "../../../modals/marketing/import/import-patrons-modal";

class PatronsPage extends Component {
  render() {
    const {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;

    return (
      <>
        <PageHeadings
          label={"Patrons"}
          description={"View all of the patrons at your location"}
          buttons={[
            {
              label: "Import Patrons",
              onClick: () => this.importModal.open(),
            },
            {
              label: "Add Patron",
              onClick: () => this.patronModal.open(),
            },
          ]}
        />

        <PatronDetailsModal
          ref={(e) => (this.patronModal = e)}
          updateState={(e) =>
            this.props.router.navigate("/patron/" + e.UNIQUE_ID)
          }
        />

        <ImportPatronsModal ref={(e) => (this.importModal = e)} />

        <Filter
          searchParams
          downloadable
          searchable
          csv={{
            route: "patrons/dumb",
            file: "patrons",
            headers: [
              {label: "Name", key: "FULL_NAME"},
              {label: "Phone", key: "PHONE"},
              {label: "Email", key: "EMAIL"},
              {label: "Average Ticket", key: "AVERAGE_TICKET", type: "dollars"},
              {
                label: "Last Seen",
                key: "LAST_SEEN",
                type: "date",
              },
              {
                label: "Current Points",
                key: "POINTS",
              },
              {
                label: "Total Spend",
                key: "TOTAL_SPEND",
                type: "dollars",
              },
              {
                label: "Total Tips",
                key: "TOTAL_TIPS",
                type: "dollars",
              },
              {
                label: "Average Ticket",
                key: "AVERAGE_TICKET",
                type: "dollars",
              },
              {
                label: "Average Tip",
                key: "AVERAGE_TIP",
                type: "dollars",
              },
              {
                label: "Total Tickets",
                key: "TICKETS",
              },
            ],
          }}
        >
          {(_, search) => {
            return (
              <Table
                sortable
                searchParams
                search={search}
                route="patrons/dumb"
                actionButtons={[
                  {
                    label: "View",
                    onClick: (ticket) =>
                      this.props.router.navigate("/patron/" + ticket.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    minWidth: 250,
                    label: "Name",
                    value: "FULL_NAME",
                    format: (_, row) => (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getObjectImage(row, "LOGO", "appicon.png")}
                            alt=""
                          />
                        </div>

                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {row.FULL_NAME}
                          </div>

                          <div className="text-sm text-gray-500">
                            {getFormattedPhone(row.PHONE)}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    label: "Last Seen",
                    value: "LAST_SEEN",
                    format: (seen, _) =>
                      seen ? moment(seen).format("MM/D/yy") : "-",
                  },
                  {
                    label: `Current ${LOYALTY_POINTS_VERBIAGE}`,
                    value: "POINTS",
                  },
                  {
                    label: "Total Spend",
                    value: "TOTAL_SPEND",
                    type: "dollars",
                  },
                  {
                    label: "Total Tips",
                    value: "TOTAL_TIPS",
                    type: "dollars",
                  },
                  {
                    label: "Average Ticket",
                    value: "AVERAGE_TICKET",
                    type: "dollars",
                  },
                  {
                    label: "Average Tip",
                    value: "AVERAGE_TIP",
                    type: "dollars",
                  },
                  {
                    label: "Total Tickets",
                    value: "TICKETS",
                  },
                ]}
                pagination
              />
            );
          }}
        </Filter>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PatronsPage));
