import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import moment from "moment";
import PayrollContractorPaymentModal from "../../../../modals/payroll/payroll-contractor-payment-modal";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../../../components/loading-spinner";

class ContractorsChoosePaymentsComponent extends Component {
  state = {breakdown: null};

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let breakdown = await request(
      "payroll/contractor-payroll/breakdown",
      "GET"
    );

    this.setState({breakdown});
  };

  handleClick = () => {
    return true;
  }

  render() {
    let {breakdown} = this.state;

    let columns = [
      {
        label: "Name",
        format: (value, row) => {
          return `${row.CONTRACTOR.first_name} ${row.CONTRACTOR.last_name}`;
        },
      },
      {
        label: "",
        width: 1,
        format: (value, row) => {
          if (row.PAYMENT) {
            return (
              <div
                className={"text-indigo-500 font-semibold cursor-pointer"}
                onClick={() => {
                  this.contractorPaymentModal.open(row);
                }}
              >
                {row.PAYMENT.payment_method === "direct_deposit"
                  ? "Direct Deposit"
                  : "Manual"}
              </div>
            );
          }
        },
      },
      {
        label: "Total",
        format: (value, row) => {
          if (!row.PAYMENT) {
            return (
              <div
                className={"text-indigo-500 font-semibold cursor-pointer"}
                onClick={() => {
                  this.contractorPaymentModal.open(row);
                }}
              >
                + Enter Payment
              </div>
            );
          }

          return (
            <div
              onClick={() => {
                this.contractorPaymentModal.open(row);
              }}
            >
              {decimalToDollars(row.PAYMENT.amount) > 0 && (
                <div className={"text-indigo-500 font-semibold cursor-pointer"}>
                  Amount: ${row.PAYMENT.amount}
                </div>
              )}

              {decimalToDollars(row.PAYMENT.reimbursement_amount) > 0 && (
                <div className={"text-indigo-500 font-semibold cursor-pointer"}>
                  Reimbursement: ${row.PAYMENT.reimbursement_amount}
                </div>
              )}
            </div>
          );
        },
        width: 1,
      },
    ];

    if(!breakdown){
      return <LoadingSpinner />
    }

    return (
      <div>
        <PayrollContractorPaymentModal
          ref={(e) => (this.contractorPaymentModal = e)}
          refresh={this.refresh}
        />

        <Table data={breakdown} columns={columns} />
      </div>
    );
  }
}

export default ContractorsChoosePaymentsComponent;
