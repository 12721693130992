import React, {Component} from "react";
import {
  FormInput,
  FormEmail,
  FormPhone,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import FormStateSelect from "../../../components/form-state-select";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {withRouter} from "../../../utils/navigation";
import FranchiseDropdown from "../../../dropdowns/admin/franchise-dropdown";

class LocationModal extends Component {
  state = {location: null};

  open(location = null) {
    this.setState({location}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createLocation({
    name,
    franchise,
    company,
    city,
    address,
    state,
    zipcode,
    latitude,
    longitude,
  }) {
    const serverLocation = await request("admin/dashboard/location", "POST", {
      NAME: name,
      TAX_RATE: 0,
      COMPANY_ID: company,
      FRANCHISE_ID: franchise,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zipcode,
      LATITUDE: latitude,
      LONGITUDE: longitude,
    });

    this.props.router.navigate("/admin/location/" + serverLocation.UNIQUE_ID);
  }

  async saveLocation({first, last, phone, email, company, notes}) {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts/" + contact.ID, "PATCH", {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      COMPANY: company,
    });

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {location} = this.state;

    return (
      <Modal
        buttonLabel={location ? "Save" : "Add"}
        label={location ? "Edit Location" : "Create Location"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        large
      >
        <Formik
          onSubmit={
            location
              ? this.saveLocation.bind(this)
              : this.createLocation.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            company: Yup.number()
              .typeError("Company is required")
              .required("Company is required"),
            franchise: Yup.number()
              .typeError("Franchise is required")
              .required("Franchise is required"),
            address: Yup.string().required("Address is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zipcode: Yup.string().required("Zipcode is required"),
            latitude: Yup.string().required("Latitude is required"),
            longitude: Yup.string().required("Longitude is required"),
          })}
          initialValues={{
            name: location?.NAME ?? "",
            company: location?.COMPANY_ID ?? null,
            franchise: location?.FRANCHISE_ID ?? null,
            address: location?.ADDRESS ?? "",
            city: location?.CITY ?? "",
            zipcode: location?.ZIPCODE ?? "",
            state: location?.STATE ?? "",
            latitude: location?.LATITUDE ?? "",
            longitude: location?.LONGITUDE ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Avery's Cool Location"
                  label="Name"
                  name="name"
                />

                <FormRow>
                  <CompanyDropdown
                    flex
                    name="company"
                    options={formikOptions}
                    buttonHint={{
                      label: "Create New",
                      onClick: () =>
                        this.props.router.navigate("/admin/companies"),
                    }}
                  />

                  <FranchiseDropdown
                    flex
                    name="franchise"
                    options={formikOptions}
                    buttonHint={{
                      label: "Create New",
                      onClick: () =>
                        this.props.router.navigate("/admin/franchises"),
                    }}
                  />
                </FormRow>

                <FormInput
                  name="address"
                  label="Address"
                  options={formikOptions}
                />

                <FormRow>
                  <FormInput name="city" label="City" options={formikOptions} />

                  <FormStateSelect
                    name="state"
                    label="State"
                    options={formikOptions}
                    flex={1}
                  />

                  <FormInput
                    name="zipcode"
                    label="Zipcode"
                    options={formikOptions}
                    flex={1}
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    name="latitude"
                    label="Latitude"
                    options={formikOptions}
                    flex
                  />

                  <FormInput
                    name="longitude"
                    label="Longitude"
                    options={formikOptions}
                    flex
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(LocationModal);
