import React, {Component} from "react";
import {PageHeadings, Tab, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import {getCheckContractorOnboardingLink, getPayrollEmployeeOnboardingLink} from "../../../../utils/payroll-helper";

class PayrollContractorLandingPage extends Component {
  state={contractor: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let contractor = await request("payroll/contractors/"+ID, "GET");

    this.setState({contractor});
  }

  openHosted(){
    const {ID} = this.props.router.params;

    getCheckContractorOnboardingLink(ID).then((res) => {
      const handler = window.Check.create({
        link: res.url,
        onClose: this.onClose,
      });
      handler.open();
    });
  }

  render() {
    let {contractor} = this.state;

    let tabs = [
      {id: "info", label: "Personal Information"},
      // {id: "payments", label: "Paystubs"},
    ];

    return (
      <div className="p-6">
        <PageHeadings label={`Your Payroll Portal`} />

        <Tab data={tabs}>
          {(id) => {
            return <div>
              {contractor?.onboard.status === "blocking" && (
                <div role="alert">
                  <div className="mt-3 bg-red-500 text-white font-bold px-4 py-2">
                    Incomplete
                  </div>
                  <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
                    <p>
                      Please click on "Open Bank & Legal Information" and
                      fill out the required information in order to get paid!
                    </p>
                  </div>
                </div>
              )}

            <TwoColumnList
              label={"Personal Information"}
              buttons={[
                // {
                //   label: "Edit",
                //   onClick: () => this.editContractorModal.open(),
                // },
                {
                  label: "Open Bank & Legal Information",
                  onClick: () => this.openHosted()
                }
              ]}
              data={[
                {
                  label: "Name",
                  value: `${contractor?.first_name} ${contractor?.last_name}`,
                },
                {
                  label: "Date of Birth",
                  value: contractor?.dob ?? "None",
                },
                {
                  label: "Address",
                  value: `${contractor?.address.line1}${
                    contractor?.address.line2
                      ? contractor?.address.line1
                      : ""
                  }, ${contractor?.address.city}, ${
                    contractor?.address.state
                  } ${contractor?.address.postal_code}`,
                }
              ]}
            /></div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(PayrollContractorLandingPage);
