import React, {Component} from "react";
import {
  Table,
  Tab,
  PageHeadings,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import moment from "moment";
import LoadingSpinner from "../../components/loading-spinner";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import GrayBadge from "../../components/badges/gray-badge";
import WarningBadge from "../../components/badges/warning-badge";
import PayrollSuccessModal from "../../modals/payroll/payroll-success-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImportTab from "./import-tab";
import BillingTab from "./billing-tab";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import PayrollRunErrorModal from "../../modals/payroll/terminal/payroll-run-error-modal";
import OffCyclesTab from "./off-cycles-tab";
import VoidTab from "./void-tab";

function OffCycleTab() {
  return null;
}

class PayrollSuccessPage extends Component {
  state = {
    holidayPaydays: null,
    companies: null,
    totalProcessed: null,
    notion: null,
    allTimeByDay: null,
    biweekly: null,
    startDates: null,
    payruns: null,
  };

  async componentDidMount() {
    this.refreshHolidayPaydays();
    this.refreshCompanies();
    this.refreshNotionStatus();
    this.refreshProcessed();
    this.refreshPayruns();
  }

  refreshHolidayPaydays = async () => {
    let holidayPaydays = await request("admin/holiday-paydays", "GET");
    this.setState({holidayPaydays});
  };

  refreshCompanies = async () => {
    let companies = await request("admin/check-companies", "GET");
    this.setState({companies});
  };

  refreshProcessed = async () => {
    let {TOTAL_PROCESSED, ALL_TIME_BY_DAY, BIWEEKLY, START_DATES} =
      await request("admin/dashboard/payroll/processed", "GET");
    this.setState({
      totalProcessed: TOTAL_PROCESSED,
      allTimeByDay: ALL_TIME_BY_DAY,
      biweekly: BIWEEKLY,
      startDates: START_DATES,
    });
  };

  refreshNotionStatus = async () => {
    let notion = await request("admin/dashboard/payroll-log");

    this.setState({notion});
  };

  refreshPayruns = async () => {
    let payruns = await request("admin/dashboard/payruns");

    this.setState({payruns});
  };

  render() {
    let tabs = [
      {id: "onboarding", label: "Locations"},
      {id: "off-cycle", label: "Off-Cycles"},
      {id: "billing", label: "Billing"},
      {id: "holiday", label: "Holiday Paydays"},
      {id: "void", label: "Void"},
      {id: "import", label: "Import"},
      {id: "graphs", label: "Graphs"},
    ];

    let {
      companies,
      totalProcessed,
      allTimeByDay,
      notion,
      biweekly,
      startDates,
      payruns,
      billing,
    } = this.state;

    let isLocationCompleted = function (company) {
      return (
        company.implementation?.status === "completed" &&
        payruns && payruns[company.id]
      );
    };

    let isLocationOnboarding = function (company) {
      let daysAway = moment(company.start_date).diff(moment(), "days");

      return -1 < daysAway;
    };

    let actionButtons = [
      {
        label: "Edit",
        onClick: (row) => {
          this.payrollOnboardLocationModal.open(row.location_id);
        },
      },
      {
        label: (
          <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
        ),
        onClick: (row) => {
          window.open(
            `https://console.checkhq.com/companies/` + row.id,
            "_blank"
          );
        },
      },
    ];

    let implementationColumn = {
      label: "Implementation",
      format: (value, row) => {
        if (row?.implementation?.status === "completed")
          return (
            <SuccessBadge>
              <FontAwesomeIcon icon={"check"} className="h-3 w-3" />
            </SuccessBadge>
          );
        if (row?.implementation?.status === "completed") {
          return <SuccessBadge>Live</SuccessBadge>;
        } else if (row?.implementation?.status === "in_review") {
          return <WarningBadge>In Review</WarningBadge>;
        }

        return <GrayBadge>Incomplete</GrayBadge>;
      },
      width: 1,
    };

    let locationInformation = {
      label: "Location Information",
      value: "onboard",
      format: (value, row) => {
        if (value.status === "completed") {
          return <SuccessBadge>
            <FontAwesomeIcon icon="fa-solid fa-check" />
          </SuccessBadge>;
        }

        return <DangerBadge>
          <FontAwesomeIcon icon="fa-solid fa-x" />
        </DangerBadge>;
      },
      width: 1,
    };

    let processingColumn = {
      label: "Processing",
      value: "processing_period",
      format: (value, row) => {
        if (value === "two_day") {
          return <SuccessBadge>Two Day</SuccessBadge>;
        }
        return <GrayBadge>Four Day</GrayBadge>;
      },
      width: 1,
    };

    let liveColumn = {
      label: "Live",
      value: "processing_period",
      format: (value, row) => {
        if (row?.implementation?.status === "completed") {
          return (
            <SuccessBadge>
              <FontAwesomeIcon icon="check" />
            </SuccessBadge>
          );
        }

        return (
          <DangerBadge>
            <FontAwesomeIcon icon="check" />
          </DangerBadge>
        );
      },
      width: 1,
    };

    let startDateColumn = {
      label: "Start Date",
      value: "start_date",
      format: (value, row) => {
        if (!value) {
          return "None";
        }

        return (
          <div className={"w-28"}>{`${moment(value).format("M/D")} ${
            !isLocationCompleted(row)
              ? `(In ${moment(value)
                .startOf("day")
                .diff(moment().startOf("day"), "days")} Days)`
              : ""
          }`}</div>
        );
      },
      width: 1,
    };

    let currentPayrunColumns = [
      {
        label: "Period End",
        width: 1,
        format: (value, row) => {
          let {PERIOD_END} = payruns[row.id];
          return moment(PERIOD_END).format("M/D");
        },
      },
      {
        label: "Approval Deadline",
        width: 1,
        format: (value, row) => {
          let {APPROVAL_DEADLINE} = payruns[row.id];
          return moment(APPROVAL_DEADLINE).format("M/D");
        },
      },
      {
        label: "Payday",
        width: 1,
        format: (value, row) => {
          let {PAYDAY} = payruns[row.id];
          return moment(PAYDAY).format("M/D");
        },
      },
      {
        label: "Status",
        width: 1,
        format: (value, row) => {
          let {PAYROLL_STATUS, RUN_ERROR, RUN_ERRORS} = payruns[row.id];

          if (PAYROLL_STATUS === "period_open") {
            return <GrayBadge>Period Open</GrayBadge>;
          }

          if (PAYROLL_STATUS === "needs_run") {
            return <WarningBadge>Needs Run</WarningBadge>;
          }

          if (PAYROLL_STATUS === "missed") {
            return <DangerBadge>Late</DangerBadge>;
          }

          if(!RUN_ERROR){
            return <SuccessBadge>Ran</SuccessBadge>;
          }

          return <div onClick={() => {
            this.payrollRunErrorModal.open(RUN_ERRORS)
          }}>
            <DangerBadge>Ran</DangerBadge>
          </div>;
        },
      },
    ];

    let columns = [
      {
        label: "Location",
        value: "location_name",
        format: (value, row) => {
          return <div>{`${value} ${row.other_running ? `(${row.other_running})`: ""}`}</div>
        }
      },
      {
        label: "Tip Type",
        value: "PAYROLL_PAYCHECK_TIPS",
        format: (value, row) => {
          return (
            <div className={"w-12"}>
              {row.PAYROLL_PAY_CALCULATED_TIPS === "0" && "None"}

              {row.PAYROLL_PAY_CALCULATED_TIPS === "1" &&
                value === "1" &&
                "Paycheck"}

              {row.PAYROLL_PAY_CALCULATED_TIPS === "1" &&
                value === "0" &&
                "Cash"}
            </div>
          );

          return "Cash";
        },
        width: 1,
      },
    ];

    let onboardingColumns = [...columns];
    onboardingColumns.splice(1, 0, locationInformation);
    onboardingColumns.splice(2, 0, processingColumn);
    onboardingColumns.splice(3, 0, implementationColumn);
    onboardingColumns.splice(4, 0, startDateColumn);

    let liveColumns = [...columns];
    liveColumns.splice(1, 0, liveColumn);
    liveColumns.splice(1, 0, ...currentPayrunColumns);

    let incompleteColumns = [...columns];
    incompleteColumns.splice(1, 0, startDateColumn);

    let notionColumns = [
      {
        label: "Location",
        value: "LOCATION_NAME",
        width: 1,
      },
      {
        label: "Title",
        value: "TITLE",
      },
    ];

    let notionActionButtons = [
      {
        label: (
          <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
        ),
        onClick: (row) => {
          window.open(row.URL, "_blank");
        },
      },
    ];

    return (
      <div className={"p-5 px-10"}>
        <div className={"flex flex-row text-bottom justify-between"}>
          <PageHeadings label={"Payroll Terminal"} />

          <div className={"flex flex-row space-x-4"}>
            <div className={"flex flex-col text-right"}>
              <div className="text-xl font-semibold">
                {toDollars(totalProcessed, true)}
              </div>
              <div className="font-bold text-sm">Total Processed</div>
            </div>
          </div>
        </div>

        <PayrollSuccessModal
          refesh={() => this.refreshCompanies()}
          ref={(e) => (this.payrollOnboardLocationModal = e)}
        />

        <PayrollRunErrorModal
          ref={(e) => (this.payrollRunErrorModal = e)}
        />

        <Tab data={tabs}>
          {(id) => {
            if(id === "import"){
              return <ImportTab />
            }

            if (id === "billing") {
              return <BillingTab />;
            }

            if(id === "off-cycle"){
              return <OffCyclesTab />;
            }

            if(id === "void"){
              return <VoidTab />;
            }

            if (id === "onboarding") {
              let incompleteCompanies = companies?.filter(
                (company) =>
                  !isLocationCompleted(company) &&
                  !isLocationOnboarding(company)
              );

              let completeCompanies = companies
                ?.filter((company) => isLocationCompleted(company))
                .sort((a, b) => {
                  let {APPROVAL_DEADLINE: aApprDeadline} = payruns[a.id];
                  let {APPROVAL_DEADLINE: bApprDeadline} = payruns[b.id];

                  let {PAYROLL_STATUS: aPayrollStatus} = payruns[a.id];
                  let {PAYROLL_STATUS: bPayrollStatus} = payruns[b.id];

                  if (
                    aPayrollStatus === "paid" ||
                    aPayrollStatus === "processing" ||
                    aPayrollStatus === "pending"
                  ) {
                    aApprDeadline = aApprDeadline * 10;
                  }

                  if (
                    bPayrollStatus === "paid" ||
                    bPayrollStatus === "processing" ||
                    bPayrollStatus === "pending"
                  ) {
                    bApprDeadline = bApprDeadline * 10;
                  }

                  return aApprDeadline - bApprDeadline;
                });

              let otherRun = completeCompanies?.reduce((sum, company) => (sum + (company.other_running ?? 0)), 0);

              incompleteCompanies = incompleteCompanies?.sort((a, b) => {
                let aValue = moment(a.start_date).valueOf();
                let bValue = moment(b.start_date).valueOf();

                if (!a.start_date) {
                  aValue = 10000000000000000000;
                }

                if (!b.start_date) {
                  bValue = 10000000000000000000;
                }

                return bValue - aValue;
              });

              let currentlyOnboardingCompanies = companies?.filter(
                (company) => {
                  return (
                    !isLocationCompleted(company) &&
                    isLocationOnboarding(company)
                  );
                }
              );

              return (
                <div className={"space-y-5 mt-5"}>
                  <div>
                    <div className={"text-lg font-semibold mb-2"}>
                      Currently Onboarding
                      <GrayBadge>
                        {currentlyOnboardingCompanies?.length}
                      </GrayBadge>
                    </div>

                    <Table
                      actionButtons={actionButtons}
                      columns={onboardingColumns}
                      data={currentlyOnboardingCompanies ?? null}
                    />
                  </div>

                  {/*<div>*/}
                  {/*  <div className={"text-lg font-semibold mb-2"}>*/}
                  {/*    Open Tickets*/}
                  {/*    <GrayBadge>{notion?.length}</GrayBadge>*/}
                  {/*  </div>*/}

                  {/*  <Table*/}
                  {/*    actionButtons={notionActionButtons}*/}
                  {/*    columns={notionColumns}*/}
                  {/*    data={notion}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  <div>
                    <div className={"text-lg font-semibold mb-2"}>
                      Live Locations
                      <GrayBadge>{completeCompanies?.length + otherRun}</GrayBadge>
                    </div>

                    <Table
                      actionButtons={actionButtons}
                      columns={liveColumns}
                      data={completeCompanies ?? null}
                    />
                  </div>

                  <div>
                    <div className={"text-lg font-semibold mb-2"}>
                      Incomplete Locations{" "}
                      <GrayBadge>{incompleteCompanies?.length}</GrayBadge>
                    </div>

                    <Table
                      actionButtons={actionButtons}
                      columns={incompleteColumns}
                      data={incompleteCompanies ?? null}
                    />
                  </div>
                </div>
              );
            }

            if (id === "holiday") {
              let {holidayPaydays} = this.state;

              if (!holidayPaydays) {
                return <LoadingSpinner />;
              }

              holidayPaydays.sort(
                (a, b) =>
                  moment(a.payday).valueOf() - moment(b.payday).valueOf()
              );

              holidayPaydays = holidayPaydays.filter(
                (payday) => moment(payday.payday).diff(moment(), "days") < 30
              );

              return (
                <Table
                  columns={[
                    {
                      label: "Location Name",
                      value: "locationName",
                    },
                    {
                      label: "Period End",
                      format: (value, row) => {
                        return `${moment(row.period_end).format("dddd")}`;
                      },
                      width: 1,
                    },
                    {
                      label: "Approval Deadline",
                      format: (value, row) => {
                        return `${moment(row.approval_deadline).format(
                          "dddd"
                        )}`;
                      },
                      width: 1,
                    },
                    {
                      label: "Payday",
                      format: (value, row) => {
                        return `${moment(row.payday).format("dddd M/D")}`;
                      },
                      width: 1,
                    },
                    // {
                    //   label: "Handled",
                    //   format: (value, row) => {
                    //     return
                    //   },
                    //   width: 1
                    // }
                  ]}
                  data={holidayPaydays}
                />
              );
            }

            if (id === "graphs") {
              if (!allTimeByDay) {
                return <LoadingSpinner />;
              }

              // let tickValues = allTimeByDay.map((item) => item.x);
              let lowestEpoch = allTimeByDay[0].x;
              let highestEpoch = allTimeByDay[allTimeByDay.length - 1].x;

              let monthStart = moment(lowestEpoch)
                .startOf("month")
                .add(1, "month");
              let tickValues = [];
              while (monthStart.valueOf() < highestEpoch) {
                tickValues.push(monthStart.valueOf());
                monthStart.add(1, "month");
              }

              return (
                <div className="flex flex-1 flex-col">
                  <div className={"flex flex-col m-auto mt-16"}>
                    <div className={"text-lg font-semibold mb-3"}>
                      By 2 Week Intervals
                    </div>

                    <FlexibleWidthXYPlot
                      className={"bg-white m-auto shadow-sm"}
                      margin={{left: 100, right: 30}}
                      height={600}
                      width={800}
                      xDomain={[lowestEpoch, highestEpoch]}
                    >
                      <HorizontalGridLines />

                      <VerticalBarSeries
                        color={"rgb(79 70 229)"}
                        data={biweekly}
                      />
                      <XAxis
                        tickFormat={(t) => {
                          if (t === lowestEpoch || t === highestEpoch) {
                            return moment(t).format("MMM");
                          }

                          return moment(t).format("MMM");
                        }}
                        tickValues={tickValues}
                      />

                      {/*<XAxis*/}
                      {/*  tickFormat={(t) => {*/}
                      {/*    if (t === lowestEpoch || t === highestEpoch) {*/}
                      {/*      return moment(t).format("MMM");*/}
                      {/*    }*/}

                      {/*    return <div className="border h-32"></div>;*/}
                      {/*  }}*/}
                      {/*  tickValues={startDates}*/}
                      {/*/>*/}

                      <YAxis
                        tickTotal={4}
                        tickFormat={(amount) => {
                          if (amount === 0) {
                            return;
                          }

                          return toDollars(amount, true);
                        }}
                      />
                    </FlexibleWidthXYPlot>
                  </div>
                </div>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

const TIP_CALCULATION_METHOD_TYPES = {
  TRANSACTION: "Trans",
  DAILY: "Day",
  WEEKLY: "Week",
  PAY_PERIOD: "Period",
};

export default PayrollSuccessPage;
