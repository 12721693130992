import React, {Component} from "react";
import DatePicker from "react-datepicker";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormElement from "../form/form-element";
import {createPortal} from "react-dom";

const CalendarContainer = ({children}) =>
  children
    ? createPortal(
        React.cloneElement(children, {
          className:
            "YOUR_DATEPICKER_CSS_CLASS_HERE react-datepicker-popper z-50",
        }),
        document.body
      )
    : null;

class FormDate extends Component {
  render() {
    const {
      name,
      placeholder = "Select a date",
      type = "text",
      disabled = false,
      showTime = false,
      options,
    } = this.props;

    return (
      <FormElement style={{width: 180}} {...this.props}>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <DatePicker
              calendarClassName={
                showTime && !this.props.showTimeSelectOnly
                  ? "calendar-with-time"
                  : ""
              }
              showTimeSelect={showTime}
              dateFormat={"Pp"}
              placeholderText={placeholder}
              popperClassName="ignore-click"
              className="ignore-click"
              popperContainer={CalendarContainer}
              withPortal={false} // Would be cool to use
              selected={value ? new Date(value) : null}
              popperModifiers={{
                flip: {
                  behavior: ["bottom"], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering
                  // the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
              popperProps={{
                positionFixed: true,
              }}
              onChange={(start) =>
                options
                  ? options.setFieldValue(name, !start ? null : start.getTime())
                  : onChange(start)
              }
              customInput={
                <input
                  id={name}
                  type={type}
                  name={name}
                  // value={moment(value).format("hh:mma MM/DD/YY")}
                  onBlur={onBlur}
                  onChange={() => {}}
                  disabled={disabled}
                  autoComplete="off"
                  placeholder={placeholder}
                  style={{width: 180}}
                  className={classNames(
                    disabled ? "text-neutral-text" : undefined,
                    error
                      ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                      : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                    "block w-full focus:outline-none sm:text-sm rounded-md",
                    ...extraStyle
                  )}
                  aria-describedby="email-error"
                  aria-invalid="true"
                />
              }
              {...this.props}
            />
          );
        }}
      </FormElement>
    );
  }
}

FormDate.propTypes = {
  ...FormElement.propTypes,
};

export default FormDate;
