import React, {Component} from "react";
import {
  PageHeadings,
  Card,
  Table,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {HEADER_MENU, SEARCHABLE_SIDE_MENUS} from "../settings/menu";
import Fuse from "fuse.js";
import {setupReduxConnection} from "../redux";
import algoliasearch from "algoliasearch/lite";
import {withRouter} from "../utils/navigation";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {request} from "../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class SearchPage extends Component {
  state = {client: null, options: null, finalOptions: [], search: ""};

  componentDidMount() {
    this.resetAlgolia();

    setTimeout(() => {
      this.loadSearch();
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.location !== this.props.router.location) {
      this.setState({options: null}, () => {
        this.loadSearch();
      });
    }
  }

  resetAlgolia() {
    const {location} = this.props.shop;

    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA,
      location.SEARCH_ID
    );

    this.setState({client});
  }

  loadSearch() {
    const {permissions: userPermissions} = this.props.user;
    const {location} = this.props.shop;
    const {client} = this.state;

    const url = new URL(window.location.href);

    if (!url.searchParams.has("query")) {
      return this.setState({options: null});
    }

    let search = url.searchParams.get("query");
    const items = [
      ...HEADER_MENU,
      ...SEARCHABLE_SIDE_MENUS.map((item) => {
        const {children} = item;

        return {
          ...item,
          routes: children
            ?.filter((item) => {
              return item.searchable;
            })
            .map((item) => {
              return {...item, url: item.href};
            }),
        };
      }),
    ].reduce((accum, item) => {
      if (item.routes) {
        return [
          ...accum,
          ...item.routes
            .filter((item) => {
              const {permissions} = item;

              if (!item.live && process.env.REACT_APP_ENV !== "development") {
                return false;
              }

              if (userPermissions.includes("ADMIN_FULL")) {
                return true;
              }

              if (permissions.length === 0) {
                return true;
              }

              const possiblePermissions = [...item.permissions, ...permissions];

              if (
                possiblePermissions.some((x) => userPermissions.includes(x))
              ) {
                return true;
              }

              return false;
            })
            .map((item) => ({...item, type: "route"})),
        ];
      }

      return [...accum, item];
    }, []);

    let menuItems = new Fuse(items, {
      keys: ["name", "label"],
      useExtendedSearch: true,
      threshold: 0.1,
    })
      .search(search)
      .map(({item}) => item);

    request("partner/search", "POST", {SEARCH: search, LIMIT: 100}).then(
      ({tickets, patrons, employees, products, roles, categories}) => {
        const finalOptions = [
          ...menuItems,
          ...employees.map((item) => {
            return {
              ...item,
              type: "employees",
              name: item.FULL_NAME,
            };
          }),
          ...categories.map((item) => {
            return {
              ...item,
              type: "categories",
              name: item.NAME,
            };
          }),
          ...roles.map((item) => {
            return {
              ...item,
              type: "roles",
              name: item.NAME,
            };
          }),
          ...patrons.map((item) => {
            return {
              ...item,
              type: "patrons",
              name: item.FULL_NAME,
            };
          }),
          ...products.map((item) => {
            return {
              ...item,
              name: item.NAME,
              type: "products",
              extra: item.CATEGORY_NAME,
            };
          }),
          ...tickets.map((item) => {
            return {
              ...item,
              type: "tickets",
              name: item.NAME,
              extra: "$" + toDollars(item.TOTAL),
            };
          }),
        ];

        const sortedOptions = {
          route: [],
          tickets: [],
          patrons: [],
          products: [],
          employees: [],
          categories: [],
          roles: [],
        };

        for (let option of finalOptions) {
          const sort = sortedOptions[option.type];

          if (sort) {
            sort.push(option);
          }
        }

        this.setState({
          options: sortedOptions,
          finalOptions,
          search,
        });
      }
    );
  }

  render() {
    const {client, options, search, finalOptions} = this.state;

    if (client === null || options === null) {
      return (
        <div className="p-4">
          <PageHeadings label="Search results" />

          <Loading />
        </div>
      );
    }

    if (finalOptions.length === 0) {
      return (
        <div className="p-4">
          <PageHeadings label="Search results" />

          <div className="min-h-full pt-16 pb-12 flex flex-col">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 flex justify-center">
                <div className="text-center">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No results
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    We could not find the results you were looking for.
                  </p>

                  <div className="mt-6">
                    <a
                      type="button"
                      target="_blank"
                      href="https://support.dripos.com/"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <QuestionMarkCircleIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      View Support Docs
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      );
    }

    const {products, tickets, employees, patrons, categories, roles, route} =
      options;

    return (
      <div className="p-4">
        <PageHeadings label={'Search results for "' + search + '"'} />

        <div className="mt-4">
          {route.length > 0 && (
            <Card label="Pages">
              <div className="px-4 py-2">
                {route.map((item) => (
                  <Link
                    to={item.url}
                    key={item.name}
                    className="p-3 flex items-start rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <FontAwesomeIcon icon={item.icon} className="h-6 w-6" />
                    </div>

                    <div className="ml-4">
                      <p className="text-base font-medium text-gray-900">
                        {item.name}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {item.description || "Page"}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </Card>
          )}

          {tickets.length > 0 && (
            <Card label="Tickets">
              <Table
                pagination
                data={tickets}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View Ticket",
                    onClick: (row) =>
                      this.props.router.navigate("/ticket/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                  },
                  {
                    width: 1,
                    value: "TOTAL",
                    label: "Total",
                    format: (total) => toDollars(total, true),
                  },
                  {
                    width: 1,
                    value: "DATE_CREATED",
                    label: "Date Placed",
                    format: (date) =>
                      moment(parseInt(date)).format("hh:mma MM/DD/YY"),
                  },
                ]}
              />
            </Card>
          )}

          {employees.length > 0 && (
            <Card label="Employees">
              <Table
                pagination
                data={employees}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigate("/employee/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                    format: (_, row) => (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getObjectImage(row, "LOGO", "appicon.png")}
                            alt=""
                          />
                        </div>

                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {row.name}
                          </div>

                          <div className="text-sm text-gray-500">
                            {row.EMPLOYEE_ROLE_NAME}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    value: "phone",
                    label: "Phone",
                    format: (item) => getFormattedPhone(item),
                  },
                  {
                    value: "email",
                    label: "Email",
                  },
                ]}
              />
            </Card>
          )}

          {products.length > 0 && (
            <Card label="Products">
              <Table
                pagination
                data={products}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigate("/product/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                  },
                ]}
              />
            </Card>
          )}

          {patrons.length > 0 && (
            <Card label="Patrons">
              <Table
                pagination
                data={patrons}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigate("/patron/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                  },
                ]}
              />
            </Card>
          )}

          {categories.length > 0 && (
            <Card label="Categories">
              <Table
                pagination
                data={categories}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigate("/category/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                  },
                ]}
              />
            </Card>
          )}

          {roles.length > 0 && (
            <Card label="Roles">
              <Table
                pagination
                data={roles}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigate("/role/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "name",
                    label: "Name",
                  },
                ]}
              />
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(withRouter(SearchPage));
