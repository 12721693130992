import React, {Component} from "react";
import {
  FormInput,
  FormEmail,
  FormPhone,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";

class ContactModal extends Component {
  state = {contact: null, name: null};

  open(contact = null, name = null) {
    this.setState({contact, name}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createContact({first, last, phone, email, notes, company}) {
    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts", "POST", {
      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,
      COMPANY: company,
    });

    this.props.addState(serverContact);
    this.modal.close();
  }

  async saveContact({first, last, phone, email, company, notes}) {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts/" + contact.ID, "PATCH", {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      COMPANY: company,
    });

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {contact, name} = this.state;

    return (
      <Modal
        buttonLabel={contact ? "Save" : "Add"}
        label={contact ? "Edit Contact" : "Create Contact"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            contact
              ? this.saveContact.bind(this)
              : this.createContact.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            first: Yup.string().required("First is required"),
            last: Yup.string().required("Last is required"),
            email: Yup.string().required("Email is required"),
            phone: Yup.string(),
          })}
          initialValues={{
            first: contact?.FIRST_NAME ?? name?.split(" ")[0],
            last: contact?.LAST_NAME ?? name?.split(" ")[1],
            email: contact?.EMAIL ?? "",
            phone: contact?.PHONE?.slice(1) ?? "",
            notes: contact?.NOTES ?? "",
            company: contact?.COMPANY ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    options={formikOptions}
                    placeholder="Donald"
                    label="First name"
                    name="first"
                    flex
                  />

                  <FormInput
                    options={formikOptions}
                    placeholder="Draper"
                    label="Last name"
                    name="last"
                    flex
                  />
                </FormRow>

                <FormEmail
                  options={formikOptions}
                  placeholder="don@gmail.com"
                  label="Email Address"
                  name="email"
                />

                <FormPhone
                  options={formikOptions}
                  label="Phone Number"
                  name="phone"
                  hint="optional"
                />

                <FormInput
                  options={formikOptions}
                  placeholder="Donald LLC"
                  label="Company"
                  hint="optional"
                  name="company"
                />

                <FormTextArea
                  options={formikOptions}
                  hint="optional"
                  label="Notes"
                  name="notes"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ContactModal;
