import React, {Component} from "react";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {selectModifierOption} from "../../utils/product-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Option extends Component {
  waiting = Date.now();
  state = {quantity: 1};

  componentDidMount() {
    const {quantity} = this.props;
    this.setState({quantity});
  }

  selectOption() {
    const {quantity} = this.state;
    const {product, modifier, option, selections, updateSelections} =
      this.props;

    updateSelections(
      selectModifierOption(product, modifier, selections, option, quantity)
    );
  }

  render() {
    const {modifier, option, active, variant, selections} = this.props;
    const {quantity} = this.state;

    let type =
      modifier.TYPE === 2 || modifier.TYPE === 3 || modifier.TYPE === 4
        ? "checkbox"
        : "radio";

    const isQuantityModifier = modifier.TYPE === 1 || modifier.TYPE === 3;

    let amount =
      variant && variant.MODIFIER && modifier.TYPE !== 5
        ? variant.MODIFIER * option.PRICE
        : option.PRICE;

    return (
      <div
        key={option.name}
        className="relative flex items-center h-10 w-full cursor-pointer"
        onClick={() => this.selectOption()}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={active ? "fa-solid fa-check-square" : "fa-regular fa-square"}
            solid={active}
          />
        </div>

        <div className="flex flex-row cursor-pointer items-center justify-between flex-1 border-b ml-2 py-2">
          <label
            htmlFor={option.name}
            className="font-medium text-black text-sm cursor-pointer"
          >
            {option.NAME}
          </label>

          <div className="inline-flex items-center">
            {isQuantityModifier && active && (
              <div className={"flex flex-row items-center mr-2"}>
                <div
                  className={"mr-2"}
                  onClick={(event) => {
                    event.stopPropagation();

                    if (quantity === 1) {
                      return this.selectOption();
                    }

                    this.setState({quantity: quantity - 1}, () => {
                      this.selectOption();
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={"minus-circle"}
                    className={"text-gray-300 hover:text-black transition-all"}
                  />
                </div>

                <div className={"text-sm font-bold"}>{quantity}</div>

                <button
                  className={"ml-2"}
                  onClick={(event) => {
                    event.stopPropagation();

                    this.setState(
                      {quantity: Math.min(quantity + 1, 10)},
                      () => {
                        this.selectOption();
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={"plus-circle"}
                    className={"text-gray-300 hover:text-black transition-all"}
                  />
                </button>
              </div>
            )}

            <div className="flex flex-row">
              {option.PRICE > 0 && (
                <div className="text-gray-500 text-sm font-medium">
                  {toDollars(amount, true)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Option;
