import React, {Component} from "react";
import {Rnd} from "react-rnd";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TextField extends Component {
  onChange(value) {
    const {field} = this.props;
    field.VALUE = value;
  }

  render() {
    const {name, disabled, field} = this.props;

    return (
      <Rnd
        default={{
          x: field?.X_COORDINATE,
          y: field?.Y_COORDINATE,
          width: field?.WIDTH,
          height: field?.HEIGHT,
        }}
        className={"hover:cursor-pointer"}
        bounds="parent"
        disableDragging={true}
        enableResizing={false}
        key={field.UNIQUE_ID}
      >
        <textarea
          key={name}
          id={name}
          name={name}
          wrap={"hard"}
          disabled={disabled}
          onChange={(val) => this.onChange(val.target.value)}
          placeholder={"Click here to enter text"}
          className={classNames(
            "flex flex-row placeholder:text-gray-700 text-sm justify-between align-items-top text-left align-text-top font-medium px-1 border-opacity-25 bg-opacity-25 w-full h-full border-2 text-black text-opacity-100",
            !!field.VALUE
              ? "bg-gray-500 border-gray-500 hover:cursor-default"
              : "hover:cursor-pointer bg-indigo-400 border-indigo-700 hover:bg-indigo-500 hover:bg-opacity-50 transition-colors ease-in-out duration-200"
          )}
        >
          {field.VALUE}
        </textarea>
      </Rnd>
    );
  }
}

export default TextField;
