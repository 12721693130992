import React, {Component} from "react";
import {Document, Page} from "react-pdf";
import {request} from "../../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";

class ReviewI9Part1Page extends Component {
  state = {document: null};
  componentDidMount() {
    const {employee} = this.props;

    request("company-documents/employee/part1/" + employee.ID, "GET").then(
      (document) => this.setState({document})
    );
  }

  render() {
    const {document} = this.state;

    if (!document) {
      return <Loading />;
    }

    return (
      <div
        className={
          "relative w-full flex flex-col justify-center align-middle items-center"
        }
      >
        <div className={"h-[32rem] relative overflow-y-scroll"}>
          <Document file={document.DATA}>
            <Page pageNumber={1} />
          </Document>
        </div>
      </div>
    );
  }
}

export default ReviewI9Part1Page;
