import React, {Component} from "react";
import {
  Card,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";

class InvoiceRemindersCard extends Component {
  render() {
    const {hideEdit, handleEdit, invoice, lines} = this.props;

    return (
      <Card
        label="Invoices Reminders"
        button={
          hideEdit
            ? undefined
            : {
                label: "Edit Recipients",
                onClick: () => handleEdit(),
              }
        }
      >
        <Table
          data={invoice.REMINDERS}
          columns={[
            {
              width: 1,
              label: "Reminder Date",
              value: "DATE_SCHEDULED",
              format: (date) => {
                return moment(date).format("ddd MMM Do");
              },
            },
          ]}
        />
      </Card>
    );
  }
}

InvoiceRemindersCard.propTypes = {
  handleEdit: PropTypes.func.isRequired,
};

export default InvoiceRemindersCard;
