import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import TippingModal from "../../../modals/shop/shop-settings/tipping-modal";
import SmallTicketTippingModal from "../../../modals/shop/shop-settings/small-ticket-tipping-modal";
import AdvancedSettingsModal from "../../../modals/shop/shop-settings/advanced-settings-modal";

class AdvancedTab extends Component {
  render() {
    let {location} = this.props.shop;

    return (
      <div>
        <AdvancedSettingsModal ref={(e) => (this.advancedModal = e)} />

        <TwoColumnList
          label="Advanced Settings"
          description="Settings that should be only changed when supervised by Dripos staff"
          data={[
            {
              type: "bool",
              label: "Location Online",
              value: location.ENABLED,
              tooltip: "Will this location be able to take orders.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.advancedModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(AdvancedTab);
