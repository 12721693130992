import React, {Component} from "react";
import PresetDropdown from "../../../dropdowns/sales/preset-dropdown";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";

class PresetModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  render() {
    return (
      <Modal
        buttonLabel="Add"
        ref={(e) => (this.modal = e)}
        label="Select Product Modifier"
        description="Select a modifier to use"
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{modifier: null}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => {
            this.props.updateState(values, () => this.modal.close());
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PresetDropdown
                  exclude={this.props.exclude}
                  label="Product Modifiers"
                  options={formikOptions}
                  excludeType={5}
                  name="modifier"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PresetModal;
