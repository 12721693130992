import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Portal as Second} from "react-portal";
import {Portal} from "@headlessui/react";

class Tooltip extends Component {
  state = {index: 0, id: "tool_" + Math.round(Math.random() * 1000000)};

  componentDidMount() {
    this.setState({id: "tool_" + Math.round(Math.random() * 1000000)});
  }

  renderHeader() {
    let {label, data} = this.props;
    let {index} = this.state;
    let hasPrevious = false;
    let hasNext = false;

    if (Array.isArray(data)) {
      label = data[index]?.label;

      if (data.length - 1 > index) {
        hasNext = true;
      }
    }

    if (index > 0) {
      hasPrevious = true;
    }

    return (
      <div className="flex flex-row justify-between align-center">
        {label && <div className="font-bold">{label}</div>}

        {Array.isArray(data) && (
          <div className="flex flex-row justify-between space-x-3">
            <div onClick={() => this.setState({index: Math.max(0, index - 1)})}>
              <FontAwesomeIcon
                icon="arrow-left"
                className={classNames(
                  hasPrevious ? "text-indigo-700" : "text-gray-500",
                  "cursor-pointer"
                )}
              />
            </div>

            <div
              onClick={(e) => {
                this.setState({index: Math.min(index + 1, data.length - 1)});
              }}
            >
              <FontAwesomeIcon
                icon="arrow-right"
                className={classNames(
                  hasNext ? "text-indigo-700" : "text-gray-500",
                  "cursor-pointer"
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderContent() {
    const {index} = this.state;
    const {data} = this.props;

    if (Array.isArray(data)) {
      return <span>{data[index]?.data}</span>;
    }

    return <span>{data}</span>;
  }

  renderLink() {
    let {link, data} = this.props;
    let {index} = this.state;

    if (Array.isArray(data)) {
      if (data[index]?.link) {
        link = data[index]?.link;
      }
    }

    if (link) {
      return (
        <a
          href={link}
          target="_blank"
          className="text-indigo-700 font-bold cursor-pointer"
        >
          &nbsp;Learn more
        </a>
      );
    }
  }

  render() {
    const {className, place = "top"} = this.props;
    const {id} = this.state;

    let placementMargin = "";

    switch (place) {
      case "top":
        placementMargin = "mb-10";
        break;
      case "right":
        placementMargin = "ml-3";
        break;
    }

    return (
      <div key={id} className={classNames(className)}>
        <FontAwesomeIcon
          icon="square-info"
          className="cursor-pointer text-gray-600"
          data-event-off="mouseleave touchstart"
          data-event="mouseenter click"
          data-for={id}
          data-tip=""
        />

        <Second>
          {/*<Portal closeOnOutsideClick>*/}
            <ReactTooltip
              id={id}
              border={true}
              type="light"
              effect="solid"
              place={place}
              delayHide={250}
              clickable={true}
              borderColor="#d1d5db"
              globalEventOff="touchstart"
              className={classNames(
                placementMargin,
                "solid-tooltip w-64 shadow-sm ignore-click"
              )}
            >
              <div onClick={(e) => e.stopPropagation()}>
                {this.renderHeader()}
                {this.renderContent()}
                {this.renderLink()}
              </div>
            </ReactTooltip>
          {/*</Portal>*/}
        </Second>
      </div>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  link: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.string,
      })
    ),
  ]),
};

export default Tooltip;
