import React, {Component} from "react";
import {
  Card,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import PackageModal from "../../../modals/sales/ecommerce/package-modal";
import {request} from "../../../utils/request";
import {updateStateDelegator} from "../../../utils/util";
import LabelModal from "../../../modals/sales/ecommerce/label-modal";
import {_shippingPackagesToShipEngineFormat} from "../../../utils/constants";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";

class SilkosCard extends Component {
  state = {packages: [], boxes: [], addresses: [], label: null};

  componentDidMount() {
    const {ecommerce} = this.props;

    request("ecommerce/shippingboxes", "GET", null).then((boxes) => {
      this.setState({boxes});
    });

    request("ecommerce/shippingaddresses", "GET", null).then((addresses) => {
      this.setState({addresses});
    });

    request("ecommerce/shippinglabel/" + ecommerce.ID, "GET", null).then(
      (responseData) => {
        this.setState({
          label: responseData.shippingLabel,
          packages: _shippingPackagesToShipEngineFormat(
            responseData.shippingPackages
          ),
        });
      }
    );
  }

  deleteLabel() {
    let {ecommerce} = this.props;

    showLoadingConfirmAlert(
      "Delete Label",
      "Deleting this label will remove it for good and will require you to setup a new one. Are you sure you want to continue?"
    ).then(async (close) => {
      await request("ecommerce/shippinglabel/" + ecommerce.ID, "DELETE", {});

      this.setState({label: null, packages: []}, () => {
        close();
      });

      showSuccessNotification(
        "Label Deleted",
        "You can assign new packages to create a new label"
      );
    });
  }

  render() {
    const {packages, boxes, addresses, label} = this.state;

    let packageButtons = [
      {
        label: "Make Label",
        onClick: () => this.labelModal.open(),
        disabled: packages.length === 0,
      },
      {
        label: "Create Package",
        onClick: () => this.packageModal.open(),
      },
    ];

    if (label !== null) {
      packageButtons = [];
    }

    return (
      <>
        <PackageModal
          boxes={boxes}
          ref={(e) => (this.packageModal = e)}
          addState={(pack) => this.setState({packages: [...packages, pack]})}
          updateState={(id, pack) => {
            this.setState({
              packages: updateStateDelegator(packages, id, pack),
            });
          }}
        />

        <LabelModal
          {...this.props}
          packages={packages}
          addresses={addresses}
          ref={(e) => (this.labelModal = e)}
          addState={({shippingLabel, shippingPackages}) => {
            this.setState({
              label: shippingLabel,
              packages: _shippingPackagesToShipEngineFormat(shippingPackages),
            });
          }}
        />

        <Card label="Shipment Packages" buttons={packageButtons}>
          <Table
            data={packages}
            actionButtons={
              label
                ? []
                : [
                    {
                      label: "Edit Package",
                      onClick: (pack) => this.packageModal.open(pack),
                    },
                  ]
            }
            columns={[
              {
                value: "name",
                label: "Box Name",
              },
              {
                value: "weight",
                label: "Weight",
                format: (weight) => weight.value + " " + weight.unit,
              },
              {
                width: 1,
                value: "dimensions",
                label: "Package Length",
                format: (dimensions) =>
                  dimensions.length + " " + dimensions.unit,
              },
              {
                width: 1,
                value: "dimensions",
                label: "Package Width",
                format: (dimensions) =>
                  dimensions.width + " " + dimensions.unit,
              },
              {
                width: 1,
                value: "dimensions",
                label: "Package Height",
                format: (dimensions) =>
                  dimensions.height + " " + dimensions.unit,
              },
            ]}
          />
        </Card>

        {label && (
          <TwoColumnList
            label="Shipping Label"
            buttons={[
              {
                label: "Download Label",
                onClick: () => window.open(label.SE_PDF_DOWNLOAD, "_blank"),
              },
              {
                label: "Delete Labels",
                onClick: (pack) => this.deleteLabel(),
              },
            ]}
            data={[
              {
                label: "Service",
                value: label.SE_SERVICE_CODE,
              },
              {
                label: "Tracking Number",
                value: label.SE_TRACKING_NUMBER,
              },
              {
                label: "Shipment Cost",
                value: label.SE_SHIPMENT_COST,
                type: "dollars",
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default SilkosCard;
