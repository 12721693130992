import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

class PersonDropdown extends Component {
  render() {
    const {data, disabled, options, partner} = this.props;

    return (
      <>
        {disabled && (
          <>
            <div className="text-sm font-medium text-gray-700">
              Partner Selection
            </div>
            <div>{partner.label} </div>
          </>
        )}
        {!disabled && (
          <FormSelect
            disabled={disabled}
            label={"Partner Selection"}
            options={options}
            data={data}
            name={"partner"}
            {...this.props}
          />
        )}
      </>
    );
  }
}

export default PersonDropdown;
