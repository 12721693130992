import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {
  PageHeadings,
  Table,
  AdvancedFilter,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShippingBoxModal from "../../../modals/sales/ecommerce/shipping-box-modal";
import {updateStateDelegator} from "../../../utils/util";
import ShippingAddressModal from "../../../modals/sales/ecommerce/shipping-address-modal";

class ShippingAddressesPage extends Component {
  state = {
    addresses: null,
    isLoading: true,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let addresses = await request("ecommerce/shippingaddresses", "GET");

    this.setState({isLoading: false, addresses});
  }

  render() {
    let {isLoading, addresses} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ShippingAddressModal
          ref={(e) => (this.addressModal = e)}
          addState={(address) => this.setState({addresses: [...addresses, address]})}
          updateState={(id, address) => {
            this.setState({
              addresses: updateStateDelegator(addresses, id, address),
            });
          }}
        />

        <PageHeadings
          label="Shipping Addresses"
          description="Return addresses for shipments"
          button={{label: "Create Address", onClick: () => this.addressModal.open()}}
        />

        <Table
          data={addresses}
          actionButtons={[
            {
              label: "View",
              onClick: (box) => this.addressModal.open(box),
            },
          ]}
          columns={[
            {
              label: "Name",
              value: "ADDRESS_IDENTIFIER",
            },
            {
              label: "Phone",
              value: "PHONE",
            },
            {
              label: "Person Name",
              value: "ADDRESS_NAME",
            },
            {
              label: "Address",
              value: "ADDRESS",
              format: (address) => {
                return address.LINE_ONE + " " + address.CITY + " " + address.STATE
              }
            },
          ]}
          pagination
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ShippingAddressesPage));
