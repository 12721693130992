import React, {Component} from "react";
import {request} from "../../../utils/request";
import {
  Loading,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import Table from "../../../features/operations/tables/table";
import {Stage, Layer, Rect, Circle, Text} from "react-konva";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import EditTableComponent from "../../../features/operations/tables/edit-table-component";

class TablesPage extends Component {
  state = {
    tables: null,
    selectedTable: null,
    stageWidth: 1000,
    stageHeight: 1000,
  };

  constructor() {
    super();
  }

  componentDidMount() {
    this.checkSize();

    window.addEventListener("resize", () => this.checkSize());

    request("tables", "GET").then((tables) => this.setState({tables}));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkSize);
  }

  checkSize() {
    const width = this.container?.offsetWidth ?? 1000;
    const height = this.container?.offsetHeight ?? 1000;

    this.setState({
      stageWidth: width,
      stageHeight: height,
    });
  }

  updateTable(table) {
    this.setState((prevState) => {
      const {tables} = prevState;

      const tableIndex = tables.findIndex((_table) => table.ID === _table.ID);

      tables[tableIndex] = table;

      return {tables};
    });
  }

  checkDeselect(e) {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      this.setState({selectedTable: null});
    }
  }

  onSave() {
    const {tables} = this.state;

    request("tables/batch", "PATCH", {TABLES: tables}).then(() =>
      showSuccessNotification(
        "Tables Saved.",
        "Table positions have been save successfully."
      )
    );
  }

  selectTable(selectedTable) {
    this.setState({selectedTable});
  }

  zoomStage(event) {
    const scaleBy = 1.05;

    event.evt.preventDefault();
    if (this.stage !== null) {
      const stage = this.stage;
      const oldScale = stage.scaleX();
      const {x: pointerX, y: pointerY} = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({x: newScale, y: newScale});
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  }

  renderTable(table) {
    const {selectedTable} = this.state;

    return (
      <Table
        table={table}
        ref={(e) => (this[table.ID] = e)}
        selected={selectedTable?.ID === table.ID}
        selectTable={() => this.selectTable(table)}
        updateTable={(table) => this.updateTable(table)}
        key={randomString(24)}
      />
    );
  }

  renderEditTable(table) {
    return (
      <EditTableComponent
        table={table}
        updateTable={(table) => this.updateTable(table)}
      />
    );
  }

  render() {
    const {tables, selectedTable, stageWidth, stageHeight} = this.state;

    if (!tables) {
      return <Loading />;
    }

    return (
      <div>
        <div className={"mb-2 "}>
          <PageHeadings
            label={"Table Layout"}
            description={"Edit your table layout"}
            className={"mb-4"}
            buttons={[
              {
                label: "Save",
                onClick: async () => await this.onSave(),
              },
            ]}
          />
        </div>

        <div className={"bg-white flex flex-row"}>
          <div ref={(e) => (this.container = e)}>
            <Stage
              width={stageWidth}
              height={stageHeight}
              className={"z-10"}
              onMouseDown={(e) => this.checkDeselect(e)}
              ref={(e) => (this.stage = e)}
              onWheel={(e) => this.zoomStage(e)}
              draggable
            >
              <Layer>{tables.map((table) => this.renderTable(table))}</Layer>
            </Stage>
          </div>

          <div className={"flex-1 px-6 shadow-lg "}>
            {selectedTable && this.renderEditTable(selectedTable)}
          </div>
        </div>
      </div>
    );
  }
}

export default TablesPage;
