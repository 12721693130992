import React, {Component} from "react";
import {
  Table,
  FormInput,
  FormTextArea,
  FormBoolean,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import PolicyCategoryModal from "../../../modals/team/policies/policy-category-modal";
import {updateStateDelegator} from "../../../utils/util";

class PolicyDetailsForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({
    name,
    categories,
    type,
    review,
    paidDismissal,
    description,
    unlimited,
    paid,
  }) {
    return {
      details: {
        NAME: name,
        TYPE: type,
        CATEGORIES: categories,
        REVIEW_REQUESTS: review,
        PAID_DISMISSAL: paidDismissal,
        DESCRIPTION: description,
        UNLIMITED: unlimited,
        PAID: paid,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, details} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
        })}
        initialValues={{
          name: details?.NAME ?? "",
          paid: details?.PAID ?? "1",
          type: details?.TYPE ?? "PAID",
          paidDismissal: details?.PAID_DISMISSAL ?? "0",
          review: details?.REVIEW_REQUESTS ?? "1",
          description: details?.DESCRIPTION ?? "",
          showCategories: details
            ? details?.CATEGORIES?.length > 0
              ? "1"
              : "0"
            : "0",
          categories: details?.CATEGORIES ?? [],
          unlimited: details?.UNLIMITED ?? "0",
        }}
      >
        {(formikOptions) => {
          const {handleSubmit, values, setFieldValue} = formikOptions;
          const {categories} = values;

          return (
            <form onSubmit={handleSubmit}>
              <PolicyCategoryModal
                categories={categories}
                ref={(e) => (this.categoryModal = e)}
                addState={(category) =>
                  setFieldValue("categories", [...categories, category])
                }
                updateState={(id, category) => {
                  setFieldValue(
                    "categories",
                    updateStateDelegator(categories, id, category)
                  );
                }}
              />

              <FormInput options={formikOptions} label="Name" name="name" />

              <FormSelect
                options={formikOptions}
                label="Type"
                name="type"
                data={[
                  {label: "Generic Time Off", value: "UNPAID"},
                  {label: "Paid Time Off (PTO)", value: "PAID"},
                  {label: "Parental Leave", value: "PARENTAL"},
                  {label: "Sick Leave", value: "SICK"},
                  {label: "Vacation", value: "VACATION"},
                  {label: "Personal Time", value: "PERSONAL"},
                  {label: "Holiday", value: "HOLIDAY"},
                ]}
              />

              <FormTextArea
                options={formikOptions}
                label="Description"
                name="description"
              />

              <FormBoolean
                options={formikOptions}
                tooltip="Do the time off requests have to be approved by a manager"
                label="Time Off Requires Approval"
                name="review"
              />

              <FormBoolean
                tooltip="Does the employee have unlimited amount of hours to request off"
                onChangeSoft={({value}) =>
                  this.props.updateUnlimited(value === "1")
                }
                options={formikOptions}
                label="Unlimited Hours"
                name="unlimited"
              />

              <FormBoolean
                tooltip="Will the employee be paid for hours they take off under this policy"
                options={formikOptions}
                label="Paid Hours"
                name="paid"
              />

              <FormBoolean
                tooltip="Will the employee be paid for accrued time off hours upon dismissal"
                options={formikOptions}
                label="Paid Dismissal"
                name="paidDismissal"
              />

              <FormBoolean
                options={formikOptions}
                label="Policy Categories"
                name="showCategories"
                tooltip="Enable to have employee input a time off category when sending in requests"
              />

              {values.showCategories === "1" && (
                <Table
                  draggable
                  actionTexts={[
                    {
                      label: "Add Category",
                      onClick: () => this.categoryModal.open(),
                    },
                  ]}
                  data={categories}
                  className="mt-4"
                  actionButtons={[
                    {
                      label: "Edit",
                      onClick: (row) => {
                        this.categoryModal.open(row);
                      },
                    },
                  ]}
                  columns={[
                    {
                      value: "NAME",
                      label: "Name",
                    },
                    {
                      value: "ADVANCED_NOTICE",
                      label: "Advanced Notice",
                    },
                  ]}
                />
              )}
            </form>
          );
        }}
      </Formik>
    );
  }
}

PolicyDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  details: PropTypes.object,
};

export default PolicyDetailsForm;
