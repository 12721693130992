import React, {Component} from "react";
import {Formik, Form} from "formik";
import {
  FormInput,
  FormPhone,
  FormEmail,
} from "@frostbyte-technologies/frostbyte-tailwind";
import FormStateSelect from "../../../../components/form-state-select";
import FormDateSpecify from "../../../../components/form-date-specify";
import * as Yup from "yup";
import {
  createPayrollEmployee,
  onboardCodeToEmployee,
} from "../../../../utils/payroll-helper";
import {showErrorAlert} from "../../../../utils/alert-helper";

class PayrollEmployeeFormSetupComponent extends Component {
  state = {employee: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let employee = await onboardCodeToEmployee(ID);

    this.setState({isLoading: false, employee});
  }

  async handleClick() {
    return Boolean(await this.formikRef.submitForm());
  }

  submitForm = async (values) => {
    let {
      firstName,
      lastName,
      email,
      line1,
      line2,
      city,
      state,
      zip,
      dob,
    } = values;
    let {ID} = this.state.employee;
    let {ID: onboardCode} = this.props.router.params;

    try {
      await createPayrollEmployee({
        ONBOARD_CODE: onboardCode,
        EMPLOYEE_ID: ID,
        FIRST_NAME: firstName,
        LAST_NAME: lastName,
        EMAIL: email,
        ADDRESS_1: line1,
        ADDRESS_2: line2,
        CITY: city,
        STATE: state,
        ZIP: zip,
        DOB: dob,
      });

      return true;
    } catch (e) {
      showErrorAlert(
        "Error",
        e.error.message,
        "Ok"
      );

      return false;
    }
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    line1: Yup.string().required("Must Provide an Address"),
    city: Yup.string().required("Must Provide a City"),
    state: Yup.string().required("Must Provide a State"),
    zip: Yup.number().required("A Valid Zipcode is Required"),
  });

  render() {
    return (
      <div className="flex justify-center pb-10">
        <Formik
          initialValues={{}}
          onSubmit={this.submitForm}
          validationSchema={this.validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div className="w-full px-6 md:w-1/2 md:px-6">
                <Form onSubmit={this.submitForm}>
                  <FormInput
                    name={"firstName"}
                    label={"Legal First Name"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"lastName"}
                    label={"Legal Last Name"}
                    options={formikOptions}
                  />

                  <FormEmail
                    name={"email"}
                    label={"Email"}
                    options={formikOptions}
                  />

                  <FormDateSpecify
                    name={"dob"}
                    label="Date of Birth"
                    options={formikOptions}
                  />

                  <FormInput
                    name={"line1"}
                    label={"Address Line 1"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"line2"}
                    label={"Address Line 2"}
                    options={formikOptions}
                  />

                  <div className="flex flex-row">
                    <FormInput
                      name={"city"}
                      label={"City"}
                      options={formikOptions}
                    />

                    <FormStateSelect
                      className="ml-5"
                      name="state"
                      label={"State"}
                      options={formikOptions}
                    />

                    <FormInput
                      className="ml-5 w-24"
                      name={"zip"}
                      label={"Zipcode"}
                      options={formikOptions}
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default PayrollEmployeeFormSetupComponent;
