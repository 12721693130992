import React, {Component, Fragment} from "react";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class ButtonList extends Component {
  state = {selected: null};

  componentDidMount() {
    const {searchParam, items, onChange, defaultValue} = this.props;

    if (searchParam) {
      const url = new URL(window.location.href);

      if (url.searchParams.has(searchParam)) {
        const id = url.searchParams.get(searchParam);
        const selected = items.find((item) => item.id === id);

        if (selected) {
          this.setState({selected}, () => onChange && onChange(selected));
        }
      }
    } else if (defaultValue) {
      const selected = items.find((item) => item.id === defaultValue);

      if (selected) {
        this.setState({selected}, () => onChange && onChange(selected));
      }
    }
  }

  updateSearchParam(val) {
    const {searchParam} = this.props;

    if (searchParam) {
      const url = new URL(window.location.href);

      url.searchParams.set(searchParam, val);

      window.history.pushState({}, null, url);
    }
  }

  fetchAccent() {
    const {theme} = this.props;

    if (theme === "gray") {
      return {
        divide: "divide-gray-300",
        dropdown:
          "bg-white relative border-l-0 border inline-flex items-center px-2 py-2 rounded-r-md border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
        buttons:
          "bg-white text-gray-700 border border-gray-300 hover:bg-gray-50",
        icons: "text-gray-700",
      };
    }

    return {
      dropdown:
        "relative inline-flex items-center bg-indigo-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500",
      buttons:
        "bg-indigo-500 text-white border-transparent hover:bg-indigo-600",
      divide: "divide-indigo-600",
      icons: "text-white",
    };
  }

  render() {
    const {
      label,
      items,
      icon,
      selectable,
      onChange,
      labelPrefix = "",
    } = this.props;
    const {buttons, icons, dropdown, divide} = this.fetchAccent();
    const {selected} = this.state;

    return (
      <Listbox
        value={selected}
        onChange={(selected) => {
          onChange && onChange(selected);

          if (selectable) {
            this.setState({selected}, () => {
              this.updateSearchParam(selected.id);
            });
          }
        }}
      >
        {({open}) => (
          <>
            <Listbox.Label className="sr-only">
              Change published status
            </Listbox.Label>

            <div className="relative">
              <div
                className={classNames(
                  divide,
                  "inline-flex shadow-sm rounded-md divide-x"
                )}
              >
                <div
                  className={classNames(
                    divide,
                    "relative z-0 inline-flex shadow-sm rounded-md divide-x"
                  )}
                >
                  <Listbox.Button
                    className={classNames(
                      buttons,
                      "relative inline-flex items-center py-2 pl-3 pr-4 border rounded-l-md shadow-sm"
                    )}
                  >
                    <FontAwesomeIcon
                      icon={icon}
                      className="h-5 w-5"
                      aria-hidden="true"
                    />

                    <p className="ml-2.5 text-sm font-medium">
                      {selected ? labelPrefix + selected.label : label}
                    </p>
                  </Listbox.Button>

                  <Listbox.Button className={classNames(dropdown)}>
                    <span className="sr-only">Change published status</span>

                    <ChevronDownIcon
                      className={classNames(icons, "h-5 w-5 ")}
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-20 origin-top-right absolute right-0 mt-2 -mr-1 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                  {items.map((option) => (
                    <Listbox.Option
                      key={option.name}
                      value={option}
                      className={({active}) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default select-none relative p-4 text-sm"
                        )
                      }
                    >
                      {({selected, active}) => {
                        return (
                          <div className="flex flex-col">
                            <div className="flex justify-between">
                              <p
                                className={
                                  selected ? "font-semibold" : "font-medium"
                                }
                              >
                                {option.label}
                              </p>

                              {selected ? (
                                <span
                                  className={
                                    active ? "text-white" : "text-indigo-600"
                                  }
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </div>
                            <p
                              className={classNames(
                                active ? "text-indigo-200" : "text-gray-500",
                                "mt-2"
                              )}
                            >
                              {option.description}
                            </p>
                          </div>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
}

ButtonList.propTypes = {
  label: PropTypes.string.isRequired,
  selectable: PropTypes.bool,
  labelPrefix: PropTypes.bool,
  onChange: PropTypes.func,
  searchParam: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default ButtonList;
