import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Loading,
  PageHeadings,
  Tab,
  Card,
  TwoColumnList,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import RolePermissions from "../../../features/team/role-permissions";
import RoleModal, {
  APPROVAL_NOTIFICATIONS,
} from "../../../modals/team/roles/role-modal";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PayRateModal from "../../../modals/team/pay-rate-modal";
import EmployeeTable from "../../../tables/team/employee-table";
import RemoveRoleModal from "../../../modals/team/roles/remove-role-modal";
import RoleEmployeeSelectModal from "../../../modals/team/role-employee-select-modal";

class RolePage extends Component {
  state = {role: null, rates: [], permissions: [], accounts: [], filter: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const {
      ROLE: role,
      ACCOUNTS: accounts,
      PERMISSIONS: permissions,
    } = await request("employeeroles/role/" + id, "GET");

    request("pay/rates/role/" + role.ID, "GET").then((rates) => {
      this.setState({rates});
    });

    this.setState({
      role,
      accounts,
      permissions: permissions.map((item) => item.CONTENT),
    });
  }

  async savePermissions(permissions) {
    const {ID: id} = this.props.router.params;

    const permissionArr = Object.keys(permissions).filter((item) => {
      return parseInt(permissions[item]) === 1;
    });

    await request("employeeroles/roles/" + id, "PATCH", {
      PERMISSIONS: permissionArr,
    });

    this.setState({permissions: permissionArr});
  }

  render() {
    const {role, accounts, permissions, rates, filter} = this.state;

    if (role === null) {
      return <Loading />;
    }

    return (
      <div>
        <RoleModal
          onSave={(extra) => this.setState({role: {...role, ...extra}})}
          ref={(e) => (this.roleModal = e)}
        />

        <RoleEmployeeSelectModal
          ref={(e) => (this.employeeModal = e)}
          role={this.state.role}
          onUpdate={() => {
            this.syncState();
          }}
        />

        <PayRateModal
          role={role}
          ref={(e) => (this.payRateModal = e)}
          addState={(rate) => {
            this.setState({rates: [...rates, rate]});
          }}
          updateState={() => {
            this.syncState();
          }}
        />

        <RemoveRoleModal
          ref={(e) => (this.removeRoleModal = e)}
          updateState={() => this.props.router.navigate("/roles")}
        />

        <PageHeadings
          label={role.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Role",
                      onClick: () => this.removeRoleModal.open(role),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Team", url: "/"},
            {label: "My Team", url: "/team"},
            {label: "Roles Page", url: "/roles"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.roleModal.open(role);
              },
            },
          ]}
          label="Role Information"
          description={`Information about the "${role.NAME}" role`}
          data={[
            {label: "Name", value: role.NAME},
            {
              label: "Color",
              value: (
                <div
                  style={{backgroundColor: `#${role.COLOR}`}}
                  className="h-5 w-5 "
                />
              ),
            },
            {label: "Tips Disabled", value: role.TIPS_DISABLED, type: "bool"},
            {
              label: "Notify of Trade and Time Off Requests",
              value: APPROVAL_NOTIFICATIONS.find(
                (item) => item.id === role.APPROVAL_NOTIFICATIONS
              )?.label,
            },
            {
              label: "Notify of Late Clock Ins",
              value: APPROVAL_NOTIFICATIONS.find(
                (item) => item.id === role.CLOCK_NOTIFICATIONS
              )?.label,
            },
            {
              label: "Notify of Up For Grabs Shifts",
              value: APPROVAL_NOTIFICATIONS.find(
                (item) => item.id === role.GRABS_NOTIFICATIONS
              )?.label,
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Active Employees", id: "employees"},
            {label: "Permissions", id: "permissions"},
            {label: "Pay Rates", id: "rates"},
          ]}
        >
          {(id) => {
            if (id === "employees") {
              return (
                <Card
                  label="Employees"
                  description="These employees have an active role setup"
                  buttons={[
                    {
                      label: "Add Employees",
                      onClick: () => {
                        this.employeeModal.open();
                      },
                    },
                  ]}
                >
                  <EmployeeTable data={accounts} pagination striped />
                </Card>
              );
            }

            if (id === "permissions") {
              return (
                <Card label="Role Permissions">
                  <RolePermissions
                    permissions={permissions}
                    onSubmit={(data) => {
                      request(
                        "employeeroles/roles/" + role.ID + "/permissions",
                        "PATCH",
                        {PERMISSIONS: data}
                      );

                      const permKeys = Object.keys(data);
                      const newPermissions = permissions.filter((item) => {
                        return permKeys.indexOf(item) === -1;
                      });

                      for (let key of permKeys) {
                        if ("" + data[key] === "1") {
                          newPermissions.push(key);
                        }
                      }

                      this.setState({permissions: newPermissions});

                      console.log("HI", newPermissions);
                    }}
                  />
                </Card>
              );
            }

            return (
              <Card
                label="Pay Rates"
                description="These are the default rates employees will get"
                buttons={[
                  {
                    label: "Add Pay Rate",
                    onClick: () => this.payRateModal.open(),
                  },
                  {
                    theme: "gray",
                    selectable: true,
                    type: "list",
                    icon: "eye",
                    defaultValue: "active",
                    onChange: ({id: filter}) => this.setState({filter}),
                    items: [
                      {
                        id: "all",
                        label: "View all rates",
                        description: "View both active and archived pay rates.",
                      },
                      {
                        id: "active",
                        label: "Viewing active rates",
                        description: "View only active pay rates.",
                      },
                    ],
                  },
                ]}
              >
                <Table
                  pagination
                  data={
                    filter === "active"
                      ? rates.filter((item) => !item.DATE_ARCHIVED)
                      : rates
                  }
                  ref={(e) => (this.tableRef = e)}
                  actionButtons={[
                    {
                      label: "Edit",
                      onClick: (row) => this.payRateModal.open(row),
                    },
                  ]}
                  columns={[
                    {
                      width: 1,
                      value: "NAME",
                      label: "Name",
                    },
                    {
                      value: "TYPE",
                      label: "Type",
                      format: (_, {TYPE, CONTENT}) => {
                        if (TYPE === "TIME_RANGE") {
                          const timeSplit = CONTENT.split("|");

                          const startTime = moment()
                            .startOf("day")
                            .add(timeSplit[0], "minutes");
                          const endTime = moment()
                            .startOf("day")
                            .add(timeSplit[1], "minutes");

                          return (
                            "Hourly " +
                            startTime.format("hh:mma") +
                            " - " +
                            endTime.format("hh:mma")
                          );
                        }

                        return "Hourly";
                      },
                    },
                    {
                      width: 1,
                      value: "AMOUNT",
                      label: "Amount",
                      format: (value, row) => {
                        return "$" + toDollars(row.AMOUNT);
                      },
                    },
                    {
                      width: 1,
                      value: "STATUS",
                      label: "Status",
                      format: (value, row) => {
                        return row.DATE_ARCHIVED ? "Inactive" : "Active";
                      },
                    },
                    {
                      width: 1,
                      value: "DATE_UPDATED",
                      label: "Updated",
                      mobile: "lg:table-cell",
                      format: (value) => {
                        return moment(value).format("MM/DD/YY");
                      },
                    },
                  ]}
                />
              </Card>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(RolePage);
