import React, {Component} from "react";
import {SlideOver} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class IngredientModal extends Component {
  state = {invoices: []};

  open(invoices = null) {
    if (invoices.length === 0) return;

    this.setState({invoices}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.slideMenu.open();
    });
  }

  render() {
    const {invoices} = this.state;

    return (
      <SlideOver
        label="Invoices"
        buttons={[
          {label: "Close", type: "gray", onClick: () => this.slideMenu.close()},
        ]}
        description="Click on an invoice to be brought to the invoices page"
        ref={(e) => (this.slideMenu = e)}
      >
        <ul
          role="list"
          className="flex-1 divide-y divide-gray-200 overflow-y-auto"
        >
          {invoices.map((item) => {
            return (
              <li key={item.ID}>
                <div className="group relative flex items-center py-6 px-5">
                  <a
                    target="_blank"
                    href={"/invoice/" + item.UNIQUE_ID}
                    className="-m-1 block flex-1 p-1"
                  >
                    <div
                      className="absolute inset-0 group-hover:bg-gray-50"
                      aria-hidden="true"
                    />

                    <div className="relative flex min-w-0 flex-1 justify-between items-center">
                      <div className="truncate">
                        <p className="truncate text-sm font-medium text-gray-900">
                          {item.CONTACT.FIRST_NAME} {item.CONTACT.LAST_NAME}
                        </p>

                        <p className="truncate text-sm text-gray-500">
                          Invoice Number #{item.INVOICE_NUMBER}
                        </p>
                      </div>

                      <div className="text-md font-medium">
                        ${toDollars(item.TICKET.PAYMENT_INTENT.TOTAL)}
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </SlideOver>
    );
  }
}

export default IngredientModal;
