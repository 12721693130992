import React, {Component} from "react";
import {
  FormElement,
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import ComboBox from "../../../components/combobox";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class SelectDiscountModal extends Component {
  state = {discounts: [], resolve: null};

  componentDidMount() {
    request("partner/discounts/product", "GET").then((discounts) =>
      this.setState({discounts})
    );
  }

  open() {
    return new Promise((resolve) =>
      this.setState({resolve}, () => this.modal.open())
    );
  }

  close({discount, amount, type}) {
    const {resolve, discounts} = this.state;
    let discountObj;

    if (discount) {
      discountObj = discounts.find((_discount) => _discount.ID === discount);
      discountObj.AMOUNT = discountObj.CONTENT;

      resolve(discountObj);
    } else {
      resolve({
        NAME: "Custom Discount",
        AMOUNT: type === 0 ? decimalToDollars(amount) : amount,
        TYPE: type,
      });
    }

    this.modal.close();
  }

  addDiscount(discountId) {
    const {discounts} = this.state;
    const discount = discounts.find((discount) => discount.ID === discountId);
    const amount =
      discount.TYPE === 0 ? toDollars(discount.CONTENT) : discount.CONTENT;

    return {amount, type: discount.TYPE};
  }

  render() {
    const {discounts} = this.state;

    return (
      <Modal
        buttonLabel={"Add"}
        label={"Add Discount"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        innerRef={(e) => (this.formikRef = e)}
        onSubmit={(values) => {
          this.close(values);
        }}
      >
        <Formik
          initialValues={{discount: null, type: 0, amount: ""}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => this.close(values)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  label={"Discount"}
                  name={"discount"}
                  options={formikOptions}
                  placeholder={"Select a Discount"}
                  data={discounts.map((discount) => {
                    return {id: discount.ID, label: discount.NAME};
                  })}
                  onChangeSoft={(discount) => {
                    const {amount, type} = this.addDiscount(discount.id);
                    setFieldValue("amount", amount);
                    setFieldValue("type", type);
                  }}
                />

                <div className={"flex flex-row"}>
                  <FormSelect
                    label={"Type"}
                    name={"type"}
                    className={"flex-auto w-1/3"}
                    options={formikOptions}
                    data={[
                      {value: 0, label: "Flat"},
                      {value: 1, label: "Percent"},
                    ]}
                  />

                  <FormInput
                    label="Amount"
                    name={"amount"}
                    className={"flex-auto w-full ml-2"}
                    options={formikOptions}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SelectDiscountModal;
