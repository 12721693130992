import React, {Component, Fragment} from "react";
import Calendar from "../features/reporting/reporting-filter/calendar";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";

class DatePeriodSelect extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)){
      this.calRef.setEpochs(this.props.value);
    }
  }

  render() {
    const {value, onChange, className} = this.props;

    const {startEpoch, endEpoch} = value;

    let str = moment(startEpoch).format("M/D/YYYY")

    if(moment(startEpoch).startOf("day").valueOf() !== moment(endEpoch).startOf("day").valueOf()){
      str += ` - ${moment(endEpoch).format("M/D/YYYY")}`
    }

    return (
      <Menu as="div" className={classNames("relative inline-block text-left", className)}>
        <div className="flex flex-row ">
          <Menu.Button
            className="inline-flex rounded-md justify-center w-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
            {str}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>


        <Transition
          as={Fragment}
          unmount={false}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            unmount={false}
            className="p-1 rounded-md origin-top-right z-50 absolute mt-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="flex flex-row">
              <div className="flex flex-col items-center hidden md:block">
                <Calendar
                  ref={(e) => (this.calRef = e)}
                  onChange={({startEpoch, endEpoch}) => {

                    onChange({startEpoch, endEpoch});
                  }}
                  initialEpochs={{startEpoch: 0, endEpoch: 0}}
                />
              </div>
            </div>
          </Menu.Items></Transition>
      </Menu>
    );
  }
}

export default DatePeriodSelect;
