import momentBusinessDays from "moment-business-days";

export function payoutEpochToMoment(payoutEpoch) {
  return momentBusinessDays(payoutEpoch * 1000, "x")
    .utc()
    .startOf("day")
    .add(1, "day");
}

export function getPayoutTicketPeriod(payoutCreatedEpoch){
  let payoutMoment = payoutEpochToMoment(payoutCreatedEpoch);

  return {
    startEpoch: momentBusinessDays(payoutMoment.valueOf()).businessSubtract(2).valueOf(),
    endEpoch:  momentBusinessDays(payoutMoment.valueOf()).businessSubtract(1).valueOf()
  }
}
