import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import FormStateSelect from "../../components/form-state-select";

class PayrollCreateContractorModal extends Component {
  open() {
    this.slide.open();
  }

  submitForm = async (values) => {
    let {type, firstName, lastName, line1, line2, city, state, postalCode} = values;

    let checkPayload = {
      TYPE: type,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      LINE_1: line1,
      LINE_2: line2,
      CITY: city,
      STATE: state,
      POSTAL_CODE: postalCode,
    };

    await request("payroll/contractors", "POST", checkPayload);
    this.props.refresh();
  };

  render() {
    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Create Contractor`}
        buttonLabel="Save"
        buttonOnClick={() => this.formikRef.submitForm()}
      >
        <Formik
          initialValues={{
            type: "individual",
          }}
          onSubmit={this.submitForm}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {handleSubmit, values} = formikOptions;
            let {type} = values;

            let isBusiness = type === "business";

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={"Type"}
                  name={"type"}
                  data={[
                    {
                      label: "Individual",
                      value: "individual",
                    },
                    {
                      label: "Business",
                      value: "business",
                    },
                  ]}
                  options={formikOptions}
                />

                {isBusiness && (
                  <FormInput
                    name={"businessName"}
                    label={"Business Name"}
                    options={formikOptions}
                  />
                )}

                <FormInput
                  label={isBusiness ? "Primary Contact's First Name" : "First Name"}
                  name={"firstName"}
                  options={formikOptions}
                />

                <FormInput
                  label={isBusiness ? "Primary Contact's Last Name" : "Last Name"}
                  name={"lastName"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Line 1"}
                  name={"line1"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Line 2 (Optional)"}
                  name={"line2"}
                  options={formikOptions}
                />

                <FormInput
                  label={"City"}
                  name={"city"}
                  options={formikOptions}
                />

                <FormStateSelect
                  label={"State"}
                  name={"state"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Postal Code"}
                  name={"postalCode"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayrollCreateContractorModal;
