import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import FormStateSelect from "../../../components/form-state-select";

class GiftCardCompanySettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {address, zip, city, state} = values;

    let payload = {
      COMPANY_ALT_ADDRESS: address,
      COMPANY_ALT_ZIP: zip,
      COMPANY_ALT_CITY: city,
      COMPANY_ALT_STATE: state,
    };

    await request("settings/company", "PATCH", {
      SETTINGS: payload,
    });

    let {COMPANY_SETTINGS: companySettings} = await request(
      "settings/shop/all",
      "GET"
    );

    this.props.updateShop({companySettings});
    this.modal.close();
  };

  render() {
    let {companySettings} = this.props.shop;
    const {
      COMPANY_ALT_ADDRESS,
      COMPANY_ALT_ZIP,
      COMPANY_ALT_CITY,
      COMPANY_ALT_STATE,
    } = companySettings;

    return (
      <Modal
        label="Gift Card Receipt Settings"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        large
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            address: COMPANY_ALT_ADDRESS,
            zip: COMPANY_ALT_ZIP,
            state: COMPANY_ALT_STATE,
            city: COMPANY_ALT_CITY,
          }}
          validationSchema={Yup.object({
            address: Yup.string().nullable(),
            zip: Yup.string().nullable(),
            state: Yup.string().nullable(),
            city: Yup.string().nullable(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="address"
                  label="Gift Card Purchase Address"
                  options={formikOptions}
                />

                <FormInput
                  name="city"
                  label="Gift Card Purchase City"
                  options={formikOptions}
                  flex
                />
                <FormRow>
                  <FormStateSelect
                    name="state"
                    label="State"
                    options={formikOptions}
                  />

                  <FormInput
                    name="zip"
                    label="Zip Code"
                    hint={"Company Setting"}
                    options={formikOptions}
                    flex
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  GiftCardCompanySettingsModal
);
