const defaultState = {
  otherPayments: [],
  categories: [],
  orderTypes: [],
  products: [],
};

const UPDATE_MENU = "UPDATE_MENU";

export function updateMenu(payload) {
  return {type: UPDATE_MENU, payload};
}

export const menuReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_MENU:
      return {...state, ...payload};
  }
};
