import React, {Component} from "react";
import {
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";


class EmailCampaignModal extends Component {
  state = {campaign: null};

  open(campaign = null) {
    this.setState({campaign}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createCampaign({name, subject}) {
    let serverCampaign = await request("marketing/campaigns/email", "POST", {
      SUBJECT: subject,
      NAME: name,
    });

    this.props.addState(serverCampaign);
    this.modal.close();
  }

  async saveCampaign({name, subject}) {
    const {campaign} = this.state;

    const serverCampaign = await request(
      "marketing/campaigns/email/" + campaign.ID,
      "PATCH",
      {
        NAME: name,
        SUBJECT: subject,
      }
    );

    this.props.updateState(serverCampaign);
    this.modal.close();
  }

  render() {
    const {campaign} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={campaign ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        label={campaign ? "Edit Email Campaign" : "Create Email Campaign"}
      >
        <Formik
          onSubmit={
            campaign
              ? this.saveCampaign.bind(this)
              : this.createCampaign.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: campaign?.NAME ?? "",
            subject: campaign?.SUBJECT ?? null,
          }}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" label="Name" options={formikOptions} />

                <FormInput
                  options={formikOptions}
                  label="Subject"
                  name="subject"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmailCampaignModal;
