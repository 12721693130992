import React, {Component} from "react";
import {
  FormInput,
  FormEmail,
  FormPhone,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import FormStateSelect from "../../../components/form-state-select";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {withRouter} from "../../../utils/navigation";
import FranchiseDropdown from "../../../dropdowns/admin/franchise-dropdown";

class CompanyModal extends Component {
  state = {company: null};

  open(company = null) {
    this.setState({company}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createCompany({
    name,
  }) {
    const serverLocation = await request("admin/company", "POST", {
      NAME: name,
    });

    this.props.router.navigate("/admin/company/" + serverLocation.UNIQUE_ID);
  }

  async saveCompany({first, last, phone, email, company, notes}) {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts/" + contact.ID, "PATCH", {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      COMPANY: company,
    });

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {company} = this.state;

    return (
      <Modal
        buttonLabel={company ? "Save" : "Add"}
        label={company ? "Edit Company" : "Create Location"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            company
              ? this.saveCompany.bind(this)
              : this.createCompany.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
          })}
          initialValues={{
            name: company?.NAME ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Avery's Companies"
                  label="Name"
                  name="name"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(CompanyModal);
