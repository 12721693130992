import React, {Component} from "react";
import {
  Card,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import ContactModal from "../../../modals/shop/contact-modal";

class ContactTab extends Component {
  render() {
    let {EMAIL, FACEBOOK, TWITTER, INSTAGRAM, WEBSITE, PHONE} = this.props.info;

    return (
      <div>
        <Card>
          <ContactModal
            info={this.props.info}
            ref={(e) => (this.contactModal = e)}
            updateInfo={this.props.updateInfo}
          />

          <TwoColumnList
            buttons={[
              {
                label: "Edit",
                onClick: () => this.contactModal.open(),
              },
            ]}
            label="Contact"
            description="Edit your location's contact information"
            data={[
              {label: "Website", value: WEBSITE},
              {label: "Phone", value: PHONE ? PHONE : "None"},
              {label: "Email", value: EMAIL ? EMAIL : "None"},
              {label: "Facebook", value: FACEBOOK ? FACEBOOK : "None"},
              {label: "Twitter", value: TWITTER ? TWITTER : "None"},
              {label: "Instagram", value: INSTAGRAM ? INSTAGRAM : "None"},
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default ContactTab;
