import React, {Component} from "react";
import {
  PageHeadings,
  Loading,
  TwoColumnList,
  Table,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import AddDrawerModal from "../../../../modals/operations/cash/add-drawer-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class BankTemplatePage extends Component {
  state = {template: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    const {ID} = this.props.router.params;

    request("storebanks/templates/" + ID, "GET").then((template) => {
      this.setState({template});
    });
  }

  async deleteDrawer(drawer) {
    showLoadingConfirmAlert(
      "Delete Drawer",
      "Are you sure you want to delete this cash drawer?"
    ).then(async (close) => {
      await request("storebanks/templates/drawers/" + drawer.ID, "DELETE");
      await this.syncState();
      close();
    });
  }

  async archiveTemplate() {
    const {template} = this.state;

    showLoadingConfirmAlert(
      "Delete Template",
      "Are you sure you want to delete this template?"
    ).then(async (close) => {
      await request("storebanks/templates/" + template.ID, "DELETE");
      close();
      this.props.router.navigate("/banks");
    });
  }

  render() {
    const {template} = this.state;

    if (!template) {
      return <Loading />;
    }

    return (
      <div>
        <AddDrawerModal
          ref={(e) => (this.drawerModal = e)}
          syncState={() => this.syncState()}
        />

        <PageHeadings
          label={template.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Template",
                      onClick: () => this.archiveTemplate(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Cash Management", url: "/cash"},
            {label: "Store Banks", url: "/banks"},
          ]}
        />

        <TwoColumnList
          label={"Template Information"}
          data={[
            {label: "Name", value: template.NAME},
            {label: "Location", value: template.LOCATION_NAME},
            {label: "Company", value: template.FRANCHISE_NAME},
            {label: "Number of Drawers", value: template.DRAWERS.length},
          ]}
        />

        <Card
          label={"Template Drawers"}
          button={{
            label: "Add Drawer",
            onClick: () => this.drawerModal.open(template),
          }}
        >
          <Table
            data={template.DRAWERS}
            actionButtons={[
              {label: "Delete", onClick: async (row) => this.deleteDrawer(row)},
            ]}
            columns={[
              {value: "DEVICE_NAME", label: "Device Name"},
              {value: "DEVICE_INFO", label: "Type"},
              {
                value: "AMOUNT_OPEN",
                label: "Opening Amount",
                format: (val) => toDollars(val, true),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(BankTemplatePage);
