import React, {Component} from "react";
import PropTypes from "prop-types";
import Card from "./card";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class DescriptionList extends Component {
  render() {
    const {stacked, data = []} = this.props;

    return (
      <Card {...this.props}>
        <dl className={`sm:divide-y sm:divide-gray-200`}>
          {data.map((item, index) => {
            const {label, value} = item;

            return (
              <div
                key={index}
                className={classNames(
                  stacked
                    ? "px-6"
                    : "sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                  `py-4`
                )}
              >
                <dt className="text-sm font-medium text-gray-500">{label}</dt>

                <dd
                  className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2`}
                >
                  {value}
                </dd>
              </div>
            );
          })}
        </dl>
      </Card>
    );
  }
}

DescriptionList.propTypes = {
  ...Card.propTypes,

  stacked: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DescriptionList;
