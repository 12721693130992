import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ProductPricingModal from "../../modals/sales/product/product-pricing-modal";
import InventoryModal from "../../modals/operations/inventory/inventory-modal";
import {request} from "../../utils/request";
import {PRODUCT_ENABLES} from "../../utils/constants";
import ProductDetailsModal from "../../modals/sales/product/product-details-modal";

class ProductsTable extends Component {
  async openInventory(rawProduct) {
    const {inventory, product} = await request(
      "inventory/" + rawProduct.ID,
      "GET"
    );

    this.inventoryModal.open(product, inventory);
  }

  render() {
    const {updateInventory} = this.props;

    const actionItems = [
      {
        label: "View",
        onClick: (row) => {
          this.props.router.navigate("/product/" + row.UNIQUE_ID);
        },
      },
    ];

    if (this.props.updateProduct) {
      actionItems.unshift({
        label: "Quick Edit",
        onClick: (row) => {
          this.productModal.open(row);
        },
      });
    }

    return (
      <>
        <ProductPricingModal
          {...this.props}
          ref={(e) => (this.pricingModal = e)}
        />

        <ProductDetailsModal
          {...this.props}
          ref={(e) => (this.productModal = e)}
        />

        <InventoryModal
          {...this.props}
          ref={(e) => (this.inventoryModal = e)}
          updateState={updateInventory}
        />

        <Table
          {...this.props}
          sorting
          pagination
          searchParams
          ref={(e) => (this.tableRef = e)}
          actionButtons={actionItems}
          columns={[
            {
              width: 1,
              value: "NAME",
              label: "Name",
              format: (value, row) => {
                return (
                  <div className={"flex flex-row justify-between"}>
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <img
                          src={getObjectImage(row, "LOGO", "logo_mark-01.jpg")}
                          className="object-cover h-10 w-10 rounded-full"
                          alt=""
                        />
                      </div>

                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {row.NAME}
                        </div>

                        <div className="text-sm text-gray-500">
                          {row.CATEGORY_NAME}
                        </div>
                      </div>
                    </div>
                    {row?.TAX_RATES?.length === 0 && (
                      <div className={"flex flex-col justify-center ml-10"}>
                        No Tax Rate
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "PRICE",
              label: "Price",
              onClick: this.props.updatePricing
                ? (product) => this.pricingModal.open(product)
                : undefined,
              format: (value, rest) => {
                const pricingModifier = rest?.CUSTOMIZATIONS?.find(
                  (item) => item.TYPE === 5 && item.OPTIONS.length > 0
                );

                return pricingModifier
                  ? toDollars(
                      Math.min(
                        ...pricingModifier?.OPTIONS.map(
                          (modifier) => modifier.PRICE
                        )
                      ),
                      true
                    ) + "+"
                  : toDollars(value, true);
              },
            },
            {
              width: 1,
              value: "INVENTORY",
              label: "Stock",
              onClick: this.props.updateInventory
                ? (product) => this.openInventory(product)
                : undefined,
              format: (value) => value ?? "-",
            },
            {
              onClick: this.props.updateProduct
                ? (product) => this.productModal.open(product, true)
                : undefined,
              value: "ENABLED",
              label: "Enabled Platforms",
              format: (_, product) => {
                return Object.keys(PRODUCT_ENABLES)
                  .filter((item) => {
                    return product[item];
                  })
                  .map((item) => {
                    return PRODUCT_ENABLES[item];
                  })
                  .join(", ");
              },
            },
          ]}
        />
      </>
    );
  }
}

ProductsTable.propTypes = {
  data: PropTypes.array.isRequired,
  sort: PropTypes.array,
  filters: PropTypes.array,

  updatePricing: PropTypes.func,
};

export default withRouter(ProductsTable);
