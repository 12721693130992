import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import CouponForm from "../../../forms/marketing/promotions/coupon-form";

class CouponModal extends Component {
  state = {coupon: null};

  open(coupon = null) {
    this.setState({coupon}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveCoupon({code, max, maxIndividual}) {
    const {coupon} = this.state;

    let couponPayload = {
      MAX_REDEEMS: max ? max : null,
      MAX_ACCOUNT_REDEEMS: maxIndividual ? maxIndividual : null,
      CODE: code,
    };

    await request("promotions/coupons/" + coupon.ID, "PATCH", couponPayload);

    this.props.updateState({...coupon, ...couponPayload});
    this.modal.close();
  }

  render() {
    const {coupon} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Coupon"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        {coupon && (
          <CouponForm
            handleSubmit={this.saveCoupon.bind(this)}
            ref={(e) => (this.formikRef = e)}
            coupon={coupon}
          />
        )}
      </Modal>
    );
  }
}

export default CouponModal;
