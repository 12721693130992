import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";

class LocationsTable extends Component {
  render() {
    const {locations} = this.props;

    return (
      <>
        <Table
          pagination
          className={"mt-4"}
          data={locations}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
          ]}
        />
      </>
    );
  }
}

export default LocationsTable;
