import React, {Component} from "react";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";
import {PROMOTION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/src/helpers/promotion-helper";
import GrayBadge from "../../components/badges/gray-badge";
import {withRouter} from "../../utils/navigation";

class PromotionCard extends Component {
  render() {
    const {handleCard, promotion} = this.props;

    const promotionData = [
      {
        label: "Promotion Name",
        value: promotion.NAME,
      },
      {
        label: "Status",
        value: promotion.ENABLED === 0 ? "Inactive" : "Active",
      },
    ];

    if (promotion.DATE_START) {
      promotionData.push(
        {
          label: "Start Date",
          value: moment(promotion.DATE_START).format("h:mma MM/DD/YY"),
        },
        {
          label: "End Date",
          value: promotion.DATE_END
            ? moment(promotion.DATE_END).format("h:mma MM/DD/YY")
            : "-",
        }
      );
    }

    let typeObject = null;
    let promotionType = "Full Ticket";
    if (promotion?.TYPE === PROMOTION_TYPES.PRODUCT) {
      const {PRODUCTS: products = []} = promotion;
      promotionType = "Product";
      if (products.length > 0 && typeof products[0] === "number") {
        typeObject = {
          label: "Products",
          span: true,
          value:
            products.length + ` product${products.length === 1 ? "" : "s"}`,
        };
      } else {
        if (promotion.IS_COMPANY_WIDE) {
          const productSet = products.reduce((accum, curr) => {
            if (!accum[curr.NAME]) {
              accum[curr.NAME] = 1;
            } else {
              accum[curr.NAME] += 1;
            }

            return accum;
          }, {});

          typeObject = {
            label: "Products",
            span: true,
            value:
              products.length > 0
                ? Object.keys(productSet)
                    .map((name) => {
                      return name + " (" + productSet[name] + " locations)";
                    })
                    .join(", ")
                : "None",
          };
        } else {
          typeObject = {
            label: "Products",
            span: true,
            value:
              products.length > 0
                ? products.map((product) => product.NAME).join(", ")
                : "None",
          };
        }
      }
    } else if (promotion?.TYPE === PROMOTION_TYPES.CATEGORY) {
      const {CATEGORIES: categories = []} = promotion;
      promotionType = "Category";
      if (categories.length > 0 && typeof categories[0] === "number") {
        typeObject = {
          label: "Categories",
          span: true,
          value:
            categories.length +
            ` categor${categories.length === 1 ? "y" : "ies"}`,
        };
      } else {
        if (promotion.IS_COMPANY_WIDE) {
          const catSet = categories.reduce((accum, curr) => {
            if (!accum[curr.NAME]) {
              accum[curr.NAME] = 1;
            } else {
              accum[curr.NAME] += 1;
            }

            return accum;
          }, {});

          typeObject = {
            label: "Categories",
            span: true,
            value:
              categories.length > 0
                ? Object.keys(catSet)
                    .map((name) => {
                      return name + " (" + catSet[name] + " locations)";
                    })
                    .join(", ")
                : "None",
          };
        } else {
          typeObject = {
            span: true,
            label: "Categories",
            value:
              categories.length > 0
                ? categories.map((cat) => cat.NAME).join(", ")
                : "None",
          };
        }
      }
    }

    promotionData.push({label: "Promotion Type", value: promotionType});

    if (typeObject) {
      promotionData.push({
        label: "Once per Order",
        value: promotion.ONCE_PER_ORDER,
        type: "bool",
      });

      promotionData.push(typeObject);
    }

    let discountType = "Flat Amount";
    let discountObj = {
      label: "Discount Amount",
      value: "$" + toDollars(promotion.DISCOUNT.FLAT_FEE),
    };

    if (promotion?.DISCOUNT?.PERCENTAGE) {
      discountType = "Percentage Based";
      discountObj = {
        label: "Discount Percentage",
        value: promotion.DISCOUNT.PERCENTAGE + "%",
      };
    }

    promotionData.push({label: "Discount Type", value: discountType});

    if (discountObj) {
      promotionData.push(discountObj);
    }

    if (promotion.USAGE && promotion.USAGE.length > 0) {
      const usages = promotion.USAGE.length;
      const amount = promotion.USAGE.reduce(
        (accum, usage) => accum + (usage?.AMOUNT_DISCOUNT ?? 0),
        0
      );

      promotionData.push({label: "Times Used", value: usages});
      promotionData.push({
        label: "Amount Used",
        value: toDollars(amount, true),
      });
    }

    if (promotion.TEXT_CAMPAIGN_ID) {
      promotionData.push({
        label: "Text Campaign",
        value: (
          <div
            className="cursor-pointer text-indigo-600 font-medium"
            onClick={() => {
              // TODO fix this to be the unique id
              this.props.router.navigate(
                "/campaigns/text/" + promotion.TEXT_CAMPAIGN_ID
              );
            }}
          >
            View
          </div>
        ),
      });
    }

    return (
      <TwoColumnList
        label={
          promotion.IS_COMPANY_WIDE ? (
            <div className={"flex flex-row items-center"}>
              <div>Promotion</div>
              <GrayBadge className="ml-2 h-1/2">Company-wide</GrayBadge>
            </div>
          ) : (
            "Promotion"
          )
        }
        description="Information about this promotion"
        data={promotionData}
        buttons={[
          {
            label: "Edit Information",
            onClick: handleCard,
          },
        ]}
      />
    );
  }
}

PromotionCard.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default withRouter(PromotionCard);
