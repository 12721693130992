import React, {Component} from "react";
import {Tab, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import TippingSettingsTab from "../../features/shop/shop-settings/tipping-settings-tab";
import LoyaltyTab from "../../features/shop/shop-settings/loyalty-tab";
import MobileOrderingTab from "../../features/shop/shop-settings/mobile-ordering-tab";
import EmployeeManagementTab from "../../features/shop/shop-settings/employee-management-tab";
import OrderSystemTab from "../../features/shop/shop-settings/order-system-tab";
import TimeCardAutoApprovalTab from "../../features/shop/shop-settings/time-card-auto-appoval-tab";
import SchedulerTab from "../../features/shop/shop-settings/scheduler-tab";
import TicketSettingsTab from "../../features/shop/shop-settings/ticket-settings-tab";
import ReportTab from "../../features/shop/shop-settings/report-tab";
import AdvancedTab from "../../features/shop/shop-settings/advanced-tab";
import MarketingTab from "../../features/shop/shop-settings/marketing-tab";
import GiftCardsTab from "../../features/shop/shop-settings/gift-cards-tab";

class ShopSettingsPage extends Component {
  render() {
    return (
      <div>
        <PageHeadings label={"Shop Settings"} />

        <Tab
          data={[
            {label: "Order System", id: "order-system"},
            {label: "Ticket", id: "ticket"},
            {label: "Tipping", id: "tipping"},
            {label: "Loyalty", id: "loyalty"},
            {label: "Marketing", id: "marketing"},
            {label: "Gift Cards", id: "gift-cards"},
            {label: "Mobile Ordering", id: "mobile-ordering"},
            {label: "Employee Management", id: "employee-management"},
            {label: "Time Cards", id: "auto-approval"},
            {label: "Scheduler", id: "scheduler"},
            {label: "Reports", id: "report"},
            {label: "Advanced", id: "advanced"},
          ]}
        >
          {(id) => {
            if (id === "order-system") {
              return <OrderSystemTab />;
            }

            if (id === "ticket") {
              return <TicketSettingsTab />;
            }

            if (id === "tipping") {
              return <TippingSettingsTab />;
            }

            if (id === "loyalty") {
              return <LoyaltyTab />;
            }

            if (id === "gift-cards") {
              return <GiftCardsTab />;
            }

            if (id === "marketing") {
              return <MarketingTab />;
            }

            if (id === "mobile-ordering") {
              return <MobileOrderingTab />;
            }

            if (id === "employee-management") {
              return <EmployeeManagementTab />;
            }

            if (id === "scheduler") {
              return <SchedulerTab />;
            }
            if (id === "report") {
              return <ReportTab />;
            }

            if (id === "auto-approval") {
              return <TimeCardAutoApprovalTab />;
            }

            if (id === "advanced") {
              return <AdvancedTab />;
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(ShopSettingsPage));
