import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES, TAX_COLUMNS,
  TAXABLE_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";

class TaxesReportPage extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      let {BREAKDOWN, TOTALS} = reportData;

      for (let tax of BREAKDOWN) {
        let {NAME, AMOUNT} = tax;

        rows.push({
          label: NAME,
          numbers: {
            AMOUNT,
          },
        });
      }

      rows.push({
        label: "Total",
        numbers: {
          AMOUNT: TOTALS.AMOUNT
        },
        style: [CELL_TYPES.BOLD],
      });
    }


    return rows;
  }

  render() {
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Taxes Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="taxes"
          endpoint="report/taxes"
          constantColumns={TAX_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={false}
        />
      </div>
    );
  }
}

export default TaxesReportPage;
