import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormPhone,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import FormRow from "../../../components/form-row";
import FormStateSelect from "../../../components/form-state-select";

class ShippingAddressModal extends Component {
  state = {address: null};

  open(address = null) {
    this.setState({address}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createBox({name, phone, internal, lineOne, lineTwo, city, state, zipcode}) {

    const boxPayload = {
      ADDRESS_NAME: name,
      ADDRESS_IDENTIFIER: internal,
      PHONE: "1" + phone.replaceAll(" ", ""),
      ADDRESS: {
        LINE_ONE: lineOne,
        LINE_TWO: lineTwo,
        ZIPCODE: zipcode,
        STATE: state,
        CITY: city,
        LATITUDE: 0,
        LONGITUDE: 0,
      }
    };

    const serverAddresses = await request(
      "ecommerce/shippingaddresses",
      "POST",
      boxPayload
    );

    this.props.addState(serverAddresses);
    this.modal.close();
  }

  async saveBox({name, length, width, height}) {
    const {box} = this.state;
    const boxPayload = {
      NAME: name,
      LENGTH: length,
      WIDTH: width,
      HEIGHT: height,
    };

    const serverBox = await request(
      "ecommerce/shippingboxes/" + box.ID,
      "POST",
      boxPayload
    );

    this.props.updateState(serverBox.ID, serverBox);
    this.modal.close();
  }

  async removeBox() {
    const {box} = this.state;

    let serverPrize = await request(
      "ecommerce/shippingboxes/" + box.ID,
      "DELETE",
      {}
    );

    this.props.updateState(box.ID);
    this.modal.close();
  }

  render() {
    const {address} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={address ? "Save" : "Add"}
        label={address ? "Edit Box" : "Create Box"}
        deleteOnClick={() => this.removeBox()}
        formikOnClick={() => this.formikRef}
        deleteLabel={address && "Delete"}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            internal: address?.ADDRESS_IDENTIFIER ?? "",
            name: address?.ADDRESS_NAME ?? "",
            phone: address?.PHONE ?? "",
            lineOne: address?.ADDRESS?.LINE_ONE ?? "",
            lineTwo: address?.ADDRESS?.LINE_TWO ?? "",
            city: address?.ADDRESS?.CITY ?? "",
            state: address?.ADDRESS?.STATE ?? "",
            zipcode: address?.ADDRESS?.ZIPCODE ?? "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            phone: Yup.string().required("Phone is required"),
            internal: Yup.string().required("Internal Name is required"),
            lineOne: Yup.string().required("Line One is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zipcode: Yup.string().required("Zipcode is required"),
          })}
          onSubmit={address ? this.saveBox.bind(this) : this.createBox.bind(this)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="internal"
                  options={formikOptions}
                  label="Internal Name"
                  placeholder="Store Location"
                />

                <FormPhone
                  name="phone"
                  options={formikOptions}
                  label="Phone"
                  placeholder="234-342-1234"
                />

                <FormInput
                  name="name"
                  options={formikOptions}
                  label="Return Label Name"
                  placeholder="John Doe"
                />

                <FormInput
                  name="lineOne"
                  options={formikOptions}
                  label="Line One"
                />

                <FormInput
                  name="lineTwo"
                  options={formikOptions}
                  label="Line Two"
                />

                <FormRow>
                  <FormInput name="city" label="City" options={formikOptions} />

                  <FormStateSelect
                    name="state"
                    label="State"
                    options={formikOptions}
                    flex={1}
                  />

                  <FormInput
                    name="zipcode"
                    label="Zipcode"
                    options={formikOptions}
                    flex={1}
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(ShippingAddressModal);
