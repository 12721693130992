import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class BankAccountDetailsModal extends Component {
  state = {bankName: null, accountNumber: null, routingNumber: null};

  open(bankName, accountNumber, routingNumber) {
    this.setState({bankName, accountNumber, routingNumber}, this.slide.open());
  }

  render() {
    let {bankName, accountNumber, routingNumber} = this.state;

    return (
      <Modal ref={(e) => (this.slide = e)}>
        <FormInput
          disabled
          value={bankName}
          label={"Bank Name"}
        />

        <FormInput
          disabled
          value={accountNumber}
          label={"Account Number"}
        />

        <FormInput
          disabled
          value={routingNumber}
          label={"Routing Number"}
        />
      </Modal>
    );
  }
}

export default BankAccountDetailsModal;
