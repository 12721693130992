import React, {Component} from "react";
import {
  Card,
  Table,
  PageHeadings,
  FormInput,
  Loading,
  Tab,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  getPayrollEmployees,
  includeEmployeeInPayroll,
} from "../../../utils/payroll-helper";
import {Settings} from "react-feather";
import PayrollEmployeeSettingsModal from "../../../modals/payroll/payroll-employee-settings-modal";
import Fuse from "fuse.js";
import DangerBadge from "../../../components/badges/danger-badge";
import PayrollSkeletonEmployeeSettingsModal from "../../../modals/payroll/payroll-skeleton-employee-settings-modal";
import SuccessBadge from "../../../components/badges/success-badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setupReduxConnection} from "../../../redux";

class PayrollEmployeesPage extends Component {
  state = {
    isLoading: true,
    payrollEmployees: null,
    search: "",
    locations: null,
  };

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let emps = await getPayrollEmployees();
    emps = emps.map((employee) => {
      return {
        ...employee,
        id: employee.ID,
        fullName: employee.FULL_NAME,
        payrollStatus: employee.PAYROLL_STATUS,
      };
    });

    let locationsDict = emps.reduce((dict, emp) => {
      dict[emp.LOCATION_ID] = {id: emp.LOCATION_ID, label: emp.LOCATION_NAME};

      return dict;
    }, {});

    this.setState({
      payrollEmployees: emps,
      locations: Object.values(locationsDict),
      isLoading: false,
    });

    return true;
  };

  renderIncludeBadge(row) {
    let {
      INCLUDE_IN_REGULAR,
      DEFAULT_IS_DIRECT_DEPOSIT,
      ONBOARDING_NEEDED_FOR_NEXT_PAYRUN,
    } = row;
    let {status} = row.PAYROLL.onboard;
    let {payment_method_preference} = row.PAYROLL;

    return (
      <div>
        {ONBOARDING_NEEDED_FOR_NEXT_PAYRUN && (
          <DangerBadge>Needed Before Next Payrun</DangerBadge>
        )}

        {!INCLUDE_IN_REGULAR && status !== "none" && (
          <span
            className="float-right inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 cursor-pointer"
            onClick={() => this.payrollEmployeesSettingsModal.open(row)}
          >
            Not Included in Payruns
          </span>
        )}

        {payment_method_preference === "manual" && status !== "none" && (
          <span
            className="float-right inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 cursor-pointer"
            onClick={() => this.payrollEmployeesSettingsModal.open(row)}
          >
            Manual Pay
          </span>
        )}
      </div>
    );
  }

  renderStatus(row) {
    let {status} = row.PAYROLL.onboard;

    return (
      <div className="flex flex-row items-center">
        <div className="w-28">{this.renderStatusBadge(row)}</div>

        {status !== "none" && (
          <Settings
            className="ml-3 h-5 w-5 cursor-pointer"
            onClick={() => {
              if (!row.ACTIVE) {
                this.payrollSkeletonEmployeeSettingsModal.open(row);
              } else {
                this.payrollEmployeesSettingsModal.open(row);
              }
            }}
          />
        )}
      </div>
    );
  }

  renderStatusBadge(row) {
    let {status} = row.PAYROLL.onboard;

    if (status === "none") {
      return (
        <button
          type="button"
          className="inline-flex items-center px-2.5 py-0.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
          onClick={async () => {
            await includeEmployeeInPayroll(row.ID);
            await this.refresh();

            row = {
              ...row,
              INCLUDE_IN_REGULAR: 1,
            };

            if (!row.ACTIVE) {
              this.payrollSkeletonEmployeeSettingsModal.open(row);
            } else {
              this.payrollEmployeesSettingsModal.open(row);
            }
          }}
        >
          Add
        </button>
      );
    }

    return (
      <div>
        {(status === "blocking" || status === "not_started" || status === "needs_attention") && (
          <DangerBadge className={"h-5"}>
            <FontAwesomeIcon icon={"x"} className="h-3 w-3"/>
          </DangerBadge>
        )}

        {status === "completed" && (
          <SuccessBadge className={"h-5"}>
            <FontAwesomeIcon icon={"check"} className="h-3 w-3"/>
          </SuccessBadge>
        )}
      </div>
    );
  }

  renderFilteredEmployees(filters) {
    let {payrollEmployees, search} = this.state;

    const columns = [
      {label: "Name", value: "fullName"},
      {
        label: "",
        value: "",
        width: 1,
        format: (value, row) => {
          return this.renderIncludeBadge(row);
        },
      },
      {
        label: "Onboarding Status",
        value: "",
        width: 1,
        format: (value, row) => {
          return this.renderStatus(row);
        },
      },
      {
        label: "Employee Status",
        value: "",
        width: 1,
        format: (value, row) => {
          return (
            <div>
              {!!row.ACTIVE && <div className={"text-green-600"}>Active</div>}
              {!row.ACTIVE && <div>Inactive</div>}
            </div>
          );
        },
      },
    ];

    let sortedEmployees = payrollEmployees?.sort((a, b) => {
      let onboardingNeededValue =
        b.ONBOARDING_NEEDED_FOR_NEXT_PAYRUN |
        (0 - a.ONBOARDING_NEEDED_FOR_NEXT_PAYRUN) |
        0;

      let activityValue = b.ACTIVE - a.ACTIVE;

      let statusValue =
        STATUS_SEQ[b.PAYROLL.onboard.status] -
        STATUS_SEQ[a.PAYROLL.onboard.status];

      let alphabetizedValue = a.FULL_NAME.localeCompare(
        b.FULL_NAME
      );

      return (
        onboardingNeededValue * 10000 +
        statusValue * 1000 +
        activityValue * 100 +
        alphabetizedValue
      );
    });

    if (search?.length > 0) {
      sortedEmployees = new Fuse(sortedEmployees, {
        keys: ["FULL_NAME"],
        useExtendedSearch: true,
        threshold: 0.1,
      })
        .search(search)
        .map(({item}) => item);
    }

    return (
      <Table
        data={sortedEmployees}
        columns={columns}
        filters={filters}
      />
    );
  }

  render() {
    let {isLoading, payrollEmployees, locations, search} = this.state;
    let {location} = this.props.shop;

    if (isLoading) {
      return <Loading/>;
    }

    let activeFilter = (item) => {
      return item.ACTIVE;
    };

    let inactiveFilter = (item) => {
      return !item.ACTIVE;
    };

    let locationDefault = {};

    if (location.TYPE === "STORE") {
      locationDefault = {filter: "homeLocation", id: location.ID}
    }

    return (
      <div>
        <PayrollEmployeeSettingsModal
          onSave={this.refresh}
          ref={(e) => (this.payrollEmployeesSettingsModal = e)}
        />
        <PayrollSkeletonEmployeeSettingsModal
          onSave={this.refresh}
          ref={(e) => (this.payrollSkeletonEmployeeSettingsModal = e)}
        />
        <PageHeadings
          label="Payroll Employees"
          description={"Manage your employee's payroll onboarding process"}
        />
        {/*<FormInput*/}
        {/*  className={"py-2"}*/}
        {/*  placeholder={"Search by name"}*/}
        {/*  onChange={(e) => {*/}
        {/*    this.setState({search: e.target.value});*/}
        {/*  }}*/}
        {/*/>*/}

        <Filter
          defaultFilters={[
            {filter: "status", id: 1},
            locationDefault
          ]}
          data={[
            {
              id: "homeLocation",
              label: "Home Location",
              options: locations,
              onFilter: (options, data) => {
                if (options.length === 0) {
                  return data;
                }

                return data.filter((item) => options.includes(item.LOCATION_ID));
              }
            },
            {
              id: "status",
              label: "Status",
              onFilter: (options, data) => {
                return data.filter((item) => options.includes(item.ACTIVE));
              },
              options: [
                {label: "Active", id: 1},
                {label: "Inactive", id: 0},
              ],
            },
          ]}
        >
          {(filters) => {
            return this.renderFilteredEmployees(filters);
          }}
        </Filter>
      </div>
    );
  }
}

const STATUS_SEQ = {
  none: 0,
  completed: 1,
  not_started: 3,
  blocking: 3,
  needs_attention: 2,
};

export default setupReduxConnection(["shop"])(PayrollEmployeesPage);
