import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronDown, ChevronRight} from "react-feather";

class ExpandableListComponent extends Component {
  state = {expanded: false};

  render() {
    const {
      header,
      data = [],
      headerStyle = "",
      listStyle = "",
      elementStyle = "",
      containerStyle = "",
    } = this.props;

    const {expanded} = this.state;

    return (
      <div className={"flex-auto"}>
        <div className={classNames("flex flex-row", containerStyle)}>
          <div className={"flex cursor-pointer items-center mr-2"}>
            {!expanded && (
              <ChevronRight
                className={"h-4 w-4"}
                onClick={() => this.setState({expanded: true})}
              />
            )}

            {expanded && (
              <ChevronDown
                className={"h-4 w-4"}
                onClick={() => this.setState({expanded: false})}
              />
            )}
          </div>

          <div className={classNames(headerStyle)}>{header}</div>
        </div>

        {expanded && (
          <div className={classNames(listStyle)}>
            {data.map((element) => {
              return <div className={classNames(elementStyle)}>{element}</div>;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default ExpandableListComponent;
