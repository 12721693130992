import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  minutesToHours,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import TimeCardModal from "../../../modals/team/time-card-modal";
import {STATE_OVERTIME_RULES} from "./reporting-constants";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import TimeCardBreaks from "../../team/time-card-breaks";
import {fetchUnpaidBreaksTotalMinutes} from "../../../utils/breaks-helper";
import DangerBadge from "../../../components/badges/danger-badge";

class TimeCardSummaryExpansionComponent extends Component {
  getTabs() {
    let {groupedByRole} = this.props;

    let tabs = [{id: "timecards", label: "Time Cards"}];

    if (
      !groupedByRole &&
      (this.props.row?.PAY_TOTALS?.AMOUNT_OVERTIME_MINUTES > 0 ||
        this.props.row?.PAY_SUMMARY?.AMOUNT_OVERTIME_MINUTES > 0)
    ) {
      tabs.push({id: "overtime-calculations", label: "Overtime Calculations"});
    }

    if (!groupedByRole) {
      tabs.push({id: "payrates", label: "Pay Rates"});
    }

    return tabs;
  }

  getTimeCardsColumns() {
    let {groupedByRole, breakOptions} = this.props;
    let {showTips} = this.props;
    let {SHOW_BREAKS_OUT_OF_COMPLIANCE} = this.props.shop.settings;

    showTips = true;

    let showOvertime =
      this.props.shop.settings.TIME_CARDS_CALCULATE_OVERTIME === "1";

    let DAILY_DOUBLE =
      STATE_OVERTIME_RULES[this.props.shop.location.STATE].DAILY_DOUBLE;

    const TIMECARDS_HEADERS = [
      {
        label: "Location",
        value: "TIMESHEET_LOCATION_NAME",
      },
      {
        label: !groupedByRole ? "Role" : "Name",
        value: "ROLE",
        format: (value, row) => {
          if (groupedByRole) {
            return row?.FULL_NAME;
          }

          return row?.ROLE_NAME ?? "No Role";
        },
      },
      {
        label: "Date",
        selector: "DATE_START",
        format: (value, row) => (
          <div>
            <div>{`${moment(row.DATE_START).format("MM/DD")}`}</div>

            <div>{`${moment(row.DATE_START).format("h:mm A")} - ${moment(
              row.DATE_END
            ).format("h:mm A")}`}</div>
          </div>
        ),
      },
      {
        label: "Scheduled Date",
        value: "SHIFT_DATE_START",
        format: (value, row) => {
          if (!value || !row.SHIFT_DATE_END) {
            return <div />;
          }

          return (
            <div>
              <div>{`${moment(row.SHIFT_DATE_START).format("MM/DD")}`}</div>

              <div>{`${moment(row.SHIFT_DATE_START).format(
                "h:mm A"
              )} - ${moment(row.SHIFT_DATE_END).format("h:mm A")}`}</div>
            </div>
          );
        },
      },
      {
        label: "Total",
        value: "AMOUNT_MINUTES",
        format: (value, row) => (
          <div>
            <div>{`${minutesToHours(row?.AMOUNT_MINUTES ?? 0)} hrs`}</div>

            <div>{toDollars(row?.AMOUNT_PAYED ?? 0, true)}</div>
          </div>
        ),
        sortable: true,
        sortFunction: (a, b) => {
          return (a?.AMOUNT_MINUTES ?? 0) - (b?.AMOUNT_MINUTES ?? 0);
        },
      },
      // {
      //   label: "Hours Scheduled",
      //   value: "AMOUNT_DOGS",
      //   format: (value, row) => {
      //     if (row.Shifts.ID) {
      //       return (
      //         (row.Shifts.DATE_END - row.Shifts.DATE_START) / (1000 * 60 * 60)
      //       );
      //     }
      //
      //     return "None";
      //   },
      // },
    ];

    if (showOvertime) {
      TIMECARDS_HEADERS.splice(
        TIMECARDS_HEADERS.length - 1,
        0,
        ...[
          {
            label: "Regular",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(
                  row?.AMOUNT_REGULAR_MINUTES ?? 0
                )} hrs`}</div>

                <div>{toDollars(row?.AMOUNT_REGULAR_GROSS ?? 0, true)}</div>
              </div>
            ),
          },
          {
            label: "OT",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(
                  row?.AMOUNT_OVERTIME_MINUTES ?? 0
                )} hrs`}</div>

                <div>{toDollars(row?.AMOUNT_OVERTIME_GROSS ?? 0, true)}</div>
              </div>
            ),
          },
        ]
      );
    }

    if (showOvertime && DAILY_DOUBLE) {
      TIMECARDS_HEADERS.splice(
        TIMECARDS_HEADERS.length - 1,
        0,
        ...[
          {
            label: "2x OT",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(
                  row?.AMOUNT_DOUBLE_OVERTIME_MINUTES ?? 0
                )} hrs`}</div>

                <div>
                  {toDollars(row?.AMOUNT_DOUBLE_OVERTIME_GROSS ?? 0, true)}
                </div>
              </div>
            ),
          },
        ]
      );
    }

    if (showTips) {
      // TIMECARDS_HEADERS.push({
      //   label: "Tips",
      //   selector: "AMOUNT_TIPS",
      //   format: (value, row) => toDollars(row?.AMOUNT_TIPS ?? 0, true),
      // });
    }

    if (breakOptions && breakOptions.length > 0) {
      TIMECARDS_HEADERS.splice(TIMECARDS_HEADERS.length - 1, 0, {
        label: "Unpaid Breaks",
        value: "UNPAID_BREAK_DURATION",
        format: (value, row) => {
          let displayStr = `${fetchUnpaidBreaksTotalMinutes(
            row.BREAKS ?? []
          )} mins`;

          if (SHOW_BREAKS_OUT_OF_COMPLIANCE && row.BREAKS_OUT_OF_COMPLIANCE) {
            return (
              <DangerBadge className={"text-sm font-normal"}>
                {displayStr}
              </DangerBadge>
            );
          }

          return displayStr;
        },
        sortable: true,
        sortFunction: (a, b) => {
          return (a?.AMOUNT_MINUTES ?? 0) - (b?.AMOUNT_MINUTES ?? 0);
        },
      });
    }

    return TIMECARDS_HEADERS;
  }

  renderOvertimePay() {
    let {PAY_WEEK_BREAKDOWN} = this.props.row;

    let data = Object.keys(PAY_WEEK_BREAKDOWN).map((itemKey) => ({
      START_DATE: parseInt(itemKey),
      ...PAY_WEEK_BREAKDOWN[itemKey],
    }));

    let columns = [
      {
        label: "Work Week",
        value: "START_DATE",
        format: (value, row) => {
          return `${moment.utc(value).format("MM/DD")} - ${moment
            .utc(value)
            .add(6, "days")
            .format("MM/DD")}`;
        },
      },

      {
        label: "Regular",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>{minutesToHours(row.AMOUNT_REGULAR_MINUTES)} hrs</div>
              <div>{toDollars(row.AMOUNT_REGULAR_WAGE, true)}/hr</div>
            </div>
          );
        },
      },
      {
        label: "Overtime",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>{minutesToHours(row.AMOUNT_OVERTIME_MINUTES)} hrs</div>
              <div>{toDollars(row.AMOUNT_OVERTIME_WAGE, true)}/hr</div>
            </div>
          );
        },
      },
    ];

    let DAILY_DOUBLE =
      STATE_OVERTIME_RULES[this.props.shop.location.STATE].DAILY_DOUBLE;

    if (DAILY_DOUBLE) {
      columns.push({
        label: "Double Overtime",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>
                {minutesToHours(row.AMOUNT_DOUBLE_OVERTIME_MINUTES)} hrs
              </div>
              <div>{toDollars(row.AMOUNT_DOUBLE_OVERTIME_WAGE, true)}/hr</div>
            </div>
          );
        },
      });
    }

    return <Table columns={columns} data={data} />;
  }

  render() {
    const {breakOptions} = this.props;

    return (
      <div className="px-10 py-3 pb-20 text-sm">
        <TimeCardModal
          onChange={async () => {
            if (this.props.refresh) {
              await this.props.refresh();
            }
          }}
          breakOptions={breakOptions}
          ref={(e) => (this.cardModal = e)}
        />

        <Tab data={this.getTabs()}>
          {(id) => {
            return (
              <div className="border border-gray-200 mt-4">
                {id === "payrates" && (
                  <Table
                    columns={PAY_RATES_HEADERS}
                    data={this.props.row.PAY_RATES}
                  />
                )}

                {id === "timecards" && (
                  <Table
                    actionButtons={[]}
                    columns={this.getTimeCardsColumns()}
                    data={this.props.row.TIMESHEETS?.sort(
                      (a, b) => a.DATE_START - b.DATE_START
                    )}
                    onClick={(row) => this.cardModal.open(row)}
                  />
                )}

                {id === "overtime-calculations" && this.renderOvertimePay()}
              </div>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(
  TimeCardSummaryExpansionComponent
);

const PAY_RATES_HEADERS = [
  {
    label: "Name",
    value: "PAY_RATE_NAME",
  },
  {
    label: "Role",
    value: "ROLE_NAME",
  },
  {
    label: "Rate",
    value: "PAY_RATE_AMOUNT",
    format: (value) => toDollars(value ?? 0, true),
  },
  {
    label: "Hours Worked",
    selector: "AMOUNT_MINUTES",
    format: (value, row) => minutesToHours(row?.AMOUNT_MINUTES ?? 0),
  },
  {
    label: "Pay Earned",
    selector: "AMOUNT_PAYED",
    format: (value, row) => toDollars(row?.AMOUNT_PAYED ?? 0, true),
  },
];
