import React, {Component} from "react";
import {request} from "../../../utils/request";
import {
  Loading,
  Card,
  FormInput,
  Button,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {cloneDeep} from "lodash";
import PdfViewer from "../../../components/pdf-viewer";
import {
  DOCUMENT_FIELD_DEFAULTS,
  DOCUMENT_FIELD_ICON_MAP,
  DOCUMENT_FIELDS,
} from "../../../utils/constants";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showErrorAlert, showSuccessAlert} from "../../../utils/alert-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import * as Yup from "yup";
import ComboBox from "../../../components/combobox";
import {showSuccessNotification} from "../../../utils/notification-helper";
import MultiEmployeeSelect from "../../../components/multi-employee-select";
import GrayBadge from "../../../components/badges/gray-badge";

class ConfigureDocumentPage extends Component {
  state = {
    document: null,
    fields: [],
    page: 1,
    currentField: null,
    error: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UNIQUE_ID} = this.props.router.params;

    const document = await request(
      "company-documents/file/" + UNIQUE_ID,
      "GET"
    );

    const fields = await request(
      "company-documents/fields/" + document.ID,
      "GET"
    );

    fields.forEach((field) => {
      field.ICON = DOCUMENT_FIELD_ICON_MAP[field.TYPE];
    });

    this.setState({document, fields});
  }

  updatePage(page) {
    this.setState({page});
  }

  async saveFields() {
    const {fields, document} = this.state;

    const fieldErrors = fields.filter((field) => {
      if (!field) {
        return false;
      }

      const error =
        field &&
        !(field.ASSIGNEE && field.ASSIGNEE.assignedType) &&
        field.TYPE !== "PHONE" &&
        field.TYPE !== "EMAIL" &&
        field.TYPE !== "COMPANY" &&
        field.TYPE !== "DATE_SIGNED";

      field.ERROR = !!error;
      return error;
    });

    if (fieldErrors.length > 0) {
      this.setState({error: "Please assign all fields", fields});
      return;
    }

    const payload = fields
      .filter((field) => !!field)
      .map((field) => {
        return {
          ID: field.ID,
          DOCUMENT_ID: document.ID,
          X_COORDINATE: Math.round(
            field.X_COORDINATE ?? DOCUMENT_FIELD_DEFAULTS.X_COORDINATE
          ),
          Y_COORDINATE: Math.round(
            field.Y_COORDINATE ?? DOCUMENT_FIELD_DEFAULTS.Y_COORDINATE
          ),
          WIDTH: field.WIDTH ?? DOCUMENT_FIELD_DEFAULTS.WIDTH,
          HEIGHT: field.HEIGHT ?? DOCUMENT_FIELD_DEFAULTS.HEIGHT,
          TYPE: field.TYPE,
          UNIQUE_ID: field.UNIQUE_ID,
          PAGE: field.PAGE,
          OPTIONS: field.OPTIONS ? JSON.stringify(field.OPTIONS) : null,
          ASSIGNEE: field.ASSIGNEE,
          REQUIRED: field.REQUIRED ? 1 : 0,
        };
      });

    await request("company-documents/fields", "PATCH", {
      DOCUMENT_ID: document.ID,
      FIELDS: payload,
    });

    try {
      await this.syncState();
      return showSuccessAlert("Fields saved successfully.");
    } catch (e) {
      showErrorAlert("Fields failed to save.");
    }
  }

  addField(field) {
    const {page} = this.state;
    field.UNIQUE_ID = "fld_" + randomString(32);
    field.PAGE = page;
    field.ASSIGNEE = {assignedType: null, assigned: []};
    field.REQUIRED = true;

    this.setState((prevState) => {
      const {fields} = prevState;
      const setField = cloneDeep(field);
      return {fields: [...fields, setField], currentField: setField};
    });
  }

  deleteField(field) {
    this.setState((prevState) => {
      const {fields} = prevState;

      const idx = fields.findIndex(
        (item) => item?.UNIQUE_ID === field.UNIQUE_ID
      );

      if (idx !== -1) {
        fields[idx] = undefined;
      }

      return {fields};
    });
  }

  updateField(field) {
    this.setState((prevState) => {
      const {fields} = prevState;

      const fieldIndex = fields.findIndex(
        (_field) => _field?.UNIQUE_ID === field.UNIQUE_ID
      );

      if (fieldIndex === -1) {
        return {};
      }

      fields.splice(fieldIndex, 1, field);

      return {fields};
    });
  }

  renderOptionsMenu() {
    const {error} = this.state;

    return (
      <div className={"flex flex-col"}>
        {DOCUMENT_FIELDS.map((item, index) => (
          <button
            key={index + "_" + randomString(24)}
            className={
              "text-sm text-center mx-10 my-2 border border-indigo-500 bg-white text-indigo-500 rounded-md px-3 py-2 hover:bg-indigo-500 hover:text-white transition-colors ease-in-out duration-200"
            }
            onClick={() => this.addField(item)}
          >
            {item.LABEL} {item.ICON}
          </button>
        ))}

        <Button
          onClick={() => {
            this.saveButtonRef.startLoading();
            this.saveFields().then(() => this.saveButtonRef.stopLoading());
          }}
          className={
            "text-sm text-center mx-10 my-2 border rounded-md px-3 py-2 bg-indigo-500 text-white hover:bg-indigo-700 transition-colors ease-in-out duration-200"
          }
          label="Save Fields"
          ref={(e) => (this.saveButtonRef = e)}
        />

        {error && (
          <div className={"text-red-500 text-xs text-center"}>{error}</div>
        )}
      </div>
    );
  }

  addDropdownOption(field) {
    field.OPTIONS.push({
      NAME: null,
      ID: field.UNIQUE_ID + "_" + field.OPTIONS.length,
    });

    this.updateField(field);
  }

  removeFieldOption(field, option) {
    const index = field.OPTIONS.findIndex(
      (_option) => option.ID === _option.ID
    );

    if (index !== -1) {
      field.OPTIONS.splice(index, 1);
      this.updateField(field);
    }
  }

  renderDropdownOptions(field) {
    const {OPTIONS} = field;

    return (
      <Card
        label={"Dropdown"}
        className={"mx-10 w-1/4 "}
        buttons={[
          {
            label: "Add Option +",
            onClick: () => this.addDropdownOption(field),
          },
        ]}
      >
        <div className="flex flex-col justify-between">
          <div>
            {OPTIONS?.map((option) => {
              return (
                <div className={"flex-row flex mx-2 align-middle"}>
                  <FormInput
                    name={field.UNIQUE_ID + "_" + field.OPTIONS.length}
                    className={"mr-2"}
                    onChange={(val) => (option.NAME = val.target.value)}
                    value={option.NAME}
                    flex
                  />

                  <button
                    className={"text-gray-500 hover:text-gray-700 align-middle"}
                    onClick={() => this.removeFieldOption(field, option)}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-x" />
                  </button>
                </div>
              );
            })}
          </div>

          {this.renderAssignmentOptions(field, true)}
        </div>
      </Card>
    );
  }

  renderAssignmentOptions(field, minWidth = false) {
    const {ASSIGNEE} = field;

    return (
      <Formik
        key={field.UNIQUE_ID + "_assignment"}
        onSubmit={({assigned, assignedType}) => {
          field.ASSIGNEE = {assigned, assignedType};
          this.updateField(field);
          showSuccessNotification("Assigned Employee Saved");
        }}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          assignedType: ASSIGNEE?.assignedType,
          assigned: ASSIGNEE?.assigned,
          required: true,
        }}
        validationSchema={Yup.object().shape({
          assigned: Yup.number().nullable(),
          assignedType: Yup.string()
            .nullable()
            .required("Please select an assignee type"),
        })}
      >
        {(formikOptions) => {
          const {values, handleSubmit} = formikOptions;

          return (
            <form
              onSubmit={handleSubmit}
              className={classNames(
                "flex flex-col",
                minWidth ? "min-width-fit" : "min-w-[25%] mx-10"
              )}
            >
              <Card
                label={
                  <div className={"flex flex-row"}>
                    Field Assignment
                    <div className={"flex flex-row justify-end items-center"}>
                      <input
                        id={"required"}
                        name={"required"}
                        type="checkbox"
                        checked={field.REQUIRED}
                        aria-describedby={"required-description"}
                        className="ml-2 focus:ring-primary-border h-4 w-4 text-primary-text border-neutral-border rounded"
                        onChange={(e) => {
                          field.REQUIRED = e.target.checked;
                          this.updateField(field);
                        }}
                      />

                      <GrayBadge darkGrey className={"ml-3 "}>
                        Required
                      </GrayBadge>
                    </div>
                  </div>
                }
              >
                <ComboBox
                  label={"Who should fill out this field?"}
                  className={"mx-2 my-2"}
                  name={"assignedType"}
                  data={[
                    {id: "REVIEWER", label: "A reviewer"},
                    {id: "EMPLOYEE", label: "The onboarding employee"},
                  ]}
                  options={formikOptions}
                  onChangeSoft={(e) => {
                    field.ASSIGNEE.assignedType = e.id;
                    this.updateField(field);
                  }}
                />

                {values.assignedType === "REVIEWER" && (
                  <MultiEmployeeSelect
                    className={"mx-2 my-2"}
                    label={"Would you like to select assigned reviewers?"}
                    name="assigned"
                    options={formikOptions}
                    placeholder="Click to select employee"
                    onChangeSoft={(assigned) => {
                      field.ASSIGNEE.assigned = assigned;
                      this.updateField(field);
                    }}
                  />
                )}
              </Card>
            </form>
          );
        }}
      </Formik>
    );
  }

  renderRadioOptions(field) {}

  renderExtraOptions() {
    const {currentField} = this.state;
    const {TYPE} = currentField;

    if (TYPE === "DROPDOWN") {
      return this.renderDropdownOptions(currentField);
    } else if (
      TYPE === "SIGNATURE" ||
      TYPE === "INITIALS" ||
      TYPE === "TEXTBOX" ||
      TYPE === "CHECKBOX"
    ) {
      return this.renderAssignmentOptions(currentField);
    }
  }

  render() {
    const {document, fields, currentField} = this.state;

    if (!document || !fields) {
      return <Loading />;
    }

    return (
      <>
        <div
          className={
            "inline-block flex flex-row justify-items-between  items-start"
          }
        >
          {this.renderOptionsMenu()}

          <PdfViewer
            document={document}
            fields={fields}
            deleteField={(field) => this.deleteField(field)}
            updatePage={(page) => this.updatePage(page)}
            onFieldClick={(field) => this.setState({currentField: field})}
          />

          {currentField && this.renderExtraOptions()}
        </div>
      </>
    );
  }
}

export default withRouter(ConfigureDocumentPage);
