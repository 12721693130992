import React, {Component} from "react";
import {
  FormInput,
  FormBoolean,
  Modal,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import moment from "moment";

export const DAYS = [
  {label: "Monday", value: "0"},
  {label: "Tuesday", value: "1"},
  {label: "Wednesday", value: "2"},
  {label: "Thursday", value: "3"},
  {label: "Friday", value: "4"},
  {label: "Saturday", value: "5"},
  {label: "Sunday", value: "6"},
];

class InventoryRuleModal extends Component {
  state = {rule: null, id: -1};

  open(rule = null, id = -1) {
    this.setState({rule, id}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createRule({time, type, quantity, days}) {
    const {product} = this.props;

    const startOfDay = moment().startOf("day");
    const start = moment(startOfDay.format("YYYY-MM-DD") + " " + time);
    const finalTime = start.valueOf() - startOfDay.valueOf();

    const rulePayload = {
      DAYS: days,
      RESET: type === "0",
      TIME: finalTime + new Date().getTimezoneMilliOffset(),
      QUANTITY: quantity,
    };

    let rules = await request(
      "inventory/rule/" + product.ID,
      "POST",
      rulePayload
    );

    this.props.addState({ID: rules[0].ID, RULES: rules});
    this.modal.close();
  }

  async saveRule({time, type, quantity, days}) {
    const {rule, id} = this.state;

    const startOfDay = moment().startOf("day");
    const start = moment(startOfDay.format("YYYY-MM-DD") + " " + time);
    const finalTime = start.valueOf() - startOfDay.valueOf();

    const rulePayload = {
      DAYS: days,
      RESET: "" + type !== "1",
      TIME: finalTime + new Date().getTimezoneMilliOffset(),
      QUANTITY: quantity,
    };

    const serverRules = await request(
      "inventory/rule/" + rule[0].ID,
      "PATCH",
      rulePayload
    );

    this.props.updateState(id, {ID: id, RULES: serverRules});
    this.modal.close();
  }

  async deleteRule() {
    const {rule, id} = this.state;
    const {product} = this.props;

    const serverLine = await request(
      "inventory/rule/" + product.ID + "/" + rule[0].ID,
      "DELETE",
      {}
    );

    this.props.updateState(id);
    this.modal.close();
  }

  render() {
    const {rule} = this.state;

    return (
      <Modal
        deleteLabel={rule && "Delete"}
        deleteOnClick={this.deleteRule.bind(this)}
        buttonLabel={rule ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={rule ? "Edit Rule" : "Create Task"}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            time: rule
              ? moment()
                  .startOf("day")
                  .add(
                    rule[0].OFFSET - new Date().getTimezoneMilliOffset(),
                    "milliseconds"
                  )
                  .format("hh:mm")
              : null,
            quantity: rule ? rule[0].QUANTITY : "",
            type: rule ? "" + rule[0].TYPE : "0",
            days: rule ? rule.map((item) => "" + item.DAY) : [],
          }}
          onSubmit={
            rule ? this.saveRule.bind(this) : this.createRule.bind(this)
          }
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  type="time"
                  label="Time"
                  name="time"
                  options={formikOptions}
                />

                <FormInput
                  options={formikOptions}
                  label="Quantity"
                  name="quantity"
                />

                <FormSelect
                  options={formikOptions}
                  label="Reset Quantity"
                  name="type"
                  data={[
                    {label: "On", value: "0"},
                    {label: "Off", value: "1"},
                  ]}
                />

                <FormSelect
                  tooltip="Which days will this reset happen"
                  options={formikOptions}
                  label="Reset Days"
                  hint="pick multiple"
                  name="days"
                  data={DAYS}
                  multi
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

InventoryRuleModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  addState: PropTypes.func.isRequired,
};

export default InventoryRuleModal;
