import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";

class AdminWrapper extends Component {
  componentDidMount() {
    const {header} = this.props;

    if (header === false) {
      this.props.updateLayoutHeader(false);
    }
  }

  componentWillUnmount() {
    const {header} = this.props;

    if (header === false) {
      this.props.updateLayoutHeader(true);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default setupReduxConnection(["layout"])(AdminWrapper);
