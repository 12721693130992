import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import LocationsModal from "../../../modals/shop/company-structure/locations-modal";

class CompaniesTable extends Component {
  buildCompaniesDict() {
    const {companies, locations} = this.props;
    const companiesCopy = JSON.parse(JSON.stringify(companies));

    const companyIndex = companiesCopy.reduce((dict, item, index) => {
      dict[item.COMPANY_ID] = index;
      return dict;
    }, {});

    locations.forEach((location) => {
      const index = companyIndex[location.COMPANY_ID];

      if (companiesCopy[index].locations) {
        companiesCopy[index].locations.push(location);
      } else {
        companiesCopy[index].locations = [location];
      }
    });

    return companiesCopy;
  }

  render() {
    const tableData = this.buildCompaniesDict();

    return (
      <>
        <LocationsModal ref={(e) => (this.locationsModal = e)} />
        <Table
          pagination
          className={"mt-4"}
          data={tableData}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.locationsModal.open(row);
              },
            },
          ]}
          columns={[
            {
              value: "COMPANY_NAME",
              label: "Company Name",
            },
            {
              value: "locations",
              label: "Locations",
              format: (e) => {
                return e?.length ?? 0;
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(CompaniesTable);
