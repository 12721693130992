import React, {Component} from "react";
import {Rnd} from "react-rnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {DOCUMENT_FIELD_DEFAULTS} from "../utils/constants";

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 33;

class DraggableBox extends Component {
  render() {
    const {field, deleteField, disabled, selected, onFieldClick} = this.props;

    let color = "bg-indigo-500 border-indigo-700";

    if (disabled && !selected) {
      color = "bg-gray-500 border-gray-700";
    } else if (field.ERROR) {
      color = "bg-red-500 border-red-700";
    }

    return (
      <Rnd
        default={{
          x: field?.X_COORDINATE ?? 0,
          y: field?.Y_COORDINATE ?? 0,
          width: field?.WIDTH ?? DOCUMENT_FIELD_DEFAULTS.WIDTH,
          height: field?.HEIGHT ?? DOCUMENT_FIELD_DEFAULTS.HEIGHT,
        }}
        bounds="parent"
        enableResizing={!disabled}
        disableDragging={disabled}
        key={field.UNIQUE_ID}
        ref={(e) => (this.draggable = e)}
        onDragStop={() => {
          field.X_COORDINATE = this.draggable.draggable.state.x;
          field.Y_COORDINATE = this.draggable.draggable.state.y;
        }}
        onResizeStop={(e, direction, ref, delta) => {
          field.WIDTH = (field.WIDTH ?? DEFAULT_WIDTH) + delta.width;
          field.HEIGHT = (field.HEIGHT ?? DEFAULT_HEIGHT) + delta.height;
          field.X_COORDINATE = this.draggable.draggable.state.x;
          field.Y_COORDINATE = this.draggable.draggable.state.y;
        }}
        onResizeStart={() => onFieldClick(field)}
        onDragStart={() => onFieldClick(field)}
      >
        <div
          className={classNames(
            "flex flex-row justify-between align-items-middle px-1  border-opacity-25 bg-opacity-25 w-full h-full border-2  text-black text-opacity-100",
            color
          )}
        >
          {field.ICON}

          {!disabled && (
            <button onClick={() => deleteField(field)}>
              <FontAwesomeIcon icon={"delete-left"} />
            </button>
          )}
        </div>
      </Rnd>
    );
  }
}

export default DraggableBox;
