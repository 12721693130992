import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import FormRow from "../../../../../components/form-row";

class W4Step3 extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.w4-employee.w4-step-3." + path;
  }

  render() {
    const {options} = this.props;

    return (
      <form>
        <div className={"my-3 block text-sm font-medium text-gray-700"}>
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
          <Trans i18nKey={this.getI18NextKey("condition.label")} />
        </div>

        <FormRow>
          <FormInput
            flex
            options={options}
            name={"numberQualifyingDependents"}
            label={
              <Trans i18nKey={this.getI18NextKey("qualifyingChildren.label")} />
            }
          />

          <FormInput
            flex
            options={options}
            name={"numberOtherDependents"}
            label={
              <Trans i18nKey={this.getI18NextKey("otherDependents.label")} />
            }
          />
        </FormRow>
      </form>
    );
  }
}

export default W4Step3;
