import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import EmployeeRoleModal from "../../../modals/team/employee/employee-role-modal";
import RolesTable from "../../../tables/team/roles-table";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Banner from "../../../components/banner";
import TerminateEmployeeModal from "../../../modals/team/employee/terminate-employee-modal";
import ReinstateEmployeeModal from "../../../modals/team/employee/reinstate-employee-modal";
import PayRateModal from "../../../modals/team/pay-rate-modal";
import ShiftModal from "../../../modals/scheduling/shift-modal";
import TimeCardModal from "../../../modals/team/time-card-modal";
import {setupReduxConnection} from "../../../redux";
import EmployeeDetailsModal from "../../../modals/team/employee/employee-details-modal";
import {COMPENSATION_TYPES} from "../../../utils/team-constants";
import EmployeeTable from "../../../tables/team/employee-table";
import EmployeePolicyModal from "../../../modals/team/policies/employee-policy-modal";
import {updateStateDelegator} from "../../../utils/util";
import TimeOffModal from "../../../modals/team/employee/time-off-modal";
import {
  fetchRecurrenceString,
  timeOffStatusToBadge,
} from "../../../utils/team-helper";
import ShiftPreferencesModal from "../../../modals/team/employee/shift-preferences-modal";
import {PERMISSIONS} from "../../../utils/constants";
import EmployeeFileModal from "../../../modals/team/employee/employee-file-modal";
import {saveAs} from "file-saver";
import EmployeeNotesModal from "../../../modals/team/employee/employee-notes-modal";
import ShowTextModal from "../../../modals/general/show-text-modal";
import {fetchEmployeeHomeLocationsString} from "../../../utils/employee-helper";

class EmployeePage extends Component {
  state = {
    employee: null,
    rates: null,
    roles: null,
    shifts: null,
    cards: null,
    unavailability: null,
    prefs: null,
    timeOff: null,
    terminations: [],
    filter: null,
    reports: null,
    policies: null,
    files: null,
    blackoutDates: [],
  };

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({employee: null}, () => {
        this.syncState();
      });
    }
  }

  syncState() {
    const {ID: id} = this.props.router.params;

    request("employees/" + id, "GET", null).then((employee) => {
      this.setState({employee});

      request("pay/rates/employee/" + employee.ID, "GET").then((rates) => {
        this.setState({rates});
      });

      request("timeoff/policies/employee/" + employee.ID, "GET").then(
        (policies) => {
          this.setState({policies});
        }
      );

      request("timeoff/blackout/", "GET").then((serverDates) => {
        this.setState({blackoutDates: serverDates});
      });

      request("employeeroles/employee/" + employee.ID, "GET").then((roles) => {
        this.setState({roles});
      });

      request("employees/file/" + employee.ID, "GET").then((files) => {
        this.setState({files});
      });

      request("employees/" + employee.ID + "/reports", "GET").then(
        (reports) => {
          this.setState({reports});
        }
      );

      request("scheduling/schedule/employee/" + employee.ID, "GET").then(
        (shifts) => {
          shifts.sort((a, b) => b.DATE_START - a.DATE_START);
          this.setState({shifts});
        }
      );

      request("team/timesheet/employee/" + employee.ID, "GET").then((cards) => {
        cards.sort((a, b) => b.DATE_START - a.DATE_START);
        this.setState({cards});
      });

      request("termination/employee/" + employee.ID, "GET").then(
        (terminations) => {
          this.setState({terminations});
        }
      );

      request("scheduling/unavailability/employee/" + employee.ID, "GET").then(
        (unavailabilityAndPrefs) => {
          const unavailability = unavailabilityAndPrefs.filter(
            (item) => item.TYPE === 1
          );
          const prefs = unavailabilityAndPrefs.filter(
            (item) => item.TYPE === 0
          );

          unavailability.sort((a, b) => a.ISO_DAY - b.ISO_DAY);
          prefs.sort((a, b) => a.ISO_DAY - b.ISO_DAY);

          this.setState({unavailability, prefs});
        }
      );

      request("timeoff/requests/employee/" + employee.ID, "GET").then(
        (timeOff) => {
          timeOff.sort((a, b) => b.DATE_START - a.DATE_START);

          this.setState({timeOff});
        }
      );

      request(`employees/notes/${employee.ID}`, "GET").then((notes) => {
        this.setState({notes});
      });
    });
  }

  async downloadFile(signedUrl, name) {
    saveAs(signedUrl, name);
  }

  blessRequest = async (requestId, isAccepted, reviewerNotes) => {
    let {timeOff} = this.state;

    const blessPayload = {
      NOTES: reviewerNotes,
    };

    let serverRequest = null;

    if (isAccepted) {
      serverRequest = await request(
        "timeoff/requests/" + requestId + "/approve",
        "POST",
        blessPayload
      );
    } else {
      serverRequest = await request(
        "timeoff/requests/" + requestId + "/deny",
        "POST",
        blessPayload
      );
    }

    const requestIndex = timeOff.findIndex((item) => {
      return item.ID === serverRequest.ID;
    });

    if (requestIndex !== -1) {
      timeOff.splice(requestIndex, 1, serverRequest);

      this.setState({timeOff});
    }
  };

  render() {
    const {
      employee,
      rates,
      roles,
      shifts,
      cards,
      prefs,
      timeOff,
      unavailability,
      reports,
      filter,
      policies,
      terminations,
      files,
      notes,
      blackoutDates,
    } = this.state;
    const {permissions: userPermissions} = this.props.user;

    const canViewRates =
      userPermissions?.includes("ADMIN_FULL") ||
      userPermissions?.includes("PAY_RATES_FULL");

    const canViewTimeCards =
      userPermissions?.includes(PERMISSIONS.ADMIN.FULL.id) ||
      userPermissions?.includes(PERMISSIONS.TEAM.FULL.id) ||
      userPermissions?.includes(PERMISSIONS.TEAM.TIME_FULL.id);

    const canViewFiles =
      userPermissions?.includes("ADMIN_FULL") ||
      userPermissions?.includes("EMPLOYEE_FILES_FULL");

    let hasPayRatesEnabled =
      employee?.TYPE === COMPENSATION_TYPES.HOUR.VALUE && !employee?.RATE;

    let tabs = [
      {label: "Roles", id: "roles"},
      {label: "Policies", id: "policies"},
      {
        label: "Shifts",
        id: "shifts",
      },
      {label: "Shift Preferences", id: "prefs"},
      {label: "Time Off", id: "time"},
      {
        label: "Direct Reports",
        id: "reports",
      },
    ];

    if (canViewTimeCards) {
      tabs.splice(3, 0, {label: "Time Cards", id: "cards"});
    }

    if (canViewRates && hasPayRatesEnabled) {
      tabs.splice(1, 0, {label: "Pay Rates", id: "rates"});
    }

    if (canViewFiles) {
      tabs.splice(tabs.length, 0, {label: "Files", id: "files"});
      tabs.splice(tabs.length, 0, {label: "Notes", id: "notes"});
    }

    if (employee === null) {
      return <Loading />;
    }

    const activeTerminations = terminations.filter((item) => {
      return item.DATE_REINSTATED === null;
    });

    let informationArr = [
      {
        label: "Name",
        value: employee.FULL_NAME,
      },
      {
        label: "Home Location",
        value: employee.LOCATION_NAME,
      },
      {
        label: "Department",
        link:
          employee.DEPARTMENT_ID &&
          "/department/" + employee.DEPARTMENT_UNIQUE_ID,
        value: employee.DEPARTMENT_NAME ?? "None",
      },
      {
        label: "Manager",
        link:
          employee.MANAGER_EMPLOYEE_ID &&
          "/employee/" + employee.MANAGER_EMPLOYEE_UNIQUE_ID,
        value: employee.MANAGER_EMPLOYEE_NAME ?? "None",
      },
      {
        label: "Email",
        value: employee.EMAIL,
      },
      {
        label: "Phone",
        value: getFormattedPhone(employee.PHONE),
      },
      {
        label: "Start Date",
        value: employee.DATE_STARTED ?? "Not Added",
        type: employee.DATE_STARTED ? "date" : "",
      },
      {
        label: "Pin",
        value: employee.PIN,
      },
      {
        label: "Additional Home Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip:
          "Gives employee permissions access to the following locations, in addition to their home location.",
      },
      {
        label: "Scheduling Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.SCHEDULE_LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip: "Allows employee to be scheduled on the following locations.",
      },
      {
        label: "Notification Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.NOTIFICATION_LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip:
          "Sends employee notifications from the following locations (trade requests and late clock in reminders).",
      },
      {
        label: "Compensation Type",
        value:
          COMPENSATION_TYPES[employee.TYPE]?.LABEL ??
          COMPENSATION_TYPES["HOUR"].LABEL,
      },
    ];

    if (employee.TYPE === COMPENSATION_TYPES.SAL.VALUE && canViewRates) {
      informationArr.push({
        label: "Salary",
        value: `${toDollars(employee.RATE, true)}`,
      });
    }

    if (employee.TYPE === COMPENSATION_TYPES.HOUR.VALUE && canViewRates) {
      informationArr.push({
        label: "Hourly Pay Type",
        value: `${
          employee.RATE ? "Employee Specific Rate" : "Role Based Rate"
        }`,
      });

      if (employee.RATE) {
        informationArr.push({
          label: "Default Rate",
          value: toDollars(employee.RATE, true),
        });
      }
    }

    return (
      <div>
        <EmployeeNotesModal
          ref={(e) => (this.notesModal = e)}
          employee={employee}
          syncState={() => this.syncState()}
        />

        <ShowTextModal ref={(e) => (this.showTextModal = e)} />

        <EmployeeFileModal
          ref={(e) => {
            this.fileModal = e;
          }}
          employee={employee}
          syncState={() => {
            this.syncState();
          }}
        />
        <EmployeeDetailsModal
          updateState={(employee) => this.setState({employee})}
          ref={(e) => (this.detailModal = e)}
        />

        <EmployeePolicyModal
          employee={employee}
          policies={policies ?? []}
          ref={(e) => (this.policyModal = e)}
          addState={(policy) =>
            this.setState({policies: [...policies, policy]})
          }
          updateState={(id, policy) => {
            this.setState({
              policies: updateStateDelegator(policies, id, policy),
            });
          }}
        />

        {/*<ContactSupportModal ref={(e) => (this.detailModal = e)} />*/}

        <TerminateEmployeeModal
          updateState={() => this.props.router.navigate("/team")}
          ref={(e) => (this.terminateModal = e)}
        />

        <ReinstateEmployeeModal
          updateState={() => this.props.router.navigate("/team")}
          ref={(e) => (this.reinstateModal = e)}
        />

        <EmployeeRoleModal
          roles={roles}
          employee={employee}
          ref={(e) => (this.roleModal = e)}
          addState={(role) => this.setState({roles: [...roles, role]})}
          removeState={(role) => {
            const roleIndex = roles.findIndex(
              ({Roles}) => Roles.ID === role.ID
            );

            if (roleIndex !== -1) {
              roles.splice(roleIndex, 1);
            }

            this.setState({roles});
          }}
        />

        <TimeOffModal
          ref={(e) => (this.timeOffModal = e)}
          policies={policies}
          blackoutDates={blackoutDates}
          updateTimeOff={(req) => {
            let {timeOff} = this.state;

            const idx = timeOff.findIndex((_timeOff) => _timeOff.ID === req.ID);

            if (idx === -1) {
              timeOff = [req, ...timeOff];
            } else {
              timeOff.splice(idx, 1, req);
            }

            this.setState({timeOff});
          }}
          deleteTimeOff={(id) => {
            const idx = timeOff.findIndex((_timeOff) => _timeOff.ID === id);

            if (idx !== -1) {
              timeOff.splice(idx, 1);
            }

            this.setState({timeOff});
          }}
        />

        <ShiftModal
          ref={(e) => (this.shiftModal = e)}
          startDay={this.props.shop.settings.SCHEDULER_FIRST_DAY}
        />

        <TimeCardModal
          ref={(e) => (this.timeCardModal = e)}
          onChange={() => {
            this.syncState();
          }}
          employee={this.state.employee}
        />

        <PayRateModal
          ref={(e) => (this.payRateModal = e)}
          employee={employee}
          addState={() => {
            this.syncState();
          }}
          updateState={() => {
            this.syncState();
          }}
        />

        <ShiftPreferencesModal
          ref={(e) => (this.shiftPreferencesModal = e)}
          onChange={() => this.syncState()}
        />

        <PageHeadings
          logo={getObjectImage(
            employee,
            "LOGO",
            "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
          )}
          label={employee.FULL_NAME}
          description={employee.ROLES.map((item) => item.ROLE_NAME).join(", ")}
          breadcrumbs={[
            {label: "Team", url: "/"},
            {label: "My Team", url: "/team"},
            {
              label: "Employees Page",
              url: "/employees",
            },
          ]}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label:
                        employee.ACTIVE === 1
                          ? "Terminate Employee"
                          : "Reinstate Employee",
                      onClick:
                        employee.ACTIVE === 1
                          ? () => this.terminateModal.open(employee)
                          : () => this.reinstateModal.open(employee),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        {employee.ACTIVE === 0 && (
          <Banner
            type="error"
            className="mt-4"
            label="Employee is inactive"
            detailsLabel="Learn more"
            description={
              employee.FULL_NAME +
              " is inactive or terminated. Click to the right to learn more."
            }
          />
        )}

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.detailModal.open(employee),
            },
          ]}
          label="Employee Information"
          description="Information about this employee"
          data={informationArr}
        />

        <Tab className="mt-2" data={tabs}>
          {(id) => {
            if (id === "rates" && !canViewRates) {
              id = "roles";
            }

            if (id === "reports") {
              return (
                <Card
                  label="Direct Reports"
                  description={
                    "The employees that are reporting to " + employee.FULL_NAME
                  }
                >
                  <EmployeeTable data={reports} pagination striped />
                </Card>
              );
            }

            if (id === "roles") {
              return (
                <Card
                  label="Roles"
                  button={{
                    label: "Add Role",
                    onClick: () => this.roleModal.open(),
                  }}
                >
                  <RolesTable
                    pagination
                    roles={
                      roles
                        ? roles.map((item) => ({
                            EMPLOYEE_ROLE_ID: item.Employee_Roles.ID,
                            ...item.Roles,
                          }))
                        : null
                    }
                    onClick={(row) => this.roleModal.open(row)}
                  />
                </Card>
              );
            }

            if (id === "policies") {
              return (
                <Card
                  label="Policies"
                  button={{
                    label: "Add Policy",
                    onClick: () => this.policyModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={policies}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.policyModal.open(row);
                        },
                      },
                    ]}
                    columns={[
                      {
                        value: "POLICY_NAME",
                        label: "Name",
                      },
                      {
                        value: "BALANCE",
                        label: "Amount",
                        format: (balance, policy) => {
                          return policy.UNLIMITED ? "Unlimited" : balance;
                        },
                      },
                      {
                        value: "TOTAL",
                        label: "Total",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "rates" && canViewRates) {
              return (
                <Card
                  label="Pay Rates"
                  buttons={[
                    {
                      label: "Add Rate",
                      onClick: () => {
                        this.payRateModal.open(null, employee);
                      },
                    },
                    {
                      theme: "gray",
                      selectable: true,
                      type: "list",
                      icon: "eye",
                      defaultValue: "active",
                      onChange: ({id: filter}) => this.setState({filter}),
                      items: [
                        {
                          id: "all",
                          label: "View all rates",
                          description:
                            "View both active and archived pay rates.",
                        },
                        {
                          id: "active",
                          label: "Viewing active rates",
                          description: "View only active pay rates.",
                        },
                      ],
                    },
                  ]}
                >
                  <Table
                    pagination
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (value) => {
                          return (
                            <div className="text-sm font-medium text-gray-900">
                              {value}
                            </div>
                          );
                        },
                      },
                      {
                        value: "EMPLOYEE_ROLE_NAME",
                        label: "Attached Role",
                        format: (value) => {
                          return (
                            <div className="text-sm font-medium text-gray-500">
                              {value}
                            </div>
                          );
                        },
                      },
                      {
                        value: "ROLE_NAME",
                        label: "Pay Origin",
                        format: (value) => {
                          return (
                            <div className="text-sm font-medium text-gray-500">
                              {value ? value : "Custom"}
                            </div>
                          );
                        },
                      },
                      {
                        value: "TYPE",
                        label: "Pay Type",
                        format: (_, {TYPE, CONTENT}) => {
                          if (TYPE === "TIME_RANGE") {
                            const timeSplit = CONTENT.split("|");

                            const startTime = moment()
                              .startOf("day")
                              .add(timeSplit[0], "minutes");
                            const endTime = moment()
                              .startOf("day")
                              .add(timeSplit[1], "minutes");

                            return (
                              "Hourly " +
                              startTime.format("hh:mma") +
                              " - " +
                              endTime.format("hh:mma")
                            );
                          }

                          if (TYPE === "SALARY") {
                            return "Salary";
                          }

                          return "Hourly";
                        },
                      },
                      {
                        width: 1,
                        value: "AMOUNT",
                        label: "Amount",
                        format: (value) => {
                          return "$" + toDollars(value);
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_ARCHIVED",
                        label: "Status",
                        format: (value, row) => {
                          return row.DATE_ARCHIVED ? "Archived" : "Active";
                        },
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.payRateModal.open(row);
                        },
                      },
                    ]}
                    data={
                      filter === "active"
                        ? rates?.filter((item) => !item.DATE_ARCHIVED)
                        : rates
                    }
                  />
                </Card>
              );
            }

            if (id === "shifts") {
              return (
                <Card
                  label="Shifts"
                  buttons={[
                    {
                      label: "Add Shift",
                      onClick: () => {
                        const start = moment()
                          .startOf("day")
                          .add(12, "h")
                          .valueOf();
                        const end = moment()
                          .startOf("day")
                          .add(13, "h")
                          .valueOf();

                        this.shiftModal.openForCreation(
                          start,
                          end,
                          employee.ID,
                          null
                        );
                      },
                    },
                  ]}
                >
                  <Table
                    pagination
                    columns={[
                      {
                        value: "DATE_START",
                        label: "Date",
                        format: (_, row) => {
                          return (
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {row.LOCATION_NAME}
                              </div>

                              <div className="text-sm text-gray-500">
                                {row.ROLE_NAME}
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date",
                        format: (start) => {
                          return moment(start).format("ddd, MMM Do YYYY");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date Start",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_END",
                        label: "Date End",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_ARCHIVED",
                        label: "Shift Length",
                        format: (_, row) => {
                          return moment(row.DATE_END)
                            .diff(row.DATE_START, "hours", true)
                            .toFixed(2);
                        },
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.shiftModal.openForEdit(row);
                        },
                      },
                    ]}
                    data={shifts}
                  />
                </Card>
              );
            }

            if (id === "cards") {
              return (
                <Card
                  label="Time Cards"
                  button={{
                    label: "Add Card",
                    onClick: () => this.timeCardModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={cards}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.timeCardModal.open(row);
                        },
                      },
                    ]}
                    columns={[
                      {
                        value: "DATE_START",
                        label: "Date",
                        format: (_, row) => {
                          return (
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {row.LOCATION_NAME}
                              </div>

                              <div className="text-sm text-gray-500">
                                {row.ROLE_NAME}
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date",
                        format: (start) => {
                          return moment(start).format("ddd, MMM Do YYYY");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date Start",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_END",
                        label: "Date End",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_ARCHIVED",
                        label: "Shift Length",
                        format: (_, row) => {
                          return moment(row.DATE_END)
                            .diff(row.DATE_START, "hours", true)
                            .toFixed(2);
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "prefs") {
              return (
                <>
                  <Card
                    label="Unavailability"
                    button={{
                      label: "Add Unavailability",
                      onClick: () =>
                        this.shiftPreferencesModal.open(1, employee.ID),
                    }}
                  >
                    <Table
                      actionButtons={[
                        {
                          label: "Edit",
                          onClick: (row) =>
                            this.shiftPreferencesModal.open(
                              row.TYPE,
                              null,
                              row
                            ),
                        },
                      ]}
                      pagination
                      columns={[
                        {
                          value: "ISO_DAY",
                          label: "Day",
                          format: (iso_day) => {
                            return moment()
                              .isoWeekday(iso_day + 1)
                              .format("dddd");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_START",
                          label: "Start",
                          format: (dateStart) => {
                            return dateStart === 0
                              ? "-"
                              : moment(dateStart).format("MMMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "START",
                          label: "From",
                          format: (start, row) => {
                            if (row.ALL_DAY) {
                              return "All Day";
                            }

                            return moment(start).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "END",
                          label: "To",
                          format: (end, row) => {
                            if (row.ALL_DAY) {
                              return "";
                            }

                            return moment(end).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "RECURRENCE_TYPE",
                          label: "Repeats",
                          format: (type, row) => {
                            return fetchRecurrenceString(
                              type,
                              row.RECURRENCE_INTERVAL
                            );
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_EXPIRES",
                          label: "Until",
                          format: (dateExpires, row) => {
                            if (!dateExpires) {
                              return "-";
                            }

                            return moment(dateExpires).format("MMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_UPDATED",
                          label: "Last Updated",
                          format: (date, row) => {
                            return moment(date).format("MMM Do YYYY");
                          },
                        },
                      ]}
                      data={unavailability}
                    />
                  </Card>

                  <Card
                    label="Preferences"
                    button={{
                      label: "Add Preference",
                      onClick: () =>
                        this.shiftPreferencesModal.open(0, employee.ID),
                    }}
                  >
                    <Table
                      actionButtons={[
                        {
                          label: "Edit",
                          onClick: (row) =>
                            this.shiftPreferencesModal.open(
                              row.TYPE,
                              null,
                              row
                            ),
                        },
                      ]}
                      pagination
                      columns={[
                        {
                          value: "ISO_DAY",
                          label: "Day",
                          format: (iso_day) => {
                            return moment()
                              .isoWeekday(iso_day + 1)
                              .format("dddd");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_START",
                          label: "Start",
                          format: (dateStart) => {
                            return dateStart === 0
                              ? "-"
                              : moment(dateStart).format("MMMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "START",
                          label: "From",
                          format: (start, row) => {
                            if (row.ALL_DAY) {
                              return "All Day";
                            }

                            return moment(start).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "END",
                          label: "To",
                          format: (end, row) => {
                            if (row.ALL_DAY) {
                              return "";
                            }

                            return moment(end).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "RECURRENCE_TYPE",
                          label: "Repeats",
                          format: (type, row) => {
                            return fetchRecurrenceString(
                              type,
                              row.RECURRENCE_INTERVAL
                            );
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_EXPIRES",
                          label: "Until",
                          format: (dateExpires, row) => {
                            if (!dateExpires) {
                              return "-";
                            }

                            return moment(dateExpires).format("MMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_UPDATED",
                          label: "Last Updated",
                          format: (date, row) => {
                            return moment(date).format("MMM Do YYYY");
                          },
                        },
                      ]}
                      data={prefs}
                    />
                  </Card>
                </>
              );
            }

            if (id === "time") {
              return (
                <Card
                  label="Time Off"
                  button={{
                    label: "Add Time Off",
                    onClick: () => this.timeOffModal.open(employee.ID),
                  }}
                >
                  <Table
                    pagination
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) =>
                          this.timeOffModal.open(employee.ID, row),
                      },
                    ]}
                    columns={[
                      {
                        value: "DATE_START",
                        label: "From",
                        format: (start, timeOffObj) => {
                          if (timeOffObj.PARTIAL_DAYS === 1) {
                            return moment(start).format("MM/DD/YYYY hh:mma");
                          }
                          return moment(start).format("MM/DD/YYYY");
                        },
                      },
                      {
                        value: "DATE_END",
                        label: "To",
                        format: (end, timeOffObj) => {
                          if (timeOffObj.PARTIAL_DAYS === 1) {
                            return moment(end).format("MM/DD/YYYY hh:mma");
                          }
                          return moment(end).format("MM/DD/YYYY");
                        },
                      },
                      {
                        value: "POLICY_NAME",
                        label: "Policy",
                      },
                      {
                        value: "AMOUNT",
                        label: "Hours Requested",
                      },
                      {
                        value: "STATUS",
                        label: "Status",
                        format: (value) => {
                          return timeOffStatusToBadge(value);
                        },
                      },
                      {
                        value: "REVIEWER_NAME",
                        label: "Reviewer",
                        format: (value) => {
                          return value ?? "Auto Reviewed";
                        },
                      },
                      {
                        value: "NOTES",
                        label: "Notes",
                        format: (val) => {
                          return val?.length > 0 ? val : "None";
                        },
                      },
                    ]}
                    data={timeOff}
                  />
                </Card>
              );
            }

            if (id === "files") {
              return (
                <Card
                  label="Files"
                  buttons={[
                    {
                      label: "Upload New",
                      onClick: () => this.fileModal.open(employee),
                    },
                  ]}
                >
                  <Table
                    pagination
                    actionButtons={[
                      {
                        label: "Download",
                        onClick: async (row) =>
                          await this.downloadFile(
                            row.SIGNED_URL,
                            row.FILE_NAME
                          ),
                      },
                      {
                        label: "Edit",
                        onClick: (row) => this.fileModal.open(employee, row),
                      },
                    ]}
                    columns={[
                      {
                        value: "FILE_NAME",
                        label: "File Name",
                      },
                      {
                        value: "DATE_UPLOADED",
                        label: "Date Uploaded",
                        format: (end) =>
                          moment(parseInt(end)).format("MMM DD, h:mm A"),
                      },
                    ]}
                    data={files}
                  />
                </Card>
              );
            }

            if (id === "notes") {
              return (
                <Card
                  label="Notes"
                  buttons={[
                    {
                      label: "Create Note",
                      onClick: () => this.notesModal.open(employee),
                    },
                  ]}
                >
                  <Table
                    pagination
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) => this.showTextModal.open(row),
                      },
                      {
                        label: "Edit",
                        onClick: (row) => this.notesModal.open(employee, row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NOTE_NAME",
                        label: "Note Name",
                      },
                      {
                        value: "DATE_UPLOADED",
                        label: "Date Uploaded",
                        format: (end) =>
                          moment(parseInt(end)).format("MMM DD, h:mm A"),
                      },
                      {
                        value: "NOTE",
                        label: "Note",
                        format: (value) =>
                          value?.length > 50
                            ? `${value.substring(0, 50)}...`
                            : value,
                      },
                    ]}
                    data={notes}
                  />
                </Card>
              );
            }

            return <div>Hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(EmployeePage));
