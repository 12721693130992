import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import * as Yup from "yup";
import {showSuccessAlert} from "../../utils/alert-helper";
import PropTypes from "prop-types";

class BillingModal extends Component {
  open() {
    this.modal.open();
  }

  updateExternalAccount = async ({cvc, expiry, number}) => {
    const expirySplit = expiry.split("/");

    await request("partner/billing/card", "POST", {
      YEAR: expirySplit[1],
      MONTH: expirySplit[0],
      CVC: cvc,
      NUMBER: number,
    });

    this.props.refresh && this.props.refresh();
    this.modal.close();

    showSuccessAlert(
      "Billing Information Saved",
      "Your billing information has been saved!"
    );
  };

  render() {
    return (
      <Modal
        label="Billing Payment Information"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          initialValues={{
            type: "1",
            cvc: "",
            expiry: "",
            number: "",
          }}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.updateExternalAccount}
          validationSchema={Yup.object().shape({
            cvc: Yup.string().required("Card CVC is required"),
            expiry: Yup.string().required("Card expiration is required"),
            number: Yup.string()
              .required("Card number is required")
              .test(
                "test-name",
                "Please enter a valid card number",
                (rawValue) => {
                  let value = (rawValue || "").replaceAll(" ", "");

                  return value.length === (value.startsWith("37") ? 15 : 16);
                }
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-row justify-between">
                  <FormInput
                    className="flex-1 mr-2"
                    label="Card Number"
                    name="number"
                    options={formikOptions}
                  />
                </div>

                <div className="flex flex-row justify-between">
                  <FormInput
                    className="w-24"
                    label="CVC"
                    name="cvc"
                    options={formikOptions}
                  />

                  <FormInput
                    label="Expiration"
                    name={"expiry"}
                    placeholder="MM/YY"
                    options={formikOptions}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BillingModal.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default BillingModal;
