import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import {updateStateDelegator} from "../../../utils/util";
import StockUnitModal from "../../../modals/operations/ingredients/stock-unit-modal";

class StockingsPage extends Component {
  state = {
    isLoading: true,
    ingredients: [],
    units: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let units = await request("ingredients/stock", "GET", {});

    this.setState({isLoading: false, units});
  }

  render() {
    const {isLoading, units} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Stocking Units"
          description="Large unit amounts constructed from ingredient units"
          button={{
            label: "Create Stocking Unit",
            onClick: () => this.unitModal.open(),
          }}
        />

        <StockUnitModal
          ref={(e) => (this.unitModal = e)}
          addState={(unit) => this.setState({units: [unit, ...units]})}
          updateState={(unit) => {
            this.setState({
              units: updateStateDelegator(units, unit.ID, unit),
            });
          }}
        />

        <Table
          pagination
          className="mt-4"
          data={units}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.unitModal.open(row),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "STOCK",
              label: "Quantity",
              format: (_, row) =>
                row.STOCK + " (" + row.INGREDIENT_UNIT_NAME + ")",
            },
            {
              width: 1,
              label: "Created",
              value: "DATE_CREATED",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              width: 1,
              label: "Updated",
              value: "DATE_UPDATED",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(StockingsPage));
