import React, {Component} from "react";

import {
  Modal,
  FormInput,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";

class EmployeeNotesModal extends Component {
  state = {noteRow: null, employee: null};

  open(employee, noteRow = null) {
    this.setState({employee, noteRow}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  createNote = async (values) => {
    const {note, noteName} = values;
    const {employee} = this.state;
    const {syncState} = this.props;

    request(`employees/note/${employee.ID}`, "POST", {
      NOTE: note,
      NOTE_NAME: noteName,
    })
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateNote = async (values) => {
    const {note, noteName} = values;
    const {noteRow} = this.state;
    const {syncState} = this.props;

    request(`employees/note/${noteRow.ID}`, "PATCH", {
      NOTE: note,
      NOTE_NAME: noteName,
    })
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteNote = async () => {
    const {noteRow} = this.state;
    const {syncState} = this.props;

    request(`employees/note/${noteRow.ID}`, "DELETE")
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {noteRow} = this.state;

    return (
      <Modal
        tooltip={{
          data: noteRow
            ? "Update note for this employee"
            : "Add a new note for this employee",
        }}
        buttonLabel={noteRow ? "Update" : "Save"}
        label={noteRow ? "Update Note" : "Add Note"}
        ref={(e) => (this.modal = e)}
        deleteOnClick={() => this.deleteNote(noteRow)}
        formikOnClick={() => this.formikRef}
        deleteLabel={noteRow ? "Delete Note" : null}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={noteRow ? this.updateNote : this.createNote}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            note: noteRow?.NOTE,
            noteName: noteRow?.NOTE_NAME,
          }}
          validationSchema={Yup.object().shape({
            note: Yup.string().nullable().required(),
            noteName: Yup.string().nullable().required(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  label={"Note Name"}
                  name={"noteName"}
                />
                <FormTextArea
                  options={formikOptions}
                  label="Notes"
                  name="note"
                />{" "}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmployeeNotesModal;
