import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
  FormToggle,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import * as Yup from "yup";

class DiscountDetailsModal extends Component {
  state = {discount: null, product: 0};

  open(discount = null, product = null) {
    this.setState({discount, product}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async addDiscount({name, type, content, logo, auto, autoQuantity}) {
    const {product} = this.state;

    let serverPaymentType = {
      NAME: name,
      CONTENT: type === 0 ? decimalToDollars(content) : content,
      PRODUCT: product,
      TYPE: parseInt(type),
      LOGO: logo?.trim()?.length > 0 ? logo : "appicon.png",
      AUTO: parseInt(auto),
      AUTO_CONTENT: autoQuantity,
      AUTO_TYPE: 0,
    };

    const serverDiscount = await request(
      "partner/discount",
      "POST",
      serverPaymentType
    );

    this.props.addState(serverDiscount);
    this.modal.close();
  }

  async deleteDiscount() {
    const {discount} = this.state;

    await request("partner/discount/" + discount.ID, "DELETE", null);

    this.setState({discount: null});

    this.props.onDelete && this.props.onDelete();

    this.modal && this.modal.close();
  }

  async saveDiscount({name, type, content, logo, auto, autoQuantity}) {
    const {discount} = this.state;

    let payload = {
      NAME: name,
      TYPE: parseInt(type),
      CONTENT: parseInt(type) === 0 ? decimalToDollars(content) : content,
      LOGO: logo?.trim().length > 0 ? logo : "appicon.png",
      AUTO: parseInt(auto),
      AUTO_CONTENT: autoQuantity,
      AUTO_TYPE: 0,
    };

    const serverDiscount =  await request("partner/discount/" + discount.ID, "PUT", payload);

    this.props.updateState({discount: {...serverDiscount}});
    this.modal.close();
  }

  render() {
    const {discount, product} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label={discount ? "Edit Discount" : "Create Discount"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={discount ? "Delete" : null}
        deleteOnClick={() => {
          this.deleteDiscount();
        }}
      >
        <Formik
          onSubmit={
            discount
              ? this.saveDiscount.bind(this)
              : this.addDiscount.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            name: Yup.string().nullable().required("Name is required."),
            type: Yup.number().nullable().required("Type is required"),
            content: Yup.number()
              .typeError(
                "Discount Amount must be a number. Please remove any $ or %."
              )
              .required("Discount Amount is required."),
            logo: Yup.string(),
          })}
          enableReinitialize
          initialValues={{
            name: discount?.NAME,
            type: discount?.TYPE,
            content:
              parseInt(discount?.TYPE) === 0
                ? toDollars(discount?.CONTENT)
                : discount?.CONTENT,
            logo: discount?.LOGO,
            auto: discount?.AUTO_RULE ? "1" : "0",
            autoQuantity: discount?.AUTO_RULE?.CONTENT,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Name"
                  name="name"
                  options={formikOptions}
                  placeholder={"Employee Discount"}
                />

                <FormSelect
                  name="type"
                  label="Discount Type"
                  options={formikOptions}
                  data={[
                    {label: "Flat", value: 0},
                    {label: "Percentage", value: 1},
                  ]}
                />

                <FormInput
                  label="Discount Amount"
                  name="content"
                  placeholder={values?.type ? "75" : "5.00"}
                  options={formikOptions}
                />

                <ImageFormDropZone
                  options={formikOptions}
                  label="Discount Logo"
                  name="logo"
                />

                <div className={"mt-3"} />

                {/*TODO For now we will only do this on product discounts, but this functionality should be available for all discounts*/}
                {/*{!!product && (*/}
                {/*  <FormToggle*/}
                {/*    label="Automatically Apply"*/}
                {/*    name="auto"*/}
                {/*    tooltip={{*/}
                {/*      label: "Automatic Discounts",*/}
                {/*      // data: "If automatic discounts are turned on, this discount will be automatically applied if a set of criteria is met.",*/}
                {/*      data: "If automatic discounts are turned on, this discount will be automatically applied if a certain quantity of specified items are ordered.",*/}
                {/*    }}*/}
                {/*    options={formikOptions}*/}
                {/*    flex*/}
                {/*    ignoreShadow*/}
                {/*  />*/}
                {/*)}*/}

                {/*{values?.auto === "1" && (*/}
                {/*  <>*/}
                {/*    <FormInput*/}
                {/*      label="Quantity"*/}
                {/*      name="autoQuantity"*/}
                {/*      tooltip={{*/}
                {/*        label: "Quantity",*/}
                {/*        data: "The minimum quantity of the selected items required for this discount to be applied. If there are more than the inputted quantity then the discount will be applied to any additional products as well.",*/}
                {/*      }}*/}
                {/*      options={formikOptions}*/}
                {/*    />*/}

                {/*    /!*TODO support other types*!/*/}

                {/*    /!*Apply to will eventually mean which of the quantities to apply to, i.e. the exact quantity,*/}
                {/*    the minimum quantity or BOGO. For now, we will stick with Minimum quantity, meaning once the minimum*/}
                {/*    quantity is met then we apply the discount to all qualifying products.*/}
                {/*    *!/*/}
                {/*    <FormInput*/}
                {/*      label="Apply To"*/}
                {/*      name="autoApplyTo"*/}
                {/*      tooltip={{data: "Your mom"}}*/}
                {/*      options={formikOptions}*/}
                {/*    />*/}
                {/*  </>*/}
                {/*)}*/}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

DiscountDetailsModal.propTypes = {
  updateState: PropTypes.func,
};

export default DiscountDetailsModal;
