import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MessagesPage from "../pages/team/messaging/messages-page";
import {getNumberUnread} from "../utils/messaging/message-helper";
import {setupReduxConnection} from "../redux";
import Draggable from "react-draggable";

class ChatBubble extends Component {
  state = {numberUnread: 0, x: null, y: null};

  openMessages() {
    this.messageModal.open();
  }

  componentDidMount() {
    this.recalculateNumPending();
  }

  recalculateNumPending() {
    const numberUnread = getNumberUnread();

    this.setState({numberUnread});
  }

  handleStart(e) {
    this.setState({x: e.screenX, y: e.screenY});
  }

  handleStop(e) {
    const {x, y} = this.state;
    const {screenX, screenY} = e;

    if (x === screenX && y === screenY) {
      this.openMessages();
    }
  }

  render() {
    const {numberUnread} = this.state;

    return (
      <div
        style={{height: "100vh", width: "100vw"}}
        className={"absolute left-0 right-0"}
      >
        <MessagesPage
          recalculatePending={() => this.recalculateNumPending()}
          ref={(e) => (this.messageModal = e)}
        />

        <Draggable
          bounds={"parent"}
          onStart={(e) => this.handleStart(e)}
          onStop={(e) => this.handleStop(e)}
        >
          <div className="bottom-10 left-10 bg-gray-200 p-3 rounded-full hover:bg-gray-300 hover:cursor-pointer fixed">
            <FontAwesomeIcon
              icon={"comment"}
              className={"text-slate-600 h-7 w-7"}
            />

            {numberUnread > 0 && (
              <div className="absolute flex top-0 right-0 bg-red-600 rounded-full h-5 w-5 items-center justify-center">
                <div className="flex text-white text-xs font-semibold">
                  {numberUnread}
                </div>
              </div>
            )}
          </div>
        </Draggable>
      </div>
    );
  }
}

export default setupReduxConnection(["chat"])(ChatBubble);
