import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import ModifierModal from "../../../modals/sales/modifiers/modifier-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  Tab,
  Loading,
  PageHeadings,
  TwoColumnList,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import TicketReceiptModal from "../../../modals/sales/tickets/ticket-receipt-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import RefundModal from "../../../modals/sales/tickets/refund-modal";
import VoidModal from "../../../modals/sales/tickets/void-modal";
import {PDFDownloadLink} from "@react-pdf/renderer";
import TicketPDF from "../../../features/pdf/ticket-pdf";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import SilkosCard from "../../../features/sales/ecommerce/silkos-card";

class EcommerceTicketPage extends Component {
  state = {ticket: null, ecommerce: null};

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({ticket: null, ecommerce: null}, async () => {
        await this.syncState();
      });
    }
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;

    let {TICKET: ticket, ECOMMERCE_ORDER: ecommerce} = await request(
      "ecommerce/orders/" + id,
      "GET",
      null
    );
    this.setState({ticket, ecommerce});
  };

  renderSelections(product) {
    const isRefunded = product.DATE_REFUNDED !== null;

    let customizations = product.SELECTIONS.reduce((dict, item) => {
      if (item.MODIFIER_ID in dict) {
        dict[item.MODIFIER_ID].push({...item});
      } else if (item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT) {
        return dict;
      } else {
        dict[item.MODIFIER_ID] = [{...item}];
      }

      return dict;
    }, {});

    let Notes = undefined;
    if (product.NOTES && product.NOTES.length > 0) {
      Notes = product.NOTES;
    }

    if (product.TYPE === "E_GIFT_CARD") {
      Notes = product.NOTES.split("-")[2];
    }

    let str = "";
    Object.values(customizations).forEach((item, index) => {
      str =
        str +
        item
          .map(
            (option) =>
              option.OPTION_NAME +
              (option.QUANTITY > 1 ? ` (${option.QUANTITY})` : "")
          )
          .join(", ");
      if (index !== Object.values(customizations).length - 1) {
        str = str + ", ";
      }
    });

    return (
      <div className={classNames(isRefunded && "line-through")}>
        <p className="mt-1 text-sm text-neutral-text">{str}</p>

        {Notes && (
          <p className="mt-1 text-sm text-neutral-text">Notes: {Notes}</p>
        )}
      </div>
    );
  }

  render() {
    const {ticket, ecommerce} = this.state;

    if (ticket === null) {
      return <Loading />;
    }

    const ticketInformation = [
      {
        label: "Name",
        value: ticket.NAME,
      },
      {
        label: "Number",
        value: ticket.TICKET_NUMBER,
      },
      {
        label: "Platform",
        value: ticket.FROM_OFFLINE ? (
          <div className="flex flex-row">
            <div>{ticket.PLATFORM}</div>
            <DangerBadge className="ml-3">Offline Processed</DangerBadge>
          </div>
        ) : (
          ticket.PLATFORM
        ),
      },
      {
        label: "Unique Identifier",
        value: ticket.UNIQUE_ID,
      },
    ];

    if (ticket.EMPLOYEE_NAME) {
      ticketInformation.push({
        label: "Cashier Name",
        value: ticket.EMPLOYEE_NAME,
      });
    }

    if (ticket.EMAIL) {
      ticketInformation.push({
        label: "Email",
        value: ticket.EMAIL,
      });
    }

    if (ticket.PHONE) {
      ticketInformation.push({
        label: "Phone",
        value: getFormattedPhone(ticket.PHONE),
      });
    }

    if (ticket.NOTES) {
      ticketInformation.push({
        label: "Notes",
        value: ticket.NOTES,
        span: true,
      });
    }

    let pdfName =
      ticket.TICKET_NUMBER +
      " " +
      moment(ticket.DATE_COMPLETED).format("MM/DD/YYYY") +
      " - " +
      ticket.LOCATION_NAME;

    let isVoided = ticket.STATUS === "VOIDED";
    let isRefunded = ticket.PAYMENT_INTENT.AMOUNT_REFUNDED;

    let fullyRefunded =
      ticket.PAYMENT_INTENT.AMOUNT_REFUNDED ===
      ticket.PAYMENT_INTENT.AMOUNT_PAYED;

    const isUnpaid =
      ticket.PAYMENT_INTENT.DATE_PAYED === null &&
      !ticket.PAYMENT_INTENT.PRE_AUTHORIZATION_ID;

    let ticketActionButtons = [];

    let ticketData = [
      {
        label: "Subtotal",
        value:
          "$" +
          toDollars(
            ticket.PAYMENT_INTENT.AMOUNT +
              ticket.PAYMENT_INTENT.AMOUNT_DISCOUNT -
              ticket.PAYMENT_INTENT.AMOUNT_TIP -
              ticket.PAYMENT_INTENT.AMOUNT_FEES
          ),
      },
      {
        label: "Discounts",
        value: "-$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_DISCOUNT),
      },
      {
        label: "Taxes",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_TAX),
      },
      {
        label: "Tips",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_TIP),
      },
      {
        label: "Fees",
        tooltip: {
          data: "These are fees that you have charged your customers. (Service Fees, Passed Processing Fees, Custom Fees, etc.)",
        },
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_FEES),
      },
      {
        label: "Total",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.TOTAL),
      },
    ];

    if (ticket.PAYMENT_INTENT.AMOUNT_REFUNDED > 0) {
      const tipRow = ticketData.find((row) => row.label === "Tips");

      if (tipRow) {
        tipRow.value =
          "$" +
          toDollars(
            ticket.ITEMS.reduce(
              (accum, curr) =>
                curr.TYPE === "TIP" ? curr.AMOUNT + accum : accum,
              0
            )
          );
      }

      const feeRow = ticketData.find((row) => row.label === "Fees");

      if (feeRow) {
        feeRow.value =
          "$" +
          toDollars(
            ticket.ITEMS.reduce(
              (accum, curr) =>
                curr.TYPE === "FEE" || curr.TYPE === "CUSTOM_FEE"
                  ? curr.AMOUNT + accum
                  : accum,
              0
            )
          );
      }

      ticketData.push({
        label: "Refunded",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_REFUNDED),
      });
    }

    if (!fullyRefunded) {
      ticketActionButtons.push({
        label: "Refund",
        onClick: () => this.refundModal.open(ticket),
      });
    }

    if (!isVoided) {
      ticketActionButtons.push({
        label: "Void",
        onClick: () => setTimeout(() => this.voidModal.open(ticket), 200),
      });
    }

    return (
      <div className="p-6 height-screen">
        <ModifierModal ref={(e) => (this.modifierModal = e)} />
        <TicketReceiptModal ref={(e) => (this.receiptModal = e)} />
        <RefundModal
          handleUpdate={this.syncState}
          ref={(e) => (this.refundModal = e)}
        />
        <VoidModal
          handleUpdate={this.syncState}
          ref={(e) => (this.voidModal = e)}
        />

        <PageHeadings
          label={"#" + ticket.TICKET_NUMBER + " " + ticket.NAME}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Ecommerce Orders", url: "/orders"},
          ]}
          buttons={[
            {
              label: "Actions",
              type: "dropdown",
              sections: [
                {
                  items: [
                    {
                      label: (
                        <PDFDownloadLink
                          document={<TicketPDF ticket={ticket} />}
                          fileName={pdfName}
                        >
                          {({blob, url, loading, error}) => (
                            <div>
                              <FontAwesomeIcon
                                icon="fa-file-pdf"
                                className={"h-5 w-5 mr-3 text-gray-400"}
                              />
                              {loading
                                ? "Loading document..."
                                : "Download as PDF"}
                            </div>
                          )}
                        </PDFDownloadLink>
                      ),
                    },
                    {
                      label: "Send Receipt",
                      icon: "receipt",
                      onClick: () => this.receiptModal.open(ticket),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <TwoColumnList
          buttons={[{label: "Edit Information"}]}
          label={
            <div className={"flex flex-row"}>
              <div className="mr-4">Ticket Information</div>

              {isVoided && <DangerBadge>Voided</DangerBadge>}
            </div>
          }
          description="Information about this ticket"
          data={ticketInformation}
        />

        <TwoColumnList
          buttons={ticketActionButtons}
          label={
            <div className={"flex flex-row"}>
              <div className="mr-4">Payment Overview</div>

              {!!isRefunded && (
                <GrayBadge darkGrey>{`${
                  fullyRefunded ? "Fully" : "Partially"
                } Refunded`}</GrayBadge>
              )}
              {!!isUnpaid && <DangerBadge>Unpaid</DangerBadge>}
            </div>
          }
          description="Information about the payment"
          data={ticketData}
        />

        <Tab
          data={[
            {id: "labels", label: "Packages & Labels"},
            {id: "items", label: "Line Items"},
            {id: "payments", label: "Payments"},
          ]}
        >
          {(id) => {
            if (id === "labels") {
              return <SilkosCard ticket={ticket} ecommerce={ecommerce} />;
            }

            if (id === "items") {
              const items = [
                ...ticket.ITEMS.filter(
                  (item) => item.TYPE !== "FEE" && item.TYPE !== "TIP"
                ),
                ...ticket.ITEMS.filter((item) => item.TYPE === "TIP"),
                ...ticket.ITEMS.filter((item) => item.TYPE === "FEE"),
              ];

              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={items}
                  ref={(e) => (this.tableRef = e)}
                  columns={[
                    {
                      value: "NAME",
                      label: "Item",
                      format: (value, row) => (
                        <div className="flex items-center">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getObjectImage(row, "LOGO", "appicon.png")}
                            alt=""
                          />

                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {row.NAME}
                            </div>

                            <div className="text-sm text-gray-500">
                              {this.renderSelections(row)}
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      value: "QUANTITY",
                      label: "Quantity",
                    },
                    {
                      value: "AMOUNT",
                      label: "Item Price",
                      format: (amount, row) =>
                        toDollars(amount + (row?.AMOUNT_DISCOUNT ?? 0), true),
                    },
                    {
                      value: "AMOUNT_DISCOUNT",
                      label: "Discounts",
                      format: (amount) => "-" + toDollars(amount, true),
                    },
                    {
                      value: "AMOUNT_TAX",
                      label: "Tax",
                      format: (tax) => toDollars(tax, true),
                    },
                    {
                      value: "TOTAL",
                      label: "Total",
                      format: (total) => toDollars(total, true),
                    },
                  ]}
                />
              );
            }

            if (id === "payments") {
              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={ticket.PAYMENT_INTENT.PAYMENTS}
                  ref={(e) => (this.tableRef = e)}
                  columns={[
                    {
                      value: "TYPE",
                      label: "Payment Method",
                    },
                    {
                      value: "AMOUNT",
                      label: "Amount Payed",
                      format: (val) => "$" + toDollars(val),
                    },
                    {
                      value: "AMOUNT_REFUNDED",
                      label: "Amount Refunded",
                      format: (val) => "$" + toDollars(val),
                    },
                  ]}
                />
              );
            }

            return <div>Hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(EcommerceTicketPage);
