import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import TagDetailsModal from "../../../modals/sales/tag-details-modal";

class TagPage extends Component {
  state = {tag: null};

  componentDidMount() {
    const {ID: id} = this.props.router.params;

    request("tag/" + id, "GET", null).then(async (tag) => {
      this.setState({tag});
    });
  }

  render() {
    const {tag} = this.state;

    if (tag === null) {
      return <Loading />;
    }

    return (
      <div>
        <TagDetailsModal
          ref={(e) => (this.tagModal = e)}
          updateState={(tag) => this.setState({tag})}
          onDelete={() => this.props.router.navigate("/tags")}
        />

        <PageHeadings
          label={tag.NAME}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Tags Page", url: "/tags"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.tagModal.open(tag),
            },
          ]}
          label="Tag Details"
          description="Information about this tag"
          data={[
            {label: "Name", value: tag.NAME},
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(TagPage));
