import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SuccessBadge from "../../../../components/badges/success-badge";
import DangerBadge from "../../../../components/badges/danger-badge";
import GratuityModal from "../../../../modals/sales/gratuity/auto-gratuity-modal";

class GratuitiesPage extends Component {
  state = {rules: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("gratuity", "GET").then((rules) => {
      this.setState({rules});
    });
  }

  render() {
    const {rules} = this.state;

    if (rules === null) {
      return <Loading />;
    }

    return (
      <div>
        <GratuityModal
          ref={(e) => (this.gratuityModal = e)}
          onSubmit={() => this.syncState()}
        />

        <PageHeadings
          label={"Gratuity"}
          description={
            "Setup your rules for automatically applying gratuity to orders"
          }
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/gratuity"},
            {label: "Gratuity Page", url: "/gratuity"},
          ]}
        />

        <Card
          label="Auto Gratuity Rules"
          description={`Configure your automatic gratuity rules.`}
          tooltip={{
            label: "Auto Gratuity",
            data:
              "Auto gratuity rules allow you to determine which orders should have gratuity automatically" +
              " applied. Only one auto gratuity rule can be enabled at a time.",
          }}
          button={{
            label: "Create Rule",
            onClick: () => this.gratuityModal.open(),
          }}
        >
          <Table
            data={rules}
            actionButtons={[
              {
                label: "View",
                onClick: (row) =>
                  this.props.router.navigate("/gratuity/" + row.UNIQUE_ID),
              },
              {
                icon: "trash",
                onClick: (row) => {
                  this.setState({rules: null}, () => {
                    request("gratuity/" + row.ID, "DELETE").then(() => {
                      this.syncState();
                    });
                  });
                },
              },
            ]}
            columns={[
              {label: "Gratuity Rule Name", value: "NAME"},
              {
                value: "THRESHOLD",
                label: `Threshold`,
                format: (threshold, row) => {
                  if (row.IS_COUNT) {
                    return threshold + " items";
                  }
                  return toDollars(threshold, true);
                },
              },
              {
                label: "Gratuity Amount",
                value: "GRATUITY_AMOUNT",
                format: (amount, row) => {
                  if (row.IS_PERCENT) {
                    return amount + "%";
                  }
                  return toDollars(amount, true);
                },
              },
              {
                label: "PRODUCTS/CATEGORIES",
                value: "TYPE",
                format: (value, row) => {
                  if (value === "ALL") {
                    return "All products";
                  } else if (value === "PRODUCT") {
                    return row.PRODUCTS.length === 1
                      ? "1 product"
                      : row.PRODUCTS.length + " products";
                  } else if (value === "CATEGORY") {
                    return row.CATEGORIES.length === 1
                      ? "1 category"
                      : row.CATEGORIES.length + " categories";
                  }

                  return "-";
                },
              },

              {
                label: "Status",
                value: "ENABLED",
                format: (enabled, _) => {
                  if (enabled) {
                    return <SuccessBadge>Enabled</SuccessBadge>;
                  }
                  return <DangerBadge yellow>Disabled</DangerBadge>;
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withTranslation()(withRouter(GratuitiesPage));
