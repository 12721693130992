import React, {Component} from "react";
import * as Yup from "yup";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getStore} from "../../redux";
import {fetchDefaultTaxRate} from "../../redux/shop";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import ModifierInventoryModal from "../../modals/sales/modifiers/modifier-inventory-modal";

class ModifierOptions extends Component {
  constructor(props) {
    super(props);

    this.setState = this.props.setState;
  }

  fetchTaxRate() {
    const {product} = this.props;

    if (product) {
      const rate = product.TAX_RATES.reduce((accum, item) => {
        if (item.IS_INCLUSIVE) {
          return accum;
        }

        return accum + item.RATE;
      }, 0);

      return rate / 100;
    }

    return getStore().dispatch(fetchDefaultTaxRate()) / 100;
  }

  render() {
    const {modifier, product, showModifier} = this.props;

    const tableColumns = [
      {
        value: "NAME",
        label: "Name",
        minWidth: "175px",
        editable: true,
        isSimple: true,
        placeholder: "Placeholder name",
        yup: Yup.string()
          .min(1, "Minimum length is 1")
          .required("Name is required"),
        onChange: (index, val) => {
          const options = [...modifier.OPTIONS];

          options.splice(index, 1, {
            ...options[index],
            NAME: val,
          });

          this.setState({
            modifier: {...modifier, OPTIONS: options},
          });
        },
        format: (value, row) => {
          return row.NAME;
        },
      },
      {
        value: "ENABLED",
        label: "Enabled",
        type: "select",
        data: [
          {label: "Yes", value: "1"},
          {label: "No", value: "0"},
        ],
        editable: true,
        width: "100px",
        onChange: (index, val) => {
          const options = [...modifier.OPTIONS];

          options.splice(index, 1, {
            ...options[index],
            ENABLED: val,
          });

          this.setState({
            modifier: {...modifier, OPTIONS: options},
          });
        },
      },
    ];

    if (modifier.PRESET_ID) {
      tableColumns.push({
        value: "DEFAULT",
        label: "Default",
        width: "100px",
        editable: true,
        type: "select",
        data: [
          {label: "Yes", value: "1"},
          {label: "No", value: "0"},
        ],
        onChange: async (index, val, option) => {
          const options = [...modifier.OPTIONS];
          const defaults = product.DEFAULTS;

          if (val === "1") {
            let route = `product/${product.ID}/preset/${modifier.PRESET_ID}/customization/${modifier.ID}/option/${option.ID}`;
            let globalPayload = await request(route, "POST", {});

            defaults.push(globalPayload);
          } else {
            await request(
              `customization/defaults/${option.DEFAULT_OBJECT.ID}`,
              "DELETE",
              {}
            );

            const defaultIndex = defaults.findIndex((item) => {
              return item.ID === option.DEFAULT_OBJECT.ID;
            });

            if (defaultIndex !== -1) {
              defaults.splice(defaultIndex, 1);
            }
          }

          options.splice(index, 1, {
            ...options[index],
            DEFAULT: val,
          });

          this.setState({
            modifier: {...modifier, OPTIONS: options},
          });
        },
      });
    }

    if (modifier.TYPE !== 4) {
      tableColumns.splice(
        1,
        0,
        ...[
          {
            icon: "dollar-sign",
            placeholder: "2.50",
            value: "PRICE",
            label: "Price",
            width: "100px",
            editable: true,
            isSimple: true,
            yup: Yup.number()
              .typeError("Must be a number")
              .required("Price is required"),
            onChange: (index, val) => {
              const options = [...modifier.OPTIONS];

              options.splice(index, 1, {
                ...options[index],
                PRICE: val,
              });

              this.setState({
                modifier: {...modifier, OPTIONS: options},
              });
            },
            format: (value, row) => {
              return row.PRICE;
            },
          },
          {
            icon: "dollar-sign",
            value: "PRICE_TAX",
            label: "Price w/ Tax",
            width: "100px",
            isSimple: true,
            tooltip: product
              ? ""
              : "The price with tax is calculated using the default tax rate. The price may change depending on the product to which this modifier is applied.",
            onChange: (index, val) => {
              const options = [...modifier.OPTIONS];

              options.splice(index, 1, {
                ...options[index],
                PRICE_TAX: val,
              });

              this.setState({
                modifier: {...modifier, OPTIONS: options},
              });
            },
            format: (_, item) => {
              return toDollars(
                decimalToDollars("" + item.PRICE) +
                  Math.round(
                    decimalToDollars("" + item.PRICE) * this.fetchTaxRate()
                  )
              );
            },
          },
        ]
      );
    }

    if (showModifier) {
      tableColumns.push({
        editable: true,
        value: "MODIFIER",
        label: "Modifier Multiple",
        tooltip: "The multiple the other modifiers will increase by",
        width: "100px",
        onChange: (index, val) => {
          const options = [...modifier.OPTIONS];

          options.splice(index, 1, {
            ...options[index],
            MODIFIER: val,
          });

          this.setState({
            modifier: {...modifier, OPTIONS: options},
          });
        },
      });
    }

    return (
      <>
        <ModifierInventoryModal ref={(e) => (this.inventoryModal = e)} />

        <Table
          white
          draggable
          verticalLines
          className="mt-4"
          columns={tableColumns}
          data={modifier.OPTIONS}
          onErrorChange={(tableInvalid) => this.setState({tableInvalid})}
          ref={(e) => (this.modifierTable = e)}
          actionTexts={[
            {
              label: "Add Option",
              onClick: () => {
                const options = [...modifier.OPTIONS];

                const seq = Math.max(
                  ...modifier.OPTIONS.map((item) => item.SEQ)
                );

                options.push({
                  NAME: "",
                  PRICE: "",
                  ENABLED: 1,
                  SEQ: seq + 1,
                  INGREDIENTS: [],
                });

                this.setState({
                  modifier: {...modifier, OPTIONS: options},
                });
              },
            },
          ]}
          actionButtons={[
            {
              icon: "user-chef",
              onClick: (item, index) => {
                this.inventoryModal.open(modifier, item);
              },
            },
            {
              icon: "trash",
              onClick: (row, index) => {
                const options = [...modifier.OPTIONS];

                options.splice(index, 1);

                if (this.modifierTable?.state?.errors) {
                  const newErrors = this.modifierTable.state.errors;

                  for (const key of Object.keys(
                    this.modifierTable.state.errors
                  )) {
                    if (key.includes(row.tid)) {
                      delete newErrors[key];
                    }
                  }

                  this.modifierTable.setState({errors: newErrors});
                }

                this.setState({
                  modifier: {...modifier, OPTIONS: options},
                });
              },
            },
          ]}
        />
      </>
    );
  }
}

export default ModifierOptions;
