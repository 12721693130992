import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import EmployeeTable from "../../../tables/team/employee-table";
import {setupReduxConnection} from "../../../redux";
import RolesTable from "../../../tables/team/roles-table";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

class ReorderPage extends Component {
  state = {employees: [], roles: []};

  restoreDefaultView() {
    let {employees} = this.state;

    showLoadingConfirmAlert(
      "Restore Default View",
      "This will revert employee order to alphabetical by name"
    ).then(async (close) => {
      employees.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));

      for (let i = 0; i < employees.length; i++) {
        employees[i].SEQ = i + 1;
      }
      this.setState({employees}, () => {
        close();
        this.saveEmployeeSequence();
      });
    });
  }

  saveEmployeeSequence() {
    const {employees} = this.state;

    request("employees/seq", "POST", {
      EMPLOYEES: employees.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      employees.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  saveRoleSequence() {
    const {roles} = this.state;

    request("employeeroles/seq", "POST", {
      ROLES: roles.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      roles.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {location} = this.props.shop;

    const serverRoles = await request("employeeroles/roles", "GET");

    const serverEmployees = await request("employees/v2", "GET", {
      ROLES: true,
      ACTIVE: 0,
    });

    serverEmployees.sort((a, b) => a.SEQ - b.SEQ);
    serverRoles.sort((a, b) => a.SEQ - b.SEQ);

    this.setState({
      employees: serverEmployees.filter(
        (item) => parseInt(item.LOCATION_ID) === location.ID
      ),
      roles: serverRoles,
    });
  }

  render() {
    const {employees} = this.state;
    const {roles} = this.state;
    const activeEmployees = employees?.filter((item) => item.ACTIVE === 1);
    const activeRoles = roles?.filter((item) => !item.DATE_ARCHIVED);

    return (
      <>
        <PageHeadings
          label="Reorder Employees"
          description="Determine the order that employees are displayed on the scheduler"
        />

        <Tab
          data={[
            {label: "Roles", id: "roles"},
            {label: "Employees", id: "employees"},
          ]}
        >
          {(id) => {
            if (id === "employees") {
              return (
                <Card
                  label="Employees"
                  description="Drag and drop the employees to choose your desired order"
                  button={{
                    label: "Restore Default",
                    onClick: () => this.restoreDefaultView(),
                  }}
                >
                  <EmployeeTable
                    data={activeEmployees}
                    draggable
                    onDrag={this.saveEmployeeSequence.bind(this)}
                  />
                </Card>
              );
            } else if (id === "roles") {
              return (
                <Card
                  label="Roles"
                  description="Drag and drop the roles to choose your desired order"
                >
                  <RolesTable
                    roles={activeRoles}
                    draggable
                    onDrag={this.saveRoleSequence.bind(this)}
                  />
                </Card>
              );
            } else {
              return <div>Invalid State!</div>;
            }
          }}
        </Tab>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(ReorderPage);
