import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";

class AdditionalTipsModal extends Component {
  state = {
    payrollId: null,
    employeeId: null,
    employeeHomeLocationId: null,
    name: null,
    additionTip: null,
    employeeLocations: null,
  };

  async componentDidMount() {
    this.setState({
      employeeLocations: await request("employees/locations", "GET"),
    });
  }

  openNew(payrollId, employeeId, employeeHomeLocationId, name) {
    this.setState(
      {
        payrollId,
        employeeId,
        employeeHomeLocationId,
        name,
        additionalTip: {AMOUNT: "", TYPE: "paycheck_tips"},
      },
      this.modal.open()
    );
  }

  open(payrollId, employeeId, employeeHomeLocationId, name, additionalTip) {
    this.setState(
      {payrollId, employeeId, employeeHomeLocationId, name, additionalTip},
      this.modal.open()
    );
  }

  delete = async () => {
    let {employeeHomeLocationId} = this.state;

    await this.submitAdditionalTips(null, 0, employeeHomeLocationId);
    await this.props.refresh();

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {type, amount, location} = values;

    await this.submitAdditionalTips(type, amount, location);
    await this.props.refresh();

    this.modal.close();
  };

  submitAdditionalTips = async (type, amount, location) => {
    let {payrollId, employeeId, additionalTip} = this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: employeeId,
      TIP_TYPE: type,
      AMOUNT: decimalToDollars(amount),
      ID: additionalTip?.ID,
      LOCATION_ID: location,
    };

    await request("payroll/run-payroll/set-additional-tips", "POST", payload);
  };

  render() {
    let {name, additionalTip, employeeLocations, employeeHomeLocationId} =
      this.state;
    let {dbaLocations} = this.props;

    return (
      <Modal
        label={`Additional Tips for ${name}`}
        buttonLabel="Save"
        deleteLabel="Delete"
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            amount: additionalTip ? toDollars(additionalTip.AMOUNT) : "",
            type: additionalTip?.TYPE,
            location: additionalTip?.LOCATION_ID
              ? additionalTip.LOCATION_ID
              : employeeHomeLocationId,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={"Payout Type"}
                  name={"type"}
                  data={[
                    {
                      label: "Paycheck Tips",
                      value: "paycheck_tips",
                    },
                    {
                      label: "Cash Tips",
                      value: "cash_tips",
                    },
                  ]}
                  options={formikOptions}
                />

                <FormInput
                  label={"Amount (in $)"}
                  name={"amount"}
                  options={formikOptions}
                />

                {dbaLocations?.length > 1 && (
                  <FormSelect
                    label={"Location"}
                    name={"location"}
                    options={formikOptions}
                    data={dbaLocations.map((loc) => ({
                      label: loc.NAME,
                      value: loc.ID,
                    }))}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  AdditionalTipsModal
);
