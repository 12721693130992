import {SHIFT_VIEWS, VIEWS} from "../features/scheduling/scheduler";

const defaultState = {
  projectedTotals: null,
  isLoading: true,
  currentView: "customWeek",
  shiftView: "SCHEDULE_ROLE",
  currentViewString: "hi!"
}

const UPDATE_STATS = "UPDATE_STATS";
const UPDATE_LOADING = "UPDATE_LOADING";
const UPDATE_SCHEDULING_STATS = "UPDATE_SCHEDULING_STATS";
const UPDATE_CURRENT_VIEW_STRING = "UPDATE_CURRENT_VIEW_STRING";

export function updateCurrentViewString(payload){
  return {type: UPDATE_CURRENT_VIEW_STRING, payload};
}

export function updateSchedulingStats(payload){
  return {type: UPDATE_SCHEDULING_STATS, payload};
}

export function updateStats(payload) {
  return {type: UPDATE_STATS, payload};
}

export function updateLoading(payload){
  return {type: UPDATE_LOADING, payload};
}

export function setCurrentViewString(payload){
  return (dispatch) => {
    dispatch(updateCurrentViewString(payload));
  };
}

export function setStats(payload) {
  return (dispatch) => {
    dispatch(updateStats(payload));
  };
}

export function setSchedulingStats(payload){
  return (dispatch) => {
    dispatch(updateSchedulingStats(payload));
  };
}

export const schedulingStatsReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_SCHEDULING_STATS:
      return {...state, ...payload}
    case UPDATE_STATS:
      return {...state, projectedTotals:payload};
    case UPDATE_LOADING:
      return {...state, isLoading: payload};
    case UPDATE_CURRENT_VIEW_STRING:
      return {...state, currentViewString: payload};
  }
};
