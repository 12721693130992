import React, {Component} from "react";
import FormElement from "./form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";

class FormTextArea extends Component {
  render() {
    const {name, rows = 3, placeholder, disabled = false} = this.props;

    return (
      <FormElement {...this.props}>
        {(value, error, onChange, onBlur, extraStyle) => {
          return (
            <textarea
              {...this.props}
              id={name}
              rows={rows}
              name={name}
              value={value}
              ref={(e) => (this.textAreaRef = e)}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              onChange={onChange}
              className={classNames(
                disabled ? "text-neutral-text" : undefined,
                error
                  ? "text-red-900  placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                "shadow-sm block w-full sm:text-sm rounded-md",
                extraStyle
              )}
              aria-describedby="email-error"
              aria-invalid="true"
            />
          );
        }}
      </FormElement>
    );
  }
}

FormTextArea.propTypes = {
  ...FormElement.propTypes,

  rows: PropTypes.number,
  placeholder: PropTypes.string,
};

export default FormTextArea;
