import React, {Component} from "react";
import {
  PageHeadings,
  Card,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import SuccessBadge from "../../../components/badges/success-badge";
import DangerBadge from "../../../components/badges/danger-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import EmailCampaignModal from "../../../modals/marketing/campaigns/email-campaign-modal";
import MarketingTab from "../../../features/shop/shop-settings/marketing-tab";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {setupReduxConnection} from "../../../redux";
import PatronSelectModal from "../../../modals/marketing/patron-select-modal";
import TextCampaignModal from "../../../modals/marketing/campaigns/text-campaign-modal";
import CardAlert from "../../../features/card-alert";
import PurchaseTextCreditsModal from "../../../modals/marketing/campaigns/purchase-text-credits-modal";
import {showErrorAlert} from "../../../utils/alert-helper";
import moment from "moment-timezone";
import {TEXT_CAMPAIGN_TYPES} from "../../../utils/marketing-constants";

class CampaignsPage extends Component {
  state = {
    retentions: null,
    emails: null,
    texts: null,
    companyName: null,
    lists: [],
  };

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("marketing/campaigns/email", "GET").then((emails) => {
      emails.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED);
      this.setState({emails});
    });

    request("text/marketing/lists", "GET", null).then((lists) => {
      this.setState({lists});
    });

    request("text/marketing/campaigns", "GET", null).then((texts) => {
      texts = texts.filter((campaign) => !campaign.DATE_ARCHIVED);
      texts.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED);
      this.setState({texts});
    });
  }

  createSubscriptionList() {
    request("text/marketing/list", "POST", {NAME: "Default Subscription List"})
      .then(() => this.syncState())
      .catch((err) => {
        console.log(err);
      });
  }

  removePatron(row) {
    const {ID} = row;
    request("text/marketing/unsubscribe", "POST", {PATRON_ID: ID}).then(() => {
      this.syncState();
    });
  }

  render() {
    const {emails, texts, lists} = this.state;
    const {
      MARKETING_PHONE_NUMBER,
      TEXT_MARKETING_PURCHASED_CREDITS,
      TEXT_MARKETING_AUTO_CREDITS,
    } = this.props.shop.companySettings;

    return (
      <div>
        <PurchaseTextCreditsModal ref={(e) => (this.creditModal = e)} />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>Marketing Campaigns</div>

              <GrayBadge darkGrey className={"ml-3 mt-2"}>
                Beta
              </GrayBadge>
            </div>
          }
          description="Create email and text campaigns that can be sent out to customers"
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Purchase Credits",
                      onClick: () => this.creditModal.open(),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <Tab
          data={[
            {label: "Email Campaigns", id: "email"},
            {label: "Text Campaigns", id: "text"},
          ]}
        >
          {(id) => {
            if (id === "email") {
              return (
                <>
                  <EmailCampaignModal
                    ref={(e) => (this.campaignModal = e)}
                    addState={(item) => {
                      this.props.router.navigate(
                        "/campaigns/email/" + item.UNIQUE_ID
                      );
                    }}
                  />

                  <MarketingTab onlyEmail />

                  <Card
                    label="Email Campaigns"
                    description="One off marketing campaigns that use e-mail based communication."
                    button={{
                      theme: "primary",
                      label: "Create",
                      icon: "plus",
                      type: "list",
                      onChange: ({id}) => {
                        if (id === "email") {
                          this.campaignModal.open();
                        }
                      },
                      items: [
                        {
                          id: "email",
                          label: "Email Campaign",
                          description:
                            "Create a new Email Campaign for one off campaigns.",
                        },
                      ],
                    }}
                  >
                    <Table
                      pagination
                      data={emails}
                      ref={(e) => (this.tableRef = e)}
                      actionButtons={[
                        {
                          label: "View",
                          onClick: (row) =>
                            this.props.router.navigate(
                              "/campaigns/email/" + row.UNIQUE_ID
                            ),
                        },
                      ]}
                      columns={[
                        {
                          value: "NAME",
                          label: "Name",
                          width: 1,
                        },
                        {
                          value: "DATE_CREATED",
                          label: "Date Created",
                          type: "date",
                        },
                        {
                          value: "DATE_EXECUTED",
                          label: "Status",
                          format: (value) =>
                            value ? (
                              <SuccessBadge>Sent</SuccessBadge>
                            ) : (
                              <DangerBadge yellow>Draft</DangerBadge>
                            ),
                        },
                      ]}
                    />
                  </Card>
                </>
              );
            }
            if (id === "text") {
              return (
                <>
                  <PatronSelectModal
                    ref={(e) => (this.patronModal = e)}
                    updateState={() => {
                      this.syncState();
                    }}
                  />

                  <TextCampaignModal
                    ref={(e) => (this.textCampaignModal = e)}
                    subscriptionLists={lists}
                    updateState={(serverCampaign) => {
                      this.props.router.navigate(
                        "/campaigns/text/" + serverCampaign.UNIQUE_ID
                      );
                    }}
                  />

                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
                    <CardAlert
                      label="Free Credits"
                      icon="paper-plane"
                      value={TEXT_MARKETING_AUTO_CREDITS}
                      subtext={"free sends remaining this month"}
                      link="/credits"
                    />

                    <CardAlert
                      label="Purchased Credits"
                      icon="usd"
                      value={TEXT_MARKETING_PURCHASED_CREDITS}
                      subtext={"purchased sends remaining"}
                      link="/credits"
                    />
                  </div>

                  <TwoColumnList
                    label="Text Marketing Settings"
                    description="View your text marketing options"
                    data={[
                      {
                        label: "Marketing Phone Number",
                        hint: "Company Setting",
                        value: MARKETING_PHONE_NUMBER ? (
                          getFormattedPhone(MARKETING_PHONE_NUMBER)
                        ) : (
                          <div>
                            Please contact{" "}
                            <a
                              href={"mailto:support@dripos.com"}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              support@dripos.com
                            </a>{" "}
                            if you would like to set up a marketing phone
                            number!
                          </div>
                        ),
                        tooltip: {
                          label: "Marketing Phone Number",
                          data: "This is the phone number that you own that is set up for your account.",
                        },
                      },
                    ]}
                  />

                  <Tab
                    data={[
                      {label: "One Off Campaigns", id: "one"},
                      {label: "Birthday Campaigns", id: "bday"},
                      {label: "Welcome Campaigns", id: "welcome"},
                    ]}
                    noQuery={true}
                  >
                    {(id) => {
                      let label = "One Off Campaigns";
                      let buttonLabel = "Create One Off Campaign";
                      let description = "Manage your one off campaigns";
                      let tooltip = {
                        data: [],
                      };

                      if (id === "one") {
                        label = "One Off Campaigns";
                        description = "Manage your one off campaigns";
                        buttonLabel = "Create One Off Campaign";
                        tooltip.data.push({
                          label: "One Off Campaigns",
                          data: "Create text campaigns to send out to subscribers to alert them of happenings in your shop!",
                        });
                      } else if (id === "bday") {
                        label = "Birthday Campaigns";
                        buttonLabel = "Create Birthday Campaign";
                        description = "Manage your birthday campaigns";
                        tooltip.data.push({
                          label: "Birthdays",
                          data: "Create a Birthday campaign that will send out to subscribers on their Birthday.",
                        });
                      } else if (id === "welcome") {
                        label = "Welcome Campaigns";
                        buttonLabel = "Create Welcome Campaign";
                        description = "Manage your welcome campaigns";
                        tooltip.data.push({
                          label: "Welcome Campaigns",
                          data: "Create a Welcome campaign that will send out to subscribers when they first subscribe to text marketing.",
                        });
                      }

                      return (
                        <Card
                          label={label}
                          description={description}
                          button={{
                            label: buttonLabel,
                            onClick: () => {
                              if (!MARKETING_PHONE_NUMBER) {
                                return showErrorAlert(
                                  "No Marketing Number",
                                  "You must have a marketing phone number to create a marketing campaign. Please reach out to support@dripos.com to create a phone number."
                                );
                              }

                              this.textCampaignModal.open(
                                null,
                                id === "bday"
                                  ? TEXT_CAMPAIGN_TYPES.BIRTHDAY
                                  : id === "welcome"
                                  ? TEXT_CAMPAIGN_TYPES.WELCOME
                                  : TEXT_CAMPAIGN_TYPES.ONE_OFF
                              );
                            },
                          }}
                          tooltip={tooltip}
                        >
                          <Table
                            data={
                              texts?.filter((text) => {
                                if (id === "bday") {
                                  return (
                                    text.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                                  );
                                } else if (id === "welcome") {
                                  return (
                                    text.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                                  );
                                } else {
                                  return (
                                    text.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
                                  );
                                }
                              }) ?? []
                            }
                            columns={[
                              {
                                value: "NAME",
                                label: "Campaign Name",
                              },
                              {
                                value: "TYPE",
                                label: "Type",
                                format: (value, _) =>
                                  value === TEXT_CAMPAIGN_TYPES.ONE_OFF
                                    ? "One Off"
                                    : value === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                                    ? "Birthday"
                                    : value === TEXT_CAMPAIGN_TYPES.WELCOME
                                    ? "Welcome"
                                    : "",
                              },
                              {
                                value: "DATE_CREATED",
                                label: "Date Created",
                                type: "date",
                              },
                              {
                                value: "DATE_EXECUTED",
                                label: "Send Date",
                                format: (value, row) => {
                                  if (
                                    row.TYPE !== TEXT_CAMPAIGN_TYPES.ONE_OFF
                                  ) {
                                    return "-";
                                  }
                                  if (value) {
                                    return moment(value).format(
                                      "M/D/YY hh:mm A"
                                    );
                                  } else if (row.DATE_SCHEDULED) {
                                    return moment(row.DATE_SCHEDULED).format(
                                      "M/D/YY hh:mm A"
                                    );
                                  } else {
                                    return "-";
                                  }
                                },
                              },
                              {
                                value: "DATE_EXECUTED",
                                label: "Status",
                                format: (value, row) =>
                                  !row.DATE_EXECUTED ? (
                                    row.DATE_SCHEDULED ? (
                                      <SuccessBadge>Scheduled</SuccessBadge>
                                    ) : (
                                      <DangerBadge yellow>Draft</DangerBadge>
                                    )
                                  ) : (
                                    <SuccessBadge>
                                      {row.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
                                        ? "Sent"
                                        : "Active"}
                                    </SuccessBadge>
                                  ),
                              },
                            ]}
                            actionButtons={[
                              {
                                label: "View",
                                onClick: (row) =>
                                  this.props.router.navigate(
                                    "/campaigns/text/" + row.UNIQUE_ID
                                  ),
                              },
                            ]}
                            pagination
                          />
                        </Card>
                      );
                    }}
                  </Tab>
                </>
              );
            }

            return <Card label="This Campaign">?</Card>;
          }}
        </Tab>
      </div>
    );
  }
}
export default setupReduxConnection(["shop"])(withRouter(CampaignsPage));
