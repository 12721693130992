import React, {Component} from "react";
import Select from "react-dropdown-select";
import {PlusIcon, SearchIcon} from "@heroicons/react/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HEADER_MENU, SEARCHABLE_SIDE_MENUS} from "../../settings/menu";
import Fuse from "fuse.js";
import algoliasearch from "algoliasearch/lite";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showSuccessNotification} from "../../utils/notification-helper";
import {
  faCoffee,
  faLayerGroup,
  faStore,
  faTicket,
  faUserGroup,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import {request} from "../../utils/request";

const TYPE_LINKS = {
  employees: "/employee/",
  tickets: "/ticket/",
  patrons: "/patron/",
  products: "/product/",
  roles: "/role/",
  categories: "/category/",
  locations: "/admin/location/",
};

export const HIT_ICON = {
  employees: faUserGroup,
  tickets: faTicket,
  patrons: faUsers,
  products: faCoffee,
  roles: faUserGroup,
  categories: faLayerGroup,
  locations: faStore,
};

const TYPE_LABEL = {
  employees: "Employee",
  tickets: "Ticket",
  patrons: "Patron",
  products: "Product",
  route: "Page",
  roles: "Role",
  categories: "Category",
  locations: "Location",
};

class GlobalSearch extends Component {
  state = {
    open: false,
    lastSearch: "",
    options: [],
    search: null,
    client: null,

    selected: null,
  };

  constructor(props) {
    super(props);

    this.requestStack = [];
  }

  componentDidMount() {
    if (this.props.shop.location) {
      this.resetAlgolia();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shop.location === null && this.props.shop.location !== null) {
      this.resetAlgolia();
    }
  }

  resetAlgolia() {
    const {location} = this.props.shop;

    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA,
      location.SEARCH_ID
    );

    this.setState({client});
  }

  async onSelectItem(value) {
    if (!value) {
      return;
    }

    const {lastSearch} = this.state;

    if (value.LOCATION_ID) {
      const serverLocation = await request(
        "admin/location/" + value.LOCATION_ID,
        "GET"
      );

      localStorage.setItem("CURRENT_LOCATION", "" + serverLocation.UNIQUE_ID);
    }

    let initialRoute = TYPE_LINKS[value.type];
    if (value.type === "route") {
      initialRoute = value.url;
    } else if (value.type === "all") {
      initialRoute = "search?query=" + lastSearch;
    } else if (initialRoute) {
      initialRoute += value.UNIQUE_ID;
    }

    if (initialRoute) {
      this.props.updateLayoutFromAdmin(true);

      return this.props.router.navigate(initialRoute);
    }

    showSuccessNotification("Can't figure it out", "What happened");
  }

  search(search, ignoreStack) {
    const {lastSearch, options} = this.state;

    if (lastSearch === search) {
      return options;
    }

    if (search === "") {
      return this.setState({lastSearch: search, options: []}, () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      });
    }

    if (this.selectRef) {
      this.selectRef.state.cursor = 0;
    }

    request("admin/search", "POST", {SEARCH: search})
      .then(
        ({
          tickets,
          patrons,
          employees,
          products,
          roles,
          categories,
          locations = [],
        }) => {
          const options = [
            ...employees.map((item) => {
              return {
                ...item,
                type: "employees",
                name: item.FULL_NAME + " (" + item.COMPANY_NAME + ")",
                icon: HIT_ICON["employees"],
              };
            }),
            ...categories.map((item) => {
              return {
                ...item,
                type: "categories",
                icon: HIT_ICON["categories"],
                name: item.NAME,
              };
            }),
            ...roles.map((item) => {
              return {
                ...item,
                type: "roles",
                name: item.NAME,
                icon: HIT_ICON["roles"],
              };
            }),
            ...patrons.map((item) => {
              return {
                ...item,
                type: "patrons",
                name: item.FULL_NAME,
                icon: HIT_ICON["patrons"],
              };
            }),
            ...products.map((item) => {
              return {
                ...item,
                name: item.NAME,
                type: "products",
                icon: HIT_ICON["products"],
                extra: item.CATEGORY_NAME,
              };
            }),
            ...tickets.map((item) => {
              return {
                ...item,
                type: "tickets",
                name: item.NAME,
                icon: HIT_ICON["tickets"],
                extra: "$" + toDollars(item.TOTAL),
              };
            }),
            ...locations.map((item) => {
              return {
                ...item,
                type: "locations",
                name: item.NAME,
                icon: HIT_ICON["locations"],
              };
            }),
          ];

          if (options.length > 0) {
            options.unshift({
              type: "all",
              id: "towhoeverwantstoreadthisyouarefat:)",
              name: "Showing all results for " + search,
            });
          }

          this.setState(
            {
              lastSearch: search,
              options,
            },
            () => {
              this.requestStack.shift();

              if (this.requestStack.length > 0) {
                this.requestStack = [this.requestStack.pop()];

                this.requestStack[0]();
              }
            }
          );
        }
      )
      .catch(() => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      });
  }

  render() {
    const {permissions: userPermissions} = this.props.user;
    const {lastSearch, options, client} = this.state;

    return (
      <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
        <div className="max-w-lg w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>

          <Select
            handleKeyDownFn={(event) => {
              if (event.event.code === "Enter") {
                event.event.preventDefault();
                event.event.stopPropagation();

                this.search(event.state.search);
              }
            }}
            noDataRenderer={() => {
              if (lastSearch === "") {
                return (
                  <div className="text-center p-6">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Type then click enter to search
                    </h3>

                    <p className="mt-1 text-sm text-gray-500">
                      Use query params like location:27 or is:ticket
                    </p>

                    <p className="mt-1 text-sm text-gray-500">
                      Look for things like locations, accounts, tickets,
                      employees, patrons, products.
                    </p>
                  </div>
                );
              }

              return (
                <div className="text-center p-6">
                  <FontAwesomeIcon
                    icon="cloud-question"
                    className="mx-auto h-12 w-12 text-gray-400"
                  />

                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No records found
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    We could not find anything with the search you inputted.
                  </p>

                  <div className="mt-4">
                    <a
                      target="_blank"
                      href="https://support.dripos.com/"
                      className="font-medium text-indigo-500 cursor-pointer hover:text-indigo-700"
                    >
                      View support docs
                    </a>
                  </div>
                </div>
              );
            }}
            options={options}
            onChange={(values) => this.onSelectItem(values[0])}
            className="global-search block w-full z-20"
            onDropdownClose={() => this.setState({options: [], lastSearch: ""})}
            dropdownHandleRenderer={() => {
              return <div />;
            }}
            ref={(e) => (this.selectRef = e)}
            inputRenderer={({state, methods, inputRef}) => {
              return (
                <form
                  className="relative w-full"
                  onSubmit={(e) => {
                    e.preventDefault();

                    methods.addItem({type: "all"});
                  }}
                >
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>

                  <input
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-500 focus:ring-primary-border focus:border-primary-border sm:text-sm"
                    placeholder="Search..."
                    onChange={methods.setSearch}
                    value={state.search}
                    autoComplete="off"
                    type="search"
                    ref={inputRef}
                    id="global-search"
                  />
                </form>
              );
            }}
            itemRenderer={({item, state, itemIndex, methods}) => {
              const isSelected = state.cursor === itemIndex;
              const {name, icon, type, extra} = item;

              return (
                <div
                  onClick={() => methods.addItem(item)}
                  className={classNames(
                    isSelected && "bg-indigo-500 text-white",
                    "group px-4 py-2 hover:bg-indigo-500 hover:text-white flex flex-row items-center"
                  )}
                >
                  {icon && (
                    <div className="mr-3">
                      <FontAwesomeIcon icon={icon} className="h-6 w-6 mt-1" />
                    </div>
                  )}

                  <div className="flex flex-col flex-1">
                    <div className="font-medium">{name}</div>

                    <div
                      className={classNames(
                        isSelected
                          ? "text-white"
                          : "text-gray-500 group-hover:text-white",
                        "text-sm"
                      )}
                    >
                      {TYPE_LABEL[type]}
                    </div>
                  </div>

                  {extra && <div className="font-medium size-sm">{extra}</div>}
                </div>
              );
            }}
            searchFn={({state}) => {
              return options;
            }}
          />
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(withRouter(GlobalSearch));
