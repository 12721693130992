import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ADPMappingTab extends Component {
  state = {currentIndex: 0, adpExport: null, adpExportDisplay: null, steps: null};

  componentDidMount() {
    this.mapADP();

    let {data} = this.props.payroll.importData.checkTemplateData;

    let steps = Object.keys(data[0]).map(key => {
      if (!DO_NOT_MAP_FIELDS.includes(key)) {
        return {
          label: key, key
        }
      }
    }).filter(step => step);

    this.setState({steps})
  }

  handleClick = () => {
    let {adpExport, steps} = this.state;

    let paycheckToCheckMapping = steps.reduce((dict, step) => {
      if(step.value){
        dict[step.value.label] = {key: step.key, index: step.value.index, skip: step.value.skip}
      }

      return dict;
    }, {});

    this.props.updateImport({adpExport, paycheckToCheckMapping});

    return true;
  }

    mapADP = () => {
    let {exportData} = this.props.payroll.importData;
    let adpExport = [];

    for (let index in exportData) {
      if (exportData[index][0].startsWith("Employee:") && !exportData[parseInt(index) + 1][0].startsWith("1099")) {
        let arr = [];
        for (let i = 0; i < Object.keys(exportData[index]).length; i++) {
          let string = exportData[parseInt(index) + 1][i];

          if (isNaN(parseInt(string[0]))) {
            arr = [...arr, ...this.getStringArr(string)];
          }
        }

        adpExport.push({name: exportData[index][0].replace("Employee: ", ""), data: arr})
      }
    }

    // Turn export into display
    let displayDict = {};

    for (let {data} of adpExport) {
      for (let {label, numbers} of data) {
        displayDict[label] = Math.max(displayDict[label] ?? numbers.length, numbers.length);
      }
    }

    let adpExportDisplay = [];
    for (let key of Object.keys(displayDict)) {
      adpExportDisplay.push({label: key, count: displayDict[key], selectedStep: null});
    }

    this.setState({adpExport, adpExportDisplay});
  }

  getStringArr(str) {
    let splitArr = str.split(" ");
    let arr = [];
    let stringBuilder = "";
    let parsingString = false;
    for (let entry of splitArr) {
      if (!isNaN(parseInt(entry[0]))) {
        // Parsing a digit
        stringBuilder = "";
        if (!arr[arr.length - 1]) {
          console.log("pizza", str)
        }
        arr[arr.length - 1].numbers.push(entry);

        parsingString = false;
      } else {
        if (parsingString) {
          // Continuing a string
          arr[arr.length - 1].label += ` ${entry}`;
        } else {
          // New string
          arr.push({label: entry, numbers: []})
        }

        parsingString = true;
      }
    }

    return arr;
  }

  render() {
    let {currentIndex, adpExportDisplay, steps} = this.state;

    return <div className="p-6 flex flex-row">
      <div className="overflow-y-auto" style={{
        maxHeight: 500
      }}>
        {steps?.map((step, index) => (
          <div
            className={classNames(currentIndex === index && "border-indigo-400 border-2", "justify-between import-step items-center flex flex-row px-2 py-2 hover:bg-gray-100 cursor-pointer")}
            onClick={() => this.setState({currentIndex: index})}
          >
            <div>
              {!step.value && <span className="mr-3">{index + 1}.</span>}

              {step.value && <FontAwesomeIcon
                className="text-indigo-500 mr-3"
                icon="fa-solid fa-check"
              />}

              {step.label}
            </div>

            {step?.value?.skip &&
              <div>Skip</div>
            }
          </div>
        ))}
      </div>

      <div className="px-20">
        {adpExportDisplay?.map(({label, count, selectedStep}) => (
            <div>
              <div>{label}</div>
              <div className={"flex flex-row"}>
                {new Array(count).fill(1).map((value, index) => {
                  let currentStep = steps[currentIndex];
                  let currentSelection = currentStep?.value?.label === label && currentStep?.value?.index === index;
                  let selectedIndex = steps.findIndex(step => step?.value?.label === label && step?.value?.index === index)
                  return (
                    <div
                      className={classNames("text-center h-5 w-5 hover:bg-gray-50 cursor-pointer text-sm border", selectedIndex > -1 && !currentSelection && "border-indigo-500", currentSelection && "border-black")}
                      onClick={() => {
                        steps[currentIndex].value = {label, index, skip: false};

                        this.setState(prevState => ({steps, currentIndex: prevState.currentIndex + 1}));
                      }}
                    >{selectedIndex > -1 && (selectedIndex + 1)}</div>
                  )
                })}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  }
}

const DO_NOT_MAP_FIELDS = [
  "Employee Name",
  "Period Start",
  "Period End",
  "Payday",
  "Employee ID",
  "Contractor ID",
  "Contractor Name",
  "Workplace ID"
]

export default setupReduxConnection(["payroll"])(
  withRouter(ADPMappingTab)
);
