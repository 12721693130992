import React, {Component} from "react";
import Wizard from "../../../../components/wizard";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import CouponForm from "../../../../forms/marketing/promotions/coupon-form";
import PromotionForm from "../../../../forms/marketing/promotions/promotion-form";
import CouponCard from "../../../../features/marketing/coupon-card";
import PromotionCard from "../../../../features/marketing/promotion-card";

class CreateCouponPage extends Component {
  variant = null;

  async submitProduct({coupon, promotion}) {
    delete coupon.NUM_REDEEMS;
    delete promotion.PRODUCT_NAME;
    delete promotion.CATEGORY_NAME;

    const serverCoupon = await request("promotions/coupons", "POST", {
      ...coupon,
      PROMOTION: promotion,
    });

    this.props.router.navigate("/coupon/" + serverCoupon.UNIQUE_ID);
  }

  render() {
    return (
      <Wizard
        ref={(e) => (this.wizardRef = e)}
        onSubmit={this.submitProduct.bind(this)}
        steps={[
          {
            id: 0,
            name: "Coupon info",
            description: "The details about the coupon code",
            render: ({values}, {handleSubmit, setupRef}) => {
              return (
                <CouponForm
                  ref={(e) => setupRef(e)}
                  handleSubmit={handleSubmit}
                  coupon={values?.coupon}
                />
              );
            },
          },
          {
            id: 1,
            name: "Promotion info",
            description: "The details about the promotion and discount",
            render: ({values}, {handleSubmit, setupRef}) => {
              return (
                <PromotionForm
                  ref={(e) => setupRef(e)}
                  handleSubmit={handleSubmit}
                  promotion={values?.promotion}
                />
              );
            },
          },
          {
            id: 2,
            name: "Preview",
            description: "Confirm the details of the coupon",
            render: ({values}) => {
              const {coupon, promotion} = values;

              return (
                <>
                  <CouponCard
                    handleCard={() => this.wizardRef.setStep(0)}
                    coupon={coupon}
                  />

                  <PromotionCard
                    handleCard={() => this.wizardRef.setStep(1)}
                    promotion={promotion}
                  />
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreateCouponPage);
