import React, {Component} from "react";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import Card from "./card";
import Tooltip from "../tooltip/tooltip";
import moment from "moment";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

let Link = null;

try {
  require.resolve("react-router-dom");

  Link = require("react-router-dom").Link;
} catch (e) {}

class TwoColumnList extends Component {
  render() {
    const {data} = this.props;

    return (
      <Card {...this.props}>
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {data
              .filter((item) => {
                if (item.hide) {
                  return !!item.value;
                }

                return true;
              })
              .map((item) => {
                const {label, value, span, type, tooltip, link, hint, custom} = item;

                if (custom) {
                  return (
                    <div
                      className={classNames(
                        span ? "sm:col-span-2" : "sm:col-span-1"
                      )}
                    >
                      {custom()}
                    </div>
                  );
                }

                let componentValue = value;
                if (type === "color") {
                  componentValue = (
                    <div
                      style={{
                        backgroundColor: value.includes("#")
                          ? value
                          : `#${value}`,
                      }}
                      className="h-5 w-5 "
                    />
                  );
                }

                if (type === "bool" || type === "boolean") {
                  componentValue = "" + value === "1" ? "Yes" : "No";
                }

                if (type === "date") {
                  componentValue = moment(value).format("MM/DD/YY");
                }

                if (type === "datetime") {
                  componentValue = moment(value).format("hh:mma MM/DD/YY");
                }

                if (type === "phone") {
                  componentValue = getFormattedPhone(value);
                }

                if (type === "dollar" || type === "dollars") {
                  componentValue = "$" + toDollars(value);
                }

                if (link) {
                  componentValue = (
                    <Link
                      to={link}
                      className="text-indigo-600 font-semibold hover:text-indigo-900 cursor-pointer"
                    >
                      {componentValue}
                    </Link>
                  );
                }

                return (
                  <div
                    className={classNames(
                      span ? "sm:col-span-2" : "sm:col-span-1"
                    )}
                  >
                    <div className="flex flex-row">
                      <dt className="text-sm font-medium text-gray-500">
                        {label}
                      </dt>

                      {tooltip && (
                        <>
                          {typeof tooltip === "string" ? (
                            <Tooltip className="ml-2" data={tooltip} />
                          ) : (
                            <Tooltip className="ml-2" {...tooltip} />
                          )}
                        </>
                      )}

                      {hint && (
                        <div
                          style={{marginTop: 2}}
                          className="flex ml-2 bg-gray-200 px-2 -py-1 rounded-md text-xs text-gray-500 items-center font-medium"
                        >
                          {hint}
                        </div>
                      )}
                    </div>

                    <dd className="mt-1 text-sm text-gray-900">
                      {componentValue}
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </Card>
    );
  }
}

TwoColumnList.propTypes = {
  ...Card.propTypes,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.object,
      type: PropTypes.string,
      span: PropTypes.bool,
    })
  ),
};

export default TwoColumnList;
