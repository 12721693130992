import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";

class ButtonGroup extends Component {
  fetchThemeAccents() {
    const {theme} = this.props;

    if (false) {
      return "bg-white text-gray-700 hover:bg-gray-50";
    }

    return "bg-indigo-600 text-white hover:bg-indigo-500";
  }

  render() {
    const {label, items} = this.props;

    const theme = this.fetchThemeAccents();

    return (
      <span className="relative z-0 inline-flex shadow-sm rounded-md">
        <button
          disabled
          type="button"
          className={classNames(
            theme,
            "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300  text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          )}
        >
          {label}
        </button>

        <Menu  as="span" className="-ml-px relative block">
          <Menu.Button
            className={classNames(
              theme,
              "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            )}
          >
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {items.map((item) => (
                  <Menu.Item key={item.name}>
                    {({active}) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </span>
    );
  }
}

ButtonGroup.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default ButtonGroup;
