import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PatronDropdown from "../../../dropdowns/marketing/patron-dropdown";
import * as Yup from "yup";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class GiftCardModal extends Component {
  state = {card: null};

  open(card = null) {
    this.setState({card});
    this.modal.open();
  }

  async saveCard({patronId, amount, notes, pan}) {
    const {card} = this.state;

    if (notes === "") {
      notes = null;
    }

    if (card) {
      await request("cards/partner/gift/" + card.ID, "PATCH", {
        AMOUNT: decimalToDollars(amount),
        NOTES: notes,
        ACCOUNT_ID: patronId,
      });
    } else {
      const serverCard = await request("cards/partner/gift", "POST", {
        ACCOUNT_ID: patronId,
        PAN_ID: pan,
        TOTAL: decimalToDollars(amount),
        BALANCE: decimalToDollars(amount),
        NOTES: notes,
      });
      this.props.onCreate && this.props.onCreate(serverCard);
    }

    this.props.updateState && this.props.updateState();
    this.modal.close();
  }

  render() {
    const {card} = this.state;
    const {patron} = this.props;

    return (
      <Modal
        buttonLabel={card ? "Save" : "Create"}
        label={card ? "Add Funds to Gift Card" : "Create Gift Card"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveCard.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape(
            {
              patronId: Yup.number()
                .nullable()
                .when("pan", {
                  is: (e) => !e || e.length === 0,
                  then: Yup.number()
                    .nullable()
                    .required("Patron or PAN ID is required."),
                }),
              pan: Yup.string()
                .nullable()
                .when("patronId", {
                  is: (e) => !e || e.length === 0,
                  then: Yup.string()
                    .nullable()
                    .required("Patron or PAN ID is required."),
                }),
              amount: Yup.number()
                .nullable()
                .typeError(
                  "Amount must be a number. Please remove any leading '$'."
                ),
              notes: Yup.string().nullable(),
            },
            [["patronId", "pan"]]
          )}
          initialValues={{
            patronId: patron
              ? patron.ACCOUNT_ID
              : card
              ? card.ACCOUNT_ID
              : null,
            pan: card ? card.PAN_ID : null,
            amount: null,
            notes: card?.NOTES ? card.NOTES : null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PatronDropdown
                  card={card}
                  formikOptions={formikOptions}
                  tooltip={{
                    data: "The patron associated with this gift card. Either Patron or PAN ID (or both) must be selected.",
                  }}
                />

                <FormInput
                  name="pan"
                  label="PAN ID"
                  options={formikOptions}
                  tooltip={{
                    data: "PAN ID is not editable for already created gift cards.",
                  }}
                  placeholder={!!card ? "-" : "1234567890"}
                  disabled={!!card}
                />

                <FormInput
                  name="amount"
                  label={card ? "Add Amount" : "Amount"}
                  tooltip={
                    card
                      ? {
                          data: "An optional amount to add (or subtract) to the balance of this gift card. If you would like to subtract, please input a negative number.",
                        }
                      : {data: "The starting balance of this gift card."}
                  }
                  options={formikOptions}
                  placeholder={"$25.00"}
                  hint={"Optional"}
                />

                <FormInput
                  name="notes"
                  label="Notes"
                  hint="Optional"
                  tooltip={{
                    data: "An optional notes field used to describe what this gift card is for.",
                  }}
                  options={formikOptions}
                  placeholder={"Happy Birthday!"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default GiftCardModal;
