import React, {Component} from "react";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";

class Tab extends Component {
  state = {tab: null};

  constructor(props) {
    super(props);

    this.id = "tab_" + randomString(24);
  }

  componentDidMount() {
    const {data, noQuery} = this.props;

    if (data.length > 0) {
      const hasQuery = this.fetchQueryParams();

      if (hasQuery === null || noQuery) {
        this.setState({tab: data[0].id});
      } else {
        this.setState({tab: hasQuery});

        const selector = document.querySelector("#" + this.id + "-" + hasQuery);

        if (selector) {
          selector.scrollIntoView();
        }
      }
    }
  }

  fetchQueryParams() {
    const url = new URL(window.location.href);

    if (url.searchParams.has("tab")) {
      let queryVal = url.searchParams.get("tab");
      if (!isNaN(queryVal)) {
        queryVal = parseInt(queryVal);
      }

      return queryVal;
    }

    return null;
  }

  updateQueryParams(id) {
    const {noQuery} = this.props;

    if (noQuery) {
      return;
    }

    const url = new URL(window.location.href);

    url.searchParams.set("tab", id);

    window.history.pushState({}, null, url);
  }

  render() {
    const {tab} = this.state;
    const {data, className} = this.props;

    return (
      <div className={classNames(className)}>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>

          <select
            id="tabs"
            name="tabs"
            value={data.find((dataTab) => dataTab.id === tab)?.id}
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              let tabVal = e.target.value;

              if (!isNaN(tabVal)) {
                tabVal = parseInt(tabVal);
              }

              this.setState({tab: tabVal}, () => {
                this.updateQueryParams(tabVal);
              });
            }}
          >
            {data.map((tab) => (
              <option value={tab.id} key={tab.id}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              style={{overflowX: "auto", paddingBottom: 2}}
              className="-mb-px flex space-x-8"
              aria-label="Tabs"
            >
              {data.map((dataTab) => (
                <a
                  id={this.id + "-" + dataTab.id}
                  key={dataTab.id}
                  onClick={() =>
                    this.setState({tab: dataTab.id}, () =>
                      this.updateQueryParams(dataTab.id)
                    )
                  }
                  aria-current={dataTab.id === tab ? "page" : undefined}
                  className={classNames(
                    dataTab.id === tab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer whitespace-nowrap"
                  )}
                >
                  {dataTab.icon && (
                    <dataTab.icon
                      className={classNames(
                        dataTab.id === tab
                          ? "text-indigo-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  )}

                  <span>{dataTab.label}</span>

                  {dataTab.badge ? (
                    <span
                      className={classNames(
                        dataTab.id === tab
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-200 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {dataTab.badge}
                    </span>
                  ) : (
                    <div />
                  )}
                </a>
              ))}
            </nav>
          </div>
        </div>

        {this.props.children &&
          this.props.children(
            tab,
            data.find((item) => {
              return "" + item.id === "" + tab;
            })
          )}
      </div>
    );
  }
}

Tab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,

      badge: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default Tab;
