import React, {Component} from "react";
import {
  FormPhone,
  FormSelect,
  FormInput,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {showSuccessAlert} from "../../../utils/alert-helper";

class TicketReceiptModal extends Component {
  state = {ticket: null};

  open(ticket = null) {
    this.setState({ticket}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async sendTicketReceipt({type, phone, email}) {
    const {ticket} = this.state;

    let receiptPayload = {
      PHONE: phone,
      EMAIL: null,
    };

    if (type === "email") {
      receiptPayload = {
        PHONE: null,
        EMAIL: email,
      };
    }

    await request("tickets/" + ticket.ID + "/receipt", "POST", receiptPayload);

    this.modal.close();

    showSuccessAlert("Receipt Send", "We have sent the receipt!");
  }

  render() {
    return (
      <Modal
        buttonLabel="Send"
        label="Send Ticket Receipt"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.sendTicketReceipt.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            type: "email",
            phone: "",
            email: "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label="Send Method"
                  options={formikOptions}
                  name="type"
                  data={[
                    {label: "Email", value: "email"},
                    {label: "Text", value: "text"},
                  ]}
                />

                {values.type === "email" && (
                  <FormInput
                    label="Email"
                    name="email"
                    placeholder="support@dripos.com"
                    options={formikOptions}
                  />
                )}

                {values.type === "text" && (
                  <FormPhone
                    label="Phone"
                    name="phone"
                    placeholder="999 888 7777"
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TicketReceiptModal;
