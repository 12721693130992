import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import LoadingSpinner from "../../../components/loading-spinner";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";
import ReportTable from "../../../features/reporting/reports/report-table";
import {combineArraysIfExist} from "../../../utils/util";
import ReportingValueCell from "../../../features/reporting/reports/reporting-value-cell";
import ReportingLabelCell from "../../../features/reporting/reports/reporting-label-cell";
import {PAYOUT_ROWS} from "../../../features/reporting/reports/reporting-constants";
import {CSVLink} from "react-csv";
import {DownloadIcon} from "@heroicons/react/solid";

class PayoutPage extends Component {
  state = {payoutData: null};

  async componentDidMount() {
    const {PAYOUT_UNIQUE_ID} = this.props.router.params;

    let payoutData = await request(`payouts/${PAYOUT_UNIQUE_ID}`);

    this.setState({payoutData});
  }

  getColumns() {
    return [
      {
        label: "",
        width: 50,
        sticky: true,
        format: (value, row) => {
          return <ReportingLabelCell row={row} style={row.style} />;
        },
      },
      {
        label: "Total",
        width: 100,
        format: (value, row) => {
          return (
            <ReportingValueCell
              row={row}
              firstIndex={[0]}
              secondIndex={row.selector}
              thirdIndex={row.secondSelector}
              style={combineArraysIfExist(row.style, "")}
              dollarAmount={true}
            />
          );
        },
      },
    ];
  }

  getCsvData(payoutData) {
    const {location} = this.props.shop;
    const {DATE_ARRIVED, ...data} = payoutData;
    const numbers = [data];

    const csvHeaders = [{label: "", key: "LABEL"}];
    const csvData = [{0: location.NAME, LABEL: "Location"}];

    const rows = PAYOUT_ROWS;

    for (let row of rows) {
      let currentRow = {LABEL: row.label};

      for (let entryIndex in numbers) {
        currentRow[entryIndex] = row.dollarAmount
          ? toDollars(numbers[entryIndex][row.selector] || 0, true)
          : numbers[row.selector];
      }

      csvData.push(currentRow);
    }

    for (let entryIndex in numbers) {
      csvHeaders.push({
        label: moment.utc(DATE_ARRIVED).format("MM/DD"),
        key: "" + entryIndex,
      });
    }

    return {csvHeaders, csvData};
  }

  render() {
    let {payoutData} = this.state;
    const {PAYOUT_UNIQUE_ID} = this.props.router.params;

    if (!payoutData) {
      return <LoadingSpinner />;
    }

    let {PAYOUT_TOTAL, DATE_ARRIVED} = payoutData;
    const {csvData, csvHeaders} = this.getCsvData(payoutData);

    let arrivedStr =
      moment.utc(DATE_ARRIVED).valueOf() <= moment().valueOf()
        ? "Arrived"
        : "Arriving";

    return (
      <div>
        <PageHeadings
          label={`${toDollars(PAYOUT_TOTAL, true)} Payout`}
          description={`${arrivedStr} on ${moment
            .utc(DATE_ARRIVED)
            .format("MMM Do YYYY")}`}
          button={{
            type: "gray",
            className: "px-0 pt-0 pb-0 border-hidden",
            label: (
              <CSVLink
                filename={`payout-${moment
                  .utc(DATE_ARRIVED)
                  .format("MM/DD/YY")}.csv`}
                data={csvData}
                headers={csvHeaders}
              >
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <DownloadIcon className="h-5 w-5" />
                </button>
              </CSVLink>
            ),
          }}
        />

        <Card
          label={"Payout Summary"}
          button={{
            label: "View Tickets",
            onClick: () =>
              this.props.router.navigate(`/tickets?payout=${PAYOUT_UNIQUE_ID}`),
          }}
        >
          <ReportTable
            columns={this.getColumns()}
            numbers={[payoutData]}
            constantRows={PAYOUT_ROWS}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PayoutPage));
