import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import FormRow from "../../components/form-row";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ImageFormDropZone from "../../components/image-form-drop-zone";
import {getAssetUrl} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";

class BlockEditor extends Component {
  renderType() {
    const {block} = this.props;

    if (block.TYPE === "ROW") {
      return (
        <FormRow>
          <FormSelect
            flex
            label="Horizontal Alignment"
            data={[
              {label: "Start", value: "FLEX-START"},
              {label: "End", value: "FLEX-END"},
              {label: "Center", value: "CENTER"},
              {label: "Space In Between", value: "SPACE-BETWEEN"},
              {label: "Space Evenly", value: "SPACE-EVENLY"},
            ]}
            value={block.HORIZONTAL_ALIGN}
            onChange={(type) => {
              this.props.updateBlock(block.ID, {HORIZONTAL_ALIGN: type});
            }}
          />

          <FormSelect
            flex
            label="Vertical Alignment"
            data={[
              {label: "Start", value: "FLEX-START"},
              {label: "End", value: "FLEX-END"},
              {label: "Center", value: "CENTER"},
              {label: "Space In Between", value: "SPACE-BETWEEN"},
              {label: "Space Evenly", value: "SPACE-EVENLY"},
            ]}
            value={block.VERTICAL_ALIGN}
            onChange={(type) => {
              this.props.updateBlock(block.ID, {VERTICAL_ALIGN: type});
            }}
          />
        </FormRow>
      );
    }

    if (block.TYPE === "IMAGE") {
      return (
        <FormRow>
          <ImageFormDropZone
            label="Logo"
            onChangeSoft={(file) => {
              this.props.updateBlock(block.ID, {
                IMAGE: {...block.IMAGE, SOURCE: getAssetUrl(file.fileDetails)},
              });
            }}
          />

          <FormInput
            label="Width"
            value={block.IMAGE?.WIDTH}
            onChange={(text) => {
              this.props.updateBlock(block.ID, {
                IMAGE: {...block.IMAGE, WIDTH: text.target.value},
              });
            }}
          />

          <FormInput
            label="Height"
            value={block.IMAGE?.HEIGHT}
            onChange={(text) => {
              this.props.updateBlock(block.ID, {
                IMAGE: {...block.IMAGE, HEIGHT: text.target.value},
              });
            }}
          />
        </FormRow>
      );
    }

    if (block.TYPE === "SELECTIONS") {
      return (
        <>
          <FormSelect
            label="Selection Joining"
            data={[
              {label: "None", value: null},
              {label: "Comma", value: "TEXT"},
              {label: "New Line", value: "NEW_LINE"},
            ]}
            value={block?.JOIN?.TYPE ?? null}
            onChange={(type) => {
              let joinPayload = null;
              if (type === "NEW_LINE") {
                joinPayload = {
                  ID: "block_" + randomString(24),
                  TYPE: "NEW_LINE",
                };
              }

              if (type === "TEXT") {
                joinPayload = {
                  ID: "block_" + randomString(24),
                  TYPE: "TEXT",
                  MARGIN: "0 4 0 0",
                  TEXT: {
                    CONTENT: ", ",
                  },
                };
              }

              this.props.updateBlock(block.ID, {JOIN: joinPayload});
            }}
          />
        </>
      );
    }

    if (block.TYPE === "OPTIONS") {
      return (
        <>
          <FormSelect
            label="Selection Joining"
            data={[
              {label: "None", value: null},
              {label: "Comma", value: "TEXT"},
            ]}
            value={block?.JOIN?.TYPE ?? null}
            onChange={(type) => {
              let joinPayload = null;
              if (type === "NEW_LINE") {
                joinPayload = {
                  ID: "block_" + randomString(24),
                  TYPE: "NEW_LINE",
                };
              }

              if (type === "TEXT") {
                joinPayload = {
                  ID: "block_" + randomString(24),
                  TYPE: "TEXT",
                  MARGIN: "0 4 0 0",
                  TEXT: {
                    CONTENT: ", ",
                  },
                };
              }

              this.props.updateBlock(block.ID, {JOIN: joinPayload});
            }}
          />
        </>
      );
    }

    if (block.TYPE === "TEXT") {
      return (
        <>
          <FormInput
            label="Text"
            value={block.TEXT?.CONTENT}
            onChange={(text) => {
              this.props.updateBlock(block.ID, {
                TEXT: {...block.TEXT, CONTENT: text.target.value},
              });
            }}
          />

          <FormSelect
            label="Text Color"
            data={[
              {label: "Black", value: undefined},
              {label: "Light Gray", value: "#E5E5EC"},
              {label: "Gray", value: "#767676"},
              {label: "Dark Gray", value: "#414445"},
              {label: "White", value: "#FFFFFF"},
            ]}
            value={block.HORIZONTAL_ALIGN}
            onChange={(color) => {
              this.props.updateBlock(block.ID, {
                TEXT: {...block.TEXT, COLOR: color},
              });
            }}
          />

          <FormRow>
            <FormSelect
              flex
              data={[
                {label: "Left", value: "LEFT"},
                {label: "Center", value: "CENTER"},
                {label: "Right", value: "RIGHT"},
              ]}
              label="Text Align"
              value={block.TEXT?.TEXT_ALIGN ?? "LEFT"}
              onChange={(text) => {
                this.props.updateBlock(block.ID, {
                  TEXT: {...block.TEXT, TEXT_ALIGN: text},
                });
              }}
            />

            <FormInput
              flex
              label="Font Size"
              value={block.TEXT?.FONT_SIZE ?? 12}
              onChange={(text) => {
                this.props.updateBlock(block.ID, {
                  TEXT: {...block.TEXT, FONT_SIZE: parseInt(text.target.value)},
                });
              }}
            />

            <FormInput
              flex
              label="Font Weight"
              value={block.TEXT?.FONT_WEIGHT ?? 400}
              onChange={(text) => {
                this.props.updateBlock(block.ID, {
                  TEXT: {...block.TEXT, FONT_WEIGHT: text.target.value},
                });
              }}
            />

            <Button
              className="mt-8"
              label="Add Format"
              onClick={() => {
                const format = block.TEXT.FORMAT || [];

                format.push({
                  KEY: "AMOUNT",
                  TYPE: "DOLLARS",
                  CONTENT: null,
                });

                this.props.updateBlock(block.ID, {
                  TEXT: {...block.TEXT, FORMAT: format},
                });
              }}
            />
          </FormRow>

          {this.renderTextFormat()}
        </>
      );
    }

    return <div/>;
  }

  renderContext(context) {
    return <div>{JSON.stringify(context)}</div>;
  }

  handleDelete() {
    const {ID} = this.props.block;

    if (this.props.updateBlock) {
      this.props.updateBlock(ID, null);
    }
  }

  handleSave() {
    this.props.selectBlock(null);
  }

  renderMargin() {
    const {block} = this.props;

    let spots = ["Margin Top", "Margin Right", "Margin Bottom", "Margin Left"];
    let margins = [0, 0, 0, 0];

    const actualSplit = (block.MARGIN || "0").split(" ");

    if (actualSplit.length === 1) {
      margins = [
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
      ];
    } else if (actualSplit.length === 4) {
      margins = actualSplit;
    }

    return (
      <div>
        <FormRow>
          {spots.map((item, index) => {
            return (
              <FormInput
                flex
                label={item}
                value={margins[index]}
                onChange={(text) => {
                  margins[index] = parseInt(text.target.value) || 0;

                  this.props.updateBlock(block.ID, {
                    MARGIN: margins.join(" "),
                  });
                }}
              />
            );
          })}
        </FormRow>
      </div>
    );
  }

  renderPadding() {
    const {block} = this.props;

    let spots = [
      "Padding Top",
      "Padding Right",
      "Padding Bottom",
      "Padding Left",
    ];
    let margins = [0, 0, 0, 0];

    const actualSplit = (block.PADDING || "0").split(" ");

    if (actualSplit.length === 1) {
      margins = [
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
      ];
    } else if (actualSplit.length === 4) {
      margins = actualSplit;
    }

    return (
      <div>
        <FormRow>
          {spots.map((item, index) => {
            return (
              <FormInput
                flex
                label={item}
                value={margins[index]}
                onChange={(text) => {
                  margins[index] = parseInt(text.target.value) || 0;

                  this.props.updateBlock(block.ID, {
                    PADDING: margins.join(" "),
                  });
                }}
              />
            );
          })}
        </FormRow>
      </div>
    );
  }

  renderBorder() {
    const {block} = this.props;

    let spots = ["Border Top", "Border Right", "Border Bottom", "Border Left"];
    let margins = [0, 0, 0, 0];

    const actualSplit = (block.BORDER || "0").split(" ");

    if (actualSplit.length === 1) {
      margins = [
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
      ];
    } else if (actualSplit.length === 4) {
      margins = actualSplit;
    }

    return (
      <div>
        <FormRow>
          {spots.map((item, index) => {
            return (
              <FormInput
                flex
                label={item}
                value={margins[index]}
                onChange={(text) => {
                  margins[index] = parseInt(text.target.value) || 0;

                  this.props.updateBlock(block.ID, {
                    BORDER: margins.join(" "),
                  });
                }}
              />
            );
          })}
        </FormRow>
      </div>
    );
  }

  renderRadius() {
    const {block} = this.props;

    let spots = ["Border Radius Top Left", "Border Radius Top Right", "Border Radius Bot Right", "Border Radius Bot Left"];
    let radius = [0, 0, 0, 0];

    const actualSplit = (block.RADIUS || "0").split(" ");

    if (actualSplit.length === 1) {
      radius = [
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
        parseInt(actualSplit[0]),
      ];
    } else if (actualSplit.length === 4) {
      radius = actualSplit;
    }

    return (
      <div>
        <FormRow>
          {spots.map((item, index) => {
            return (
              <FormInput
                flex
                label={item}
                value={radius[index]}
                onChange={(text) => {
                  radius[index] = parseInt(text.target.value) || 0;

                  this.props.updateBlock(block.ID, {
                    RADIUS: radius.join(" "),
                  });
                }}
              />
            );
          })}
        </FormRow>
      </div>
    );
  }

  renderConditions() {
    const {block} = this.props;

    if (!block.CONDITIONS || block.CONDITIONS.length === 0) {
      return <div/>;
    }

    return (
      <div>
        Conditions
        {block.CONDITIONS.map((item, index) => {
          return (
            <div>
              <FormRow>
                <FormInput
                  flex
                  label="Key"
                  value={item.KEY}
                  onChange={(e) => {
                    block.CONDITIONS[index].KEY = e.target.value;

                    this.props.updateBlock(block.ID, {
                      CONDITIONS: [...block.CONDITIONS],
                    });
                  }}
                />

                <FormSelect
                  flex
                  label="Operator"
                  value={item.OPERATOR}
                  onChange={(text) => {
                    block.CONDITIONS[index].OPERATOR = text;

                    this.props.updateBlock(block.ID, {
                      CONDITIONS: [...block.CONDITIONS],
                    });
                  }}
                  data={[
                    {label: "Is Empty", value: "NULL"},
                    {label: "Is Set", value: "SET"},
                    {label: "<=", value: "<="},
                    {label: "<", value: "<"},
                    {label: ">=", value: ">="},
                    {label: ">", value: ">"},
                    {label: "=", value: "="},
                    {label: "!=", value: "!="},
                  ]}
                />

                {!["SET", "NULL"].includes(item.OPERATOR) && (
                  <FormInput
                    flex
                    label="Value"
                    value={item.VALUE}
                    onChange={(e) => {
                      block.CONDITIONS[index].VALUE = e.target.value;

                      this.props.updateBlock(block.ID, {
                        CONDITIONS: [...block.CONDITIONS],
                      });
                    }}
                  />
                )}
              </FormRow>

              <Button
                className="mt-8"
                label="Remove Condition"
                onClick={() => {
                  block.CONDITIONS.splice(index, 1);

                  this.props.updateBlock(block.ID, {
                    CONDITIONS: [...block.CONDITIONS],
                  });
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderTextFormat() {
    const {block} = this.props;

    if (!block.TEXT.FORMAT || block.TEXT.length === 0) {
      return <div/>;
    }

    return (
      <div>
        Text Formatting:
        {block.TEXT.FORMAT.map((item, index) => {
          return (
            <div>
              <FormRow>
                <FormInput
                  flex
                  label="Key"
                  value={item.KEY}
                  onChange={(e) => {
                    block.TEXT.FORMAT[index].KEY = e.target.value;

                    console.log(block.TEXT.FORMAT[index]);

                    this.props.updateBlock(block.ID, {
                      TEXT: {
                        ...block.TEXT,
                        FORMAT: [...block.TEXT.FORMAT],
                      },
                    });
                  }}
                />

                <FormSelect
                  flex
                  label="Type"
                  value={item.TYPE}
                  onChange={(text) => {
                    block.TEXT.FORMAT[index].TYPE = text;

                    if (text === "DATE") {
                      block.TEXT.FORMAT[index].CONTENT = "hh:mma MM/DD/YY";
                    }

                    this.props.updateBlock(block.ID, {
                      TEXT: {
                        ...block.TEXT,
                        FORMAT: [...block.TEXT.FORMAT],
                      },
                    });
                  }}
                  data={[
                    {label: "Dollar", value: "DOLLARS"},
                    {label: "Date", value: "DATE"},
                  ]}
                />

                {item.TYPE === "DATE" && (
                  <FormInput
                    flex
                    label="Value"
                    value={item.CONTENT}
                    onChange={(e) => {
                      block.TEXT.FORMAT[index].CONTENT = e.target.value;

                      this.props.updateBlock(block.ID, {
                        TEXT: {
                          ...block.TEXT,
                          FORMAT: [...block.TEXT.FORMAT],
                        },
                      });
                    }}
                  />
                )}

                <Button
                  className="mt-8"
                  label="Remove Condition"
                  onClick={() => {
                    block.CONDITIONS.splice(index, 1);

                    this.props.updateBlock(block.ID, {
                      CONDITIONS: [...block.CONDITIONS],
                    });
                  }}
                />
              </FormRow>
            </div>
          );
        })}
      </div>
    );
  }

  renderHierarchy(block, past = null) {
    const {blocks} = this.props;

    return (
      <span>
        {blocks[block.PARENT] && (
          <>
            {this.renderHierarchy(blocks[block.PARENT])}
            {" -> "}
          </>
        )}

        {block.TYPE}
      </span>
    );
  }

  render() {
    const {parentBlock, block} = this.props;

    if (!block) {
      return <div/>;
    }

    let blockParentIndex = -1;
    if (parentBlock) {
      blockParentIndex = parentBlock?.CHILDREN?.findIndex((item) => {
        return item.ID === block.ID;
      });
    }

    return (
      <div
        style={{
          backgroundColor: "lightblue",
        }}
      >
        <div>
          <div>Navigation:</div>
          <FormRow>
            {block.PARENT && (
              <Button
                flex
                label="Go to Parent"
                onClick={() => this.props.selectBlock(block.PARENT)}
              />
            )}

            {block.CHILDREN?.length > 0 && (
              <Button
                flex
                label="Go to Child"
                onClick={() => this.props.selectBlock(block.CHILDREN[0].ID)}
              />
            )}

            <Button
              flex
              label="Add Child"
              onClick={() => {
                const children = block.CHILDREN || [];

                const childPayload = {
                  PARENT: block.ID,
                  ID: "block_" + randomString(24),
                  TEXT: {CONTENT: "HELLO"},
                  TYPE: "TEXT",
                };

                children.push(childPayload);

                this.props.updateBlock(block.ID, {CHILDREN: children});
                this.props.selectBlock(childPayload.ID);
              }}
            />

            {blockParentIndex !== -1 && (
              <>
                {blockParentIndex !== 0 && (
                  <Button
                    flex
                    label="↑"
                    onClick={() => {
                      this.props.selectBlock(
                        parentBlock.CHILDREN[blockParentIndex - 1].ID
                      );
                    }}
                  />
                )}

                {blockParentIndex !== parentBlock.CHILDREN?.length - 1 && (
                  <Button
                    flex
                    label="↓"
                    onClick={() => {
                      this.props.selectBlock(
                        parentBlock.CHILDREN[blockParentIndex + 1].ID
                      );
                    }}
                  />
                )}
              </>
            )}
          </FormRow>
          <div>Movement:</div>
          <FormRow>
            {blockParentIndex !== -1 && (
              <>
                {blockParentIndex !== 0 && (
                  <Button
                    flex
                    label="Move Block ↑"
                    onClick={() => {
                      const children = parentBlock.CHILDREN;

                      children.splice(blockParentIndex, 1);
                      children.splice(blockParentIndex - 1, 0, block);

                      this.props.updateBlock(parentBlock.ID, {
                        CHILDREN: children,
                      });
                    }}
                  />
                )}

                {blockParentIndex !== parentBlock.CHILDREN?.length - 1 && (
                  <Button
                    flex
                    label="Move Block ↓"
                    onClick={() => {
                      const children = parentBlock.CHILDREN;

                      children.splice(blockParentIndex, 1);
                      children.splice(blockParentIndex + 1, 0, block);

                      this.props.updateBlock(parentBlock.ID, {
                        CHILDREN: children,
                      });
                    }}
                  />
                )}
              </>
            )}
          </FormRow>

          <div>Insertions:</div>
          <FormRow>
            <Button
              flex
              label="Add Child"
              onClick={() => {
                const children = block.CHILDREN || [];

                children.push({
                  PARENT: block.ID,
                  ID: "block_" + randomString(24),
                  TEXT: {CONTENT: "HELLO"},
                  TYPE: "TEXT",
                });

                this.props.updateBlock(block.ID, {CHILDREN: children});
              }}
            />

            {blockParentIndex !== -1 && (
              <>
                <Button
                  flex
                  label="Add Block Before"
                  onClick={() => {
                    const parentChildren = parentBlock.CHILDREN;
                    const newBlock = {
                      PARENT: parentBlock.ID,
                      ID: "block_" + randomString(24),
                      TEXT: {CONTENT: "HELLO"},
                      TYPE: "TEXT",
                    };

                    parentChildren.splice(blockParentIndex, 0, newBlock);

                    this.props.updateBlock(block.PARENT, {
                      CHILDREN: parentChildren,
                    });

                    this.props.selectBlock(newBlock.ID);
                  }}
                />

                <Button
                  flex
                  label="Add Block After"
                  onClick={() => {
                    const parentChildren = parentBlock.CHILDREN;
                    const newBlock = {
                      PARENT: parentBlock.ID,
                      ID: "block_" + randomString(24),
                      TEXT: {CONTENT: "HELLO"},
                      TYPE: "TEXT",
                    };

                    parentChildren.splice(blockParentIndex + 1, 0, newBlock);

                    this.props.updateBlock(block.PARENT, {
                      CHILDREN: parentChildren,
                    });

                    this.props.selectBlock(newBlock.ID);
                  }}
                />
              </>
            )}
          </FormRow>

          <div>Manipulation:</div>
          <FormRow>
            <Button
              flex
              label="Delete Block"
              onClick={() => {
                this.props.updateBlock(block.ID, null);
              }}
            />

            {blockParentIndex !== -1 && (
              <Button
                flex
                label="Duplicate Block"
                onClick={() => {
                  const children = parentBlock.CHILDREN;
                  const blockClone = JSON.parse(JSON.stringify(block));

                  const doBlockWork = (block) => {
                    block.ID = "block" + randomString(24);

                    if (block.CHILDREN) {
                      for (let child of block.CHILDREN) {
                        doBlockWork(child);
                      }
                    }
                  };

                  doBlockWork(blockClone);

                  parentBlock.CHILDREN.splice(
                    blockParentIndex + 1,
                    0,
                    blockClone
                  );

                  this.props.updateBlock(block.PARENT, {
                    CHILDREN: children,
                  });

                  this.props.selectBlock(blockClone.ID);
                }}
              />
            )}

            <Button
              flex
              label="Add Condition"
              onClick={() => {
                const conditions = block.CONDITIONS || [];

                conditions.push({
                  KEY: "KEY",
                  OPERATOR: "=",
                  VALUE: 1,
                });

                this.props.updateBlock(block.ID, {CONDITIONS: conditions});
              }}
            />
          </FormRow>

          {this.renderConditions()}

          <div className="bg-white h-10"/>

          <div className="flex">
            {this.renderHierarchy(block)}

            <div className="flex-1"/>
          </div>

          <div className="bg-white h-10"/>

          <FormRow>
            <FormSelect
              label="Block Type"
              data={[
                {label: "Row", value: "ROW"},
                {label: "Image", value: "IMAGE"},
                {label: "Text", value: "TEXT"},
                {label: "Container", value: "CONTAINER"},
                {label: "Line Items", value: "LINE_ITEMS"},
                {label: "Selections", value: "SELECTIONS"},
                {label: "Options", value: "OPTIONS"},
                {label: "Payments", value: "PAYMENTS"},
              ]}
              value={block.TYPE}
              onChange={(type) => {
                const blockPayload = {TYPE: type};

                if (type === "IMAGE") {
                  blockPayload.IMAGE = {
                    SOURCE: "",
                    WIDTH: 100,
                    HEIGHT: 100,
                  };
                }

                if (type === "ROW") {
                  blockPayload.HORIZONTAL_ALIGN = "SPACE-BETWEEN";
                  blockPayload.VERTICAL_ALIGN = "FLEX-START";

                  if (!blockPayload.CHILDREN) {
                    blockPayload.CHILDREN = [];
                  }

                  if (blockPayload.CHILDREN.length === 0) {
                    const childBlock = {
                      PARENT: block.ID,
                      ID: "block_" + randomString(24),
                      TEXT: {CONTENT: "HELLO"},
                      TYPE: "TEXT",
                    };

                    blockPayload.CHILDREN.push(childBlock);

                    setTimeout(() => {
                      this.props.selectBlock(childBlock.ID);
                    }, 100);
                  }
                }

                if (type === "CONTAINER") {
                  if (!blockPayload.CHILDREN) {
                    blockPayload.CHILDREN = [];
                  }

                  if (blockPayload.CHILDREN.length === 0) {
                    const childBlock = {
                      PARENT: block.ID,
                      ID: "block_" + randomString(24),
                      TEXT: {CONTENT: "HELLO"},
                      TYPE: "TEXT",
                    };

                    blockPayload.CHILDREN.push(childBlock);

                    setTimeout(() => {
                      this.props.selectBlock(childBlock.ID);
                    }, 100);
                  }
                }

                if (type === "LINE_ITEMS") {
                  if (!blockPayload.CHILDREN) {
                    blockPayload.CHILDREN = [];
                  }

                  if (blockPayload.CHILDREN.length === 0) {
                    const parentID = "block_" + randomString(24);
                    const childID = "block_" + randomString(24);

                    const childBlock = {
                      PARENT: block.ID,
                      ID: parentID,
                      TYPE: "CONTAINER",
                      CHILDREN: [
                        {
                          PARENT: parentID,
                          ID: childID,
                          TEXT: {CONTENT: "HELLO"},
                          TYPE: "TEXT",
                        },
                      ],
                    };

                    blockPayload.CHILDREN.push(childBlock);

                    setTimeout(() => {
                      this.props.selectBlock(childID);
                    }, 100);
                  }
                }

                if (type === "SELECTIONS") {
                  blockPayload.JOIN = {TYPE: "NEW_LINE"};

                  if (!blockPayload.CHILDREN) {
                    blockPayload.CHILDREN = [];
                  }

                  if (blockPayload.CHILDREN.length === 0) {
                    const parentID = "block_" + randomString(24);
                    const childID = "block_" + randomString(24);

                    const childBlock = {
                      PARENT: block.ID,
                      ID: parentID,
                      TYPE: "CONTAINER",
                      CHILDREN: [
                        {
                          PARENT: parentID,
                          ID: childID,
                          TEXT: {CONTENT: "HELLO"},
                          TYPE: "TEXT",
                        },
                      ],
                    };

                    blockPayload.CHILDREN.push(childBlock);

                    setTimeout(() => {
                      this.props.selectBlock(childID);
                    }, 100);
                  }
                }

                if (type === "OPTIONS") {
                  if (!blockPayload.CHILDREN) {
                    blockPayload.CHILDREN = [];
                  }

                  if (blockPayload.CHILDREN.length === 0) {
                    const parentID = "block_" + randomString(24);
                    const childID = "block_" + randomString(24);

                    const childBlock = {
                      PARENT: block.ID,
                      ID: parentID,
                      TYPE: "ROW",
                      CHILDREN: [
                        {
                          PARENT: parentID,
                          ID: childID,
                          TEXT: {CONTENT: "HELLO"},
                          TYPE: "TEXT",
                        },
                      ],
                    };

                    blockPayload.CHILDREN.push(childBlock);

                    setTimeout(() => {
                      this.props.selectBlock(childID);
                    }, 100);
                  }
                }

                if (type !== "TEXT") {
                  blockPayload.TEXT = undefined;
                }

                this.props.updateBlock(block.ID, blockPayload);
              }}
              flex
            />

            <FormSelect
              flex
              label="Background Color"
              data={[
                {label: "None", value: undefined},
                {label: "White", value: "#FFFFFF"},
                {label: "Light Gray", value: "#E5E5EC"},
                {label: "Gray", value: "#767676"},
                {label: "Dark Gray", value: "#414445"},
                {label: "Black", value: "#000000"},
              ]}
              value={block.BACKGROUND}
              onChange={(type) => {
                this.props.updateBlock(block.ID, {BACKGROUND: type});
              }}
            />
          </FormRow>

          {this.renderType()}

          <div className="bg-white h-10"/>

          {this.renderMargin()}
          {this.renderPadding()}
          {this.renderBorder()}
          {this.renderRadius()}
        </div>
      </div>
    );
  }
}

BlockEditor.propTypes = {
  block: PropTypes.object.isRequired,
  blocks: PropTypes.array,
};

export default BlockEditor;
