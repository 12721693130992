import React, {Component} from "react";
import {Card, Table, PageHeadings, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import moment from "moment-timezone";
import {capitalize} from "lodash";
import {withRouter} from "../../../utils/navigation";

class PayrollHistoryPage extends Component {
  state = {history: null, isLoading: true};

  async componentDidMount() {
    let res = await request("payroll/history", "GET", null);
    res = res.map((payroll) => {
      let {id, type, period_start, period_end, payday, status} = payroll;

      let amount = "";
      if (status !== "draft") {
        let {liability} = payroll.totals;
        amount = "$" + liability;
      }

      let periodStr =
        moment(period_start).format("MM/DD/YY") +
        " - " +
        moment(period_end).format("MM/DD/YY");

      return {
        id,
        payday: moment(payday).format("MM/DD/YY"),
        period: periodStr,
        type: this.convertTypeToLabel(type),
        status: capitalize(status),
        amount,
      };
    });
    this.setState({history: res, isLoading: false});
  }

  convertTypeToLabel(id) {
    id = id.replaceAll("_", " ");
    return id.replace(/\b./g, function (m) {
      return m.toUpperCase();
    });
  }

  clickRow(payroll){
    let {id} = payroll;
    this.props.router.navigate("/payrolls/" + id);
  }

  render() {
    let {isLoading, history} = this.state;

    if(isLoading){
      return <Loading />
    }

    return (
      <div>
        <PageHeadings label="Payroll History" />

        <Card>
            <Table
              pagination
              data={history.filter(item => item.status !== "Draft")}
              onClick={(row) => {
                if (row.status !== "Draft") {
                  this.clickRow(row);
                }
              }}
              actionButtons={[
                {
                  label: "View",
                  onClick: (row) => this.props.router.navigate(row.id),
                },
              ]}
              columns={[
                {
                  label: "Payday",
                  value: "payday",
                },
                {
                  label: "Type",
                  value: "type",
                },
                {
                  label: "Period",
                  value: "period",
                },
                {
                  label: "Total Amount",
                  value: "amount",
                },
                {
                  label: "Status",
                  value: "status",
                },
              ]}
            />
        </Card>
      </div>
    );
  }
}

export default withRouter(PayrollHistoryPage);
