import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {showErrorNotification} from "../../../../utils/notification-helper";
import {showErrorAlert} from "../../../../utils/alert-helper";

class PayrollBenefitsPage extends Component {
  componentDidMount() {}

  renderBenefitsCard({icon, onClick, title, description}) {
    return (
      <div className="" onClick={() => onClick()}>
        <Card className={"relative p-4 h-48 cursor-pointer hover:bg-gray-100 hover:border-indigo-500"}>
          <FontAwesomeIcon
            icon={icon}
            className="h-8 w-8 text-indigo-500 py-2"
          />

          <div className={"font-semibold text-lg"}>{title}</div>
          <div className="text-sm">{description}</div>

          {/*<div className="absolute bottom-4 text-sm font-semibold text-indigo-500 underline">*/}
          {/*  {"via SimplyInsured"}*/}
          {/*</div>*/}
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div>
        <PageHeadings label={"Benefits"} />

        <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-4 gap-4">
          {this.renderBenefitsCard({
            title: "Healthcare",
            description: "Provide your employees the best medical, vision, and dental plans possible",
            onClick: () => this.props.router.navigate("health"),
            icon: "stethoscope",
          })}

          {this.renderBenefitsCard({
            title: "Workers' Compensation",
            description: "Protect you and your employees to ensure peace of mind in case of workplace injury or illness",
            onClick: () => this.props.router.navigate("workers"),
            icon: "helmet-safety",
          })}

          {/*{this.renderBenefitsCard({*/}
          {/*  title: "Retirement Plans",*/}
          {/*  description: "Administrate benefits outside of Dripos' offerings",*/}
          {/*  onClick: () => showErrorAlert("Coming Soon"),*/}
          {/*  icon: "sack-dollar",*/}
          {/*})}*/}

          {this.renderBenefitsCard({
            title: "Custom",
            description: "Administrate benefits outside of Dripos' offerings",
            onClick: () => this.props.router.navigate("custom"),
            icon: "gears",
          })}
        </div>
      </div>
    );
  }
}
export default withRouter(PayrollBenefitsPage);
