import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  PLATFORM_SALES_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {AUDIT_PLATFORM_SALES_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";

class PlatformSalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      rows = [
        {
          label: "Kiosk",
          numbers: reportData.PLATFORM_SALES_RECORDS.KIOSK,
        },
        {
          label: "Mobile App",
          numbers: reportData.PLATFORM_SALES_RECORDS.MOBILE,
        },
        {
          label: "Point of Sale",
          numbers: reportData.PLATFORM_SALES_RECORDS.POS,
          expandedRows: (row) => {
            return reportData.DEVICE_SALES_RECORDS.map((item) => ({
              label: item.DEVICE_NAME,
              numbers: item,
              style: [CELL_TYPES.SECONDARY],
            }));
          },
        },
        {
          label: "Web",
          numbers: reportData.PLATFORM_SALES_RECORDS.WEB,
        },
        {
          label: "Third",
          numbers: reportData.PLATFORM_SALES_RECORDS.THIRD,
        },
      ];

      if (!reportData.PLATFORM_SALES_RECORDS?.THIRD?.NET_TOTAL) {
        rows = rows.filter((row) => row.label != "Third");
      }

      rows.sort((row1, row2) => {
        return row2.numbers.PAYMENT_COUNT - row1.numbers.PAYMENT_COUNT;
      });

      rows.push({
        label: "Total",
        numbers: reportData.TOTALS,
        style: [CELL_TYPES.BOLD],
      });
    }

    return rows;
  };

  render() {
    let {locationIdArray} = this.props.reporting;

    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    let columns = PLATFORM_SALES_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      columns = AUDIT_PLATFORM_SALES_COLUMNS;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Platform Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="platform-sales"
          endpoint="report/platformsales"
          constantColumns={columns}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          expandable={true}
          isExpandable={(row) =>
            locationIdArray.length === 1 && row.label === "Point of Sale"
          }
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(
  PlatformSalesReportPage
);
