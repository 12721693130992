import React, {Component} from "react";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {
  showConfirmAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import {request} from "../../../utils/request";

class InvoiceRecipientsCard extends Component {
  deleteInvoiceReceipient(recipient) {
    const {updateState, invoice} = this.props;

    showLoadingConfirmAlert(
      "Delete this Recipient",
      "Are you sure you wanted to delete this invoice recipient?"
    ).then(async (close) => {
      const recipients = await request(
        "invoices/" + recipient.ID + "/recipients",
        "DELETE"
      );

      updateState && updateState({...invoice, RECIPIENTS: recipients});
      close();
    });
  }

  render() {
    const {hideEdit, handleEdit, invoice, lines} = this.props;

    const actionButtons =
      invoice.STATUS === "DRAFT"
        ? [
            {
              label: "Delete",
              onClick: (recipient) => this.deleteInvoiceReceipient(recipient),
            },
          ]
        : [];

    return (
      <Card
        label="Additional Recipients"
        button={
          hideEdit
            ? undefined
            : {
                label: "Add Recipients",
                onClick: () => handleEdit(),
              }
        }
      >
        <Table
          data={invoice.RECIPIENTS}
          columns={[
            {
              label: "Email",
              value: "EMAIL",
            },
          ]}
          actionButtons={actionButtons}
        />
      </Card>
    );
  }
}

InvoiceRecipientsCard.propTypes = {
  handleEdit: PropTypes.func.isRequired,
};

export default InvoiceRecipientsCard;
