import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import FormStateSelect from "../../components/form-state-select";
import {
  getContractorOnboardLink,
  getEmployeeOnboardLink,
  remindPayrollEmployeeToOnboard,
} from "../../utils/payroll-helper";
import {CheckIcon} from "@heroicons/react/solid";

class PayrollContractorSettingsModal extends Component {
  state = {contractor: null, emailSent: false};

  open(contractor) {
    this.setState({contractor, emailSent: false});

    this.slide.open();
  }

  render() {
    let {contractor, emailSent} = this.state;

    console.log(contractor)

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Contractor Settings`}
        buttonLabel="Save"
        buttonOnClick={() => this.formikRef.submitForm()}
      >
        <div className="flex flex-row justify-around">
          <button
            type="button"
            className="w-36 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
            onClick={async () => {
              await getContractorOnboardLink(contractor.ONBOARD_CODE).then(
                (res) => {
                  navigator.clipboard.writeText(res)                }
              );
            }}
          >
            <div>Copy Link</div>

            <div className="text-xs text-gray-500">to clipboard</div>
          </button>

          <button
            type="button"
            className="w-36 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
            onClick={async () => {
              await getContractorOnboardLink(contractor.ONBOARD_CODE).then(
                (res) => {
                  window.open(res, "_blank").focus();
                }
              );
            }}
          >
            <div>Open Payroll Link</div>

            <div className="text-xs text-gray-500">as {contractor?.CONTRACTOR.first_name}</div>
          </button>
        </div>
      </Modal>
    );
  }
}

export default PayrollContractorSettingsModal;
