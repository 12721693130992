import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {request} from "../../../../utils/request";
import * as Yup from "yup";
import EmployeeFormDropZone from "../../../../components/employee-form-drop-zone";

class CompanyFileModal extends Component {
  state = {file: null};

  open(file = null) {
    this.setState({file}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    // submit thing to database
    const {filename, fileUpload} = values;
    const {url} = fileUpload;

    await request(`company-documents/file/`, "POST", {
      FILE_NAME: filename,
      URL: url,
    });

    const {syncState} = this.props;

    syncState();
    this.modal.close();
  };

  close() {
    this.modal.close();
  }

  deletePdf(file) {
    const {syncState} = this.props;
    request(`employees/file/${file.URL}`, "DELETE")
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const {file} = this.state;

    return (
      <Modal
        tooltip={{
          data: `${
            file ? "Update the company file" : "Add a new company file"
          }`,
        }}
        buttonLabel={file ? "Replace" : "Save"}
        label={file ? "Replace File" : "Add File"}
        ref={(e) => (this.modal = e)}
        deleteOnClick={() => this.deletePdf(file)}
        formikOnClick={() => this.formikRef}
        deleteLabel={file ? "Delete File" : null}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            fileUpload: file
              ? {
                  filename: file?.FILE_NAME,
                  url: file?.URL,
                }
              : null,
            filename: file?.FILE_NAME,
          }}
          validationSchema={Yup.object().shape({
            fileUpload: Yup.object()
              .shape({
                filename: Yup.string()
                  .nullable()
                  .required("The file name is required."),
                url: Yup.string()
                  .nullable()
                  .required("The file url is required."),
              })
              .nullable()
              .required("Please select a file."),
            filename: Yup.string()
              .nullable()
              .test(
                "length check",
                "File name is required",
                (val) => val?.length > 0
              )
              .when("fileUpload", {
                is: (e) => e,
                then: Yup.string()
                  .nullable()
                  .test({
                    name: "file name validation",
                    message: "File name is invalid.",
                    test: function (value) {
                      return value?.match(
                        /^[a-zA-Z0-9](?:[a-zA-Z0-9 ._-]*[a-zA-Z0-9])?\.[a-zA-Z0-9_-]+$/
                      );
                    },
                  })
                  .test({
                    name: "file extension validation",
                    message: "File extension must match the original file",
                    test: function (value) {
                      const {filename} = this.parent.fileUpload;
                      const filenameSplit = filename?.split(".");
                      const valueSplit = value?.split(".");

                      return (
                        valueSplit &&
                        filenameSplit &&
                        filenameSplit[filenameSplit.length - 1] ===
                          valueSplit[valueSplit.length - 1]
                      );
                    },
                  }),
              }),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {fileUpload} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  label="File Name"
                  name="filename"
                />

                {!fileUpload && (
                  <EmployeeFormDropZone
                    name={"fileUpload"}
                    onChangeSoft={(file) => {
                      setFieldValue("filename", file.name);
                      setFieldValue("fileUpload", file.fileDetails);
                    }}
                    endpoint={"company-documents/"}
                  />
                )}

                {fileUpload && (
                  <div
                    className={
                      "flex-col justify-center items-center text-center"
                    }
                  >
                    <button
                      type="button"
                      className="mt-4 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setFieldValue("fileUpload", null);
                        setFieldValue("filename", "");
                      }}
                    >
                      Clear file
                    </button>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

CompanyFileModal.propTypes = {
  onSave: PropTypes.func,
};

export default CompanyFileModal;
