import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";

class EditTableComponent extends Component {
  render() {
    const {table, updateTable} = this.props;

    return (
      <div className={"flex flex-col"}>
        <FormInput
          value={table.NAME}
          label="Table Name"
          onChange={(val) => {
            table.NAME = val.target.value;
            updateTable(table);
          }}
        />

        <FormInput
          value={table.SEATS}
          label={"Table Seats"}
          onChange={(val) => {
            table.SEATS = val.target.value;
            updateTable(val);
          }}
        />

        <FormSelect
          label={"Table Shape"}
          name="shape"
          value={table.OBJECT.SHAPE}
          data={[
            {
              label: "Triangle",
              value: "TRIANGLE",
            },
            {
              label: "Rectangle",
              value: "SQUARE",
            },
            {
              label: "Circle",
              value: "CIRCLE",
            },
          ]}
          onChange={(val) => {
            console.log(val);
            table.OBJECT.SHAPE = val;
            updateTable(table);
          }}
        />
      </div>
    );
  }
}

export default EditTableComponent;
