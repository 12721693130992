import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import LocationDropdown from "../../features/reporting/reporting-filter/location-dropdown";
import {Formik} from "formik";
import LocationsDropdown from "../../dropdowns/team/locations-dropdown";
import {request} from "../../utils/request";

class DuplicateCategoryModal extends Component {
  state = {category: null};
  loading = false;

  open(category) {
    this.loading = false;

    this.setState({category}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async duplicateCategory({location}) {
    const {category} = this.state;

    if (this.loading) return;

    this.loading = true;
    await request("products/sync/" + location, "POST", {
      CATEGORY_ID: category.ID,
    });

    this.loading = false;

    alert("Done");

    this.modal.close();
  }

  render() {
    return (
      <Modal
        tooltip={{data: "Test"}}
        buttonLabel="Save"
        label="Duplicate Category "
        description="Duplicate category to a seperate location"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.duplicateCategory.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            location: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <LocationsDropdown name="location" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default DuplicateCategoryModal;
