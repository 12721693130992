import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class PolicyDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {allowNull, exclude = [], unique} = this.props;

    const serverPolicies = await request("timeoff/policies", "GET");
    const data = serverPolicies
      .filter((item) => !exclude.includes(item.ID))
      .map((item) => ({
        id: unique ? item.UNIQUE_ID : item.ID,
        label: item.NAME,
      }));

    if (allowNull) {
      data.unshift({label: "All Locations", id: null});
    }

    this.setState({
      data,
    });
  }

  fetchLocation() {
    const {data} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;

    return <ComboBox {...this.props} label="Policy" data={data} />;
  }
}

PolicyDropdown.propTypes = {
  ...ComboBox.propTypes,

  allowNull: PropTypes.bool,
};

export default PolicyDropdown;
