import React, {Component} from "react";
import {
  classNames,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import RawDataTable from "./data/raw-table-data";
import PropTypes from "prop-types";
import dragula from "react-dragula";
import ButtonRow from "../buttons/button-row";
import SimpleServerTableData from "./data/simple-server-table-data";
import ReactTooltip from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "../tooltip/tooltip";
import moment from "moment";
import {MoonLoader} from "react-spinners";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import ReactResizeDetector from "react-resize-detector";

class Table extends Component {
  mobileScrollable = true;
  state = {
    headerHeight: 0,
    isLoading: false,
    dataFromProps: false,
    hasMore: false,
    hasPrevious: false,
    cursor: null,
    count: 0,
    data: [],
    selected: [],

    sort: null,
    sortDesc: true,

    start: null,
    end: null,

    drag: null,

    focus: null,

    errors: {},
    touched: {},

    expanded: [],

    lastProps: [],

    rows: 10,
  };

  constructor(props) {
    super(props);

    this.requestStack = [];

    this.id = randomString(6);
  }

  onTouchMove = (e) => {
    if (!this.mobileScrollable) {
      e.preventDefault();
    }
  };

  componentDidMount() {
    const {data = [], defaultSort, route} = this.props;

    if (defaultSort) {
      this.setState({sort: defaultSort});
    }

    if (this.props.data) {
      this.setState({lastProps: JSON.parse(JSON.stringify(this.props.data))});
    }

    if (route) {
      this.setState({data: null});
    }

    this.resetTableData(data, true);

    document.addEventListener("touchmove", this.onTouchMove, {passive: false});
  }

  componentWillUnmount() {
    document.removeEventListener("touchmove", this.onTouchMove);
  }

  componentDidUpdate(prevProps) {
    const {lastProps, focus} = this.state;
    const {filters, data} = this.props;

    if (prevProps.search !== this.props.search) {
      return this.setState({sort: null, sortDesc: true, cursor: null}, () => {
        this.resetTableData(data);
      });
    }

    if (data && !focus && JSON.stringify(lastProps) !== JSON.stringify(data)) {
      return this.setState(
        {lastProps: JSON.parse(JSON.stringify(data))},
        () => {
          this.resetTableData(data);
        }
      );
    }

    if (
      !focus &&
      (JSON.stringify(prevProps.data) !== JSON.stringify(data) ||
        JSON.stringify(prevProps.filters) !== JSON.stringify(filters))
    ) {
      this.resetTableData(data);
    }
  }

  refresh = async () => {
    const {data} = this.props;

    this.resetTableData(data);
  };

  async resetTableData(data = null, force, ignoreQuery = false, ignoreStack) {
    if (!ignoreStack) {
      this.requestStack.push(() => {
        this.resetTableData(data, force, ignoreQuery, true);
      });
    }

    if (this.requestStack.length > 1) return;

    this.setState({isLoading: true});

    // console.log("WE HERE", this.requestStack);

    let {errors, sort, sortDesc, rows} = this.state;
    const {
      data: rawData,
      filters = [],
      route,
      limit = 10,
      search,
      searchFields,
      columns,
      pagination = false,
    } = this.props;

    const url = new URL(window.location.href);

    let resetValues = false;
    if (Array.isArray(data)) {
      for (let item of data) {
        if (!item.tid || force) {
          resetValues = true;

          item.tid = "tid" + Math.round(Math.random() * 1000000);
        }
      }
    }

    if (!ignoreQuery && url.searchParams.has("sort")) {
      sort = url.searchParams.get("sort");

      this.setState({sort});
    }

    if (!ignoreQuery && url.searchParams.has("sortDesc")) {
      sortDesc = url.searchParams.get("sortDesc") === "true";

      this.setState({sortDesc});
    }

    if (route) {
      this.dataTable = new SimpleServerTableData({
        url: route,
        limit,
        filters,
        sort,
        sortDesc,
        pagination,
        columns,
        search,
        searchFields,
        rows,
      });
    } else {
      if (data === null) {
        data = rawData;
      }

      this.dataTable = new RawDataTable({
        data,
        limit,
        filters,
        sort,
        sortDesc,
        pagination,
        columns,
        search,
        searchFields,
        rows,
      });
    }

    let tableState = null;
    const cursor = !ignoreQuery && url.searchParams.get("cursor");

    try {
      tableState = await this.dataTable.fetchInitial(
        cursor ?? this.state.cursor
      );
    } catch (e) {
      this.requestStack.shift();

      if (this.requestStack.length === 0) {
        this.setState({isLoading: false});
      } else {
        this.requestStack = [this.requestStack.pop()];

        this.requestStack[0]();
      }

      return console.log("Table error");
    }

    if (resetValues) {
      let index = 0;
      for (const column of columns.filter((item) => item.yup)) {
        for (let row of data) {
          const rowName = row.tid + "-" + index;

          try {
            await column.yup.validate(row[column.value]);
          } catch (e) {
            errors[rowName] = e.message;
          }
        }

        index += 1;
      }
    }

    this.setState({...tableState, errors}, () => {
      this.checkError();

      this.requestStack.shift();

      if (this.requestStack.length === 0) {
        this.setState({isLoading: false});
      } else {
        this.requestStack = [this.requestStack.pop()];

        this.requestStack[0]();
      }

      if (this.drag) {
        this.drag.destroy();
      }

      this.drag = dragula([document.getElementById("table_" + this.id)], {
        moves: function (el, container, handle) {
          return handle.classList.contains("table-drag");
        },
      })
        .on("drag", () => {
          this.mobileScrollable = false;
        })
        .on("drop", () => {
          Array.from(
            document.getElementById("table_" + this.id).children
          ).forEach((child, index) => {
            const childData = data.find((item) => {
              return "table_" + this.id + "_row_" + item.tid === child.id;
            });

            if (childData) {
              childData.SEQ = index + 1;
            }
          });

          this.mobileScrollable = true;

          setTimeout(() => {
            this.props.onDrag && this.props.onDrag();
          }, 100);
        });
    });
  }

  getFormikRef() {
    return this.formikRef;
  }

  checkError() {
    const {errors} = this.state;
    const {onErrorChange} = this.props;

    if (onErrorChange) {
      onErrorChange(Object.keys(errors).length > 0);
    }
  }

  async setMaxCursor(max) {
    const {searchParams, route} = this.props;

    if (route) {
      this.setState({isLoading: true, rows: max}, () => this.refresh());
    }

    this.dataTable.limit = max;

    const tableState = await this.dataTable.refetch();

    if (searchParams) {
      const url = new URL(window.location.href);

      url.searchParams.set("cursor", tableState.cursor);

      window.history.pushState({}, null, url);
    }

    this.setState({...tableState, isLoading: false, rows: max});
  }

  async refetch() {
    const {route} = this.props;

    if (route) {
      this.setState({isLoading: true});
    }

    const tableState = await this.dataTable.refetch();

    this.setState({...tableState, isLoading: false});
  }

  async fetchNext() {
    const {searchParams, route} = this.props;

    if (route) {
      this.setState({isLoading: true});
    }

    const tableState = await this.dataTable.fetchMore();

    if (searchParams) {
      const url = new URL(window.location.href);

      url.searchParams.set("cursor", tableState.cursor);

      window.history.pushState({}, null, url);
    }

    this.setState({...tableState, isLoading: false});
  }

  async fetchPrevious() {
    const {searchParams, route} = this.props;

    if (route) {
      this.setState({isLoading: true});
    }

    const tableState = await this.dataTable.fetchPrevious();

    if (searchParams) {
      const url = new URL(window.location.href);

      if (tableState.cursor) {
        url.searchParams.set("cursor", tableState.cursor);
      } else {
        url.searchParams.delete("cursor");
      }

      window.history.pushState({}, null, url);
    }

    this.setState({...tableState, isLoading: false});
  }

  fetchSelectedRows() {
    const {selected, data} = this.state;

    return data.filter((item) => {
      return selected.includes(item.tid);
    });
  }

  selectAll() {
    let {selected, data} = this.state;

    if (selected.length === 0) {
      selected.push(
        ...data.map((item) => {
          return item.tid;
        })
      );
    } else {
      selected = [];
    }

    if (selected.length === data.length || selected.length === 0) {
      this.selectCheckbox.indeterminate = false;
    } else {
      this.selectCheckbox.indeterminate = true;
    }

    this.setState({selected});
  }

  selectRow(row) {
    const {selected, data} = this.state;

    const index = selected.indexOf(row.tid);
    if (index === -1) {
      selected.push(row.tid);
    } else {
      selected.splice(index, 1);
    }

    if (selected.length === data.length || selected.length === 0) {
      this.selectCheckbox.indeterminate = false;
    } else {
      this.selectCheckbox.indeterminate = true;
    }

    this.setState({selected});
  }

  renderButtons() {
    const {buttons = []} = this.props;

    if (buttons.length === 0) {
      return <div />;
    }

    return (
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 md:px-6">
        <div />

        <ButtonRow buttons={buttons} />
      </div>
    );
  }

  renderActionTexts() {
    const {actionTexts = [], actionTextClassName = ""} = this.props;

    if (actionTexts.length === 0) {
      return <div />;
    }

    return (
      <div
        style={{marginBottom: 6}}
        className={classNames(
          actionTextClassName,
          "flex justify-end space-x-2"
        )}
      >
        {actionTexts.map((item) => {
          return (
            <div
              onClick={item.onClick}
              className="text-indigo-700 text-sm font-medium cursor-pointer"
            >
              + {item.label}
            </div>
          );
        })}
      </div>
    );
  }

  renderPagination() {
    const {count, data, hasMore, hasPrevious, start, end, isLoading} =
      this.state;
    const {pagination = false, rowsPerPage} = this.props;

    if (!pagination || data === null || isLoading) {
      return <div />;
    }

    return (
      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 md:px-6"
        aria-label="Pagination"
      >
        {rowsPerPage && (
          <div className="flex flex-1">
            <span className="text-sm text-indigo-600">
              {"Rows Per Page "}
              <span
                className="font-medium text-sm text-indigo-600 cursor-pointer"
                onClick={() => this.setMaxCursor(10)}
              >
                10
              </span>
              <span className="text-sm text-indigo-600">{" | "}</span>
              <span
                className="font-medium text-sm text-indigo-600 cursor-pointer"
                onClick={() => this.setMaxCursor(25)}
              >
                25
              </span>
              <span className="text-sm text-indigo-600">{" | "}</span>
              <span
                className="font-medium text-sm text-indigo-600 cursor-pointer"
                onClick={() => this.setMaxCursor(50)}
              >
                50
              </span>
            </span>
          </div>
        )}

        <div className="flex flex-1">
          <p className="text-sm text-gray-700">
            Showing
            {typeof start !== "undefined" && count > 0 ? (
              <span>
                <span className="font-medium"> {start} </span>
                to
                <span className="font-medium"> {end} </span>
              </span>
            ) : (
              <span className="font-medium"> {data.length} </span>
            )}
            of
            <span className="font-medium"> {count} </span>
            results
          </p>
        </div>

        <div className="flex-1 flex justify-between md:justify-end">
          <a
            onClick={() => hasPrevious && this.fetchPrevious()}
            className={classNames(
              hasPrevious
                ? "text-gray-700 bg-white hover:bg-gray-50"
                : "text-gray-300",
              "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md cursor-pointer"
            )}
          >
            Previous
          </a>

          <a
            onClick={() => hasMore && this.fetchNext()}
            className={classNames(
              hasMore
                ? "text-gray-700 bg-white hover:bg-gray-50"
                : "text-gray-300",
              "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md cursor-pointer"
            )}
          >
            Next
          </a>
        </div>
      </nav>
    );
  }

  render() {
    const {
      data,
      selected,
      focus,
      errors,
      touched,
      expanded,
      sort,
      sortDesc,
      isLoading,
      headerHeight,
    } = this.state;

    const {
      white,
      columns,
      leftPad,
      onClick,
      striped,
      draggable,
      verticalLines,
      actionButtons = [],
      selectButtons = [],
      expandable,
      className,
      sortable,
      selectable,
      searchParams,
      hideBorder = false,
      classNames: rawClassNames = "",
    } = this.props;

    return (
      <div className={classNames(rawClassNames, className, "")}>
        {this.renderActionTexts()}
        {/*{this.renderMobileTable()}*/}

        <div
          className={classNames(
            white && "bg-white shadow sm:rounded-lg",
            "block"
          )}
        >
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div
                style={{maxWidth: "100%"}}
                className="w-full py-2 align-middle inline-block"
              >
                <div
                  className={classNames(
                    !hideBorder && "border-b border-gray-200 md:rounded-lg",
                    "overflow-y-hidden"
                  )}
                >
                  <div className="relative overflow-x-auto">
                    {selectable && selected.length > 0 ? (
                      <div
                        style={{left: 48, height: headerHeight}}
                        className="absolute top-0 flex items-center space-x-3 bg-gray-50 sm:left-16"
                      >
                        {selectButtons.map(({label, onClick}) => {
                          return (
                            <button
                              type="button"
                              onClick={() => onClick(this.fetchSelectedRows())}
                              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                            >
                              {label}
                            </button>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}

                    <table
                      className="min-w-full divide-y divide-gray-200 overflow-hidden"
                      style={{
                        height: 1,
                        width: "100%",
                      }}
                    >
                      <ReactResizeDetector
                        handleHeight
                        onResize={(_, height) => {
                          this.setState({headerHeight: height});
                        }}
                      >
                        <thead className="bg-gray-50">
                          <tr
                            className={classNames(
                              verticalLines && "divide-x divide-gray-200"
                            )}
                          >
                            {selectable && (
                              <th
                                scope="col"
                                className="relative w-12 px-6 sm:w-16 sm:px-8"
                              >
                                <input
                                  type="checkbox"
                                  style={{marginTop: -8}}
                                  className="absolute top-1/2 left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                  checked={selected.length > 0}
                                  ref={(e) => (this.selectCheckbox = e)}
                                  onChange={() => this.selectAll()}
                                />
                              </th>
                            )}

                            {draggable && (
                              <th
                                scope="col"
                                style={{
                                  width: 30,
                                }}
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            )}

                            {expandable && (
                              <th
                                scope="col"
                                style={{
                                  width: 1,
                                }}
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            )}

                            {leftPad && (
                              <th
                                scope="col"
                                style={{
                                  width: 35,
                                }}
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            )}

                            {columns.map((item, index) => {
                              const columnStyle = item.style || {};

                              if (item.width) {
                                columnStyle.width = item.width;
                              }

                              if (item.flex) {
                                columnStyle.flex = 1;
                              }

                              return (
                                <th
                                  scope="col"
                                  align={item.align}
                                  style={columnStyle}
                                  className={classNames(
                                    item.noLeftPad ||
                                      (selectable && index === 0)
                                      ? ""
                                      : "pl-6",
                                    item.mobile ? item.mobile + " hidden" : "",
                                    "pr-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  )}
                                >
                                  <div
                                    className={classNames(
                                      sortable && item.sortable !== false
                                        ? "cursor-pointer"
                                        : "",
                                      "flex flex-row group items-center"
                                    )}
                                    onClick={() => {
                                      if (
                                        !sortable ||
                                        item.sortable === false
                                      ) {
                                        return;
                                      }

                                      if (sort === item.value) {
                                        return this.setState(
                                          {cursor: null, sortDesc: !sortDesc},
                                          () => {
                                            this.resetTableData(
                                              null,
                                              null,
                                              true
                                            );

                                            if (searchParams) {
                                              const url = new URL(
                                                window.location.href
                                              );

                                              url.searchParams.delete("cursor");

                                              url.searchParams.set(
                                                "sortDesc",
                                                "" + !sortDesc
                                              );

                                              window.history.pushState(
                                                {},
                                                null,
                                                url
                                              );
                                            }
                                          }
                                        );
                                      }

                                      this.setState(
                                        {
                                          cursor: null,
                                          sort: item.value,
                                          sortDesc: true,
                                        },
                                        () => {
                                          this.resetTableData(null, null, true);

                                          if (searchParams) {
                                            const url = new URL(
                                              window.location.href
                                            );

                                            url.searchParams.delete("cursor");

                                            url.searchParams.set(
                                              "sort",
                                              item.value
                                            );
                                            url.searchParams.set(
                                              "sortDesc",
                                              "true"
                                            );

                                            window.history.pushState(
                                              {},
                                              null,
                                              url
                                            );
                                          }
                                        }
                                      );
                                    }}
                                  >
                                    {item.label}

                                    {item.tooltip && (
                                      <Tooltip
                                        className="ml-2"
                                        {...(typeof item.tooltip === "string"
                                          ? {data: item.tooltip}
                                          : item.tooltip)}
                                      />
                                    )}

                                    {sortable && item.sortable !== false ? (
                                      sort === item.value ? (
                                        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                          {sortDesc ? (
                                            <ChevronDownIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <ChevronUpIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </span>
                                      ) : (
                                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                          <ChevronDownIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                </th>
                              );
                            })}

                            {actionButtons.length > 0 && (
                              <th
                                scope="col"
                                className={classNames(
                                  actionButtons[0].icon ? "px-4" : "px-6",
                                  "relative py-2 flex-shrink"
                                )}
                                style={{
                                  width: actionButtons[0].icon ? 50 : 1,
                                }}
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            )}
                          </tr>
                        </thead>
                      </ReactResizeDetector>

                      <tbody
                        className="bg-white divide-y divide-gray-200"
                        id={"table_" + this.id}
                      >
                        {data === null || isLoading ? (
                          <td className="align-center" colSpan={1000}>
                            <div className="mt-10 mb-10 items-center flex flex-col">
                              <div className="flex">
                                <MoonLoader
                                  color={"blue"}
                                  loading={true}
                                  size={24}
                                />
                              </div>

                              <h3 className="mt-3 text-sm font-medium text-gray-900">
                                Loading data
                              </h3>

                              <div className="mt-1 text-sm text-gray-500">
                                Give us a moment to fetch your information!
                              </div>
                            </div>
                          </td>
                        ) : (
                          <></>
                        )}

                        {data?.length === 0 && (
                          <td className="align-center" colSpan={1000}>
                            <div className="text-center py-10">
                              <h3 className="text-sm font-medium text-gray-900">
                                No data
                              </h3>

                              <p className="mt-1 text-sm text-gray-500">
                                Get started by creating a new item.
                              </p>
                            </div>
                          </td>
                        )}

                        {!isLoading &&
                          data?.map((row, index) => {
                            const keyRow = row.id || row.ID;

                            const expandIndex = expanded.indexOf(row.tid);
                            const isExpanded = expandIndex !== -1;

                            return (
                              <>
                                <tr
                                  onClick={() => onClick && onClick(row)}
                                  className={classNames(
                                    striped && index % 2 !== 0 && "bg-gray-50",
                                    verticalLines && "divide-x divide-gray-200",
                                    "hover:bg-gray-50 hover:cursor-pointer"
                                  )}
                                  id={"table_" + this.id + "_row_" + row.tid}
                                  key={keyRow}
                                >
                                  {selectable && (
                                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                      {selected.includes(row.tid) && (
                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                      )}

                                      <input
                                        type="checkbox"
                                        style={{marginTop: -8}}
                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                        checked={selected.includes(row.tid)}
                                        onChange={(e) => {
                                          this.selectRow(row);
                                        }}
                                      />
                                    </td>
                                  )}

                                  {draggable && (
                                    <td
                                      style={{cursor: "move"}}
                                      className="h-full table-drag justify-center text-gray-500 text-center"
                                    >
                                      <FontAwesomeIcon
                                        icon="bars"
                                        className="table-drag"
                                      />
                                    </td>
                                  )}

                                  {leftPad && <td />}

                                  {expandable && (
                                    <td
                                      className="h-full cursor-pointer justify-center text-gray-500 text-center"
                                      onClick={() => {
                                        if (isExpanded) {
                                          expanded.splice(expandIndex, 1);
                                        } else {
                                          expanded.push(row.tid);
                                        }

                                        this.setState({expanded});
                                      }}
                                    >
                                      <div style={{width: 35}} className="pl-6">
                                        <FontAwesomeIcon
                                          icon={
                                            isExpanded
                                              ? "chevron-down"
                                              : "chevron-right"
                                          }
                                        />
                                      </div>
                                    </td>
                                  )}

                                  {columns.map((column, columnIndex) => {
                                    const {
                                      editable,
                                      onChange,
                                      yup,
                                      icon,
                                      onClick,
                                      placeholder,
                                    } = column;
                                    const value = row[column.value];
                                    const formikName =
                                      row.tid + "-" + columnIndex;
                                    const error =
                                      touched[formikName] && errors[formikName];

                                    let isSimple = true;
                                    let rowColComp = value;
                                    if (column.format) {
                                      rowColComp = column.format(value, row);
                                    } else if (column.type === "date") {
                                      rowColComp =
                                        moment(value).format("MM/DD/YY");
                                    } else if (column.type === "datetime") {
                                      rowColComp =
                                        moment(value).format("hh:mma MM/DD/YY");
                                    } else if (column.type === "dollars") {
                                      rowColComp = toDollars(value, true);
                                    } else if (column.type === "bool") {
                                      rowColComp =
                                        parseInt(value) === 1 ? "yes" : "no";
                                    }

                                    if (column.className) {
                                      rowColComp = (
                                        <div className={column.className}>
                                          {rowColComp}
                                        </div>
                                      );
                                    }

                                    if (column.type === "custom") {
                                      return (
                                        <td className="h-full">
                                          <div className="h-full">
                                            {column.component(index, row)}
                                          </div>
                                        </td>
                                      );
                                    }

                                    if (column.type === "select") {
                                      const {data} = column;

                                      return (
                                        <td className="h-full">
                                          <div className="h-full">
                                            <select
                                              id={formikName}
                                              defaultValue={value}
                                              name="company-website"
                                              ref={(e) => {
                                                this[
                                                  index + "-" + columnIndex
                                                ] = e;
                                              }}
                                              className={classNames(
                                                focus === formikName
                                                  ? "border-indigo-500"
                                                  : "border-transparent",
                                                "border-2 px-6 h-full flex flex-grow font-small text-sm block w-full text-gray-500 cursor-pointer"
                                              )}
                                              placeholder="www.example.com"
                                              onChange={() => {
                                                this[
                                                  index + "-" + columnIndex
                                                ].blur();
                                              }}
                                              onFocus={() => {
                                                this.setState({
                                                  focus: formikName,
                                                });
                                              }}
                                              onBlur={async (e) => {
                                                if (onChange) {
                                                  onChange(
                                                    index,
                                                    e.target.value,
                                                    row
                                                  );
                                                }

                                                if (yup) {
                                                  yup
                                                    .validate(e.target.value)
                                                    .then(() => {
                                                      delete errors[
                                                        keyRow +
                                                          "-" +
                                                          columnIndex
                                                      ];

                                                      this.setState({errors});
                                                    })
                                                    .catch((err) => {
                                                      errors[
                                                        keyRow +
                                                          "-" +
                                                          columnIndex
                                                      ] = err.message;

                                                      this.setState({errors});
                                                    });
                                                }

                                                this.setState({focus: null});
                                              }}
                                            >
                                              {data.map((item) => {
                                                return (
                                                  <option value={item.value}>
                                                    {item.label}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </div>
                                        </td>
                                      );
                                    }

                                    if (column.editable) {
                                      let borderColor = "border-transparent";

                                      if (focus === formikName) {
                                        borderColor = "border-indigo-500";
                                      } else if (error) {
                                        borderColor = "border-red-300";
                                      }

                                      return (
                                        <td
                                          style={{padding: 0}}
                                          className="h-full"
                                        >
                                          <div
                                            className={classNames(
                                              borderColor,
                                              "h-full border-2"
                                            )}
                                          >
                                            <div className="relative h-full">
                                              {icon && (
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                  <FontAwesomeIcon
                                                    className="h-4 w-4 text-gray-400"
                                                    aria-hidden="true"
                                                    icon={icon}
                                                  />
                                                </div>
                                              )}

                                              <input
                                                type="text"
                                                data-tip={error}
                                                disabled={
                                                  column.onDisable &&
                                                  column.onDisable(row)
                                                }
                                                name={formikName}
                                                autoComplete="off"
                                                defaultValue={value}
                                                data-event-off="mouseleave"
                                                data-event="mouseenter click"
                                                className={classNames(
                                                  icon && "pl-10",
                                                  "border-transparent px-6 h-full border-0 flex flex-grow font-small text-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300"
                                                )}
                                                placeholder={placeholder}
                                                onFocus={() => {
                                                  this.setState({
                                                    focus: formikName,
                                                  });
                                                }}
                                                onChange={(e) => {
                                                  if (onChange) {
                                                    onChange(
                                                      index,
                                                      e.target.value
                                                    );
                                                  }

                                                  if (yup) {
                                                    yup
                                                      .validate(e.target.value)
                                                      .then(() => {
                                                        delete errors[
                                                          formikName
                                                        ];

                                                        this.setState({errors});
                                                      })
                                                      .catch((err) => {
                                                        errors[formikName] =
                                                          err.message;

                                                        this.setState({errors});
                                                      })
                                                      .finally(() => {
                                                        this.checkError();
                                                      });
                                                  }
                                                }}
                                                onBlur={async (e) => {
                                                  column.onBlur &&
                                                    column.onBlur(e, index);

                                                  this.setState({
                                                    touched: {
                                                      ...touched,
                                                      [formikName]: true,
                                                    },
                                                  });

                                                  this.setState({
                                                    focus: null,
                                                  });
                                                }}
                                              />

                                              {error && (
                                                <ReactTooltip
                                                  place="top"
                                                  type="light"
                                                  effect="solid"
                                                  delayHide={250}
                                                  clickable={true}
                                                  border={true}
                                                  borderColor="#d1d5db"
                                                  className="solid-tooltip shadow-sm"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      );
                                    }

                                    let leftPadding = error ? 24 : 2 + 24;
                                    if (selectable && columnIndex === 0) {
                                      leftPadding = 0;
                                    }

                                    if (column.noLeftPad) {
                                      leftPadding = 0;
                                    }

                                    let colorAccent = "";

                                    if (isSimple) {
                                      colorAccent = "text-gray-500 text-sm";
                                    }

                                    if (onClick) {
                                      colorAccent =
                                        "text-sm text-indigo-600 font-medium cursor-pointer";
                                    }

                                    return (
                                      <td
                                        data-tip={error}
                                        data-event="mouseenter click"
                                        data-event-off="dblclick"
                                        style={{
                                          minWidth: column.minWidth,
                                          whiteSpace: !!column.width
                                            ? "nowrap"
                                            : undefined,
                                          paddingTop: error ? 10 : 1 + 12,
                                          paddingLeft: leftPadding,
                                          paddingRight: error ? 20 : 24,
                                          paddingBottom: error ? 10 : 12,
                                          cursor: editable ? "text" : undefined,
                                        }}
                                        onClick={() => {
                                          if (onClick) onClick(row);

                                          if (editable) {
                                            this.setState(
                                              {
                                                focus:
                                                  index + "-" + columnIndex,
                                              },
                                              () => {
                                                setTimeout(() => {
                                                  this[
                                                    index + "-" + columnIndex
                                                  ].focus();
                                                }, 1);
                                              }
                                            );
                                          }
                                        }}
                                        className={classNames(
                                          error && "ring-indigo-500",
                                          "py-2",
                                          column.mobile
                                            ? column.mobile + " hidden"
                                            : "",
                                          editable
                                            ? "cursor-text cursor:text"
                                            : "",
                                          !column.minWidth && "min-w-100",
                                          colorAccent
                                        )}
                                      >
                                        <div style={{wordBreak: "break-word"}}>
                                          {rowColComp}
                                        </div>
                                        {error && (
                                          <ReactTooltip
                                            place="top"
                                            type="dark"
                                            effect="solid"
                                          />
                                        )}
                                      </td>
                                    );
                                  })}

                                  {actionButtons.length > 0 && (
                                    <td className="py-2 px-6 flex flex-row space-x-6 items-center justify-center whitespace-nowrap text-sm font-medium h-full">
                                      {actionButtons.map(
                                        ({label, icon, onClick}) => {
                                          if (icon) {
                                            return (
                                              <a
                                                className="flex justify-center text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                                onClick={(event) =>
                                                  onClick &&
                                                  onClick(row, index, event)
                                                }
                                              >
                                                <FontAwesomeIcon icon={icon} />
                                              </a>
                                            );
                                          }

                                          return (
                                            <a
                                              onClick={(event) =>
                                                onClick &&
                                                onClick(row, index, event)
                                              }
                                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                            >
                                              {label}
                                            </a>
                                          );
                                        }
                                      )}
                                    </td>
                                  )}
                                </tr>

                                {isExpanded && (
                                  <tr>
                                    <td colSpan={1000}>
                                      {expandable(row, index)}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  {this.renderPagination()}
                  {this.renderButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  classNames: PropTypes.string,
  className: PropTypes.string,

  white: PropTypes.bool,
  striped: PropTypes.bool,
  verticalLines: PropTypes.bool,

  selectable: PropTypes.bool,
  expandable: PropTypes.func,

  filters: PropTypes.array,
  sort: PropTypes.string,

  actionTexts: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string, onClick: PropTypes.func})
  ),
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string, onClick: PropTypes.func})
  ),

  selectButtons: PropTypes.array,

  // On click for mobile
  onClick: PropTypes.func,

  pagination: PropTypes.bool,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      format: PropTypes.func,
      editable: PropTypes.bool,
      onChange: PropTypes.func,
    })
  ).isRequired,

  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["primary", "gray"]),
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.string,
    })
  ),

  limit: PropTypes.number,

  onErrorChange: PropTypes.func,
};

export default Table;
