import {setupReduxConnection} from "../../redux";
import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";

class BankAccountModal extends Component {
  open() {
    this.slide.open();
  }

  handleSubmit = async () => {
    await this.formikRef.submitForm();

    await this.props.refresh();

    this.slide.close();
  };

  submitForm = async (values) => {
    await request("banking/bank_account", "POST", values);
  };

  render() {
    let {accountHolderName, bankName, accountNumber, routingNumber} =
      this.props.banking.bankAccount;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label="Your Bank Account"
        buttonLabel="Save"
        buttonOnClick={this.handleSubmit}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            account_type: "company",
          }}
          onSubmit={this.submitForm}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div>
                <div className="text-sm font-semibold text-gray-800">
                  This Must be a Checking Account
                </div>

                <FormInput
                  label="Account Holder Name"
                  name="account_holder_name"
                  options={formikOptions}
                />

                <FormSelect
                  label="Account Type"
                  name="account_type"
                  data={[
                    {label: "Individual", value: "individual"},
                    {label: "Company", value: "company"},
                  ]}
                  options={formikOptions}
                />

                <FormInput
                  label="Bank Name"
                  name="bankName"
                  options={formikOptions}
                />

                <FormInput
                  label="Account Number"
                  name="account_number"
                  placeholder={accountNumber}
                  options={formikOptions}
                />

                <FormInput
                  label="Routing Number"
                  name="routing_number"
                  options={formikOptions}
                />
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["banking"])(BankAccountModal);
