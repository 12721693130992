import React, {Component} from "react";
import {
  FormBoolean,
  Modal,
  FormSelect,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import ContactPaymentModal from "./contact-payment-modal";
import {showSuccessAlert} from "../../../utils/alert-helper";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class PayInvoiceModal extends Component {
  state = {invoice: null, methods: null};

  open(invoice = null) {
    this.setState({invoice, methods: invoice.CONTACT.PAYMENT_METHODS}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async payInvoice({type, receipt, method, tip}) {
    const {invoice} = this.state;

    if (type === "METHOD") {
      if (method === null) {
        this.formikRef.setFieldError(
          "method",
          "Please select or create a payment method"
        );

        return this.modal.fetchModalButton().stopLoading();
      }

      try {
        await request("invoices/" + invoice.ID + "/pay/method", "POST", {
          PAYMENT_METHOD_ID: method,
          AMOUNT_TIP: tip ? decimalToDollars(tip) : 0,
          RECEIPT: receipt,
        });

        setTimeout(
          () =>
            request("invoices/" + invoice.ID, "GET").then((invoice) =>
              this.props.updateState(invoice)
            ),
          2000
        );
      } catch ({error}) {
        if (error?.raw?.message) {
          this.formikRef.setFieldError("method", error.raw.message);
        } else {
          this.formikRef.setFieldError(
            "method",
            "There was an error processing this card. Try again later."
          );
        }

        return this.modal.fetchModalButton().stopLoading();
      }

      this.modal.close();

      return setTimeout(
        () =>
          showSuccessAlert(
            "Payment Successful",
            "Invoice payment went through but it might take a few minutes for invoice status to update.",
            "Continue",
            {
              buttonClick: () => {
                this.props.resyncPage();
              },
            }
          ),
        250
      );
    }

    let serverIngredient = await request(
      "invoices/" + invoice.ID + "/pay",
      "POST",
      {
        RECEIPT: receipt,
        SOURCE: {
          TYPE: type,
          CONTENT: null,
          AMOUNT: invoice.TICKET.PAYMENT_INTENT.TOTAL + (tip ? decimalToDollars(tip) : 0),
          AMOUNT_TIP: tip ? decimalToDollars(tip) : 0,
        },
      }
    );

    this.props.updateState(serverIngredient);
    this.modal.close();
  }

  render() {
    const {methods, invoice} = this.state;

    return (
      <Modal
        buttonLabel="Pay"
        label="Pay Invoice"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <ContactPaymentModal
          addState={(card) =>
            this.setState({methods: [...methods, card]}, () => {
              invoice.CONTACT.PAYMENT_METHODS.push(card);

              this.formikRef.setFieldValue("method", card.id);
            })
          }
          ref={(e) => (this.paymentModal = e)}
        />

        <Formik
          onSubmit={this.payInvoice.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            type: Yup.string().required("Payment type is required"),
          })}
          initialValues={{
            method: methods?.length > 0 ? methods[0].id : null,
            type: "METHOD",
            receipt: "1",
            tip: 0,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  data={[
                    {label: "Contact Payment Method", value: "METHOD"},
                    {label: "Check", value: "CHECK"},
                    {label: "Cash", value: "CASH"},
                  ]}
                  options={formikOptions}
                  label="Payment Type"
                  name="type"
                />
                {values.type === "METHOD" && (
                  <FormSelect
                    data={methods.map((item) => ({
                      label:
                        item.card.brand.capitalize() +
                        " - Ending with " +
                        item.card.last4,
                      value: item.id,
                    }))}
                    buttonHint={{
                      label: "Add Method",
                      onClick: () => this.paymentModal.open(invoice.CONTACT),
                    }}
                    options={formikOptions}
                    label="Payment Type"
                    name="method"
                  />
                )}
                <FormBoolean
                  options={formikOptions}
                  label="Send Receipt"
                  name="receipt"
                />
                {!!invoice.REQUEST_TIP && (
                  <FormInput options={formikOptions} label="Tip" name="tip" />
                )}{" "}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayInvoiceModal;
