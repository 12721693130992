import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {Formik} from "formik";
import {showSuccessAlert} from "../../../utils/alert-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class VoidModal extends Component {
  state = {ticket: null, openCashDrawers: []};

  componentDidMount() {
    request("cashboxManagement/dashboard/active/all", "GET").then(
      (openCashDrawers) => this.setState({openCashDrawers})
    );
  }

  async open(ticket) {
    this.setState({ticket}, () => {
      this.modal.open();
    });
  }

  calculateCashRefunded(sources) {
    if (!sources) return 0;

    return sources.reduce((accum, item) => {
      if (item.TYPE === "CASH") {
        return accum + item.AMOUNT_REFUNDED;
      }

      return accum;
    }, 0);
  }

  calculateCashToRefund(sources) {
    if (!sources) return 0;

    return sources.reduce((accum, item) => {
      if (item.TYPE === "CASH") {
        return accum + item.AMOUNT;
      }

      return accum;
    }, 0);
  }

  async voidTicket(values) {
    let {ticket} = this.state;
    const {drawer} = values;

    await request("tickets/" + ticket.ID + "/void", "POST");

    const items = ticket.ITEMS.filter(
      (item) => item.DATE_REFUNDED === null && item.TYPE !== "TICKET_REFUND"
    ).map((item) => item.ID);

    const {serverTicket, sourcesRefunded} = await request(
      `tickets/${ticket.ID}/refund`,
      "POST",
      {
        SEND_TEXT: true,
        ITEMS: items,
        DRAWER: drawer,
      }
    );

    const cashRefunded = this.calculateCashRefunded(sourcesRefunded);

    await this.props.handleUpdate();
    this.modal.close();

    if (cashRefunded) {
      showSuccessAlert(
        toDollars(cashRefunded, true) + " cash refunded.",
        toDollars(cashRefunded, true) +
          " in cash should be refunded to the customer."
      );
    }
  }

  render() {
    const {openCashDrawers, ticket} = this.state;
    const isCash = this.calculateCashToRefund(ticket?.PAYMENT_INTENT?.PAYMENTS);

    return (
      <Modal
        label={"Void Ticket"}
        buttonLabel={"Void"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          initialValues={{drawer: null}}
          onSubmit={(values) => this.voidTicket(values)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            drawer: Yup.number().nullable(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {openCashDrawers.length > 0 && !!isCash && (
                  <FormSelect
                    name={"drawer"}
                    label={"Drawer to use to Refund Cash Transaction"}
                    hint={"Optional"}
                    data={openCashDrawers.map((drawer) => {
                      return {label: drawer.DEVICE_NAME, value: drawer.ID};
                    })}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default VoidModal;
