import React, {Component} from "react";
import {
  FormTextArea,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";

class GiftCardSettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {promptAttachPatron, giftCardTextAccount, giftCardText, autoGen} =
      values;

    let payload = {
      PROMPT_GIFT_CARD_ATTACH: promptAttachPatron,
    };

    let companyPayload = {
      GIFT_CARD_TEXT_ACCOUNT: giftCardTextAccount,
      GIFT_CARD_TEXT: giftCardText,
      AUTO_GENERATE_PAN: autoGen,
    };

    await Promise.all([
      request("partner/settings/v2", "PATCH", {SETTINGS: payload}),
      request("settings/company", "PATCH", {SETTINGS: companyPayload}),
    ]);

    const {COMPANY_SETTINGS: companySettings, PARTNER_SETTINGS: settings} =
      await request("settings/shop/all", "GET");

    this.props.updateShop({settings, companySettings});
    this.modal.close();
  };

  render() {
    let {settings, companySettings} = this.props.shop;
    const {PROMPT_GIFT_CARD_ATTACH} = settings;

    const {GIFT_CARD_TEXT, GIFT_CARD_TEXT_ACCOUNT, AUTO_GENERATE_PAN} =
      companySettings;

    return (
      <Modal
        label="Gift Card Settings"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        large
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            promptAttachPatron: PROMPT_GIFT_CARD_ATTACH,
            giftCardTextAccount: GIFT_CARD_TEXT_ACCOUNT,
            giftCardText: GIFT_CARD_TEXT,
            autoGen: AUTO_GENERATE_PAN,
          }}
          validationSchema={Yup.object({
            promptAttachPatron: Yup.string().nullable(),
            autoGen: Yup.string(),
            giftCardTextAccount: Yup.string()
              .nullable()
              .test(
                "text-account-length-test",
                "Gift Card Text can be at most 512 characters",
                (val) => val && val.length <= 512
              ),
            giftCardText: Yup.string()
              .nullable()
              .test(
                "text-length-test",
                "Gift Card Text can be at most 512 characters",
                (val) => val && val.length <= 512
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormTextArea
                  name="giftCardText"
                  hint="Company Setting"
                  label="Gift Card Text (Customer Name is Unknown)"
                  options={formikOptions}
                  maxLength={512}
                  tooltip={{
                    data: [
                      {
                        label: "Gift Card Text Overview",
                        data: "The text that a customer will receive when a gift card is created for them.",
                      },
                      {
                        label: "Maximum Length",
                        data: "The maximum character length is 512 characters.",
                      },
                      {
                        label: "Text Tags",
                        data: "The following tabs contain tags that can be used when editing your gift card text.",
                      },
                      {
                        label: "{{NOTES}}",
                        data: "The notes attached to this gift card.",
                      },
                      {
                        label: "{{BALANCE}}",
                        data: "The balance in dollars of the gift card.",
                      },
                      {
                        label: "{{URL}}",
                        data: "The url that can be used to view the balance of this gift card.",
                      },
                      {
                        label: "{{LOCATION_NAME}}",
                        data: "The name of the current location.",
                      },
                      {
                        label: "{{COMPANY_NAME}}",
                        data: "The name of the company attached to this location.",
                      },
                    ],
                  }}
                />

                <FormTextArea
                  name="giftCardTextAccount"
                  label="Gift Card Text (Customer Name is Attached)"
                  hint="Company Setting"
                  options={formikOptions}
                  maxLength={512}
                  tooltip={{
                    data: [
                      {
                        label: "Gift Card Text Overview",
                        data: "The text that a customer will receive when a gift card is created for them.",
                      },
                      {
                        label: "Maximum Length",
                        data: "The maximum character length is 512 characters.",
                      },
                      {
                        label: "Text Tags",
                        data: "The following tabs contain tags that can be used when editing your gift card text.",
                      },
                      {
                        label: "{{NAME}}",
                        data: "The name of the customer.",
                      },
                      {
                        label: "{{NOTES}}",
                        data: "The notes attached to this gift card.",
                      },
                      {
                        label: "{{BALANCE}}",
                        data: "The balance in dollars of the gift card.",
                      },
                      {
                        label: "{{URL}}",
                        data: "The url that can be used to view the balance of this gift card.",
                      },
                      {
                        label: "{{LOCATION_NAME}}",
                        data: "The name of the current location.",
                      },
                      {
                        label: "{{COMPANY_NAME}}",
                        data: "The name of the company attached to this location.",
                      },
                    ],
                  }}
                />

                <FormRow>
                  <FormBoolean
                    name="autoGen"
                    label="Generate Digital Gift Card PAN"
                    options={formikOptions}
                    hint="Company Setting"
                    flex
                  />

                  <FormBoolean
                    name="promptAttachPatron"
                    label="Prompt to Attach Gift Cards"
                    options={formikOptions}
                    tooltip="If enabled, selecting a gift card during checkout while a patron is attached will prompt you to link the gift card to that patron."
                    flex
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  GiftCardSettingsModal
);
