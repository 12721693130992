import React, {Component} from "react";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import {Combobox as ComboComponent} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

const people = [
  {
    id: 1,
    name: "Leslie Alexander",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

class Combobox extends Component {
  state = {value: null, query: ""};

  render() {
    const {value, query} = this.state;

    console.log(ComboComponent);

    const filteredPeople =
      query === ""
        ? people
        : people.filter((person) => {
            return person.name.toLowerCase().includes(query.toLowerCase());
          });

    return (
      <ComboComponent
        as="div"
        value={value}
        ref={(e) => (this.buttonRef = e)}
        onChange={(value) => this.setState({value})}
      >
        <ComboComponent.Label className="block text-sm font-medium text-gray-700">
          Assigned to
        </ComboComponent.Label>
        <div className="relative mt-1">
          <ComboComponent.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => this.setState({query: event.target.value})}
            displayValue={(person) => person.name}
            onFocus={() => console.log(this.buttonRef)}
          />

          <ComboComponent.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboComponent.Button>

          {filteredPeople.length > 0 && (
            <ComboComponent.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.map((person) => (
                <ComboComponent.Option
                  key={person.id}
                  value={person}
                  className={({active}) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({active, selected}) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={person.imageUrl}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />
                        <span
                          className={classNames(
                            "ml-3 truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {person.name}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboComponent.Option>
              ))}
            </ComboComponent.Options>
          )}
        </div>
      </ComboComponent>
    );
  }
}

export default Combobox;
