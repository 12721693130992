import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  CUSTOM_PAYMENT_TYPE_TIPS_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";

class CustomPaymentTipsReport extends Component {
  convertDataToRows(reportData) {
    let toReturn = [];

    if (reportData) {
      for (let custom of reportData) {
        toReturn.push({
          label: custom.NAME,
          numbers: custom,
          style: [CELL_TYPES.SECONDARY],
        });
      }
    }

    return toReturn;
  }

  render() {
    const {REPORTING_SHOW_DATE_CSV} = this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Custom Payment Types Tips Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="custom-tips"
          endpoint="report/custom"
          constantColumns={CUSTOM_PAYMENT_TYPE_TIPS_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(
  CustomPaymentTipsReport
);
