import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import GiftCardsTable from "../../../tables/marketing/gift-cards-table";
import RedeemsTable from "../../../tables/marketing/redeems-table";
import TicketsTable from "../../../tables/sales/tickets-table";
import PatronDetailsModal from "../../../modals/marketing/patron-details-modal";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import GiftCardModal from "../../../modals/marketing/gift-cards/gift-card-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class PatronPage extends Component {
  state = {patron: null, orders: null, cards: null, redeems: null};

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({patron: null}, () => {
        this.syncState();
      });
    }
  }

  syncState() {
    const {ID: id} = this.props.router.params;

    request("patron/" + id, "GET", null).then((patron) => {
      patron.ORDERS.sort(
        (a, b) =>
          (b.DATE_COMPLETED || Date.now()) - (a.DATE_COMPLETED || Date.now())
      );

      this.setState({
        redeems: patron.REDEEMS,
        orders: patron.ORDERS,
        cards: patron.GIFTS,
        totalSpent: toDollars(
          "" +
            patron.ORDERS.reduce((prev, curr) => {
              return prev + parseInt(curr.TOTAL);
            }, 0)
        ),
        patron,
      });
    });
  }

  async archivePatron() {
    const {patron} = this.state;

    await request("patrons/" + patron.ID, "DELETE", {});
    this.props.router.navigate("/patrons");
  }

  render() {
    const {patron, orders, cards, redeems, totalSpent} = this.state;

    if (patron === null) {
      return <Loading />;
    }

    return (
      <div>
        <GiftCardModal
          ref={(e) => (this.giftCardModal = e)}
          patron={patron}
          updateState={() => this.syncState()}
        />

        <PatronDetailsModal
          ref={(e) => (this.discountModal = e)}
          updateState={(e) => this.setState(e)}
        />

        <PageHeadings
          label={
            patron.DATE_ARCHIVED ? (
              <div className={"flex flex-row items-center"}>
                <div>{patron.FULL_NAME}</div>
                <DangerBadge className="ml-2 mt-2 h-1/2">Archived</DangerBadge>
              </div>
            ) : (
              patron.FULL_NAME
            )
          }
          logo={getObjectImage(patron, "LOGO", "appicon.png")}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Archive",
                      onClick: () => {
                        this.archivePatron();
                      },
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Patrons Page", url: "/patrons"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Patron",
              onClick: () => this.discountModal.open(patron),
            },
          ]}
          label="Patron Information"
          description="Information about this Patron"
          data={[
            {label: "Name", value: patron.FULL_NAME},
            {label: "Identifier", value: patron.UNIQUE_ID},
            {
              label: "First Seen",
              value: moment(patron.DATE_CREATED).format("MM/DD/YYYY"),
            },
            {
              label: "Last Seen",
              value:
                patron?.ORDERS.length > 0
                  ? moment(patron.ORDERS[0].DATE_COMPLETED).format("MM/DD/YYYY")
                  : "",
            },
            {label: "Total Spent", value: `$${totalSpent}`},
            {label: "Phone", value: getFormattedPhone(patron.PHONE)},
            {label: "Email", value: patron.EMAIL},
            {label: "Current Points", value: patron.POINTS},
            {label: "Lifetime Points", value: patron.LIFETIME},
            {
              label: "Birthday",
              value:
                patron.BIRTH_DAY && patron.BIRTH_MONTH
                  ? patron.BIRTH_MONTH + "/" + patron.BIRTH_DAY
                  : "-",
            },
          ]}
        />

        <Tab
          data={[
            {id: "ticket", label: "Tickets"},
            {id: "cards", label: "Gift Cards"},
            {id: "redeems", label: "Reward Redemptions"},
          ]}
        >
          {(id) => {
            if (id === "cards") {
              return (
                <Card
                  label="Gift Cards"
                  description="These are all the gift cards the customer has purchased"
                  buttons={[
                    {
                      label: "Create Gift Card",
                      onClick: () => this.giftCardModal.open(),
                    },
                  ]}
                >
                  <GiftCardsTable cards={cards} />
                </Card>
              );
            }

            if (id === "redeems") {
              return (
                <Card
                  label="Redeems"
                  description="This shows when a customer redeemeed a reward"
                >
                  <RedeemsTable redeems={redeems} />
                </Card>
              );
            }

            return (
              <Card
                label="Tickets"
                description="Tickets patron placed at this current location"
              >
                <TicketsTable tickets={orders} />
              </Card>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PatronPage));
