import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import PolicyEmployeeModal from "../../../modals/team/policies/policy-employee-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import TimeOffCard from "../../../features/team/time-off-card";
import moment from "moment";
import {POLICY_TYPE} from "../../../utils/constants";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {toDecimalPlaces} from "../../../utils/decimal-helper";

class PolicyPage extends Component {
  state = {policy: null, employees: null, executions: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const policy = await request("timeoff/policies/" + id, "GET");

    request("timeoff/policies/" + id + "/employees", "GET").then(
      (employees) => {
        this.setState({employees});
      }
    );

    request("timeoff/policies/" + id + "/executions", "GET").then(
      (executions) => {
        this.setState({executions});
      }
    );

    this.setState({policy});
  }

  deletePolicy() {
    const {policy} = this.state;

    showLoadingConfirmAlert(
      "Delete Policy",
      "Are you sure you want to delete this time off policy?"
    ).then(async (close) => {
      await request("timeoff/policies/" + policy.ID, "DELETE", {});

      close();

      this.props.router.navigate("/policies");
      this.modal.close();
    });
  }

  render() {
    const {policy, employees, executions} = this.state;

    if (policy === null) {
      return <Loading />;
    }

    return (
      <div>
        <PolicyEmployeeModal
          policy={policy}
          employees={employees ?? []}
          ref={(e) => (this.employeeModal = e)}
          addState={(employee) => {
            this.setState({employees: [...employees, employee]});
          }}
        />

        <PageHeadings
          label={policy.NAME}
          description={
            POLICY_TYPE[policy.TYPE] + " - " + policy.METHOD.capitalize()
          }
          buttons={[
            {
              label: "Run Policy",
              onClick: () =>
                this.props.router.navigate(
                  "/policy/" + policy.UNIQUE_ID + "/run"
                ),
            },
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Policy",
                      onClick: this.deletePolicy.bind(this),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Team", url: "/team"},
            {label: "Policies Page", url: "/policies"},
          ]}
        />

        <TimeOffCard
          policy={policy}
          onClick={() =>
            this.props.router.navigate("/policy/" + policy.UNIQUE_ID + "/edit")
          }
        />

        <Tab
          data={[
            {label: "Enrolled Employees", id: "employees"},
            {label: "Policy Runs", id: "runs"},
          ]}
        >
          {(tab) => {
            if (tab === "employees") {
              return (
                <Card
                  label="Enrolled Employees"
                  description="A list of all employees enrolled on this policy"
                  button={{
                    label: "Enroll Employee",
                    onClick: () => this.employeeModal.open(),
                  }}
                >
                  <Table
                    data={employees}
                    columns={[
                      {
                        value: "FULL_NAME",
                        label: "Employee Name",
                        format: (value, row) => {
                          return (
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={getObjectImage(
                                    row,
                                    "LOGO",
                                    "appicon.png"
                                  )}
                                  alt=""
                                />
                              </div>

                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {row.FULL_NAME}
                                </div>

                                <div className="text-sm text-gray-500">
                                  {row.ROLES?.map(
                                    (item) => item.ROLE_NAME
                                  ).join(", ")}
                                </div>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        value: "BALANCE",
                        label: "Current Balance",
                        format: (balance) => toDecimalPlaces(balance, 2),
                      },
                      {
                        value: "TOTAL",
                        tooltip:
                          "How many hours has this employee earned in their lifetime",
                        label: "Lifetime Accrued Hours",
                        format: (balance) => toDecimalPlaces(balance, 2),
                      },
                      {
                        value: "DATE_CREATED",
                        label: "Policy Joined",
                        format: (created) => moment(created).format("M/D/YY"),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (tab === "runs") {
              return (
                <Card
                  label="Policy Runs"
                  description="A list of times this policy has been executed"
                >
                  <Table
                    data={executions}
                    columns={[
                      // {
                      //   value: "TOTAL",
                      //   label: "Employees Tracked",
                      // },
                      {
                        value: "HOURS",
                        label: "Hours Tracked",
                        format: (hours) => toDecimalPlaces(hours, 2),
                      },
                      {
                        value: "AMOUNT",
                        label: "Hours Earned",
                        format: (hours) => toDecimalPlaces(hours, 2),
                      },
                      {
                        value: "DATE_CREATED",
                        label: "Date Ran",
                        format: (created) => moment(created).format("M/D/YY"),
                      },
                    ]}
                  />
                </Card>
              );
            }

            return (
              <Table
                className="mt-4"
                data={[]}
                columns={[
                  {
                    value: "NAME",
                    label: "Customer Name",
                  },
                  {
                    value: "DATE_CREATED",
                    label: "Time Earned",
                  },
                ]}
              />
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PolicyPage));
