import {
  Modal,
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormCategorySelect from "../../../../components/form-category-select";
import FormProductSelect from "../../../../components/form-product-select";
import * as Yup from "yup";
import {REDEEM_RULE_TYPES} from "../../../../utils/loyalty-constants";

class RedeemingRuleModal extends Component {
  state = {rule: null};

  open(rule = null) {
    this.setState({rule}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {rule} = this.state;
    const {
      name: NAME,
      pointAmount: POINT_AMOUNT,
      type: TYPE,
      isPercent: IS_PERCENT,
      discountAmount: AMOUNT,
      maxValue: MAX_VALUE,
      categories: CATEGORIES,
      products: PRODUCTS,
    } = values;

    const body = {NAME, TYPE, POINT_AMOUNT, AMOUNT, IS_PERCENT};

    // product discount
    if (TYPE === REDEEM_RULE_TYPES.PRODUCT) {
      body.PRODUCTS = PRODUCTS;
    }

    // Category discount
    if (TYPE === REDEEM_RULE_TYPES.CATEGORY) {
      body.CATEGORIES = CATEGORIES;
    }

    if (IS_PERCENT === 1 && MAX_VALUE) {
      body.MAX_VALUE = decimalToDollars(MAX_VALUE);
    }

    if (IS_PERCENT !== 1) {
      body.AMOUNT = decimalToDollars(AMOUNT);
    }

    if (rule) {
      await request("loyalty/rules/redeem/" + rule.ID, "PATCH", body);
    } else {
      await request("loyalty/rules/redeem", "POST", body);
    }

    this.props.onChange && this.props.onChange();
    this.modal.close();
  };

  handleDelete = async () => {
    const {ID} = this.state.rule;

    await request("loyalty/rules/redeem/" + ID, "DELETE", null);

    this.props.onChange && this.props.onChange();
    this.modal.close();
  };

  render() {
    const {rule} = this.state;
    const {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE
      ? LOYALTY_POINTS_VERBIAGE.charAt(0).toUpperCase() +
        LOYALTY_POINTS_VERBIAGE.slice(1)
      : "Points";

    const schema = Yup.object({
      name: Yup.string().nullable().required("Rule Name is required."),
      type: Yup.string().nullable().required("Earnings Type is required."),
      pointAmount: Yup.number()
        .nullable()
        .required(`${terminology} Amount is required.`)
        .typeError(`${terminology} Amount must be a number.`),
      discountAmount: Yup.number()
        .nullable()
        .required("Discount Amount is required.")
        .typeError("Discount Amount must be a number."),
      isPercent: Yup.number().nullable().required("Discount Type is required."),
      maxValue: Yup.number()
        .nullable()
        .typeError("Max value must be a number."),
      categories: Yup.array().nullable(),
      products: Yup.array().nullable(),
    });

    return (
      <Modal
        label="Redeeming Rule"
        buttonLabel={rule ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        deleteLabel={rule ? "Delete" : null}
        deleteOnClick={this.handleDelete}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: rule?.NAME,
            type: rule?.TYPE,
            discountAmount: rule
              ? rule?.IS_PERCENT === 1
                ? rule.AMOUNT
                : toDollars(rule.AMOUNT)
              : null,
            isPercent: rule?.IS_PERCENT,
            pointAmount: rule?.POINT_AMOUNT,
            maxValue: rule?.MAX_VALUE ? toDollars(rule.MAX_VALUE) : null,
            categories: rule?.CATEGORIES
              ? rule.CATEGORIES.map((cat) => cat.CATEGORY_ID || cat.ID)
              : [],
            products: rule?.PRODUCTS
              ? rule.PRODUCTS.map((prod) => prod.PRODUCT_ID || prod.ID)
              : [],
          }}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Reward Name"
                  name="name"
                  options={formikOptions}
                  placeholder={"50% off any Drink"}
                  tooltip={{
                    data: `This will be displayed to the user to explain how they can redeem their ${terminology}. E.g. "50% off any Drink"`,
                  }}
                />

                <FormInput
                  label={`${terminology} Amount`}
                  name="pointAmount"
                  options={formikOptions}
                  placeholder={`15 ${terminology}`}
                  tooltip={{
                    data: `The amount of ${terminology} to be redeemed for this reward.`,
                  }}
                />

                <FormSelect
                  label="Reward Type"
                  name="type"
                  data={REWARD_TYPES}
                  options={formikOptions}
                  tooltip={{
                    data: REWARD_TYPES,
                  }}
                />

                <FormSelect
                  label="Discount Type"
                  name="isPercent"
                  data={[
                    {label: "Dollar Discount", value: 0},
                    {label: "Percentage Discount", value: 1},
                  ]}
                  options={formikOptions}
                  tooltip={{
                    data: 'Select a percentage discount or a dollar discount. If percentage is selected, the discount will never be greater than the "Maximum Rewards Value"',
                  }}
                />

                <FormInput
                  label="Discount Amount"
                  name="discountAmount"
                  options={formikOptions}
                  placeholder={values.isPercent ? "50%" : "$5.00"}
                  tooltip={{
                    data: "The dollar amount or percentage to discount by.",
                  }}
                />

                {!!values.isPercent && (
                  <FormInput
                    label="Maximum Dollar Value"
                    name="maxValue"
                    hint={"Optional"}
                    options={formikOptions}
                    placeholder={"$100"}
                    tooltip={{
                      data: 'If the discount type is set to "Percentage Discount" you can optionally set a maximum dollar amount for your rule.',
                    }}
                  />
                )}

                {values.type === REDEEM_RULE_TYPES.CATEGORY && (
                  <FormCategorySelect
                    label="Categories"
                    name="categories"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which categories this reward rule should apply to.",
                    }}
                    multi
                  />
                )}

                {values.type === REDEEM_RULE_TYPES.PRODUCT && (
                  <FormProductSelect
                    label="Products"
                    name="products"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which products this reward rule should apply to.",
                    }}
                    multi
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const REWARD_TYPES = [
  {
    label: "Discount Entire Sale",
    value: REDEEM_RULE_TYPES.TICKET,
    data: "With this reward type, the discount amount will be applied to the entire sale.",
  },
  {
    label: "Discount Specific Product",
    value: REDEEM_RULE_TYPES.PRODUCT,
    data: "With this reward type, the discount amount can only be used on a certain products.",
  },
  {
    label: "Discount Specific Category",
    value: REDEEM_RULE_TYPES.CATEGORY,
    data: "With this reward type, the discount amount can only be used on products in certain categories.",
  },
  {
    label: "Discount All Products",
    value: REDEEM_RULE_TYPES.ALL_PRODUCTS,
    data: "With this reward type, the discount amount can only be used on any one product. This is equivalent to selecting every category with a Category Discount.",
  },
];

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  RedeemingRuleModal
);
