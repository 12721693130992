import {
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import FormProductSelect from "../../../components/form-product-select";
import FormCategorySelect from "../../../components/form-category-select";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showErrorAlert} from "../../../utils/alert-helper";

class WeightedQueueModal extends Component {
  state = {weight: null};

  open(weight = null) {
    this.setState({weight}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createWeight({
    type,
    weightType,
    amount,
    quantity,
    weight,
    notes,
    products,
    categories,
  }) {
    const {weights} = this.props;
    const isWeightUsed = weights.some((item) => {
      return item.WEIGHT === parseInt(weight);
    });

    if (isWeightUsed) {
      this.modal.stopLoading();

      return this.formikRef.setFieldError(
        "weight",
        "This weight value has already been used on a different threshold"
      );
    }

    const weightPayload = {
      QUANTITY: 0,
      TYPE: type,
      AMOUNT: amount,
      WEIGHT_TYPE: weightType,
      WEIGHT: weight,
      NOTES: notes,

      TOGGLE: {
        PRODUCTS: [],
        CATEGORIES: [],
      },
    };

    if (type === "DOLLAR") {
      weightPayload.QUANTITY = decimalToDollars(quantity);
    }

    if (type === "PRODUCT") {
      weightPayload.TOGGLE.PRODUCTS = products.map((item) => {
        return {PRODUCT_ID: item};
      });
    }

    if (type === "CATEGORY") {
      weightPayload.TOGGLE.CATEGORIES = categories.map((item) => {
        return {CATEGORY_ID: item};
      });
    }

    const serverWeight = await request(
      "ordering/weights",
      "POST",
      weightPayload
    );

    this.props.addState(serverWeight);
    this.modal.close();
  }

  async saveWeight({
    type,
    weightType,
    amount,
    quantity,
    weight,
    notes,
    products,
    categories,
  }) {
    const {weights} = this.props;
    const {weight: weightObj} = this.state;

    const isWeightUsed = weights.some((item) => {
      return item.WEIGHT === parseInt(weight) && item.ID !== weightObj.ID;
    });

    if (isWeightUsed) {
      this.modal.stopLoading();

      return this.formikRef.setFieldError(
        "weight",
        "This weight value has already been used on a different threshold"
      );
    }

    const weightPayload = {
      TYPE: type,
      AMOUNT: amount,
      WEIGHT_TYPE: weightType,
      QUANTITY: quantity,
      WEIGHT: weight,
      NOTES: notes,

      TOGGLE: {
        PRODUCTS: [],
        CATEGORIES: [],
      },
    };

    if (type === "DOLLAR") {
      weightPayload.QUANTITY = decimalToDollars(quantity);
    }

    if (type === "PRODUCT") {
      weightPayload.TOGGLE.PRODUCTS = products.map((item) => {
        return {PRODUCT_ID: item};
      });
    }

    if (type === "CATEGORY") {
      weightPayload.TOGGLE.CATEGORIES = categories.map((item) => {
        return {CATEGORY_ID: item};
      });
    }

    const serverWeight = await request(
      "ordering/weights/" + weightObj.ID,
      "PATCH",
      weightPayload
    );

    this.props.updateState(serverWeight.ID, serverWeight);
    this.modal.close();
  }

  renderContent(formikOptions) {
    const {values} = formikOptions;

    if (values.type === "DOLLAR") {
      return (
        <FormInput
          name="quantity"
          label="Dollar Amount"
          options={formikOptions}
        />
      );
    }

    if (values.type === "PRODUCT") {
      return (
        <>
          <FormProductSelect
            multi
            name="products"
            label="Products"
            options={formikOptions}
            tooltip={{
              data: "Select which products this threshold should apply to. Select none for any product.",
            }}
          />

          <FormInput
            name="quantity"
            label="Number of Products"
            tooltip="The number of selected products that need to be ordered for the threshold to be hit."
            options={formikOptions}
          />
        </>
      );
    }

    if (values.type === "CATEGORY") {
      return (
        <>
          <FormCategorySelect
            multi
            name="categories"
            label="Categories"
            options={formikOptions}
            tooltip={{
              data: "Select which categories this threshold should apply to. Select none for any category.",
            }}
          />

          <FormInput
            name="quantity"
            label="Number of Products"
            tooltip="The number of products that need to be ordered in the selected categories for the threshold to be hit."
            options={formikOptions}
          />
        </>
      );
    }
  }

  renderWeightRule(formikOptions) {
    const {values} = formikOptions;

    if (values.weightType === "ADD") {
      return (
        <FormInput
          name="amount"
          label="Time Added (Minutes)"
          options={formikOptions}
          tooltip="Amount of time in minutes that will be added to the queue time if this threshold is hit"
        />
      );
    }

    if (values.weightType === "PREVENT") {
      return (
        <FormTextArea
          name="notes"
          label="Prevention Message"
          options={formikOptions}
          tooltip="The message displayed to the patron when preventing them from checking out"
        />
      );
    }
  }

  render() {
    const {weightSeq} = this.props;
    const {weight} = this.state;

    const initialValues = {
      type: weight?.TYPE ?? "DOLLAR",
      amount: weight?.AMOUNT ?? "30",
      quantity: weight?.QUANTITY ?? "50.00",
      weightType: weight?.WEIGHT_TYPE ?? "ADD",
      weight: weight?.WEIGHT ?? weightSeq,
      notes: weight?.NOTES ?? "",
      products: weight?.TOGGLE?.PRODUCTS?.map((item) => item.PRODUCT_ID) ?? [],
      categories:
        weight?.TOGGLE?.CATEGORIES.map((item) => item.CATEGORY_ID) ?? [],
    };

    if (weight?.TYPE === "DOLLAR") {
      initialValues.quantity = toDollars(initialValues.quantity);
    }

    return (
      <Modal
        label="Weighted Queue Time"
        buttonLabel={weight ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            weight ? this.saveWeight.bind(this) : this.createWeight.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          initialValues={initialValues}
          validationSchema={Yup.lazy((values) => {
            const baseValidation = {};

            if (values.type === "DOLLAR") {
              baseValidation.quantity = Yup.number()
                .typeError("Dollar amount must be a number")
                .required("Dollar amount must be set");
            }

            if (values.type === "CATEGORY" || values.type === "PRODUCT") {
              baseValidation.quantity = Yup.number()
                .typeError("Product amount must be a number")
                .required("Product amount must be set");
            }

            if (values.weightType === "PREVENT") {
              baseValidation.notes = Yup.string().required(
                "Prevention message must be set"
              );
            }

            return Yup.object(baseValidation);
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="type"
                  label="Threshold Type"
                  options={formikOptions}
                  onChangeSoft={({value}) => {
                    if (value === "DOLLAR") {
                      setFieldValue("quantity", "50.00");
                    } else {
                      setFieldValue("quantity", "5");
                    }
                  }}
                  data={[
                    {label: "Dollar Amount", value: "DOLLAR"},
                    {label: "Number of Products", value: "PRODUCT"},
                    {label: "Products in Category", value: "CATEGORY"},
                  ]}
                />

                {this.renderContent(formikOptions)}

                <FormSelect
                  name="weightType"
                  label="Weight Rule"
                  options={formikOptions}
                  onChangeSoft={() => {}}
                  data={[
                    {label: "Add Minutes", value: "ADD"},
                    {label: "Prevent Checkout", value: "PREVENT"},
                  ]}
                />

                {this.renderWeightRule(formikOptions)}

                <FormInput
                  name="weight"
                  label="Threshold Weight"
                  options={formikOptions}
                  tooltip="The weight of the threshold determines which threshold is looked at first. Thresholds will be scanned from highest to lowest, and the first threshold to pass will be selected. This means the most extreme cases should be the highest weighted (most expensive dollar amount or most products)."
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

WeightedQueueModal.propTypes = {
  weights: PropTypes.array,
  weightSeq: PropTypes.number,
  updateState: PropTypes.func,
  addState: PropTypes.func,
};

export default setupReduxConnection(["shop"])(WeightedQueueModal);
