import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import ComboBox from "../../components/combobox";
import {setupReduxConnection} from "../../redux";

class SwitchLocationModal extends Component {
  state = {data: []};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const serverLocations = await request("employees/locations", "GET");

    this.setState({data: serverLocations});
  }

  async switchLocation({location}) {
    localStorage.setItem("CURRENT_LOCATION", "" + location);
    localStorage.removeItem("SCHEDULE_LOCATION_ARR");

    window.location.href = "/";
  }

  render() {
    const {location} = this.props.shop;
    const {stayOpen} = this.props;
    const {data} = this.state;

    const companiesDict = data.reduce((dict, item) => {
      if (!dict[item.COMPANY_ID]) {
        dict[item.COMPANY_ID] = item;
      }

      return dict;
    }, {});

    return (
      <Modal
        {...this.props}
        ref={(e) => (this.modal = e)}
        buttonLabel={stayOpen ? "Load" : "Switch"}
        label={stayOpen ? "Select Location" : "Switch Locations"}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          onSubmit={this.switchLocation.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            company: location?.COMPANY_ID,
            location: location?.UNIQUE_ID,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue, values} = formikOptions;
            const {company} = values;

            const companies = Object.values(companiesDict).map((item) => {
              return {
                id: item.COMPANY_ID,
                location: item.UNIQUE_ID,
                label: item.COMPANY_NAME,
              };
            });

            const locations = data
              .filter((item) => {
                return item.COMPANY_ID === parseInt(company);
              })
              .map((item) => {
                return {id: item.UNIQUE_ID, label: item.NAME};
              });

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  label="Company"
                  name="company"
                  strategy="absolute"
                  options={formikOptions}
                  data={companies}
                  onChangeSoft={({location}) =>
                    setFieldValue("location", location)
                  }
                />

                <ComboBox
                  label="Locations"
                  name="location"
                  strategy="absolute"
                  options={formikOptions}
                  data={locations}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(SwitchLocationModal);
