import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import {request} from "../../../utils/request";

class KioskLogosModal extends Component {
  state = {banner: null, logo: null};

  open(logo, banner) {
    this.setState({banner, logo}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {banner, logo} = values;

    let updatedSettings = {
      KIOSK_BANNER: banner,
      KIOSK_LOGO: logo,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: updatedSettings});

    this.props.updateState(updatedSettings);
    this.modal.close();
  };

  render() {
    let {logo, banner} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label={"Kiosk Images"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{banner, logo}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone
                  label="Kiosk Logo"
                  options={formikOptions}
                  name="logo"
                />

                <ImageFormDropZone
                  label="Kiosk Banner"
                  options={formikOptions}
                  name="banner"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default KioskLogosModal;
