import TableData from "./table-data";
import {request} from "../../../utils/request";

class SimpleServerTableData extends TableData {
  url = "NO_URL";
  raw = [];

  constructor({url, limit, filters, sort, sortDesc, search, rows}) {
    super();

    this.url = url;
    this.limit = limit;
    this.filters = filters;
    this.sortDesc = sortDesc;
    this.search = search;
    this.sort = sort;
    this.data = [];
    this.rows = rows ?? 10;
    this.prev = null;
  }

  async fetchInitial(cursor = null) {
    if (!isNaN(cursor)) {
      cursor = parseInt(cursor);
    }

    const body = {
      SEARCH: this.search,
      FILTERS: this.filters,
      CURSOR: cursor === null ? null : {KEY: "BEFORE", VALUE: cursor},
      SORT_DESC: this.sortDesc,
      SORT: this.sort,
      ROWS: this.rows
    };

    const {data, hasMore, hasPrevious, count, start, end} = await request(
      this.url,
      "POST",
      body
    );

    this.prev = body;
    this.data = data;

    return {
      data,
      count,
      hasMore,
      hasPrevious,
      start,
      end,
    };
  }

  async fetchMore() {
    const val = this.data.slice(-1)[0]?.ID;
    const body = {
      SEARCH: this.search,
      FILTERS: this.filters,
      CURSOR: {KEY: "BEFORE", VALUE: val},
      SORT_DESC: this.sortDesc,
      SORT: this.sort,
      ROWS: this.rows,
    };

    const {data, hasMore, hasPrevious, count, start, end} = await request(
      this.url,
      "POST",
      body
    );

    this.prev = body;
    this.data = data;

    return {
      data,
      count,
      hasMore,
      cursor: val,
      hasPrevious,
      start,
      end,
    };
  }

  async fetchPrevious() {
    const body = {
      SEARCH: this.search,
      FILTERS: this.filters,
      CURSOR: {KEY: "AFTER", VALUE: this.data[0]?.ID},
      SORT_DESC: this.sortDesc,
      SORT: this.sort,
      ROWS: this.rows,
    };

    const {data, hasMore, hasPrevious, count, start, end} = await request(
      this.url,
      "POST",
      body,
    );

    this.prev = body;
    this.data = data;

    return {
      data,
      count,
      hasMore,
      cursor: hasPrevious ? this.data.slice(-2)[0]?.ID : null,
      hasPrevious,
      start,
      end,
    };
  }

  async refetch() {
    const {data, hasMore, hasPrevious, count, start, end} = await request(
      this.url,
      "POST",
      this.prev
    );

    this.data = data;

    return {
      data,
      count,
      hasMore,
      cursor: this.prev.CURSOR,
      hasPrevious,
      start,
      end,
    };
  }
}

export default SimpleServerTableData;
