import React, {Component} from "react";
import PropTypes from "prop-types";
import TailComponent from "../tail-component";

class SectionHeading extends Component {
  render() {
    const {label, description} = this.props;

    return (
      <TailComponent {...this.props}>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{label}</h3>

        {description && (
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
        )}
      </TailComponent>
    );
  }
}

SectionHeading.propTypes = {
  ...TailComponent.propTypes,

  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default SectionHeading;
