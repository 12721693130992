import React, {Component} from "react";
import {
  PageHeadings,
  Filter,
  Loading,
  AdvancedFilter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import TimeCardModal from "../../modals/team/time-card-modal";
import TimeCardsTable from "../../features/team/time-cards-table";
import {PlusIcon} from "@heroicons/react/solid";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import BreakModal from "../../modals/team/breaks/break-modal";
import moment from "moment";
import {fetchUnpaidBreaksTotalMinutes} from "../../utils/breaks-helper";
import ReportingDateComponent from "../../features/reporting/reporting-filter/reporting-date-component";

class TimesheetsPage extends Component {
  state = {cards: null, locations: [], breakOptions: null, isLoading: true};

  componentDidMount() {
    this.syncData().then(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const routeTimesheetId = searchParams.get("id");

      if (routeTimesheetId) {
        this.openRouteTimesheet(routeTimesheetId);
      }
    });
  }

  async syncData() {
    let [serverLocations, serverBreakOptions, serverRoles] = await Promise.all([
      request("partner/franchise/locations", "GET"),
      request("team/break-option/company", "GET"),
      request("employeeroles/roles/", "GET"),
    ]);

    this.setState({
      locations: serverLocations,
      breakOptions: serverBreakOptions,
      roles: serverRoles,
      isLoading: false,
    });
  }

  async openRouteTimesheet(timesheetId) {
    const {card: serverCard} = await request(
      "team/time/card/" + timesheetId,
      "GET"
    );

    if (serverCard) {
      this.cardModal.open(serverCard);
    }
  }

  formatRoles() {
    const {roles} = this.state;

    const toReturn = roles.map((_role) => {
      return {value: _role.ID, label: _role.NAME};
    });

    return toReturn;
  }

  render() {
    const {locations, breakOptions, isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Time Cards"
          description="Track the hours worked by your employees"
          button={{
            label: (
              <div className="flex flex-row items-center">
                <PlusIcon className="h-4 w-4 mr-2" />
                Create
              </div>
            ),
            onClick: () => this.cardModal.open(),
          }}
        />

        <TimeCardModal
          ref={(e) => (this.cardModal = e)}
          onChange={() => {
            this.timeCardTable.handleRefresh();
          }}
          breakOptions={breakOptions}
        />

        <AdvancedFilter
          className="mt-2"
          data={[
            {
              label: "Search",
              placeholder: "Search Name or Role",
              type: "search",
              id: "search",
            },
            {
              type: "array",
              data: [
                {label: "Start Date", type: "datetime", id: "start"},
                {label: "End Date", type: "datetime", id: "before"},
              ],
            },
            {
              type: "selectMultiple",
              id: "location",
              label: "Locations",
              data: locations.map((item) => {
                return {value: item.ID, label: item.NAME};
              }),
            },
            {
              id: "role",
              type: "selectMultiple",
              label: "Employee Role",
              data: this.formatRoles(),
            },
          ]}
        >
          {(filters, search) => {
            return (
              <TimeCardsTable
                search={search}
                searchFields={["FULL_NAME", "ROLE"]}
                filters={filters}
                onClick={(row) => this.cardModal.open(row)}
                breakOptions={breakOptions}
                ref={(e) => (this.timeCardTable = e)}
              />
            );
          }}
        </AdvancedFilter>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(TimesheetsPage));
