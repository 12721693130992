import React, {Component, Fragment} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";

class ScheduledTicketsLineItems extends Component {
  render() {
    let {lineItems} = this.props;

    return (
      <div className="px-12 py-12 bg-slate-50">
        <Table
          data={lineItems.map((item) => ({...item}))}
          columns={COLUMN_HEADERS}
        />
      </div>
    );
  }
}

const COLUMN_HEADERS = [
  {
    label: "Product",
    value: "PRODUCT_NAME",
    sortable: true,
  },
  {
    label: "Quantity",
    value: "LINE_ITEM_QUANTITY",
    sortable: true,
  },
  {
    label: "Notes",
    value: "LINE_ITEM_NOTES",
    format: (value) => value ?? "-",
  },
  {
    label: "Category",
    value: "CATEGORY_NAME",
    sortable: true,
  },
];

export default ScheduledTicketsLineItems;
