import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import PromptDetailsModal from "../../../../modals/sales/prompts/prompt-details-modal";

class PromptsPage extends Component {
  state = {
    isLoading: true,
    prompts: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let {PROMPTS: prompts} = await request("prompts/cache", "GET");

    this.setState({isLoading: false, prompts});
  }

  render() {
    const {isLoading, prompts} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Prompts"
          description="View and modify additional prompts that are available at checkout"
          button={{
            label: "Create Prompt",
            onClick: () => this.promptModal.open(),
          }}
        />

        <PromptDetailsModal
          ref={(e) => (this.promptModal = e)}
          addState={({PROMPT}) =>
            this.props.router.navigate("/prompt/" + PROMPT.UNIQUE_ID)
          }
        />

        <Table
          pagination
          data={prompts}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: ({PROMPT}) =>
                this.props.router.navigate("/prompt/" + PROMPT.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "name",
              label: "Full Name",
              format: (_, {PROMPT}) => {
                return PROMPT.FULL_NAME;
              },
            },
            {
              value: "name",
              label: "Short Name",
              format: (_, {PROMPT}) => {
                return PROMPT.SHORT_NAME;
              },
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, row) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(PromptsPage));
