import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";

class FormCheckbox extends Component {
  render() {
    let {options, name} = this.props;

    return (
      <FormElement {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <div className="form-check">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-purple-600 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                checked={value}
                onChange={() => {
                  if (options) {
                    options.setFieldValue(name, !value);
                  } else {
                    onChange(!value);
                  }
                }}
              />
            </div>
          );
        }}
      </FormElement>
    );
  }
}

export default FormCheckbox;
