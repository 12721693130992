import React, {Component, Fragment} from "react";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormElement from "../form/form-element";
import ReactDOM from "react-dom";
import ReactResizeDetector from "react-resize-detector";
import PropTypes from "prop-types";

class FormSelect extends Component {
  state = {offset: null};

  constructor(props) {
    super(props);

    this.id = "fs_" + randomString(24);
  }

  componentDidMount() {
    setTimeout(() => {
      const scrollView = document.querySelector(".modal-scroll-box");

      if (scrollView) {
        scrollView.addEventListener("scroll", this.onScroll);
      }
    }, 1);
  }

  componentWillUnmount() {
    const scrollView = document.querySelector(".modal-scroll-box");

    if (scrollView) {
      scrollView.removeEventListener("scroll", this.onScroll);
    }
  }

  onScroll = (e) => {
    const selectElement = document.querySelector("#" + this.id);

    if (selectElement) {
      this.setState({
        offset: selectElement.getBoundingClientRect().top,
      });
    }
  };

  render() {
    const {offset} = this.state;
    const {
      multi,
      data,
      name,
      options,
      secondaryBlock,
      ignoreInnerMargin,
      placeholder = "Click to select",
    } = this.props;

    return (
      <FormElement id={this.id} {...this.props}>
        {(
          rawValue,
          error,
          onChange,
          onBlur,
          extraStyle,
          onChangeSoft,
          {options, name}
        ) => {
          let buttonLabel = placeholder;
          let value = data.find((item) => "" + item.value === "" + rawValue);

          if (multi) {
            value = rawValue;
          }

          if (multi && Array.isArray(value) && value.length > 0) {
            const valueArr = value.map((val) => {
              const dataValue = data.find((item) => item.value === val);

              return dataValue?.label;
            });

            if (valueArr.length > 1) {
              buttonLabel = `${valueArr[0]}, ${valueArr.length - 1} More`;
            } else {
              buttonLabel = `${valueArr[0]}`;
            }
          } else if (value?.label) {
            buttonLabel = value?.label;
          }

          return (
            <div>
              <Listbox
                value={value}
                onChange={(val) => {
                  onChangeSoft && onChangeSoft(val);

                  if (!multi) {
                    if (options) {
                      return options.setFieldValue(name, val.value);
                    }

                    return onChange && onChange(val.value);
                  }

                  if (!Array.isArray(value)) {
                    return console.log("No clue what this select is");
                  }

                  const valueIndex = value.findIndex((option) => {
                    return option === val.value;
                  });

                  if (valueIndex !== -1) {
                    value.splice(valueIndex, 1);
                  } else {
                    value.push(val.value);
                  }

                  if (options) {
                    options.setFieldValue(name, value);
                  } else {
                    onChange && onChange(value);
                  }
                }}
              >
                {({open}) => (
                  <>
                    <div
                      className={`${!ignoreInnerMargin ? "mt-1 " : ""}relative`}
                    >
                      <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <span className="block truncate">{buttonLabel}</span>

                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <ReactResizeDetector handleWidth handleHeight>
                        {({width, ref}) => {
                          return (
                            <div>
                              {open ? (
                                ReactDOM.createPortal(
                                  <Listbox.Options
                                    style={{width, top: offset}}
                                    className="absolute z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {data.map((option) => (
                                      <Listbox.Option
                                        value={option}
                                        key={option.value}
                                        className={({active}) =>
                                          classNames(
                                            active
                                              ? "text-white bg-indigo-600"
                                              : "text-gray-900",
                                            "cursor-default select-none relative py-2 pl-3 pr-9"
                                          )
                                        }
                                      >
                                        {({selected, active}) => {
                                          if (multi && Array.isArray(value)) {
                                            selected = value.some((val) => {
                                              return val === option.value;
                                            });
                                          } else if (value?.value) {
                                            selected =
                                              value.value === option.value;
                                          }

                                          let labelFont = secondaryBlock
                                            ? "font-semibold"
                                            : "font-normal";

                                          if (selected) {
                                            labelFont = secondaryBlock
                                              ? "font-bold"
                                              : "font-semibold";
                                          }

                                          return (
                                            <>
                                              <div className="flex">
                                                <span
                                                  className={classNames(
                                                    labelFont,
                                                    "block truncate"
                                                  )}
                                                >
                                                  {option.label}
                                                </span>

                                                {!secondaryBlock &&
                                                option.secondary ? (
                                                  <span
                                                    className={classNames(
                                                      active
                                                        ? "text-indigo-200"
                                                        : "text-gray-500",
                                                      "ml-2 truncate"
                                                    )}
                                                  >
                                                    {option.secondary}
                                                  </span>
                                                ) : (
                                                  <div />
                                                )}
                                              </div>

                                              {secondaryBlock &&
                                              option.secondary ? (
                                                <div
                                                  className={classNames(
                                                    active
                                                      ? "text-indigo-200"
                                                      : "text-gray-500"
                                                  )}
                                                >
                                                  {option.secondary}
                                                </div>
                                              ) : (
                                                <div />
                                              )}

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-indigo-600",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          );
                                        }}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>,
                                  document.querySelector("#" + this.id)
                                )
                              ) : (
                                <div />
                              )}
                            </div>
                          );
                        }}
                      </ReactResizeDetector>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          );
        }}
      </FormElement>
    );
  }
}

FormSelect.propTypes = {
  ...FormElement.propTypes,

  onChangeSoft: PropTypes.func,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      secondary: PropTypes.string,
    })
  ).isRequired,

  secondaryBlock: PropTypes.bool,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
};

export default FormSelect;
