import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {request} from "../../utils/request";
import {setStats} from "../../redux/scheduling-stats";

class StatsContainer extends Component {
  componentDidMount() {
    this.calculate();
  }

  async calculate() {
    let projectedTotals = await request("scheduling/projections", "GET");
    this.props.setStats(projectedTotals);
  }

  render() {
    return <div></div>;
  }
}
export default setupReduxConnection(["schedulingStats"])(withRouter(StatsContainer));
