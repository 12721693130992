import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  getPayrollEmployeeOnboardingLink,
  onboardCodeToEmployee,
} from "../../../../utils/payroll-helper";
import LoadingSpinner from "../../../../components/loading-spinner";
import PayrollEmployeeOnboardPage from "./payroll-employee-onboard-page";
import {withRouter} from "../../../../utils/navigation";
import PayrollEmployeeTaxDocumentsPage from "./payroll-employee-tax-documents-page";
import PayrollEmployeePaystubsPage from "./payroll-employee-paystubs-page";
import PayrollEmployeePortalInformationModal from "../../../../modals/payroll/payroll-employee-portal-information-modal";
import moment from "moment";
import PayrollEmployeeInformationPage from "./payroll-employee-information-page";

class PayrollEmployeeLandingPage extends Component {
  state = {isLoading: true, data: null, checkEmployeeId: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let {STARTED} = await onboardCodeToEmployee(ID);

    if (!STARTED) {
      this.props.router.navigate(`/employee-payroll/onboard/${ID}`);
    } else {
      this.setState({isLoading: false, checkEmployeeId: STARTED});
    }
  }

  render() {
    let {isLoading, checkEmployeeId} = this.state;

    let tabs = [
      {id: "info", label: "Personal Information"},
      {id: "paystubs", label: "Paystubs"},
      // {id: "tax", label: "Tax Documents"},
    ];

    if (isLoading) {
      return <div></div>;
    }

    return (
      <div className="p-6">
        <PageHeadings label={`Payroll Portal`} />

        <Tab data={tabs}>
          {(id) => {
            if (id === "info") {
              return (
                <div>
                  <PayrollEmployeeInformationPage />
                </div>
              );
            }

            if (id === "paystubs") {
              return (
                <div>
                  <PayrollEmployeePaystubsPage />
                </div>
              );
            }

            if (id === "tax") {
              return (
                <div>
                  <PayrollEmployeeTaxDocumentsPage checkEmployeeId={checkEmployeeId} />
                </div>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(PayrollEmployeeLandingPage);
