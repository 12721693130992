import React, {Component} from "react";
import Checkbox from "./checkbox";

class CheckboxGroup extends Component {
  state = {selectedArray: []};

  componentDidMount() {
    let {name, defaultSelectedArray, formikOptions} = this.props;

    if (!defaultSelectedArray) {
      return;
    }

    this.setState({selectedArray: defaultSelectedArray});

    if (formikOptions) {
      formikOptions.setFieldValue(name, defaultSelectedArray);
    }
  }

  render() {
    let {data, name, formikOptions, cannotBeEmpty} = this.props;
    let {selectedArray} = this.state;

    return data.map((item) => (
      <Checkbox
        key={item.value}
        name={item.value}
        label={item.name}
        checked={selectedArray.includes(item.value)}
        onChange={(e) => {
          let {selectedArray} = this.state;

          let index = selectedArray.findIndex((currentItem) => {
            return currentItem === item.value;
          });

          if (index > -1) {
            if (cannotBeEmpty && selectedArray.length === 1) {
              return;
            }

            selectedArray.splice(index, 1);
          } else {
            selectedArray.push(item.value);
          }

          this.setState({selectedArray});

          if (formikOptions) {
            formikOptions.setFieldValue(name, selectedArray);
          } else {
            this.props.onChange(selectedArray);
          }
        }}
      />
    ));
  }
}

export default CheckboxGroup;
