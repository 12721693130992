import React, {Component} from "react";
import {request} from "../../../utils/request";
import {showConfirmAlert} from "../../../utils/alert-helper";
import {withRouter} from "../../../utils/navigation";
import {
  PageHeadings,
  Card,
  Table,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BillingModal from "../../../modals/general/billing-modal";
import LoadingSpinner from "../../../components/loading-spinner";

class BillingPage extends Component {
  state = {subscriptions: null, sources: null, invoices: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    request("partner/billing", "GET", null).then((data) => {
      if (!data) {
        this.setState({
          subscriptions: [],
          sources: [],
          invoices: [],
        });

        return;
      }

      const {subscriptions, sources, invoices} = data;
      this.setState({
        subscriptions,
        sources,
        invoices: invoices.data.filter((inv) => inv.status !== "draft"),
      });
    });
  }

  render() {
    let {sources, invoices, subscriptions} = this.state;

    return (
      <div>
        <PageHeadings
          label={"Billing"}
          description={
            "Update the payment method to which your Dripos subscription will be billed"
          }
        />

        <BillingModal
          ref={(e) => (this.billingModal = e)}
          refresh={() => this.syncState()}
        />

        <Tab
          data={[
            {label: "Payment Methods", id: "methods"},
            {label: "History", id: "history"},
            {label: "Subscriptions", id: "plan"},
          ]}
        >
          {(id) => {
            if (id === "methods") {
              const actionButtons = [];

              if (sources && sources.length > 1) {
                actionButtons.push({
                  label: "Remove Card",
                  onClick: (row) => {
                    showConfirmAlert(
                      "Remove Card?",
                      `Are you sure you would like to remove the card ending in ${row.last4} from your account?`
                    )
                      .then(() => {
                        return request("partner/source/" + row.id, "DELETE");
                      })
                      .then(() => {
                        return this.syncState();
                      })
                      .catch(() => {});
                  },
                });
              }

              return (
                <Card
                  label="Payment Methods"
                  description="Your current saved payment methods"
                  button={{
                    label: "Add Payment Method",
                    onClick: () => this.billingModal.open(),
                  }}
                >
                  <Table
                    columns={[
                      {
                        label: "Brand",
                        value: "card",
                        format: (value, _) => {
                          if (!value || !value.brand) {
                            return "-";
                          }

                          return (
                            value.brand[0].toUpperCase() +
                            value.brand.substring(1)
                          );
                        },
                      },
                      {
                        label: "Number",
                        value: "card",
                        format: (value, _) => {
                          if (!value) {
                            return "-";
                          }

                          return "**** **** **** **** " + value.last4;
                        },
                      },
                      {
                        label: "Expires",
                        value: "card",
                        format: (value, _) => {
                          if (!value || !value.exp_month || !value.exp_year) {
                            return "-";
                          }

                          return (
                            "" +
                            value.exp_month +
                            "/" +
                            value.exp_year.toString().substring(2)
                          );
                        },
                      },
                    ]}
                    placeholders={{noDataTitle: "No cards found!"}}
                    actionButtons={actionButtons}
                    data={sources}
                  />
                </Card>
              );
            }

            if (id === "history") {
              return (
                <Card
                  label="Payment History"
                  description="Your previous and upcoming payments"
                >
                  <Table
                    columns={[
                      {
                        label: "Description",
                        value: "charge",
                        format: (value, _) => {
                          return (
                            value?.statement_descriptor ||
                            value?.calculated_statement_descriptor ||
                            "-"
                          );
                        },
                      },
                      {
                        label: "Amount",
                        value: "amount_due",
                        format: (value) => {
                          return toDollars(value, true);
                        },
                      },
                      {
                        label: "Date Paid",
                        value: "status_transitions",
                        format: (value, _) => {
                          if (value?.paid_at) {
                            return moment(value.paid_at * 1000).format(
                              "MM/DD/yyyy"
                            );
                          }
                          return "-";
                        },
                      },
                      {
                        label: "Billing Period",
                        value: "charge",
                        format: (_, row) => {
                          if (row.period_start === row.period_end) {
                            return "One-off";
                          }

                          return (
                            moment(row.period_start * 1000).format("MM/DD") +
                            " - " +
                            moment(row.period_end * 1000).format("MM/DD")
                          );
                        },
                      },
                      {
                        label: "Status",
                        value: "status",
                        format: (value) => {
                          if (value === "paid") {
                            return "Paid";
                          } else if (value === "void") {
                            return "Voided";
                          } else if (value === "open") {
                            return "Open";
                          }

                          return "Unpaid";
                        },
                      },
                    ]}
                    placeholders={{noDataTitle: "No Payments Found!"}}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) => {
                          this.props.router.navigateNewTab(
                            row.hosted_invoice_url
                          );
                        },
                      },
                      {
                        label: <FontAwesomeIcon icon="download" />,
                        onClick: (row) =>
                          this.props.router.navigateNewTab(row.invoice_pdf),
                      },
                    ]}
                    data={invoices}
                  />
                </Card>
              );
            }

            if (id === "plan") {
              return (
                <>
                  <Card
                    label="Subscription Plans"
                    description="Your Dripos subscriptions"
                  >
                    {!!subscriptions && subscriptions.length === 0 && (
                      <div className="px-6 py-5 font-small text-sm">
                        Could not find any Dripos Subscriptions
                      </div>
                    )}
                  </Card>

                  {!subscriptions && (
                    <div className="mt-5">
                      <LoadingSpinner size={15} />
                    </div>
                  )}

                  {!!subscriptions &&
                    subscriptions.map((sub) => {
                      const subName = sub?.plan?.product?.name;
                      const discountAmount =
                        sub?.discount?.coupon?.amount_off ?? 0;

                      let term = sub.plan.interval;
                      if (term === "month") {
                        term = "Monthly";
                      } else if (term === "year") {
                        term = "Annually";
                      } else if (term === "day") {
                        term = "Daily";
                      } else if (term === "week") {
                        term = "Weekly";
                      }

                      return (
                        <TwoColumnList
                          label={subName}
                          data={[
                            {
                              label: "Amount",
                              value: (
                                <div>
                                  {discountAmount > 0 && (
                                    <>
                                      <span className="line-through">
                                        {toDollars(sub.plan.amount, true)}
                                      </span>{" "}
                                    </>
                                  )}
                                  <span>
                                    {toDollars(
                                      sub.plan.amount - discountAmount,
                                      true
                                    )}
                                  </span>
                                </div>
                              ),
                            },
                            {
                              label: "Quantity",
                              value: sub.quantity,
                            },
                            {
                              label: "Term",
                              value: term,
                            },
                            {
                              label: "Status",
                              value: sub.status,
                            },
                          ]}
                        />
                      );
                    })}
                </>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(BillingPage);
