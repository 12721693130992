import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";

class RemindInvoiceModal extends Component {
  state = {invoice: null};

  open(invoice = null) {
    this.setState({invoice}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async sendInvoice(button, disableButton) {
    const {invoice} = this.state;

    button && button.startLoading();
    disableButton && disableButton.disable();

    const serverInvoice = await request(
      "invoices/" + invoice.ID + "/reminder",
      "POST",
      {}
    );

    this.modal && this.modal.close();
  }

  render() {
    const {invoice} = this.state;

    const total = invoice?.TICKET?.ITEMS?.reduce(
      (accum, item) => accum + item.TOTAL,
      0
    );

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modal.close()}
        deleteType="gray"
        deleteLabel="Cancel"
        buttons={[
          {
            type: "gray",
            label: "Save for Later",
            ref: (e) => (this.draftButton = e),
            onClick: () => this.modal.close(),
          },
          {
            label: "Send Now",
            ref: (e) => (this.sendButton = e),
            onClick: () => this.sendInvoice(this.sendButton, this.draftButton),
          },
        ]}
        label="Send Invoice"
        ref={(e) => (this.modal = e)}
      >
        <div className="font-medium">
          Send invoice reminder to{" "}
          <span className="font-bold">
            {invoice?.CONTACT?.FIRST_NAME} {invoice?.CONTACT?.LAST_NAME}
          </span>
        </div>

        {invoice?.RECIPIENTS?.length > 0 ? (
          <div className="mt-4 text-sm text-gray-700">
            The reminder will also be sent to{" "}
            <span className="font-bold">
              {invoice?.RECIPIENTS.map((item) => item.EMAIL).join(", ")}
            </span>
            .
          </div>
        ) : (
          <div className="mt-4 text-sm text-gray-700">
            Are you sure you want to send this invoice reminder.
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(RemindInvoiceModal);
