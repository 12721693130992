import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ComboBox from "../../../components/combobox";
import {request} from "../../../utils/request";

class SelectProductDiscountModal extends Component {
  state = {productDiscounts: []};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.syncState().then(() => {
      this.modal.open();
    });
  }

  async syncState() {
    let productDiscounts = await request("partner/discounts/product", "GET");

    this.setState({productDiscounts});
  }

  render() {
    const {exclude = [], remove = false} = this.props;
    const {productDiscounts} = this.state;
    const data = productDiscounts.filter((item) => {
      return !exclude.includes(item.ID);
    });

    return (
      <Modal
        buttonLabel={remove ? "Remove" : "Add"}
        label={"Select Product Discount"}
        description={`Select a product discount to ${
          remove ? "remove from" : "add to"
        } this product`}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{discount: null}}
          onSubmit={(values) => {
            this.props.updateState(values, () => this.modal.close());
          }}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  {...this.props}
                  placeholder="Select a product discount"
                  options={formikOptions}
                  label="Product Discount"
                  data={data.map((item) => {
                    return {id: `${item.ID}`, label: item.NAME};
                  })}
                  name="discount"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SelectProductDiscountModal;
