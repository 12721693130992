import React, {Component} from "react";
import ChoosePaydayComponent from "../choose-payday-component";
import {request} from "../../../../utils/request";
import {showErrorAlert} from "../../../../utils/alert-helper";

class LateChoosePaydayComponent extends Component {
  state = {loading: false, payday: null};

  async handleClick() {
    let {payday} = this.state;

    try {
      await request("payroll/late-options/set", "POST", {DATE: payday});
      return true;
    } catch (e) {
      showErrorAlert(
        "Invalid Payday",
        "Please select a valid payday.",
        "Ok"
      );

      return false;
    }
  }

  render() {
    let {loading} = this.state;

    return (
      <ChoosePaydayComponent
        loading={loading}
        onPaydayChange={(newPayday) => {
          this.setState({payday: newPayday});
        }}
        chooseWorkPeriod={false}
      />
    );
  }
}

export default LateChoosePaydayComponent;
