import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {
  Filter,
  Loading,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import ProductsTable from "../../../../tables/sales/products-table";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class ProductsPage extends Component {
  state = {isLoading: true, products: [], categories: []};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const categories = await request("categories", "GET");
    const products = await request("products", "GET");

    this.setState({isLoading: false, products, categories});
  }

  componentDidUpdate(prevProps) {
    if (this.props.menu.products.length !== prevProps.menu.products.length) {
      this.tableRef.resetTableData(this.props.menu.products);
    }
  }

  fetchDownloadData() {
    const {products} = this.state;

    return products.map((item) => {
      const pricingModifier = item?.CUSTOMIZATIONS?.find(
        (item) => item.TYPE === 5 && item.OPTIONS.length > 0
      );

      const price = pricingModifier
        ? toDollars(
            Math.min(
              ...pricingModifier?.OPTIONS.map((modifier) => modifier.PRICE)
            ),
            true
          )
        : toDollars(item.PRICE, true);

      return {
        ...item,
        FORMATTED_INVENTORY: item.INVENTORY ?? "-",
        IS_SIZED: !!pricingModifier,
        FORMATTED_PRICE: price,
      };
    });
  }

  render() {
    const {isLoading, products, categories} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Products"
          description="Create and view products that will display on your point of sale and sales systems"
          button={{
            label: "Create Product",
            onClick: () => this.props.router.navigate("/product"),
          }}
        />

        <Filter
          csv={{
            file: "products",
            data: this.fetchDownloadData(),
            headers: [
              {label: "Name", key: "NAME"},
              {label: "Price", key: "FORMATTED_PRICE"},
              {label: "Is Sized", key: "IS_SIZED", type: "bool"},
              {label: "Category", key: "CATEGORY_NAME"},
              {label: "Current Stock", key: "FORMATTED_INVENTORY"},
              {label: "Created", key: "DATE_CREATED", type: "datetime"},
              {label: "Updated", key: "DATE_UPDATED", type: "datetime"},
            ],
          }}
          searchable
          searchParams
          className="mt-4"
          data={[
            {
              id: "category",
              label: "Categories",
              onFilter: (options, data) => {
                if (options.length === 0) {
                  return data;
                }

                return data.filter((item) => {
                  return options.includes(item.CATEGORY_ID);
                });
              },
              options: categories.map((item) => ({
                id: item.ID,
                label: item.NAME,
                value: item.ID,
              })),
            },
            {
              id: "status",
              label: "Product Status",
              onFilter: (options, data) => {
                if (options.length === 0) {
                  return data;
                }

                if (options.includes("1")) {
                  data = data.filter((item) => {
                    return item.ENABLED;
                  });
                }

                if (options.includes("2")) {
                  data = data.filter((item) => {
                    return item.AUTO_COMPLETE || item.CATEGORY_AUTO_COMPLETE;
                  });
                }

                if (options.includes("3")) {
                  data = data.filter((item) => {
                    return item.POS_ENABLED;
                  });
                }

                if (options.includes("4")) {
                  data = data.filter((item) => {
                    return item.KIOSK_ENABLED;
                  });
                }

                if (options.includes("5")) {
                  data = data.filter((item) => {
                    return item.THIRD_PARTY_ENABLED;
                  });
                }

                return data;
              },
              options: [
                {label: "Point of Sale Enabled", id: "3"},
                {label: "Mobile Enabled", id: "1"},
                {label: "Kiosk Enabled", id: "4"},
                {label: "Third Party Enabled", id: "5"},
                {label: "Auto Completing", id: "2"},
              ],
            },
          ]}
        >
          {(filters, search) => {
            return (
              <ProductsTable
                sortable
                searchFields={["NAME"]}
                searchParams
                search={search}
                data={products}
                filters={filters}
                defaultSort="DATE_CREATED"
                updateProduct={(serverProduct) => {
                  const productIndex = products.findIndex((item) => {
                    return item.ID === serverProduct.ID;
                  });

                  if (productIndex !== -1) {
                    products.splice(productIndex, 1, {
                      ...products[productIndex],
                      ...serverProduct,
                    });
                  }

                  this.setState({products});
                }}
                updatePricing={() => this.syncData()}
                updateInventory={({product, inventory}) => {
                  const productIndex = products.findIndex((item) => {
                    return item.ID === product.ID;
                  });

                  if (productIndex !== -1) {
                    products.splice(productIndex, 1, {
                      ...products[productIndex],
                      INVENTORY: inventory.QUANTITY,
                    });
                  }

                  this.setState({products});
                }}
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ProductsPage));
