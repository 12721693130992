import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {request} from "../../../utils/request";

class AdminMarketingModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({fromName, user, subdomain, autoAmount}) => {
    const {company} = this.props;

    const companyPayload = {
      MARKETING_EMAIL_FROM:
        !fromName || fromName === "" ? null : fromName.trim(),
      MARKETING_EMAIL_USER:
        !user || user.trim() === "" ? "marketing" : user.trim(),
      MARKETING_EMAIL_SUBDOMAIN:
        !subdomain || subdomain === "" ? null : subdomain.trim(),
      TEXT_MARKETING_AUTO_AMOUNT: autoAmount,
    };

    await request("admin/company/settings/" + company.ID, "PATCH", {
      SETTINGS: companyPayload,
    });

    this.props.onUpdate();

    this.modal.close();
  };

  render() {
    const {
      MARKETING_EMAIL_USER,
      MARKETING_EMAIL_FROM,
      MARKETING_EMAIL_SUBDOMAIN,
      TEXT_MARKETING_AUTO_AMOUNT,
    } = this.props.company.SETTINGS;

    return (
      <Modal
        label="Marketing Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            fromName: MARKETING_EMAIL_FROM,
            user: MARKETING_EMAIL_USER,
            subdomain: MARKETING_EMAIL_SUBDOMAIN,
            autoAmount: TEXT_MARKETING_AUTO_AMOUNT,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="fromName"
                  label="Display Name"
                  options={formikOptions}
                />

                <FormInput
                  name="user"
                  label="Email User"
                  options={formikOptions}
                />

                <FormInput
                  name="subdomain"
                  label="Subdomain"
                  options={formikOptions}
                />

                <FormInput
                  name="autoAmount"
                  label="Free Text Sends Per Month"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AdminMarketingModal;
