import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import NewsDetailsModal from "../../../modals/marketing/news-details-modal";
import Banner from "../../../components/banner";

class NewsPage extends Component {
  state = {news: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let news = await request("news/" + id, "GET");

    request("dashboard/news/" + news.ID + "/interactions", "GET").then((data) => {
      console.log("HERE", data);
    })

    this.setState({news});
  }

  render() {
    const {news} = this.state;

    if (news === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={news.TITLE}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Post",
                      onClick: () => this.archiveChecklist(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "News Page", url: "/News"},
          ]}
        />

        <Banner
          className="my-4"
          label="News Posts are under construction!"
          description="News Posts are currently under construction and will be available to use soon!"
        />

        <NewsDetailsModal
          updateState={(news) => this.setState({news})}
          ref={(e) => (this.newsModal = e)}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.newsModal.open(news);
              },
            },
          ]}
          label="News Post Information"
          description="Information about this News Post"
          data={[
            {label: "Title", value: news.TITLE},
            {label: "Subtitle", value: news.SUBTITLE},
            {label: "Content", value: news.CONTENT},
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(NewsPage));
