import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import RevenueCenterModal from "../../../modals/sales/tables/revenue-center-modal";
import {Formik} from "formik";
import * as Yup from "yup";

class RevenueCenterPage extends Component {
  state = {
    areas: null,
    center: null,
    splits: null,
    roles: null,
  };

  componentDidMount() {
    this.syncState();
  }

  syncState = () => {
    const {ID: id} = this.props.router.params;

    request("centers/" + id, "GET").then(async (center) => {
      const roles = await request("employeeroles/roles", "GET");

      this.setState({
        center,
        roles,
        areas: center.AREAS,
        splits: center.SPLITS,
      });
    });
  };

  delete = async (category) => {
    showLoadingConfirmAlert(
      "Delete Revenue Center",
      "Are you sure you want to delete this revenue center?"
    ).then(async (close) => {
      await request("centers/" + category.ID, "DELETE", null);

      close();

      this.props.router.navigate("/centers");
    });
  };

  async updateSplit({splits}) {
    const {center} = this.state;

    if (splits.length === 0 && center.SPLITS.length === 0) return;

    if (splits.length > 0) {
      const roleSet = new Set();
      const splitSum = splits.reduce((accum, item) => {
        roleSet.add(item.ROLE_ID);

        return accum + item.AMOUNT;
      }, 0);

      if (splitSum !== 100) {
        return showErrorAlert(
          "Percentage Error",
          "Split percentage sum must add up to 100"
        );
      }

      if (roleSet.size !== splits.length) {
        return showErrorAlert(
          "Duplicate Role",
          "You can only select a role once for splitting tips"
        );
      }
    }

    this.splitButton.startLoading();

    const serverSplits = await request(
      "centers/" + center.ID + "/splits",
      "POST",
      {
        SPLITS: splits,
      }
    );

    this.splitButton.stopLoading();

    this.setState({
      splits: serverSplits,
      center: {...center, SPLITS: serverSplits},
    });
  }

  render() {
    const {center, areas, splits, roles} = this.state;

    if (center === null) {
      return <Loading />;
    }

    return (
      <div>
        <RevenueCenterModal
          ref={(e) => (this.centerModal = e)}
          updateState={(_, center) => this.setState({center})}
        />

        <PageHeadings
          label={center?.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Revenue Center",
                      onClick: () => this.delete(center),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Tables Service", url: "/tables"},
            {label: "Revenue Centers Page", url: "/centers"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.centerModal.open(center),
            },
          ]}
          label={center.NAME + " Information"}
          description="Information about this revenue center"
          data={[
            {label: "Name", value: center.NAME},
            {
              label: "Description",
              value: center.DESCRIPTION,
              span: true,
              hide: true,
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Tip Splitting", id: "splits"},
            {label: "Service Areas", id: "areas"},
            {label: "Checks", id: "checks"},
          ]}
        >
          {(id) => {
            if (id === "areas") {
              return (
                <Card
                  label="Service Areas"
                  description="The service areas this revenue center is attached to"
                >
                  <Table
                    pagination
                    data={areas}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) =>
                          this.props.router.navigate(
                            "/category/" + row.UNIQUE_ID
                          ),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "splits") {
              return (
                <Formik
                  onSubmit={this.updateSplit.bind(this)}
                  innerRef={(e) => (this.formikRef = e)}
                  enableReinitialize
                  initialValues={{
                    splits,
                  }}
                >
                  {(formikOptions) => {
                    const {setFieldValue, values, handleSubmit} = formikOptions;
                    const splitsValue = values.splits;

                    return (
                      <Card
                        label="Tip Splitting"
                        description="How will splitting be divided across roles"
                        button={{
                          label: "Save Splits",
                          ref: (e) => (this.splitButton = e),
                          onClick: handleSubmit,
                        }}
                      >
                        <Table
                          pagination
                          className="mt-2"
                          data={splitsValue}
                          actionTextClassName="mr-4"
                          actionTexts={[
                            {
                              label: "Add Split",
                              onClick: () => {
                                const splits = [...splitsValue];

                                splits.push({
                                  ROLE_ID: null,
                                  AMOUNT: 20,
                                });

                                setFieldValue("splits", splits);
                              },
                            },
                          ]}
                          actionButtons={[
                            {
                              icon: "trash",
                              onClick: (_, index) => {
                                const splits = [...splitsValue];

                                splits.splice(index, 1);

                                setFieldValue("splits", splits);
                              },
                            },
                          ]}
                          columns={[
                            {
                              value: "ROLE_ID",
                              label: "Role",
                              type: "select",
                              data: [
                                {label: "Waiter Rake", value: null},
                                ...roles.map((item) => {
                                  return {label: item.NAME, value: item.ID};
                                }),
                              ],
                              editable: true,
                              onChange: (index, val) => {
                                const row = splitsValue[index];

                                splitsValue.splice(index, 1, {
                                  ...row,
                                  ROLE_ID: val,
                                });

                                setFieldValue("splits", splitsValue);
                              },
                            },
                            {
                              placeholder: "20",
                              value: "AMOUNT",
                              label: "Split Percentage",
                              editable: true,
                              isSimple: true,
                              yup: Yup.number()
                                .typeError("Must be a number")
                                .required("Rake is required"),
                              onChange: (index, val) => {
                                let row = splitsValue[index];

                                splitsValue.splice(index, 1, {
                                  ...row,
                                  AMOUNT: parseInt(val),
                                });

                                setFieldValue("splits", splitsValue);
                              },
                            },
                          ]}
                        />
                      </Card>
                    );
                  }}
                </Formik>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(RevenueCenterPage));
