import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import NewsDetailsModal from "../../../modals/marketing/news-details-modal";
import Banner from "../../../components/banner";

class NewsPage extends Component {
  state = {
    isLoading: true,
    news: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let news = await request("news/partner", "GET");

    this.setState({isLoading: false, news});
  }

  render() {
    const {isLoading, news} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="News Post"
          description="Create news posts that will be displayed on the order app"
          button={{label: "Create Post", onClick: () => this.newsModal.open()}}
        />

        <NewsDetailsModal
          ref={(e) => (this.newsModal = e)}
          addState={(news) => {
            this.props.router.navigate("/new/" + news.UNIQUE_ID)
          }}
        />

        <Banner
          className="my-4"
          label="News Posts are under construction!"
          description="News Posts are currently under construction and will be available to use soon!"
        />

        <Table
          pagination
          data={news}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) =>
                this.props.router.navigate("/new/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "TITLE",
              label: "Title",
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(NewsPage);
