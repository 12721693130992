import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind"

class InvoicePdfPreview extends Component {
  state = {data: null};

  componentDidMount() {
    const {invoice, lines} = this.props;

    const amount = lines.reduce((accum, item) => accum + item.AMOUNT, 0);
    const tax = lines.reduce((accum, item) => accum + item.AMOUNT_TAX, 0);
    const total = lines.reduce((accum, item) => accum + item.TOTAL, 0);

    const invoicePayload = {
      ...invoice,

      STATUS: "DRAFT",

      TICKET: {
        NOTES: invoice.NOTES,
        DATE_CREATED: Date.now(),

        PAYMENT_INTENT: {
          PAYMENTS: [],
          DATE_PAYED: null,

          AMOUNT: amount,
          AMOUNT_TAX: tax,

          TOTAL: total,
        },

        ITEMS: lines,
      },
    };

    request(
      "invoices/pdf/preview",
      "POST",
      {INVOICE: invoicePayload},
      {Accept: "application/pdf"}
    ).then((data) => {
      this.setState({data: btoa(String.fromCharCode(...new Uint8Array(data)))});
    });
  }

  render() {
    const {data} = this.state;

    if (data) {
      return (
        <div>
          <iframe
            style={{height: "100vh"}}
            src={"data:application/pdf;base64," + data}
            type="application/pdf"
            width="100%"
          />
        </div>
      );
    }

    return <Loading />;
  }
}

export default InvoicePdfPreview;
