import {showLoadingConfirmAlert} from "./alert-helper";
import moment from "moment-timezone";

export function nameTruncate(string) {
  if (string.includes(" ")) {
    const spaceIndex = string.indexOf(" ");
    return string.slice(0, spaceIndex + 2);
  }
  return string;
}

export function isShiftInUnavailabilityRange(
  shiftStart,
  shiftEnd,
  unavailStart,
  unavailEnd
) {
  if (unavailEnd > shiftStart && unavailEnd <= shiftEnd) {
    return true;
  }

  if (unavailStart >= shiftStart && unavailStart < shiftEnd) {
    return true;
  }

  if (unavailStart <= shiftStart && unavailEnd >= shiftEnd) {
    return true;
  }

  return false;
}

export function isShiftInTimeOffRange(
  shiftStart,
  shiftEnd,
  timeOffStart,
  timeOffEnd,
  timezone
) {
  timeOffStart = moment(timeOffStart).tz(timezone).startOf("day").valueOf();
  timeOffEnd = moment(timeOffEnd).tz(timezone).endOf("day").valueOf();

  //Because the start and end time on time off requests for partial days is ambiguous, we just do a loose check to see if coincides on the day
  return shiftStart >= timeOffStart && shiftEnd <= timeOffEnd;
}

export function isTimeOffConflict(time, shift, timezone) {
  return (
    time.EMPLOYEE_ID === shift.EMPLOYEE_ID &&
    time.STATUS === "APPROVED" &&
    isShiftInTimeOffRange(
      shift.DATE_START,
      shift.DATE_END,
      time.DATE_START,
      time.DATE_END,
      timezone
    )
  );
}

export function isUnavailabilityConflict(unavail, shift) {
  return (
    unavail.EMPLOYEE_ID === shift.EMPLOYEE_ID &&
    unavail.TYPE === 1 &&
    isShiftInUnavailabilityRange(
      shift.DATE_START,
      shift.DATE_END,
      unavail.DATE_START,
      unavail.DATE_END
    )
  );
}
