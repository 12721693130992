import React, {Component} from "react";
import {
  Button,
  FormBoolean,
  FormInput,
  FormSelect,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ProductDropdown from "../../../dropdowns/sales/product-dropdown";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import FormRow from "../../../components/form-row";
import CustomerModal from "../../../modals/operations/invoices/contact-modal";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import ContactDropdown from "../../../dropdowns/operations/contact-dropdown";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checkbox from "../../../components/form-elements/checkbox";

class InvoiceForm extends Component {
  state = {orderTypes: []};

  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {
      contact,
      contactObj,
      status,
      contactName,
      number,
      due,
      service,
      notes,
      tipEnabled,
      scheduled,
    } = this.formikRef.values;

    return {
      invoice: {
        STATUS: status,
        INVOICE_NUMBER: number,

        CONTACT: contactObj,
        CONTACT_NAME: contactName,
        CONTACT_ID: contact,

        DATE_SERVICE: service,
        DATE_DUE: due ? parseInt(due) : null,
        REQUEST_TIP: tipEnabled,
        NOTES: notes,
        TICKET_DATE_SCHEDULED: scheduled ? service : null,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  renderType(options) {
    const {values, setFieldValue} = options;

    if (values.type === "product") {
      return (
        <ProductDropdown
          onChangeSoft={({label}) => setFieldValue("productName", label)}
          ref={(e) => (this.productRef = e)}
          options={options}
          name="product"
        />
      );
    }

    if (values.type === "category") {
      return (
        <CategoryDropdown
          onChangeSoft={({label}) => setFieldValue("categoryName", label)}
          ref={(e) => (this.categoryRef = e)}
          options={options}
          name="category"
        />
      );
    }
  }

  renderDiscount(options) {
    const {values} = options;

    if (values.discount === "flat") {
      return <FormInput label="Flat Amount" name="flat" options={options} />;
    }

    if (values.discount === "percentage") {
      return (
        <FormInput
          label="Discount Percentage"
          name="percentage"
          options={options}
        />
      );
    }
  }

  render() {
    const {handleSubmit, invoice} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={{
          number: invoice?.INVOICE_NUMBER ?? null,
          contact: invoice?.CONTACT_ID ?? null,
          contactName: invoice?.CONTACT_NAME ?? null,
          service: invoice?.DATE_SERVICE ?? Date.now(),
          status: invoice?.STATUS ?? "DRAFT",
          due: invoice?.DATE_DUE ?? null,
          notes: invoice?.NOTES,
          contactObj: invoice?.CONTACT ?? null,
          tipEnabled: invoice?.REQUEST_TIP ?? false,
          scheduled: !!(
            invoice?.TICKET?.DATE_SCHEDULED || invoice?.TICKET_DATE_SCHEDULED
          ),
        }}
        validationSchema={Yup.object().shape({
          contactName: Yup.string()
            .nullable()
            .required("Please select a contact"),
        })}
      >
        {(formikOptions) => {
          const {values, handleSubmit, setFieldValue, errors} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <CustomerModal
                addState={(contact) => {
                  setFieldValue("contactObj", contact);
                  setFieldValue("contact", contact.ID);

                  this.patronDropdown.syncData(
                    contact.FIRST_NAME + " " + contact.LAST_NAME
                  );
                }}
                ref={(e) => (this.customerModal = e)}
              />

              <div>
                <ContactDropdown
                  onChangeSoft={({name, contact}) => {
                    setFieldValue("contactName", name);
                    setFieldValue("contactObj", contact);
                  }}
                  nameField="contactName"
                  options={formikOptions}
                  label="Contact"
                  name="contact"
                  createLabel="Create Contact"
                  ref={(e) => (this.patronDropdown = e)}
                  onCreate={(query) => this.customerModal.open(null, query)}
                />

                {errors.contactName && (
                  <div className={"text-sm text-red-700"}>
                    Please select a contact.
                  </div>
                )}
              </div>

              <FormRow>
                <FormInput
                  options={formikOptions}
                  label="Invoice Number"
                  name="number"
                  flex
                />

                <FormDateTimeSelect
                  name="due"
                  label="Due Date"
                  options={formikOptions}
                  onChangeSoft={(val) => {
                    this.props.onDueChange && this.props.onDueChange(val);
                  }}
                  hideTime
                  flex
                />
              </FormRow>

              <FormTextArea
                options={formikOptions}
                label="Invoices Notes"
                hint="Optional"
                name="notes"
              />

              <FormBoolean
                options={formikOptions}
                label={"Enable Tips"}
                name={"tipEnabled"}
              />

              <div
                className={"flex flex-row justify-start space-x-4 items-end"}
              >
                <FormDateTimeSelect
                  options={formikOptions}
                  label="Service Date"
                  name="service"
                  tooltip={{
                    label: "Service Date",
                    data: "Select when items in this invoice will show up on reports as Sales.",
                  }}
                />

                <Checkbox
                  className={"mb-2"}
                  formikOptions={formikOptions}
                  name={"scheduled"}
                  label={"Send to Ticket Screen at Service"}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

InvoiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default InvoiceForm;
