import React, {Component} from "react";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import DashboardItem from "./dashboard-item";

class ItemsTab extends Component {
  render() {
    let {items, router} = this.props;

    if(!items){
      return <Loading />
    }

    return (
      <div className="mt-2 bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {items.map((notification) => (
            <DashboardItem router={router} item={notification} />
          ))}
        </ul>
      </div>
    );
  }
}

export default ItemsTab
