import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";

class CategoryDiscountModal extends Component {
  state = {productDiscounts: []};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.syncState().then(() => {
      this.modal.open();
    });
  }

  async syncState() {
    const discounts = await request("partner/discounts/product", "GET");
    const productDiscounts = discounts.filter(
      (discount) => !!discount.PRODUCT && !discount.ARCHIVED
    );

    this.setState({productDiscounts});
  }

  async handleSubmit({discount: discountId}) {
    const {category} = this.props;
    const {productDiscounts} = this.state;

    const discount = productDiscounts.find(
      (discount) => discount.ID === discountId
    );

    const {CATEGORY_DISCOUNT_ID} = await request(
      "discounts/category/",
      "POST",
      {
        CATEGORY_ID: category.ID,
        DISCOUNT_ID: discountId,
      }
    );

    discount.CATEGORY_DISCOUNT_ID = CATEGORY_DISCOUNT_ID;

    this.props.updateState && this.props.updateState(discount);

    this.modal.close();
  }

  render() {
    const {productDiscounts} = this.state;
    const {exclude = []} = this.props;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Select Discount"}
        description={
          "Select which discount you would like to add to this category."
        }
        tooltip={{
          data: "You can only add product discounts to a category. To create a product discount, go to the Product Discounts tab on the Discounts Page.",
        }}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            discount: null,
          }}
          onSubmit={(values) => this.handleSubmit(values)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label="Discount"
                  name="discount"
                  data={productDiscounts
                    .filter((discount) => !exclude.includes(discount.ID))
                    .map((discount) => ({
                      label: discount.NAME,
                      value: discount.ID,
                    }))}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CategoryDiscountModal;
