export function getTicketTransactionDate(ticket) {
  if (!!ticket.FROM_OFFLINE) {
    return;
  }

  if (!!ticket.DATE_SENT) {
    return ticket.DATE_SENT;
  }

  return ticket.DATE_PAYED;
}
