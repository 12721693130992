import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class RoleDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = []} = this.props;

    const serverRoles = await request("employeeroles/roles", "GET");
    const data = serverRoles
      .filter((item) => !exclude.includes(item.ID))
      .map((item) => {
        return {
          id: item.ID,
          label: item.NAME,
        };
      });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return <ComboBox label="Role" {...this.props} data={data} />;
  }
}

RoleDropdown.propTypes = {
  ...ComboBox.propTypes,
};

export default RoleDropdown;
