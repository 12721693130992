import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import OrderSystemModal from "../../../modals/shop/shop-settings/order-system-modal";
import ProcessingFeesModal from "../../../modals/shop/shop-settings/processing-fees-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketTypeDropdown from "../../../dropdowns/sales/ticket-type-dropdown";
import {request} from "../../../utils/request";

class OrderSystemTab extends Component {
  state = {orderTypes: null};

  componentDidMount() {
    request("types/order", "GET").then((orderTypes) =>
      this.setState({orderTypes})
    );
  }

  render() {
    let {
      DELIVERY_ENABLED,
      KIOSK_ENABLED,
      REQUIRE_CASH_DRAWER,
      PASS_PROCESSING_FEES_MOBILE,
      PASS_PROCESSING_FEES_POS,
      TAX_WITHHOLDING,
      DEFAULT_CASH_AMOUNT,
      LOCATION_BASED_CASH_DRAWERS,
      INVOICE_ORDER_TYPE,
      // SHOW_COUPON_CHECKOUT
    } = this.props.shop.settings;

    const {orderTypes} = this.state;

    return (
      <div>
        <OrderSystemModal ref={(e) => (this.orderSystemModal = e)} />

        <ProcessingFeesModal ref={(e) => (this.processingFeesModal = e)} />

        <TwoColumnList
          label="Order System"
          description="Configure order system settings for your shop"
          data={[
            {
              label: "Delivery Enabled",
              value: DELIVERY_ENABLED,
              type: "bool",
              tooltip:
                "Allow your customers to place orders on the website and mobile app for delivery.",
            },
            {
              label: "Kiosk Enabled",
              value: KIOSK_ENABLED,
              type: "bool",
              tooltip:
                "When disabled, kiosk specific features will be unavailable.",
            },
            {
              label: "Require Cash Drawer for Charge",
              value: REQUIRE_CASH_DRAWER,
              type: "bool",
              tooltip:
                "Before being able to do any charges, require a cash drawer to be opened.",
            },
            {
              label: "Cash Drawer Starting Amount",
              value: `${toDollars(DEFAULT_CASH_AMOUNT, true)}`,
              tooltip:
                "The default starting cash amount when a new cash drawer is opened",
            },
            {
              label: "Share Cash Drawer Between Devices",
              value: LOCATION_BASED_CASH_DRAWERS,
              type: "bool",
              tooltip:
                "If enabled, an open cash drawer will be shared across devices within your location. Otherwise, cash drawers will be specific to each device.",
            },
            {
              label: "Tax Withholding",
              value: TAX_WITHHOLDING,
              type: "bool",
              tooltip:
                "Withhold taxes on each purchase for tax payouts that are separate from the regular payouts",
            },
            {
              label: "Invoicing Order Type",
              value:
                orderTypes && orderTypes.length > 0
                  ? orderTypes.find(
                      (type) => type.ID + "" === INVOICE_ORDER_TYPE
                    )?.NAME ?? orderTypes[0].NAME
                  : "",
              type: "string",
            },
            // {
            //   label: "Coupons on Point of Sale",
            //   value: SHOW_COUPON_CHECKOUT,
            //   type: "bool",
            //   tooltip:
            //     "If enabled you will have the ability to attach coupons on the Point of Sale",
            // },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.orderSystemModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Processing Fees"
          description={"Configure processing fee settings for your shop"}
          tooltip={{
            data: "Pass on the 2.9% + 30¢ processing fee to the customer for card not resent transactions",
          }}
          data={[
            {
              label: "Pass Processing on Online & Mobile Ordering",
              value: PASS_PROCESSING_FEES_MOBILE,
              type: "bool",
              tooltip:
                "Charges a processing fee on all website & mobile orders.",
            },
            {
              label: "Pass Processing on POS Ordering",
              value: PASS_PROCESSING_FEES_POS,
              type: "bool",
              tooltip: "Charges a processing fee on all POS orders.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.processingFeesModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(OrderSystemTab);
