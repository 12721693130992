import React, {Component, Fragment} from "react";
import moment from "moment";
import {setupReduxConnection} from "../../redux";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import {Menu, Transition} from "@headlessui/react";

import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import WeekCalendar from "./week-calendar";
import {VIEWS} from "./scheduler";

class CurrentViewButton extends Component {
  render() {
    let {currentViewString} = this.props.schedulingStats;
    let {currentView} = this.props.scheduling;

    return (
      <Menu as="div" className="relative inline-block cursor-pointer text-left">
        <Menu.Button className="w-40 hover:bg-gray-50 cursor-pointer inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 cursor-default">
          {currentViewString}

          {currentView !== VIEWS.MONTH && (
            <ChevronDownIcon
              className="-mr-1 ml-1 h-4 w-4 mt-0.5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>

        {currentView !== VIEWS.MONTH && (
          <Transition
            as={Fragment}
            unmount={false}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              unmount={false}
              className="p-5 origin-top-left z-50 absolute mt-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="flex flex-row">
                <WeekCalendar
                  onChange={(s) => {
                    this.props.updateView(s.startEpoch);
                  }}
                />
              </div>
            </Menu.Items>
          </Transition>
        )}
      </Menu>
    );
  }
}

export default setupReduxConnection(["scheduling", "schedulingStats"])(
  CurrentViewButton
);
