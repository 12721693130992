import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";

class ChecklistTaskOptionModal extends Component {
  state = {option: null, index: -1};

  open(option = null, index = -1) {
    this.setState({option, index}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createOption({name, seq}) {
    const optionPayload = {
      NAME: name,
      SEQ: seq,
    };

    this.props.addState(optionPayload);

    this.modal.close();
  }

  async saveOption({name, seq}) {
    const {option, index} = this.state;

    const optionPayload = {
      NAME: name,
      SEQ: seq,
    };

    this.props.updateState(index, {
      ...option,
      ...optionPayload,
    });

    this.modal.close();
  }

  render() {
    const {option} = this.state;

    return (
      <Modal
        buttonLabel={option ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={option ? "Edit Task Option" : "Create Task Option"}
      >
        <Formik
          onSubmit={
            option ? this.saveOption.bind(this) : this.createOption.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: option?.NAME ?? "",
            seq: option?.SEQ ?? "1",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />
                <FormInput label="Seq" name="seq" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChecklistTaskOptionModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  addState: PropTypes.func.isRequired,
};

export default ChecklistTaskOptionModal;
