import React, {Component} from "react";
import {Card, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import StepNavCard from "../../../features/step-nav-card";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import PayrollCompanySetupComponent from "../../../features/payroll/onboard/company/payroll-company-form-setup-component";
import PayrollCompanyTaxAuthSetupComponent from "../../../features/payroll/onboard/company/payroll-company-tax-auth-setup-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {InlineWidget, CalendlyEventListener} from "react-calendly";
import PayrollCalendlyScheduleComponent from "../../../features/payroll/onboard/company/payroll-calendly-schedule-component";

class PayrollCompanySetupPage extends Component {
  state = {currentStep: STEPS.LANDING};

  componentDidMount() {
    let {PAYROLL_WHITELISTED} = this.props.shop.location;

    if (PAYROLL_WHITELISTED) {
      this.setState({currentStep: STEPS.ONBOARD});
    }
  }

  componentWillUnmount() {}

  render() {
    let {router} = this.props;
    let {NAME} = this.props.shop.location;
    let {currentStep, fadeOut, fadeIn} = this.state;

    let tabs = [
      {id: "payroll", label: "Full-Service Payroll"},
      {id: "benefits", label: "Employee Benefits"},
      {id: "onboarding", label: "Onboarding"},
    ];

    if (currentStep === STEPS.LANDING) {
      return (
        <Card className="w-full h-full p-6">
          <div>
            <div className={"lg:w-2/3 m-auto space-y-6"}>
              <div>
                <div className={"text-right w-32"}>
                  <img
                    className=" h-auto rounded-lg"
                    src={require("../../../assets/images/logo_type.png")}
                    alt="image description"
                  />
                  <div className="font-bold text-lg">Payroll</div>
                </div>
              </div>

              <div className="w-full flex flex-row items-center m-auto">
                <button
                  type="button"
                  className="m-auto space-x-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-indigo-600"
                >
                  Contact Support to Learn More!
                </button>
              </div>

              <Tab data={tabs}>
                {(id) => {
                  if (id === "onboarding") {
                    return (
                      <div className="mt-7 flex flex-row">
                        <div className="w-1/2 space-y-4 pr-5">
                          <div className="text-xl font-bold">
                            Onboard new employees without touching anything
                          </div>

                          <div className="text-gray-800">
                            Your employees will automatically receive reminders
                            to fill out their required onboarding information.
                            From there, they'll have a portal to securely view
                            all paystubs, tax filings, and update their
                            information.
                          </div>
                        </div>

                        <div className=" w-1/2">
                          <img
                            className="w-auto h-auto rounded-lg"
                            src={require("./../../../assets/images/employee-onboard.png")}
                            alt="image description"
                          />
                        </div>
                      </div>
                    );
                  }

                  if (id === "benefits") {
                    return (
                      <div className="mt-7 flex flex-row">
                        <div className="w-1/2 space-y-5 pr-5">
                          <div className="text-xl font-bold">
                            Offer health insurance with ease
                          </div>

                          <div className="text-gray-800">
                            We are a one-stop-shop for your health insurance.
                            You can search, compare, and enroll in health
                            benefits directly from the dashboard.
                          </div>

                          <ul className="space-y-3">
                            <li className="font-semibold">
                              <span className="pr-3">✅</span>
                              <span>Medical, dental, and vision coverage</span>
                            </li>

                            <li className="font-semibold">
                              <span className="pr-3">✅</span>
                              <span>Automated payroll deductions</span>
                            </li>

                            <li className="font-semibold">
                              <span className="pr-3">✅</span>
                              <span>
                                Use any existing plan or choose from any to
                                enroll in
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className=" w-1/2">
                          <img
                            className="w-auto h-auto rounded-lg"
                            src={require("./../../../assets/images/simplyinsured.png")}
                            alt="image description"
                          />
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="mt-7 space-y-5">
                      <div className="text-xl font-bold ">
                        Run payroll in 4 clicks
                      </div>

                      <div className="text-gray-800">
                        We handle the heavy work: calculating your wages, tips,
                        taxes, and filing with the government. All you need to
                        do is click run.
                      </div>

                      <img
                        className="w-auto h-auto rounded-lg"
                        src={require("./../../../assets/images/run-payroll-screenshot.png")}
                        alt="image description"
                      />
                    </div>
                  );
                }}
              </Tab>
            </div>
          </div>
        </Card>
      );
    }

    return (
      <StepNavCard
        label={`Payroll Setup for ${NAME}`}
        router={router}
        steps={[
          {
            label: "Business Information",
            component: PayrollCompanySetupComponent,
          },
          {label: "Onboarding", component: PayrollCompanyTaxAuthSetupComponent},
        ]}
      />
    );
  }
}

const STEPS = {
  LANDING: "LANDING",
  ONBOARD: "ONBOARD",
};

export default setupReduxConnection(["shop"])(
  withRouter(PayrollCompanySetupPage)
);
