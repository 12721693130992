import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {PERMISSIONS} from "../../utils/constants";

class ShopSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title="Restaurant"
              permissions={[PERMISSIONS.SHOP.FULL.id]}
              navigation={[
                {
                  live: true,
                  id: "shop",
                  name: "My Shop",
                  href: "/shop",
                  permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
                  children: [
                    {
                      live: true,
                      name: "Shop",
                      href: "/shop",
                      paths: ["/shop"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Hours",
                      href: "/hours",
                      paths: ["/shop/hours"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Hours Overrides",
                      href: "/overrides",
                      paths: ["/shop/overrides"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Settings",
                      href: "/settings",
                      paths: ["/shop/settings"],
                      permissions: [],
                    },
                  ],
                },
                {
                  live: true,
                  id: "websites",
                  name: "Order Website",
                  href: "/websites",
                  permissions: [PERMISSIONS.SHOP.WEBSITES.id],
                  children: [
                    {
                      live: true,
                      name: "QR Codes",
                      href: "/websites/qr",
                      permissions: [],
                    },
                  ],
                },
                {
                  live: true,
                  id: "device",
                  name: "Devices",
                  href: "/devices",
                  permissions: [PERMISSIONS.SHOP.DEVICES_FULL.id],
                  children: [
                    {
                      live: true,
                      name: "Kiosk Settings",
                      href: "/kiosks",
                      permissions: [],
                    },
                  ],
                },
                {
                  live: false,
                  id: "companyStructure",
                  name: "Company Structure",
                  href: "/company-structure/companies",
                  permissions: [],
                  children: [
                    {
                      live: false,
                      name: "Companies",
                      href: "/company-structure/companies",
                      permissions: [],
                    },
                    {
                      live: false,
                      name: "Admins",
                      href: "/company-structure/admins",
                      permissions: [],
                    },
                  ],
                },
              ]}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default ShopSideNav;
