import React, {Component} from "react";
import {
  Tab,
  Loading,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import LocationsTable from "../../../tables/shop/company-structure/locations-table";

class CompanyPage extends Component {
  state = {locations: null, company: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    request(`companies/locations/${id}`, "GET").then((locations) =>
      this.setState({locations})
    );

    request(`companies/${id}`, "GET").then((company) => {
      this.setState({company});
    });
  }

  render() {
    const {locations, company} = this.state;

    if (!locations || !company) {
      return <Loading />;
    }

    return (
      <>
        <PageHeadings
          label={`${company.NAME}`}
          description={`Manage settings for ${company.NAME}`}
        />

        <Tab
          data={[
            {
              id: "locations",
              label: "Locations",
              badge: locations ? `${locations.length}` : "0",
            },
          ]}
        >
          {(tab) => {
            if (tab === "locations") {
              return <LocationsTable locations={locations} />;
            }
          }}
        </Tab>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(CompanyPage));
