import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import SignatureCanvas from "react-signature-canvas";
import {Trans} from "react-i18next";

class SignatureModal extends Component {
  state = {field: null, resolve: null};

  open(field) {
    return new Promise((resolve) => {
      this.setState({resolve, field}, () => this.modal.open());
    });
  }

  getI18NextKey(path) {
    return "modals.team.employee.onboarding.signature-modal." + path;
  }

  async submitSignature() {
    if (this.canvas.isEmpty()) {
      return;
    }

    const {resolve} = this.state;
    const signatureImageURL = this.canvas.toDataURL();
    resolve(signatureImageURL);
    this.modal.close();
  }

  render() {
    return (
      <Modal
        large
        label={<Trans i18nKey={this.getI18NextKey("signature.label")} />}
        tooltip={{
          data: "Use the signature pad below to sign your name.",
        }}
        buttonLabel={<Trans i18nKey={this.getI18NextKey("submit.label")} />}
        closeLabel={<Trans i18nKey={this.getI18NextKey("cancel.label")} />}
        buttonOnClick={() => this.submitSignature()}
        ref={(e) => (this.modal = e)}
      >
        <SignatureCanvas
          penColor="black"
          className={"hover:cursor-pointer"}
          canvasProps={{
            position: "relative",
            width: 600,
            height: 200,
            className: "sigCanvas",
          }}
          ref={(e) => (this.canvas = e)}
          throttle={1}
          velocityFilterWeight={1}
        />
      </Modal>
    );
  }
}

export default SignatureModal;
