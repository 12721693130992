import React, {Component} from "react";
import AlertService from "../services/alert-service";
import NotificationService from "../services/notification-service";
import AdminHeader from "./admin-header";
import {request} from "../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../utils/navigation";
import {updateUserAccount} from "../../redux/user";
import {getStore} from "../../redux";

class AdminLayout extends Component {
  state = {isLoading: true, isAdmin: false};

  componentDidMount() {
    request("dashboard/admin", "GET")
      .then((data) => {
        const {account} = data;

        getStore().dispatch(updateUserAccount(account));

        this.setState({isAdmin: true, isLoading: false});
      })
      .catch((err) => {
        console.log("ERROR", err);

        this.setState({isLoading: false});
      });
  }

  renderBye() {
    setTimeout(() => {
      this.props.router.navigate("/403");
    }, 1);

    return <div>No Permission Dawg</div>;
  }

  render() {
    const {isLoading, isAdmin} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    if (!isAdmin) {
      return this.renderBye();
    }

    return (
      <div>
        <AdminHeader />
        <AlertService />
        <NotificationService />

        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(AdminLayout);
