import React, {Component} from "react";
import GrayBadge from "../../components/badges/gray-badge";
import {request} from "../../utils/request";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import PayrollRunErrorModal from "../../modals/payroll/terminal/payroll-run-error-modal";

class OffCyclesTab extends Component {
  state = {offCyclePayrolls: null};

  async componentDidMount() {
    let offCyclePayrolls = await request("admin/payroll/off-cycles");

    this.setState({offCyclePayrolls})
  }

  getColumns() {
    return [
      {
        label: "Location",
        value: "NAME"
      },
      {
        label: "Period End",
        value: "PERIOD_END",
        format: (value) => moment(value, "YYYY-MM-DD").format("M/D"),
        width: 1
      },
      {
        label: "Tests",
        value: "RUN_ERRORS",
        format: (value) => {
          if (value) {
            return <div onClick={() => {
              this.payrollRunErrorModal.open(value)
            }}><DangerBadge>
              <FontAwesomeIcon icon="fa-solid fa-x"/>
            </DangerBadge></div>
          } else {
            return <SuccessBadge>
              <FontAwesomeIcon icon="fa-solid fa-check"/>
            </SuccessBadge>
          }

        },
        width: 1
      }
    ];
  }

  render() {
    let {offCyclePayrolls} = this.state;

    let actionButtons = [
      {
        label: (
          <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square"/>
        ),
        onClick: (row) => {
          window.open(
            `https://console.checkhq.com/payrolls/` + row.CHECK_PAYROLL_ID,
            "_blank"
          );
        },
      },
    ];

    return (
      <div className={"space-y-5 mt-5"}>
        <PayrollRunErrorModal
          ref={(e) => (this.payrollRunErrorModal = e)}
        />

        <div className={"text-lg font-semibold mb-2"}>
          Approved Off-Cycle Payrolls
        </div>

        <Table
          actionButtons={actionButtons}
          columns={this.getColumns()}
          data={offCyclePayrolls}
        />
      </div>
    );
  }
}

export default OffCyclesTab;
