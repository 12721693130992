import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {request} from "../../../utils/request";

class CompanyDocumentsDropdown extends Component {
  state = {companyDocuments: []};

  componentDidMount() {
    this.syncData();
  }

  syncData() {
    request("company-documents/files", "GET").then((companyDocuments) => {
      this.setState({companyDocuments});
    });
  }

  render() {
    const {companyDocuments} = this.state;

    const comboBoxData = companyDocuments.map((document) => {
      return {
        id: document.ID,
        label: document.FILE_NAME,
      };
    });

    return (
      <ComboBox
        label={"Company Document"}
        {...this.props}
        data={comboBoxData}
        onChangeSoft={(e) => this.props.updateState({NAME: e.id, SEQ: e.id})}
      />
    );
  }
}

export default CompanyDocumentsDropdown;
