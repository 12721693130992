import React, {Component} from "react";
import {
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class LabelerModal extends Component {
  state = {labeler: null};

  open(labeler) {
    this.setState({labeler}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {labeler} = this.state;

    labeler.NAME = values.NAME;
    labeler.IP_ADDRESS = values.IP_ADDRESS;

    await request("/labels/devices/" + labeler.ID, "PATCH", labeler);

    this.props.updateInfo(labeler);
    this.modal.close();
  };

  render() {
    const {labeler} = this.state;

    const schema = Yup.object({
      NAME: Yup.string().nullable().required("Name is required."),
      IP_ADDRESS: Yup.string().nullable().required("IP Address is required."),
    })

    return (
      <Modal
        label="Edit Labeler"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            NAME: labeler?.NAME,
            IP_ADDRESS: labeler?.IP_ADDRESS,
          }}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Labeler Name"
                  name="NAME"
                  options={formikOptions}
                />

                <FormInput
                  label="IP Address"
                  name="IP_ADDRESS"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default LabelerModal;
