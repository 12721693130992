import React, {Component} from "react";
import {Trans} from "react-i18next";
import {
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class W4Step2 extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.w4-employee.w4-step-2." + path;
  }

  renderMultipleJobsWorksheet() {
    const {options} = this.props;

    return (
      <div>
        <div className={"my-3 block text-sm font-medium text-gray-700"}>
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
          <Trans
            i18nKey={this.getI18NextKey("multipleJobsWorksheet.title.label")}
          />
        </div>

        <FormSelect
          name={"numberOfJobs"}
          options={options}
          data={[
            {
              value: "TWO_JOBS",
              label: (
                <Trans
                  i18nKey={this.getI18NextKey(
                    "multipleJobsWorksheet.twoJobs.label"
                  )}
                />
              ),
            },
            {
              value: "THREE_JOBS",
              label: (
                <Trans
                  i18nKey={this.getI18NextKey(
                    "multipleJobsWorksheet.threeJobs.label"
                  )}
                />
              ),
            },
          ]}
        />
        {!!(
          options.values.numberOfJobs === "TWO_JOBS" ||
          options.values.numberOfJobs === "THREE_JOBS"
        ) && (
          <div>
            <FormInput
              name={"highestPaying"}
              options={options}
              label={
                <Trans
                  i18nKey={this.getI18NextKey(
                    "multipleJobsWorksheet.higherPaying.label"
                  )}
                />
              }
            />

            {options.values.numberOfJobs === "THREE_JOBS" && (
              <FormInput
                name={"secondHighestPaying"}
                options={options}
                label={
                  <Trans
                    i18nKey={this.getI18NextKey(
                      "multipleJobsWorksheet.secondHighestPaying.label"
                    )}
                  />
                }
              />
            )}

            <FormInput
              name={"lowestPaying"}
              options={options}
              label={
                <Trans
                  i18nKey={this.getI18NextKey(
                    "multipleJobsWorksheet.lowestPaying.label"
                  )}
                />
              }
            />
          </div>
        )}
        <FormInput
          label={
            <Trans
              i18nKey={this.getI18NextKey(
                "multipleJobsWorksheet.numPayPeriods.label"
              )}
            />
          }
          name={"numPayPeriods"}
          options={options}
        />
      </div>
    );
  }

  render() {
    const {options} = this.props;
    const {values} = options;
    const {step2Type} = values;

    return (
      <form>
        <div className={"my-3 block text-sm font-medium text-gray-700"}>
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
          <Trans i18nKey={this.getI18NextKey("breakpoint.label")} />
        </div>

        <div className={"my-3 block text-sm font-medium text-gray-700"}>
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
          <Trans i18nKey={this.getI18NextKey("condition.label")} />
        </div>

        <FormSelect
          name={"step2Type"}
          options={options}
          data={[
            {
              value: "RESERVED",
              label: (
                <Trans i18nKey={this.getI18NextKey("reservedOption.label")} />
              ),
            },
            {
              value: "MULTIPLE_JOBS_WORKSHEET",
              label: (
                <Trans
                  i18nKey={this.getI18NextKey(
                    "multipleJobsWorksheetOption.label"
                  )}
                />
              ),
            },
            {
              value: "OTHER",
              label: (
                <Trans i18nKey={this.getI18NextKey("otherOption.label")} />
              ),
            },
          ]}
          label={"Select one of the following"}
        />

        {step2Type === "MULTIPLE_JOBS_WORKSHEET" &&
          this.renderMultipleJobsWorksheet()}
      </form>
    );
  }
}

export default W4Step2;
