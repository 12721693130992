import React, {Component} from "react";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  STATE_OVERTIME_RULES, TEAM_SALES_COLUMNS,
  TIME_CARD_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import TimeCardSummaryExpansionComponent from "../../features/reporting/reports/time-card-summary-expansion-component";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {TIP_CALCULATION_METHOD_TYPES} from "../../utils/settings-constants";
import RadioDropdown from "../../components/form-elements/radio-dropdown";
import {
  classNames,
  minutesToHours,
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import DangerBadge from "../../components/badges/danger-badge";
import CSVDropdown from "../../features/reporting/csv-dropdown";
import {
  convertColumnsToCSVHeaders,
  convertToCSVData,
  getDateString,
} from "../../utils/reporting-helper";
import moment from "moment-timezone";

class TeamSalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      let {EMPLOYEE_BREAKDOWN} = reportData;

      rows = EMPLOYEE_BREAKDOWN.map(emp => (
        {
          label: emp.FULL_NAME,
          numbers: {
            PRODUCT_TOTALS: emp.PRODUCT_TOTALS
          },
          expandCell: (row) => (
            <Table
              actionButtons={[]}
              columns={[
                {
                  label: "Product",
                  value: "NAME"
                },
                {
                  label: "Category",
                  value: "CATEGORY_NAME"
                },
                {
                  label: "Quantity",
                  value: "QUANTITY",
                  width: 1
                },
                {
                  label: "Total Amount",
                  value: "TOTAL_AMOUNT",
                  width: 1,
                  format: (value) => toDollars(value, true),
                }
              ]}
              data={row.numbers.PRODUCT_TOTALS}
            />
          ),
        }
      ))
    }

    return rows;
  };

  render() {
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Team Sales Report"} description={"Items sold while employee was clocked-in"}/>
        </div>

        <FixedColumnReport
          filenamePrefix="team-sales"
          endpoint="report/team-sales"
          constantColumns={[]}
          convertDataToRows={this.convertDataToRows}
          expandable={true}
          hideCSVLink={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(
  withRouter(TeamSalesReportPage)
);
