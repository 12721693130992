import React, {Component} from "react";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class StatusLabel extends Component {
  render() {
    const {label, type} = this.props;

    let colorClasses = "bg-green-100 text-green-800";
    if (type === "warning") {
      colorClasses = "bg-yellow-100 text-yellow-800";
    } else if (type === "error") {
      colorClasses = "bg-red-100 text-red-800"
    }

    return (
      <span
        className={classNames(
          "inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
          colorClasses
        )}
      >
        {label}
      </span>
    );
  }
}

StatusLabel.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error"]),
  label: PropTypes.string,
};

export default StatusLabel;
