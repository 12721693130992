import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import ModifierModal from "../../../../modals/sales/modifiers/modifier-modal";
import {Formik} from "formik";

import {
  Card,
  FormInput,
  FormSelect,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {updateStateDelegator} from "../../../../utils/util";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Modifier from "../../../../features/product/modifier";
import PresetModal from "../../../../modals/sales/modifiers/preset-modal";
import ProductDetailsModal from "../../../../modals/sales/product/product-details-modal";
import ProductIngredientsTab from "./tabs/product-ingredients-tab";
import ProductAvailabilityTab from "./tabs/product-availability-tab";
import {
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../../utils/alert-helper";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import PricingModifierModal from "../../../../modals/sales/modifiers/pricing-modifier-modal";
import {setupReduxConnection} from "../../../../redux";
import SelectProductDiscountModal from "../../../../modals/sales/product/select-product-discount-modal";
import ProductDiscountDetailsModal from "../../../../modals/sales/discounts/product-discount-details-modal";
import ModifiersTable from "../../../../tables/sales/modifiers-table";
import ProductSyncModal from "../../../../modals/sales/product/product-sync-modal";
import TaxesTable from "../../../../tables/sales/taxes-table";
import ProductTaxModal from "../../../../modals/sales/tax/product-tax-modal";
import BarcodeModal from "../../../../modals/sales/product/barcode-modal";

class ProductPage extends Component {
  state = {
    product: null,
    modifiers: [],
    productDiscounts: [],
    productTags: [],
    taxes: [],
  };

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({product: null}, () => {
        this.syncState();
      });
    }
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const product = await request("product/" + id, "GET", null);

    const productTags = await request(
      "tags/product/" + product.ID,
      "GET",
      null
    );

    const taxes = await request("tax/product/" + product.ID, "GET", null);

    this.setState({
      taxes,
      product,
      productTags,
      modifiers: product.CUSTOMIZATIONS,
      productDiscounts: product.PRODUCT_DISCOUNTS,
    });

    // await request("sync/products/" + product.ID, "GET", null);
  }

  duplicateProduct() {
    const {product} = this.state;

    showLoadingConfirmAlert(
      "Duplicate Product",
      "Are you sure you want to duplicate this product?"
    )
      .then((close) => {
        request("product/duplicate/" + product.ID, "POST", {})
          .then((newProd) => {
            this.props.router.navigate("/product/" + newProd.UNIQUE_ID);
            close();
          })
          .catch((err) => {
            close();
            console.log(err);

            showErrorAlert(
              "Could Not Duplicate",
              "Oops! Something went wrong while trying to duplicate this product. Please contact support for help!",
              "Ok"
            );
          });
      })
      .catch(() => {});
  }

  async deleteProduct() {
    const {product} = this.state;

    showLoadingConfirmAlert(
      "Delete Product",
      "Are you sure you want to delete this product?"
    ).then(async (close) => {
      await request("product/" + product.ID, "DELETE", {});

      this.props.router.navigate("/products");

      close();

      showSuccessNotification("Product Delete", "We have deleted the product");
    });
  }

  async addProductDiscount(discountId) {
    const {product, productDiscounts} = this.state;

    const productDiscount = await request(
      `product/discount/${discountId}`,
      "POST",
      {PRODUCTS: [product.ID]}
    );

    if (productDiscount.length === 1) {
      const newProductDiscounts = [...productDiscounts, productDiscount[0]];

      this.setState({
        product: {...product, PRODUCT_DISCOUNTS: newProductDiscounts},
        productDiscounts: newProductDiscounts,
      });
    }
  }

  async saveOrderingModifiers() {
    const {product, modifiers} = this.state;

    const serverModifiers = await request(
      "modifiers/product/" + product.ID,
      "PUT",
      modifiers
    );

    this.setState({
      product: {...product, CUSTOMIZATIONS: serverModifiers},
      modifiers: serverModifiers,
    });
  }

  async removeVariant() {
    showLoadingConfirmAlert(
      "Remove Sized Pricing",
      "Are you sure you want to delete this products sized pricing?"
    ).then(async (close) => {
      this.variantButton.startLoading();

      await this.savePricing({...this.formikRef.values, type: "0"});
      this.formikRef.setFieldValue("variant", null);
      this.formikRef.setFieldValue("type", "0");

      close();
    });
  }

  async savePricing({price, type, weightPrice}) {
    const {product, modifiers} = this.state;

    this.pricingButton.startLoading();

    const productVariantIndex = modifiers.findIndex((item) => {
      return item.TYPE === 5;
    });

    const toSaveObject = {
      PRICE: decimalToDollars(price),
      VARIABLE: parseInt(type) === 1 ? 1 : 0,
      WEIGHT_UNIT_COST:
        parseInt(type) === 3 && weightPrice
          ? decimalToDollars(weightPrice)
          : null,
    };

    await request("products/" + product.ID, "PATCH", toSaveObject);

    if (parseInt(type) === 2) {
      this.variantRef.outsideSave();
    } else if (productVariantIndex !== -1) {
      const productModifiers = modifiers;
      const productVariant = productModifiers[productVariantIndex];

      if (productVariant.PRESET_ID) {
        await request(
          `preset/${productVariant.ID}/${product.ID}/remove`,
          "POST",
          {}
        );
      } else if (productVariant.ID) {
        await request("customization/" + productVariant.ID, "DELETE", {});
      }

      productModifiers.splice(productVariantIndex, 1);

      this.setState(
        {product: {...product, CUSTOMIZATIONS: productModifiers}},
        () => {
          this.formikRef.setFieldValue("variant", null);
        }
      );
    } else {
      product.PRICE = decimalToDollars(price);
      this.setState({product: {...product}}, () => {
        this.formikRef.setFieldValue("variant", null);
      });
    }

    showSuccessNotification(
      "Product Pricing Saved",
      `The pricing for ${product.NAME} has been saved`
    );

    await this.syncState();
    this.pricingButton.stopLoading();
  }

  deleteRate(tax) {
    const {taxes} = this.state;

    showLoadingConfirmAlert(
      "Archive Product Tax Rate",
      "Are you sure you want to delete this tax rate?"
    ).then(async (close) => {
      await request("tax/product/" + tax.ID, "DELETE", null);

      this.setState({
        taxes: updateStateDelegator(taxes, tax.ID),
      });

      close();
    });
  }

  fetchTaxRate() {
    const {product} = this.state;

    const rate = product.TAX_RATES.reduce((accum, item) => {
      if (item.IS_INCLUSIVE) {
        return accum;
      }

      return accum + item.RATE;
    }, 0);

    return rate / 100;
  }

  renderPricingContent(type, formikOptions) {
    const {location} = this.props.shop;
    const {product, modifiers} = this.state;

    if (parseInt(type) === 0) {
      const price = decimalToDollars(formikOptions.values.price);

      return (
        <>
          <FormInput
            name="price"
            options={formikOptions}
            label="Product Price"
          />

          <FormInput
            disabled
            label="Product Price w/ Tax"
            value={toDollars(price + Math.round(price * this.fetchTaxRate()))}
          />
        </>
      );
    }

    if (parseInt(type) === 1) {
      const price = decimalToDollars(formikOptions.values.price);

      return (
        <>
          <FormInput name="price" options={formikOptions} label="Base Price" />

          <FormInput
            disabled
            label="Variable Base Price w/ Tax"
            value={toDollars(
              price + Math.round(price * (location.TAX_RATE / 100))
            )}
          />
        </>
      );
    }

    if (parseInt(type) === 2) {
      const variantIndex = modifiers.findIndex((item) => item.TYPE === 5);
      const variant = modifiers[variantIndex];

      if (variant) {
        return (
          <div>
            <Modifier
              variant
              product={product}
              modifier={variant}
              ref={(e) => (this.variantRef = e)}
              updateState={(_, variant) => {
                modifiers.splice(variantIndex, 1, variant);

                this.setState({modifiers});
              }}
              addState={(variant) => {
                modifiers.splice(variantIndex, 1, variant);

                this.setState({modifiers});
              }}
            />
          </div>
        );
      }
    }

    if (parseInt(type) === 3) {
      return (
        <div>
          <FormInput
            name="price"
            placeholder="2.50"
            options={formikOptions}
            label="Base Price"
            tooltip={{
              label: "Base Price",
              data: "This is the base price of the product. The total cost of the product will be this price plus the weighted price.",
            }}
          />

          <FormInput
            name="weightPrice"
            placeholder={"0.10"}
            options={formikOptions}
            label="Price Per Unit Weight"
            tooltip={{
              label: "Weighted Price",
              data: "This is the price per unit of weight. The total cost of the product will be this price times the weight of the item plus the base price. ",
            }}
          />
        </div>
      );
    }

    return <div />;
  }

  renderPricing() {
    const {product, modifiers} = this.state;

    let productType = product.VARIABLE ? 1 : 0;
    const productVariant = modifiers.find((item) => {
      return item.TYPE === 5;
    });

    if (productVariant) {
      productType = 2;
    }

    if (product.WEIGHT_UNIT_COST !== null) {
      productType = 3;
    }

    const initialValues = {
      global: "premade",
      variant: productVariant ?? null,
      price: toDollars(product.PRICE),
      type: productType,
      weightPrice:
        productType === 3 ? toDollars(product.WEIGHT_UNIT_COST) : null,
    };

    // if (productVariant?.PRESET) {
    //   initialValues.global = "custom";
    //   initialValues.variant = productVariant.ID
    // }

    return (
      <Formik
        initialValues={initialValues}
        innerRef={(e) => (this.formikRef = e)}
        onSubmit={this.savePricing.bind(this)}
      >
        {(formikOptions) => {
          const {values, handleSubmit} = formikOptions;

          return (
            <Card
              label="Pricing"
              buttons={[
                ...(values.variant
                  ? [
                      {
                        label: "Remove Sized Pricing",
                        ref: (e) => (this.variantButton = e),
                        onClick: () => this.removeVariant(),
                      },
                    ]
                  : []),
                {
                  label: "Save Pricing",
                  ref: (e) => (this.pricingButton = e),
                  onClick: handleSubmit,
                },
              ]}
              tooltip={{
                data: [
                  {
                    label: "Pricing Modifiers",
                    data: "The pricing type determines how the price of a product is ultimately calculated. There are a few options for the pricing type.",
                  },
                  {
                    label: "Fixed Pricing",
                    data: "Fixed priced items are given a single base price. Modifiers added to this type of item are summed to form the final price.",
                  },
                  {
                    label: "Variable Pricing",
                    data: "Variable priced items are assigned a price at checkout on the POS. These should not be used on kiosk, mobile app or order website.",
                  },
                  {
                    label: "Sized Pricing",
                    data: "Sized priced items have different prices based on the size of the item. You may customize the available sizes and their prices. Sizes are displayed as a required modifier to the item.",
                  },
                  {
                    label: "Weight Pricing",
                    data: "Weight priced items are given a base price and a price per unit. At checkout, a weight must be given on the POS. The final price of the item will be the base price + the price per unit * the weight + price of any modifiers.",
                  },
                ],
              }}
            >
              <div className="p-2 sm:p-6">
                <FormSelect
                  name="type"
                  secondaryBlock
                  onChangeSoft={({value}) => {
                    if (parseInt(value) === 2) {
                      if (values.variant === null) {
                        this.pricingModal.open();
                      }
                    }
                  }}
                  options={formikOptions}
                  label="Pricing Type"
                  data={[
                    {
                      value: "0",
                      label: "Fixed Pricing",
                      secondary: "Base price of the product is a fixed rate",
                    },
                    {
                      value: "1",
                      label: "Variable Pricing",
                      secondary:
                        "Price will be set at checkout on the point of sale. Should not be used for mobile products.",
                    },
                    {
                      value: "3",
                      label: "Weight Pricing",
                      secondary: "Price is based on weight of the product",
                    },
                    {
                      value: "2",
                      label: "Sized Pricing",
                      secondary:
                        "Set multiple sizes that will change the default price of the product",
                    },
                  ]}
                />

                {this.renderPricingContent(values.type, formikOptions)}
              </div>
            </Card>
          );
        }}
      </Formik>
    );
  }

  renderModifiers() {
    const {product, modifiers} = this.state;

    return (
      <>
        <PresetModal ref={(e) => (this.presetDropdown = e)} />

        <Card
          label="Modifiers"
          buttons={[
            {
              theme: "primary",
              label: "Add Modifier",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "custom") {
                  this.modifierModal.open(
                    {
                      ID: null,
                      TYPE: 0,
                      REQUIRED: 1,
                      ENABLED: 1,
                      OPTIONS: [
                        {NAME: "", PRICE: "", ENABLED: 1, INGREDIENTS: []},
                      ],
                    },
                    product
                  );
                }

                if (id === "global") {
                  this.presetModal.open();
                }
              },
              items: [
                {
                  id: "global",
                  label: "Global Modifier",
                  description:
                    "Use a modifier that was created to be used globally.",
                },
                {
                  id: "custom",
                  label: "Custom",
                  description:
                    "A modifier that will only be used for this product.",
                },
              ],
            },
          ]}
        >
          <ModifiersTable
            onDrag={() => this.saveOrderingModifiers()}
            onClick={(item) => this.modifierModal.open(item, product)}
            data={modifiers.filter((item) => item.TYPE !== 5)}
            editOnClick={(item) => this.modifierModal.open(item, product)}
          />
        </Card>
      </>
    );
  }

  renderProductDiscounts() {
    const {product, productDiscounts} = this.state;

    return (
      <>
        <Card
          label="Product Discounts"
          description="Discounts that can be applied to this product on the POS"
          tooltip={{
            data:
              "These discounts are available for selection on this products page on the POS. When selected, this " +
              "discount will be applied as a discount for the product only, not the entire ticket.",
          }}
          buttons={[
            {
              theme: "primary",
              label: "Add Discount",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "add") {
                  this.selectDiscountModal.open();
                }

                if (id === "create") {
                  this.discountDetailsModal.open();
                }

                if (id === "test") {
                  console.log(product.ID);
                  request("product/scan/" + product.ID, "POST")
                    .then((p) => console.log(p))
                    .catch((err) => console.log(err));
                }
              },
              items: [
                {
                  id: "add",
                  label: "Add Existing Discount",
                  description:
                    "Add an existing Product Discount to this product.",
                },
                {
                  id: "create",
                  label: "Create New Product Discount",
                  description:
                    "Create a new Product Discount and add it to this product.",
                },
              ],
            },
          ]}
        >
          <Table
            verticalLines
            classNames="mt-0"
            onClick={(item) => this.discountDetailsModal.open(item, 1, product)}
            data={productDiscounts}
            actionButtons={[
              {
                label: "Edit",
                onClick: (item) =>
                  this.discountDetailsModal.open(item, 1, product),
              },
            ]}
            ref={(e) => (this.discountsTable = e)}
            columns={[
              {
                value: "NAME",
                label: "Name",
                width: "200px",
                format: (value, _) => {
                  return (
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {value}
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              {
                width: "40px",
                value: "TYPE",
                label: "Type",
                mobile: "sm:table-cell",
                format: (value, _) => {
                  return (
                    <div className="text-sm font-medium text-gray-900">
                      {value === 0 ? "Flat" : "Percentage"}
                    </div>
                  );
                },
              },
              {
                width: "60px",
                value: "CONTENT",
                label: "Value",
                mobile: "sm:table-cell",
                format: (value, row) => {
                  return (
                    <div className="text-sm font-medium text-gray-900">
                      {row.TYPE === 0 ? `$${toDollars(value)}` : `${value}%`}
                    </div>
                  );
                },
              },
            ]}
          />
        </Card>
      </>
    );
  }

  renderBarcode() {
    const {product} = this.state;

    return (
      <TwoColumnList
        label="Barcode"
        description="Set the barcode associated with this product"
        tooltip={{
          label: "Barcode",
          data:
            "Each product can be assigned a barcode. Setting a product's barcode will allow you to use your " +
            "Dashboard App to scan that product adding it to the cart. In order to do this you must have the Barcode" +
            "Scanner Host Device setting set to the device you wish to have the items added to the cart on.",
        }}
        data={[
          {
            label: "Barcode",
            value: product.BARCODE || "None",
          },
        ]}
        buttons={[
          {
            label: "Edit",
            onClick: () => this.barcodeModal.open(product),
          },
        ]}
      />
    );
  }

  render() {
    const {product, modifiers, productDiscounts, productTags, taxes} =
      this.state;

    const exclude = productDiscounts?.map((item) => {
      return item.DISCOUNT_ID;
    });

    if (product === null) {
      return <Loading />;
    }

    let tags = "";
    productTags.forEach((tag, i) => {
      if (i !== productTags.length - 1) {
        tags += `${tag.NAME}, `;
      } else {
        tags += `${tag.NAME}`;
      }
    });

    return (
      <div>
        <ProductDetailsModal
          ref={(e) => (this.productDetailModal = e)}
          updateProduct={async () => {
            await this.syncState();
          }}
          initialTags={productTags.map((i) => i.TAG_ID)}
        />

        <BarcodeModal
          ref={(e) => (this.barcodeModal = e)}
          updateProduct={(barcode) => {
            this.setState({product: {...product, BARCODE: barcode}});
          }}
        />

        <ProductTaxModal
          ref={(e) => (this.taxModal = e)}
          addState={(tax) => this.setState({taxes: [...taxes, tax]})}
          updateState={(id) =>
            this.setState({
              taxes: updateStateDelegator(taxes, id),
            })
          }
          exclude={taxes.map((tax) => tax.TAX_ID)}
        />

        <SelectProductDiscountModal
          ref={(e) => {
            this.selectDiscountModal = e;
          }}
          updateState={(values, callback) => {
            const {discount} = values;
            this.addProductDiscount(discount).then(() => callback());
          }}
          exclude={exclude}
        />

        <ProductDiscountDetailsModal
          ref={(e) => (this.discountDetailsModal = e)}
          updateState={({productDiscount}) => {
            this.setState({
              productDiscounts: updateStateDelegator(
                productDiscounts,
                productDiscount.ID,
                productDiscount
              ),
            });
          }}
          addState={async (item) => {
            await this.addProductDiscount(item.ID);
          }}
          onDelete={() => {
            this.syncState();
          }}
        />

        <ModifierModal
          product={product}
          updateState={(id, item) => {
            this.setState({
              modifiers: updateStateDelegator(modifiers, id, item),
            });
          }}
          addState={(item) => {
            const serverModifiers = JSON.parse(JSON.stringify(modifiers));

            serverModifiers.push(item);

            const serverProduct = JSON.parse(JSON.stringify(product));
            serverProduct.CUSTOMIZATIONS = serverModifiers;

            this.setState({
              modifiers: serverModifiers,
              product: serverProduct,
            });
          }}
          ref={(e) => (this.modifierModal = e)}
        />

        <PresetModal
          ref={(e) => (this.presetModal = e)}
          exclude={modifiers.map((item) => item.ID)}
          updateState={async ({type, modifier}, close) => {
            const serverModifier = await request(
              "v2/preset/" + modifier + "/product/" + product.ID,
              "POST",
              {}
            );

            modifiers.push(serverModifier);

            this.setState({modifiers}, () => {
              close();
            });
          }}
        />

        <ProductSyncModal ref={(e) => (this.syncModal = e)} />

        <PricingModifierModal
          ref={(e) => (this.pricingModal = e)}
          clearState={() => this.formikRef.setFieldValue("type", "0")}
          updateState={async ({type, modifier}, close) => {
            if (type === "custom") {
              const variantPayload = {
                ID: null,
                NAME: "Sizes",
                MAX_SELECTIONS: 0,
                MIN_SELECTIONS: 0,
                INTERNAL_NAME: product.NAME + " Sizes",
                DEFAULT_OPTION: null,
                TYPE: 5,
                REQUIRED: 1,
                ENABLED: 1,
                OPTIONS: [],
                PRESET: 0,
              };

              modifiers.push(variantPayload);

              this.setState({modifiers}, () => {
                this.formikRef.setFieldValue("variant", variantPayload);

                close();
              });
            } else {
              const serverModifier = await request(
                "v2/preset/" + modifier + "/product/" + product.ID,
                "POST",
                {}
              );

              modifiers.push(serverModifier);

              this.setState({modifiers}, () => {
                this.formikRef.setFieldValue("variant", serverModifier);

                close();
              });
            }
          }}
        />

        <PageHeadings
          logo={getObjectImage(product, "LOGO", "logo_mark-01.jpg")}
          logoCover={true}
          label={product.NAME}
          status={{label: "Active"}}
          description={product.CATEGORY_NAME}
          buttons={[
            // {
            //   label: "Sync Product",
            //   onClick: () => this.syncModal.open(product),
            // },
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Duplicate",
                      onClick: () => {
                        this.duplicateProduct();
                      },
                    },
                  ],
                },
                {
                  items: [
                    {label: "Delete", onClick: () => this.deleteProduct()},
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Menu Page", url: "/menu"},
            {label: "Products Page", url: "/products"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.productDetailModal.open(product);
              },
            },
          ]}
          label="Product Information"
          description="Information about this product"
          data={[
            {
              label: "Name",
              value: product.NAME,
            },
            {
              label: "Category",
              value: product.CATEGORY_NAME,
              link: "/category/" + product.CATEGORY_UNIQUE_ID,
            },
            {
              label: "Point of Sale Enabled",
              value: product.POS_ENABLED,
              type: "bool",
            },
            {
              label: "Mobile Ordering Enabled",
              value: product.ENABLED,
              type: "bool",
            },
            {
              label: "Kiosk Ordering Enabled",
              value: product.KIOSK_ENABLED,
              type: "bool",
            },
            {
              label: "Third Party Enabled",
              value: product.THIRD_PARTY_ENABLED,
              type: "bool",
            },
            {
              label: "Disable Tax",
              value: product.TAX_DISABLED,
              type: "bool",
            },
            {
              label: "Tax Override",
              value: product.TAX_OVERRIDE,
              type: "bool",
              tooltip: {
                label: "Tax Override",
                data:
                  "If this is set to yes, then taxes will be applied to this product even if it is ordered with a" +
                  " tax disabled order type. If taxes are explicitly toggled off with the tax toggle on the POS," +
                  " then taxes will not be charged.",
              },
            },
            {
              label: "One click add on POS",
              value: product.ONE_CLICK,
              type: "bool",
            },
            {
              label: "Is Alcohol",
              value: product.ALCOHOLIC,
              type: "bool",
            },
            {
              label: "Advanced notice",
              value: (product.ADVANCED_TIME || "0") + " days",
            },
            {
              label: "Minimum Quantity",
              value: product.MIN_QTY ?? "-",
              tooltip:
                "The minimum quantity that must be ordered for mobile and web orders. This does not impact POS orders.",
            },
            {
              label: "Maximum Quantity",
              value: product.MAX_QTY ?? "-",
              tooltip:
                "The maximum quantity that can be ordered for a single mobile or web order. This does not impact POS orders.",
            },
            {
              label: "Auto complete product",
              tooltip:
                "If a product has this field set to yes, then this product will be automatically completed on purchase. This setting only applies to orders placed on the register.",
              value: product.AUTO_COMPLETE,
              type: "bool",
            },
            {label: "Product Tags", value: tags, hide: true},
            {
              label: "Description",
              value: product.DESCRIPTION,
              span: true,
            },
            {
              label: "Preparation instructions",
              value: product.INSTRUCTIONS,
              span: true,
              hide: true,
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Pricing", id: "pricing"},
            {label: "Modifiers", id: "modifiers"},
            {label: "Ingredients", id: "ingredients"},
            {label: "Availability", id: "availability"},
            {label: "Discounts", id: "discounts"},
            {label: "Taxes", id: "taxes"},
            {label: "Barcode", id: "barcode"},
          ]}
        >
          {(id) => {
            if (id === "taxes") {
              return (
                <Card
                  label="Tax Rates"
                  description="The default tax rates that will get applied this product"
                  button={{
                    label: "Add Tax Rate",
                    onClick: () => this.taxModal.open(product),
                  }}
                >
                  <TaxesTable
                    taxes={taxes.map((item) => ({
                      ...item.TAX_RATE,
                      ID: item.ID,
                    }))}
                    actionButtons={[
                      {
                        label: "Delete Rate",
                        onClick: (tax) => this.deleteRate(tax),
                      },
                      {
                        label: "View Tax Rate",
                        onClick: (row) =>
                          this.props.router.navigate("/tax/" + row.UNIQUE_ID),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "ingredients") {
              return <ProductIngredientsTab product={product} />;
            }

            if (id === "pricing") {
              return this.renderPricing();
            }

            if (id === "modifiers") {
              return this.renderModifiers();
            }

            if (id === "availability") {
              return <ProductAvailabilityTab product={product} />;
            }

            if (id === "discounts") {
              return this.renderProductDiscounts();
            }

            if (id === "barcode") {
              return this.renderBarcode();
            }

            return <div />;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(ProductPage));
