import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormCheck,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import EmployeeDropdown from "../../dropdowns/team/employee-dropdown";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import FormStateSelect from "../../components/form-state-select";
import {CARD_TYPES} from "../../utils/finance-constants";

class CreateCardModal extends Component {
  state = {shipToLocation: 1};

  open = () => {
    this.slide.open();
  };

  handleSubmit = async (values) => {
    let {type, employeeID, line1, line2, city, zipcode, state} = values;
    let {shipToLocation} = this.state;

    if (type === CARD_TYPES.PHYSICAL) {
      await request("banking2/cards/physical", "POST", {
        EMPLOYEE_ID: employeeID,
        SHIP_TO_LOCATION: shipToLocation,
        LINE_1: line1,
        LINE_2: line2,
        CITY: city,
        ZIPCODE: zipcode,
        STATE: state
      });
    } else if (type === CARD_TYPES.VIRTUAL) {
      await request("banking2/cards/virtual", "POST", {
        EMPLOYEE_ID: employeeID,
      });
    }

    this.slide.close();
    await this.props.handleNewCard();
  };

  render() {
    let {NAME, ADDRESS, CITY, ZIPCODE, STATE} = this.props.shop.location;
    let {shipToLocation} = this.state;

    return (
      <Modal
        label="Create Card"
        buttonLabel="Create"
        buttonOnClick={() => this.formikRef.submitForm()}
        ref={(e) => (this.slide = e)}
      >
        <Formik
          initialValues={{
            type: "physical",
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {type} = formikOptions.values;

            return (
              <div>
                <div className="text-sm font-semibold text-gray-600">
                  Billing Address will be {NAME}'s address
                </div>

                <div className="text-sm font-semibold mb-2 text-gray-600">
                  Cardholder will be employee's name
                </div>

                <FormSelect
                  label="Card Type"
                  name="type"
                  data={[
                    {label: "Physical", value: CARD_TYPES.PHYSICAL},
                    {label: "Virtual", value: CARD_TYPES.VIRTUAL},
                  ]}
                  options={formikOptions}
                />

                <EmployeeDropdown name="employeeID" options={formikOptions} />

                {type === "physical" && (
                  <div className="mt-3">
                    <div className="text-sm font-semibold mb-2">
                      Shipping Information
                    </div>

                    <FormCheck
                      name="ship"
                      label={
                        <div className="flex flex-col">
                          <div>{`Ship to ${NAME}`}</div>
                          <div>{`(${ADDRESS}, ${CITY} ${STATE} ${ZIPCODE})`}</div>
                        </div>
                      }
                      value={shipToLocation}
                      onChange={() => {
                        this.setState((prevState) => ({
                          shipToLocation: !prevState.shipToLocation,
                        }));
                      }}
                    />

                    {!shipToLocation && (
                      <div>
                        <FormInput name={"line1"} label={"Address Line 1"} options={formikOptions} />

                        <FormInput name={"line2"} label={"Address Line 2"} options={formikOptions} />

                        <div className={"flex flex-row justify-between"}>
                          <FormInput
                            name={"city"}
                            label={"City"}
                            options={formikOptions}
                          />

                          <FormStateSelect
                            name={"state"}
                            label={"State"}
                            options={formikOptions}
                          />

                          <FormInput className="w-24" name={"zipcode"} label={"Postal Code"} options={formikOptions} />
                        </div>

                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}



export default setupReduxConnection(["shop"])(CreateCardModal);
