import React, {Component} from "react";
import FormElement from "./../form/form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';

class FormCurrency extends Component {
  focus() {
    this.ref.focus();
  }

  render() {
    const {name, placeholder} = this.props;

    return (
      <FormElement
        {...this.props}
      >
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <NumberFormat
              getInputRef={(e) => this.ref = e}
              type="number"
              id={name}
              name={name}
              value={value}
              onBlur={onBlur}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e);

                setTimeout(() => {
                  onChangeSoft && onChangeSoft(e.target.value);
                }, 1);
              }}
              className={classNames(
                error
                  ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                "block w-full pr-10 focus:outline-none sm:text-sm rounded-md",
                extraStyle
              )}
              aria-describedby="email-error"
              aria-invalid="true"
            />
          );
        }}
      </FormElement>
    );
  }
}

FormCurrency.propTypes = {
  ...FormElement.propTypes,

  placeholder: PropTypes.string,
};

export default FormCurrency;
