import React, {Component} from "react";
import {request} from "../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import {
  FormInput,
  FormSelect,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";

class AutoclockModal extends Component {
  state = {
    card: null,
  };

  open(card) {
    this.setState({card}, () => this.modal.open());
  }

  close() {
    this.modal.close();
  }

  async submitForm(values) {
    let {date, dateStart: start, dateEnd: end} = values;
    const {card} = this.state;

    start = parseInt(start.split(":")[0] * 60) + parseInt(start.split(":")[1]);
    end = parseInt(end.split(":")[0] * 60) + parseInt(end.split(":")[1]);

    if (end && end < start) {
      end += 1440;
    }

    const dateEnd = moment(date).add(end, "minutes").valueOf();
    const dateStart = moment(date).add(start, "minutes").valueOf();

    const timecardPayload = {
      EMPLOYEE_ID: card.EMPLOYEE_ID,
      DATE_START: dateStart,
      DATE_END: dateEnd,
      ROLE_ID: card.ROLE_ID,
      LOCATION_ID: card.LOCATION_ID,
      APPROVED: 1,
    };

    await request("team/timesheet/" + card.ID, "PATCH", timecardPayload);

    let {cards} = this.props;
    const cardIndex = cards.findIndex((item) => item.ID === card.ID);

    if (cardIndex !== -1) {
      cards.splice(cardIndex, 1);
    }

    this.props.updateCards(cards);

    this.close();
  }

  render() {
    let {card} = this.state;

    let initialValue = {
      date: card
        ? moment(card.DATE_START).startOf("day")
        : moment().startOf("day"),
      dateStart: card ? moment(card.DATE_START).format("HH:mm") : undefined,
      dateEnd: card ? moment(card.DATE_END).format("HH:mm") : undefined,
    };

    return (
      <Modal
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        label={"Confirm Time Card"}
        description=""
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={(values) => this.submitForm(values)}
          initialValues={initialValue}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div className="flex mb-5 flex-col">
                  <FormDateTimeSelect
                    name="date"
                    label={"Date"}
                    options={formikOptions}
                    buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                    hideTime={true}
                  />
                  <div className="flex flex-row">
                    <FormInput
                      type="time"
                      name="dateStart"
                      label="Start"
                      options={formikOptions}
                    />

                    <div className=" mx-2"></div>

                    <FormInput
                      type="time"
                      name="dateEnd"
                      label="End"
                      options={formikOptions}
                    />
                  </div>
                </div>

                <div className="text-xs italic text-zinc-500">
                  Note: Shift times are limited to 24 hours
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AutoclockModal;

const validationSchema = Yup.object().shape({
  dateStart: Yup.string().required(),
  dateEnd: Yup.string().required(),
});
