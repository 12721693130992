import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";
import PropTypes from "prop-types";

class ChecklistModal extends Component {
  state = {checklist: null};

  open(checklist) {
    this.setState({checklist}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createChecklist({duration, employee, name, pin, role}) {
    const checklistPayload = {
      NAME: name,
      DURATION: duration,
      EMPLOYEE_ID: employee,
      REQUIRE_PIN: pin,
      ROLE_ID: role,
    };

    const serverChecklist = await request(
      "checklists/",
      "POST",
      checklistPayload
    );

    this.props.addState(serverChecklist);
    this.modal.close();
  }

  async saveChecklist({pin, duration, employee, status, name, role}) {
    const {checklist} = this.state;

    const checklistPayload = {
      NAME: name,
      DURATION: duration,
      ENABLED: status,
      EMPLOYEE_ID: employee,
      REQUIRE_PIN: pin,
      ROLE_ID: role,
    };

    const serverChecklist = await request(
      "checklists/" + checklist.ID,
      "PATCH",
      checklistPayload
    );

    this.props.updateState(serverChecklist);
    this.modal.close();
  }

  render() {
    const {checklist} = this.state;

    return (
      <Modal
        label={checklist ? "Edit Checklist" : "Create Checklist"}
        buttonLabel={checklist ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            checklist
              ? this.saveChecklist.bind(this)
              : this.createChecklist.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: checklist?.NAME ?? "",
            duration: checklist?.DURATION ?? "60",
            status: checklist?.ENABLED ?? "1",
            employee: checklist?.EMPLOYEE_ID,
            employeeName: checklist?.EMPLOYEE_NAME,
            role: checklist?.ROLE_ID,
            roleName: checklist?.ROLE_NAME,
            pin: checklist?.REQUIRE_PIN,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  placeholder="Avery's Checklist"
                  options={formikOptions}
                  label="Name"
                  name="name"
                />

                <FormInput
                  tooltip="How long do employees have to complete the checklist"
                  label="Duration (minutes)"
                  name="duration"
                  options={formikOptions}
                />

                <FormBoolean
                  name="pin"
                  label="Require Employee Pin"
                  options={formikOptions}
                />

                {checklist && (
                  <FormBoolean
                    label="Active"
                    name="status"
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChecklistModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default ChecklistModal;
