import {
  FormBoolean,
  Modal,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class TippingModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {
      tipsEnabled,
      noTipDisplayEnabled,
      tipOption1,
      tipOption2,
      tipOption3,
      defaultTipValue,
    } = values;

    let payload = {
      TIP_ENABLED: tipsEnabled,
      NO_TIP_DISPLAY_ENABLED: noTipDisplayEnabled,
      TIP_OPTIONS: JSON.stringify([tipOption1, tipOption2, tipOption3]),
      DEFAULT_TIP_VALUE: defaultTipValue,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {TIP_ENABLED, NO_TIP_DISPLAY_ENABLED, TIP_OPTIONS, DEFAULT_TIP_VALUE} =
      this.props.shop.settings;

    TIP_OPTIONS = JSON.parse(TIP_OPTIONS);

    return (
      <Modal
        label="Tipping Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            tipsEnabled: TIP_ENABLED,
            noTipDisplayEnabled: NO_TIP_DISPLAY_ENABLED,
            tipOption1: TIP_OPTIONS[0],
            tipOption2: TIP_OPTIONS[1],
            tipOption3: TIP_OPTIONS[2],
            defaultTipValue: DEFAULT_TIP_VALUE,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="tipsEnabled"
                  label="Tips Enabled"
                  options={formikOptions}
                />

                <FormBoolean
                  name="noTipDisplayEnabled"
                  label="Display No Tips Option"
                  options={formikOptions}
                />

                <FormInput
                  label={"Tip Option 1"}
                  name="tipOption1"
                  options={formikOptions}
                />

                <FormInput
                  label={"Tip Option 2"}
                  name="tipOption2"
                  options={formikOptions}
                />

                <FormInput
                  label={"Tip Option 3"}
                  name="tipOption3"
                  options={formikOptions}
                />

                <FormInput
                  label={"Default Tip Value"}
                  name={"defaultTipValue"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  TippingModal
);
