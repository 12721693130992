import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  FormEmail,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormDateSpecify from "../../components/form-date-specify";
import FormStateSelect from "../../components/form-state-select";
import {createPayrollEmployee} from "../../utils/payroll-helper";
import {request} from "../../utils/request";

class PayrollEmployeePortalInformationModal extends Component {
  state = {error: null};

  open() {
    this.setState({error: null});

    this.slide.open();
  }

  handleClick = () => {
    this.formikRef.submitForm();
  };

  submitForm = async (values) => {
    let {id} = this.props.checkEmployee;

    let {
      firstName,
      lastName,
      month,
      day,
      year,
      line1,
      line2,
      city,
      state,
      zip,
    } = values;

    let payload = {
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      MONTH: month,
      DAY: day,
      YEAR: year,
      LINE_1: line1,
      LINE_2: line2,
      CITY: city,
      STATE: state,
      ZIP: zip,
    };

    try {
      await request(`payroll/check-employee/${id}`, "PATCH", payload);
      this.props.refresh();
      this.slide.close();
    } catch (e) {
      this.setState({error: "Please review your information and try again"});
      return false;
    }
  };

  render() {
    let {error} = this.state;
    let {checkEmployee} = this.props;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Personal Information`}
        buttonLabel={"Save"}
        buttonOnClick={this.handleClick}
        disableButtonLoading={true}
      >
        <Formik
          initialValues={{
            firstName: checkEmployee?.first_name,
            lastName: checkEmployee?.last_name,
            month: checkEmployee?.dob.split("-")[1],
            day: checkEmployee?.dob.split("-")[2],
            year: checkEmployee?.dob.split("-")[0],
            line1: checkEmployee?.residence?.line1,
            line2: checkEmployee?.residence?.line2,
            city: checkEmployee?.residence?.city,
            state: checkEmployee?.residence?.state,
            zip: checkEmployee?.residence?.postal_code,
          }}
          enableReinitialize={true}
          onSubmit={this.submitForm}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div>
                {error && <div className={"text-red-600"}>{error}</div>}

                <FormInput
                  name={"firstName"}
                  label={"Legal First Name"}
                  options={formikOptions}
                />

                <FormInput
                  name={"lastName"}
                  label={"Legal Last Name"}
                  options={formikOptions}
                />

                <div className="flex flex-row">
                  <FormInput
                    name={"month"}
                    className="w-14"
                    placeholder={"MM"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"day"}
                    className="w-14 ml-5"
                    placeholder={"DD"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"year"}
                    className="w-16 ml-5"
                    placeholder={"YYYY"}
                    options={formikOptions}
                  />
                </div>

                <FormInput
                  name={"line1"}
                  label={"Address Line 1"}
                  options={formikOptions}
                />

                <FormInput
                  name={"line2"}
                  label={"Address Line 2"}
                  options={formikOptions}
                />

                <div className="flex flex-row">
                  <FormInput
                    name={"city"}
                    label={"City"}
                    options={formikOptions}
                  />

                  <FormStateSelect
                    className="ml-5"
                    name="state"
                    label={"State"}
                    options={formikOptions}
                  />

                  <FormInput
                    className="ml-5 w-24"
                    name={"zip"}
                    label={"Zipcode"}
                    options={formikOptions}
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayrollEmployeePortalInformationModal;
