import React, {Component} from "react";
import {Popover} from "@headlessui/react";
import Calendar from "../features/reporting/reporting-filter/calendar";
import moment from "moment";
import FormElement from "@frostbyte-technologies/frostbyte-tailwind/dist/components/form/form-element";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Button} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FormDateTimeSelect extends Component {
  constructor(props) {
    super(props);

    this.id = "fdts_" + randomString(24);
  }

  render() {
    let {options, name, hideTime, hideDate, buttonText, flex, clearable} = this.props;

    let setMinutes = [0, 15, 30, 45];
    let times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute of setMinutes) {
        times.push({hour, minute});
      }
    }

    times.push({hour: 23, minute: 59});

    let format = "M/D/YY, h:mm A";

    if (hideTime) {
      format = "M/D/YY";
    }

    if (hideDate) {
      format = "hh:mm A";
    }

    return (
      <FormElement id={this.id} {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          let currentMinutes = 0;

          if (!hideTime) {
            currentMinutes = moment(value).diff(
              moment(value).startOf("day"),
              "minutes"
            );
          }

          return (
            <div>
              <Popover className="relative">
                {({open, close}) => (
                  <>
                    <div className={"flex items-center"}>
                      <Popover.Button className={classNames(flex && "w-full")}>
                        <button
                          type="button"
                          className={classNames(
                            flex && "w-full",
                            "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          )}
                        >
                          {buttonText
                            ? buttonText(value)
                            : value
                            ? moment(value).format(format)
                            : "Click to select time"}
                        </button>
                      </Popover.Button>

                      {!!clearable && (
                        <button
                          className={
                            "inline-flex font-bold text-center mx-2 p-2  text-red-500 hover:text-red-700 focus:text-red-700 focus:ring-1 focus:ring-red-700 rounded"
                          }
                          onClick={() =>
                            options.setFieldValue("scheduledTime", null)
                          }
                          type="button"
                        >
                          {<FontAwesomeIcon icon={"x"} />}
                        </button>
                      )}
                    </div>

                    {open &&
                      ReactDOM.createPortal(
                        <Popover.Panel
                          className="absolute h-72 z-10 bg-white"
                          focus={false}
                          unmount={false}
                        >
                          {({close}) => (
                            <div className="flex flex-row mt-4">
                              {!hideDate && (
                                <Calendar
                                  className="pt-4"
                                  initialEpochs={{
                                    startEpoch: value,
                                    endEpoch: value,
                                  }}
                                  singleDay
                                  small
                                  onChange={({startEpoch}) => {
                                    let newEpoch = moment(startEpoch)
                                      .startOf("day")
                                      .add(currentMinutes, "minutes")
                                      .valueOf();

                                    onChangeSoft && onChangeSoft(startEpoch);
                                    if (options) {
                                      options.setFieldValue(name, newEpoch);
                                    } else {
                                      onChange(newEpoch);
                                    }
                                  }}
                                />
                              )}

                              {!hideTime && (
                                <div className="border-l w-24">
                                  <div className="mt-4 pb-2 w-full flex justify-center fixed sticky font-semibold border-b">
                                    Time
                                  </div>

                                  <div className="h-52 overflow-y-scroll flex flex-col cursor-pointer">
                                    {times.map((time) => {
                                      let {hour, minute} = time;

                                      let mappedMinutes = hour * 60 + minute;
                                      let isAM = hour < 12;

                                      if (hour > 12) {
                                        hour -= 12;
                                      }

                                      return (
                                        <div
                                          onClick={() => {
                                            let startMoment =
                                              moment(value).startOf("day");

                                            startMoment.add(
                                              mappedMinutes,
                                              "minutes"
                                            );

                                            onChangeSoft &&
                                              onChangeSoft(
                                                startMoment.valueOf()
                                              );
                                            if (options) {
                                              options.setFieldValue(
                                                name,
                                                startMoment.valueOf()
                                              );
                                            } else {
                                              onChange(startMoment.valueOf());
                                            }

                                            close();
                                          }}
                                          className={classNames(
                                            "text-center text-sm py-0.5 px-2",
                                            currentMinutes === mappedMinutes
                                              ? "bg-indigo-500 text-white"
                                              : ""
                                          )}
                                        >
                                          {`${hour === 0 ? 12 : hour}:${
                                            minute === 0 ? "00" : minute
                                          } ${isAM ? "AM" : "PM"}`}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </Popover.Panel>,
                        document.querySelector("#" + this.id)
                      )}
                  </>
                )}
              </Popover>
            </div>
          );
        }}
      </FormElement>
    );
  }
}

FormDateTimeSelect.propTypes = {
  hideTime: PropTypes.bool,
};

export default FormDateTimeSelect;
