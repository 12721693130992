import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {
  Crosshair, FlexibleHeightXYPlot,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis, XYPlot,
  YAxis,
} from "react-vis";
import ReportingGraphCrosshair from "../../../features/reporting/reporting-graph-crosshair";
import LoadingSpinner from "../../../components/loading-spinner";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class SuccessGoalPage extends Component {
  state = {knowledgeBaseStats: {}};

  componentDidMount() {
    this.refreshData();
  }

  refreshData = async () => {
    let knowledgeBaseStats = await request("admin/success/goal");
    this.setState({knowledgeBaseStats});
  }

  render() {
    let {knowledgeBaseStats} = this.state;
    let divider = 40;

    let docStatus = Object.keys(knowledgeBaseStats).map(key => ({
      label: key,
      value: knowledgeBaseStats[key]
    }));

    let totalBugs = Object.values(knowledgeBaseStats).reduce((sum, item) => sum + item, 0);

    return (
      <div>
        <div style={{height: window.innerHeight - 25, paddingTop: divider}} className="flex flex-row justify-around">
          <div style={{width: window.innerWidth / 2 - divider * 2}} className="bg-white p-6 shadow sm:rounded-lg">
            <PageHeadings
              label={"Onboarding Efficiency"}
            />
          </div>
          <div style={{width: window.innerWidth / 2 - divider * 2}}
               className="bg-white p-6 shadow sm:rounded-lg flex flex-col justify-between">
            <PageHeadings
              label={"Knowledge Base"}
            />

            <div className={" text-center"}>
              <div><span className={"text-5xl font-bold"}>{knowledgeBaseStats["Completed"]}</span><span
                className={"font-semibold text-4xl"}>%</span></div>
              <div
                className={"text-gray-700 font-semibold text-lg mt-3"}>{knowledgeBaseStats["Completed"]} / {Object.values(knowledgeBaseStats).reduce((sum, val) => sum + val, 0)}</div>
            </div>

            {/*<div className={"flex flex-row justify-between mt-20 items-left"}>*/}
            {/*  {docStatus.map((item, index) => (*/}
            {/*    <div>*/}
            {/*      <div className="font-semibold text-lg text-gray-500">{item.label}</div>*/}
            {/*      <div*/}
            {/*        className={classNames(`flex items-baseline text-3xl font-bold`, index === docStatus.length - 1 ? "text-indigo-600" : "text-gray-600")}>*/}
            {/*        {item.value}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {/*</div>*/}

            <div className={"items-center flex flex-row justify-center"}>
              <div className={"flex flex-col text-gray-500 font-semibold text-sm"}>
                <XYPlot
                  height={window.innerHeight - 350}
                  width={window.innerWidth / 4}
                  xDomain={[0, 2]}
                  yDomain={[0, 1]}
                >
                  <HorizontalGridLines/>

                  <VerticalBarSeries
                    cluster={1}
                    color="#e5deff"
                    data={[
                      {
                        x: 1,
                        y: (knowledgeBaseStats["In-Progress"] + knowledgeBaseStats["Pending Review"] + knowledgeBaseStats["Completed"]) / totalBugs
                      },
                    ]}
                  />

                  <VerticalBarSeries
                    cluster={1}
                    color="#978cf6"
                    data={[
                      {x: 1, y: (knowledgeBaseStats["Pending Review"] + knowledgeBaseStats["Completed"]) / totalBugs},
                    ]}
                  />

                  <VerticalBarSeries
                    cluster={1}
                    color="#978cf6"
                    data={[
                      {x: 1, y: (knowledgeBaseStats["Pending Edits"] + knowledgeBaseStats["Completed"]) / totalBugs},
                    ]}
                  />

                  <VerticalBarSeries
                    cluster={1}
                    color={"rgb(79 70 229)"}
                    data={[{
                      x: 1,
                      y: knowledgeBaseStats["Completed"] / totalBugs
                    }]}
                  />

                  <YAxis tickTotal={10}
                         tickFormat={(t) => {
                           return `${t * 100}%`;
                         }}/>
                </XYPlot>

                <div className={"flex-row flex justify-between mb-5"}>
                  <div>
                    <div style={{backgroundColor: "#e5deff"}} className={"h-3 w-3"}/>
                    <span>In-Progress</span>
                  </div>
                  <div>
                    <div style={{backgroundColor: "#978cf6"}}className={"h-3 w-3"}/>
                    <span>Pending Review</span>
                  </div>
                  <div>
                    <div style={{backgroundColor: "#978cf6"}}className={"h-3 w-3"}/>
                    <span>Pending Edits</span>
                  </div>
                  <div>
                    <div style={{backgroundColor: "rgb(79 70 229)"}}className={"h-3 w-3"}/>
                    <span>Completed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessGoalPage;
