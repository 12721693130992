import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import ReportingValueCell from "../../features/reporting/reports/reporting-value-cell";
import ReportingLabelCell from "../../features/reporting/reports/reporting-label-cell";
import {combineArraysIfExist, timeFrameToLabel} from "../../utils/util";
import FixedRowReport from "../../features/reporting/reports/fixed-row-report";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {Crosshair, FlexibleWidthXYPlot, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis,} from "react-vis";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReportingGraphCrosshair from "../../features/reporting/reporting-graph-crosshair";
import {SALES_SUMMARY_ROWS} from "../../features/reporting/reports/reporting-constants";
import {AUDIT_SALES_SUMMARY_ROWS} from "../../features/reporting/reports/audit-reporting-constants";
import ReportingGraph, {REPORTING_GRAPH_TYPES} from "../../features/reporting/reporting-graph";

class SalesReportPage extends Component {
  state = {crosshairValues: []};

  convertDataToColumns = (reportData) => {
    let {lastStartEpoch, lastEndEpoch, timeFrame} = this.props.reporting;

    let columns = [];

    if (reportData) {
      columns = reportData.map((item, index) => {
        return {
          label:
            timeFrameToLabel(
              item,
              lastStartEpoch,
              lastEndEpoch,
              timeFrame
            ),
          // moment(item.TIMESPAN).format(getFormatString()),
          width: 100,
          format: (value, row) => {
            return (
              <ReportingValueCell
                row={row}
                firstIndex={index}
                secondIndex={row.selector}
                thirdIndex={row.secondSelector}
                style={combineArraysIfExist(row.style, item.style)}
                dollarAmount={true}
              />
            );
          },
        };
      });
      columns.unshift({
        label: "",
        width: 50,
        sticky: true,
        format: (value, row) => {
          return <ReportingLabelCell row={row} style={row.style}/>;
        },
      });
    }

    return columns;
  };

  renderSingleDayGraph(reportData) {
    let {REPORTING_MODE} = this.props.shop.settings;
    let {crosshairValues} = this.state;

    let key = "TIMESPAN";

    if (REPORTING_MODE === "AUDIT") {
      key = "HOUR";
    }

    let hoursDict = reportData?.reduce((dict, item) => {
      dict[moment(item[key]).format("H")] = {
        GROSS_SALES: item.GROSS_SALES,
        orderCount: item.PAYMENT_COUNT,
      };

      return dict;
    }, {});

    let hoursData = [];

    for (let hour of Array(24).keys()) {
      if (hoursDict[hour]) {
        hoursData.push({
          x: hour,
          y: hoursDict[hour].GROSS_SALES,
          orderCount: hoursDict[hour].orderCount,
        });
      } else {
        hoursData.push({x: hour, y: 0, orderCount: 0});
      }
    }

    if (reportData.length === 0) {
      return <div/>;
    }

    return (
      <Card className={"mb-5 shadow-sm rounded-sm hidden md:block"}>
        <div className="p-5 pb-1">
          <FlexibleWidthXYPlot
            margin={{left: 75}}
            height={160}
            xDomain={[0, 23]}
            onMouseLeave={() => this.setState({crosshairValues: []})}
          >
            <HorizontalGridLines/>

            <VerticalBarSeries
              color={"rgb(79 70 229)"}
              data={hoursData}
              onNearestX={(datapoint, event) => {
                this.setState({crosshairValues: [datapoint]});
              }}
            />

            <Crosshair
              values={crosshairValues}
              className={"z-50"}
              style={{line: {backgroundColor: "#4B5563"}}}
            >
              <ReportingGraphCrosshair
                timeString={`${moment()
                  .startOf("day")
                  .add(crosshairValues[0]?.x, "hours")
                  .format("h:mm A")}
                   - ${moment()
                  .startOf("day")
                  .add(crosshairValues[0]?.x + 1, "hours")
                  .subtract(1, "minute")
                  .format("h:mm A")}`}
                totalSales={crosshairValues[0]?.y}
                ticketCount={crosshairValues[0]?.orderCount}
                isLeft={crosshairValues[0]?.x < 12}
              />
            </Crosshair>

            <XAxis
              tickValues={[0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]}
              tickFormat={(t) => {
                if (t === 0) {
                  return "12 AM";
                }

                if (t === 12) {
                  return "12 PM";
                }

                if (t > 12) {
                  return t % 12;
                }

                return t;
              }}
            />
            <YAxis
              tickTotal={4}
              tickFormat={(amount) => {
                if (amount === 0) {
                  return;
                }

                return toDollars(amount, true);
              }}
            />
          </FlexibleWidthXYPlot>
        </div>
      </Card>
    );
  }

  renderMultiDayGraph = (daily) => {
    let {crosshairValues} = this.state;
    let {REPORTING_MODE} = this.props.shop.settings;

    let key = "TIMESPAN";

    if (REPORTING_MODE === "AUDIT") {
      key = "DAY";
    }

    let dateMap = daily.map((item, index) => {
      let year = item[key].slice(0, 4);
      let month = parseInt(item[key].slice(5, 7)) - 1;
      let date = item[key].slice(8, 10);

      if (REPORTING_MODE === "AUDIT") {
        year = moment(item[key]).year();
        month = moment(item[key]).month();
        date = moment(item[key]).date();
      }

      return {
        x: moment({
          year,
          month,
          date,
        }).valueOf(),
        y: item.GROSS_SALES,
        orderCount: item.PAYMENT_COUNT,
        index,
      }
    });

    if (dateMap.length === 0) {
      return <div/>;
    }

    let tickValues = dateMap.map((item) => item.x);

    let lowestEpoch = dateMap[0].x;
    let highestEpoch = dateMap[dateMap.length - 1].x;

    let highestY = dateMap.reduce((high, item) => {
      if (item.y > high) {
        return item.y;
      }
      return high;
    }, 0);

    return (
      <Card className={"mb-5 shadow-sm rounded-sm hidden md:block"}>
        <div className="p-5 pb-1">
          <div>
            <FlexibleWidthXYPlot
              margin={{left: 75, right: 30}}
              height={160}
              xDomain={[lowestEpoch, highestEpoch]}
              yDomain={[0, highestY]}
              onMouseLeave={() => this.setState({crosshairValues: []})}
            >
              <HorizontalGridLines/>

              <VerticalBarSeries
                color={"rgb(79 70 229)"}
                data={dateMap}
                onNearestX={(datapoint, event) => {
                  this.setState({crosshairValues: [datapoint]});
                }}
              />

              <Crosshair
                values={crosshairValues}
                className={"z-50"}
                style={{line: {backgroundColor: "#4B5563"}}}
              >
                <ReportingGraphCrosshair
                  timeString={`${moment(crosshairValues[0]?.x).format(
                    "MMM Do, YYYY"
                  )}`}
                  totalSales={crosshairValues[0]?.y}
                  ticketCount={crosshairValues[0]?.orderCount}
                  isLeft={crosshairValues[0]?.index < dateMap.length / 2}
                />
              </Crosshair>

              <XAxis
                tickFormat={(t) => {
                  if (t === lowestEpoch || t === highestEpoch) {
                    return moment(t).format("MMM D");
                  }

                  return moment(t).format("D");
                }}
                tickValues={tickValues}
              />
              <YAxis
                tickTotal={4}
                tickFormat={(amount) => {
                  if (amount === 0) {
                    return;
                  }

                  return toDollars(amount, true);
                }}
              />
            </FlexibleWidthXYPlot>
          </div>
        </div>
      </Card>
    );
  };

  renderGraph = (reportData, rawData) => {
    let {REPORTING_MODE} = this.props.shop.settings;

    const {hourly, daily} = reportData;

    if (REPORTING_MODE === "AUDIT") {
      return this.renderAuditGraph(rawData);
    }

    let {lastStartEpoch, lastEndEpoch} = this.props.reporting;

    if (
      moment(lastStartEpoch).startOf("day").valueOf() ===
      moment(lastEndEpoch).startOf("day").valueOf()
    ) {
      return this.renderSingleDayGraph(hourly);
    }

    return this.renderMultiDayGraph(daily);
  };

  renderAuditGraph(rawData) {
    return (
      <div className={"bg-white p-4 rounded-none shadow-sm mb-6"}>
        <ReportingGraph
          height={150}
          displayAsDollars={true}
          datasets={[
            {
              id: "base",
              rawData: rawData,
              stroke: "#4e46e5",
              strokeWidth: 2,
              fill: "#ccc9f7",
              fillOpacity: 0.7,
              stopNow: true
            }]}
          type={REPORTING_GRAPH_TYPES.AREA.id}
          dataKey={"GROSS_SALES"}
          ignoreWeekdayLabels={true}
        />
      </div>
    );
  }

  getRows = (reportData) => {
    let {REPORTING_MODE} = this.props.shop.settings;

    let rows = SALES_SUMMARY_ROWS;

    if (REPORTING_MODE === "AUDIT") {
      rows = [...AUDIT_SALES_SUMMARY_ROWS];
    }

    if (reportData.reduce((sum, entry) => sum + entry.PASS_PROCESSING_COLLECTED, 0) == 0) {
      rows = rows.filter(row => row.selector !== "PASS_PROCESSING_COLLECTED");
    }

    if (reportData.reduce((sum, entry) => sum + entry.DELIVERY_FEES_COLLECTED, 0) == 0) {
      rows = rows.filter(row => row.selector !== "DELIVERY_FEES_COLLECTED");
    }

    if (reportData.reduce((sum, entry) => sum + entry.CUSTOM_FEES_COLLECTED, 0) == 0) {
      rows = rows.filter(row => row.selector !== "CUSTOM_FEES_COLLECTED");
    }

    if (reportData.reduce((sum, entry) => sum + entry.SERVICE_FEES_COLLECTED, 0) == 0) {
      rows = rows.filter(row => row.selector !== "SERVICE_FEES_COLLECTED");
    }

    return rows;
  }

  render() {

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Sales Summary Report"}/>
        </div>

        <FixedRowReport
          filenamePrefix="sales-summary"
          endpoint="report/salessummary"
          convertDataToColumns={this.convertDataToColumns}
          timeframePicker={true}
          locationPicker={true}
          showLocationsInDownload={true}
          showDateRangeInDownload={true}
          enableStripePayouts={true}
          graph={(reportData, rawData) => this.renderGraph(reportData, rawData)}
          getRows={this.getRows}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(SalesReportPage);
