import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import FormProductSelect from "../../../components/form-product-select";

class ProductSelectModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async handleSubmit({products}) {
    const {discount} = this.props;
    await request("product/discount/" + discount.ID, "POST", {
      PRODUCTS: products,
    });

    this.props.updateState && this.props.updateState();

    this.modal.close();
  }

  render() {
    const {exclude = []} = this.props;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Select Products"}
        description={"Select a product that this discount should be added to"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            products: [],
          }}
          onSubmit={(values) => this.handleSubmit(values)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormProductSelect
                  label="Products"
                  name="products"
                  options={formikOptions}
                  tooltip={{
                    data: "Select which products this discount should be available for",
                  }}
                  multi
                  exclude={exclude}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ProductSelectModal;
