import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../utils/request";

class FormCategorySelect extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {unique} = this.props;

    const serverCategories = await request("categories", "GET");
    const data = serverCategories
      .filter((item) => !item.DATE_ARCHIVED)
      .map((item) => {
        return {
          value: unique ? item.UNIQUE_ID : item.ID,
          label: item.NAME,
        };
      });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return <FormSelect {...this.props} data={data} />;
  }
}

export default FormCategorySelect;
