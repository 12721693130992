import React, {Component} from "react";
import ChoosePaydayComponent, {PAYMENT_METHOD_TYPES} from "../choose-payday-component";
import {request} from "../../../../utils/request";
import {createOffCyclePayroll} from "../../../../utils/payroll-helper";
import {showErrorAlert} from "../../../../utils/alert-helper";

class OffCycleChoosePaydayComponent extends Component {
  state = {loading: false, payday: null, periodStart: null, periodEnd: null, paymentMethod: PAYMENT_METHOD_TYPES.DIRECT_DEPOSIT.value};

  async handleClick() {
    let {periodStart, periodEnd, payday, paymentMethod} = this.state;

    let payload = {
      PERIOD_START: periodStart,
      PERIOD_END: periodEnd,
      PAYDAY: payday,
      IS_MANUAL: paymentMethod === PAYMENT_METHOD_TYPES.MANUAL.value
    };

    try {
      await createOffCyclePayroll(payload);
      return true;
    } catch (error) {
      let label = "Invalid Dates";
      let description =
        "The dates selected are invalid. Please correct them to run an off-cycle payroll";

      if (error === "APPROVAL_DEADLINE_PASSED") {
        label = "Approval Deadline Passed";
        description =
          "The approval deadline for the specified dates has passed. Please choose a different paydate";
      }

      if(error?.error === "PAYDAY_NOT_IN_FUTURE"){
        label = "Payday Invalid";
        description = "Payday must be today or in future";
      }

      await showErrorAlert(label, description, "Ok");
    }
  }

  render() {
    let {loading} = this.state;

    return (
      <ChoosePaydayComponent
        loading={loading}
        onPeriodStartChange={(newPeriodStart) => {
          this.setState({periodStart: newPeriodStart});
        }}
        onPeriodEndChange={(newPeriodEnd) => {
          this.setState({periodEnd: newPeriodEnd});
        }}
        onPaydayChange={(newPayday) => {
          this.setState({payday: newPayday});
        }}
        onPaymentMethodChange={(paymentMethod) => {
          this.setState({paymentMethod});
        }}
        chooseManual={true}
        chooseWorkPeriod={true}
      />
    );
  }
}

export default OffCycleChoosePaydayComponent;
