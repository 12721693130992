import React, {Component} from "react"

class FormSpacer extends Component {
  render() {
    return (
      <div className="flex-1" />
    );
  }
}

export default FormSpacer;
