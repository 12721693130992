import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import MultiSelectCombobox from "../../components/multi-select-combobox";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

class LocationsDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {allowNull, exclude = [], unique, isCompany} = this.props;

    let url = "partner/franchise/locations";
    if (isCompany) url = "partner/company/locations";

    const serverLocations = await request(url, "GET");
    const data = serverLocations.map((item) => ({
      value: unique ? item.UNIQUE_ID : item.ID,
      id: unique ? item.UNIQUE_ID : item.ID,
      label: item.NAME,
    })).filter((item) => {
      return !exclude.includes(item.id);
    });

    if (allowNull) {
      data.unshift({label: "All Locations", id: null});
    }

    this.setState({
      data,
    });
  }

  fetchLocation() {
    const {data} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {hideIfSingle, multi} = this.props;
    const {data} = this.state;

    if (hideIfSingle && (data.length === 1 || data.length === 0)) {
      return <div />;
    }

    if (multi) {
      return <FormSelect {...this.props} label="Location" data={data} multi {...this.props} />;
    }

    return <ComboBox {...this.props} label="Location" data={data} {...this.props} />;
  }
}

LocationsDropdown.propTypes = {
  ...ComboBox.propTypes,

  allowNull: PropTypes.bool,
  hideIfSingle: PropTypes.bool,
  isCompany: PropTypes.bool,
};

export default LocationsDropdown;
