import React, {Component} from "react";
import PropTypes from "prop-types";
import ButtonRow from "../buttons/button-row";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Tooltip from "../tooltip/tooltip";
import {SearchIcon} from "@heroicons/react/solid";

class Card extends Component {
  state = {search: ""};

  renderHeader() {
    const {search} = this.state;
    const {
      label,
      tooltip,
      description,
      buttonsBottom,
      hint,
      noHeaderBorder,
      searchable,
    } = this.props;

    if (!label && !description) {
      return <div />;
    }

    return (
      <div
        className={classNames(
          "bg-white px-4 py-5 sm:px-6",
          !noHeaderBorder && "border-b border-gray-200"
        )}
      >
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex flex-row align-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {label}
              </h3>

              {tooltip && <Tooltip className="ml-2 mb-1" {...tooltip} />}

              {hint && (
                <div
                  style={{marginTop: 2}}
                  className="flex ml-2 mb-1 bg-gray-200 px-2 -py-1 rounded-md text-xs text-gray-500 items-center font-medium"
                >
                  {hint}
                </div>
              )}
            </div>

            {description && (
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {description}
              </p>
            )}
          </div>

          {searchable && (
            <div className="relative rounded-md shadow-sm flex flex-col">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>

              <input
                type="text"
                value={search}
                onChange={(e) =>
                  this.setState({search: e.target.value}, () => {
                    if (searchParams) {
                      const url = new URL(window.location.href);

                      url.searchParams.delete("sort");
                      url.searchParams.delete("sortDesc");
                      url.searchParams.set("search", e.target.value);

                      if (e.target.value.length === 0) {
                        url.searchParams.delete("search");
                      }

                      window.history.pushState({}, null, url);
                    }
                  })
                }
                className="bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search..."
              />
            </div>
          )}

          {!buttonsBottom && <ButtonRow marginPadding {...this.props} />}
        </div>
      </div>
    );
  }

  renderFooter() {
    const {buttonsBottom} = this.props;

    if (!buttonsBottom) {
      return <div />;
    }

    return (
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 md:px-6">
        {buttonsBottom && (
          <>
            <div />

            <ButtonRow {...this.props} />
          </>
        )}
      </div>
    );
  }

  render() {
    const {className} = this.props;
    const {search} = this.state;

    return (
      <div
        className={classNames(className, "bg-white mt-4 shadow sm:rounded-lg")}
      >
        {this.renderHeader()}

        <div>
          {typeof this.props.children === "function"
            ? this.props.children({search})
            : this.props.children}
        </div>

        {this.renderFooter()}
      </div>
    );
  }
}

Card.propTypes = {
  ...ButtonRow.propTypes,

  label: PropTypes.string,
  description: PropTypes.string,
  searchable: PropTypes.bool,

  buttonsBottom: PropTypes.bool,
  className: PropTypes.string,

  tooltip: Tooltip.propTypes,
  hint: PropTypes.string,

  noHeaderBorder: PropTypes.bool,
};

export default Card;
