import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import CompanyPermissionsModal from "../../../modals/shop/company-structure/company-permissions-modal";

class PartnersTable extends Component {
  render() {
    const {partners, companies, syncState, type, people, locations} =
      this.props;

    return (
      <>
        <CompanyPermissionsModal
          ref={(e) => (this.permissionsModal = e)}
          companies={companies}
          syncState={syncState}
          people={people}
          locations={locations}
          type={type}
        />

        <Table
          pagination
          data={partners}
          actionButtons={[
            {
              label: "Edit",
              onClick: (partner) => {
                this.permissionsModal.open(partner);
              },
            },
          ]}
          columns={[
            {
              value: "FULL_NAME",
              label: "Name",
              format: (_, partner) => {
                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {partner.FULL_NAME}
                    </div>

                    <div className="text-sm text-gray-500">{partner.EMAIL}</div>

                    <div className="text-sm text-gray-500">
                      {getFormattedPhone(partner.PHONE)}
                    </div>
                  </div>
                );
              },
            },
            {
              value: "PERMISSIONS",
              label: "Permissions",
              width: 1,
              format: (_, partner) => {
                let locationPermissions = [];
                let companyPermissions = [];
                let superPermissions = false;

                partner.PERMISSIONS.forEach((item) => {
                  if (item.TYPE === "LOCATION") {
                    locationPermissions.push(item);
                  } else if (item.TYPE === "COMPANY") {
                    companyPermissions.push(item);
                  } else if (item.TYPE === "SUPER") {
                    superPermissions = true;
                  }
                });

                return (
                  <div onClick={() => this.permissionsModal.open(partner)}>
                    {type === "LOCATION" && (
                      <div
                        className={classNames(
                          "text-sm",
                          locationPermissions.length === 0
                            ? "text-gray=500"
                            : "font-medium  cursor-pointer text-indigo-500 hover:text-indigo-600 transition duration-250 ease-in-out"
                        )}
                      >
                        {locationPermissions.length}{" "}
                        {locationPermissions.length === 1
                          ? "Location"
                          : "Locations"}
                      </div>
                    )}

                    {type === "COMPANY" && (
                      <div
                        className={classNames(
                          "text-sm",
                          companyPermissions.length === 0
                            ? "text-gray=500"
                            : "font-medium cursor-pointer text-indigo-500 hover:text-indigo-600 transition duration-250 ease-in-out"
                        )}
                      >
                        {companyPermissions.length}{" "}
                        {companyPermissions.length === 1
                          ? "Company"
                          : "Companies"}
                      </div>
                    )}

                    {type === "SUPER" && (
                      <div
                        className={
                          "font-medium cursor-pointer text-indigo-500 hover:text-indigo-600 transition-all duration-250 ease-in-out"
                        }
                      >
                        Super Partner
                      </div>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </>
    );
  }
}

export default PartnersTable;
