import React, {Component} from "react";
import {
  Loading,
  Modal,
  FormElement,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {getStripeKey, request} from "../../../utils/request";
import {MoonLoader} from "react-spinners";
import PropTypes from "prop-types";

import {ElementsConsumer, CardElement} from "@stripe/react-stripe-js";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(getStripeKey());

class ContactPaymentModal extends Component {
  state = {isLoading: true, secret: null, contact: null};

  open(contact = null) {
    this.setState({isLoading: true, contact}, () => {
      this.loadSetup();

      this.modal.open();
    });
  }

  async loadSetup() {
    const {contact} = this.state;

    const {client_secret} = await request(
      "contacts/" + contact.ID + "/setup",
      "GET"
    );

    this.setState({secret: client_secret, isLoading: false});
  }

  render() {
    const {isLoading, secret, contact} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Contact Payment Method"
        buttonOnClick={() => this.cardForm.handleSubmit()}
        ref={(e) => (this.modal = e)}
      >
        {isLoading ? (
          <div className="my-4 flex flex-grow justify-center items-center">
            <MoonLoader color={"blue"} loading={true} size={42} />
          </div>
        ) : (
          <Elements stripe={stripePromise}>
            <FinalForm
              addState={(method) => {
                this.props.addState(method);
                this.modal.close();
              }}
              resetButton={() => {
                this.modal.fetchModalButton().stopLoading();
              }}
              ref={(e) => (this.cardForm = e)}
              contact={contact}
              secret={secret}
            />
          </Elements>
        )}
      </Modal>
    );
  }
}

class CardSetupForm extends Component {
  state = {error: null};

  handleSubmit = async (event) => {
    const {contact, stripe, elements, secret} = this.props;

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event && event.preventDefault();

    if (!stripe || !elements) {
      return console.log("No Stripe");
    }

    const result = await stripe.confirmCardSetup(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        // billing_details: {
        //   name: "Jenny Rosen",
        // },
      },
    });

    if (result.error) {
      this.props.resetButton(result.error.message);

      this.setState({error: result.error.message});
    } else {
      const {setupIntent} = result;

      const paymentMethod = await request(
        "contacts/" + contact.ID + "/method/" + setupIntent.payment_method,
        "GET"
      );

      this.props.addState(paymentMethod);
    }
  };

  render() {
    const {error} = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <FormElement error={error} label="Credit Card">
            {() => (
              <div className="mt-2 px-4 py-3 bg-white rounded-md">
                <CardElement
                  onFocus={() => this.setState({error: null})}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        padding: "8px",
                        backgroundColor: "white",
                        color: "#32325d",
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                        iconColor: "#fa755a",
                      },
                    },
                  }}
                />
              </div>
            )}
          </FormElement>
        </label>
      </form>
    );
  }
}

const FinalForm = InjectedCardSetupForm(CardSetupForm);

function InjectedCardSetupForm(Component) {
  function ComponentWithProp({forwardedRef, ...props}) {
    return (
      <ElementsConsumer>
        {({stripe, elements}) => (
          <Component
            ref={forwardedRef}
            elements={elements}
            stripe={stripe}
            {...props}
          />
        )}
      </ElementsConsumer>
    );
  }

  return React.forwardRef((props, ref) => {
    return <ComponentWithProp {...props} forwardedRef={ref} />;
  });
}

ContactPaymentModal.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default ContactPaymentModal;
