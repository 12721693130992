import PropTypes from "prop-types";
import React from "react";
import {UserPlus, XOctagon} from "react-feather";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../components/loading-spinner";
import {setupReduxConnection} from "../../redux";
import {SHIFT_VIEWS} from "./scheduler";

const SchedulerResource = ({
  isLoading,
  title,
  shiftView,
  activeEmployee,
  externalEmployee,
  hours,
  cost,
  employeeId,
  multipleRoleEmployee,
  preferredTotal,
  employeeModal,
  unavailability,
  timeOff,
  payViewPermission,
  showLaborCost,
}) => {
  return (
    <div
      className={"pl-2 flex flex-col align-center cursor-pointer"}
      onClick={() => {
        if (shiftView !== SHIFT_VIEWS.ROLES) {
          employeeModal.open(employeeId, unavailability, timeOff, title);
        }
      }}
    >
      <div className="text-sm">{title}</div>

      {/*{!activeEmployee && (*/}
      {/*  <span className="ml-2" title={"Inactive Employee"}>*/}
      {/*    <XOctagon size={14} />*/}
      {/*  </span>*/}
      {/*)}*/}
      {/*{externalEmployee && (*/}
      {/*  <span className="ml-2" title={"External Employee"}>*/}
      {/*    <UserPlus size={14} />*/}
      {/*  </span>*/}
      {/*)}*/}

      {showLaborCost && (
        <div>
          {preferredTotal && (
            <div className="text-[10px] text-gray-700">
              <span
                className={classNames(
                  preferredTotal > hours ? "text-red-500" : "",
                  "block"
                )}
              >
                {hours} / {preferredTotal} Hrs
              </span>
              {payViewPermission
                ? ` ${hours > preferredTotal ? " - " : ""}$${toDollars(cost)}`
                : ""}
            </div>
          )}

          {!preferredTotal && (
            <div className="text-[10px] text-gray-700">
              {Math.round(hours * 100) / 100} Hrs
              {payViewPermission ? ` - $${toDollars(cost)}` : ""}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SchedulerResource.propTypes = {
  employeeId: PropTypes.number.isRequired,
  activeEmployee: PropTypes.bool.isRequired,
  cost: PropTypes.number.isRequired,
  externalEmployee: PropTypes.bool.isRequired,
  multipleRoleEmployee: PropTypes.bool.isRequired,
  hours: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default setupReduxConnection(["scheduling"])(SchedulerResource);
