import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class PermissionWrapper extends Component {
  renderBye() {
    setTimeout(() => {
      this.props.router.navigate("/403");
    }, 1);

    return <div>No Permission Dawg</div>;
  }

  render() {
    const {permissions: userPermissions} = this.props.user;
    const {permissions, live} = this.props;

    if (!live && process.env.REACT_APP_ENV !== "development") {
      return this.renderBye();
    }

    const permissionArr = permissions.map((item) => {
      if (typeof item === "string") {
        return item;
      }

      return item.id;
    });

    if (userPermissions.includes("ADMIN_FULL") || permissionArr.length === 0) {
      return this.props.children;
    }

    if (permissionArr.some((x) => userPermissions.includes(x))) {
      return this.props.children;
    }

    return this.renderBye();
  }
}

export default setupReduxConnection(["user"])(withRouter(PermissionWrapper));
