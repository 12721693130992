import {getStore} from "../redux";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React from "react";
import {addAlert} from "../redux/alert";
import {CheckIcon} from "@heroicons/react/outline";
import {AlertTriangle} from "react-feather";

/**
 * Adds a notification
 *
 * @param params
 * @param params.label
 * @param params.description
 * @param params.buttonLabel
 * @param params.buttonClick
 * @param params.closeLabel
 * @param params.icon
 * @param params.buttons
 * @param params.type
 *
 */
export function showAlert(params) {
  let {dispatch} = getStore();
  let {icon} = params;

  const alertPayload = {
    id: "alert_" + randomString(24),
    type: "basic",
    ...params,
  };

  if (icon === "success") {
    alertPayload.icon = (
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
    );
  }

  if (icon === "error") {
    alertPayload.icon = (
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
        <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
    );
  }

  dispatch(addAlert(alertPayload));
}

export function showSuccessAlert(label, description, buttonLabel = "Continue", params = {}) {
  showAlert({label, description, buttonLabel, icon: "success", ...params});
}

export function showErrorAlert(label, description, buttonLabel = "Continue") {
  showAlert({label, description, buttonLabel, type: "error", icon: "error"});
}

export async function showConfirmAlert(label, description, button = "Confirm") {
  return new Promise((resolve, reject) => {
    showAlert({
      label,
      description,
      buttonLabel: button,
      buttonClick: () => resolve(),
      buttonReject: () => reject(),
      type: "confirm",
      icon: "success",
    });
  });
}

export async function showLoadingConfirmAlert(
  label,
  description,
  button = "Confirm",
  settings
) {
  return new Promise((resolve, reject) => {
    showAlert({
      label,
      description,
      buttonLabel: button,
      buttonClick: (callback) => resolve(callback),
      buttonReject: () => reject(),
      type: "confirm",
      icon: "success",
      loading: true,
      ...settings
    });
  });
}
