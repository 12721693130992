import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ModifierModal from "../../../modals/sales/modifiers/modifier-modal";
import RolesTable from "../../../tables/team/roles-table";

class RolesPage extends Component {
  state = {data: null};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverRoles = await request("employeeroles/roles", "GET");

    this.setState({data: serverRoles});
  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <ModifierModal ref={(e) => (this.modifierModal = e)} />

        <PageHeadings
          label="Roles"
          description="Modify the roles that your employees can have"
          button={{
            label: "Add Role",
            onClick: () => this.props.router.navigate("/role"),
          }}
        />

        <RolesTable pagination className="mt-4" roles={data} />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(RolesPage));
