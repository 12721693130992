import React, {Component} from "react";
import {Tab, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {
  CELL_TYPES,
  DISCOUNT_SUMMARY_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import ReportTable from "../../features/reporting/reports/report-table";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../utils/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DiscountsReportPage extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData?.BREAKDOWN) {
      for (let breakdownEntry of reportData?.BREAKDOWN) {
        rows.push({
          label: breakdownEntry.NAME,
          numbers: breakdownEntry,
        });
      }

      rows.push({
        label: "Total",
        numbers: reportData?.TOTALS,
        style: [CELL_TYPES.BOLD],
      });
    }

    return rows;
  }

  renderCustomTable = (reportData, columns) => {
    let discountLogColumns = [
      {
        label: "Name",
        value: "NAME",
        format: (name, row) => {
          if (name && name.length > 0) {
            return name;
          }

          if (!row.DISCOUNT_ID && !row.PROMOTION_ID) {
            return "Custom Discount" + (row.NOTES ? ` (${row.NOTES})` : "");
          } else if (row.PROMOTION_ID) {
            return row.PROMOTION_NAME;
          } else {
            return "-";
          }
        },
      },
      {
        label: "Type",
        value: "IS_TICKET_DISCOUNT",
        format: (value, row) => {
          if (row.IS_LINE_ITEM) {
            return "Discount";
          }

          if (row.PROMOTION_ID) {
            return "Promotion";
          }
          return value ? "Ticket Discount" : "Product Discount";
        },
      },
      {
        label: "Date Used",
        value: "DATE_PAYED",
        format: (value) => {
          return moment(value).format("M/DD");
        },
      },
      {
        label: "Platform",
        value: "PLATFORM",
      },
      {
        label: "Discount Amount",
        value: "AMOUNT_ADJUSTED",
        format: (value, row) => {
          // Old style discounts
          if (row.IS_LINE_ITEM) {
            return toDollars(row.AMOUNT_DISCOUNT, true);
          }

          if (!value) {
            return toDollars(row.AMOUNT, true);
          }
          return toDollars(value, true);
        },
      },
      {
        label: "Ticket",
        format: (value, row) => {
          return (
            <div
              className="cursor-pointer font-semibold text-indigo-500"
              onClick={() => {
                this.props.router.navigateNewTab(
                  `/ticket/${row.TICKET_UNIQUE_ID}`
                );
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
            </div>
          );
        },
      },
    ];

    return (
      <Tab
        data={[
          {label: "Summary", id: "summary"},
          {label: "Log", id: "log"},
        ]}
      >
        {(id) => {
          if (id === "summary") {
            return (
              <ReportTable
                data={this.convertDataToRows(reportData)}
                constantColumns={columns}
                numbers={reportData}
              />
            );
          }

          if (id === "log") {
            return (
              <Table
                pagination
                data={reportData?.LOG}
                columns={discountLogColumns}
              />
            );
          }
        }}
      </Tab>
    );
  };

  render() {
    let {REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Discounts Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="discounts-sales"
          endpoint="report/discounts"
          constantColumns={DISCOUNT_SUMMARY_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={false}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          renderCustomTable={this.renderCustomTable}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "reporting", "shop"])(
  withRouter(DiscountsReportPage)
);
