import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import CsvMapper from "../csv-mapper";
import {IGNORE_FIELDS, PLATFORM_IGNORE_FIELDS} from "./constants";

class MappingTab extends Component {
  state = {steps: []};

  componentDidMount() {
    let {pastProvider} = this.props.payroll.importData;
    let {data} = this.props.payroll.importData.checkTemplateData;


    let steps = Object.keys(data[0])
      .map((key) => {
        if (
          !DO_NOT_MAP_FIELDS.includes(key) &&
          !IGNORE_FIELDS.includes(key) &&
          !(PLATFORM_IGNORE_FIELDS[pastProvider] ?? []).includes(key)
        ) {
          return {
            label: key,
            key,
          };
        }
      })
      .filter((step) => step);

    this.csvMapper.setSteps(steps);
  }

  handleClick = () => {
    let steps = this.csvMapper.getSteps();

    let paycheckToCheckMapping = steps.reduce((dict, step) => {
      if (step.value) {
        dict[step.key] = {
          rowOffset: step.value.row,
          colOffset: step.value.col,
          skip: step.value.skip,
        };
      }
      return dict;
    }, {});

    this.props.updateImport({paycheckToCheckMapping});

    return true;
  };

  render() {
    return (
      <CsvMapper
        ref={(e) => (this.csvMapper = e)}
        allowSkips={true}
        showFirstPaycheck={true}
        initialSteps={[]}
        handleClick={this.handleClick}
      />
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(MappingTab));

export const DO_NOT_CONVERT_FIELDS = ["Workplace ID"];
export const DO_NOT_MAP_FIELDS = [
  "Employee ID",
  "Contractor ID",
  "Contractor Name",
  "Contractor Payment Reimbursement Amount",
  "Contractor Payment Amount",
];
