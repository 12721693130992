import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import moment from "moment";
import ReportingFilter from "../reporting-filter/reporting-filter";
import LoadingSpinner from "../../../components/loading-spinner";
import ReportTable from "./report-table";
import {
  CELL_TYPES,
  PAYMENT_METHODS_COLUMNS,
  SALES_SUMMARY_ROWS,
  TIMEFRAMES,
} from "./reporting-constants";
import {isEqual} from "lodash";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {combineArraysIfExist, timeFrameToLabel} from "../../../utils/util";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import ReportingValueCell from "./reporting-value-cell";
import ReportingLabelCell from "./reporting-label-cell";
import {AUDIT_SALES_SUMMARY_ROWS} from "./audit-reporting-constants";
import {
  convertEpochsToDateString,
  nullifyIgnoredColumns,
} from "../../../utils/reporting-helper";

class FixedRowReport extends Component {
  state = {
    isLoading: false,
    reportData: null,
    paymentMethodsData: null,
    csvHeaders: [],
    csvData: [],
    hourly: null,
    rid: null,
  };
  onGenerate = async () => {
    let {REPORTING_MODE} = this.props.shop.settings;

    let {endpoint, showLocationsInDownload, showDateRangeInDownload} =
      this.props;
    let {
      startEpoch,
      endEpoch,
      locationIdArray,
      timeFrame,
      excludeThird,
      reportingLocations,
    } = this.props.reporting;

    this.props.setLastEpochs({
      lastStartEpoch: startEpoch,
      lastEndEpoch: endEpoch,
    });

    let newRid = `rid_${Math.random().toString(36).slice(2, 7)}`;
    this.setState({isLoading: true, reportData: null, rid: newRid});

    let EXECUTE_REPORTS = ["TIMESPAN", "HOUR", "DAY"];

    if (!EXECUTE_REPORTS.includes(timeFrame)) {
      EXECUTE_REPORTS.push(timeFrame);
    }

    let body = {
      START_EPOCH: startEpoch,
      END_EPOCH: endEpoch,
      LOCATION_ID_ARRAY: locationIdArray,
      EXECUTE_REPORTS,
      POPULATE_MISSING_DATES: false,
      EXCLUDE_THIRD_PARTY: excludeThird,
    };

    let reportData = await request(endpoint, "POST", body);

    let numbers;
    switch (timeFrame) {
      case TIMEFRAMES.SUMMARY:
        numbers = [{...reportData?.TIMESPAN[0]}];
        break;
      case TIMEFRAMES.DAILY:
        numbers = [...reportData?.DAY];
        break;
      case TIMEFRAMES.WEEKLY:
        numbers = [...reportData?.WEEK];
        break;
      case TIMEFRAMES.MONTHLY:
        numbers = [...reportData?.MONTH];
        break;
    }

    let csvData = [];

    let rows = SALES_SUMMARY_ROWS;

    if (REPORTING_MODE === "AUDIT") {
      rows = AUDIT_SALES_SUMMARY_ROWS;
    }

    for (let row of rows) {
      let currentRow = {LABEL: row.label};

      for (let entryIndex in numbers) {
        currentRow[entryIndex] = row.dollarAmount
          ? toDollars(numbers[entryIndex][row.selector] || 0, true)
          : numbers[row.selector];
      }

      csvData.push(currentRow);
    }

    let csvHeaders = [{label: "", key: "LABEL"}];
    for (let entryIndex in numbers) {
      csvHeaders.push({
        label: timeFrameToLabel(
          numbers[entryIndex].TIMESPAN,
          startEpoch,
          endEpoch,
          timeFrame
        ),
        key: "" + entryIndex,
      });
    }

    if (showLocationsInDownload) {
      const selectedLocations = locationIdArray.map(
        (_lid) => reportingLocations[_lid].NAME
      );

      csvData.unshift({
        0: selectedLocations.toString(),
        LABEL: "Locations",
      });
    }

    if (showDateRangeInDownload) {
      const dateString = convertEpochsToDateString(
        startEpoch,
        endEpoch,
        "MM/DD/YY",
        " - "
      );

      csvData.unshift({
        0: dateString,
        LABEL: "Date",
      });
    }

    if (this.state.rid === newRid) {
      this.setState({
        isLoading: false,
        paymentMethodsData: reportData.PAYMENT_METHODS,
        reportData: numbers,
        csvHeaders,
        csvData,
        hourly: [...reportData?.HOUR],
        daily: [...reportData?.DAY],
        rawData: reportData
      });
    }
  };

  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      let customTypeRows = Object.keys(reportData.CUSTOM_TYPE_BREAKDOWN).map(
        (key) => ({
          label: reportData.CUSTOM_TYPE_BREAKDOWN[key].PAYMENT_TYPE,
          numbers: reportData.CUSTOM_TYPE_BREAKDOWN[key],
          style: [CELL_TYPES.SECONDARY],
        })
      );

      let customTypeTotalNumbers = Object.keys(
        reportData.CUSTOM_TYPE_BREAKDOWN
      ).reduce(
        (obj, key) => {
          obj.NET_PAYMENT += reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_PAYMENT;
          obj.NET_TOTAL += reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_TOTAL;
          obj.PAYMENT_COUNT +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].NET_PAYMENT;
          obj.PROCESSING_FEES +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].PROCESSING_FEES;
          obj.REFUND_AMOUNT +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].REFUND_AMOUNT;
          obj.TOTAL_COLLECTED +=
            reportData.CUSTOM_TYPE_BREAKDOWN[key].TOTAL_COLLECTED;

          return obj;
        },
        {
          NET_PAYMENT: 0,
          NET_TOTAL: 0,
          PAYMENT_COUNT: 0,
          PROCESSING_FEES: 0,
          REFUND_AMOUNT: 0,
          TOTAL_COLLECTED: 0,
        }
      );

      let customTypeTotalRow = {
        label: "Custom Types",
        numbers: customTypeTotalNumbers,
      };

      rows = [
        {
          label: "Cash",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CASH,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Check",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CHECK,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.CARDS,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Gift Cards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.GIFT_CARDS,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "Loyalty Rewards",
          numbers: reportData.PAYMENT_METHOD_RECORDS.REWARD,
          style: [CELL_TYPES.REGULAR],
        },
        customTypeTotalRow,
        ...customTypeRows,
        {
          label: "Net Accounts Receivable",
          numbers: reportData.PAYMENT_METHOD_RECORDS.NET_AR,
          style: [CELL_TYPES.REGULAR],
        },
        {
          label: "All Payments",
          numbers: reportData.TOTALS,
          style: [CELL_TYPES.BOLD],
        },
      ];

      if (!reportData.PAYMENT_METHOD_RECORDS.CHECK?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Check");
      }

      if (!reportData.PAYMENT_METHOD_RECORDS?.NET_AR?.NET_TOTAL) {
        rows = rows.filter((row) => row.label !== "Net Accounts Receivable");
      }

      if (reportData.PAYMENT_METHOD_RECORDS?.PAYDOWN?.NET_TOTAL > 0) {
        rows.push({
          label: "Loan Paydown",
          numbers: nullifyIgnoredColumns(
            reportData.PAYMENT_METHOD_RECORDS.PAYDOWN,
            ["NET_TOTAL"]
          ),
          rowType: [CELL_TYPES.NEGATIVE],
          style: [CELL_TYPES.NEGATIVE],
        });
      }
    }

    return rows;
  };

  getColumns = () => {
    let {firstColLabel, csvColumns} = this.props;

    let constantColumns = PAYMENT_METHODS_COLUMNS;

    let columns = constantColumns.map((item) => {
      return {
        ...item,
        format: (value, row) => {
          return (
            <ReportingValueCell
              row={row}
              firstIndex={item.columnSelector}
              style={combineArraysIfExist(row.style, item.style)}
              dollarAmount={item.dollarAmount}
              percentage={item.percentage}
              number={item.number}
              format={item.format}
            />
          );
        },
      };
    });

    columns.unshift({
      label: (
        <div className="text-left">{firstColLabel ? firstColLabel : ""}</div>
      ),
      width: 50,
      sticky: true,
      format: (value, row) => {
        return <ReportingLabelCell row={row} style={row.style} />;
      },
    });

    let csvHeaders = (csvColumns ? csvColumns : columns)
      .filter((column) => !column.sticky)
      .map((column) => {
        return {
          label: column.label,
          key: column.columnSelector,
        };
      });

    csvHeaders.unshift({
      label: firstColLabel ? firstColLabel : "",
      key: "LABEL",
    });

    return {columns, csvHeaders};
  };

  render() {
    let {isLoading, paymentMethodsData, reportData, hourly, daily, rawData} = this.state;
    let {getRows} = this.props;

    let data;

    let {columns, csvHeaders} = this.getColumns();

    if (reportData) {
      data = this.convertDataToRows(paymentMethodsData);
    }

    return (
      <div>
        <ReportingFilter
          filenamePrefix={this.props.filenamePrefix}
          ref={(e) => (this.reportingFilter = e)}
          onGenerate={this.onGenerate}
          csvHeaders={this.state.csvHeaders}
          csvData={this.state.csvData}
          timeframePicker={this.props.timeframePicker}
          locationPicker={this.props.locationPicker}
          enableStripePayouts={this.props.enableStripePayouts}
          showExcludeThird={true}
        />

        {isLoading && <LoadingSpinner />}

        {reportData && reportData !== null && (
          <div>
            {this.props.graph && this.props.graph({hourly, daily}, rawData)}

            <ReportTable
              columns={this.props.convertDataToColumns(reportData)}
              numbers={reportData}
              constantRows={getRows(reportData)}
              expansionDependent={true}
            />

            <div className={"mt-5"}>
              <ReportTable
                data={data}
                constantColumns={columns}
                numbers={paymentMethodsData}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(FixedRowReport);
