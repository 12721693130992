import React, {Component} from "react";
import {
  FormInput,
  Modal,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import IngredientUnitDropdown from "../../../dropdowns/operations/ingredient-unit-dropdown";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class IngredientModal extends Component {
  state = {ingredient: null};

  open(ingredient = null) {
    this.setState({ingredient}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createIngredient({name, unit, sku, cost, barcode}) {
    let serverIngredient = await request("ingredients/", "POST", {
      NAME: name,
      COST: decimalToDollars(cost),
      SKU: sku,
      BARCODE: barcode,
      INGREDIENT_UNIT_ID: unit,
    });

    this.props.addState(serverIngredient);
    this.modal.close();
  }

  async saveIngredient({name, unit, sku, cost, barcode, enabled, unitName}) {
    const {ingredient} = this.state;

    let serverIngredient = await request(
      "ingredients/" + ingredient.ID,
      "PATCH",
      {
        NAME: name,
        COST: decimalToDollars(cost),
        SKU: sku,
        BARCODE: barcode,
        INGREDIENT_UNIT_ID: unit,
        ENABLED: enabled,
      }
    );

    serverIngredient.INGREDIENT_UNIT_NAME = unitName;

    this.props.updateState(serverIngredient);
    this.modal.close();
  }

  render() {
    const {ingredient} = this.state;

    return (
      <Modal
        buttonLabel={ingredient ? "Save" : "Add"}
        label={ingredient ? "Edit Ingredient" : "Save Ingredient"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            ingredient
              ? this.saveIngredient.bind(this)
              : this.createIngredient.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Unit name is required"),
          })}
          initialValues={{
            name: ingredient?.NAME ?? "",
            cost: ingredient ? toDollars(ingredient.COST) : "",
            sku: ingredient?.SKU ?? "",
            barcode: ingredient?.BARCODE ?? "",
            enabled: ingredient?.ENABLED ?? "1",
            unit: ingredient?.INGREDIENT_UNIT_ID,
            unitName: ingredient?.INGREDIENT_UNIT_NAME,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Tomatoes"
                  label="Ingredient Name"
                  name="name"
                />

                {ingredient && (
                  <FormBoolean
                    options={formikOptions}
                    label="Enabled"
                    name="enabled"
                  />
                )}

                <IngredientUnitDropdown
                  onChangeSoft={({label}) => setFieldValue("unitName", label)}
                  options={formikOptions}
                  name="unit"
                />

                <FormInput
                  options={formikOptions}
                  placeholder="2.50"
                  label="Unit Cost"
                  hint="Optional"
                  name="cost"
                />

                <FormInput
                  options={formikOptions}
                  placeholder="123456789"
                  hint="Optional"
                  label="Sku"
                  name="sku"
                />

                <FormInput
                  options={formikOptions}
                  placeholder="1111111"
                  hint="Optional"
                  label="Barcode"
                  name="barcode"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default IngredientModal;
