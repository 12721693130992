import React, {Component} from "react";
import FormRow from "../../../../../components/form-row";
import FormDateSpecify from "../../../../../components/form-date-specify";
import InputMask from "react-input-mask";
import {
  FormPhone,
  FormEmail,
  FormElement,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Trans} from "react-i18next";

class EmployeeContactPage extends Component {
  getI18NextKey(path) {
    return (
      "pages.onboarding.landing-page-types.i9-part1.employee-contact-page." +
      path
    );
  }

  render() {
    const {options} = this.props;

    return (
      <form className={"mb-3"}>
        <FormRow>
          <FormDateSpecify
            className={"mb-0"}
            mdy
            name={"dateOfBirth"}
            label={<Trans i18nKey={this.getI18NextKey("dateOfBirth.label")} />}
            options={options}
            flex
          />

          <FormElement
            options={options}
            label={<Trans i18nKey={this.getI18NextKey("ssn.label")} />}
            name={"ssn"}
          >
            {(value, error, onChange) => {
              return (
                <InputMask
                  mask={"999 99 9999"}
                  name={"ssn"}
                  type={"password"}
                  pattern={"[0-9]*"}
                  maskChar={null}
                  className={
                    "focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                  }
                  value={value}
                  flex
                  options={options}
                  onChange={onChange}
                  aria-describedby="email-error"
                  aria-invalid="true"
                />
              );
            }}
          </FormElement>

          <FormEmail
            flex
            name={"email"}
            label={<Trans i18nKey={this.getI18NextKey("email.label")} />}
            options={options}
          />

          <FormPhone
            flex
            name={"phone"}
            label={<Trans i18nKey={this.getI18NextKey("phone.label")} />}
            options={options}
          />
        </FormRow>
      </form>
    );
  }
}

export default EmployeeContactPage;
