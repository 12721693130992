import React, {Component} from "react";
import {RadioGroup} from "@headlessui/react";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class Cards extends Component {
  render() {
    const {value, onChange, onDeselect, data} = this.props;

    return (
      <RadioGroup value={value} onChange={onChange}>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {data.map((radioOption) => (
            <div
              onClick={() => {
                if (value === radioOption.value) {
                  onDeselect();
                }
              }}
            >
              <RadioGroup.Option
                key={radioOption.value}
                value={radioOption.value}
                className={({checked, active}) =>
                  classNames(
                    checked ? "border-transparent" : "border-neutral-border",
                    active ? "" : "",
                    "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                  )
                }
              >
                {({checked, active}) => (
                  <>
                    <div className="flex-1 flex">
                      <div className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className="block text-sm font-medium text-gray-900"
                        >
                          {radioOption.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-sm text-neutral-text"
                        >
                          {radioOption.description}
                        </RadioGroup.Description>
                        <RadioGroup.Description
                          as="span"
                          className="mt-6 text-sm font-medium text-gray-900"
                        >
                          {radioOption.subtitle}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-primary-text"
                      )}
                      aria-hidden="true"
                    />
                    <div
                      className={classNames(
                        checked
                          ? "border-primary-border"
                          : "border-transparent",
                        "border-2 absolute -inset-px rounded-lg pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            </div>
          ))}
        </div>
      </RadioGroup>
    );
  }
}

export default Cards;
