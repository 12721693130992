import {Component} from "react";
import {
  getConversationImage,
  getConversationLastMessage,
  getConversationName,
  getLastMessageDate,
} from "../../utils/messaging/message-helper";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class MessagePreview extends Component {
  state = {lastSent: ""};

  componentDidMount() {
    this.updateConversationLastSent();
  }

  componentDidUpdate(prevProps) {
    const {conversation} = this.props;
    const {conversation: prevConversation} = prevProps;

    if (conversation.DATE_LAST_MESSAGE !== prevConversation.DATE_LAST_MESSAGE) {
      this.updateConversationLastSent();
    }
  }

  componentWillUnmount() {
    clearInterval(this.conversationTimeStamp);
  }

  updateConversationLastSent() {
    const {conversation} = this.props;

    let lastSent = getLastMessageDate(conversation);

    this.setState({lastSent});

    if (this.conversationTimeStamp) {
      clearInterval(this.conversationTimeStamp);
    }

    this.conversationTimeStamp = setInterval(() => {
      lastSent = getLastMessageDate(conversation);

      this.setState({lastSent});
    }, 1000 * 60);
  }

  render() {
    const {conversation, isUnread} = this.props;
    const {account} = this.props.user;
    const {lastSent} = this.state;

    const conversationName = getConversationName(account, conversation);
    const conversationLastMessage = getConversationLastMessage(conversation);

    return (
      <div
        className={`flex flex-row h-16 rounded-md ${
          isUnread && "font-bold"
        } overflow-hidden whitespace-nowrap`}
      >
        <div className="flex justify-center items-center w-12 pl-1">
          {getConversationImage(account, conversation)}
        </div>

        <div className="flex flex-1 flex-col justify-evenly px-2 overflow-hidden">
          <div
            className={`text-sm ${
              isUnread ? "font-bold" : "font-medium"
            } overflow-hidden text-ellipsis`}
          >
            {conversationName}
          </div>

          <div className="text-sm text-gray-700 flex flex-row">
            <div
              style={{maxWidth: 170}}
              className={"text-ellipsis overflow-hidden"}
            >
              {conversationLastMessage}
            </div>
            <div>• {lastSent}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(MessagePreview));
