import React, {Component} from "react";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReviewAndConfirmFileModal from "../../../../modals/team/employee/onboarding/review-and-confirm-file-modal";
import PdfEditor from "../../../../components/pdf-editor";
import {Trans} from "react-i18next";
import {request} from "../../../../utils/request";

class CustomFormPage extends Component {
  state = {error: null};

  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.custom-form-page." + path;
  }

  async processSignatureFields(fields) {
    return Promise.all(
      fields.map(async (field) => {
        if (
          (field.TYPE === "SIGNATURE" || field.TYPE === "INITIALS") &&
          field.VALUE
        ) {
          const imageBytes = await fetch(field.VALUE).then((data) =>
            data.arrayBuffer()
          );

          field.VALUE = Array.from(new Uint8Array(imageBytes));
        }

        return field;
      })
    );
  }

  async submitDocument(document, fields) {
    const {employee, ID, instanceId} = this.props;
    fields = await this.processSignatureFields(fields);

    await request("company-documents/instance/complete", "POST", {
      ID: ID,
      CHECKLIST_INSTANCE_ID: instanceId,
      FILE_NAME: document.FILE_NAME,
      EMPLOYEE_ID: employee.ID,
      FIELDS: fields.filter((_field) => !!_field.VALUE),
    });
  }

  async submitForm() {
    const {document, fields} = await this.pdfEditor.verifyComplete();
    const {options, ID} = this.props;

    if (!document) {
      this.setState({
        error: <Trans i18nKey={this.getI18NextKey("error-message.label")} />,
      });
      return false;
    }

    const result = await this.confirmModal.open(document);

    if (fields.length > 0) {
      result.BUCKET = "onboarding-company-documents";
      await this.submitDocument(result, fields);
    }

    if (!result) {
      return !!result;
    }

    options.setFieldValue("CUSTOM_FORM_" + ID, result);
    return !!result;
  }

  render() {
    const {employee, ID, instanceId} = this.props;
    const {error} = this.state;

    return (
      <div>
        <ReviewAndConfirmFileModal
          ref={(e) => (this.confirmModal = e)}
          employee={employee}
          key={randomString(24)}
        />

        <div>
          <div
            className={classNames(
              "flex flex-row justify-center justify-items-center"
            )}
          >
            <PdfEditor
              ID={ID}
              instanceId={instanceId}
              employee={employee}
              className={"h-1/2"}
              fieldsDisabled
              extraStyle={"mt-4"}
              ref={(e) => (this.pdfEditor = e)}
            />
          </div>
          <div
            className={"flex flex-row justify-items-end justify-end align-end"}
          >
            <div className={"flex-col"}>
              {!!error && (
                <div className={"text-sm text-red-700"}> {error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomFormPage;
