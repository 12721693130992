import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import Alert from "../../components/alert";

class AlertService extends Component {
  render() {
    const {data} = this.props.alert;

    return (
      <div>
        {data.map((item) => {
          return <Alert {...item} />;
        })}
      </div>
    );
  }
}

export default setupReduxConnection(["alert"])(AlertService);
