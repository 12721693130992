import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  TAXABLE_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {
  Card,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TaxableReportPage extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      let {NET_SALES, NON_TAXABLE, TAXABLE, TAXES} = reportData;

      rows.push({
        label: "Total",
        numbers: {
          NET_SALES,
          NON_TAXABLE,
          TAXABLE,
          THIRD_PARTY_SALES: 0,
          TAXES,
        },
        rowType: CELL_TYPES.REGULAR,
      });
    }

    return rows;
  }

  renderBreakdownRow(row) {
    return (
      <div>
        <Table
          data={row.ITEMS}
          columns={[
            {
              label: "",
              value: "",
            },
          ]}
        />
      </div>
    );
  }

  renderCustomTable = (reportData) => {
    let {REPORTING_MODE} = this.props.shop.settings;

    return <div></div>;

    if (REPORTING_MODE === "AUDIT") {
      return (
        <div>
          {reportData.NON_TAXABLE_BREAKDOWN.length > 0 && (
            <Card label={"Non-Taxable Breakdown"}>
              <Table
                data={reportData.NON_TAXABLE_BREAKDOWN}
                columns={[
                  {
                    label: "Name",
                    value: "NAME",
                  },
                  {
                    label: "Non-Taxable Sales",
                    value: "TOTAL",
                    format: (value, row) => toDollars(value, true),
                  },
                ]}
                // expandable={(row) => this.renderBreakdownRow(row)}
              />
            </Card>
          )}
        </div>
      );
    }

    return <div></div>;
  };

  render() {
    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Taxable Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="taxes"
          endpoint="report/taxable"
          constantColumns={TAXABLE_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          renderCustomTable={this.renderCustomTable}
          alwaysShowCustomTable={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(TaxableReportPage);
