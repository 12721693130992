import React, {Component} from "react";
import {
  FormElement,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import InputMask from "react-input-mask";

class FormDateSpecify extends Component {
  state = {month: null, day: null, year: null};

  getNewValue() {
    let {month, day, year} = this.state;
    const {mdy} = this.props;

    return mdy ? `${month}/${day}/${year}` : `${year}-${month}-${day}`;
  }

  render() {
    let {options, name} = this.props;

    return (
      <FormElement {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <div className="flex flex-row">
              <InputMask
                className="w-14 mt-0 focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full focus:outline-none sm:text-sm rounded-md"
                mask={"99"}
                type={"text"}
                placeholder={"MM"}
                maskChar={null}
                onChange={(e) => {
                  let {value} = e.target;
                  this.setState({month: value});

                  let newString = this.getNewValue();
                  if (options) {
                    options.setFieldValue(name, newString);
                  } else {
                    onChange(newString);
                  }
                }}
              />

              <InputMask
                className="w-14 ml-5  mt-0 focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full focus:outline-none sm:text-sm rounded-md"
                placeholder={"DD"}
                mask={"99"}
                type={"text"}
                maskChar={null}
                onChange={async (e) => {
                  let {value} = e.target;
                  await this.setState({day: value});

                  let newString = this.getNewValue();
                  if (options) {
                    options.setFieldValue(name, newString);
                  } else {
                    onChange(newString);
                  }
                }}
              />

              <InputMask
                className="w-16 ml-5  mt-0 focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full focus:outline-none sm:text-sm rounded-md"
                mask={"9999"}
                type={"text"}
                maskChar={null}
                placeholder={"YYYY"}
                onChange={async (e) => {
                  let {value} = e.target;

                  await this.setState({year: value});

                  let newString = this.getNewValue();
                  if (options) {
                    options.setFieldValue(name, newString);
                  } else {
                    onChange(newString);
                  }
                }}
              />
            </div>
          );
        }}
      </FormElement>
    );
  }
}

export default FormDateSpecify;
