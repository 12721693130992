import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import MemoDetailsModal from "../../../modals/team/memo-details-modal";

class MemosPage extends Component {
  state = {memos: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let memos = await request("memos", "GET");

    this.setState({isLoading: false, memos});
  }

  render() {
    const {isLoading, memos} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Memos"
          description="Create messages that can be sent to some or all employees"
          button={{label: "Create Memo", onClick: () => this.memoModal.open()}}
        />

        <MemoDetailsModal
          ref={(e) => (this.memoModal = e)}
          addState={(memo) =>
            this.props.router.navigate("/memo/" + memo.UNIQUE_ID)
          }
        />

        <Table
          pagination
          data={memos}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) =>
                this.props.router.navigate("/memo/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "SUBJECT",
              label: "Subject",
            },
            {
              value: "REPLY_TO",
              label: "Reply Email",
            },
            {
              width: 1,
              value: "DATE_EXECUTED",
              label: "Date Sent",
              format: (value, _) => {
                return value ? moment(value).format("MM/DD/YY") : "Draft";
              },
            },
            {
              width: 1,
              label: "Date Created",
              value: "DATE_CREATED",
              format: (value) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(MemosPage);
