import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import {
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import GratuityModal from "../../../../modals/sales/gratuity/auto-gratuity-modal";

class GratuityPage extends Component {
  state = {gratuityRule: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const gratuityRule = await request("gratuity/" + id, "GET");

    this.setState({gratuityRule});
  }

  render() {
    const {gratuityRule} = this.state;

    if (gratuityRule === null) {
      return <Loading />;
    }

    return (
      <div>
        <GratuityModal
          ref={(e) => (this.gratuityModal = e)}
          onSubmit={() => this.syncState()}
          onDelete={() => this.props.router.navigate("/gratuity")}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{gratuityRule.NAME}</div>
              {gratuityRule.ENABLED ? (
                <SuccessBadge className="ml-2 mt-2 h-1/2">Enabled</SuccessBadge>
              ) : (
                <DangerBadge yellow className="ml-2 mt-2 h-1/2">
                  Disabled
                </DangerBadge>
              )}
            </div>
          }
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: gratuityRule.ENABLED
                        ? "Disable Rule"
                        : "Enable Rule",
                      onClick: () =>
                        request(
                          `gratuity/${
                            gratuityRule.ENABLED ? "disable" : "enable"
                          }/` + gratuityRule.ID,
                          "POST",
                          null
                        ).then(() => this.props.router.navigate("/gratuity")),
                    },
                  ],
                },
              ],
            },
          ]}
          description={"View and edit your automatic gratuity rule"}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/gratuity"},
            {label: "Gratuity Page", url: "/gratuity"},
          ]}
        />

        <TwoColumnList
          label="Auto Gratuity Information"
          description=""
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.gratuityModal.open(gratuityRule),
            },
          ]}
          data={[
            {label: "Gratuity Rule Name", value: gratuityRule.NAME},
            {
              label: `Threshold`,
              value: gratuityRule.IS_COUNT
                ? gratuityRule.THRESHOLD + " items"
                : toDollars(gratuityRule.THRESHOLD, true),
            },
            {
              label: `Gratuity Amount`,
              value: gratuityRule.IS_PERCENT
                ? gratuityRule.GRATUITY_AMOUNT + "%"
                : toDollars(gratuityRule.GRATUITY_AMOUNT, true),
            },
            {
              label: "Products/Categories",
              value:
                gratuityRule.TYPE === "ALL"
                  ? "All products"
                  : gratuityRule.TYPE === "PRODUCT"
                  ? gratuityRule.PRODUCTS.reduce(
                      (str, product, idx) =>
                        idx === gratuityRule.PRODUCTS.length - 1
                          ? str + product.NAME
                          : str + product.NAME + ", ",
                      ""
                    )
                  : gratuityRule.TYPE === "CATEGORY"
                  ? gratuityRule.CATEGORIES.reduce(
                      (str, category, idx) =>
                        idx === gratuityRule.CATEGORIES.length - 1
                          ? str + category.NAME
                          : str + category.NAME + ", ",
                      ""
                    )
                  : "-",
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(GratuityPage);
