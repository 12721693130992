import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormTextArea,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import {showErrorAlert} from "../../../utils/alert-helper";

class RevenueCenterModal extends Component {
  state = {center: null};

  open(center = null) {
    this.setState({center}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createCenter({name, description}) {
    const taxPayload = {
      NAME: name,
      DESCRIPTION: description,
    };

    const serverPayload = await request("centers", "POST", taxPayload);

    this.props.addState(serverPayload);
  }

  async saveCenter({name, description}) {
    const {center} = this.state;

    const taxPayload = {
      NAME: name,
      DESCRIPTION: description,
    };

    const serverCategory = await request(
      "centers/" + center.ID,
      "PATCH",
      taxPayload
    );

    this.props.updateState(serverCategory.ID, serverCategory);
    this.modal.close();
  }

  render() {
    const {center} = this.state;

    return (
      <Modal
        buttonLabel={center ? "Save" : "Create"}
        label={center ? "Edit Revenue Center" : "Create Revenue Center"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            center ? this.saveCenter.bind(this) : this.createCenter.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: center?.NAME ?? "",
            description: center?.DESCRIPTION ?? "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Revenue center name is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  flex
                  name="name"
                  label="Name"
                  placeholder="Patio"
                  options={formikOptions}
                />

                <FormTextArea
                  name="description"
                  label="Description"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default RevenueCenterModal;
