import React, {Component} from "react";
import {
  Modal,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";

class ReimbursementModal extends Component {
  state = {payrollId: null, employeeId: null, name: null, reimbursement: null};

  openNew(payrollId, employeeId, name) {
    this.setState({payrollId, employeeId, name, reimbursement: {AMOUNT: ""}}, this.modal.open());
  }

  open(payrollId, employeeId, name, reimbursement) {
    this.setState({payrollId, employeeId, name, reimbursement}, this.modal.open());
  }

  delete = async () => {
    await this.submitReimbursement(0, null);
    await this.props.refresh();

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {amount, description} = values;

    await this.submitReimbursement(amount, description)
    await this.props.refresh();

    this.modal.close();
  };

  submitReimbursement = async (amount, description) => {
    let {payrollId, employeeId, reimbursement} = this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: employeeId,
      AMOUNT: decimalToDollars(amount),
      DESCRIPTION: description,
      ID: reimbursement?.ID
    };

    await request("payroll/run-payroll/set-reimbursement", "POST", payload);
  }

  render() {
    let {name, reimbursement} = this.state;

    return (
      <Modal
        label={`Reimbursement for ${name}`}
        buttonLabel="Save"
        deleteLabel="Delete"
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            amount: reimbursement ? toDollars(reimbursement.AMOUNT): "",
            description: reimbursement?.DESCRIPTION
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div className="text-sm font-semibold">This is a one time reimbursement for this pay period</div>
                <FormInput
                  label={"Amount (in $)"}
                  name={"amount"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Description"}
                  name={"description"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ReimbursementModal;
