import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class SubscriptionListModal extends Component {
  state = {subscriptionLists: [], patrons: []};

  open(patrons = []) {
    this.setState({patrons}, async () => {
      await this.syncState();
      this.formikRef && this.formikRef.resetForm({});

      this.modal.open();
    });
  }

  async syncState() {
    const lists = await request("text/marketing/lists", "GET");

    const subscriptionLists = lists.filter((list) => !list.IS_DEFAULT);
    this.setState({subscriptionLists});
  }

  async submit({listId}) {
    const {patrons} = this.state;

    await request("text/marketing/list/patrons/", "POST", {
      LIST_ID: listId,
      PATRONS: patrons,
    });

    this.props.onSubmit && this.props.onSubmit(listId);
    this.modal.close();
  }

  render() {
    const {subscriptionLists} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        label={"Select Subscription List"}
      >
        <Formik
          onSubmit={this.submit.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            listId: Yup.string().required("Subscriber List is required"),
          })}
          initialValues={{listId: null}}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  data={subscriptionLists.map((list) => ({
                    label: list.NAME,
                    value: list.ID,
                  }))}
                  options={formikOptions}
                  label={"Subscriber List"}
                  name={"listId"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SubscriptionListModal;
