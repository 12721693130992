import React, {Component} from "react";
import {
  PageHeadings,
  Loading,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import StoreBanksExpansionComponent from "../../../../features/operations/cash/store-banks/store-banks-expansion-component";

class StoreBankPage extends Component {
  state = {bank: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    request("storebanks/" + ID, "GET").then((bank) => {
      this.setState({bank});
    });
  }

  getBankData() {
    const {bank} = this.state;

    const openAmount = bank.cashDrawers.reduce(
      (total, drawer) => total + drawer.AMOUNT_OPEN,
      0
    );

    const closeAmount =
      bank.cashDrawers.reduce(
        (total, drawer) => total + drawer.AMOUNT_CLOSE,
        0
      ) + bank.payins.reduce((total, payin) => total + payin.AMOUNT, 0);

    const payinTotal =
      bank.cashDrawers.reduce(
        (total, drawer) => total + drawer.PAYIN_TOTAL,
        0
      ) + bank.payins.reduce((total, payin) => total + payin.AMOUNT, 0);

    const cashSales = bank.cashDrawers.reduce(
      (total, drawer) => total + drawer.CASH_SALES.TOTAL_COLLECTED ?? 0,
      0
    );

    const expectedDepositAmount = payinTotal + cashSales;
    const openCloseDifferential = closeAmount - openAmount;
    const actualDeposit = bank.AMOUNT_DEPOSIT;

    return {
      expectedDepositAmount,
      openCloseDifferential,
      actualDeposit,
    };
  }

  getCashDrawersData() {
    const {bank} = this.state;
    const {cashDrawers} = bank;
    const drawersData = [];

    const bankPayins = bank.payins.reduce(
      (total, item) => total + item.AMOUNT,
      0
    );

    const totals = {
      DATE_OPEN: null,
      DEVICE_NAME: "Total",
      PAYIN_TOTAL: 0,
      AMOUNT_OPEN: 0,
      AMOUNT_CLOSE: 0,
      AMOUNT_EXPECTED: 0,
      CASH_SALES: 0,
      EXPECTED_DEPOSIT: bankPayins,
      OPEN_CLOSE_DIFFERENCE: 0,
      AMOUNT_DEPOSIT: 0,
      DEPOSIT_DIFFERENCE: 0,
      CASH_REMAINING: 0,
      PAYINS: [],
    };

    for (const drawer of cashDrawers) {
      const amountExpected =
        drawer.AMOUNT_EXPECTED ??
        drawer.PAYIN_TOTAL + drawer.CASH_SALES.TOTAL_COLLECTED;

      const drawerData = {
        ...drawer,
        CASH_SALES: drawer.CASH_SALES.TOTAL_COLLECTED,
        AMOUNT_EXPECTED: amountExpected,
        EXPECTED_DEPOSIT: amountExpected - drawer.CASH_REMAINING,
        OPEN_CLOSE_DIFFERENCE: drawer.AMOUNT_CLOSE - drawer.CASH_REMAINING,
        DEPOSIT_DIFFERENCE: -amountExpected + drawer.CASH_REMAINING,
      };
      delete drawerData.AMOUNT_DEPOSIT;

      totals.PAYIN_TOTAL += drawer.PAYIN_TOTAL ?? 0;
      totals.CASH_SALES += drawer.CASH_SALES.TOTAL_COLLECTED ?? 0;
      totals.AMOUNT_OPEN += drawer.AMOUNT_OPEN ?? 0;
      totals.AMOUNT_CLOSE += drawer.AMOUNT_CLOSE ?? 0;
      totals.AMOUNT_EXPECTED += amountExpected ?? 0;
      totals.EXPECTED_DEPOSIT += amountExpected - drawer.CASH_REMAINING ?? 0;
      totals.OPEN_CLOSE_DIFFERENCE +=
        drawer.AMOUNT_CLOSE - drawer.CASH_REMAINING ?? 0;
      totals.CASH_REMAINING += drawer.CASH_REMAINING ?? 0;
      totals.PAYINS.push(...drawer.PAYINS);

      drawersData.push(drawerData);
    }

    totals.AMOUNT_DEPOSIT = bank.AMOUNT_DEPOSIT;
    totals.DEPOSIT_DIFFERENCE = totals.AMOUNT_DEPOSIT - totals.EXPECTED_DEPOSIT;
    totals.BANK_PAYIN_TOTAL = bankPayins;
    totals.AMOUNT_EXPECTED_BANK = totals.AMOUNT_EXPECTED + bankPayins;
    totals.PAYINS.push(...bank.payins);

    drawersData.push({
      DATE_OPEN: bank.DATE_OPEN,
      DEVICE_NAME: "Bank",
      BANK_PAYIN_TOTAL: bankPayins,
      EXPECTED_DEPOSIT: bankPayins,
      AMOUNT_DEPOSIT: bank.AMOUNT_DEPOSIT,
      PAYINS: bank.payins,
      DEPOSIT_DIFFERENCE: bank.AMOUNT_DEPOSIT - bankPayins,
      AMOUNT_EXPECTED_BANK: bankPayins + totals.AMOUNT_EXPECTED,
    });

    drawersData.push(totals);

    return drawersData;
  }

  formatRow(val, row) {
    if (row.DEVICE_NAME === "Total") {
      return <div className="font-bold">{val}</div>;
    }

    return <div>{val}</div>;
  }

  render() {
    const {bank} = this.state;

    if (!bank) {
      return <Loading />;
    }

    const drawerData = this.getCashDrawersData();

    return (
      <>
        <PageHeadings
          label={"Store Bank"}
          description={moment(bank.DATE_OPEN).format("LLL")}
        />

        <Table
          className={"mt-4"}
          data={drawerData}
          expandable={(row) => <StoreBanksExpansionComponent row={row} />}
          columns={[
            {
              value: "DEVICE_NAME",
              label: "Device Name",
              width: 1,
              format: (val, row) => this.formatRow(val, row),
            },
            {
              value: "AMOUNT_OPEN",
              label: "Starting Cash",
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              width: 1,
            },
            {
              value: "PAYIN_TOTAL",
              label: "Pay In / Out",
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              width: 2,
            },
            {
              value: "CASH_SALES",
              label: "Cash Sales",
              format: (val, row) =>
                val === undefined
                  ? ""
                  : this.formatRow(toDollars(val, true), row),
              width: 1,
            },
            {
              value: "AMOUNT_EXPECTED",
              label: "Expected in Drawer",
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              width: 1,
              tooltip:
                "How much cash should be in the drawer. The value is equal to the opening amount plus pay in events and cash sales less pay out events.",
            },
            {
              value: "CASH_REMAINING",
              label: "Cash Retained",
              width: 1,
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              tooltip:
                "The amount of cash that was left in the drawer after it was closed. This is entered alongside the close amount.",
            },
            {
              value: "AMOUNT_CLOSE",
              label: "Amount Close",
              width: 1,
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              tooltip: "The closing amount as entered by the closing employee.",
            },
            {
              value: "OPEN_CLOSE_DIFFERENCE",
              label: "Excess Cash on Hand",
              width: 1,
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              tooltip: "The closing amount less the cash retained.",
            },
            {
              value: "BANK_PAYIN_TOTAL",
              label: "Bank Pay In / Out",
              width: 1,
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              tooltip:
                "The sum of all pay in and pay out events made to the bank.",
            },
            {
              value: "EXPECTED_DEPOSIT",
              label: "Expected Deposit",
              width: 1,
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              tooltip:
                "The deposit the system expects. This is equal to expected in bank less cash retained.",
            },
            {
              value: "AMOUNT_DEPOSIT",
              label: "Deposit Amount",
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              width: 1,
              tooltip:
                "The deposit that the employee entered when they closed the bank.",
            },
            {
              value: "DEPOSIT_DIFFERENCE",
              label: "Deposit Differential",
              format: (val, row) =>
                val !== undefined
                  ? this.formatRow(toDollars(val, true), row)
                  : "",
              width: 1,
              tooltip:
                "This is the difference between the deposit amount and the expected deposit.",
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(StoreBankPage);
