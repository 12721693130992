import {fetchLineItemSelection} from "@frostbyte-technologies/frostbyte-tickets/dist/helpers/cart-helper";

export function calculateInvoicingLineItemPrice(product) {
  const {TAX_DISABLED: disabled, TAX_RATES: rates = []} = product;
  const base = product.PRICE * (product.QUANTITY ?? 1);
  let tax = 0;
  let amount = base;
  let lines = [];

  rates.sort((a, b) => b.IS_INCLUSIVE - a.IS_INCLUSIVE);

  for (let rate of rates) {
    const ratePayload = {
      PRODUCT_TAX_ID: null,
      TAX_ID: rate.ID,
      AMOUNT: 0,
    };

    if (rate.PRODUCT) {
      ratePayload.PRODUCT_TAX_ID = rate.PRODUCT.ID;
    }

    if (rate.IS_INCLUSIVE) {
      const percent = decimalPercent(rate.RATE);

      const rateAmount = Math.round(base / percent);
      const rateTax = base - rateAmount;

      amount -= rateTax;
      tax += rateTax;

      ratePayload.AMOUNT = rateTax;
    } else {
      const rateTax = Math.round((rate.RATE / 100) * base);

      tax += rateTax;

      ratePayload.AMOUNT = rateTax;
    }

    lines.push(ratePayload);
  }

  if (!rates && product.PRICE_TAX) {
    tax = product.PRICE_TAX * product.QUANTITY;
  }

  return {amount, tax, total: amount + tax, lines};
}

function decimalPercent(percent) {
  return percent / 100 + 1;
}

export function selectModifierOption(
  product,
  modifier,
  selections,
  option,
  quantity
) {
  let modifiers = selections.filter((item) => {
    return item.MODIFIER_ID === modifier.ID;
  });

  let modifierIndex = selections.findIndex((item) => {
    return item.MODIFIER_ID === modifier.ID;
  });

  let optionIndex = selections.findIndex((item) => {
    return item.MODIFIER_ID === modifier.ID && item.OPTION_ID === option.ID;
  });

  let payload = fetchLineItemSelection({
    rates: product.TAX_RATES,
    modifier,
    quantity,
    option,
  });

  const isVariant = modifier.TYPE === 5;
  const isOptionSelected = optionIndex !== -1;
  const isModifierSelected = modifierIndex !== -1;
  const isSingleOption = [0, 1, 5].includes(modifier.TYPE);
  const isQuantitySelect = [1, 3].includes(modifier.TYPE);
  const modifierSelection = selections[optionIndex];

  if (isOptionSelected) {
    if (isQuantitySelect) {
      selections.splice(optionIndex, 1);

      if (modifierSelection.QUANTITY !== quantity) {
        selections.push(payload);
      }
    } else if (!isVariant) {
      selections.splice(optionIndex, 1);
    }
  } else {
    if (isSingleOption && isModifierSelected) {
      selections.splice(modifierIndex, 1);
    }

    if (
      modifier.MAX_SELECTIONS > 0 &&
      modifiers.length === modifier.MAX_SELECTIONS
    ) {
      return selections;
    }

    selections.push(payload);
  }

  return selections;
}

export function fetchDefaultProductSelections(product) {
  const selections = [];

  for (let item of product.CUSTOMIZATIONS) {
    let option = item.OPTIONS.find((option) => {
      return option.ID === item.DEFAULT_OPTION;
    });

    let defaultOverrides = product?.DEFAULTS ?? [];
    let defaultCustomizationProductOverride = defaultOverrides.find((x) => {
      return x.CUSTOMIZATION_ID === item.ID;
    });

    if (defaultCustomizationProductOverride) {
      option = item.OPTIONS.find((option) => {
        return option.ID === defaultCustomizationProductOverride.OPTION_ID;
      });
    }

    if (!option) {
      continue;
    }

    const lineItemSelection = fetchLineItemSelection({
      rates: product.TAX_RATES,
      modifier: item,
      quantity: 1,
      option,
    });

    selections.push(lineItemSelection);
  }

  return selections;
}
