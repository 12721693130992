import React, {Component} from "react";
import {
  Rect,
  Ellipse,
  RegularPolygon,
  Transformer,
  Text,
  Group,
} from "react-konva";

class Table extends Component {
  getPosition() {
    return this.group.position();
  }

  componentDidMount() {
    this.drawTransformBox();
  }

  componentDidUpdate() {
    this.drawTransformBox();
  }

  drawTransformBox() {
    if (this.trRef) {
      this.trRef.nodes([this.table]);
      this.trRef.getLayer().batchDraw();
    }
  }

  updateTransform() {
    const node = this.table;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const {table} = this.props;

    table.OBJECT.POSITION.X = node.x();
    table.OBJECT.POSITION.Y = node.y();
    table.OBJECT.POSITION.ROTATION = node.rotation();
    table.OBJECT.WIDTH = node.width() * scaleX;
    table.OBJECT.HEIGHT = node.height() * scaleY;
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);

    this.props.updateTable(table);
  }

  updateDrag(e) {
    const {table} = this.props;

    table.OBJECT.POSITION.X += e.target.x();
    table.OBJECT.POSITION.Y += e.target.y();

    this.props.updateTable(table);
  }

  render() {
    const {table, selected} = this.props;

    return (
      <>
        <Group
          key={"GROUP_" + table.ID}
          ref={(e) => (this.group = e)}
          draggable
          onDragEnd={(e) => this.updateDrag(e)}
        >
          {table.OBJECT.SHAPE === "SQUARE" && (
            <Rect
              ref={(e) => (this.table = e)}
              height={table.OBJECT.HEIGHT}
              key={"RECT" + table.ID}
              width={table.OBJECT.WIDTH}
              fill={selected ? "#A5B4FC" : "white"}
              rotation={table.OBJECT.POSITION.ROTATION}
              stroke={selected ? "#818CF8" : "black"}
              strokeWidth={selected ? 2 : 1}
              originY={table.OBJECT.HEIGHT / 2}
              originX={table.OBJECT.WIDTH / 2}
              x={table.OBJECT.POSITION.X}
              y={table.OBJECT.POSITION.Y}
              onClick={() => this.props.selectTable()}
              onTransformEnd={() => this.updateTransform()}
            />
          )}

          {table.OBJECT.SHAPE === "TRIANGLE" && (
            <RegularPolygon
              ref={(e) => (this.table = e)}
              key={"TRI_" + table.ID}
              sides={3}
              width={table.OBJECT.WIDTH}
              height={table.OBJECT.WIDTH}
              fill={selected ? "#A5B4FC" : "white"}
              stroke={selected ? "#818CF8" : "black"}
              strokeWidth={selected ? 2 : 1}
              rotation={table.OBJECT.POSITION.ROTATION}
              x={table.OBJECT.POSITION.X}
              y={table.OBJECT.POSITION.Y}
              onDragEnd={(e) => this.updateDrag(e)}
              onTransformEnd={() => this.updateTransform()}
              onClick={() => this.props.selectTable()}
            />
          )}
          {table.OBJECT.SHAPE === "CIRCLE" && (
            <Ellipse
              ref={(e) => (this.table = e)}
              r={table.OBJECT.WIDTH / 2}
              key={"CIRC_" + table.ID}
              fill={selected ? "#A5B4FC" : "white"}
              stroke={selected ? "#818CF8" : "black"}
              strokeWidth={selected ? 2 : 1}
              originY={table.OBJECT.HEIGHT / 2}
              originX={table.OBJECT.WIDTH / 2}
              radius={{x: table.OBJECT.WIDTH / 2, y: table.OBJECT.HEIGHT / 2}}
              x={table.OBJECT.POSITION.X}
              y={table.OBJECT.POSITION.Y}
              name={table.NAME}
              onTransformEnd={() => this.updateTransform()}
              onClick={() => this.props.selectTable()}
            />
          )}

          <Text
            key={"TEXT" + table.ID}
            text={table.NAME}
            x={table.OBJECT.POSITION.X}
            y={table.OBJECT.POSITION.Y}
            rotation={table.OBJECT.POSITION.ROTATION}
            fontStyle={"bold"}
            fontSize={24}
            fill={selected ? "#312E81" : "black"}
            align={"left"}
          />
        </Group>

        {selected && (
          <Transformer
            ref={(e) => (this.trRef = e)}
            rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
            snapTolerance={10}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>
    );
  }
}

export default Table;
