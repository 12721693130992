import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {PERMISSIONS} from "../../utils/constants";
import PermissionModal from "../../modals/team/permission-modal";

class RolePermissions extends Component {
  render() {
    const {permissions, onSubmit, read} = this.props;

    const actionButtons = [];
    if (!read) {
      actionButtons.push({
        label: "Edit",
        onClick: (row) => {
          this.permissionModal.open(row.PERMISSIONS, permissions);
        },
      });
    }

    const permissionData = Object.keys(PERMISSIONS).map((key) => {
      const activePermissions = [];

      for (let {id, label} of Object.values(PERMISSIONS[key])) {
        if (permissions.indexOf(id) !== -1) {
          activePermissions.push(label);
        }
      }

      let permissionText = "None";
      if (activePermissions.length > 0) {
        permissionText = activePermissions.join(", ");
      }

      return {
        AREA: key,
        ACTIVE: permissionText,
        PERMISSIONS: Object.values(PERMISSIONS[key]),
      };
    });

    return (
      <div>
        <PermissionModal
          ref={(e) => (this.permissionModal = e)}
          onSubmit={onSubmit}
        />

        <Table
          data={permissionData}
          ref={(e) => (this.tableRef = e)}
          actionButtons={actionButtons}
          columns={[
            {
              width: 1,
              value: "AREA",
              label: "Area",
            },
            {
              value: "ACTIVE",
              label: "Active Permissions",
            },
          ]}
        />
      </div>
    );
  }
}

export default RolePermissions;
