import React, {Component} from "react";
import CompaniesTable from "../../../../tables/shop/company-structure/companies-table";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import ShowTextModal from "../../../../modals/general/show-text-modal";

class CompaniesTab extends Component {
  render() {
    const {companies, locations} = this.props.companyStructure;

    const note = {
      NOTE_NAME: "Contact Support",
      NOTE: "Contact Dripos support to create new companies",
    };

    return (
      <div>
        <ShowTextModal ref={(e) => (this.modalRef = e)} />

        <Card
          label={"Companies"}
          button={{
            label: "Create Company +",
            onClick: () => this.modalRef.open(note),
          }}
        >
          <CompaniesTable companies={companies} locations={locations} />
        </Card>
      </div>
    );
  }
}

export default CompaniesTab;
