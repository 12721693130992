import {Component} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../redux";
import {getCalendarWeekStartEpoch} from "../../redux/scheduling";
import {VIEWS} from "./scheduler";
import {calculateWeekStartEpochFromMoment} from "../../utils/scheduling-helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class WeekCalendar extends Component {
  state = {
    beginningOfCurrentMonth: moment().startOf("month"),
    days: [],
    startEpoch: null
  };

  // //updateObj with attributes of startEpoch, endEpoch
  onChange = (startEpoch) => {
    this.setState(startEpoch, this.props.onChange(startEpoch));
  };

  async componentDidMount() {
    await this.props.getCalendarWeekStartEpoch();
    // this.
    let startEpoch = await this.props.getCalendarWeekStartEpoch();
    let endEpoch = moment(startEpoch).add(6, "days");

    //
    // if (!startEpoch) {
    //   startEpoch = moment().startOf("day").valueOf();
    //   endEpoch = startEpoch;
    // }

    // this.onChange({startEpoch, endEpoch});

    this.setState({
      startEpoch,
      endEpoch,
      beginningOfCurrentMonth: moment(startEpoch).startOf("month"),
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        moment(startEpoch).startOf("month").valueOf()
      ),
    });
  }

  setEpochs(startEpoch) {
    this.onChange(startEpoch);

    let beginningOfCurrentMonth = moment(startEpoch).startOf("month");

    // this.setState({
    //   beginningOfCurrentMonth,
    //   days: this.generateDaysOfNewMonth(
    //     startEpoch,
    //     endEpoch,
    //     beginningOfCurrentMonth.valueOf()
    //   ),
    // });
  }

  goBackMonth = () => {
    let {beginningOfCurrentMonth} = this.state;
    let {startEpoch, endEpoch} = this.state;

    if (!(startEpoch || endEpoch)) {
      return;
    }

    beginningOfCurrentMonth.subtract(1, "month").startOf("month");

    this.setState({
      beginningOfCurrentMonth,
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        beginningOfCurrentMonth.valueOf()
      ),
    });
  };

  goForwardMonth = () => {
    let {beginningOfCurrentMonth} = this.state;
    let {startEpoch, endEpoch} = this.state;

    if (!(startEpoch || endEpoch)) {
      return;
    }

    beginningOfCurrentMonth.add(1, "month").startOf("month");

    this.setState({
      beginningOfCurrentMonth,
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        beginningOfCurrentMonth.valueOf()
      ),
    });
  };

  setYear = (year) => {
    let {startEpoch, endEpoch} = this.state;

    this.setState({
      beginningOfCurrentMonth: moment(startEpoch)
        .set("year", year)
        .startOf("month"),
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        moment(startEpoch).set("year", year).startOf("month").valueOf()
      ),
    });
  };

  generateDaysOfNewMonth = (startEpoch, endEpoch, monthStartEpoch) => {
    let monthStartMoment = moment(monthStartEpoch);

    let endOfMonth = monthStartMoment.clone().endOf("month");
    let days = [];

    let currentDayMoment = monthStartMoment.clone();
    while (
      endOfMonth.diff(currentDayMoment, "days") >= 0 &&
      currentDayMoment.format("MM") === endOfMonth.format("MM")
    ) {
      days.push({
        moment: currentDayMoment.clone(),
        weekday: currentDayMoment.day(),
        date: currentDayMoment.format("DD/MM"),
        isCurrentMonth: true,
        isToday:
          currentDayMoment.format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY"),
      });
      currentDayMoment.add(1, "day");
    }

    let firstTrackedDay = days[0].moment.clone();

    let firstDay = this.props.shop.settings.SCHEDULER_FIRST_DAY;

    while (firstTrackedDay.day() !== parseInt(firstDay)) {
      firstTrackedDay.subtract(1, "day");

      days.unshift({
        moment: firstTrackedDay.clone(),
        date: firstTrackedDay.format("DD/MM"),
        weekday: firstTrackedDay.day(),
      });
    }

    let lastTrackedDay = days[days.length - 1].moment.clone();

    while (days.length < 42) {
      lastTrackedDay.add(1, "day");
      days.push({
        moment: lastTrackedDay.clone(),
        date: lastTrackedDay.format("DD/MM"),
        weekday: lastTrackedDay.day(),
      });
    }

    return days;
  };

  updatedDays() {
    let {days} = this.state;
    let {currentView, currentDateString} = this.props.scheduling;
    let isDayMode = currentView === VIEWS.DAY;
    let {SCHEDULER_FIRST_DAY} = this.props.shop.settings;

    let firstSelectedEpoch, secondSelectedEpoch;

    if(isDayMode) {
      firstSelectedEpoch = moment(currentDateString).startOf("day").valueOf();
      secondSelectedEpoch = moment(currentDateString).startOf("day").valueOf();
    } else {
      firstSelectedEpoch =  calculateWeekStartEpochFromMoment(moment(currentDateString), SCHEDULER_FIRST_DAY);
      secondSelectedEpoch = moment(firstSelectedEpoch).add(6, "days");
    }

    for (let day in days) {
      days[day].isSelected = days[day].moment.valueOf() >= firstSelectedEpoch && secondSelectedEpoch >= days[day].moment.valueOf();
    }

    return days;
  }

  onClick = async (day) => {
    let isDayMode = this.props.scheduling.currentView === VIEWS.DAY;
    let startEpoch, endEpoch;

    if(!isDayMode){
      let weekStart = await this.props.getWeekStartEpochFromMoment(day.moment);
      startEpoch = weekStart;
      endEpoch = moment(weekStart).add(6, "days").valueOf();
    } else {
      startEpoch = day.moment;
      endEpoch = startEpoch;
    }

    this.setState({
      startEpoch,
      endEpoch,
      beginningOfCurrentMonth: moment(startEpoch).startOf("month"),
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        moment(startEpoch).startOf("month").valueOf()
      ),
    });

    this.onChange({startEpoch, endEpoch});
  };

  render() {
    let {beginningOfCurrentMonth, days} = this.state;
    let isDayMode = this.props.scheduling.currentView === VIEWS.DAY;

    let title = beginningOfCurrentMonth.format("MMMM");

    let currentYear = moment().format("YYYY");
    let calendarYear = moment(beginningOfCurrentMonth).format("YYYY");

    let dayArray = ["S", "M", "T", "W", "T", "F", "S"];

    let {SCHEDULER_FIRST_DAY} = this.props.shop.settings;

    if(!days){
      return <div></div>
    }
    days = this.updatedDays();

    let weekGroupings = days.reduce((dict, day, index) => {
      let week = Math.floor(index / 7);

      if (!dict[week]) {
        dict[week] = [day];
      } else {
        dict[week].push(day);
      }
      return dict;
    }, {});

    let weeks = Object.values(weekGroupings);

    return (
      <div className="w-72 p-4 pt-0 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>

            <ChevronLeftIcon
              onClick={this.goBackMonth}
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>

          <div className="flex-auto font-semibold space-x-2">
            <span>{title}</span>

            <select
              id="location"
              name="location"
              className="mt-1 w-20 pl-1 pr-1 py-1 text-base font-semibold border-gray-300 sm:text-sm rounded-md"
              value={calendarYear}
              onChange={(e) => {
                this.setYear(e.target.value);
              }}
            >
              {[...Array(5).keys()].reverse().map((item) => {
                return <option>{currentYear - item}</option>;
              })}
            </select>
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>

            <ChevronRightIcon
              onClick={this.goForwardMonth}
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </div>

        <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
          {dayArray.map((item, index) => {
            return <div>{dayArray[(index + parseInt(SCHEDULER_FIRST_DAY)) % 7]}</div>;
          })}
        </div>

        <div className="isolate mt-1 grid gap-px rounded-sm bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {weeks.map((week) => {
            return (
              <div
                className={classNames(
                  !isDayMode && "week",
                  "gap-px grid grid-cols-7"
                )}
              >
                {week.map((day, dayIdx) => (
                  <button
                    key={day.date}
                    type="button"
                    className={classNames(
                      "py-1.5 focus:z-10",
                      !isDayMode && (day.isCurrentMonth ? "dayInMonth" : "dayOutOfMonth"),
                      isDayMode && (day.isCurrentMonth ? "bg-white" : "bg-gray-50"),
                      isDayMode && "hover:bg-gray-200",
                    (day.isSelected || day.isToday) && "font-semibold",
                      day.isSelected && "text-white",
                      !day.isSelected &&
                        day.isCurrentMonth &&
                        !day.isToday &&
                        "text-gray-900",
                      !day.isSelected &&
                        !day.isCurrentMonth &&
                        !day.isToday &&
                        "text-gray-400",
                      day.isToday && !day.isSelected && "text-indigo-600",
                      dayIdx === 0 && "rounded-tl-sm",
                      dayIdx === 6 && "rounded-tr-sm",
                      dayIdx === days.length - 7 && "rounded-bl-sm",
                      dayIdx === days.length - 1 && "rounded-br-sm"
                    )}
                    onClick={() => {
                      this.onClick(day);
                    }}
                  >
                    <div
                      className={classNames(
                        "mx-auto flex h-5 w-5 items-center justify-center rounded-sm",
                        day.isSelected && day.isToday && "bg-indigo-600",
                        day.isSelected && !day.isToday && "bg-gray-900"
                      )}
                    >
                      {day.moment.format("D")}
                    </div>
                  </button>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "scheduling"])(WeekCalendar);
