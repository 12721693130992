import React, {Component} from "react";
import {Card, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PayrollCompanySetupPage extends Component {
  render() {
    let {NAME} = this.props.shop.location;
    let {router} = this.props;

    let tabs = [
      {id: "menu", label: "Dripos Menu for Third Party"},
      {id: "tickets", label: "Orders into Ticket Screen"},
      {id: "sales", label: "Track sales w/ Dripos"},
    ];

    return (
      <Card className=" w-full p-6">
        <div className={"lg:w-2/3 m-auto space-y-6"}>
          <div>
            <div className={"text-right w-32"}>
              <img
                className=" h-auto rounded-lg"
                src={require("../../../assets/images/logo_type.png")}
                alt="image description"
              />

              <div className="font-bold text-lg">Third Party Delivery</div>
            </div>
          </div>

          <div className="w-1/2 flex flex-row justify-start justify-items-start m-auto space-x-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-indigo-600 ">
            <FontAwesomeIcon icon={"calendar"} className="h-4 w-4" />
            <div>Contact Support to Setup Onboarding Call</div>
          </div>

          <Tab data={tabs}>
            {(id) => {
              if (id === "menu") {
                return (
                  <div className="mt-7 flex flex-row">
                    <div className=" space-y-4 pr-5">
                      <div className="text-xl font-bold">
                        Have the Dripos menu auto sync to third party
                      </div>

                      <div className="text-gray-800">
                        Managing multiple menus is frustrating. Dripos
                        synchronizes your menus to create a seamless third party
                        delivery experience. So if you disable a product on the
                        point of sale, it will automatically disable it on
                        DoorDash or UberEats.
                      </div>
                    </div>

                    {/*<div className=" w-1/2">*/}
                    {/*  <img*/}
                    {/*    className="w-auto h-auto rounded-lg"*/}
                    {/*    src={require("./../../../assets/images/employee-onboard.png")}*/}
                    {/*    alt="image description"*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                );
              }

              if (id === "tickets") {
                return (
                  <div className="mt-7 flex flex-row">
                    <div className="space-y-5 pr-5">
                      <div className="text-xl font-bold">
                        Inbound third party orders directly into Dripos
                      </div>

                      <div className="text-gray-800">
                        Third party delivery orders come directly into the
                        Dripos ticket screen, so you can manage them like any
                        other ticket.
                      </div>
                    </div>

                    {/*<div className=" w-1/2">*/}
                    {/*  <img*/}
                    {/*    className="w-auto h-auto rounded-lg"*/}
                    {/*    src={require("./../../../assets/images/simplyinsured.png")}*/}
                    {/*    alt="image description"*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                );
              }

              if (id === "sales") {
                return (
                  <div className="mt-7 flex flex-row">
                    <div className="space-y-5 pr-5">
                      <div className="text-xl font-bold">
                        Track third party sales with Dripos
                      </div>

                      <div className="text-gray-800">
                        Since all third party sales will be entered into our
                        platform, you can track sales and product numbers in our
                        reporting. No need to export all sales to a different
                        system, just use our built in reporting.
                      </div>
                    </div>

                    {/*<div className=" w-1/2">*/}
                    {/*  <img*/}
                    {/*    className="w-auto h-auto rounded-lg"*/}
                    {/*    src={require("./../../../assets/images/simplyinsured.png")}*/}
                    {/*    alt="image description"*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                );
              }
            }}
          </Tab>
        </div>
      </Card>
    );
  }
}

export default setupReduxConnection(["shop"])(
  withRouter(PayrollCompanySetupPage)
);
