import React, {Component} from "react";
import {request} from "../../utils/request";
import ComboBox from "../../components/combobox";

class DeviceDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData();
  }

  syncData() {
    request("partner/devices", "GET").then((data) => this.setState({data}));
  }

  render() {
    const {data} = this.state;

    if (!data) {
      return <div></div>;
    }

    return (
      <ComboBox
        label={"Device"}
        data={data.map((device) => {
          return {label: device.NAME, value: device.ID, id: device.ID};
        })}
        {...this.props}
      />
    );
  }
}

export default DeviceDropdown;
