import React, {Component} from "react";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import {request} from "../../utils/request";
import moment from "moment";

class TimesheetHistoryModal extends Component {
  state = {
    timesheet: null,
    data: null,
    loading: true,
  };

  open(timesheet) {
    this.setState({timesheet}, () => {
      this.modal.open();
      this.syncState();
    });
  }

  close() {
    this.modal.close();
  }

  async syncState() {
    const {timesheet} = this.state;

    this.setState({loading: true});

    const data = await request(
      "team/timesheet/history/" + timesheet.ID,
      "GET",
      {}
    );

    this.setState({data, loading: false});
  }

  renderLoading() {
    return <div className="text-gray-300 text-sm">Fetching data...</div>;
  }

  renderHistory() {
    const {data} = this.state;

    if (!data || data === []) {
      return <div className="text-sm">No records found</div>;
    }

    return (
      <div>
        {data.map((event) => (
          <div className="mb-2">
            <div className="text-sm">
              {`${event.TYPE}: `}
              {` Recorded on ${
                event.TYPE === "Offline Clock In"
                  ? moment(event.DATE_START).format("MMM D, h:mm A")
                  : moment(event.DATE_CREATED).format("MMM D, h:mm A")
              }`}
              {event.EMPLOYEE && ` by ${event.EMPLOYEE.FULL_NAME}`}
            </div>
            {event.DATE_START && event.DATE_END && (
              <div className="text-gray-500 text-sm">
                {`Time Card Dates: ${moment(event.DATE_START).format(
                  "MMM D, h:mm A"
                )} - ${moment(event.DATE_END).format("MMM D, h:mm A")}`}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  render() {
    let {loading} = this.state;

    return (
      <Modal
        buttonLabel="Ok"
        buttonOnClick={() => this.close()}
        label={"Timesheet History"}
        description=""
        ref={(e) => (this.modal = e)}
      >
        {loading && this.renderLoading()}
        {!loading && this.renderHistory()}
      </Modal>
    );
  }
}

export default TimesheetHistoryModal;
