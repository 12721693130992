import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import {setupReduxConnection} from "../../../../redux";
import {getPayrollCompanyOnboardingLink} from "../../../../utils/payroll-helper";

class PayrollCompanyTaxAuthSetupComponent extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.location = "https://dashboard.dripos.com/payroll";
    }, 5000);
  }

  render() {
    return (
      <div className="flex justify-center flex-col">
        <div className={"text-center font-semibold"}>
          You're being brought to the Payroll onboarding page
        </div>

        <div className="py-10">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user","shop"])(PayrollCompanyTaxAuthSetupComponent);
