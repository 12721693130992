import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import ContactModal from "../../modals/operations/invoices/contact-modal";

class ContactDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    const {name, nameField, options} = this.props;
    let value = null;

    if (options && nameField) {
      value = options.values[nameField];
    }

    this.syncData(value).catch((err) => {
      console.log("Problem fetching patron data", err);
    });
  }

  async syncData(search = null) {
    let serverPatrons = [];

    if (search) {
      const {data} = await request("contacts/dumb", "POST", {
        SEARCH: search,
      });

      serverPatrons = data;
    } else {
      serverPatrons = await request("contacts/top", "GET");
    }

    this.setState({data: serverPatrons});
  }

  render() {
    const {account, onChangeSoft, options, name, onChange} = this.props;
    const {data} = this.state;

    return (
      <>
        <ContactModal
          ref={(e) => (this.contactModal = e)}
          updateState={(contact) => {
            const index = data.findIndex((item) => {
              return item.ID === contact.ID;
            });

            if (index !== -1) {
              data.splice(index, 1, contact);

              this.setState({data});
            }
          }}
        />

        <ComboBox
          onQueryChange={(err) => {
            err.length > 0 && this.syncData(err);
          }}
          disabled={this.props.disabled}
          className="mt-2"
          secondaryBlock
          buttonHint={{
            hideNull: true,
            label: "Edit Contact",
            onClick: (element) => {
              const contactData = data.find((item) => {
                return item.ID === element;
              });

              this.contactModal.open(contactData);
            },
          }}
          label="Contact Name"
          data={
            data?.map((contact) => {
              return {
                contact,
                name: contact?.FIRST_NAME + " " + contact?.LAST_NAME,
                id: account ? contact.ACCOUNT_ID : contact.ID,
                label: `${contact?.FIRST_NAME} ${contact?.LAST_NAME}${
                  contact?.EMAIL ? " - " + contact?.EMAIL : ""
                }`,
              };
            }) ?? []
          }
          {...this.props}
        />
      </>
    );
  }
}

ContactDropdown.propTypes = {
  ...ComboBox.propTypes,
};

export default ContactDropdown;
