import React, {Component} from "react";
import {
  PageHeadings,
  Tooltip,
} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {CELL_TYPES, LABOR_VS_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import moment from "moment";
import {setupReduxConnection} from "../../redux";

class LaborVsCostReportPage extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      let {BREAKDOWN, TOTALS} = reportData;

      rows = BREAKDOWN.map((item) => {
        return {
          label: item.TIME_LABEL,
          numbers: item,
        };
      });

      rows.push({
        label: TOTALS.TIME_LABEL,
        numbers: TOTALS,
        style: [CELL_TYPES.BOLD],
      });
    }

    return rows;
  }

  render() {
    const {REPORTING_SHOW_LOCATION_CSV, } =
      this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Labor vs Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="labor-vs-sales"
          endpoint="report/laborvssales"
          constantColumns={LABOR_VS_SALES_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          hideTime={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(LaborVsCostReportPage);
