import React, {Component} from "react";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";
import {setupReduxConnection} from "../../../../redux";
import {Check} from "react-feather";
import TimesheetsApprovalTable from "../../../team/timesheets-approval-table";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {getBreakOptions} from "../../../../utils/payroll-helper";

class ApproveTimesheetsComponent extends Component {
  state = {isLoading: true, cards: null, breakOptions: null};

  async componentDidMount() {
    await this.syncState();
  }

  syncState = () => {
    let {PERIOD_START, PERIOD_END} = this.props.payroll.currentPayrun;

    getBreakOptions().then((breakOptions) => {
      this.setState({breakOptions});
    });

    request(
      `payroll/timesheet-unapproved/${PERIOD_START}/${PERIOD_END}`,
      "GET"
    ).then((cards) => {
      this.setState({isLoading: false, cards});
    });
  };

  handleClick() {
    let {cards} = this.state;

    // if (cards.length === 0) {
    return true;
    // }

    // showErrorAlert(
    //   "Unapproved Time Cards",
    //   "You still have unapproved time cards for this period!",
    //   "Ok"
    // );
    //
    // return false;
  }

  render() {
    let {isLoading, cards, breakOptions} = this.state;

    if (!cards) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        {isLoading && (
          <div className="py-10">
            <LoadingSpinner />
          </div>
        )}

        {!isLoading && (
          <TimesheetsApprovalTable
            cards={cards}
            updateCards={(cards) => {
              this.setState({cards: [...cards]});
            }}
            ref={(e) => (this.approvalTable = e)}
            breakOptions={breakOptions}
            emptyComponent={
              <div className="py-20 flex flex-row justify-center text-2xl font-semibold items-center">
                <Check className="mr-3" />
                All Timesheets Have Been Approved!
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(ApproveTimesheetsComponent);
