import React, {Component} from "react";
import {PaperClipIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";

class Attachments extends Component {
  render() {
    const {data} = this.props;

    return (
      <ul
        role="list"
        className="border border-gray-200 rounded-md divide-y divide-gray-200"
      >
        {data.map((item) => {
          const {label} = item;

          return (
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />

                <span className="ml-2 flex-1 w-0 truncate">{label}</span>
              </div>

              <div className="ml-4 flex-shrink-0">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Download
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

Attachments.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Attachments;
