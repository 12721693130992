import React, {Component} from "react";
import {
  FormInput,
  Modal,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class BreakOptionModal extends Component {
  state = {breakOption: null};

  open(breakOption = null) {
    this.setState({breakOption}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveOption({name, amountMinutes, isPaid}) {
    const {breakOption} = this.state;

    const breakOptionPayload = {
      NAME: name,
      AMOUNT_MINUTES: amountMinutes,
      IS_PAID: isPaid,
    };

    let serverBreakOption;

    try {
      serverBreakOption = await request(
        "team/break-option/" + breakOption.ID,
        "PATCH",
        breakOptionPayload
      );
    } catch (error) {
      alert(error);
      console.log(error);
    }

    if (serverBreakOption) {
      this.props.updateState(serverBreakOption);
    }

    this.modal.close();
  }

  async createOption({name, amountMinutes, isPaid}) {
    const {breakOption} = this.state;

    const breakOptionPayload = {
      NAME: name,
      AMOUNT_MINUTES: amountMinutes,
      IS_PAID: isPaid,
    };

    let serverBreakOption;

    try {
      serverBreakOption = await request(
        "team/break-option",
        "POST",
        breakOptionPayload
      );
    } catch (error) {
      alert(error);
      console.log(error);
    }

    if (serverBreakOption) {
      this.props.updateState(serverBreakOption);
    }

    this.modal.close();
  }

  async deleteOption() {
    const {breakOption} = this.state;

    try {
      await request("team/break-option/" + breakOption.ID, "DELETE");
    } catch (error) {
      alert(error);
      console.log(error);
    }

    this.props.updateState(breakOption, true);

    this.modal.close();
  }

  render() {
    const {breakOption} = this.state;

    let initialValues = {
      name: breakOption?.NAME ?? "",
      amountMinutes: breakOption?.AMOUNT_MINUTES ?? 0,
      isPaid: breakOption?.IS_PAID ? true : false,
    };

    let validationShape = {
      name: Yup.string().required("Name is required"),
      amountMinutes: Yup.number().required("Duration of break is required"),
    };

    return (
      <Modal
        buttonLabel={breakOption ? "Save" : "Add"}
        label={breakOption ? "Edit Break Option" : "Create Break Option"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={breakOption && "Delete"}
        deleteOnClick={this.deleteOption.bind(this)}
      >
        <Formik
          onSubmit={
            breakOption
              ? this.saveOption.bind(this)
              : this.createOption.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationShape)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Name"
                  name="name"
                  placeholder="Placeholder Name"
                  options={formikOptions}
                />

                <FormInput
                  label="Amount Minutes"
                  name="amountMinutes"
                  placeholder="0"
                  options={formikOptions}
                />

                <FormSelect
                  options={formikOptions}
                  label="Is Break Paid"
                  name="isPaid"
                  data={[
                    {label: "Yes", value: true},
                    {label: "No", value: false},
                  ]}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default BreakOptionModal;
