import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {ArrowRight} from "react-feather";
import BankAccountDetailsModal from "../../../modals/banking/bank-account-details-modal";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import moment from "moment/moment";
import SendFundsModal from "../../../modals/banking/send-funds-modal";

class TransactionsPage extends Component {
  state = {loading: true, balance: null, faDetails: null};

  constructor(props) {
    super(props);
    // let {FA_ID} = this.props.shop.location;
    //
    // if (!FA_ID) {
    //   console.log("transactinos")
    //
    //   this.props.router.navigate("/cash/setup");
    // }
  }

  async componentDidMount() {
    let {
      balance,
      faDetails,
      openBankingTransactions,
      postedBankingTransactions,
    } = await request("banking2/transactions-page", "GET");

    postedBankingTransactions = postedBankingTransactions.data.map((item) => {
      let mapping = {
        id: item.id,
        flow: item.flow,
        status: item.status,
        created: item.created,
        date: moment(item.created * 1000).format("MMM DD"),
        amount: toDollars(item.amount, true),
      };

      let {flow_type} = item;

      let arr = item.description.split("|");

      if (arr[2] === " dripos payout") {
        mapping.description = "dripos payout";
        mapping.type = "payout";
      } else if (flow_type === "received_debit") {
        if (arr[1] === " Purchase ") {
          mapping.description = arr[2];
          mapping.card = arr[0];
          mapping.category = arr[3];
          mapping.type = "card_purchase";
        } else if (arr[1] === " Received debit ") {
          mapping.description = `${arr[0].substring(0, arr[0].length - 1)}, ${
            arr[2]
          }`;
        }
      } else if (flow_type === "received_credit") {
        if (arr[1] === " Refund ") {
          mapping.description = arr[2];
          mapping.category = arr[3];
          mapping.type = "refund";
        }
      } else if (flow_type === "outbound_payment") {
        if (arr[1] === " Outbound payment ") {
          mapping.description = arr[2];
        }
      }

      if (!mapping.description) {
        console.log(item);
      }

      // } else if (arr[1] === " Purchase " || arr[1] === " Authorization ") {
      //   mapping.description = arr[2];
      //   mapping.card = arr[0];
      //   mapping.category = arr[3];
      //   mapping.type = "card-purchase";
      // } else if (arr[1] === " Received debit ") {
      //   mapping.description = arr[0];
      //   mapping.type = "ach";
      // } else if (arr[1] === " Outbound payment ") {
      //   mapping.description = arr[2];
      // }

      return mapping;
    });

    this.setState({
      loading: false,
      balance,
      faDetails,
      openBankingTransactions,
      postedBankingTransactions,
    });
  }

  render() {
    let {
      loading,
      balance,
      faDetails,
      openBankingTransactions,
      postedBankingTransactions,
    } = this.state;

    let accountNumber = faDetails?.financial_addresses[0].aba.account_number;
    let routingNumber = faDetails?.financial_addresses[0].aba.routing_number;
    let bankName = faDetails?.financial_addresses[0].aba.bank_name;

    return (
      <div>
        <BankAccountDetailsModal
          ref={(e) => (this.bankAccountDetailsModal = e)}
        />

        <SendFundsModal ref={(e) => (this.sendFundsModal = e)} />

        <div className="flex flex-row justify-between">
          <PageHeadings label="Transactions" />

          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => this.sendFundsModal.open()}
          >
            Send Funds
          </button>
        </div>
        {loading && <LoadingSpinner />}

        {!loading && (
          <div>
            <Card>
              <div className="flex flex-row px-10">
                <div className="flex flex-col py-10">
                  <div className="text-sm font-semibold text-gray-700">
                    Available Balance
                  </div>

                  <div className="font-bold text-xl">
                    {toDollars(faDetails.balance.current.usd, true)}
                  </div>
                </div>

                <div className="flex flex-col ml-14 pl-6  border-l pt-10">
                  <div className="text-sm font-semibold text-gray-700">
                    Account Details
                  </div>

                  <div className="font-bold text-xl">
                    {faDetails.financial_addresses[0].aba.account_number.replace(
                      /.(?=.{4,}$)/g,
                      "•"
                    )}
                  </div>

                  <div
                    className="flex flex-row text-indigo-700 mt-2 text-sm font-semibold items-center cursor-pointer"
                    onClick={() =>
                      this.bankAccountDetailsModal.open(
                        bankName,
                        accountNumber,
                        routingNumber
                      )
                    }
                  >
                    View Details <ArrowRight className="h-4 w-4 ml-1" />
                  </div>
                </div>
              </div>
            </Card>

            <div className="mt-1 font-semibold">
              <Card label={"Completed"}>
                <Table
                  limit={25}
                  columns={COLUMNS}
                  data={postedBankingTransactions.filter(
                    (item) => item.type !== "payout"
                  )}
                />
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const COLUMNS = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Amount",
    value: "amount",
  },
];

export default setupReduxConnection(["shop"])(withRouter(TransactionsPage));
