import React, {Component} from "react";
import {Card, Table, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import PropTypes from "prop-types";
import {
  formatShiftString,
  openShiftStatusToBadge,
} from "../../../../utils/team-helper";
import {OPEN_SHIFT_APPROVAL_TYPES} from "../../../../utils/team-constants";
import {setupReduxConnection} from "../../../../redux";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import {request} from "../../../../utils/request";
import OpenShiftApprovalModal from "../../../../modals/team/open-shift-approval-modal";
import {showErrorAlert} from "../../../../utils/alert-helper";

class OpenShiftApprovalTab extends Component {
  approveRequest = async (requestId, isAccepted, notes = null) => {
    let {openShifts, update} = this.props;

    try {
      await request("scheduling/open-shifts/" + requestId, "PATCH", {
        STATUS: isAccepted,
        NOTES: notes,
      });
    } catch (err) {
      if (err.error === "SHIFT_ASSIGNED") {
        if (!isAccepted) {
          const idx = openShifts.findIndex((item) => item.ID === requestId);

          if (idx !== -1) {
            openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.denied.value;
          }

          update(openShifts);
        } else {
          return showErrorAlert(
            "Request Approval Error",
            "Shift has already been assigned. You can still mark request as denied to remove it from the pending page.",
            "Ok"
          );
        }
      }
    }

    const idx = openShifts.findIndex((item) => item.ID === requestId);

    if (isAccepted) {
      openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.approved.value;
    } else if (idx != -1) {
      openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.denied.value;
    }

    update(openShifts);
  };

  render() {
    const {openShifts} = this.props;
    let {locations} = this.props;
    const {location} = this.props.shop;

    return (
      <Card
        label="Open Shifts"
        description="Requests from employees to pick up open shifts"
      >
        <OpenShiftApprovalModal
          ref={(e) => (this.OpenShiftApprovalModal = e)}
          approveRequest={this.approveRequest}
        />

        <Filter
          className="mt-0"
          defaultFilters={[
            {
              filter: "status",
              label: "Pending",
              id: OPEN_SHIFT_APPROVAL_TYPES.pending.value,
            },
            {filter: "location", id: location.ID},
          ]}
          data={[
            {
              id: "location",
              label: "Location",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.SHIFT_LOCATION_ID);
                }),
              options: locations ?? [],
            },
            {
              id: "status",
              label: "Status",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.STATUS);
                }),
              options: [
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.pending.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.pending.label,
                },
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.approved.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.approved.label,
                },
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.denied.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.denied.label,
                },
              ],
            },
          ]}
        >
          {(filters) => (
            <Table
              pagination
              data={openShifts}
              filters={filters}
              actionButtons={[
                {
                  label: "View Details",
                  onClick: (row) => this.OpenShiftApprovalModal.open(row),
                },
              ]}
              columns={[
                {
                  value: "NAME",
                  label: "Employee Name",
                  width: 2,
                  format: (value, row) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={getObjectImage(
                              row,
                              "TRADER_LOGO",
                              "appicon.png"
                            )}
                            className="h-10 w-10 rounded-full"
                            alt=""
                          />
                        </div>

                        <div className="ml-4 text-sm font-medium text-gray-900">
                          {row.FULL_NAME}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  value: "SHIFT_DATE",
                  label: "Shift",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div className="text-sm text-gray-500">
                        {formatShiftString(row.DATE_START, row.DATE_END)}
                      </div>
                    );
                  },
                },
                {
                  value: "ROLE_NAME",
                  label: "Role",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div className="text-sm text-gray-500">
                        {row.ROLE_NAME}
                      </div>
                    );
                  },
                },
                {
                  value: "STATUS",
                  label: "Status",
                  width: 1,
                  format: (value) => {
                    return openShiftStatusToBadge(value);
                  },
                },
                {
                  value: "STATUS",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div>
                        {row.STATUS ===
                          OPEN_SHIFT_APPROVAL_TYPES.pending.value && (
                          <div className="flex flex-row">
                            <div
                              className="text-green-600 hover:text-green-900 cursor-pointer font-medium mr-7"
                              onClick={async () => {
                                await this.approveRequest(row.ID, true);
                              }}
                            >
                              <CheckIcon className="h-6 w-6" />
                            </div>

                            <div
                              className="text-red-600 hover:text-red-900 cursor-pointer font-medium"
                              onClick={async () => {
                                await this.approveRequest(row.ID, false);
                              }}
                            >
                              <XIcon className="h-6 w-6" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </Filter>
      </Card>
    );
  }
}

OpenShiftApprovalTab.propTypes = {
  openShifts: PropTypes.array.isRequired,
};

export default setupReduxConnection(["user"])(OpenShiftApprovalTab);
