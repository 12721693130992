import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class DangerBadge extends Component {
  render() {
    let {className, blue, yellow} = this.props;
    let color = "";

    if (yellow) {
      color = "bg-orange-100 text-yellow-800";
    } else if (blue) {
      color = "bg-blue-100 text-blue-800";
    } else {
      color = "bg-red-100 text-red-800";
    }

    return (
      <div
        {...this.props}
        className={classNames(
          `inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${color}`,
          className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default DangerBadge;
