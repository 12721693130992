import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import FormRow from "../../../components/form-row";

class BlackoutDatesModal extends Component {
  state = {date: null};

  open(date = null) {
    this.setState({date}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveBlackoutDate({name, dateStart, dateEnd}) {
    const {date} = this.state;

    const blackoutDatePayload = {
      NAME: name,
      DATE_START: dateStart,
      DATE_END: dateEnd,
    };

    let serverDate;

    try {
      serverDate = await request(
        "timeoff/blackout/" + date.ID,
        "PATCH",
        blackoutDatePayload
      );
    } catch (error) {
      console.log(error);
      return alert(error);
    }

    if (serverDate) {
      this.props.updateDates(serverDate);
    }

    this.modal.close();
  }

  async createBlackoutDate({name, dateStart, dateEnd}) {
    const blackoutDatePayload = {
      NAME: name,
      DATE_START: dateStart,
      DATE_END: dateEnd,
    };

    let serverBlackoutDate;

    try {
      serverBlackoutDate = await request(
        "timeoff/blackout/",
        "POST",
        blackoutDatePayload
      );
    } catch (error) {
      console.log(error);
      return alert(error);
    }

    if (serverBlackoutDate) {
      this.props.updateDates(serverBlackoutDate);
    }

    this.modal.close();
  }

  async deleteBlackoutDate() {
    const {date} = this.state;

    try {
      await request("timeoff/blackout/" + date.ID, "DELETE");
    } catch (error) {
      console.log(error);
      return alert(error);
    }

    this.props.updateDates(date, true);

    this.modal.close();
  }

  render() {
    const {date} = this.state;

    let initialValues = {
      name: date?.NAME ?? "",
      dateStart: date?.DATE_START ?? null,
      dateEnd: date?.DATE_END ?? null,
    };

    let validationShape = {
      name: Yup.string().required("Name is required"),
      dateStart: Yup.number()
        .typeError("Start date is required")
        .required("Start date is required"),
      dateEnd: Yup.number()
        .typeError("Start date is required")
        .required("End date is required")
        .test(
          "Valid Date Range",
          "Please ensure end date is after start date",
          (val, ctx) => {
            return val >= ctx.parent.dateStart;
          }
        ),
    };

    return (
      <Modal
        buttonLabel={date ? "Save" : "Create"}
        label={date ? "Edit Blackout Date" : "Create Blackout Date"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={date && "Delete"}
        deleteOnClick={() => this.deleteBlackoutDate()}
      >
        <Formik
          onSubmit={
            date
              ? this.saveBlackoutDate.bind(this)
              : this.createBlackoutDate.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationShape)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Name"
                  name="name"
                  placeholder="Placeholder Name"
                  options={formikOptions}
                  tooltip={
                    "The name of the blackout date that will be displayed (e.g. Fourth of July Vacation Blackout)"
                  }
                />

                <FormRow>
                  <FormDateTimeSelect
                    label="Start Date"
                    name="dateStart"
                    options={formikOptions}
                    hideTime={true}
                    flex
                    tooltip={"The date that the blackout period will start"}
                  />

                  <FormDateTimeSelect
                    label="End Date"
                    name="dateEnd"
                    options={formikOptions}
                    hideTime={true}
                    flex
                    tooltip={
                      "The date that the blackout period will end. Note that the blackout period will include the end date."
                    }
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default BlackoutDatesModal;
