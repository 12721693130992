import React, {Component} from "react";
import {request} from "../../../../../utils/request";
import {
  W4_EMPLOYEE_PREFILL_FIELDS,
  W4_EMPLOYEE_PREFILL_FIELDS_SPANISH,
  W4_HEAD_OF_HOUSEHOLD_CUTOFFS,
  W4_INDIVIDUAL_CUTOFFS,
  W4_MARRIED_CUTOFFS,
} from "../../../../../utils/constants";
import moment from "moment";
import {
  decimalToDollars,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {PDFDocument} from "pdf-lib";
import ReviewAndConfirmFileModal from "../../../../../modals/team/employee/onboarding/review-and-confirm-file-modal";
import SignatureModal from "../../../../../modals/team/employee/onboarding/signature-modal";
import {Document, Page} from "react-pdf";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import SignatureField from "../../../../../components/pdf-editor/signature-field";
import i18next from "i18next";
import {cloneDeep} from "lodash";

class W4SignPage extends Component {
  state = {
    document: null,
    field: {
      WIDTH: 188,
      HEIGHT: 28,
      X_COORDINATE: i18next.language === "en" ? 125 : 99,
      Y_COORDINATE: i18next.language === "en" ? 602 : 652,
    },
  };

  componentDidMount() {
    request("company-documents/w4/" + i18next.language, "GET").then(
      async (document) => {
        document = await this.applyAnnotations(document);

        this.setState({document});
      }
    );
  }

  async applyAnnotations(document) {
    const {values} = this.props.options;

    const editablePDF = await PDFDocument.load(
      new Uint8Array(document.DATA.data).buffer
    );

    const page = editablePDF.getPages()[0];
    const FIELDS =
      i18next.language === "en"
        ? W4_EMPLOYEE_PREFILL_FIELDS
        : W4_EMPLOYEE_PREFILL_FIELDS_SPANISH;

    Object.keys(FIELDS).forEach((field) => {
      const fieldValue = values[field];

      if (!fieldValue) {
        return;
      }

      const fieldDetails = FIELDS[field];

      page.drawText(fieldValue, {
        x: fieldDetails.X_COORDINATE + 5,
        y:
          page.getHeight() -
          fieldDetails.Y_COORDINATE -
          fieldDetails.HEIGHT +
          5,
        width: fieldDetails.WIDTH,
        height: fieldDetails.HEIGHT,
        size: 12,
      });
    });

    this.writeTodaysDate(page);
    this.writeCityStateZip(page);
    this.writeFilingTypeCheck(page);
    this.writeNumberOfQualifyingDependents(page);
    this.writeNumberOfOtherDependents(page);
    this.writeNumberOfTotalDependents(page);
    this.writeDeductions(page);
    this.writeWitholding(page);
    this.writeTwoJobsCheck(page);
    this.writeOtherIncome(page);

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  writeTwoJobsCheck(page) {
    const {step2Type} = this.props.options.values;
    const isEnglish = i18next.language === "en";

    if (step2Type === "OTHER") {
      page.drawText("x", {
        width: 10,
        height: 10,
        x: 564,
        y: page.getHeight() - (isEnglish ? 328 : 359),
        size: 14,
      });
    }
  }

  writeDeductions(page) {
    const {itemizedEstimate, filingType, studentLoanEstimate} =
      this.props.options.values;
    const isEnglish = i18next.language === "en";

    const filingValues = {
      INDIVIDUAL: 1385000,
      MARRIED: 2770000,
      HEAD_OF_HOUSEHOLD: 2080000,
    };

    const subtotal = Math.max(
      0,
      filingValues[filingType] - decimalToDollars(itemizedEstimate)
    );

    page.drawText(toDollars(subtotal + decimalToDollars(studentLoanEstimate)), {
      width: 65,
      height: 21,
      x: 512,
      y: page.getHeight() - (isEnglish ? 538 : 586),
      size: 12,
    });
  }

  writeNumberOfTotalDependents(page) {
    const {numberQualifyingDependents, numberOtherDependents} =
      this.props.options.values;
    const isEnglish = i18next.language === "en";

    page.drawText(
      toDollars(
        numberQualifyingDependents * 200000 + numberOtherDependents * 50000
      ),
      {
        width: isEnglish ? 63 : 66,
        height: isEnglish ? 17 : 24,
        x: isEnglish ? 513 : 512,
        y: page.getHeight() - (isEnglish ? 457 : 500),
        size: 12,
      }
    );
  }

  writeNumberOfQualifyingDependents(page) {
    const {numberQualifyingDependents} = this.props.options.values;
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(numberQualifyingDependents * 200000), {
      width: 63,
      height: 17,
      x: 412,
      y: page.getHeight() - (isEnglish ? 410 : 465),
      size: 12,
    });
  }

  writeNumberOfOtherDependents(page) {
    const {numberOtherDependents} = this.props.options.values;
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(numberOtherDependents * 50000), {
      width: 63,
      height: 17,
      x: 412,
      y: page.getHeight() - (isEnglish ? 430 : 479),
      size: 12,
    });
  }

  writeTodaysDate(page) {
    const isEnglish = i18next.language === "en";

    if (isEnglish) {
      page.drawText(moment().format("M/D/YYYY"), {
        x: 460,
        y: page.getHeight() - 628,
        width: 117,
        height: 28,
        size: 12,
      });
    } else {
      page.drawText(moment().format("M/D/YYYY"), {
        x: 464,
        y: page.getHeight() - 676,
        width: 113,
        height: 24,
        size: 12,
      });
    }
  }

  writeOtherIncome(page) {
    const {otherIncome} = this.props.options.values;
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(otherIncome ?? 0), {
      width: 65,
      height: 21,
      y: page.getHeight() - (isEnglish ? 495 : 548),
      x: 512,
      size: 12,
    });
  }

  writeFilingTypeCheck(page) {
    const {filingType} = this.props.options.values;
    const isEnglish = i18next.language === "en";

    const yCoords = {
      INDIVIDUAL: isEnglish ? 167 : 191,
      MARRIED: isEnglish ? 179 : 202,
      HEAD_OF_HOUSEHOLD: isEnglish ? 190 : 214,
    };

    page.drawText("x", {
      x: 116,
      y: page.getHeight() - yCoords[filingType],
      width: 10,
      height: 10,
      size: 14,
    });
  }

  writeCityStateZip(page) {
    const {state, zip, cityOrTown} = this.props.options.values;
    const addressString = cityOrTown + ", " + state + " " + zip;
    const isEnglish = i18next.language === "en";

    if (isEnglish) {
      page.drawText(addressString, {
        x: 95,
        y: page.getHeight() - 154,
        width: 383,
        height: 25,
        size: 12,
      });
    } else {
      page.drawText(addressString, {
        x: 95,
        y: page.getHeight() - 175,
        width: 355,
        height: 29,
        size: 12,
      });
    }
  }

  writeWitholding(page) {
    const {filingType} = this.props.options.values;

    if (filingType === "INDIVIDUAL") {
      this.calculateIndividualWitholding(page);
    } else if (filingType === "MARRIED") {
      this.calculateMarriedWitholding(page);
    } else if (filingType === "HEAD_OF_HOUSEHOLD") {
      this.calculateHeadOfHouseholdWitholding(page);
    }
  }

  calculateIndividualWitholding(page) {
    const {
      extraWitholding,
      highestPaying,
      secondHighestPaying,
      lowestPaying,
      numberOfJobs,
    } = this.props.options.values;

    let subtotalOne = 0;
    let subtotalTwo = 0;

    subtotalOne = this.calculateIndividualIntersection(
      decimalToDollars(highestPaying),
      decimalToDollars(lowestPaying)
    );

    if (numberOfJobs === "THREE") {
      subtotalTwo = this.calculateIndividualIntersection(
        decimalToDollars(highestPaying + secondHighestPaying),
        decimalToDollars(lowestPaying)
      );
    }

    const total = subtotalTwo + subtotalOne + decimalToDollars(extraWitholding);
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(total), {
      width: 64,
      height: 21,
      x: 512,
      y: page.getHeight() - (isEnglish ? 560 : 610),
      size: 12,
    });
  }

  calculateMarriedWitholding(page) {
    const {
      extraWitholding,
      highestPaying,
      secondHighestPaying,
      lowestPaying,
      numberOfJobs,
    } = this.props.options.values;

    let subtotalOne = 0;
    let subtotalTwo = 0;

    subtotalOne = this.calculateMarriedIntersection(
      decimalToDollars(highestPaying),
      decimalToDollars(lowestPaying)
    );

    if (numberOfJobs === "THREE") {
      subtotalTwo = this.calculateMarriedIntersection(
        decimalToDollars(highestPaying + secondHighestPaying),
        decimalToDollars(lowestPaying)
      );
    }

    const total = subtotalTwo + subtotalOne + decimalToDollars(extraWitholding);
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(total), {
      width: 64,
      height: 21,
      x: 512,
      y: page.getHeight() - (isEnglish ? 560 : 610),
      size: 12,
    });
  }

  calculateHeadOfHouseholdWitholding(page) {
    const {
      extraWitholding,
      highestPaying,
      secondHighestPaying,
      lowestPaying,
      numberOfJobs,
    } = this.props.options.values;

    let subtotalOne = 0;
    let subtotalTwo = 0;

    subtotalOne = this.calculateHeadOfHouseholdIntersection(
      decimalToDollars(highestPaying),
      decimalToDollars(lowestPaying)
    );

    if (numberOfJobs === "THREE") {
      subtotalTwo = this.calculateHeadOfHouseholdIntersection(
        decimalToDollars(highestPaying + secondHighestPaying),
        decimalToDollars(lowestPaying)
      );
    }

    const total = subtotalTwo + subtotalOne + decimalToDollars(extraWitholding);
    const isEnglish = i18next.language === "en";

    page.drawText(toDollars(total), {
      width: 64,
      height: 21,
      x: 512,
      y: page.getHeight() - (isEnglish ? 560 : 610),
      size: 12,
    });
  }

  calculateHeadOfHouseholdIntersection(higherPaying, lowerPaying) {
    const columnIndex = Math.floor(lowerPaying / 1000000);
    const column = W4_HEAD_OF_HOUSEHOLD_CUTOFFS[columnIndex];

    const rowCutoffs = [
      45000000, 25000000, 20000000, 17500000, 15000000, 12500000, 10000000,
      8000000, 6000000, 4000000, 3000000, 2000000, 1000000,
    ];

    let rowIndex = column.length - 1;

    rowCutoffs.forEach((row, index) => {
      if (higherPaying < row) rowIndex = index;
    });

    return column[rowIndex];
  }

  calculateMarriedIntersection(higherPaying, lowerPaying) {
    const columnIndex = Math.floor(lowerPaying / 1000000);
    const column = W4_MARRIED_CUTOFFS[columnIndex];

    const rowCutoffs = [
      52500000, 36500000, 32000000, 30000000, 28000000, 26000000, 24000000,
      15000000, 10000000, 8000000, 7000000, 6000000, 5000000, 4000000, 3000000,
      2000000, 1000000,
    ];

    let rowIndex = column.length - 1;

    rowCutoffs.forEach((row, index) => {
      if (higherPaying < row) rowIndex = index;
    });

    return column[rowIndex];
  }

  calculateIndividualIntersection(higherPaying, lowerPaying) {
    const columnIndex = Math.floor(lowerPaying / 1000000);
    const column = W4_INDIVIDUAL_CUTOFFS[columnIndex];

    const rowCutoffs = [
      45000000, 40000000, 25000000, 20000000, 17500000, 15000000, 12500000,
      10000000, 8000000, 6000000, 4000000, 3000000, 2000000, 1000000,
    ];

    let rowIndex = column.length - 1;

    rowCutoffs.forEach((row, index) => {
      if (higherPaying < row) rowIndex = index;
    });

    return column[rowIndex];
  }

  async applySignature() {
    const {field} = this.state;

    field.VALUE = await this.signatureModal.open(field);

    if (!field.VALUE) {
      return false;
    }

    this.setState({field});
    return true;
  }

  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.i9-part1.i-9-sign-page." + path;
  }

  async writeSignature(field, document) {
    const editablePDF = await PDFDocument.load(
      new Uint8Array(document.DATA.data).buffer
    );

    const {employee} = this.props;

    const imageBytes = await fetch(field.VALUE).then((data) =>
      data.arrayBuffer()
    );

    const pngImage = await editablePDF.embedPng(imageBytes);
    const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    page.drawImage(pngImage, {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT,
      width: field.WIDTH,
      height: field.HEIGHT,
    });

    request("company-documents/signature/image", "POST", {
      FILE: Array.from(new Uint8Array(imageBytes)),
    }).then(({url}) => {
      request("company-documents/signatures", "POST", {
        DATE_SIGNED: Date.now(),
        DOCUMENT_FIELD_ID: null,
        SIGNATURE_IMAGE_URL: url,
        ACCOUNT_ID: employee.ACCOUNT_ID,
      });
    });

    return editablePDF.save();
  }

  async handleSubmit() {
    const {document, field} = this.state;
    const {details} = this.props;
    const documentCopy = cloneDeep(document);

    documentCopy.FILE_NAME = "I9_Part_1.pdf";
    documentCopy.DATA.data = await this.writeSignature(field, documentCopy);

    const w4Document = await this.confirmModal.open(documentCopy);

    if (!!w4Document) {
      request(
        "company-documents/w4/employee/" +
          details.ID +
          "/" +
          details.CHECKLIST_INSTANCE_ID,
        "POST",
        {URL: w4Document.KEY}
      );

      this.props.options.setFieldValue("i9Document", w4Document);
    }

    return !!w4Document;
  }

  renderFields() {
    const {field} = this.state;

    return (
      <SignatureField
        field={field}
        onClick={async () => {
          const result = await this.applySignature();
          this.props.options.setFieldValue("i9DocumentSigned", !!result);
        }}
        selected={false}
        key={"SIGNATURE"}
        completed={!!field.VALUE}
        message={i18next.t(this.getI18NextKey("signature.label"))}
      />
    );
  }

  render() {
    const {document} = this.state;
    const {employee, details} = this.props;

    if (!document) {
      return <Loading />;
    }

    return (
      <>
        <ReviewAndConfirmFileModal
          ref={(e) => (this.confirmModal = e)}
          employee={employee}
          key={randomString(24)}
        />

        <SignatureModal
          key={randomString(24)}
          ref={(e) => (this.signatureModal = e)}
          document={document}
          employee={employee}
        />

        <div
          className={
            "relative w-full flex flex-col justify-center align-middle items-center"
          }
        >
          <div
            className={
              "h-[32rem] w-full sm:w-auto relative overflow-y-scroll overflow-x-scroll"
            }
          >
            <Document file={document.DATA}>
              <Page pageNumber={1} />
              {this.renderFields()}
            </Document>
          </div>
        </div>
      </>
    );
  }
}

export default W4SignPage;
