import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getEmployeeNames, getWorkplaceAddresses} from "./constants";

class CheckMappingTab extends Component {
  state = {
    checkEmployees: null,
    employeeNames: null,
    matchedEmployees: null,
    matchedWorkplaces: null,
    workplaceAddresses: null,
  };

  async componentDidMount() {
    let {
      exportData,
      firstPaycheckStartRow,
      paycheckToCheckMapping,
      paycheckIncrementer,
      companyId,
      pastProvider,
    } = this.props.payroll.importData;

    if (!paycheckToCheckMapping) {
      paycheckToCheckMapping = {};
    }

    let employeeNames = {};

    let checkEmployees = await request("admin/check-employees", "GET", {
      CHECK_COMPANY_ID: companyId,
    });
    let checkWorkplaces = await request("admin/check-workplaces", "GET", {
      CHECK_COMPANY_ID: companyId,
    });

    checkWorkplaces = checkWorkplaces.filter((work) => work.active === true);

    let workplaceMapping = paycheckToCheckMapping["Workplace ID"];

    // for (let row = firstPaycheckStartRow; row < exportData.length; row += paycheckIncrementer) {
    //   let string = exportData[row + workplaceMapping.rowOffset][workplaceMapping.colOffset];
    //   let formattedString = (string.split(":")[1].substring(1)).split(",")[0];
    //
    //   workplaceAddresses[formattedString] = true;
    // }

    let workplaceAddresses = [
      ...new Set(
        getWorkplaceAddresses({exportData, firstPaycheckStartRow, paycheckIncrementer}, workplaceMapping, pastProvider)
      ),
    ];
    employeeNames = [...new Set(getEmployeeNames({exportData, paycheckIncrementer, firstPaycheckStartRow}, pastProvider))].sort();

    let matchedEmployees = this.matchEmployees(checkEmployees, employeeNames);
    let matchedWorkplaces = this.matchWorkplaces(
      checkWorkplaces,
      workplaceAddresses
    );

    this.setState({
      checkEmployees,
      employeeNames,
      matchedEmployees,
      checkWorkplaces,
      workplaceAddresses,
      matchedWorkplaces,
    });
  }

  handleClick = () => {
    let {matchedEmployees, checkWorkplaces, matchedWorkplaces} = this.state;

    this.props.updateImport({
      matchedEmployees,
      checkWorkplaces,
      matchedWorkplaces,
    });

    return true;
  };

  matchEmployees(checkEmployees, employeeNames) {
    let exportEmployees = {};

    for (let name of employeeNames) {
      let {firstName, lastName} = this.squareNameConvert(name);

      let checkEmployee = checkEmployees.find(
        ({first_name, last_name}) =>
          firstName?.split(" ")[0].toLowerCase() ===
          first_name?.split(" ")[0].toLowerCase() &&
          lastName.replace(" ", "").replace("'", "").toLowerCase() ===
          last_name.replace(" ", "").replace("'", "").toLowerCase()
      );

      if (checkEmployee) {
        exportEmployees[name] = checkEmployee.id;
      } else {
        exportEmployees[name] = undefined;
      }
    }

    return exportEmployees;
  }

  matchWorkplaces = (checkWorkplaces, workplaceStrings) => {
    let exportWorkplaces = {};

    for (let address of workplaceStrings) {
      let checkWorkplace = checkWorkplaces.find(workplace => workplace.address.line1 === address)

      if(checkWorkplace){
        exportWorkplaces[address] = checkWorkplace.id;
      } else {
        exportWorkplaces[address] = undefined;
      }
    }

    return exportWorkplaces;
  };

  squareNameConvert = (name) => {
    let [lastName, firstName] = name.split(",");

    if(name.split(",").length === 1){
      firstName = name.split(" ")[0];
      lastName = name.split(" ")[1];
    }

    firstName = firstName?.trim().split(" ")[0];

    return {
      firstName,
      lastName,
    };
  };

  render() {
    let {
      employeeNames,
      checkEmployees,
      matchedEmployees,
      checkWorkplaces,
      workplaceAddresses,
      matchedWorkplaces,
    } = this.state;

    let allEmployeeCount = 0;
    let unMatchedEmployeesCount = 0;
    let unMatchedEmployeeNames = [];
    let unMatchedCheckEmployees = [];
    let unMatchedWorkplaceAddresses = [];
    let unMatchedCheckWorkplaces = [];

    let checkEmployeesDict = {};
    let checkWorkplacesDict = {};

    if (checkEmployees) {
      checkEmployeesDict = parseIdDict(checkEmployees, "id");
    }

    if (checkWorkplaces) {
      checkWorkplacesDict = parseIdDict(checkWorkplaces, "id");
    }

    if (matchedEmployees) {
      allEmployeeCount = Object.keys(matchedEmployees).length;
      unMatchedEmployeeNames = Object.keys(matchedEmployees).reduce(
        (arr, key) => {
          if (!matchedEmployees[key]) {
            arr.push(key);
          }

          return arr;
        },
        []
      );

      unMatchedCheckEmployees = checkEmployees.filter(
        (checkEmp) => !Object.values(matchedEmployees).includes(checkEmp.id)
      );
    }

    if (matchedWorkplaces) {
      unMatchedWorkplaceAddresses = Object.keys(matchedWorkplaces).reduce(
        (arr, key) => {
          if (!matchedWorkplaces[key]) {
            arr.push(key);
          }

          return arr;
        },
        []
      );

      unMatchedCheckWorkplaces = checkWorkplaces.filter(
        (checkWorkplace) =>
          !Object.values(matchedWorkplaces).includes(checkWorkplace.name)
      );
    }

    return (
      <div className={"px-6"}>
        <div className={"flex flex-row justify-around"}>
          <div>
            <div className="font-medium my-5">Unmatched Workplaces</div>

            {unMatchedWorkplaceAddresses.map((item) => (
              <div className={"pb-3 flex flex-row items-center space-x-3"}>
                <div className="w-56">{item}</div>

                <div>
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </div>

                <div>
                  <FormSelect
                    className={"w-48"}
                    placeholder={"ㅤ"}
                    data={unMatchedCheckWorkplaces
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((workplace) => ({
                        label: workplace.name,
                        value: workplace.id,
                      }))}
                    onChange={(e) => {
                      matchedWorkplaces[item] = e;

                      this.setState({matchedWorkplaces});
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="font-medium my-5">Matched Workplaces</div>

            {matchedWorkplaces &&
              Object.keys(matchedWorkplaces).map((key) => {
                let workplace = checkWorkplacesDict[matchedWorkplaces[key]];

                if (matchedWorkplaces[key]) {
                  return (
                    <div
                      className={"pb-3 flex flex-row items-center space-x-3"}
                    >
                      <div className="w-40">{key}</div>

                      <div>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                      </div>

                      <div className={"w-40"}>{workplace.name}</div>

                      <div
                        className={"cursor-pointer"}
                        onClick={() => {
                          matchedWorkplaces[key] = undefined;

                          this.setState({matchedWorkplaces});
                        }}
                      >
                        <FontAwesomeIcon
                          className={"text-red-400"}
                          icon="fa-solid fa-x"
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <div className="flex flex-row justify-around">
          <div>
            <div className="font-medium my-5">
              Unmatched Square Employees ({unMatchedEmployeeNames.length})
            </div>

            {unMatchedEmployeeNames.map((item) => (
              <div className={"pb-3 flex flex-row items-center space-x-3"}>
                <div className="w-40">{item}</div>

                <div>
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </div>

                <div>
                  <FormSelect
                    className={"w-48"}
                    placeholder={"ㅤ"}
                    data={unMatchedCheckEmployees
                      .sort((a, b) => a.first_name.localeCompare(b.first_name))
                      .map((emp) => ({
                        label: emp.first_name + " " + emp.last_name,
                        value: emp.id,
                      }))}
                    onChange={(e) => {
                      matchedEmployees[item] = e;

                      this.setState({matchedEmployees});
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div>
            <div className="font-medium my-5">
              Matched Square Employees (
              {allEmployeeCount - unMatchedEmployeeNames.length})
            </div>

            {matchedEmployees &&
              Object.keys(matchedEmployees).map((key) => {
                let emp = checkEmployeesDict[matchedEmployees[key]];

                if (matchedEmployees[key]) {
                  return (
                    <div
                      className={"pb-3 flex flex-row items-center space-x-3"}
                    >
                      <div className="w-40">{key}</div>

                      <div>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                      </div>

                      <div className={"w-40"}>
                        {emp.first_name} {emp.last_name}
                      </div>

                      <div
                        className={"cursor-pointer"}
                        onClick={() => {
                          matchedEmployees[key] = undefined;

                          this.setState({matchedEmployees});
                        }}
                      >
                        <FontAwesomeIcon
                          className={"text-red-400"}
                          icon="fa-solid fa-x"
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(CheckMappingTab));
