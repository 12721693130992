import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class DepartmentDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {allowNull, unique} = this.props;

    const serverLocations = await request("departments", "GET", {SOFT: true});
    const data = serverLocations.map((item) => ({
      id: unique ? item.UNIQUE_ID : item.ID,
      unique: item.UNIQUE_ID,
      label: item.NAME,
    }));

    if (allowNull) {
      data.unshift({label: "No Department", unique: null, id: null});
    }

    this.setState({
      data,
    });
  }

  render() {
    const {data} = this.state;

    return <ComboBox {...this.props} label="Department" data={data} />;
  }
}

DepartmentDropdown.propTypes = {
  ...ComboBox.propTypes,

  allowNull: PropTypes.bool,
};

export default DepartmentDropdown;
