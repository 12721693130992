import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../../components/loading-spinner";
import moment from "moment";
import {capitalize} from "lodash";

class TaxWithholdingPage extends Component {
  state = {isLoading: true, withholdingAmount: null, pastPayouts: []};

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let withholdingAmount = await request(
      "partnerwebsite/taxwithholding/amount",
      "GET",
      null
    );

    let {data: pastPayouts} = await request(
      "banking/tax-withholding-payouts/",
      "GET"
    );

    pastPayouts = pastPayouts.map((item, i) => ({
      month: moment(item.created * 1000).format("MMMM YYYY"),
      amount: toDollars(item.amount, true),
      status: capitalize(item.status),
    }));

    this.setState({isLoading: false, withholdingAmount, pastPayouts});
  };

  firePayout = () => {
    request("partnerwebsite/taxwithholding/amount", "POST", null)
      .then(async (res) => {
        await this.refresh();

        //TODO: NEW ALERT
        // SweetAlert.fire({
        //   title: "Tax Withholding Payout Created!",
        //   icon: "success",
        // });
      })
      .catch((err) =>
        alert("Tax Withholding Payout could not be created! Please try again!")
      );
  };

  render() {
    let {isLoading, pastPayouts, withholdingAmount} = this.state;

    return (
      <div>
        <PageHeadings label="Tax Withholding" />

        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <div>
            <Card>
              <div className="flex flex-row items-center p-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{`Current Withholding Amount: ${toDollars(
                  withholdingAmount,
                  true
                )}`}</h3>
                <button
                  type="button"
                  className="inline-flex items-center ml-10 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => this.firePayout()}
                >
                  Payout
                </button>
              </div>
            </Card>

            <Card label="Past Tax Withholding Payouts">
              <Table
                columns={[
                  {
                    label: "Month",
                    value: "month",
                  },
                  {
                    label: "Status",
                    value: "status",
                  },
                  {
                    label: "Amount",
                    value: "amount",
                  },
                ]}
                data={pastPayouts}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default TaxWithholdingPage;
