import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import PdfViewer from "../../../../components/pdf-viewer";
import {request} from "../../../../utils/request";
import {encryptData} from "../../../../utils/amazon-utils/kms-util";
import {Trans} from "react-i18next";
const S3_BUCKET_NAME = "onboarding-company-documents";

class ReviewAndConfirmFileModal extends Component {
  state = {resolve: null, document: null};

  async submitDocument() {
    try {
      const {document} = this.state;

      const encryptedData = await encryptData(
        new Uint8Array(document.DATA.data)
      );

      const file = await request("aws/s3/file/encrypted/", "POST", {
        BUCKET: S3_BUCKET_NAME,
        FILE_NAME: document.FILE_NAME,
        ...encryptedData,
      });

      const {url} = file;

      this.close({
        FILE_NAME: document.FILE_NAME,
        KEY: url,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  open(document) {
    return new Promise((resolve) => {
      this.setState({resolve, document}, () => this.modal.open());
    });
  }

  close(accepted) {
    const {resolve} = this.state;
    resolve && resolve(accepted);
    this.modal.close();
  }

  getI18NextKey(path) {
    return (
      "modals.team.employee.onboarding.review-and-confirm-file-modal." + path
    );
  }

  render() {
    const {document} = this.state;

    return (
      <Modal
        large
        buttonLabel={<Trans i18nKey={this.getI18NextKey("submit.label")} />}
        closeLabel={<Trans i18nKey={this.getI18NextKey("cancel.label")} />}
        ref={(e) => (this.modal = e)}
        label={
          <Trans i18nKey={this.getI18NextKey("review-and-submit-form.label")} />
        }
        buttonOnClick={() => {
          this.submitDocument();
        }}
        deleteOnClick={() => {
          this.close(false);
        }}
      >
        <PdfViewer document={document} />
      </Modal>
    );
  }
}

export default ReviewAndConfirmFileModal;
