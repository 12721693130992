import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";

class IngredientUnitModal extends Component {
  state = {unit: null};

  open(unit = null) {
    this.setState({unit}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createIngredientUnit({name}) {
    let serverUnit = await request("ingredients/units", "POST", {
      NAME: name,
    });

    this.props.addState(serverUnit);
    this.modal.close();
  }

  async saveIngredientUnit({name}) {
    const {unit} = this.state;

    let serverUnit = await request("ingredients/units/" + unit.ID, "PATCH", {
      NAME: name,
    });

    this.props.updateState(serverUnit);
    this.modal.close();
  }

  render() {
    const {unit} = this.state;

    return (
      <Modal
        buttonLabel={unit ? "Save" : "Add"}
        label={unit ? "Edit Ingredient Unit" : "Save Ingredient Unit"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            unit
              ? this.saveIngredientUnit.bind(this)
              : this.createIngredientUnit.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Unit name is required"),
          })}
          initialValues={{
            name: unit?.NAME,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Ounces"
                  label="Unit Name"
                  name="name"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default IngredientUnitModal;
