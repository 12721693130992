import React, {Component} from "react";
import {
  CELL_TYPES,
  PRODUCT_SALES_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import moment from "moment";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";

class IndividualProductSalesReport extends Component {
  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      rows = reportData.map((entry) => {
        return {
          label: moment(entry.TIMESPAN).startOf("day").format("M/D"),
          numbers: entry,
        };
      });
    }

    return rows;
  };

  render() {
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Individual Product Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="individual-product-sales"
          endpoint="report/productlog"
          constantColumns={PRODUCT_SALES_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          productPicker={true}
        />
      </div>
    );
  }
}

export default IndividualProductSalesReport;
