import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ModifierModal from "../../../modals/sales/modifiers/modifier-modal";
import RolesTable from "../../../tables/team/roles-table";
import DepartmentModal from "../../../modals/team/departments/department-modal";
import moment from "moment";

class DepartmentsPage extends Component {
  state = {data: null};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverDepartments = await request("departments", "GET");

    this.setState({data: serverDepartments});
  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <DepartmentModal
          ref={(e) => (this.departmentModal = e)}
          addState={(department) =>
            this.props.router.navigate("/department/" + department.UNIQUE_ID)
          }
        />

        <PageHeadings
          label="Departments"
          description="Segment your employees into different company departments"
          buttons={[
            {
              label: "Add Department",
              onClick: () => this.departmentModal.open(),
            },
            // {
            //   label: "View Org Chart",
            //   onClick: () => this.props.router.navigate("/role"),
            // },
          ]}
        />

        <Table
          pagination
          data={data}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/department/" + row.UNIQUE_ID);
              },
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "MANAGER_EMPLOYEE_NAME",
              label: "Manager Name",
            },
            {
              value: "EMPLOYEES",
              label: "Number of Reports",
              format: (employees) => employees.length
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(DepartmentsPage);
