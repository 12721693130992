import {CELL_TYPES} from "./reporting-constants";

export const AUDIT_SALES_SUMMARY_ROWS = [
  {
    label: "Gross Sales",
    selector: "GROSS_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Product Sales",
    selector: "PRODUCT_SALES",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Service Fees",
    selector: "SERVICE_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Custom Fees",
    selector: "CUSTOM_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Refunds",
    selector: "REFUNDS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Discounts",
    selector: "DISCOUNTS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    selector: "NET_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Tips",
    selector: "TIPS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Card Tips",
    selector: "CARD_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Cash Tips",
    selector: "CASH_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Gift Cards",
    selector: "GIFT_CARDS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Delivery Fees",
    selector: "DELIVERY_FEES_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Tax",
    selector: "TAXES",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Pass Processing Collected",
    selector: "PASS_PROCESSING_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Total Sales",
    selector: "NET_PAYMENT",
    type: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Processing",
    selector: "PROCESSING_FEES",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    selector: "NET_TOTAL",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const AUDIT_PAYMENT_METHODS_COLUMNS = [
  {
    label: "Payment Amount",
    columnSelector: "TOTAL_COLLECTED",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
  },
  {
    label: "(Refund Amount)",
    columnSelector: "REFUND_AMOUNT",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Payment",
    columnSelector: "NET_PAYMENT",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    columnType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const AUDIT_PRODUCT_SALES_COLUMNS = [
  {label: "Category", columnSelector: "CATEGORY_NAME"},
  {label: "Products Sold", columnSelector: "ORDER_COUNT"},
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Refunds)", columnSelector: "REFUNDS", dollarAmount: true, columnType: CELL_TYPES.NEGATIVE,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    columnType: CELL_TYPES.NEGATIVE,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
  },
  {label: "Tax", columnSelector: "TAXES", dollarAmount: true, style: []},
];

export const AUDIT_CATEGORY_SALES_COLUMNS = [
  {label: "Quantity Sold", columnSelector: "ORDER_COUNT"},
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {label: "Tax", columnSelector: "TAXES", dollarAmount: true},
];

export const AUDIT_PLATFORM_SALES_COLUMNS = [
  {
    label: "Order Count",
    columnSelector: "PAYMENT_COUNT",
  },
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Product Sales",
    columnSelector: "PRODUCT_SALES",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Service Fees",
    columnSelector: "SERVICE_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Custom Fees",
    columnSelector: "CUSTOM_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Card Tips",
    columnSelector: "CARD_TIPS",
    dollarAmount: true,
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
  },
  {
    label: "Gift Card Sales",
    columnSelector: "GIFT_CARDS",
    dollarAmount: true,
  },
  {
    label: "Pass Processing Collected",
    columnSelector: "PASS_PROCESSING_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Total Sales",
    columnSelector: "NET_PAYMENT",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const AUDIT_GIFT_CARD_SALES_COLUMNS = [
  {label: "Quantity Sold", columnSelector: "ORDER_COUNT"},
  {
    label: "Gift Cards",
    columnSelector: "GIFT_CARDS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
];
