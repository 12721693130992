import {dayToIsoDay} from "./util";

export function calculateWeekStartEpochFromMoment(fromMoment, isoWeekStart) {
  const firstIsoDay = dayToIsoDay(isoWeekStart);

  const weekday = fromMoment.isoWeekday();

  const dayOffset =
    weekday < firstIsoDay ? weekday - firstIsoDay + 7 : weekday - firstIsoDay;

  return fromMoment.startOf("day").add(-dayOffset, "days").valueOf();
}

export function fetchToggleLocations(schedulingLocations, loggedInLocation) {
  if (!schedulingLocations || schedulingLocations.length === 0) {
    return [loggedInLocation.ID];
  }

  return schedulingLocations;
}
