import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import ChecklistTaskOptionModal from "./checklist-task-option-modal";
import {request} from "../../../utils/request";
import CompanyDocumentsDropdown from "../../../dropdowns/team/onboarding/company-documents-dropdown";

class ChecklistTaskModal extends Component {
  state = {task: null, seq: -1, options: [], companyDocuments: []};

  open(task = null, seq = -1) {
    this.setState(
      {
        seq,
        task,
        options: task ? JSON.parse(JSON.stringify(task.OPTIONS)) : [],
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      }
    );
  }

  async createTask({name, type}) {
    const {seq, options} = this.state;
    const {checklist} = this.props;

    const taskPayload = {
      NAME: name,
      TYPE: type,
      OPTIONS: options,
      SEQ: seq,
    };

    const serverLine = await request(
      "checklists/" + checklist.ID + "/item/",
      "POST",
      taskPayload
    );

    this.props.addState(serverLine);
    this.modal.close();
  }

  async saveTask({name, type}) {
    const {task, options} = this.state;
    const {checklist} = this.props;

    const taskPayload = {
      NAME: name,
      TYPE: type,
      OPTIONS: options,
      SEQ: task.SEQ,
    };

    const serverLine = await request(
      "checklists/" + checklist.ID + "/item/" + task.ID,
      "PATCH",
      taskPayload
    );

    this.props.updateState(serverLine.ID, serverLine);
    this.modal.close();
  }

  async deleteTask() {
    const {task} = this.state;
    const {checklist} = this.props;

    const serverLine = await request(
      "checklists/" + checklist.ID + "/item/" + task.ID,
      "DELETE",
      {}
    );

    this.props.updateState(serverLine.ID);
    this.modal.close();
  }

  renderContent(formikOptions) {
    const {options} = this.state;
    const {values} = formikOptions;

    if (
      values.type === "RATE" ||
      values.type === "DROPDOWN" ||
      values.type === "RADIO"
    ) {
      return (
        <Table
          className={"mt-4"}
          data={options}
          actionTexts={[
            {label: "Add Option", onClick: () => this.optionModal.open()},
          ]}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row, index) => this.optionModal.open(row, index),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              width: 1,
              value: "Seq",
              label: "seq",
            },
          ]}
        />
      );
    }
  }

  render() {
    const {task, options} = this.state;

    return (
      <Modal
        deleteLabel={task && "Delete"}
        deleteOnClick={this.deleteTask.bind(this)}
        buttonLabel={task ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={task ? "Edit Task" : "Create Task"}
      >
        <Formik
          onSubmit={
            task ? this.saveTask.bind(this) : this.createTask.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: task?.NAME ?? "",
            type: task?.TYPE ?? "CHECK",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            const data = [
              {value: "CHECK", label: "Checkmark"},
              {value: "BOOL", label: "Yes or No"},
              {value: "NUM", label: "Number"},
              {value: "SHORT", label: "Short Answer"},
              {value: "LONG", label: "Long Answer"},
              {value: "DROPDOWN", label: "Dropdown"},
              {value: "RADIO", label: "Radio Select"},
              {value: "PICTURE", label: "Picture"},
              {value: "TEMP", label: "Temperature"},
              {value: "RATE", label: "Rating"},
            ];

            return (
              <form onSubmit={handleSubmit}>
                <ChecklistTaskOptionModal
                  ref={(e) => (this.optionModal = e)}
                  addState={(task) =>
                    this.setState({options: [...options, task]})
                  }
                  updateState={(index, option) => {
                    if (option) {
                      options.splice(index, 1, option);
                    } else {
                      options.splice(index, 1);
                    }

                    this.setState({options});
                  }}
                />

                <FormInput label="Name" name="name" options={formikOptions} />

                <FormSelect
                  name="type"
                  label="Type"
                  options={formikOptions}
                  data={data}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChecklistTaskModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  addState: PropTypes.func.isRequired,
};

export default ChecklistTaskModal;
