import React, {Component} from "react";
import {Modal, Table, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getReadRecipients} from "../../utils/messaging/message-helper";
import {withRouter} from "../../utils/navigation";

class ReadReceiptModal extends Component {
  state = {
    readRecipients: [],
    unreadRecipients: [],
    readEmployees: [],
    unreadEmployees: [],
  };

  open(read, unread, readEmployees, unreadEmployees) {
    this.setState({
      readRecipients: read,
      unreadRecipients: unread,
      readEmployees,
      unreadEmployees,
    });

    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  render() {
    const {readEmployees, unreadEmployees} = this.state;

    return (
      <Modal
        large
        whiteBackground
        label={"Message Read By"}
        hideClose
        ref={(e) => (this.modal = e)}
      >
        <Tab
          data={[
            {label: "Read", id: "read"},
            {label: "Unread", id: "unread"},
          ]}
        >
          {(id) => {
            let data = [];

            if (id === "read") {
              data = readEmployees ?? [];
            } else if (id === "unread") {
              data = unreadEmployees ?? [];
            }

            return (
              <Table
                data={data}
                columns={[
                  {
                    value: "FULL_NAME",
                    label: "NAME",
                    format: (value, row) => {
                      return (
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={getObjectImage(
                                row,
                                "LOGO",
                                "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
                              )}
                              alt=""
                            />
                          </div>

                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {row.FULL_NAME}
                            </div>

                            <div className="text-sm text-gray-500">
                              {row.ROLES?.map((item) => item.ROLE_NAME).join(
                                ", "
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    value: "LOCATION_NAME",
                    label: "HOME LOCATION",
                  },
                ]}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) =>
                      this.props.router.navigateNewTab(
                        "/employee/" + row.UNIQUE_ID
                      ),
                  },
                ]}
                ref={(e) => (this.tableRef = e)}
              />
            );
          }}
        </Tab>
      </Modal>
    );
  }
}

ReadReceiptModal.propTypes = {
  readRecipients: PropTypes.array,
  unreadRecipients: PropTypes.array,
};

export default withRouter(ReadReceiptModal);
