import React, {Component} from "react";
import {UserIcon} from "@heroicons/react/outline";
import FormInput from "./form-input";

class FormName extends Component {
  render() {
    return (
      <FormInput
        {...this.props}
        type="text"
        autoComplete="name"
        leadingIcon={
          <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        }
      />
    );
  }
}

FormName.propTypes = {
  ...FormInput.propTypes,
};

export default FormName;
