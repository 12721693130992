import React, {Component} from "react";
import RadioDropdown from "../../../components/form-elements/radio-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import {setupReduxConnection} from "../../../redux";
import ReportingDateComponent from "./reporting-date-component";
import {CSVLink} from "react-csv";
import {request} from "../../../utils/request";
import {DownloadIcon} from "@heroicons/react/solid";
import LocationFilterDropdown from "../../../components/location-filter-dropdown";
import PropTypes from "prop-types";
import {FormSelect, Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";
import {ChevronDown} from "react-feather";
import CSVDropdown from "../csv-dropdown";
import Checkbox from "../../../components/form-elements/checkbox";
import {convertEpochsToDateString} from "../../../utils/reporting-helper";

class ReportingFilter extends Component {
  state = {
    locationIdArray: [],
    products: [],
    filenameDateString: "",
    hasThird: false,
  };

  async componentDidMount() {
    let {locationIdArray} = this.props.reporting;
    let {location} = this.props.shop;

    if (locationIdArray.length === 0) {
      this.props.updateLocationIdArray([location.ID]);
    }

    let types = await request("types/order", "GET");

    if (types.find((type) => type.TYPE === "THIRD")) {
      this.setState({hasThird: true});
    }

    this.updateDateString();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {filenameDateString} = this.state;

    if (
      prevProps.reporting.startEpoch !== this.props.reporting.startEpoch ||
      prevProps.reporting.endEpoch !== this.props.reporting.endEpoch ||
      !filenameDateString
    ) {
      this.updateDateString();
    }
  }

  updateDateString = () => {
    let {startEpoch, endEpoch} = this.props.reporting;

    const newDateString = convertEpochsToDateString(startEpoch, endEpoch);

    this.setState({filenameDateString: newDateString});
  };

  render() {
    let {
      locationPicker,
      groupbyFilter,
      timeframePicker,
      product,
      hideTime,
      csvData,
      csvHeaders,
      customCSVLink,
      hideCSVLink,
      showExcludeThird,
    } = this.props;
    let {excludeThird, locationIdArray, groupReports} = this.props.reporting;
    const {REPORTING_MODE} = this.props.shop.settings;
    let {products, hasThird} = this.state;

    return (
      <div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 space-y-3 md:space-y-0">
            <ReportingDateComponent
              hideTime={hideTime}
              enableStripePayouts={this.props.enableStripePayouts}
            />

            {locationPicker && (
              <LocationFilterDropdown
                onChange={(e) => this.props.updateLocationIdArray(e)}
              />
            )}

            {timeframePicker && (
              <div className={"hidden md:flex flex-row items-center"}>
                <RadioDropdown
                  defaultValue={"TIMESPAN"}
                  name="timeFrame"
                  data={[
                    {label: "Summary", value: "TIMESPAN"},
                    {label: "Daily", value: "DAY"},
                    {label: "Weekly", value: "WEEK"},
                    {label: "Monthly", value: "MONTH"},
                  ]}
                  onChange={(val) => {
                    this.props.updateTimeFrame(val);
                  }}
                />

                <Tooltip
                  className="pl-2"
                  label="Choose the grouping of Sales data"
                />
              </div>
            )}

            {product && (
              <RadioDropdown
                defaultString={"Select a Product"}
                name="product"
                data={products.map((product) => {
                  let {ID, NAME} = product;

                  return {
                    label: NAME,
                    value: ID,
                  };
                })}
                onChange={(val) => this.props.updateSelectedProduct(val)}
              />
            )}

            {this.props.customFields}

            {!!hasThird && showExcludeThird && (
              <div className={"hidden md:flex flex-row items-center"}>
                <Checkbox
                  label={"Exclude Third Party"}
                  checked={excludeThird}
                  value={excludeThird}
                  onChange={(e) => {
                    this.props.setExcludeThird(!excludeThird);
                  }}
                />
              </div>
            )}

            {groupbyFilter &&
              locationIdArray?.length > 1 &&
              REPORTING_MODE === "AUDIT" && (
                <div className="hidden md:flex flex-row items-center">
                  <FormSelect
                    className="w-48"
                    ignoreMargin
                    ignoreInnerMargin
                    data={[
                      {label: "Group Items", value: true},
                      {label: "Do Not Group Items", value: false},
                    ]}
                    value={groupReports}
                    onChange={(val) => {
                      this.props.updateGroupReports(val);
                    }}
                  />

                  <Tooltip
                    className="pl-2"
                    data={[
                      {
                        label: "Group Items",
                        data: "If group items is chosen, we will group the same items at different locations into a single row.",
                      },
                    ]}
                  />
                </div>
              )}

            <div className="flex flex-1 md:block md:flex-none ">
              <button
                type="button"
                className="md:ml-7 flex-1 justify-center text-center inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                onClick={() => this.props.onGenerate()}
              >
                Generate Report
              </button>
            </div>
          </div>

          {!hideCSVLink && (
            <div className={"hidden md:block"}>
              {customCSVLink && (
                <CSVDropdown
                  reportData={this.props.reportData}
                  data={this.props.customCSVLink}
                />
              )}

              {!customCSVLink && (
                <CSVLink
                  filename={`${this.props.filenamePrefix} ${this.state.filenameDateString}.csv`}
                  data={csvData}
                  headers={csvHeaders}
                >
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    <DownloadIcon className="h-5 w-5" />
                  </button>
                </CSVLink>
              )}
            </div>
          )}
        </div>
        <div className="my-6 h-0.5 w-full bg-gray-200"></div>
      </div>
    );
  }
}

ReportingFilter.propTypes = {
  locationPicker: PropTypes.bool,
  groupbyFilter: PropTypes.bool,
  timeframePicker: PropTypes.bool,
};

export default setupReduxConnection(["menu", "shop", "reporting"])(
  ReportingFilter
);
