import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";

class ScheduledProducts extends Component {
  render() {
    let {DATE, PRODUCTS} = this.props.day;

    return (
      <div className="py-12 px-14 pt-10">
        <div className="font-semibold pb-3">Products Scheduled for {DATE}</div>

        <Table
          columns={COLUMN_HEADERS}
          data={PRODUCTS.map((item) => ({...item}))}
        />
      </div>
    );
  }
}

const COLUMN_HEADERS = [
  {
    label: "Product",
    value: "PRODUCT_NAME",
    sortable: true,
  },
  {
    label: "Quantity",
    value: "LINE_ITEM_QUANTITY",
    sortable: true,
  },
  {
    label: "Category",
    value: "CATEGORY_NAME",
    sortable: true,
  },
];

export default ScheduledProducts;
