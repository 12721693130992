import React, {Component} from "react";
import AdminModal from "../../../../modals/shop/company-structure/admin-modal";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import PartnersTable from "../../../../tables/shop/company-structure/partners-table";

class CompanyPartnersTab extends Component {
  render() {
    const {partners, syncState, people, companies} = this.props;

    return (
      <div>
        <AdminModal
          ref={(e) => (this.modalRef = e)}
          people={people}
          syncState={syncState}
          companies={companies}
          level={"COMPANY"}
        />

        <Card
          label={"Company Partners"}
          button={{
            label: "Add Admin+",
            onClick: () => this.modalRef.open(),
          }}
        >
          <PartnersTable
            partners={partners}
            syncState={syncState}
            companies={companies}
            people={people}
            type={"COMPANY"}
          />
        </Card>
      </div>
    );
  }
}

export default CompanyPartnersTab;
