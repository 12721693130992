import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Loading,
  PageHeadings,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";

class LocationPage extends Component {
  state = {location: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let location = await request("admin/location/" + id, "GET", null);

    this.setState({
      location,
    });
  }

  openDashboard() {
    const {location} = this.state;

    localStorage.setItem("CURRENT_LOCATION", "" + location.UNIQUE_ID);
    window.open("/", "_blank").focus();
  }

  render() {
    const {location} = this.state;

    if (location === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={location.NAME}
          button={{
            label: "View Dashboard",
            onClick: this.openDashboard.bind(this),
          }}
        />

        <TwoColumnList
          label="Location Details"
          description="Details about the contact and the information about them"
          data={[
            {
              label: "Name",
              value: location.NAME,
            },
            {
              label: "Company",
              value: location.COMPANY_NAME,
            },
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.customerModal.open(),
            },
          ]}
        />

        <Tab
          data={[
            {id: "invoices", label: "Partners"},
            {id: "methods", label: "Payment Methods"},
          ]}
        >
          {(id) => {
            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(LocationPage);
