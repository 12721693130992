import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";

class DeploymentGroupModal extends Component {
  state = {group: null};

  open(group = null) {
    this.setState({group}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createGroup({name, device_type}) {
    const serverLocation = await request("readers/deployments/groups", "POST", {
      device_type,
      name,
    });

    this.props.addState(serverLocation);
    this.modal.close();
  }

  async saveGroup({first, last, phone, email, company, notes}) {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts/" + contact.ID, "PATCH", {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      COMPANY: company,
    });

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {group} = this.state;

    return (
      <Modal
        buttonLabel={group ? "Save" : "Add"}
        label={group ? "Edit Franchise" : "Create Franchise"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            group ? this.saveGroup.bind(this) : this.createGroup.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
          })}
          initialValues={{
            name: group?.NAME ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Live Readers"
                  label="Name"
                  name="name"
                />

                <FormSelect
                  options={formikOptions}
                  label="Company"
                  name="device_type"
                  data={[
                    {label: "BBPOS WisePOS E", value: "bbpos_wisepos_e"},
                    {label: "Stripe S700", value: "stripe_s700"},
                  ]}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(DeploymentGroupModal);
