import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/notification-helper";

class ProcessesTable extends Component {
  deleteProcess(row) {
    const {syncState} = this.props;

    showLoadingConfirmAlert(
      "Delete Process",
      "Are you sure you want to delete this process?"
    ).then(async (close) => {
      request("checklists/" + row.ID, "DELETE")
        .then(async () => {
          await syncState();

          close();
          showSuccessNotification(
            "Process Deleted",
            "We have deleted the process"
          );
        })
        .catch(() => {
          showErrorNotification(
            "Error deleting process",
            "There was an error deleting this process. Contact support if this problem persists."
          );
        });
    });
  }

  render() {
    const {data} = this.props;

    return (
      <Table
        classNames={"mt-4"}
        data={data}
        actionButtons={[
          {
            label: "Edit",
            onClick: (row) =>
              this.props.router.navigate(
                `/onboarding/process/create/${row.UNIQUE_ID}`
              ),
          },
          {
            label: "Delete",
            onClick: (row) => this.deleteProcess(row),
          },
        ]}
        columns={[
          {
            value: "NAME",
            label: "Process Name",
          },
          {
            value: "NEW_INSTANCES",
            label: "New",
          },
          {
            value: "IN_PROGRESS_INSTANCES",
            label: "In Progress",
          },
          {
            value: "COMPLETED_INSTANCES",
            label: "Completed",
          },
        ]}
      />
    );
  }
}

export default withRouter(ProcessesTable);
