import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {
  PageHeadings,
  Table,
  AdvancedFilter,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShippingBoxModal from "../../../modals/sales/ecommerce/shipping-box-modal";
import {updateStateDelegator} from "../../../utils/util";

class ShippingBoxesPage extends Component {
  state = {
    boxes: null,
    isLoading: true,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let boxes = await request("ecommerce/shippingboxes", "GET");

    this.setState({isLoading: false, boxes});
  }

  render() {
    let {isLoading, boxes} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ShippingBoxModal
          ref={(e) => (this.boxModal = e)}
          addState={(box) => this.setState({boxes: [...boxes, box]})}
          updateState={(id, box) => {
            this.setState({
              boxes: updateStateDelegator(boxes, id, box),
            });
          }}
        />

        <PageHeadings
          label="Shipping Boxes"
          description="Filter to find current and future tickets"
          button={{label: "Create Box", onClick: () => this.boxModal.open()}}
        />

        <Table
          data={boxes}
          actionButtons={[
            {
              label: "View",
              onClick: (box) => this.boxModal.open(box),
            },
          ]}
          columns={[
            {
              label: "Name",
              value: "NAME",
            },
            {
              label: "Length",
              value: "LENGTH",
            },
            {
              label: "Width",
              value: "WIDTH",
            },
            {
              label: "Height",
              value: "HEIGHT",
            },
          ]}
          pagination
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ShippingBoxesPage));
