import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  FormEmail,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormDateSpecify from "../../components/form-date-specify";
import FormStateSelect from "../../components/form-state-select";
import {
  createPayrollEmployee,
  getEmployeeOnboardLink,
  remindPayrollEmployeeToOnboard
} from "../../utils/payroll-helper";

class PayrollSkeletonEmployeeSettingsModal extends Component {
  state = {payrollEmployee: null, error: false};

  open(payrollEmployee) {
    this.setState({payrollEmployee, error: false}, () => this.slide.open());
  }

  handleClick = () => {
    let {status} = this.state.payrollEmployee?.PAYROLL?.onboard;

    let employeeCreated = status !== "not_started" && status !== "none";

    if(!employeeCreated) {
      this.formikRef.submitForm();
    } else {
      this.slide.close();
    }
  }

  submitForm = async (values) => {
    let {
      firstName,
      lastName,
      email,
      line1,
      line2,
      city,
      state,
      zip,
      dob,
      startDate,
      ssn,
    } = values;

    let {ID} = this.state.payrollEmployee;

    try {
      await createPayrollEmployee({
        EMPLOYEE_ID: ID,
        FIRST_NAME: firstName,
        LAST_NAME: lastName,
        EMAIL: email,
        START_DATE: startDate,
        ADDRESS_1: line1,
        ADDRESS_2: line2,
        CITY: city,
        STATE: state,
        ZIP: zip,
        DOB: dob,
        SSN: ssn,
      });

      await this.props.onSave();

      this.slide.close();

      return true;
    } catch (e) {
      this.setState({error: true});
      return false;
    }
  };

  render() {
    let {payrollEmployee, error} = this.state;
    let status = payrollEmployee?.PAYROLL?.onboard?.status;

    let employeeCreated = status !== "not_started" && status !== "none";

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`${payrollEmployee?.fullName}'s Payroll Information`}
        buttonLabel={employeeCreated ? "Ok" : "Create"}
        buttonOnClick={this.handleClick}
        disableButtonLoading={true}
      >
        {error && (
          <div className="text-red-500">
            Invalid data, please correct then re-submit
          </div>
        )}

        <div className="flex flex-row items-center justify-around">

        <button
          type="button"
          className="mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
          onClick={async () => {
            await getEmployeeOnboardLink(payrollEmployee.ID).then(
              (res) => {
                window.open(res, "_blank").focus();
              }
            );
          }}
        >
          <div>
            <div>Open Payroll Link</div>

            <div className="text-xs text-gray-500">as {payrollEmployee?.fullName}</div>
          </div>
        </button>
        </div>

        {employeeCreated && <div>
          {`${payrollEmployee?.fullName}'s information is fully loaded into our system for filings, even though she is not an active employee.`}
        </div>}

        {!employeeCreated && (
          <Formik
            initialValues={{}}
            onSubmit={this.submitForm}
            innerRef={(e) => (this.formikRef = e)}
          >
            {(formikOptions) => {
              return (
                <div>
                  <FormInput
                    name={"firstName"}
                    label={"Legal First Name"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"lastName"}
                    label={"Legal Last Name"}
                    options={formikOptions}
                  />

                  <FormDateSpecify
                    name={"dob"}
                    label="Date of Birth"
                    options={formikOptions}
                  />

                  <FormInput
                    name={"line1"}
                    label={"Address Line 1"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"line2"}
                    label={"Address Line 2"}
                    options={formikOptions}
                  />

                  <div className="flex flex-row">
                    <FormInput
                      name={"city"}
                      label={"City"}
                      options={formikOptions}
                    />

                    <FormStateSelect
                      className="ml-5"
                      name="state"
                      label={"State"}
                      options={formikOptions}
                    />

                    <FormInput
                      className="ml-5 w-24"
                      name={"zip"}
                      label={"Zipcode"}
                      options={formikOptions}
                    />
                  </div>

                  {/*Employment start date*/}
                  <FormDateSpecify
                    name={"startDate"}
                    label="Employment Start Date"
                    options={formikOptions}
                  />

                  <FormInput
                    label={"SSN"}
                    name={"ssn"}
                    options={formikOptions}
                    type={"password"}
                  />
                </div>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
}

export default PayrollSkeletonEmployeeSettingsModal;
