import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {DRAWER_TYPES} from "../../utils/operations-constants";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";

class CashDrawerTable extends Component {
  render() {
    const {drawers} = this.props;

    return (
      <Table
        pagination
        route="cashboxManagement/dumb"
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate(`/drawer/${row.ID}`),
          },
        ]}
        columns={[
          {
            value: "DRAWER_TYPE",
            label: "Type",
            format: (value) => {
              return value === DRAWER_TYPES.EMPLOYEE_DRAWER
                ? "Employee"
                : "Cash";
            },
          },
          {
            value: "DEVICE_NAME",
            label: "Device/Employee",
            format: (device, row) => {
              return row.DRAWER_TYPE === DRAWER_TYPES.EMPLOYEE_DRAWER
                ? row.EMPLOYEE_NAME
                : device ?? "All Devices";
            },
          },
          {
            value: "DATE_OPEN",
            label: "Opened",
            mobile: "lg:table-cell",
            format: (value) => {
              return moment(value).format("MMM DD, hh:mm A");
            },
          },
          {
            value: "DATE_CLOSE",
            label: "Closed",
            mobile: "lg:table-cell",
            format: (value) => {
              return value ? moment(value).format("MMM DD, hh:mm A") : "Open";
            },
          },
          {
            label: "Expected in Drawer",
            value: "AMOUNT_EXPECTED",
            sortable: true,
            format: (value) => {
              return value !== null ? toDollars(value, true) : "-";
            },
          },
          {
            label: "Close Amount",
            value: "AMOUNT_CLOSE",
            sortable: true,
            format: (value) => {
              return value !== null ? toDollars(value, true) : "-";
            },
          },
          {
            label: "Difference",
            value: "DIFFERENCE",
            sortable: true,
            format: (value) => {
              return value !== null ? toDollars(value, true) : "-";
            },
          },
          {
            label: "Deposit",
            value: "AMOUNT_DEPOSIT",
            sortable: true,
            format: (value) => {
              return value !== null ? toDollars(value, true) : "-";
            },
          },
        ]}
        {...this.props}
      />
    );
  }
}

CashDrawerTable.propTypes = {
  drawers: PropTypes.array.isRequired,
};

export default withRouter(CashDrawerTable);
