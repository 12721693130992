import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";

class PolicyEmployeeModal extends Component {
  state = {employee: null};

  open(employee = null) {
    this.setState({employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createPolicyEmployee({employee, balance, override}) {
    const {policy} = this.props;

    const serverPolicyEmployee = await request(
      "timeoff/policies/" + policy.ID + "/employees",
      "POST",
      {
        EMPLOYEE_ID: employee,
        BALANCE: parseInt(balance),
        OVERRIDE_APPROVAL: override === "1" ? 1 : 0,
      }
    );

    this.props.addState(serverPolicyEmployee);
    this.modal.close();
  }

  async deleteRole() {
    const {role} = this.state;
    const {employee} = this.props;

    try {
      await request("employeeroles/employee/" + employee.ID, "DELETE", {
        EMPLOYEE_ROLE_ID: role.EMPLOYEE_ROLE_ID,
      });
    } catch (err) {
      if (err.code === 213) {
        alert("Every employee must have at least one role.");
        this.modal.close();
        return;
      }
      console.log(err);
      return;
    }

    this.props.removeState(role);
    this.modal.close();
  }

  render() {
    const {policy, employees} = this.props;
    const {employee} = this.state;

    const exclude = employees.map((item) => item.EMPLOYEE_ID);

    return (
      <Modal
        tooltip={{
          data: `${
            employee
              ? "Delete this role from this employee"
              : "Add a new role to this employee"
          }`,
        }}
        buttonLabel={employee ? null : "Add"}
        deleteLabel={employee ? "Remove" : null}
        label={employee ? "Edit Employee Policy" : "Enroll Employee"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deleteRole()}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.createPolicyEmployee.bind(this)}
          initialValues={{
            employee: employee?.EMPLOYEE_ID ?? null,
            balance: employee?.BALANCE ?? "0",
            override: employee?.OVERRIDE_APPROVAL ?? "0",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <EmployeeDropdown
                  name="employee"
                  exclude={exclude}
                  options={formikOptions}
                  placeholder="Click to select employee"
                />

                {!policy.UNLIMITED && (
                  <FormInput
                    name="balance"
                    label="Employee Hours"
                    options={formikOptions}
                  />
                )}

                <FormBoolean
                  name="override"
                  options={formikOptions}
                  label="Override Approvals"
                  tooltip="If override is enabled, this employee will have their time off requests auto approved"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PolicyEmployeeModal.propTypes = {
  policy: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,

  addState: PropTypes.func,
  updateState: PropTypes.func,
};

export default PolicyEmployeeModal;
