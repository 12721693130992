import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class ShowTextModal extends Component {
  state = {note: null};

  open(note) {
    this.setState({note}, () => this.modal.open());
  }

  render() {
    const {note} = this.state;
    return (
      <Modal
        closeLabel="Close"
        label={note?.NOTE_NAME ?? ""}
        ref={(e) => (this.modal = e)}
      >
        <div className={"text-md text-gray-700 justify-start align-top"}>
          {note?.NOTE ?? ""}
        </div>
      </Modal>
    );
  }
}

export default ShowTextModal;
