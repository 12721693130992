import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormTextArea,
  FormBoolean,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";
import {request} from "../../../utils/request";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import ProductTagsDropdown from "../../../dropdowns/sales/product-tags-dropdown";
import * as Yup from "yup";
import LocationDropdown from "../../../features/reporting/reporting-filter/location-dropdown";
import LocationsDropdown from "../../../dropdowns/team/locations-dropdown";

class ProductSyncModal extends Component {
  state = {product: null, categories: null};

  open(product = null) {
    this.setState({product, categories: null}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveProduct = async ({location, category}) => {
    const {product} = this.state;

    let productPayload = {
      LOCATIONS: [{LOCATION_ID: location, CATEGORY_ID: category}],
      PRODUCTS: [product.ID],
    };

    const serverResponse = await request(
      "sync/products",
      "POST",
      productPayload
    );

    this.modal.stopLoading();
  };

  render() {
    const {categories} = this.state;

    return (
      <Modal
        buttonLabel="Sync"
        label="Sync Product"
        description="Sync a product to a different location"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            location: null,
            category: null,
          }}
          validationSchema={Yup.object({})}
          onSubmit={this.saveProduct}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <LocationsDropdown
                  isCompany
                  label="Location"
                  name="location"
                  options={formikOptions}
                  onChangeSoft={async ({id}) => {
                    setFieldValue("category", null);

                    const categories = await request(
                      "sync/categories/" + id,
                      "GET"
                    );

                    this.setState({categories});
                  }}
                />

                <FormSelect
                  label="Category"
                  name="category"
                  options={formikOptions}
                  data={
                    categories?.map((item) => {
                      return {value: item.ID, label: item.NAME};
                    }) ?? []
                  }
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ProductSyncModal;
