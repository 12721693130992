import {Component} from "react";

class LoadingSpinner extends Component {
  render() {
    let {size, borderSize, color} = this.props;

    if(!size){
      size = 30;
    }

    if(!borderSize){
      borderSize = 2;
    }

    if(!color){
      color = "rgb(107 114 128)";
    }

    return (
      <div className="flex justify-center items-center">
        <style>
          {`.loader {
    width: ${size}px;
    height: ${size}px;
    border: ${borderSize}px solid ${color};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } `}
        </style>
        <span className="loader justify-center"></span>
      </div>
    );
  }
}

export default LoadingSpinner;
