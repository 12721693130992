import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import ButtonGroup from "../buttons/button-group";
import Dropdown from "../dropdown/dropdown";
import ButtonList from "./button-list";
import Button from "./button";

class ButtonRow extends Component {
  renderButtons() {
    const {button, buttons} = this.props;
    const finalButtons = [];

    if (buttons) {
      finalButtons.push(...buttons);
    }

    if (button) {
      finalButtons.push(button);
    }

    return finalButtons.map((item, i) => {
      const {label, type, icon, onClick, ignoreIconMargin} = item;

      if (type === "list") {
        return <ButtonList {...item} />;
      }

      if (type === "group") {
        return <ButtonGroup {...item} />;
      }

      if (type === "dropdown") {
        return <Dropdown {...item} />;
      }

      const globalClasses = [
        i > 0 && "ml-4",
        "relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border",
      ];

      let iconElement = <div className="h-5" />;

      if (icon) {
        iconElement = (
          <FontAwesomeIcon
            icon={icon}
            className={classNames(
              type === "gray" ? "text-gray-400" : "text-indigo-300",
              ignoreIconMargin ? "" : "-ml-1 mr-2",
              "h-5 w-5"
            )}
          />
        );
      }

      return <Button key={i} {...item} />;

      if (type === "gray") {
        return (
          <button
            key={i}
            type="button"
            onClick={() => onClick && onClick()}
            className={classNames(
              ...globalClasses,
              "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            {iconElement}
            {label}
          </button>
        );
      }

      return (
        <button
          key={i}
          type="button"
          onClick={() => onClick && onClick()}
          className={classNames(
            ...globalClasses,
            "border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          {iconElement}
          {label}
        </button>
      );
    });
  }

  render() {
    const {button = null, buttons = null, marginPadding} = this.props;

    if (button === null && buttons === null) {
      return <div />;
    }

    return (
      <div
        className={classNames(
          marginPadding && "ml-4 mt-4",
          "flex flex-col xxxs:space-y-1 sm:space-x-3 sm:flex-row"
        )}
      >
        {this.renderButtons()}
      </div>
    );
  }
}

ButtonRow.propTypes = {
  marginPadding: PropTypes.bool,

  button: PropTypes.shape({
    type: PropTypes.oneOf(["primary", "gray"]),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    ignoreIconMargin: PropTypes.bool,
  }),

  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["primary", "gray"]),
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.string,
      ignoreIconMargin: PropTypes.bool,

      sections: PropTypes.shape({
        className: PropTypes.string,

        items: PropTypes.arrayOf({
          onClick: PropTypes.func,
          label: PropTypes.string,
          icon: PropTypes.string,

          divider: PropTypes.bool,
        }),
      }),
    })
  ),
};

export default ButtonRow;
