import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import ProcessesTable from "../../../tables/team/onboarding/processes-table";
import {withRouter} from "../../../utils/navigation";
import CreateProcessModal from "../../../modals/team/employee/onboarding/create-process-modal";
import {request} from "../../../utils/request";
import GrayBadge from "../../../components/badges/gray-badge";

class ProcessesPage extends Component {
  state = {data: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("checklists/onboarding", "GET").then((data) => {
      this.setState({data});
    });
  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <CreateProcessModal
          ref={(e) => (this.createProcessModal = e)}
          syncState={() => this.syncState()}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>Onboarding Processes</div>

              <GrayBadge darkGrey className={"ml-3"}>
                Beta
              </GrayBadge>
            </div>
          }
          description={"Create and manage onboarding processes"}
          buttons={[
            {
              label: "Create Process +",
              onClick: () => this.createProcessModal.open(),
            },
          ]}
        />

        <ProcessesTable data={data} syncState={() => this.syncState()} />
      </div>
    );
  }
}

export default withRouter(ProcessesPage);
