import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../../page/page-layout";
import SideNav from "../../../components/side-nav";
import {setupReduxConnection} from "../../../redux";
import {PERMISSIONS} from "../../../utils/constants";

class RecordsSideNav extends Component {
  render() {
    let recordsInfo = [
      {
        live: true,
        name: "Payroll Actions",
        href: "/admin/success/payroll",
      },
      {
        live: true,
        name: "Reader Apps",
        children: [
          {
            live: true,
            name: "Versions",
            href: "/admin/reader/versions",
          },
          {
            live: true,
            name: "Deploy Groups",
            href: "/admin/reader/groups",
          },
          {
            live: true,
            name: "Deploy Plans",
            href: "/admin/reader/plans",
          },
          {
            live: true,
            name: "Stripe Locations",
            href: "/admin/reader/locations",
          },
          {
            live: true,
            name: "Readers",
            href: "/admin/readers",
          },
        ],
      },
    ];

    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav title="Records" navigation={recordsInfo} />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(RecordsSideNav);
