import React, {Component} from "react";
import { XIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {getStore} from "../redux";
import {removeNotification} from "../redux/notification";

class Notification extends Component {
  removeNotification() {
    getStore().dispatch(removeNotification(this.props));
  }

  render() {
    const {label, description, icon, buttons, undo, dismiss} = this.props;

    return (
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            {icon && <div className="flex-shrink-0">{icon}</div>}

            <div className="w-0 pt-0.5 flex-1 flex items-start justify-between">
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">{label}</p>

                {description && (
                  <p className="mt-1 text-sm text-gray-500">{description}</p>
                )}

                {buttons && (
                  <div className="mt-3 flex space-x-7">
                    {undo && (
                      <button
                        type="button"
                        onClick={() => {
                          this.removeNotification();
                          undo();
                        }}
                        className="bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Undo
                      </button>
                    )}

                    <button
                      type="button"
                      className="bg-white rounded-md text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        getStore().dispatch(removeNotification(this.props));
                      }}
                    >
                      Dismiss{dismiss && ` (${dismiss})`}
                    </button>
                  </div>
                )}
              </div>

              {undo && (
                <button
                  type="button"
                  onClick={() => {
                    this.removeNotification();
                    undo();
                  }}
                  className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Undo
                </button>
              )}
            </div>

            <div className="ml-4 mt-0.5 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  getStore().dispatch(removeNotification(this.props));
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,

  icon: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
};

export default Notification;
