import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormTextArea,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";

class SendFundsModal extends Component {
  open() {
    this.slide.open();
  }

  render() {
    return (
      <Modal
        label="Send Funds"
        buttonLabel={"Send"}
        ref={(e) => (this.slide = e)}
      >
        <Formik initialValues={{method: "ach"}}>
          {(formikOptions) => {
            let {method} = formikOptions.values;

            return (
              <div>
                <FormSelect
                  name="method"
                  label="Payment Method"
                  data={[{label: "ACH", value: "ach"}]}
                  options={formikOptions}
                />

                <FormInput
                  label="Routing Number"
                  name={"routing_number"}
                  options={formikOptions}
                />

                <FormInput
                  label="Account Number"
                  name={"account_number"}
                  options={formikOptions}
                />
                <FormInput
                  label="Account Holder Name"
                  name={"account_holder_name"}
                  options={formikOptions}
                />

                <FormTextArea
                  label="Description"
                  name={"description"}
                  options={formikOptions}
                />
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SendFundsModal;
