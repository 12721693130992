import React, {Component} from "react";
import {Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {request} from "../../../utils/request";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/notification-helper";
import {OPEN_SHIFT_APPROVAL_TYPES} from "../../../utils/team-constants";
import {setupReduxConnection} from "../../../redux";
import {cloneDeep} from "lodash";

class OnboardingTable extends Component {
  timeouts = [];
  resentEmails = [];

  deleteInstance(row) {
    const {syncState} = this.props;

    showLoadingConfirmAlert(
      "Delete Onboarding",
      "Are you sure you want to delete this onboarding?"
    ).then(async (close) => {
      await request("checklists/onboarding/instance/" + row.ID, "DELETE");

      syncState();
      close();

      showSuccessNotification(
        "Onboarding Deleted",
        "We have deleted the onboarding"
      );
    });
  }

  async resendEmail(id) {
    if (this.resentEmails.includes(id)) {
      showErrorNotification(
        "Email Already Sent",
        "We have already resent the email. The employee can check their inbox for a link to their onboarding."
      );
      return;
    }

    try {
      await request("onboarding/resend/" + id, "POST");
      this.resentEmails.push(id);

      const intervalId = setTimeout(() => {
        const emailIndex = this.resentEmails.findIndex(
          (_email) => _email === id
        );

        if (emailIndex !== -1) {
          this.resentEmails.splice(emailIndex, 1);
        }
      }, 60000);

      this.timeouts.push(intervalId);
      showSuccessNotification(
        "Email Sent Successfully",
        "We have resent the email. The employee can check their inbox for a link to their onboarding."
      );
    } catch (e) {
      showErrorNotification(
        "Error Sending Email",
        "There was an error sending the onboarding email. Please try again or reach out to support."
      );
    }
  }

  componentWillUnmount() {
    for (const timeoutId of this.timeouts) {
      clearTimeout(timeoutId);
    }
  }

  render() {
    const {data, tab, noButtons} = this.props;
    const {location} = this.props.shop;

    const locations = data.reduce(
      (accum, item) =>
        accum.find((_location) => _location.id === item.LOCATION_ID)
          ? accum
          : [...accum, {id: item.LOCATION_ID, label: item.LOCATION_NAME}],
      []
    );

    const employees = data.reduce(
      (accum, item) =>
        accum.find((_employee) => _employee.id === item.EMPLOYEE_ID)
          ? accum
          : [...accum, {id: item.EMPLOYEE_ID, label: item.FULL_NAME}],
      []
    );

    const processes = data.reduce(
      (accum, item) =>
        accum.find((_process) => _process.id === item.CHECKLIST_ID)
          ? accum
          : [...accum, {id: item.CHECKLIST_ID, label: item.NAME}],
      []
    );

    const buttons = noButtons
      ? []
      : [
          {
            label: "View",
            onClick: (row) => {
              this.props.router.navigate(
                "/employee-onboarding/" + row.UNIQUE_ID
              );
            },
          },
          {
            label: "Delete",
            onClick: (row) => this.deleteInstance(row),
          },
        ];

    if (tab === "awaiting-action") {
      buttons.push({
        label: "Resend Email",
        onClick: (row) => this.resendEmail(row.ID),
      });
    }

    return (
      <Filter
        className="mt-0"
        searchable
        defaultFilters={[
          {
            filter: "status",
            label: "Pending",
            id: OPEN_SHIFT_APPROVAL_TYPES.pending.value,
          },
          {filter: "location", id: location.ID},
        ]}
        data={[
          {
            id: "location",
            label: "Location",
            onFilter: (filter, data) =>
              data.filter((item) => {
                return filter.includes(item.LOCATION_ID);
              }),
            options: locations,
          },
          {
            id: "employee",
            label: "Employee",
            onFilter: (filter, data) =>
              data.filter((item) => filter.includes(item.EMPLOYEE_ID)),
            options: employees,
          },
          {
            id: "processes",
            label: "Processes",
            onFilter: (filter, data) =>
              data.filter((item) => filter.includes(item.CHECKLIST_ID)),
            options: processes,
          },
        ]}
      >
        {(filters, search) => (
          <Table
            filters={filters}
            search={search}
            data={data}
            searchFields={["FULL_NAME", "NAME"]}
            actionButtons={buttons}
            columns={[
              {
                value: "FULL_NAME",
                label: "Full Name",
              },
              {
                value: "DATE_CREATED",
                label: "Hired Date",
                format: (value) => moment(value).format("M/D/YY"),
              },
              {
                value: "DATE_END",
                label: "Onboarded Date",
                format: (value) =>
                  value ? moment(value).format("M/D/YY") : null,
              },
              {
                value: "NAME",
                label: "process",
              },
            ]}
          />
        )}
      </Filter>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(OnboardingTable));
