import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TaxRateDropdown from "../../../dropdowns/sales/tax-rate-dropdown";

class ServiceFeeModal extends Component {
  state = {fee: null};

  open(fee = null) {
    this.setState({fee}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createFee({name, type, subtotal, tax, min, max, amount}) {
    const feePayload = {
      NAME: name,
      TYPE: type,
      AMOUNT: amount,
      TAX_RATE_ID: tax,
      IS_PRE_TAX: subtotal === "1",
      MIN_AMOUNT: decimalToDollars(min),
      MAX_AMOUNT: decimalToDollars(max),
    };

    if (type === "FLAT") {
      feePayload.AMOUNT = decimalToDollars(amount);
    }

    const serverPayload = await request("fees", "POST", feePayload);

    this.props.addState(serverPayload);
  }

  async saveFee({name, type, tax, subtotal, min, max, amount}) {
    const {fee} = this.state;

    const feePayload = {
      NAME: name,
      TYPE: type,
      AMOUNT: amount,
      TAX_RATE_ID: tax,
      IS_PRE_TAX: subtotal === "1",
      MIN_AMOUNT: decimalToDollars(min),
      MAX_AMOUNT: decimalToDollars(max),
    };

    if (type === "FLAT") {
      feePayload.AMOUNT = decimalToDollars(amount);
    }

    const serverFee = await request("fees/" + fee.ID, "PATCH", feePayload);

    this.props.updateState(serverFee.ID, serverFee);
    this.modal.close();
  }

  render() {
    const {fee} = this.state;

    const initialPayload = {
      name: fee?.NAME ?? "",
      amount: fee?.AMOUNT ?? "10",
      subtotal: "" + (fee?.IS_PRE_TAX ?? "1"),
      type: fee?.TYPE ?? "PERCENT",
      min: fee ? toDollars(fee.MIN_AMOUNT) : "0",
      max: fee ? toDollars(fee?.MAX_AMOUNT) : "0",
      tax: fee?.TAX_RATE_ID ?? null,
    };

    if (fee?.TYPE === "FLAT") {
      initialPayload.amount = toDollars(fee.AMOUNT);
    }

    return (
      <Modal
        buttonLabel={fee ? "Save" : "Create"}
        label={fee ? "Edit Service Fee" : "Create Service Fee"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={fee ? this.saveFee.bind(this) : this.createFee.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={initialPayload}
          validationSchema={Yup.lazy((_) => {
            const validationSchema = {
              name: Yup.string().required("Service fee name is required"),
            };

            return Yup.object(validationSchema);
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    name="name"
                    label="Name"
                    placeholder="Health Care Fee"
                    options={formikOptions}
                    flex
                  />

                  <FormBoolean
                    name="subtotal"
                    label="Calculate on Subtotal"
                    tooltip="Will this service fee be calculated on the subtotal or total"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <FormRow>
                  <FormSelect
                    name="type"
                    label="Type"
                    options={formikOptions}
                    data={[
                      {label: "Percentage", value: "PERCENT"},
                      {label: "Flat", value: "FLAT"},
                    ]}
                    flex
                  />

                  <FormInput
                    name="amount"
                    label="Amount"
                    placeholder="10"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <TaxRateDropdown options={formikOptions} name="tax" />

                <FormRow>
                  <FormInput
                    name="min"
                    label="Minimum Subtotal Amount"
                    options={formikOptions}
                    placeholder="0"
                    flex
                  />

                  <FormInput
                    name="max"
                    label="Maximum Subtotal Amount"
                    options={formikOptions}
                    placeholder="0"
                    flex
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ServiceFeeModal;
