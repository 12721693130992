import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import EmployeeDropdown from "../../dropdowns/team/employee-dropdown";
import {request} from "../../utils/request";

class RoleEmployeeSelectModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  saveEmployees = async ({employee}) => {
    if (employee) {
      await request("employeeroles/employee/" + employee, "POST", {
        ROLE_ID: this.props.role.ID,
      });
    }

    this.props.onUpdate && this.props.onUpdate();
    this.modal.close();
  };

  render() {
    const initialValues = {
      employee: null,
    };

    return (
      <Modal
        buttonLabel="Save"
        label={"Select Employees"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.saveEmployees}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <EmployeeDropdown name="employee" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default RoleEmployeeSelectModal;
