import {
  FormBoolean,
  Modal,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {showSuccessAlert} from "../../../utils/alert-helper";

class ThirdPartyModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({mark, product, modifier}) => {
    let payload = {
      THIRD_PARTY_MARK_UP: mark,
      THIRD_PARTY_PRODUCT_ROUND_UP: product,
      THIRD_PARTY_MODIFIER_ROUND_UP: modifier,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});
    await request("chowly/menu", "POST", {});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();

    setTimeout(() => {
      showSuccessAlert(
        "Settings & Menu Sync",
        "We have updated your settings and synced menu. The menu can sometimes take 1-5 minutes to sync on third party platforms."
      );
    }, 1000)
  };

  render() {
    const {
      THIRD_PARTY_MARK_UP,
      THIRD_PARTY_PRODUCT_ROUND_UP,
      THIRD_PARTY_MODIFIER_ROUND_UP,
    } = this.props.shop.settings;

    return (
      <Modal
        buttonLabel="Save"
        label="Third Party Delivery Settings"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            mark: THIRD_PARTY_MARK_UP,
            product: THIRD_PARTY_PRODUCT_ROUND_UP,
            modifier: THIRD_PARTY_MODIFIER_ROUND_UP,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  tooltip="Percentage we want to add to the products and modifiers"
                  label="Menu Percent Markup"
                  name="mark"
                  options={formikOptions}
                />

                <FormInput
                  tooltip="Cent amount we want to round to for products"
                  label="Product Round Up"
                  name="product"
                  options={formikOptions}
                />

                <FormInput
                  tooltip="Cent amount we want to round to for modifiers"
                  label="Modifier Round Up"
                  name="modifier"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  ThirdPartyModal
);
