import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, FormToggle} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";

class PermissionModal extends Component {
  state = {
    permissions: [],
    rolePermissions: [],
    permissionSection: null,
    adminEnabled: false,
  };

  open(permissions, rolePermissions, permissionSection) {
    this.setState(
      {
        permissions,
        rolePermissions,
        permissionSection,
        adminEnabled: false,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      }
    );
  }

  setAdminEnabled(enabled) {
    this.setState((prevState) => {
      if (prevState.adminEnabled !== enabled) {
        return {adminEnabled: enabled};
      }
    });
  }

  isDisabled(setting) {
    const {permissionSection, rolePermissions} = this.state;
    const {AREA} = permissionSection ?? {AREA: null};

    if (setting === "ADMIN_FULL") {
      return false;
    } else if (rolePermissions.includes("ADMIN_FULL")) {
      return true;
    } else if (setting === `${AREA}_FULL`) {
      return false;
    } else if (rolePermissions.includes(`${AREA}_FULL`)) {
      return true;
    }
    return false;
  }

  render() {
    const {permissions, rolePermissions} = this.state;
    const {onSubmit} = this.props;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Permissions"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(vals) => onSubmit(vals) || this.modal.close()}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={permissions.reduce((dict, item) => {
            dict[item.id] = rolePermissions.indexOf(item.id) === -1 ? "0" : "1";

            return dict;
          }, {})}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {permissions.map(({id, label, description}) => {
                  return (
                    <FormToggle
                      name={id}
                      label={label}
                      description={description}
                      options={formikOptions}
                      disabled={this.isDisabled(id)}
                      onOverride={this.isDisabled(id)}
                      flex
                    />
                  );
                })}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PermissionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PermissionModal;
