import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {getStore} from "../../../redux";
import {fetchTaxRate} from "../../../redux/shop";
import ComboBox from "../../../components/combobox";

class InvoiceLinesForm extends Component {
  state = {};

  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {recipients} = this.formikRef.values;

    return {
      recipients,
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, recipients, actionTextClassName} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          recipients: recipients || [],
        }}
      >
        {(formikOptions) => {
          const {values, handleSubmit, setFieldValue} = formikOptions;
          const linesValue = values.recipients;

          const tableColumns = [
            {
              value: "EMAIL",
              label: "Email Address",
              editable: true,
              isSimple: true,
              onChange: (index, val) => {
                const options = [...linesValue];

                options.splice(index, 1, {
                  ...options[index],

                  EMAIL: val,
                });

                setFieldValue("recipients", options);
              },
            },
          ];

          return (
            <form onSubmit={handleSubmit}>
              <Table
                white
                verticalLines
                className="mt-4"
                data={linesValue || null}
                columns={tableColumns}
                onErrorChange={(tableInvalid) => this.setState({tableInvalid})}
                ref={(e) => (this.modifierTable = e)}
                actionTextClassName={actionTextClassName}
                actionTexts={[
                  {
                    label: "Add Recipient",
                    onClick: () => {
                      const lines = [...linesValue];

                      lines.push({
                        EMAIL: "",
                      });

                      setFieldValue("recipients", lines);
                    },
                  },
                ]}
                actionButtons={[
                  {
                    icon: "trash",
                    onClick: (_, index) => {
                      const lines = [...linesValue];

                      lines.splice(index, 1);

                      setFieldValue("recipients", lines);
                    },
                  },
                ]}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

InvoiceLinesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  lines: PropTypes.object.isRequired,
};

export default InvoiceLinesForm;
