import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Rnd} from "react-rnd";

class SignatureField extends Component {
  render() {
    const {field, message, onClick} = this.props;

    return (
      <Rnd
        onMouseDown={() => onClick()}
        default={{
          x: field?.X_COORDINATE,
          y: field?.Y_COORDINATE,
          width: field?.WIDTH,
          height: field?.HEIGHT,
        }}
        className={"hover:cursor-pointer"}
        bounds="parent"
        disableDragging={true}
        enableResizing={false}
        key={field.UNIQUE_ID}
      >
        <div
          className={classNames(
            "flex flex-col text-sm justify-between text-center align-items-middle font-medium px-1 border-opacity-25 bg-opacity-25 w-full h-full border-2 text-black text-opacity-100",
            field.VALUE
              ? "bg-gray-500 border-gray-700 hover:cursor-default"
              : "hover:cursor-pointer bg-indigo-500 border-indigo-700 hover:bg-indigo-700 hover:bg-opacity-50 transition-colors ease-in-out duration-200"
          )}
        >
          <img
            alt={!field.VALUE && message}
            src={field.VALUE}
            style={{width: field?.WIDTH, height: field?.HEIGHT}}
          />
        </div>
      </Rnd>
    );
  }
}

export default SignatureField;
