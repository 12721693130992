import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PatronDropdown from "../../dropdowns/marketing/patron-dropdown";
import * as Yup from "yup";

class PatronSelectModal extends Component {
  open() {
    this.modal.open();
  }

  async addPatron({patronId}) {

    await request("text/marketing/subscribe", "POST", {
      PATRON_ID: patronId,
      LIST_ID: null,
    });

    this.props.updateState && this.props.updateState();
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel={"Add"}
        label={"Add Patron to Subscription List"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.addPatron.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            patronId: Yup.number().nullable(),
          })}
          initialValues={{
            patronId: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PatronDropdown formikOptions={formikOptions} usePatronId />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PatronSelectModal;
