import React, {Component} from "react";
import {MoonLoader} from "react-spinners";

class Loading extends Component {
  render() {
    return (
      <div className="h-screen flex">
        <div className="flex flex-grow justify-center items-center">
          <MoonLoader color={"blue"} loading={true} size={50} />
        </div>
      </div>
    );
  }
}

export default Loading;
