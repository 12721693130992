import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import EmployeeDropdown from "../../../../dropdowns/team/employee-dropdown";
import {Formik} from "formik";
import * as Yup from "yup";
import ProcessesDropdown from "../../../../dropdowns/team/onboarding/processes-dropdown";
import {request} from "../../../../utils/request";
import MultiEmployeeSelect from "../../../../components/multi-employee-select";

class CreateOnboardingModal extends Component {
  open() {
    this.modal.open();
  }

  async saveOnboarding(values) {
    const {employees, process} = values;
    const {syncState} = this.props;

    await Promise.all(
      employees.map((employee) =>
        request("checklists/onboarding/instance", "POST", {
          EMPLOYEE_ID: employee,
          CHECKLIST_ID: process,
        })
      )
    );

    await syncState();
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel={"Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={"Add Onboarding"}
      >
        <Formik
          onSubmit={(values) => this.saveOnboarding(values)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            employees: [],
            process: null,
          }}
          validationSchema={Yup.object().shape({
            employees: Yup.array()
              .nullable()
              .test(
                "arr-test",
                "Please select an employee.",
                (arr) => arr && arr.length > 0
              ),
            process: Yup.number()
              .nullable()
              .required("Please select a process."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <MultiEmployeeSelect
                  name="employees"
                  options={formikOptions}
                  placeholder="Click to select employees"
                  label={"Employees"}
                />

                <ProcessesDropdown name="process" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CreateOnboardingModal;
