import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {Outlet} from "react-router-dom";

class RankWrapper extends Component {
  state = {isLoading: true, isAdmin: false};

  componentDidMount() {
    request("dashboard/admin", "GET").then((data) => {
      this.setState({isAdmin: true, isLoading: false})
    }).catch((err) => {
      this.setState({isLoading: false});
    })
  }

  renderBye() {
    setTimeout(() => {
      this.props.router.navigate("/403");
    }, 1);

    return <div>No Permission Dawg</div>;
  }

  render() {
    const {isLoading, isAdmin} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    if (!isAdmin) {
      return this.renderBye();
    }

    return (
      <Outlet />
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(RankWrapper));
