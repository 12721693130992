import React, {Component} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";

class PayrollInfoAccordion extends Component {
  state = {open: this.props.open};

  toggle(){
    this.setState(prevState => ({
      open: !prevState.open
    }));  }

  render() {
    let {open} = this.state;
    let {label} = this.props;

    return (
      <div className="">
        <div className="flex flex-row items-center cursor-pointer text-indigo-500 font-bold"
          onClick={() => this.toggle()}
        >
          {open && <ChevronDownIcon className="h-5 w-5" />}
          {!open && <ChevronUpIcon className="h-5 w-5" />}
          {label}
        </div>

        {open && <div>{this.props.children}</div>}
      </div>
    );
  }
}

export default PayrollInfoAccordion;
