import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import DepartmentModal from "../../../modals/team/departments/department-modal";
import EmployeeTable from "../../../tables/team/employee-table";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

class DepartmentPage extends Component {
  state = {department: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const department = await request("departments/" + id, "GET");

    this.setState({department});
  }

  async deleteDepartment() {
    const {department} = this.state;

    showLoadingConfirmAlert(
      "Delete Department",
      "Are you sure you want to delete this department?"
    ).then(async (close) => {
      await request("departments/" + department.ID, "DELETE");

      this.props.router.navigate("/departments");

      close();
    });
  }

  render() {
    const {department} = this.state;

    if (department === null) {
      return <Loading />;
    }

    const pageButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [
              {
                label: "Delete Department",
                onClick: () => this.deleteDepartment(),
              },
            ],
          },
        ],
      },
    ];

    return (
      <div>
        <DepartmentModal
          updateState={(memo) => this.setState({memo})}
          ref={(e) => (this.departmentModal = e)}
        />

        <PageHeadings
          label={department.NAME}
          buttons={pageButtons}
          breadcrumbs={[
            {label: "Team", url: "/team"},
            {label: "Department List", url: "/departments"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.departmentModal.open(department),
            },
          ]}
          label="Department Information"
          description="Information about the contents of the department"
          data={[
            {
              label: "Department Name",
              value: department.NAME,
            },
            {
              label: "Manager Name",
              link:
                department.MANAGER_EMPLOYEE_ID &&
                "/employee/" + department.MANAGER_EMPLOYEE_UNIQUE_ID,
              value: department.MANAGER_EMPLOYEE_NAME,
            },
          ]}
        />

        <Card
          label="Department Employees"
          description="Information about the employees in this department"
        >
          <EmployeeTable data={department.EMPLOYEES} pagination striped />
        </Card>
      </div>
    );
  }
}

export default withTranslation()(withRouter(DepartmentPage));
