import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class EmptyBox extends Component {
  render() {
    const {label, icon = "empty-set"} = this.props;

    return (
      <button
        type="button"
        className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FontAwesomeIcon
          icon={icon}
          size="2x"
          className="mx-auto h-12 w-12 text-gray-400"
        />

        <span className="mt-2 block text-sm font-medium text-gray-900">
          {label}
        </span>
      </button>
    );
  }
}

EmptyBox.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default EmptyBox;
