import React, {Component} from "react";
import FormRow from "../../../../../components/form-row";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";

class EmployeeInformationForm extends Component {
  render() {
    const {formikOptions} = this.props;

    return (
      <form>
        <FormRow>
          <FormInput
            flex
            label={"Last Name"}
            name={"employeeLastName"}
            options={formikOptions}
          />

          <FormInput
            flex
            label={"Middle Initial"}
            name={"employeeMiddleInitial"}
            options={formikOptions}
          />

          <FormInput
            flex
            label={"First Name"}
            name={"employeeFirstName"}
            options={formikOptions}
          />
        </FormRow>

        <FormInput
          flex
          label={"Citizenship/Immigration Status"}
          name={"employeeImmigrationStatus"}
          options={formikOptions}
        />
      </form>
    );
  }
}

export default EmployeeInformationForm;
