import React, {Component, Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Tooltip from "../tooltip/tooltip";
import ButtonRow from "../buttons/button-row";
import Button from "../buttons/button";

class Modal extends Component {
  lagClick = Date.now();
  state = {open: false};

  stopLoading() {
    this.buttonRef.stopLoading();
  }

  fetchModalButton() {
    return this.buttonRef;
  }

  preventClick = (e) => {
    let currentNode = e.target;

    while (currentNode.parentNode) {
      if (currentNode.classList.contains("ignore-click")) {
        this.lagClick = Date.now();
        break;
      }

      currentNode = currentNode.parentNode;
    }
  };

  open() {
    this.setState({open: true}, () => {
      document.addEventListener("mousedown", this.preventClick);
    });
  }

  close() {
    this.setState({open: false}, () => {
      document.removeEventListener("mousedown", this.preventClick);
    });
  }

  renderDelete(deleteLabel, deleteOnClick) {
    return (
      deleteLabel && (
        <Button
          type={"delete"}
          ref={(e) => (this.deleteRef = e)}
          label={deleteLabel}
          onClick={() => {
            this.deleteRef.startLoading();
            deleteOnClick();
          }}
        />
      )
    );
  }

  renderCancel(hideClose, closeLabel) {
    return (
      !hideClose && (
        <button
          type="button"
          onClick={() => this.close()}
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {closeLabel}
        </button>
      )
    );
  }

  render() {
    const {
      label,
      large,
      xlarge,
      tooltip,
      hideClose,
      onClose,
      buttons,
      className,
      deleteType,
      buttonLabel,
      deleteLabel,
      description,
      formikOnClick,
      buttonOnClick,
      disableButtonLoading,
      deleteOnClick,
      stayOpen = false,
      closeLabel = "Cancel",
      buttonDisabled = false,
      deleteOnRight = false,
    } = this.props;

    const {open} = this.state;

    return (
      <Transition show={open || stayOpen} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={false}
          onClose={() => {
            if (this.lagClick + 100 > Date.now()) return;

            this.setState({open: false}, () => onClose && onClose());
          }}
          className={classNames(
            "fixed z-20 inset-0 overflow-y-auto frost-modal",
            className
          )}
        >
          <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-neutral-bg bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  "relative w-full inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle",
                  xlarge
                    ? "sm:max-w-6xl"
                    : large
                    ? "sm:max-w-3xl"
                    : "sm:max-w-md"
                )}
              >
                <button className="flex h-0 w-0" />

                {(label || description) && (
                  <div className="bg-white px-6 py-3 border-b-2 border-gray-100">
                    {label && (
                      <div className="flex flex-row items-center">
                        <p className="font-medium">{label}</p>

                        {tooltip && (
                          <Tooltip
                            className="ml-2"
                            place="right"
                            {...tooltip}
                          />
                        )}
                      </div>
                    )}

                    {description && (
                      <p className="text-sm text-gray-500">{description}</p>
                    )}
                  </div>
                )}

                <div className="modal-scroll-box bg-gray-50 px-4 py-4 sm:p-6 sm:py-4 sm:max-h-70vh overflow-y-auto">
                  {this.props.children}
                </div>

                <div className="bg-white px-4 py-3 flex justify-between border-t-2 border-gray-100">
                  <div>
                    {deleteOnRight
                      ? this.renderCancel(hideClose, closeLabel)
                      : this.renderDelete(deleteLabel, deleteOnClick)}
                  </div>

                  <div className="flex justify-end space-x-2">
                    {buttons && <ButtonRow buttons={buttons} />}

                    {deleteOnRight
                      ? this.renderDelete(deleteLabel, deleteOnClick)
                      : this.renderCancel(hideClose, closeLabel)}

                    {buttonLabel && (
                      <Button
                        disabled={buttonDisabled}
                        ref={(e) => (this.buttonRef = e)}
                        label={buttonLabel}
                        onClick={async () => {
                          if (!disableButtonLoading) {
                            this.buttonRef.startLoading();
                          }

                          if (buttonOnClick) {
                            buttonOnClick();
                          }

                          if (formikOnClick) {
                            const formikForm = formikOnClick();

                            const val = await formikForm.validateForm();
                            if (Object.keys(val).length > 0) {
                              this.buttonRef.stopLoading();

                              for (let key of Object.keys(val)) {
                                formikForm.setFieldTouched(key, true);
                              }

                              this.buttonRef && this.buttonRef.stopLoading();

                              return this.buttonRef.setError(
                                val[Object.keys(val)[0]]
                              );
                            }

                            formikForm.submitForm();
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,

  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  disableButtonLoading: PropTypes.bool,

  deleteLabel: PropTypes.string,
  deleteOnClick: PropTypes.func,

  closeLabel: PropTypes.string,

  label: PropTypes.string,
  description: PropTypes.string,

  tooltip: Tooltip.propTypes,

  large: PropTypes.bool,

  buttons: ButtonRow.propTypes,
};

export default Modal;
