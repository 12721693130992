import React, {Component} from "react";
import {FileUploader} from "react-drag-drop-files";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {uploadImage} from "../utils/request";
import {getAssetUrl} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {asyncTimeout} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import csv from "csvtojson";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingSpinner from "./loading-spinner";
import {showErrorNotification} from "../utils/notification-helper";

export const fileTypes = ["JPEG", "PNG", "JPG", "GIF"];
const csvType = ["CSV"];
const pdfType = ["PDF", "JPEG", "PNG", "JPG", "GIF"];

class FormDropZone extends Component {
  state = {isLoading: false};

  async uploadFile(file, onChangeSoft) {
    const {type, endpoint, options, name} = this.props;

    if (type === "CSV") {
      let text = await file.text();

      return csv({flatKeys: true})
        .fromString(text)
        .then((csvRow) => {
          options.setFieldValue(name, csvRow);

          onChangeSoft && onChangeSoft(csvRow);
        });
    }

    this.setState({isLoading: true});

    try {
      const url = await uploadImage(endpoint, file);

      await asyncTimeout(2000);

      if (options) {
        options.setFieldValue(name, url);
      }

      this.setState({isLoading: false});

      file.fileDetails = url;

      onChangeSoft && onChangeSoft(file);
    } catch (e) {
      showErrorNotification(
        "Error uploading image",
        "The image failed to upload. Please try again."
      );
    }
  }
  renderIcon(value) {
    const {type, multiple} = this.props;

    if (value && value.length > 0) {
      if (type === "CSV") {
        return (
          <FontAwesomeIcon
            icon="file"
            className="mx-auto h-11 w-11 text-indigo-400"
          />
        );
      }

      if (multiple) {
        if (typeof value === "string") {
          return <img className="mx-auto h-12 w-12" src={getAssetUrl(value)} />;
        }

        if (value.length === 1) {
          return (
            <img className="mx-auto h-12 w-12" src={getAssetUrl(value[0])} />
          );
        }

        return (
          <FontAwesomeIcon
            icon="check"
            className="mx-auto h-11 w-11 text-green-600"
          />
        );
      }

      return <img className="mx-auto h-12 w-12" src={getAssetUrl(value)} />;
    }

    return (
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  render() {
    const {type, multiple = false} = this.props;
    let {isLoading} = this.state;

    let types = fileTypes;
    let uploadText = "PNG, JPG, JPEG, GIF up to 10MB";

    if (type === "CSV") {
      uploadText = "CSV up to 10MB";
      types = csvType;
    } else if (type === "PDF") {
      uploadText = "PDF, PNG, JPG, JPEG, GIF up to 10 MB";
      types = pdfType;
    }

    return (
      <FormElement {...this.props}>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <FileUploader
              handleChange={async (file) => {
                if (multiple) {
                  for (const oneFile of file) {
                    await this.uploadFile(oneFile, onChangeSoft);
                  }
                } else {
                  return this.uploadFile(file, onChangeSoft);
                }
              }}
              multiple={multiple}
              name="file"
              types={types}
            >
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-indigo-300">
                  <div className="h-24">
                    <div className="space-y-1 text-center">
                      {!isLoading && (
                        <div>
                          {this.renderIcon(value)}

                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">{uploadText}</p>
                        </div>
                      )}

                      {isLoading && (
                        <div className="space-y-1 text-center mt-10">
                          <LoadingSpinner />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FileUploader>
          );
        }}
      </FormElement>
    );
  }
}

FormDropZone.propTypes = {
  ...FormElement.propTypes,

  type: PropTypes.string,
};

export default FormDropZone;
