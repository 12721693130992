import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class EmployeeDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = [], allowNull} = this.props;

    const serverEmployees = await request("employees/v2", "GET");
    const data = Object.values(
      serverEmployees.reduce((dict, item) => {
        if (!dict[item.LOCATION_ID]) {
          dict[item.LOCATION_ID] = [item];
        } else {
          dict[item.LOCATION_ID].push(item);
        }

        return dict;
      }, {})
    ).map((item) => {
      return {
        title: item[0].LOCATION_NAME,
        data: item
          .filter((item) => !exclude.includes(item.ID))
          .map((item) => {
            return {id: item.ID, label: item.FULL_NAME, unique: item.UNIQUE_ID};
          }),
      };
    });

    if (allowNull) {
      data.unshift({
        title: "Global",
        data: [{label: "None", id: null, unique: null}],
      });
    }

    this.setState({data});
  }

  render() {
    const {data} = this.state;
    const {label} = this.props;

    return (
      <ComboBox
        label={label ?? "Employee"}
        sections={data}
        {...this.props}
        headerIcon={"fa-solid fa-store"}
      />
    );
  }
}

EmployeeDropdown.propTypes = {
  ...ComboBox.propTypes,

  exclude: PropTypes.array,
};

export default EmployeeDropdown;
