import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

class ReaderLocationsPage extends Component {
  state = {locations: null};

  componentDidMount() {
    request("readers/deployments/locations", "GET").then((locations) => {
      this.setState({locations});
    });
  }

  render() {
    const {locations} = this.state;

    if (locations === null) {
      return <Loading />;
    }

    return (
      <>
        <PageHeadings
          label="Locations"
          description="Create and manage companies"
        />

        <Filter searchable defaultFilters={[]} data={[]}>
          {(filters, search) => {
            return (
              <Table
                pagination
                search={search}
                filters={filters}
                searchFields={["NAME"]}
                data={locations}
                className="mt-4"
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => {
                      this.props.router.navigate("/admin/company/" + row.ID);
                    },
                  },
                ]}
                columns={[
                  {
                    value: "ID",
                    label: "ID",
                  },
                  {
                    value: "NAME",
                    label: "Name",
                  },
                  {
                    value: "STRIPE",
                    label: "Terminal ID",
                    format: (STRIPE) => STRIPE?.id,
                  },
                  {
                    value: "STRIPE",
                    label: "Deployement Groups",
                    format: (STRIPE) =>
                      Object.keys(STRIPE?.device_deploy_groups ?? {}),
                  },
                ]}
              />
            );
          }}
        </Filter>
      </>
    );
  }
}

export default withRouter(ReaderLocationsPage);
