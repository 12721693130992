import {RESET_STORE} from "../utils/constants";
import {deepClone, spliceArray, upsertArray} from "../utils/util";
import {request} from "../utils/request";

const defaultState = {
  conversations: [],
  pendingMessages: [],
  pendingUpserts: [],
};

const UPDATE_CHAT = "UPDATE_CHAT";
const UPDATE_CONVERSATIONS = "UPDATE_CONVERSATIONS";
const UPDATE_PENDING_MESSAGES = "UPDATE_PENDING_MESSAGES";
const UPDATE_PENDING_UPSERTS = "UPDATE_PENDING_UPSERTS";

export function updateChat(payload) {
  return {type: UPDATE_CHAT, payload};
}

export function updateConversations(payload) {
  return {type: UPDATE_CONVERSATIONS, payload};
}

export function updatePendingMessages(payload) {
  return {type: UPDATE_PENDING_MESSAGES, payload};
}

export function updatePendingUpserts(payload) {
  return {type: UPDATE_PENDING_UPSERTS, payload};
}

export function upsertConversation(conversation) {
  return (dispatch, getState) => {
    const {conversations} = getState().chat;

    dispatch(updateConversations(upsertArray(conversations, conversation)));
  };
}

export function removeConversation(conversation) {
  return (dispatch, getState) => {
    const {conversations} = getState().chat;

    dispatch(updateConversations(spliceArray(conversations, conversation)));
  };
}

export function loadConversations() {
  return async function (dispatch) {
    let data = await request("dashboard/conversations", "GET");

    for (let conversation of data) {
      conversation.PENDING_MESSAGES = [];

      conversation.STATUS = {
        lastIndex: 10000000,
        isLoading: false,
        isDone: false,
      };
    }

    dispatch(updateConversations(data));

    return Promise.resolve();
  };
}

export function markConversationRead(id) {
  return async () => {
    await request("dashboard/conversation/" + id + "/read", "POST", {});
  };
}

export function addPendingMessage(message) {
  return (dispatch, getState) => {
    const {pendingMessages} = getState().chat;

    dispatch(updatePendingMessages([...pendingMessages, message]));
  };
}

export function addPendingUpserts(conversation) {
  return (dispatch, getState) => {
    const {pendingUpserts} = getState().chat;

    dispatch(updatePendingUpserts([...pendingUpserts, conversation]));
  };
}

export const chatReducer = (state = deepClone(defaultState), action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case RESET_STORE:
      return {...deepClone(defaultState)};
    case UPDATE_CHAT:
      return {...state, ...payload};
    case UPDATE_CONVERSATIONS:
      return {...state, conversations: payload};
    case UPDATE_PENDING_MESSAGES:
      return {...state, pendingMessages: payload};
    case UPDATE_PENDING_UPSERTS:
      return {...state, pendingUpserts: payload};
  }
};
