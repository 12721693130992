import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import moment from "moment";
import {request} from "../../../utils/request";
import {showConfirmAlert} from "../../../utils/alert-helper";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import FormRow from "../../../components/form-row";
import * as Yup from "yup";
import {
  fetchRecurrenceString,
  getDateForISODay,
} from "../../../utils/team-helper";

class ShiftPreferencesModal extends Component {
  state = {type: 0, employeeId: null, preference: null};

  open(type, employeeId, preference = null) {
    this.setState({type, employeeId, preference}, () => this.modal.open());
  }

  handleSubmit = async (values) => {
    const {preference, type, employeeId} = this.state;
    let {
      start,
      end,
      allDay,
      notes,
      recurrenceType,
      recurrenceInterval,
      dateExpires,
    } = values;

    const durationMinutes = moment(end)
      .diff(moment(start), "minutes")
      .valueOf();

    const iso =
      moment(start).isoWeekday() === 0 ? 6 : moment(start).isoWeekday() - 1;

    const offset = moment(start).diff(moment(start).startOf("day"), "minutes");

    let payload = {
      DATE_START: start,
      TYPE: type,
      ISO_DAY: iso,
      OFFSET: offset,
      ALL_DAY: !!allDay,
      DURATION: durationMinutes,
      NOTES: notes,
      DATE_EXPIRES: dateExpires,
      RECURRENCE_TYPE: recurrenceType,
      RECURRENCE_INTERVAL: recurrenceInterval,
    };

    if (preference) {
      await request("scheduling/preference/" + preference.ID, "PATCH", payload);
    } else {
      await request("scheduling/preference/" + employeeId, "POST", payload);
    }
    await this.props.onChange();

    this.modal.close();
  };

  delete = () => {
    let {ID, TYPE} = this.state.preference;

    let typeString = TYPE === 1 ? "unavailability" : "preference";

    showConfirmAlert(
      "Delete ",
      `Are you sure you want to delete this shift ${typeString}?`,
      "Confirm"
    )
      .then(async () => {
        await request("scheduling/preference/" + ID, "DELETE");
        await this.props.onChange();

        this.modal.close();
      })
      .catch(() => this.modal.close());
  };

  render() {
    const {preference, type} = this.state;
    const {
      DATE_START: start = Date.now(),
      ISO_DAY: isoDay = null,
      ALL_DAY: initialAllDay = null,
      OFFSET: initialOffset = null,
      DURATION: initialDuration = null,
      NOTES: initialNotes = null,
      RECURRENCE_TYPE: recurrenceType = 1,
      RECURRENCE_INTERVAL: recurrenceInterval = 1,
      DATE_EXPIRES: dateExpires = null,
    } = preference || {};

    let typeString = type === 1 ? "Unavailability" : "Preference";

    let dateStart = start;
    let dateEnd = null;

    if (preference) {
      //for legacy preferences with no date start, we calculate date start using offset and iso
      if (start === 0) {
        const momentIsoDay = parseInt(isoDay) === 6 ? 0 : parseInt(isoDay) + 1;
        const dateForIsoDay = getDateForISODay(momentIsoDay);

        dateStart = moment(dateForIsoDay)
          .startOf("day")
          .add(initialOffset / 60, "hours")
          .add(initialOffset % 60, "minutes")
          .valueOf();
      }

      dateEnd = moment(dateStart).add(initialDuration, "minutes").valueOf();
    }

    const validationSchema = Yup.object({
      start: Yup.number().required("Start time is required"),
      end: Yup.number()
        .nullable()
        .test(
          "Time range invalid",
          "Please select a valid end time",
          (val, ctx) => ctx.parent.allDay || (val && val >= ctx.parent.start),
        ),
    });

    return (
      <Modal
        buttonLabel={preference ? "Save" : "Add"}
        deleteLabel={preference ? "Delete" : undefined}
        label={`${preference ? "Edit" : "Add"} ${typeString}`}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={preference ? this.delete : undefined}
        large
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            start: dateStart,
            end: dateEnd,
            allDay: initialAllDay,
            notes: initialNotes ?? "",
            dateExpires,
            recurrenceType,
            recurrenceInterval,
          }}
          validationSchema={validationSchema}
        >
          {(formikOptions) => {
            const {values, handleSubmit, setFieldValue} = formikOptions;
            let {allDay} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormDateTimeSelect
                    label="Start Time"
                    name={"start"}
                    options={formikOptions}
                    buttonText={(epoch) => {
                      return allDay
                        ? moment(epoch).format("ddd, MMM D")
                        : moment(epoch).format("ddd, MMM D h:mm A");
                    }}
                    flex
                  />

                  {!allDay && (
                    <FormDateTimeSelect
                      label="End Time"
                      name={"end"}
                      options={formikOptions}
                      buttonText={(epoch) => {
                        if (epoch === null) {
                          return "Select A Time";
                        }
                        return moment(epoch).format("ddd, MMM D h:mm A");
                      }}
                      flex
                    />
                  )}
                </FormRow>

                <FormSelect
                  label="All Day"
                  name="allDay"
                  data={[
                    {label: "Yes", value: 1},
                    {label: "No", value: 0},
                  ]}
                  options={formikOptions}
                />

                <div className="flex flex-col xxxs:space-y-2 sm:flex-row sm:justify-between sm:space-x-4 items-end">
                  <FormDateTimeSelect
                    label="Ends On"
                    name={"dateExpires"}
                    options={formikOptions}
                    buttonText={(epoch) => {
                      if (epoch === null) {
                        return "Does Not Expire";
                      }
                      return moment(epoch).format("dddd, M/D");
                    }}
                    hideTime={true}
                    flex
                    hint={"optional"}
                  />

                  <div
                    onClick={() => setFieldValue("dateExpires", null)}
                    className="flex text-indigo-600 text-sm font-semibold cursor-pointer mb-2"
                  >
                    {"Clear"}
                  </div>
                </div>

                <FormRow>
                  <FormSelect
                    label="Repeats Every"
                    name="recurrenceType"
                    data={[
                      {label: "Does Not Repeat", value: 0},
                      {label: "Weekly", value: 1},
                      {
                        label: "Monthly",
                        value: 2,
                      },
                    ]}
                    options={formikOptions}
                    flex
                  />

                  {values.recurrenceType === 1 ||
                  values.recurrenceType === 2 ? (
                    <FormSelect
                      label="Interval"
                      name="recurrenceInterval"
                      data={[
                        {
                          label: fetchRecurrenceString(
                            values.recurrenceType,
                            1
                          ),
                          value: 1,
                        },
                        {
                          label: fetchRecurrenceString(
                            values.recurrenceType,
                            2
                          ),
                          value: 2,
                        },
                        {
                          label: fetchRecurrenceString(
                            values.recurrenceType,
                            3
                          ),
                          value: 3,
                        },
                        {
                          label: fetchRecurrenceString(
                            values.recurrenceType,
                            4
                          ),
                          value: 4,
                        },
                        {
                          label: fetchRecurrenceString(
                            values.recurrenceType,
                            5
                          ),
                          value: 5,
                        },
                      ]}
                      options={formikOptions}
                      flex
                    />
                  ) : (
                    <div className="flex-1"></div>
                  )}
                </FormRow>

                <FormTextArea
                  label={"Notes"}
                  name={"notes"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ShiftPreferencesModal;
