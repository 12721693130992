import React, {Component, Fragment} from "react";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, FilterIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import FormDate from "../input/form-date";
import FormInput from "../input/form-input";
import FormSelect from "../select/form-select";

const sortOptions = [
  {name: "Most Popular", href: "#", current: true},
  {name: "Best Rating", href: "#", current: false},
  {name: "Newest", href: "#", current: false},
];

class AdvancedFilter extends Component {
  state = {sort: null, filters: []};

  componentWillMount() {
    const {defaultFilters, data = []} = this.props;

    const filters = [];
    const url = new URL(window.location.href);
    const filterDict = data.reduce((dict, item) => {
      if (item.type === "array") {
        for (let value of item.data) {
          dict[value.id] = value;
        }
      } else {
        dict[item.id] = item;
      }

      return dict;
    }, {});

    for (let key of url.searchParams.keys()) {
      if (filterDict[key]) {
        const values = decodeURIComponent(url.searchParams.get(key)).split(",");

        for (let val of values) {
          filters.push({
            filter: key,
            value: isNaN(val) ? val : parseInt(val),
            onFilter: filterDict[key]?.onFilter,
          });
        }
      }
    }

    this.setState(
      {filters: filters.length > 0 ? filters : defaultFilters},
      () => this.resetSearchParams()
    );
  }

  resetSearchParams() {
    const {filters = []} = this.state;
    const {data = []} = this.props;

    const url = new URL(window.location.href);

    const filterDict = data.reduce((dict, item) => {
      if (item.type === "array") {
        for (let value of item.data) {
          dict[value.id] = value;
        }
      } else {
        dict[item.id] = item;
      }

      return dict;
    }, {});

    for (let item of Object.keys(filterDict)) {
      url.searchParams.delete(item);
    }

    if (filters.length > 0) {
      const selectedFilterDict = filters.reduce((dict, item) => {
        if (!dict[item.filter]) {
          dict[item.filter] = [item.value];
        } else {
          dict[item.filter].push(item.value);
        }

        return dict;
      }, {});

      for (let filter of Object.keys(selectedFilterDict)) {
        url.searchParams.set(
          filter,
          encodeURIComponent(selectedFilterDict[filter])
        );
      }
    }

    window.history.pushState({}, null, url);
  }

  renderFilters(filter) {
    const {filters = []} = this.state;

    if (filter.type === "search") {
      const textIndex = filters.findIndex((item) => item.filter === filter.id);
      const textValue = textIndex !== -1 ? filters[textIndex] : null;

      return (
        <FormInput
          labelClassName="text-black"
          className="mt-0 max-w-xs"
          label={filter.label}
          value={textValue?.value ?? null}
          placeholder={filter.placeholder}
          onChange={(e) => {
            let text = e.target.value;

            if (textIndex !== -1) {
              filters.splice(textIndex, 1, {
                value: text,
                filter: filter.id,
              });
            } else {
              filters.push({
                value: text,
                filter: filter.id,
              });
            }

            this.setState({filters}, () => this.resetSearchParams());
          }}
        />
      );
    }

    if (filter.type === "select") {
      const selectIndex = filters.findIndex((item) => {
        return item.filter === filter.id;
      });

      const selectValue = selectIndex !== -1 ? filters[selectIndex] : null;

      return (
        <fieldset>
          <FormSelect
            label={filter.label}
            value={selectValue?.value ?? null}
            data={filter.data}
            onChange={(selection) => {
              if (!selection || selection?.length === 0) {
                filters.splice(selectIndex, 1);
              } else if (selectIndex !== -1) {
                filters.splice(selectIndex, 1, {
                  value: selection,
                  filter: filter.id,
                });
              } else {
                filters.push({
                  value: selection,
                  filter: filter.id,
                });
              }

              this.setState({filters}, () => this.resetSearchParams());
            }}
          />
        </fieldset>
      );
    }

    if (filter.type === "selectMultiple") {
      const selected = filters.filter((_filter) => _filter.filter === filter.id).map((_filter) => _filter.value);

      return (
        <fieldset>
          <FormSelect
            label={filter.label}
            value={selected ?? []}
            data={filter.data}
            multi={true}
            onChange={(selection) => {
              let {filters = []} = this.state;

              if (!selection || selection?.length === 0) {
                filters = filters.filter((_filter) => _filter.filter !== filter.id);
              } else {
                selection = selection[selection.length - 1];

                const findIndex = filters.findIndex((_filter) => _filter.filter === filter.id && _filter.value === selection);

                if (findIndex !== -1) {
                  filters.splice(findIndex, 1);
                } else {
                  filters.push({filter: filter.id, value: selection, label: filter.label});
                }
              }

              this.setState({filters}, () => this.resetSearchParams());
            }}
          />
        </fieldset>
      );
    }

    if (filter.type === "array") {
      return (
        <fieldset>
          {filter.data.map((item, i) => {
            return (
              <div className={classNames(i > 0 && "mt-4")}>
                {this.renderFilters(item)}
              </div>
            );
          })}
        </fieldset>
      );
    }

    if (filter.type === "date" || filter.type === "datetime") {
      const dateIndex = filters.findIndex((item) => item.filter === filter.id);
      const dateValue = dateIndex !== -1 ? filters[dateIndex] : null;

      return (
        <FormDate
          className="mt-0 max-w-xs"
          label={filter.label}
          value={dateValue?.value ?? null}
          labelClassName="text-black"
          showTime={filter.type === "datetime"}
          onChange={(date) => {
            if (dateIndex !== -1) {
              filters.splice(dateIndex, 1, {
                value: date.getTime(),
                filter: filter.id,
              });
            } else {
              filters.push({
                value: date.getTime(),
                filter: filter.id,
              });
            }

            this.setState({filters}, () => this.resetSearchParams());
          }}
        />
      );
    }

    return (
      <fieldset>
        <legend className="block font-medium">{filter.label}</legend>

        <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
          {filter.data.map((option, optionIdx) => {
            const filterIndex = filters.findIndex((item) => {
              return item.filter === filter.id && item.value === option.value;
            });

            const isSelected = filterIndex !== -1;

            return (
              <div
                key={option.value}
                className="flex items-center text-base sm:text-sm"
              >
                <input
                  id={`${filter.id}-${optionIdx}`}
                  name={filter.id + "-[]"}
                  type="checkbox"
                  className="flex-shrink-0 h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      filters.splice(filterIndex, 1);
                    } else {
                      filters.push({
                        filter: filter.id,
                        ...option,
                      });
                    }

                    this.setState({filters}, () => this.resetSearchParams());
                  }}
                />

                <label
                  htmlFor={`${filter.id}-${optionIdx}`}
                  className="ml-3 min-w-0 flex-1 text-gray-600  cursor-pointer"
                >
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </fieldset>
    );
  }

  render() {
    const {sort: sortOptionsz, data = [], defaultOpen, className} = this.props;
    const {filters = [], sort} = this.state;

    const filterDict = data.reduce((dict, item) => {
      if (item.type === "array") {
        for (let value of item.data) {
          dict[value.id] = value;
        }
      } else {
        dict[item.id] = item;
      }

      return dict;
    }, {});

    const selectedFilterDict = filters.reduce((dict, item) => {
      if (!dict[item.filter]) {
        dict[item.filter] = [item.value];
      } else {
        dict[item.filter].push(item.value);
      }

      return dict;
    }, {});

    const filterArr = Object.keys(selectedFilterDict).map((filter) => {
      if (filterDict[filter]?.type === "date") {
        return {
          filter: filter,
          onFilter: filterDict[filter]?.onFilter,
          data: selectedFilterDict[filter][0],
        };
      }

      return {
        filter: filter,
        onFilter: filterDict[filter]?.onFilter,
        data: selectedFilterDict[filter],
      };
    });

    return (
      <div>
        <Disclosure
          as="section"
          defaultOpen={defaultOpen}
          aria-labelledby="filter-heading"
          className={classNames(
            className,
            "relative z-10 border-t border-b border-gray-200 grid items-center"
          )}
        >
          <h2 id="filter-heading" className="sr-only">
            Filters
          </h2>

          <div className="relative col-start-1 row-start-1 py-4">
            <div className="mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8">
              <div>
                <Disclosure.Button className="group text-gray-700 font-medium flex items-center">
                  <FilterIcon
                    className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {filters.length} Filters
                </Disclosure.Button>
              </div>

              <div className="pl-6">
                <button
                  onClick={() =>
                    this.setState({filters: []}, () => this.resetSearchParams())
                  }
                  className="text-gray-500"
                  type="button"
                >
                  Clear all
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="border-t border-gray-200 py-10">
            <div className=" mx-auto gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
              <div
                className="grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-4 lg:grid-cols-4 lg:gap-x-6">
                {data.map((item) => {
                  return this.renderFilters(item);
                })}
              </div>
            </div>
          </Disclosure.Panel>

          <div className="col-start-1 row-start-1 py-4">
            <div className="flex justify-end mx-auto px-4 sm:px-6 lg:px-8">
              {/*<Menu as="div" className="relative inline-block">*/}
              {/*  <div className="flex">*/}
              {/*    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">*/}
              {/*      Sort*/}
              {/*      <ChevronDownIcon*/}
              {/*        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"*/}
              {/*        aria-hidden="true"*/}
              {/*      />*/}
              {/*    </Menu.Button>*/}
              {/*  </div>*/}

              {/*  <Transition*/}
              {/*    as={Fragment}*/}
              {/*    enter="transition ease-out duration-100"*/}
              {/*    enterFrom="transform opacity-0 scale-95"*/}
              {/*    enterTo="transform opacity-100 scale-100"*/}
              {/*    leave="transition ease-in duration-75"*/}
              {/*    leaveFrom="transform opacity-100 scale-100"*/}
              {/*    leaveTo="transform opacity-0 scale-95"*/}
              {/*  >*/}
              {/*    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
              {/*      <div className="py-1">*/}
              {/*        {sortOptions.map((option) => (*/}
              {/*          <Menu.Item key={option.name}>*/}
              {/*            {({active}) => (*/}
              {/*              <a*/}
              {/*                href={option.href}*/}
              {/*                className={classNames(*/}
              {/*                  option.current*/}
              {/*                    ? "font-medium text-gray-900"*/}
              {/*                    : "text-gray-500",*/}
              {/*                  active ? "bg-gray-100" : "",*/}
              {/*                  "block px-4 py-2 text-sm"*/}
              {/*                )}*/}
              {/*              >*/}
              {/*                {option.name}*/}
              {/*              </a>*/}
              {/*            )}*/}
              {/*          </Menu.Item>*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    </Menu.Items>*/}
              {/*  </Transition>*/}
              {/*</Menu>*/}
            </div>
          </div>
        </Disclosure>

        {this.props.children && this.props.children(filterArr, sort)}
      </div>
    );
  }
}

AdvancedFilter.propTypes = {
  defaultFilters: PropTypes.array,
  defaultOpen: PropTypes.bool,

  sort: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string.isRequired,
      onFilter: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default AdvancedFilter;
