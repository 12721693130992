import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import Select from "react-dropdown-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Fuse from "fuse.js";
import {request} from "../../utils/request";
import {faUserGroup} from "@fortawesome/pro-light-svg-icons";
import {HIT_ICON} from "../../layouts/admin/admin-search";
import {TYPE_LABEL} from "../../layouts/header/global-search";

class EmptyConversationHeader extends Component {
  state = {
    conversation: null,
    options: [],
    lastSearch: "",
  };

  constructor(props) {
    super(props);

    this.requestStack = [];
  }

  search(search, ignoreStack) {
    let {conversation} = this.props;
    const {
      employees = [],
      roles = [],
      departments = [],
      locations = [],
    } = this.props.shop;
    let {lastSearch, options} = this.state;

    if (this.requestStack.length > 1) return;

    if (lastSearch === search) {
      this.requestStack.shift();

      if (this.requestStack.length > 0) {
        this.requestStack = [this.requestStack.pop()];

        this.requestStack[0]();
      }

      return options;
    }

    if (search === "") {
      return this.setState({lastSearch: search, options: []}, () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      });
    }

    if (this.selectRef) {
      this.selectRef.state.cursor = 0;
    }

    const items = [
      ...employees.map((item) => {
        if (
          conversation.PARTICIPANTS.findIndex(({ID}) => ID === item.ID) !== -1
        ) {
          return;
        }

        return {
          ...item,
          type: "employees",
          name: item.FULL_NAME,
          icon: HIT_ICON["employees"],
        };
      }),

      ...roles.map((item) => {
        return {
          ...item,
          type: "roles",
          icon: HIT_ICON["roles"],
          name: item.NAME,
        };
      }),

      ...locations.map((item) => {
        return {
          ...item,
          type: "locations",
          icon: HIT_ICON["locations"],
          name: item.NAME,
        };
      }),

      ...departments.map((item) => {
        return {
          ...item,
          type: "departments",
          icon: HIT_ICON["departments"],
          name: item.NAME,
        };
      }),
    ];

    options = new Fuse(items, {
      keys: ["name"],
      useExtendedSearch: true,
      threshold: 0.1,
    })
      .search(search)
      .map(({item}) => item);

    this.setState(
      {
        lastSearch: search,
        options,
      },
      () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      }
    );
  }

  renderParticipants() {
    let {conversation} = this.props;
    const {employee} = this.props.user;

    const conversationFields = [
      ...(conversation.PARTICIPANTS ?? []),
      ...(conversation.LOCATIONS ?? []),
      ...(conversation.DEPARTMENTS ?? []),
      ...(conversation.ROLES ?? []),
    ];

    return (
      <div
        className={
          "flex flex-row flex-wrap w-full flex-none justify-start mr-2"
        }
      >
        {conversationFields.map((item) => {
          if (item.ID === employee.ID) {
            return;
          }

          const name = item.NAME ?? item.FULL_NAME;

          return (
            <div
              className={
                "flex flex-row text-sm justify-between items-center h-6 mt-1 py-1 px-2 mr-2 rounded-md bg-gray-100 hover:cursor-pointer"
              }
              onClick={() => this.props.removeConversationField(item)}
            >
              {name}

              <div className={"text-sm ml-2"}>x</div>
            </div>
          );
        })}

        {this.renderInput()}
      </div>
    );
  }

  renderInput() {
    const {lastSearch, options} = this.state;

    return (
      <div className="max-w-lg w-full lg:max-w-xs break-words mt-1">
        <label htmlFor="search" className="sr-only">
          Search
        </label>

        <Select
          noDataRenderer={() => {
            if (lastSearch === "") {
              return (
                <div className="text-center p-6 text-sm">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    Type to start searching
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    Type the name of an employee to add a participant to the
                    conversation
                  </p>
                </div>
              );
            }

            return (
              <div className="text-center p-6 text-sm">
                <FontAwesomeIcon
                  icon="cloud-question"
                  className="mx-auto h-12 w-12 text-gray-400"
                />

                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No records found
                </h3>

                <p className="mt-1 text-sm text-gray-500">
                  We could not find anything with the search you inputted.
                </p>
              </div>
            );
          }}
          className="global-search block w-full z-20"
          onDropdownClose={() => this.setState({lastSearch: ""})}
          dropdownHandleRenderer={() => {
            return <div />;
          }}
          ref={(e) => (this.selectRef = e)}
          onChange={(obj) => {
            if (obj?.length === 0) {
              return;
            }

            this.props.addConversationField(obj[0]);

            const employeeSearch = document.getElementById("employee-search");

            if (employeeSearch) {
              employeeSearch.focus();
            }
          }}
          inputRenderer={({state, methods, inputRef}) => {
            return (
              <form
                className="relative w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="w-full border-transparent rounded-md bg-white sm:text-sm"
                  style={{
                    outline: "none",
                    padding: "0px",
                    "--tw-ring-color": "transparent",
                  }}
                  onChange={methods.setSearch}
                  value={state.search}
                  autoComplete="off"
                  type="search"
                  ref={inputRef}
                  placeholder={"Type an employee, role, department or location"}
                  id="employee-search"
                />
              </form>
            );
          }}
          itemRenderer={({item, state, itemIndex, methods}) => {
            const isSelected = state.cursor === itemIndex;
            const {name, icon, type, extra} = item;

            return (
              <div
                onClick={() => methods.addItem(item)}
                className={classNames(
                  isSelected && "bg-indigo-500 text-white",
                  "group px-4 py-2 hover:bg-indigo-500 hover:text-white flex flex-row items-center"
                )}
              >
                {icon && (
                  <div className="mr-3">
                    <FontAwesomeIcon icon={icon} className="h-6 w-6 mt-1" />
                  </div>
                )}

                <div className="flex flex-col flex-1 text-sm">
                  <div className="font-medium">{name}</div>

                  <div
                    className={classNames(
                      isSelected
                        ? "text-white"
                        : "text-gray-500 group-hover:text-white",
                      "text-sm"
                    )}
                  >
                    {TYPE_LABEL[type]}
                  </div>
                </div>
              </div>
            );
          }}
          searchFn={({state}) => {
            const {search} = state;

            this.search(search);

            return options;
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="flex flex-none flex-row items-start justify-start border-b-2 pt-2">
        <div className={"mx-2 text-gray-700 my-1"}>{"To:"}</div>

        {this.renderParticipants()}
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(
  withRouter(EmptyConversationHeader)
);
