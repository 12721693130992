import React, {Component} from "react";
import {Switch} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import FormElement from "./form-element";
import Tooltip from "../tooltip/tooltip";

class FormToggle extends Component {
  state = {enabled: false};

  render() {
    const {options, name, label, description, tooltip} = this.props;

    return (
      <FormElement {...this.props} label={null}>
        {(value, error, onChange) => {
          return (
            <Switch.Group
              as="div"
              className="flex items-center justify-between"
            >
              <div className="flex flex-row">
                <span className="flex-grow flex flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                  >
                    {label}
                  </Switch.Label>

                  {description && (
                    <Switch.Description
                      as="span"
                      className="text-sm text-gray-500"
                    >
                      {description}
                    </Switch.Description>
                  )}
                </span>

                {tooltip && (
                  <div className="ml-2 pt-0 mt-0">
                    {typeof tooltip === "string" ? (
                      <Tooltip data={tooltip} />
                    ) : (
                      <Tooltip {...tooltip} />
                    )}
                  </div>
                )}
              </div>

              <Switch
                checked={parseInt(value) === 1}
                onChange={(val) => {
                  const togVal = val ? "1" : "0";

                  if (options) {
                    options.setFieldValue(name, togVal);
                  }

                  onChange(togVal);
                }}
                className={classNames(
                  parseInt(value) === 1 ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    parseInt(value) === 1 ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>
          );
        }}
      </FormElement>
    );
  }
}

FormToggle.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default FormToggle;
