import React, {Component} from "react";
import {
  Table,
  Card,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";
import {Download} from "react-feather";
import b64toBlob from "b64-to-blob";
import {withRouter} from "../../../../utils/navigation";
import {getPaystub} from "../../../../utils/payroll-helper";

class PayrollEmployeePaystubsPage extends Component {
  state = {loading: true, data: null, employee: null, checkEmployeeId: null};

  componentDidMount() {
    const {ID} = this.props.router.params;

    request("payroll/employee-paystubs/" + ID, "GET").then(
      ({paystubs, employee, checkEmployeeId}) => {
        this.setState({loading: false, data: paystubs, employee, checkEmployeeId});
      }
    );
  }

  async openPaystub(payrollID) {
    let {checkEmployeeId} = this.state;
    let pdf = await getPaystub(checkEmployeeId, payrollID);

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  }

  render() {
    let {isLoading, data} = this.state;
    const {ID} = this.props.router.params;

    let columns = [
      {
        label: "Payday",
        value: "payday",
      },
      {
        label: "Period Start",
        value: "period_start",
      },
      {
        label: "Period End",
        value: "period_end",
      },
      {
        name: "Open",
        value: "id",
        format: (id, row) => {
          return (
            <div
              style={{cursor: "pointer"}}
              onClick={() => this.openPaystub(row.payroll)}
            >
              <Download />
            </div>
          );
        },
      },
    ];

    return (
      <div className="p-6">
        {/*<PageHeadings*/}
        {/*  label="Paystubs"*/}
        {/*  breadcrumbs={[*/}
        {/*    {*/}
        {/*      label: "Payroll Portal",*/}
        {/*      url: `/employee-payroll/landing/${ID}`,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}

        {/*<Card label="Your Paystubs">*/}
          {isLoading && (
            <div className="py-10">
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && <Table columns={columns} data={data} />}
        {/*</Card>*/}
      </div>
    );
  }
}

export default withRouter(PayrollEmployeePaystubsPage);
