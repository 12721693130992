import React, {Component} from "react";
import ChoosePaydayComponent from "../choose-payday-component";
import {request} from "../../../../utils/request";
import {showErrorAlert} from "../../../../utils/alert-helper";

class ContractorsChoosePaymentDateComponent extends Component {
  state = {loading: false, paymentDate: null};

  async handleClick() {
    let {paymentDate} = this.state;

    let payload = {
      PAYMENT_DATE: paymentDate,
    };

    try {
      await request("payroll/contractor-payroll/create", "POST", payload);
      return true;
    } catch (error) {
      let label = "Invalid Dates";
      let description =
        "The dates selected are invalid. Please correct them to run an off-cycle payroll";

      if (error === "APPROVAL_DEADLINE_PASSED") {
        label = "Approval Deadline Passed";
        description =
          "The approval deadline for the specified dates has passed. Please choose a different paydate";
      }

      await showErrorAlert(label, description, "Ok");
    }
  }

  render() {
    let {loading} = this.state;

    return (
      <ChoosePaydayComponent
        title={"Payment Date"}
        description={"Choose between the following to pay your contractors:"}
        loading={loading}
        onPaydayChange={(newPayday) => {
          this.setState({paymentDate: newPayday});
        }}
        chooseWorkPeriod={false}
      />
    );
  }
}

export default ContractorsChoosePaymentDateComponent;
