import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import LoadingSpinner from "../../../../components/loading-spinner";
import {DRAWER_TYPES} from "../../../../utils/operations-constants";
import moment from "moment/moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ShowImageModal from "../../../../modals/general/show-image-modal";
import {saveAs} from "file-saver";
import CashEventModal from "../../../../modals/operations/cash/cash-event-modal";

class CashDrawerPage extends Component {
  state = {cashDrawer: {}};

  async componentDidMount() {
    return this.syncState();
  }

  async syncState() {
    const {ID} = this.props.router.params;
    let cashDrawer = await request(
      `cashboxManagement/cash_drawers/${ID}`,
      "GET"
    );

    this.setState({cashDrawer});
  }

  renderBalances() {
    let {
      CASHBOX: cashbox,
      CASH_SALES: cashSales,
      PAYIN_FACTS: payinFacts,
    } = this.state.cashDrawer;

    let data = {...cashbox, ...cashSales, ...payinFacts};
    let {
      AMOUNT_OPEN,
      TOTAL_COLLECTED,
      AMOUNT_EXPECTED,
      paidInSum,
      paidOutSum,
      AMOUNT_CLOSE,
      DIFFERENCE,
    } = data;

    return (
      <Card>
        <div className="p-6 flex flex-col text-md w-96 text-gray-800">
          <div className="flex flex-row justify-between">
            <div className="pl-3.5">Starting Cash</div>
            <div>{toDollars(AMOUNT_OPEN, true)}</div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="">+ Cash Sales</div>
            <div>{toDollars(TOTAL_COLLECTED, true)}</div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="">+ Paid In</div>
            <div>{toDollars(paidInSum, true)}</div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="">- (Paid Out)</div>
            <div>{toDollars(paidOutSum, true)}</div>
          </div>

          <div className="w-full h-0.5 my-1 bg-gray-200" />

          <div className="flex flex-row justify-between">
            <div className="">= Expected in Drawer</div>
            <div>{toDollars(AMOUNT_EXPECTED, true)}</div>
          </div>

          <div className="flex flex-row justify-between mt-5">
            <div className="">Close Amount</div>
            <div>{toDollars(AMOUNT_CLOSE, true)}</div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="">Difference</div>
            <div>{toDollars(DIFFERENCE, true)}</div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="">Deposit</div>
            <div>{toDollars(AMOUNT_CLOSE - AMOUNT_OPEN, true)}</div>
          </div>
        </div>
      </Card>
    );
  }

  renderEvents() {
    let {CASHBOX: cashbox, PAYINS: payins} = this.state.cashDrawer;

    const payinsFull = [
      {
        AMOUNT: cashbox.AMOUNT_OPEN,
        DATE_SENT: cashbox.DATE_OPEN,
        TYPE: "Opened",
        FULL_NAME: cashbox.OPEN_EMPLOYEE_NAME,
        CASHBOX_ID: cashbox.ID,
      },
      ...payins,
    ];

    if (cashbox.DATE_CLOSE !== null) {
      payinsFull.push({
        AMOUNT: cashbox.AMOUNT_CLOSE,
        DATE_SENT: cashbox.DATE_CLOSE,
        TYPE: "Closed",
        FULL_NAME: cashbox.CLOSE_EMPLOYEE_NAME,
        CASHBOX_ID: cashbox.ID,
      });
    }

    return (
      <Card>
        <CashEventModal
          ref={(e) => (this.cashModal = e)}
          syncState={() => this.syncState()}
          drawer={cashbox}
        />

        <Table
          actionButtons={[
            {label: "Edit", onClick: (event) => this.cashModal.open(event)},
          ]}
          columns={[
            {
              label: "Event Type",
              sortable: true,
              value: "AMOUNT",
              format: (value, rest) => {
                if (rest.TYPE) {
                  return rest.TYPE;
                }

                return value >= 0 ? "Payin" : "Payout";
              },
            },
            {
              label: "Amount",
              value: "AMOUNT",
              sortable: true,
              format: (value) => {
                return "$" + toDollars(value);
              },
            },
            {
              label: "Timestamp",
              value: "DATE_SENT",
              sortable: true,
              format: (value) => {
                return moment(value).format("MMM DD YYYY, hh:mm A");
              },
            },
            {label: "Employee", value: "FULL_NAME", sortable: true},
            {
              label: "Notes",
              value: "NOTES",
            },
            {
              label: "Actions",
              value: "SIGNED_URL",
              width: 1,
              format: (value) => {
                if (value) {
                  return (
                    <div
                      className="flex justify-center text-indigo-600 hover:text-indigo-900 cursor-pointer font-medium"
                      onClick={() => this.showImageModal.open(value)}
                    >
                      View Photo
                    </div>
                  );
                }
              },
            },
            {
              value: "SIGNED_URL",
              width: 1,
              format: (value) => {
                if (value) {
                  return (
                    <div
                      className="flex justify-center text-indigo-600 hover:text-indigo-900 cursor-pointer font-medium"
                      onClick={() => saveAs(value, "pdf")}
                    >
                      Download Photo
                    </div>
                  );
                }
              },
            },
          ]}
          data={payinsFull}
        />
      </Card>
    );
  }

  render() {
    let {CASHBOX: cashbox} = this.state.cashDrawer;

    const openStr = `${moment(cashbox?.DATE_OPEN).format("MMM DD, hh:mm A")} 
      ${
        cashbox?.OPEN_EMPLOYEE_NAME ? " by " + cashbox?.OPEN_EMPLOYEE_NAME : ""
      }`;

    const closeStr = cashbox?.DATE_CLOSE
      ? `${moment(cashbox?.DATE_CLOSE).format("MMM DD, hh:mm A")} 
      ${
        cashbox?.CLOSE_EMPLOYEE_NAME
          ? " by " + cashbox?.CLOSE_EMPLOYEE_NAME
          : ""
      }`
      : "Drawer Open";

    return (
      <div>
        <ShowImageModal ref={(e) => (this.showImageModal = e)} />

        {!cashbox && <LoadingSpinner />}

        {cashbox && (
          <div>
            <PageHeadings
              label={
                cashbox?.DRAWER_TYPE === DRAWER_TYPES.CASH
                  ? "Cash Drawer"
                  : `${cashbox?.EMPLOYEE_NAME}'s Employee Drawer`
              }
              description={`View balances of this ${
                cashbox?.DRAWER_TYPE === DRAWER_TYPES.CASH ? "Cash" : "Employee"
              } Drawer`}
              breadcrumbs={[
                {label: "Operations", url: "/"},
                {label: "Cash Management Page", url: "/cash"},
                {label: "Cash Drawers Page", url: "/drawers"},
              ]}
            />

            <TwoColumnList
              data={[
                {
                  label: "Opened",
                  value: openStr,
                },
                {
                  label: "Closed",
                  value: closeStr,
                },
                {label: "Device Name", value: cashbox.DEVICE_NAME},
              ]}
            />

            <Tab
              className="mt-2"
              data={[
                {label: "Balance Breakdown", id: 0},
                {label: "Events", id: 1},
              ]}
            >
              {(id) => {
                if (id === 0) {
                  return this.renderBalances();
                }

                return this.renderEvents();
              }}
            </Tab>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CashDrawerPage);
