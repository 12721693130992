import React, {Component} from "react";
import ReportingSuperTable from "./reporting-super-table";

class ReportTable extends Component {
  render() {
    let {constantRows, numbers, constantColumns, data, columns} = this.props;

    if (constantRows) {
      return (
        <ReportingSuperTable
          data={constantRows.map((item) => {
            return {
              ...item,
              numbers,
            };
          })}
          columns={columns}
          expandable={this.props.expandable}
          isExpandable={this.props.isExpandable}
        />
      );
    }

    return (
      <ReportingSuperTable
        data={data}
        columns={constantColumns.map((item) => {
          return {
            ...item,
            numbers,
          };
        })}
        expandable={this.props.expandable}
        isExpandable={this.props.isExpandable}
        onRowClick={this.props.onRowClick}
        isRowClickable={this.props.isRowClickable}
      />
    );
  }
}

export default ReportTable;
