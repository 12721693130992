export const PAYMENT_TYPE_TO_STRING = {
  "CARD_PRESENT": "Card Present",
  "CARD_NOT_PRESENT": "Card Not Present",
  "CASH": "Cash",
  "GIFT_CARD": "Physical Gift Card",
  "E_GIFT_CARD": "Digital Gift Card",
  "REWARD": "Loyalty Rewards",
  "BALANCE": "Dripos Points",
  "CUSTOM_TYPE": "Custom Payment Type",
}

export const LINE_ITEM_TYPES = {
  PRODUCT: "PRODUCT",
  CUSTOM_PRODUCT: "CUSTOM_PRODUCT",
  PHYSICAL_GIFT_CARD: "PHYSICAL_GIFT_CARD",
  E_GIFT_CARD: "E_GIFT_CARD",
  FEE: "FEE",
  SHIPPING_FEE: "SHIPPING_FEE",
  TIP: "TIP",
  TICKET_DISCOUNT: "TICKET_DISCOUNT",
  TICKET_REFUND: "TICKET_REFUND" // Only for custom refunds
};
