import React, {Component} from "react";
import {fetchModifierById} from "../../utils/product-constants";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";

class ModifiersTable extends Component {
  render() {
    const {onDrag, onClick, data, editOnClick} = this.props;

    return (
      <Table
        draggable={onDrag ?? undefined}
        verticalLines
        classNames="mt-0"
        onDrag={onDrag}
        onClick={onClick}
        data={data}
        actionButtons={[
          {
            label: "Edit",
            onClick: editOnClick,
          },
        ]}
        ref={(e) => (this.modifierTable = e)}
        columns={[
          {
            value: "NAME",
            label: "Name",
            width: "200px",
            format: (value, row) => {
              return (
                <div className="flex items-center">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {row.INTERNAL_NAME ? `${row.NAME} - (${row.INTERNAL_NAME})` : `${row.NAME}`}
                    </div>

                    <div className="text-sm text-gray-500">
                      {fetchModifierById(row.TYPE)?.label}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            width: "auto",
            value: "OP",
            label: "Options",
            mobile: "sm:table-cell",
            format: (values, row) => {
              return (
                <div className="text-sm font-medium text-gray-900">
                  {row.OPTIONS.map((item) => {
                    return item.NAME;
                  }).join(", ")}
                </div>
              );
            },
          },
        ]}
      />
    );
  }
}

export default ModifiersTable;
