import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";

import RECEIPT from "./receipt.json";
import MAKE_RECEIPT from "./make-receipt.json";
import FormRow from "../../../components/form-row";
import {withRouter} from "../../../utils/navigation";

class DuplicateReceiptModal extends Component {
  state = {receipt: null};

  open(receipt = null) {
    this.setState({receipt}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createReceipt({name, type, itemSpecific, content}) {
    let receiptPayload = {
      NAME: name,
      ITEM_SPECIFIC: itemSpecific,
      CONTENT: content,
      TYPE: type,
    };

    const serverReceipt = await request("receipts", "POST", receiptPayload);

    this.modal.close();

    setTimeout(() => {
      this.props.router.navigate("/receipt/" + serverReceipt.UNIQUE_ID);
    }, 100);
  }

  render() {
    const {receipt} = this.state;

    return (
      <Modal
        buttonLabel="Duplicate"
        label="Duplciate Receipt"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.createReceipt.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            type: receipt?.TYPE,
            name: receipt?.NAME + " Duplicate",
            itemSpecific: receipt?.ITEM_SPECIFIC,
            content: receipt?.CONTENT,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Receipt name is required."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    name="name"
                    label="Receipt Name"
                    options={formikOptions}
                    flex
                  />

                  <FormSelect
                    flex
                    label="Receipt Type"
                    name="type"
                    options={formikOptions}
                    data={[
                      {label: "Make Ticket", value: "MAKE"},
                      {label: "Front of House Receipt", value: "RECEIPT"},
                    ]}
                  />
                </FormRow>

                <FormBoolean
                  name="itemSpecific"
                  label="Print Items Singular"
                  options={formikOptions}
                  tooltip="Will this receipt print each item on the ticket on it's own seperate receipt"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

DuplicateReceiptModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default withRouter(DuplicateReceiptModal);
