import React, {Component} from "react";
import {
  PageHeadings,
  Table,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import PayrollCreateContractorModal from "../../../modals/payroll/payroll-create-contractor-modal";
import {request} from "@frostbyte-technologies/frostbyte-tailwind/dist/utils/request";
import {Settings} from "react-feather";
import PayrollContractorSettingsModal from "../../../modals/payroll/payroll-contractor-settings-modal";
import {getPayrollCompanyOnboardingLink} from "../../../utils/payroll-helper";
import LoadingSpinner from "../../../components/loading-spinner";

class PayrollContractorsPage extends Component {
  state = {contractors: null};

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    let contractors = await request("payroll/contractors", "GET");

    this.setState({contractors});
  };

  renderStatusBadge = (row) => {
    let {status} = row.CONTRACTOR?.onboard;
    //
    return (
      <div>
        {status === "blocking" && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800 cursor-pointer">
            Blocking
          </span>
        )}

        {status === "needs_attention" && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800 cursor-pointer">
            Incomplete
          </span>
        )}

        {status === "completed" && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
            Complete
          </span>
        )}
      </div>
    );
  };

  render() {
    let {contractors} = this.state;

    if(!contractors){
      return <LoadingSpinner />
    }

    let columns = [
      {
        label: "Name",
        format: (value, row) => {
          return `${row?.CONTRACTOR?.first_name} ${row?.CONTRACTOR?.last_name}`;
        },
      },
      {
        label: "Status",
        format: (value, row) => {
          return this.renderStatusBadge(row);
        },
        width: 1,
      },
      {
        label: "",
        format: (value, row) => {
          return (
            <Settings
              className="ml-3 h-5 w-5 cursor-pointer"
              onClick={async () => {
                this.contractorSettingsModal.open(row);
                // request(`payroll/contractors/${row.CONTRACTOR.id}/link`, "GET").then((res) => {
                //   const handler = window.Check.create({
                //     link: res.url,
                //   });
                //   handler.open();
                //
                //   setTimeout(
                //     () => this.setState({loadingCheckComponent: false}),
                //     3000
                //   );
                // });
              }}
            />
          );
        },
        width: 1,
      },
    ];

    return (
      <div>
        <PayrollCreateContractorModal
          ref={(e) => (this.createContractorModal = e)}
          refresh={() => this.refresh()}
        />

        <PayrollContractorSettingsModal
          ref={(e) => (this.contractorSettingsModal = e)}
          refresh={() => this.refresh()}
        />

        <PageHeadings
          label="Payroll Contractors"
          description={"Manage your contractors' payroll onboarding process"}
        />

        <Card
          label="Contractors"
          button={{
            label: "+ Create Contractor",
            onClick: () => this.createContractorModal.open(),
          }}
        >
          <Table data={contractors} columns={columns} />
        </Card>
      </div>
    );
  }
}

export default PayrollContractorsPage;
