import React, {Component} from "react";
import {FormCheck, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";

class ClearEmailCampaignModal extends Component {
  open() {
    this.modal.open();
  }

  async clearPatrons({name, email}) {
    await this.props.clearCampaignPatrons(name, email);

    this.modal.close();
  }

  render() {
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={"Clear"}
        formikOnClick={() => this.formikRef}
        label={"Clear Patron Options"}
      >
        <Formik
          onSubmit={this.clearPatrons.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: true,
            email: true,
          }}
        >
          {(formikOptions) => {
            const {values, handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormCheck
                  options={formikOptions}
                  label={"Unnamed Patrons"}
                  name={"name"}
                  onChangeSoft={() => {
                    setFieldValue("name", !values.name);
                  }}
                />

                <FormCheck
                  options={formikOptions}
                  label={"Patrons without emails"}
                  name={"email"}
                  onChangeSoft={() => {
                    setFieldValue("email", !values.email);
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ClearEmailCampaignModal;
