import React, {Component} from "react";
import {ChevronRightIcon} from "@heroicons/react/solid";

class DashboardItem extends Component {
  openPath() {
    let {router} = this.props;
    let {onClick, path} = this.props.item;

    if (onClick) {
      onClick();
    } else {
      this.props.router.navigate(path);
    }
  }

  render() {
    let {label, description} = this.props.item;

    return (
      <li onClick={() => this.openPath()}>
        <a className="block hover:bg-gray-50 cursor-pointer">
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="flex-shrink-0"></div>
              <div className="min-w-0 flex-1 px-4">
                <div>
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {label}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {description}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row items-center font-semibold text-gray-700">
                <div>Go</div>

                <ChevronRightIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </a>
      </li>
    );
  }
}

export default DashboardItem;
