import React, {Component} from "react";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class PageLayout extends Component {
  render() {
    const {className} = this.props;

    return (
      <div
        className={classNames(
          className,
          "mt-6 mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-12"
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

PageLayout.propTypes = {
  className: PropTypes.string,
};

export default PageLayout;
