import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";

class CashAccountSettingsPage extends Component {
  openSettingsLink() {
    request("stripe/connect/partner", "GET", null).then((data) => {
      window.open(data, "_self").focus();
    });
  }

  render() {
    return (
      <div>
        <PageHeadings label="Settings" />

        <Card label="General">
            <Formik initialValues={{}}>
              {(formikOptions) => {
                return (
                  <div>
                    <FormInput
                      className="px-6 w-72"
                      label={"Statement Descriptor"}
                      name={"statementDescriptor"}
                    />

                    <div className="p-6 w-full flex flex-row justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
        </Card>

        <Card
          label="Stripe"
          buttons={[
            {
              label: "Open Stripe Settings",
              onClick: () => this.openSettingsLink(),
            },
          ]}
        />
      </div>
    );
  }
}

export default CashAccountSettingsPage;
