import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";

class InvoiceRecipientModal extends Component {
  state = {recipient: null};

  open(invoice, recipient = null) {
    this.setState({invoice, recipient}, () => this.modal.open());
  }

  addRecipient({email}) {
    const {invoice} = this.state;

    request("invoices/" + invoice.ID + "/recipients", "POST", {
      EMAIL: email,
    }).then((invoice) => {
      this.props.updateState({
        ...invoice,
      });

      this.modal.close();
    });
  }

  render() {
    const {recipient} = this.state;

    return (
      <Modal
        label={recipient ? "Edit Recipient" : "Add Recipient"}
        buttonLabel={recipient ? "Edit Recipient" : "Add Recipient"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{email: recipient?.EMAIL}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={
            recipient
              ? (values) => this.editRecipient(values)
              : (values) => this.addRecipient(values)
          }
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .nullable()
              .required("Please enter the email of the recipient."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="email" label="Email" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default InvoiceRecipientModal;
