import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {SelectorIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class MultiSelectCombobox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleButtonClick() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleItemClick(item, values) {
    const {options, name, onChangeSoft} = this.props;
    const isRemove =
      values.findIndex((selectedItem) => selectedItem.id === item.id) !== -1;

    const selectedItems = isRemove
      ? values.filter((i) => i.id !== item.id).map((item) => item.id)
      : [...values.map((item) => item.id), item.id];

    if (options) {
      options.setFieldValue(name, selectedItems);
    }

    if (onChangeSoft) {
      onChangeSoft(selectedItems, item, isRemove);
    }
  }

  selectAllSubItems(item, values) {
    const {options, name, onChangeSoft} = this.props;

    const valuesDict = values.reduce((accum, value) => {
      accum[value] = true;
      return accum;
    }, {});

    const itemDict = item.data.reduce((accum, _item) => {
      accum[_item.id] = true;
      return accum;
    }, {});

    const isSelect = item.data.some((_item) => !valuesDict[_item.id]);

    if (isSelect) {
      for (const _item of item.data) {
        if (!valuesDict[_item.id]) {
          values.push(_item.id);
        }
      }
    } else {
      values = values.filter((_value) => !itemDict[_value]);
    }

    options.setFieldValue(name, values);
    onChangeSoft && onChangeSoft(values);
  }

  getSelectedValues(values) {
    const {data} = this.props;

    const rawData = Object.keys(data).reduce((accum, item) => {
      accum.push(...data[item].data);
      return accum;
    }, []);

    return rawData.filter(
      (item) => !!values.find((_item) => _item === item.id)
    );
  }

  formatHeader(selectedValues) {
    const {maxLen = 2} = this.props;

    if (selectedValues.length > maxLen) {
      return selectedValues.reduce((accum, {label}, idx) => {
        if (idx === maxLen) {
          return accum + " " + (selectedValues.length - maxLen) + " more.";
        } else if (idx === maxLen - 1) {
          return accum + label + ", and";
        } else if (idx > maxLen) {
          return accum;
        } else {
          return accum + label + ", ";
        }
      }, "");
    }

    return selectedValues.map((item) => item?.label).join(", ");
  }

  render() {
    const {
      data,
      headerIcon = null,
      placeholder = null,
      popOver = false,
    } = this.props;
    const {isOpen} = this.state;

    return (
      <FormElement id={this.id} {...this.props}>
        {(value) => {
          const selectedValues = this.getSelectedValues(value);

          return (
            <>
              <div
                className="flex flex-row justify-between cursor-pointer align-items-middle my-1 border-gray-300 rounded-md shadow-sm w-full border bg-white py-2 pl-3 pr-3 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                onClick={() => this.handleButtonClick()}
              >
                {selectedValues?.length > 0
                  ? this.formatHeader(selectedValues)
                  : placeholder ?? "Select items"}
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              {isOpen && (
                <div
                  className={classNames(
                    popOver ? "absolute z-50 w-full pb-10" : "z-10 py-1",
                    'max-h-56 overflow-auto rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"'
                  )}
                >
                  {data.map((item) => (
                    <div>
                      <div
                        key={item}
                        className={
                          "ml-0 border-gray-300 text-indigo-500 text-md font-bold py-2 cursor-pointer hover:bg-indigo-100"
                        }
                        onClick={() => this.selectAllSubItems(item, value)}
                      >
                        {!!headerIcon && (
                          <FontAwesomeIcon
                            icon={headerIcon}
                            className={"px-1"}
                          />
                        )}
                        {item.title}
                      </div>
                      {item.data.map((subitem) => (
                        <div
                          className={
                            "pl-2 border-gray-300 text-gray-900 text-sm font-bold py-1 hover:bg-gray-300 hover:cursor-pointer"
                          }
                          onClick={() =>
                            this.handleItemClick(subitem, selectedValues)
                          }
                        >
                          {!!selectedValues &&
                            selectedValues.findIndex(
                              (item) => item.id === subitem.id
                            ) !== -1 && (
                              <FontAwesomeIcon
                                className="mr-1.5 text-indigo-500"
                                icon="fa-solid fa-check"
                              />
                            )}

                          {subitem.label}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}{" "}
            </>
          );
        }}
      </FormElement>
    );
  }
}

export default MultiSelectCombobox;
