import React from "react";

const PreferredEvent = ({event, timeString, allDay}) => {

  if(allDay){
    timeString = "All Day";
  }

  if (event.extendedProps.isVisible) {
    return (
      <div className="p-1 flex flex-col items-center">
        <p className="font-bold text-[10px]">{timeString}</p>
        <p className="text-[10px]">Preferred</p>
      </div>
    );
  }

  return <></>;
};

export default PreferredEvent;
