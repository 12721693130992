import React, {Component} from "react";
import {Card, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import TimesheetsApprovalTable from "../../../../features/team/timesheets-approval-table";

class TimeCardsTab extends Component {
  getAllRoles(cards) {
    if (!cards) {
      return [];
    }

    const found = {};
    const roles = [];

    cards.forEach((card) => {
      if (!found[card.ROLE_ID]) {
        roles.push({id: card.ROLE_ID, label: card.ROLE_NAME});
        found[card.ROLE_ID] = true;
      }
    });

    return roles;
  }

  render() {
    let {cards, updateCards, breakOptions} = this.props;

    return (
      <Filter
        searchable
        defaultFilters={[]}
        data={[
          {
            id: "role",
            label: "Employee Role",
            options: this.getAllRoles(cards),
            onFilter: (options, data) => {
              if (options.length === 0) {
                return data;
              }

              return data.filter((item) => options.includes(item.ROLE_ID));
            },
          },
        ]}
      >
        {(filters, search) => {
          return (
            <Card
              label="Time Cards"
              description={"All time cards that have yet to be approved"}
              buttons={[
                {
                  label: "Approve All",
                  onClick: () => this.approvalTable.approveAll(),
                },
              ]}
            >
              <TimesheetsApprovalTable
                filters={filters}
                search={search}
                searchFields={["FULL_NAME", "ROLE"]}
                cards={cards}
                updateCards={updateCards}
                breakOptions={breakOptions}
                ref={(e) => (this.approvalTable = e)}
              />
            </Card>
          );
        }}
      </Filter>
    );
  }
}

TimeCardsTab.propTypes = {
  updateCards: PropTypes.func.isRequired,
  cards: PropTypes.array.isRequired,
};

export default TimeCardsTab;
