import React, {Component} from "react";
import {
  FormInput,
  FormBoolean,
  Modal,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import moment from "moment";

class InventoryButtonModal extends Component {
  state = {button: null};

  open(button = null) {
    this.setState({button}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createButton({quantity}) {
    const {product} = this.props;

    const buttonPayload = {
      QUANTITY: quantity,
      TYPE: 1,
    };

    let serverButton = await request(
      "inventory/product/" + product.ID + "/button",
      "POST",
      buttonPayload
    );

    this.props.addState(serverButton);
    this.modal.close();
  }

  async saveButton({quantity}) {
    const {button} = this.state;

    const buttonPayload = {
      QUANTITY: quantity,
      TYPE: 1,
    };

    const serverButton = await request(
      "inventory/button/" + button.ID,
      "PATCH",
      buttonPayload
    );

    this.props.updateState(serverButton.ID, serverButton);
    this.modal.close();
  }

  async deleteButton() {
    const {button} = this.state;

    const serverButton = await request(
      "inventory/button/" + button.ID,
      "DELETE",
      {}
    );

    this.props.updateState(button.ID);
    this.modal.close();
  }

  render() {
    const {button} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        deleteLabel={button && "Delete"}
        buttonLabel={button ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        deleteOnClick={this.deleteButton.bind(this)}
        label={button ? "Edit Button" : "Create Button"}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            quantity: button?.QUANTITY ?? "",
          }}
          onSubmit={
            button ? this.saveButton.bind(this) : this.createButton.bind(this)
          }
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="160"
                  label="Quantity"
                  name="quantity"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

InventoryButtonModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  addState: PropTypes.func.isRequired,
};

export default InventoryButtonModal;
