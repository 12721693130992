import React, {Component, Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import ButtonRow from "../buttons/button-row";

class SlideOver extends Component {
  state = {open: false};

  open() {
    this.setState({open: true});
  }

  close() {
    this.setState({open: false});
  }

  renderButtons() {
    const {buttons} = this.props;

    if (buttons) {
      return (
        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
          <ButtonRow buttons={buttons} />
        </div>
      );
    }

    return (
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    );
  }

  render() {
    const {label, description} = this.props;
    const {open} = this.state;

    return (
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          autoFocus={false}
          onClose={() => this.setState({open: false})}
          className={classNames("inset-0 overflow-y-auto")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed z-40 inset-0 bg-neutral-bg bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="fixed z-40 inset-y-0 max-w-full right-0 flex sm:pl-16">
              <div className="w-screen max-w-md">
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {label}
                          </Dialog.Title>

                          {description && (
                            <p className="text-sm text-gray-500">
                              {description}
                            </p>
                          )}
                        </div>

                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            onClick={() => this.setState({open: false})}
                            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                          >
                            <span className="sr-only">Close panel</span>

                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1 flex flex-col justify-between border-t border-gray-200">
                      {this.props.children}
                    </div>
                  </div>

                  {this.renderButtons()}
                </form>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    );
  }
}

SlideOver.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SlideOver;
