export function toDecimalPlaces(number, places) {
  if (number === null || number === undefined) {
    return 0;
  }

  if (number === 0) {
    return 0;
  }

  if (places > 20) {
    places = 20;
  }

  return number.toFixed(places);
}
