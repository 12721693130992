import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import EmployeeDropdown from "../../dropdowns/team/employee-dropdown";
import {TIP_CALCULATION_METHOD_TYPES} from "../../utils/settings-constants";

class RunPayrollSettingsModal extends Component {
  open() {
    this.modal.open();
  }

  handleSubmit = async ({
                          runPayrollShowCommission,
                        }) => {
    let settingsPayload = {
      RUN_PAYROLL_SHOW_COMMISSION: runPayrollShowCommission,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: settingsPayload});

    let settings = await request("settings/shop", "GET");
    this.props.updateShop({settings});

    this.props.refresh();
    this.modal.close();
  };

  render() {
    let {
      RUN_PAYROLL_SHOW_COMMISSION,
    } = this.props.shop.settings;

    return (
      <Modal
        label="Payroll Settings"
        buttonLabel="Save"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            runPayrollShowCommission: RUN_PAYROLL_SHOW_COMMISSION,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  label={"Commission"}
                  name={"runPayrollShowCommission"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(RunPayrollSettingsModal);
