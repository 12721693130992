import moment from "moment-timezone";
import {minutesToHours} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

export function checkDSTConversion(date, from, to) {
  const startOfDayEnd = moment(date).add(to, "minutes").startOf("day");

  let dateStart = moment(date).add(from, "minutes");
  let dateEnd =
    to < from
      ? moment(date).add(1, "day").add(to, "minutes")
      : moment(date).add(to, "minutes");

  if (moment(date).isDST() !== dateStart.isDST()) {
    //from DST to non-DST implies add one hour
    if (moment(date).isDST()) {
      dateStart.add(60, "minutes");
    } else {
      dateStart.subtract(60, "minutes");
    }
  }

  //dateEnd could start on a diff day (overnight shifts), need another if check
  if (startOfDayEnd.isDST() !== dateEnd.isDST()) {
    if (startOfDayEnd.isDST()) {
      dateEnd.add(60, "minutes");
    } else {
      dateEnd.subtract(60, "minutes");
    }
  }

  return {dateStart: dateStart, dateEnd: dateEnd};
}

export function fromToDSTConversion(dateStart, dateEnd) {
  if (!dateStart || !dateEnd) {
    return {from: null, to: null};
  }

  const startOfDayStart = moment(dateStart).startOf("day");
  const startOfDayEnd = moment(dateEnd).startOf("day");

  if (startOfDayStart.isDST() !== moment(dateStart).isDST()) {
    if (startOfDayStart.isDST()) {
      dateStart = moment(dateStart).subtract(60, "minutes");
    } else {
      dateStart = moment(dateStart).add(60, "minutes");
    }
  }

  if (startOfDayEnd.isDST() !== moment(dateEnd).isDST()) {
    if (startOfDayEnd.isDST()) {
      dateEnd = moment(dateEnd).subtract(60, "minutes");
    } else {
      dateEnd = moment(dateEnd).add(60, "minutes");
    }
  }

  return {
    from: moment(dateStart).diff(startOfDayStart, "minutes"),
    to: moment(dateEnd).diff(startOfDayEnd, "minutes"),
  };
}

//This function is used for a specific case when dates fall in Nov 7 - Nov 13
//Nov 7 0:00:00 - 1667797200000, Nov 13 23:59:59 - 1668401999000
export function unavailabilityDSTHelper(date) {
  if (date > 1667797200000 && date < 1668401999000) {
    date = moment(date).add(1, "hour").toDate();
    return date;
  }
  return date;
}

export function differenceInHours(start, end) {
  const diffInMinutes = moment(end)
    .startOf("seconds")
    .diff(moment(start).startOf("seconds"), "minutes");

  return minutesToHours(diffInMinutes);
}
