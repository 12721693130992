import React, {Component} from "react";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {
  PageHeadings,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import CompaniesTab from "./company-structure-page-tabs/companies-tab";
import {showErrorAlert} from "../../../utils/alert-helper";

class CompanyStructurePage extends Component {
  state = {
    companyStructure: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UMBRELLA_ID} = this.props.shop.location;

    request(`umbrellas/${UMBRELLA_ID}`, "GET")
      .then((companyStructure) => {
        this.setState({companyStructure});
      })
      .catch((err) => {
        showErrorAlert("Error loading company structure", err);
        return false;
      });
  }

  render() {
    const {companyStructure} = this.state;

    if (!companyStructure) {
      return <Loading />;
    }

    const {umbrella} = companyStructure;

    return (
      <div>
        <PageHeadings
          label={`${umbrella.NAME} Structure`}
          description={`Manage the structure of ${umbrella.NAME}`}
        />

        <CompaniesTab
          companyStructure={companyStructure}
          syncState={() => this.syncState()}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(CompanyStructurePage));
