import React, {Component} from "react";
import {
  FormBoolean,
  FormSelect,
  FormInput,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {showErrorNotification} from "../../../utils/notification-helper";
import {showErrorAlert} from "../../../utils/alert-helper";

class TicketTypeDetailsModal extends Component {
  state = {type: null};

  open(type = null) {
    this.setState({type}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createTicketType({
    name,
    type,
    enabled,
    mobile,
    expo,
    color,
    tax,
    autoComplete,
    inProgress,
    fulfillment,
    notifyOnCompleted,
    tips,
  }) {
    let serverTicketType = {
      NAME: name,
      TYPE: type,
      COLOR: color?.slice(1),
      ENABLED: enabled,
      MOBILE_ENABLED: mobile,
      EXPO_ENABLED: expo,
      TAX_DISABLED: tax,
      AUTO_COMPLETE: autoComplete,
      BEGIN_IN_PROGRESS: inProgress,
      FULFILLMENT_INSTRUCTIONS: fulfillment,
      NOTIFY_ON_COMPLETED: notifyOnCompleted,
      SPLIT_TIPS: tips,
    };

    let serverCategory = await request("types/order", "POST", serverTicketType);

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveTicketType({
    name,
    type,
    enabled,
    mobile,
    expo,
    color,
    tax,
    autoComplete,
    inProgress,
    fulfillment,
    notifyOnCompleted,
    tips,
  }) {
    const {type: oldType} = this.state;

    let serverTicketType = {
      NAME: name,
      TYPE: type,
      COLOR: color?.slice(1),
      ENABLED: enabled,
      MOBILE_ENABLED: mobile,
      EXPO_ENABLED: expo,
      TAX_DISABLED: tax,
      AUTO_COMPLETE: autoComplete,
      BEGIN_IN_PROGRESS: inProgress,
      FULFILLMENT_INSTRUCTIONS: fulfillment,
      NOTIFY_ON_COMPLETED: notifyOnCompleted,
      SPLIT_TIPS: tips,
    };

    try {
      let serverCategory = await request(
        "types/order/" + oldType.ID,
        "PATCH",
        serverTicketType
      );

      this.props.updateState({type: {...oldType, ...serverCategory}});
    } catch (e) {
      if (e.error === "ORDER_TYPE_REQUIRED") {
        setTimeout(
          () =>
            showErrorAlert(
              "Cannot Edit Order Type",
              "You must have at least one order type enabled on the point of sale. Please enable a different order type to disable this one."
            ),
          500
        );
      }
    } finally {
      this.modal.close();
    }
  }

  render() {
    const {type} = this.state;

    return (
      <Modal
        buttonLabel={type ? "Save" : "Add"}
        label={type ? "Edit Ticket Type" : "Create Ticket Type"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            type
              ? this.saveTicketType.bind(this)
              : this.createTicketType.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: type?.NAME ?? "",
            type: type?.TYPE ?? "IN-STORE",
            enabled: type?.ENABLED ?? "1",
            mobile: type?.MOBILE_ENABLED ?? "0",
            expo: type?.EXPO_ENABLED ?? "0",
            color: "#" + type?.COLOR ?? "000000",
            tax: type?.TAX_DISABLED ?? "0",
            tips: type?.SPLIT_TIPS ?? "1",
            autoComplete: type?.AUTO_COMPLETE ?? "0",
            inProgress: type?.BEGIN_IN_PROGRESS ?? "0",
            notifyOnCompleted: type?.NOTIFY_ON_COMPLETED ?? "0",
            fulfillment: type?.FULFILLMENT_INSTRUCTIONS ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />

                <FormSelect
                  name="type"
                  label="Type"
                  options={formikOptions}
                  data={[
                    {label: "In Store", value: "IN-STORE"},
                    {label: "Delivery", value: "DELIVERY"},
                    {label: "Mobile", value: "MOBILE"},
                    {label: "DoorDash Drive", value: "DRIVE"},
                    {label: "Delayed Tipping", value: "EXTENDED"},
                    {label: "Ecommerce", value: "SILKOS"},
                  ]}
                  tooltip={{
                    data: [
                      {
                        label: "In Store",
                        data: "An in store order type will appear on the point of sale device during checkout.",
                      },
                      {
                        label: "Delivery",
                        data: "A delivery order type appears on the point of sale delivery screen and is handled by your in house delivery drivers.",
                      },
                      {
                        label: "Mobile",
                        data: "A mobile order type appears on the order app and order website.",
                      },
                      {
                        label: "DoorDash Drive",
                        data: "A DoorDash drive order type appears on the order app and order website. These orders will be delivered through our integration with DoorDash. Please reach out to support to enable this order type.",
                      },
                      {
                        label: "Delayed Tipping",
                        data: "Orders with delayed tipping require manual tip entries after checkout. Consider using delayed tipping orders if you would like to collect tips specified on receipts.",
                      },
                    ],
                  }}
                />

                {type && (
                  <FormBoolean
                    label="Enabled"
                    name="enabled"
                    options={formikOptions}
                  />
                )}

                <FormBoolean
                  label="Enable on mobile"
                  name="mobile"
                  options={formikOptions}
                />

                <FormBoolean
                  tooltip="Tickets won't be fully completed until the expo ticket screen completes the ticket"
                  label="Require Expo Completion"
                  name="expo"
                  options={formikOptions}
                />

                <FormBoolean
                  label="Tip Splitting"
                  name="tips"
                  options={formikOptions}
                />

                <FormBoolean
                  label="Disable Taxes"
                  name="tax"
                  options={formikOptions}
                  tooltip="If set to yes, tickets of this ticket type will not be charged taxes."
                />

                <FormInput
                  options={formikOptions}
                  label="Ticket Color"
                  name="color"
                  type="color"
                />

                <FormBoolean
                  label="Auto Complete tickets when coming in"
                  name="autoComplete"
                  options={formikOptions}
                />

                <FormBoolean
                  label={
                    "POS/KIOSK Checkout Notifies Customer on Ticket Completed"
                  }
                  name={"notifyOnCompleted"}
                  options={formikOptions}
                />

                <FormBoolean
                  label="Start tickets in progress"
                  name="inProgress"
                  options={formikOptions}
                />

                <FormTextArea
                  label="Fulfillment Instructions"
                  name="fulfillment"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TicketTypeDetailsModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default TicketTypeDetailsModal;
