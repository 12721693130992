import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import ColorDropdown from "../../../features/team/color-dropdown";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";

class RemoveRoleModal extends Component {
  state = {role: null};

  open(role = null) {
    this.setState({role}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async removeRole({role: replacementRole}) {
    const {role} = this.state;

    await request("employeeroles/roles/" + role.ID, "DELETE", {
      REPLACEMENT_ROLE_ID: replacementRole,
    });

    this.props.updateState();
    this.modal.close();
  }

  render() {
    const {role} = this.state;

    return (
      <Modal
        tooltip={{data: "Test"}}
        buttonLabel="Save"
        label="Edit Role"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.removeRole.bind(this)}
          initialValues={{
            role: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <RoleDropdown
                  exclude={[role?.ID]}
                  label="Replacement Role"
                  options={formikOptions}
                  name="role"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

RemoveRoleModal.propTypes = {
  onSave: PropTypes.func,
};

export default RemoveRoleModal;
