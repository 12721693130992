import React, {Component} from "react";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

class CouponForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({code, max, maxIndividual}) {
    return {
      coupon: {
        CODE: code,
        NUM_REDEEMS: 0,
        MAX_REDEEMS: max,
        MAX_ACCOUNT_REDEEMS: maxIndividual,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, coupon} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        validationSchema={Yup.object({
          code: Yup.string().required("Code is required"),
        })}
        initialValues={{
          code: coupon?.CODE ?? "",
          max: coupon?.MAX_REDEEMS,
          maxIndividual: coupon?.MAX_ACCOUNT_REDEEMS,
        }}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <FormInput
                options={formikOptions}
                label="Coupon Code"
                name="code"
              />

              <FormInput
                options={formikOptions}
                label="Max Redemptions"
                name="max"
                tooltip={{
                  data: "The total number of times this coupon can be redeemed.",
                }}
              />

              <FormInput
                options={formikOptions}
                label="Max Individual Redemptions"
                name="maxIndividual"
                tooltip={{
                  data: "The total number of times this coupon can be redeemed by a single patron.",
                }}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

CouponForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  coupon: PropTypes.object,
};

export default CouponForm;
