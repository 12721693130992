import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class EmployeeManagementModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {
      requireTimeCardBlessings,
      timeCardBlessingCashDrawerPayouts,
      sendEmployeeTextUponCreation,
      pinProtectTaxToggle,
      pinProtectEditMode,
      pinProtectPOSSettings,
      pinProtectDiscounts,
      pinProtectRefunds,
      mobileClock,
      disableTimeOff,
    } = values;

    let payload = {
      REQUIRE_TIME_CARD_BLESSINGS: requireTimeCardBlessings,
      TIME_CARD_BLESSING_CASH_DRAWER_PAYOUTS: timeCardBlessingCashDrawerPayouts,
      SEND_EMPLOYEE_CREATION_TEXT: sendEmployeeTextUponCreation,
      PIN_PROTECT_TAX_TOGGLE: pinProtectTaxToggle,
      PIN_PROTECT_EDIT_MODE: pinProtectEditMode,
      PIN_PROTECT_POS_SETTINGS: pinProtectPOSSettings,
      PIN_PROTECT_DISCOUNTS: pinProtectDiscounts,
      PIN_PROTECT_REFUNDS: pinProtectRefunds,
      MOBILE_CLOCK_IN: mobileClock,
      DISABLE_TIME_OFF: disableTimeOff,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {
      REQUIRE_TIME_CARD_BLESSINGS,
      TIME_CARD_BLESSING_CASH_DRAWER_PAYOUTS,
      SEND_EMPLOYEE_CREATION_TEXT,
      PIN_PROTECT_TAX_TOGGLE,
      PIN_PROTECT_EDIT_MODE,
      PIN_PROTECT_POS_SETTINGS,
      PIN_PROTECT_DISCOUNTS,
      PIN_PROTECT_REFUNDS,
      MOBILE_CLOCK_IN,
      DISABLE_TIME_OFF,
    } = this.props.shop.settings;

    return (
      <Modal
        label="Employee Management Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          initialValues={{
            requireTimeCardBlessings: REQUIRE_TIME_CARD_BLESSINGS,
            timeCardBlessingCashDrawerPayouts:
              TIME_CARD_BLESSING_CASH_DRAWER_PAYOUTS,
            sendEmployeeTextUponCreation: SEND_EMPLOYEE_CREATION_TEXT,
            pinProtectTaxToggle: PIN_PROTECT_TAX_TOGGLE,
            pinProtectEditMode: PIN_PROTECT_EDIT_MODE,
            pinProtectPOSSettings: PIN_PROTECT_POS_SETTINGS,
            pinProtectDiscounts: PIN_PROTECT_DISCOUNTS,
            pinProtectRefunds: PIN_PROTECT_REFUNDS,
            mobileClock: MOBILE_CLOCK_IN,
            disableTimeOff: DISABLE_TIME_OFF,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="requireTimeCardBlessings"
                  label="Require Tip Confirmation"
                  options={formikOptions}
                />

                <FormBoolean
                  label={"Tip Confirmation Creates Cash Drawer Payouts"}
                  name="timeCardBlessingCashDrawerPayouts"
                  options={formikOptions}
                />

                <FormBoolean
                  label={"Send Employees a Text Upon Creation"}
                  name={"sendEmployeeTextUponCreation"}
                  options={formikOptions}
                />

                <FormBoolean
                  label={"PIN Protect Tax Toggle"}
                  name={"pinProtectTaxToggle"}
                  options={formikOptions}
                />

                <FormBoolean
                  label={"PIN Protect Edit Mode"}
                  name={"pinProtectEditMode"}
                  options={formikOptions}
                />

                <FormBoolean
                  label={"PIN Protect the POS Settings Page"}
                  name={"pinProtectPOSSettings"}
                  options={formikOptions}
                />

                <FormBoolean
                  label={"PIN Protect Discounts"}
                  name={"pinProtectDiscounts"}
                  options={formikOptions}
                />

                <FormBoolean
                  label="PIN Protect Refunds"
                  name="pinProtectRefunds"
                  options={formikOptions}
                />

                <FormBoolean
                  label={"Mobile Clock In"}
                  name={"mobileClock"}
                  options={formikOptions}
                />

                <FormBoolean
                  label={"Disable Time Off"}
                  name={"disableTimeOff"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  EmployeeManagementModal
);
