import React, {Component} from "react";
import {
  FormBoolean,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import SchedulerTabModal from "../../../modals/shop/shop-settings/scheduler-tab-modal";
import {SHIFT_VIEWS_DATA} from "../../scheduling/scheduler";

class SchedulerTab extends Component {
  render() {
    let {
      SCHEDULER_DEFAULT_VIEW,
      SCHEDULER_SHIFT_CONFIRMATION,
      SCHEDULER_DURATION_DISPLAY,
      SCHEDULER_FIRST_DAY,
      EMAIL_SCHEDULE,
      SCHEDULER_OPEN_SHIFT_APPROVAL,
    } = this.props.shop.settings;

    return (
      <div>
        <SchedulerTabModal ref={(e) => (this.schedulerModal = e)} />

        <TwoColumnList
          label="Scheduler"
          description="Configure settings for your scheduler"
          data={[
            {
              label: "Default scheduler to Role View",
              value: SHIFT_VIEWS_DATA.find(
                (data) => data.value === SCHEDULER_DEFAULT_VIEW
              ).label,
              tooltip:
                "This allows you to set the default view of the scheduler. You can still change between these views from the scheduler with the dropdown selection.",
            },
            {
              label: "Enable Shift Confirmation",
              value: SCHEDULER_SHIFT_CONFIRMATION,
              type: "bool",
              tooltip:
                "When enabled, then employees will receive a confirmation prompt when new shifts are published, and acknowledged shifts will appear on the scheduler with a checkmark.",
            },
            {
              label: "First Day",
              value: FIRST_DAY_MAP[SCHEDULER_FIRST_DAY],
              tooltip:
                "The day that appears as the first day of the week on the scheduler.",
            },
            {
              label: "Duration Display on Shifts",
              value: SCHEDULER_DURATION_DISPLAY,
              type: "bool",
              tooltip:
                "When enabled, shifts on the scheduler will display duration in hours",
            },
            {
              value: SCHEDULER_OPEN_SHIFT_APPROVAL,
              label: "Enable Approval for Open Shifts",
              type: "bool",
              tooltip:
                "When enabled, picking up open shifts will require manager approval",
            },
            {
              label: "Weekly Schedule Emails",
              value: EMAIL_SCHEDULE,
              type: "bool",
              tooltip:
                "When enabled, employees will be sent a recurring weekly schedule email with upcoming shifts and open shifts",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.schedulerModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

const FIRST_DAY_MAP = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export default setupReduxConnection(["shop"])(SchedulerTab);
