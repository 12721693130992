import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Table,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {capitalize} from "lodash/string";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import {TRANSACTION_TYPES} from "../../../utils/finance-constants";
import {CurrencyDollarIcon, DownloadIcon} from "@heroicons/react/solid";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";
import {getPayoutTicketPeriod} from "../../../utils/payout-util";
import {showErrorAlert} from "../../../utils/alert-helper";
import {CSVLink} from "react-csv";

class PayoutsPage extends Component {
  state = {loading: true, balance: null, payouts: null};

  async componentDidMount() {
    let {balance, data} = await request("stripe/payouts/dash", "GET");
    let payoutDbEntries = await request("payouts", "GET");

    data = data.map((item, i) => {
      let {startEpoch, endEpoch} = getPayoutTicketPeriod(item.created);

      return {
        id: payoutDbEntries.find((entry) => item.id === entry.OBJECT_ID)
          ?.UNIQUE_ID,
        description: item.statement_descriptor,
        date: moment.utc(item.arrival_date * 1000).format("MMM D, YYYY"),
        type: TRANSACTION_TYPES[item.type],
        amount: "$" + toDollars(item.amount),
        status: capitalize(item.status),
        // period: item.arrival_date * 1000 > 1669809600000? `${moment(startEpoch).format("h:mm A MM/DD")} - ${moment(endEpoch).format("h:mm A MM/DD")}` : "-"
      };
    });

    // let payouts = payoutDbEntries.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED).map(({UNIQUE_ID, DATE_ARRIVED, TOTAL}, i) => {
    //   // let {startEpoch, endEpoch} = getPayoutTicketPeriod(item.created);
    //
    //   let arrived = DATE_ARRIVED < moment().valueOf();
    //
    //   return {
    //     uniqueId: UNIQUE_ID,
    //     description: "",
    //     date: moment.utc(DATE_ARRIVED).format("MMM D, YYYY"),
    //     amount: toDollars(TOTAL, true),
    //     status: arrived ? "Paid":"Pending",
    //     period: "todo"
    //     // period: item.arrival_date * 1000 > 1669809600000? `${moment(startEpoch).format("h:mm A MM/DD")} - ${moment(endEpoch).format("h:mm A MM/DD")}` : "-"
    //   };
    // });

    this.setState({loading: false, balance, payouts: data});
  }

  getCsvData(payoutsData) {
    const {location} = this.props.shop;

    const csvHeaders = [
      {label: "Date", key: "DATE"},
      {label: "Description", key: "DESC"},
      {label: "Status", key: "STATUS"},
      {label: "Payout Amount", key: "AMOUNT"},
      {label: "Location", key: "LOCATION_NAME"},
    ];

    const csvData = payoutsData.map(({amount, date, description, status}) => {
      return {
        AMOUNT: amount,
        DESC: description,
        DATE: date,
        STATUS: status,
        LOCATION_NAME: location.NAME,
      };
    });

    return {csvHeaders, csvData};
  }

  renderCashAccountBanner() {
    return (
      <div
        className="rounded-md shadow-sm bg-indigo-50 p-4 mt-3 cursor-pointer"
        onClick={() => this.props.router.navigate("/cash/setup")}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CurrencyDollarIcon
              className="h-5 w-5 text-indigo-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-indigo-700">
              Learn More About Dripos Cash Account
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderSetupStripe() {
    return (
      <div className={"flex flex-col text-center py-40"}>
        <div className="text-lg font-bold">
          To receive payouts, please setup your Stripe account
        </div>
        <div
          className="font-bold text-indigo-700 cursor-pointer"
          onClick={() => this.props.router.navigate("settings")}
        >
          on the Payout Settings page
        </div>
      </div>
    );
  }

  render() {
    let {loading, balance, payouts} = this.state;
    let {CONNECT_ID} = this.props.shop.location;

    if (!CONNECT_ID) {
      return this.renderSetupStripe();
    }

    if (loading) {
      return <Loading />;
    }

    const {csvData, csvHeaders} = this.getCsvData(payouts);

    return (
      <div>
        <PageHeadings label="Payouts" />

        <div>
          <Card>
            <div className="flex flex-row px-10 py-5">
              <div className="flex flex-col">
                <div className="font-bold text-xl">
                  {toDollars(balance.available[0].amount, true)}
                </div>
                <div className="text-sm font-semibold text-gray-700">
                  In Next Payout
                </div>
              </div>

              <div className="flex flex-col ml-16">
                <div className="font-bold text-xl">
                  {toDollars(balance.pending[0].amount, true)}
                </div>
                <div className="text-sm font-semibold text-gray-700">
                  Pending to be Included in Next Payout
                </div>
              </div>
            </div>
          </Card>

          <Card
            label="Payout History"
            button={{
              type: "gray",
              className: "px-0 pt-0 pb-0 border-hidden",
              label: (
                <CSVLink
                  filename={`payouts.csv`}
                  data={csvData}
                  headers={csvHeaders}
                >
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    <DownloadIcon className="h-5 w-5" />
                  </button>
                </CSVLink>
              ),
            }}
          >
            {!payouts && <Loading />}

            {payouts && (
              <Table
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => {
                      if (row.id) {
                        this.props.router.navigate(`/payouts/${row.id}`);
                      } else {
                        showErrorAlert(
                          "No Breakdown",
                          "A breakdown for this payout is not available.",
                          "Ok"
                        );
                      }
                    },
                  },
                ]}
                pagination
                data={payouts}
                columns={[
                  {
                    label: "Arrival Date",
                    value: "date",
                  },
                  {
                    label: "Description",
                    value: "description",
                  },
                  {
                    label: "Status",
                    value: "status",
                  },
                  {
                    label: "Amount",
                    value: "amount",
                  },
                ]}
              />
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PayoutsPage));
