import React, {Component} from "react";
import {
  getBreakOptions,
  getExtras,
  getPayrollDraft,
} from "../../../../utils/payroll-helper";
import LoadingSpinner from "../../../../components/loading-spinner";
import {setupReduxConnection} from "../../../../redux";
import {showErrorAlert} from "../../../../utils/alert-helper";
import moment from "moment-timezone";
import PayrollRunTable from "../payroll-run-table";
import {RUN_MODES} from "../../../../redux/payroll";
import {withRouter} from "../../../../utils/navigation";

class DraftPayrollComponent extends Component {
  state = {isLoading: true, payrollDraft: null, breakOptions: null};

  componentDidMount() {
    this.refresh();
  }

  handleClick = () => {
    let {isLoading} = this.state;
    let {EMPLOYEE_TIMESHEET_BREAKDOWN, SALARIED_EMPLOYEES_BREAKDOWN} =
      this.state.payrollDraft;

    let isEmpty =
      EMPLOYEE_TIMESHEET_BREAKDOWN.length === 0 &&
      SALARIED_EMPLOYEES_BREAKDOWN.length == 0;

    return !isEmpty && !isLoading;
  };

  refresh = () => {
    let {currentPayrun} = this.props.payroll;
    this.setState({loading: true});

    getBreakOptions().then((breakOptions) => {
      this.setState({breakOptions});
    });

    getPayrollDraft(currentPayrun)
      .then(async (payrollDraft) => {
        this.setState({isLoading: false, payrollDraft}, () =>
          this.createSkippedEmployeeAlert()
        );
      })
      .catch(async (error) => {
        console.log(error);
        await showErrorAlert(
          "Error",
          "Please contact support at (781)-583-3699 for more information"
        ).then((e) => this.props.router.navigate("/payroll"));
      });
  };

  createSkippedEmployeeAlert = async () => {
    let {SKIPPED_EMPLOYEE_NAMES} = this.state.payrollDraft;

    if (SKIPPED_EMPLOYEE_NAMES.length === 0) {
      return;
    }

    let employeeNamesStr = SKIPPED_EMPLOYEE_NAMES.reduce((str, item) => {
      return (str += item + ", ");
    }, "");
    employeeNamesStr = employeeNamesStr.substring(
      0,
      employeeNamesStr.length - 2
    );

    await showErrorAlert(
      "Employees Skipped",
      <div>
        <div>
          {`${employeeNamesStr} will be skipped
          on this payroll as they have not completed their onboarding process.`}
        </div>
        <div className="mt-2">
          {`You may still run this payroll and pay them at a later date via
          Off-Cycle`}
        </div>
      </div>,
      "Ok"
    );
  };

  refreshExtras = async () => {
    let {payrollDraft} = this.state;

    payrollDraft.EXTRAS = await getExtras(RUN_MODES.REGULAR);

    this.setState({payrollDraft});
    return true;
  };

  renderTimesheetBreakdown() {
    let {
      EMPLOYEE_TIMESHEET_BREAKDOWN,
      SALARIED_EMPLOYEES_BREAKDOWN,
      OTHER_EMPLOYEE_BREAKDOWN,
      EXTRAS,
      CHECK_PAYROLL_ID,
      DBA_LOCATIONS,
    } = this.state.payrollDraft;
    const {breakOptions} = this.state;

    EMPLOYEE_TIMESHEET_BREAKDOWN = EMPLOYEE_TIMESHEET_BREAKDOWN.sort((a, b) => {
      return a.FULL_NAME - b.FULL_NAME;
    });

    let finalBreakdown = [
      ...SALARIED_EMPLOYEES_BREAKDOWN,
      ...EMPLOYEE_TIMESHEET_BREAKDOWN,
      ...OTHER_EMPLOYEE_BREAKDOWN,
    ];

    return (
      <div>
        <PayrollRunTable
          checkPayrollId={CHECK_PAYROLL_ID}
          breakdown={finalBreakdown}
          extras={EXTRAS}
          refreshExtras={this.refreshExtras}
          expandSummary={true}
          dbaLocations={DBA_LOCATIONS}
          breakOptions={breakOptions}
        />
      </div>
    );
  }

  render() {
    let {isLoading} = this.state;

    if (isLoading) {
      return (
        <div className="py-10">
          <LoadingSpinner />
        </div>
      );
    }

    let {PAYDAY, APPROVAL_DEADLINE} = this.props.payroll.currentPayrun;

    let needBy = moment(1632182400000).format("h:mm A");
    let paydayMoment = moment(PAYDAY);
    let approvalDeadlineMoment = moment(APPROVAL_DEADLINE);

    return (
      <div className="p-6">
        <div className="text-xl font-semibold">Hours & Additional Earnings</div>

        <div className="block text-sm text-gray-700 font-normal mb-5">
          <span>{`To pay your employees with direct deposit on `}</span>

          <span className="font-semibold">{`${paydayMoment.format(
            "dddd MMM, DD"
          )}`}</span>

          <span>{` you'll need to run payroll by `}</span>

          <span className="font-semibold">{`${needBy} on ${approvalDeadlineMoment.format(
            "dddd MMM, DD"
          )}.`}</span>
          <span>{` If you miss this deadline, their direct deposits will be delayed.`}</span>
        </div>

        {this.renderTimesheetBreakdown()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "payroll"])(
  withRouter(DraftPayrollComponent)
);
