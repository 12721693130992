import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {request} from "../../../utils/request";

class ProcessesDropdown extends Component {
  state = {processes: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    request("checklists/onboarding/", "GET").then((data) => {
      const processes = data.map((process) => {
        return {
          id: process.ID,
          unique: process.UNIQUE_ID,
          label: process.NAME,
        };
      });

      this.setState({processes});
    });
  }

  render() {
    const {processes} = this.state;
    const {name} = this.props;

    return (
      <ComboBox
        label={"Process"}
        data={processes}
        name={name}
        {...this.props}
      />
    );
  }
}

export default ProcessesDropdown;
