import TableData from "./table-data";
import Fuse from "fuse.js";

class RawTableData extends TableData {
  raw = [];

  constructor({
    data,
    limit,
    filters,
    sort,
    sortDesc,
    pagination,
    columns,
    search,
    searchFields,
    rows,
  }) {
    super(data, limit);

    this.count = data ? data.length : 0;
    this.pagination = pagination;
    this.sortDesc = sortDesc;
    this.filters = filters;
    this.limit = rows;
    this.sort = sort;
    this.cursor = 0;

    for (let filter of filters) {
      if (filter.onFilter) {
        data = filter.onFilter(filter.data, data || []);
      }
    }

    if (search && search?.length > 0) {
      data = new Fuse(data, {
        keys: searchFields,
        useExtendedSearch: true,
        threshold: 0.2,
      })
        .search(search)
        .map(({item}) => item);
    }

    if (sort && data.length > 0) {
      const column = columns.find((item) => item.value === sort);

      if (column) {
        const testColumn = data[0][column.value];

        if (column.onSort) {
          column.onSort(data);
        } else if (isNaN(testColumn)) {
          data.sort((a, b) => {
            if (sortDesc) {
              return a[sort].localeCompare(b[sort]);
            } else {
              return b[sort].localeCompare(a[sort]);
            }
          });
        } else {
          if (sortDesc) {
            data.sort((a, b) => b[sort] - a[sort]);
          } else {
            data.sort((a, b) => a[sort] - b[sort]);
          }
        }
      }
    }

    if (data) {
      this.count = data.length;
    }

    this.raw = data;
  }

  async fetchInitial(cursor = 0) {
    this.cursor = parseInt(cursor || 0);

    if (!this.pagination) {
      return {
        data: this.raw,
      };
    }

    const maxCursor = Math.min(
      this.cursor + this.limit,
      this.raw ? this.raw.length : 0
    );

    return {
      cursor: this.cursor,
      data: this.raw === null ? null : this.raw.slice(this.cursor, maxCursor),
      hasMore: this.raw === null ? false : maxCursor !== this.raw?.length,
      hasPrevious: this.cursor > 0,
      count: this.count,
      start: this.cursor + 1,
      end: maxCursor,
    };
  }

  async fetchMore() {
    this.cursor += this.limit;

    const maxCursor = Math.min(this.cursor + this.limit, this.raw.length);

    const tablePayload = {
      data: this.raw.slice(this.cursor, maxCursor),
      hasMore: maxCursor !== this.raw.length,
      hasPrevious: true,
      cursor: this.cursor,
      start: this.cursor + 1,
      end: maxCursor,
    };

    return tablePayload;
  }

  async fetchPrevious() {
    const minCursor = Math.max(this.cursor - this.limit, 0);

    const tablePayload = {
      hasMore: true,
      hasPrevious: minCursor !== 0,
      data: this.raw.slice(minCursor, this.cursor),
      cursor: minCursor,
      start: minCursor + 1,
      end: this.cursor,
    };

    this.cursor = minCursor;

    return tablePayload;
  }

  async refetch() {
    const maxCursor = Math.min(this.cursor + this.limit, this.raw.length);

    const tablePayload = {
      data: this.raw.slice(this.cursor, maxCursor),
      hasMore: maxCursor !== this.raw.length,
      hasPrevious: this.cursor > 0,
      cursor: this.cursor,
      start: this.cursor + 1,
      end: maxCursor,
    };

    return tablePayload;
  }
}

export default RawTableData;
