import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";
import {setupReduxConnection} from "../../../../redux";

class OnboardingCompletePage extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.onboarding-complete-page." + path;
  }

  render() {
    const {account} = this.props.user;

    const employeeName = account.FULL_NAME
      ? account.FULL_NAME.split(" ")[0]
      : "";

    return (
      <div className={"my-10 "}>
        <div
          className={
            "mx-auto flex items-center justify-center font-medium text-3xl text-gray-800 my-10"
          }
        >
          <Trans
            i18nKey={this.getI18NextKey("thank-you.label")}
            values={{employeeName}}
          />
        </div>

        <div
          className={
            "mx-auto flex items-center justify-center font-medium text-2xl text-gray-800 my-10"
          }
        >
          <Trans i18nKey={this.getI18NextKey("next.label")} />
        </div>

        <div className="flex items-center justify-center ">
          <a
            href={"https://apps.apple.com/us/app/dripos-dashboard/id1502503322"}
          >
            <FontAwesomeIcon
              className={
                "text-5xl text-indigo-500 hover:text-indigo-800 hover:cursor-pointer transition-colors ease-in-out duration-300"
              }
              icon="fa-brands fa-app-store-ios"
            />
          </a>

          <a
            href={
              "https://play.google.com/store/apps/details?id=com.corkdrinks.dripdashboard&hl=en_US&gl=US"
            }
          >
            <FontAwesomeIcon
              className={
                "text-4xl px-10 text-indigo-500 hover:text-indigo-800 hover:cursor-pointer transition-colors ease-in-out duration-300"
              }
              icon="fa-brands fa-google"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(OnboardingCompletePage);
