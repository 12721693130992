import React from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {setupRealHistory} from "@frostbyte-technologies/frostbyte-tailwind";

export const REAL_HISTORY = [window.location.pathname + window.location.search];

setupRealHistory(REAL_HISTORY);

export function withRouter(Component) {
  function ComponentWithRouterProp({forwardedRef, ...props}) {
    let location = useLocation();
    let softNavigate = useNavigate();
    let params = useParams();

    const navigate = (...args) => {
      const {pathname, search} = window.location;

      REAL_HISTORY.push(pathname + search);

      softNavigate(...args);
    };

    const navigateNewTab = (...args) => {
      const {pathname, search} = window.location;
      REAL_HISTORY.push(pathname + search);

      window.open(...args, "_blank");
    };

    return (
      <Component
        {...props}
        ref={forwardedRef}
        router={{location, navigate, navigateNewTab, params}}
      />
    );
  }

  return React.forwardRef((props, ref) => {
    return <ComponentWithRouterProp {...props} forwardedRef={ref} />;
  });
}
