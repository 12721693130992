import React, {Component} from "react";

class FormRow extends Component {
  render() {
    return (
      <div className="flex flex-col xxxs:space-y-2 sm:flex-row sm:justify-between sm:space-x-4">
        {this.props.children}
      </div>
    );
  }
}

export default FormRow;
