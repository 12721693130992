import React, {Component} from "react";
import {
  PageHeadings,
  Card,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import CashDrawerTable from "../../../tables/operations/cash-drawer-table";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import moment from "moment";
import TipBucketsTable from "../../../tables/operations/tip-buckets-table";
import StoreBanksTable from "../../../tables/operations/store-banks-table";

class CashPage extends Component {
  state = {buckets: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("operations/buckets", "GET", {
      DATE_START: moment().subtract(31, "day").valueOf(),
      DATE_END: moment().valueOf(),
    }).then((buckets) => {
      this.setState({buckets});
    });
  }

  render() {
    const {buckets} = this.state;

    return (
      <>
        <PageHeadings
          label="Cash Management"
          description="Track cash in & out flows throughout the day."
        />

        <Tab
          data={[
            {label: "Cash Drawers", id: "drawers"},
            {label: "Tip Buckets", id: "buckets"},
            {label: "Store Banks", id: "banks"},
          ]}
        >
          {(id) => {
            if (id === "drawers") {
              return (
                <Card
                  label="Cash Drawers"
                  button={{
                    label: "View All",
                    onClick: () => this.props.router.navigate("/drawers"),
                  }}
                >
                  <CashDrawerTable />
                </Card>
              );
            }

            if (id === "buckets") {
              return (
                <Card
                  label="Tip Buckets"
                  button={{
                    label: "View All",
                    onClick: () => this.props.router.navigate("/buckets"),
                  }}
                >
                  <TipBucketsTable buckets={buckets} />
                </Card>
              );
            }

            if (id === "banks") {
              return (
                <Card
                  label={"Store Banks"}
                  button={{
                    label: "View All",
                    onClick: () => this.props.router.navigate("/banks"),
                  }}
                >
                  <StoreBanksTable />
                </Card>
              );
            }
          }}
        </Tab>
      </>
    );
  }
}

export default withRouter(CashPage);
