import React, {Component} from "react";
import {
  PageHeadings,
  Tab,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import CouponsTable from "../../../tables/marketing/deals/coupons-table";
import PromotionsTable from "../../../tables/marketing/deals/promotions-table";
import {withRouter} from "../../../utils/navigation";

class DealsPage extends Component {
  state = {promotions: null, coupons: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("promotions/coupons", "GET", {ACTIVE: 1}).then((coupons) => {
      coupons = coupons.filter((c) => !c?.PROMOTION?.TEXT_CAMPAIGN_ID);
      this.setState({coupons});
    });

    request("promotions", "GET", {ACTIVE: 1}).then((promotions) => {
      this.setState({promotions});
    });
  }

  render() {
    const {promotions, coupons} = this.state;

    return (
      <div>
        <PageHeadings
          label="Deals"
          description="Create promotions and coupons for your customers"
          button={{
            theme: "primary",
            label: "Create",
            icon: "plus",
            type: "list",
            onChange: ({id}) => {
              if (id === "coupon") {
                this.props.router.navigate("/coupon");
              }

              if (id === "promotion") {
                this.props.router.navigate("/promotion");
              }
            },
            items: [
              {
                id: "promotion",
                label: "Promotion",
                description: "Create a promotional event for all customers.",
              },
              {
                id: "coupon",
                label: "Coupon",
                description:
                  "Create a coupon that your customers can redeem with a code.",
              },
            ],
          }}
        />

        <Tab
          data={[
            {
              id: "promotions",
              label: "Promotions",
              badge: promotions ? "" + promotions.length : "",
            },
            {
              id: "coupons",
              label: "Coupons",
              badge: coupons ? "" + coupons.length : "",
            },
          ]}
        >
          {(id) => {
            if (id === "coupons") {
              return (
                <Card
                  label="Coupons"
                  description="Coupons that your customers can redeem"
                  tooltip={{
                    label: "Coupons",
                    data: "Coupons can be redeemed by patrons on the Point of Sale, Order App, and Order Website.",
                  }}
                  button={{
                    label: "View All",
                    onClick: () => this.props.router.navigate("/coupons"),
                  }}
                >
                  <CouponsTable coupons={coupons} />
                </Card>
              );
            }

            if (id === "promotions") {
              return (
                <>
                  <Card
                    label="Promotions"
                    description="Promotional events for all of your customers"
                    tooltip={{
                      label: "Promotions",
                      data: "Promotions are mobile order based discounts that patrons can activate on your Dripos Order Website and Order App.",
                    }}
                    button={{
                      label: "View All",
                      onClick: () => this.props.router.navigate("/promotions"),
                    }}
                  >
                    <PromotionsTable promotions={promotions} />
                  </Card>
                </>
              );
            }

            return <div>table here</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(DealsPage);
