import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

class ReaderVersionsPage extends Component {
  state = {versions: null};

  componentDidMount() {
    request("readers/deployments/versions", "GET").then((versions) => {
      this.setState({versions});
    });
  }

  render() {
    const {versions} = this.state;

    if (versions === null) {
      return <Loading />;
    }

    return (
      <>
        <PageHeadings
          label="Versions"
          description="Create and manage companies"
        />

        <Table
          pagination
          data={versions}
          className="mt-4"
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/admin/company/" + row.ID);
              },
            },
          ]}
          columns={[
            {
              width: 1,
              value: "id",
              label: "ID",
            },
            {
              width: 1,
              value: "android_apk",
              label: "Status",
              format: (android_apk) => android_apk.signature_data.status,
            },
            {
              value: "android_apk",
              label: "Version Name",
              format: (android_apk) => android_apk.version_name,
            },
            {
              value: "android_apk",
              label: "Version Code",
              format: (android_apk) => android_apk.version_code,
            },
            {
              value: "compatible_device_types",
              label: "Devices",
              format: (compatible_device_types) =>
                compatible_device_types.join(", "),
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(ReaderVersionsPage);
