import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class CancelCardModal extends Component {
  open() {
    this.slide.open();
  }

  render() {
    return (
      <Modal
        label="Terminate Card"
        buttonLabel={"Yes"}
        buttonOnClick={() => {
          this.slide.close();
          this.props.confirm();
        }}
        ref={(e) => (this.slide = e)}
      >
        <div>Are you sure you want to terminate this card?</div>
      </Modal>
    );
  }
}

export default CancelCardModal;
