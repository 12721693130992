import {Attachments} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";

export const MODIFIER_TYPE_FIELDS = [
  {
    value: 0,
    label: "Single Select",
    secondary: "Customers can select only one option",
  },
  {
    value: 1,
    label: "Single Select w/ Quantity",
    secondary:
      "Customers can select only one option but can change the quantity of that option",
  },
  {
    label: "Multi-Select",
    secondary: "Customers can select multiple options",
    value: 2,
  },
  {
    label: "Multi-Select w/ Quantity",
    secondary:
      "Customers can select multiple options and change the quantity of that option",
    value: 3,
  },
  {
    label: "Multi-Select w/ Fixed Price",
    secondary:
      "Customers can select multiple options but at one price, does not stack with multiple options",
    value: 4,
  },
  // {label: "Pricing Variant", secondary: "Changes base price", value: 5},
];

export function fetchModifierById(id) {
  return MODIFIER_TYPE_FIELDS.find((item) => item.value === parseInt(id));
}

export const PRICING_MODIFIER_FIELDS = [
  {label: "Full name", value: "Margot Foster"},
  {
    label: "Application for",
    value: "Backend Developer",
  },
  {label: "Email address", value: "margotfoster@example.com"},
  {label: "Salary expectation", value: "$120,000"},
  {
    label: "About",
    value:
      "Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.",
  },
  {
    label: "Attachments",
    value: <Attachments data={[{label: "My-Download.png"}]} />,
  },
];

export function fetchPricingModifier(product) {
}
