import React, {Component} from "react";
import {Button} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../utils/navigation";

class AdminPage extends Component {
  render() {
    return (
      <div>
        <Button
          label="Sales"
          onClick={() => this.props.router.navigate("/track/sales")}
        />

        <Button
          label="Success"
          onClick={() => this.props.router.navigate("/track/success")}
        />
      </div>
    );
  }
}

export default withRouter(AdminPage);
