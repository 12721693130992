import React, {Component} from "react";
import {
  FormInput,
  Modal,
  FormCheck,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";

import moment from "moment-timezone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import FormTimeSelect from "../../components/form-time-select";
import * as Yup from "yup";
import {checkDSTConversion, fromToDSTConversion} from "../../utils/time-helper";

class EventModal extends Component {
  state = {event: null, newEvent: false};

  openWithDates(start, end, allDay) {
    this.setState(
      {
        event: {DATE_START: start, DATE_END: end},
        newEvent: true,
        allDay: allDay,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      }
    );
  }

  open(event = null) {
    if (event === null) {
      this.setState({newEvent: true, allDay: true});
    }
    this.setState({event, newEvent: false, allDay: !event?.DATE_END}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveEvent({name, date, from, to, allDay, description}) {
    const {dateStart, dateEnd} = checkDSTConversion(date, from, to);

    const payload = {
      NAME: name,
      DATE_START: dateStart.valueOf(),
      DATE_END: allDay ? null : dateEnd.valueOf(),
      DESCRIPTION: description,
    };

    await request("scheduling/event/", "POST", payload);

    this.modal.close();
  }

  async updateEvent({name, date, from, to, allDay, description}) {
    const {ID: eventId} = this.state.event;

    const {dateStart, dateEnd} = checkDSTConversion(date, from, to);

    const payload = {
      NAME: name,
      DATE_START: dateStart.valueOf(),
      DATE_END: allDay ? null : dateEnd.valueOf(),
      DESCRIPTION: description,
    };

    await request("scheduling/event/" + eventId, "PATCH", payload);

    this.modal.close();
  }

  async deleteEvent() {
    const {ID: eventId} = this.state.event;

    await request("scheduling/event/" + eventId, "DELETE", null);

    this.modal.close();
  }

  render() {
    const {event, newEvent, allDay} = this.state;

    // Explanation: from is the difference between the start time and start of that day
    // to is either the difference if it exists or one more than the start time if not
    const {from, to} = fromToDSTConversion(event?.DATE_START, event?.DATE_END);
    const initialVals = {
      name: event?.NAME,
      date: moment(event?.DATE_START).startOf("day").valueOf(),
      from,
      to,
      allDay,
      description: event?.DESCRIPTION,
    };

    return (
      <Modal
        buttonLabel="Save"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        label={!newEvent ? "Edit Event" : "Add Event"}
        deleteLabel={newEvent ? undefined : "Delete Event"}
        deleteOnClick={newEvent ? undefined : () => this.deleteEvent()}
      >
        <Formik
          onSubmit={
            newEvent ? this.saveEvent.bind(this) : this.updateEvent.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={validationSchema}
          initialValues={initialVals}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />
                <FormInput
                  label="Description"
                  name="description"
                  options={formikOptions}
                />

                <FormDateTimeSelect
                  name="date"
                  label={"Date"}
                  options={formikOptions}
                  buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                  hideTime={true}
                />

                <div className={"mt-3 mb-3 ml-1"}>
                  <FormCheck
                    name="allDay"
                    label="All Day"
                    options={formikOptions}
                    onChangeSoft={() => {
                      this.setState({allDay: !allDay});
                    }}
                  />
                </div>

                {!allDay && (
                  <div className="flex flex-row">
                    <FormTimeSelect
                      className="mr-2"
                      name="from"
                      label={"From"}
                      options={formikOptions}
                    />

                    <div className="mt-8">-</div>

                    <FormTimeSelect
                      className="ml-2"
                      name="to"
                      label="To"
                      options={formikOptions}
                    />
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EventModal;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please set a name for this event"),
  allDay: Yup.boolean(),
  from: Yup.number()
    .nullable(true)
    .when("allDay", {
      is: false,
      then: Yup.number().required("Please select a valid date"),
    }),
  to: Yup.number()
    .nullable(true)
    .when("allDay", {
      is: false,
      then: Yup.number()
        .required("Please select a valid date")
        .moreThan(Yup.ref("from"), "End date should be after the start date"),
    }),
});
