import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  decimalToDollars,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import RolesDropdown from "../../dropdowns/team/roles-dropdown";
import {showLoadingConfirmAlert} from "../../utils/alert-helper";

class PayRateModal extends Component {
  state = {rate: null, employee: null};

  open(rate = null, employee = null) {
    this.setState({rate, employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveRate({name, type, amount, start, end}) {
    const {role, soft, employee} = this.props;
    const {rate} = this.state;

    const payRatePayload = {
      NAME: name,
      TYPE: type === "-1" ? null : type,
      AMOUNT: decimalToDollars(amount),
      CONTENT: null,
      ROLE_ID: role ? role.ID : null,
    };

    if (employee) {
      payRatePayload.ROLE_ID = rate.ROLE_ID ? rate.ROLE_ID : rate.ROLE_ID_ALT;
    }

    if (type === "TIME_RANGE") {
      const startOfDay = moment().startOf("day");

      const dateStart = moment(start, "hh:mm").diff(startOfDay, "minutes");
      const dateEnd = moment(end, "hh:mm").diff(startOfDay, "minutes");

      payRatePayload.CONTENT = Math.abs(dateStart) + "|" + Math.abs(dateEnd);
    }

    if (soft) {
      this.props.updateState(rate.ID, payRatePayload);
      return this.modal.close();
    }

    let serverPayRate = await request(
      "pay/rates/" + rate.ID,
      "PATCH",
      payRatePayload
    );

    this.props.updateState(serverPayRate);
    this.modal.close();
  }

  async createRate({name, type, amount, start, end, role: roleInput}) {
    const {role, soft, employee} = this.props;

    const payRatePayload = {
      NAME: name,
      TYPE: type === "-1" ? null : type,
      AMOUNT: decimalToDollars(amount),
      CONTENT: null,
      ROLE_ID: role ? role.ID : roleInput,
      EMPLOYEE_ID: employee ? employee.ID : null,
      ADD_TO_ROLE: !employee,
    };

    if (type === "TIME_RANGE") {
      const startOfDay = moment().startOf("day");

      const dateStart = moment(start, "hh:mm").diff(startOfDay, "minutes");
      const dateEnd = moment(end, "hh:mm").diff(startOfDay, "minutes");

      payRatePayload.CONTENT = Math.abs(dateStart) + "|" + Math.abs(dateEnd);
    }

    if (soft) {
      this.props.addState({ID: randomString(12), ...payRatePayload});
      return this.modal.close();
    }

    let serverPayRate = await request("pay/rates", "POST", payRatePayload);

    this.props.addState(serverPayRate);
    this.modal.close();
  }

  async deleteRate() {
    const {rate} = this.state;
    const {soft} = this.props;

    showLoadingConfirmAlert(
      "Delete Rate",
      "Are you sure you want to delete this pay rate?"
    )
      .then(async (close) => {
        if (soft) {
          this.props.updateState(rate.ID);
          return this.modal.close();
        }

        await request("pay/rates/" + rate.ID, "DELETE", {});

        close();

        this.props.updateState(rate.ID);
        this.modal.close();
      })
      .catch((err) => {
        this.modal.close();
      });
  }

  renderContent(formikOptions) {
    const {type} = formikOptions.values;

    if (type === "TIME_RANGE") {
      return (
        <div>
          <FormInput
            type="time"
            name="start"
            label="Range Start"
            options={formikOptions}
          />

          <FormInput
            name="end"
            type="time"
            label="Range End"
            options={formikOptions}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    const {rate, employee} = this.state;

    const initialValues = {
      name: rate?.NAME ?? "",
      amount: rate ? toDollars(rate.AMOUNT) : "",
      type: rate?.TYPE ?? -1,
      start: "",
      end: "",
      role: null,
    };

    if (rate && rate.TYPE === "TIME_RANGE") {
      const [start, end] = rate.CONTENT.split("|");

      initialValues.end = moment()
        .startOf("day")
        .add(parseInt(end), "minutes")
        .format("hh:mm");
      initialValues.start = moment()
        .startOf("day")
        .add(parseInt(start), "minutes")
        .format("hh:mm");
    }

    return (
      <Modal
        tooltip={{
          data: "You can add different pay rates to roles that can be assigned to employees with that role",
        }}
        buttonLabel="Save"
        label={rate ? "Edit Pay Rate" : "Add Pay Rate"}
        formikOnClick={() => this.formikRef}
        deleteOnClick={this.deleteRate.bind(this)}
        deleteLabel={rate && "Delete"}
        deleteOnRight={true}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={
            rate ? this.saveRate.bind(this) : this.createRate.bind(this)
          }
          validationSchema={Yup.lazy(({type}) => {
            const defaultSchema = {
              name: Yup.string().required("Pay rate name is required"),
              amount: Yup.string().required("Pay rate amount is required"),
            };

            if (type === "TIME_RANGE") {
              defaultSchema.start = Yup.string().required(
                "Time range start is required"
              );
              defaultSchema.end = Yup.string().required(
                "Time range end is required"
              );
            }

            if (employee && !rate) {
              defaultSchema.role = Yup.string()
                .nullable()
                .required("Role is required.");
            }

            return Yup.object(defaultSchema);
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {employee && !rate && (
                  <RolesDropdown
                    name="role"
                    tooltip={
                      "Even though this is an employee specific pay rate, a role still must be attached since employees can have many roles."
                    }
                    options={formikOptions}
                    ref={(e) => (this.roleRef = e)}
                    includeOnly={employee.ROLES.map((item) => item.ROLE_ID)}
                  />
                )}

                <FormInput
                  label="Rate name"
                  name="name"
                  placeholder="Senior Barista Rate"
                  options={formikOptions}
                />

                <FormSelect
                  tooltip={{
                    data: [
                      {
                        label: "Types",
                        data: "There two different types of rates: hourly and time range.",
                      },
                      {
                        label: "Hourly",
                        data: "Employees with roles of this rate type will be paid by the hour.",
                      },
                      {
                        label: "Time Range",
                        data: "This rate will only be applied to employees during the specified times.",
                      },
                    ],
                  }}
                  label="Rate type"
                  name="type"
                  options={formikOptions}
                  data={[
                    {label: "Hourly", value: "-1"}, // this will get replaced with null
                    {label: "Time Range", value: "TIME_RANGE"},
                  ]}
                />

                <FormInput
                  label="Pay Amount"
                  name="amount"
                  placeholder={"15.50"}
                  options={formikOptions}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PayRateModal.propTypes = {
  addState: PropTypes.func,
  updateState: PropTypes.func,

  role: PropTypes.object,

  soft: PropTypes.bool,
};

export default PayRateModal;
