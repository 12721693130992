import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";
import PolicyDropdown from "../../../dropdowns/team/policy-dropdown";

class PolicyEmployeeModal extends Component {
  state = {policy: null};

  open(policy = null) {
    this.setState({policy}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createEmployeePolicy({policy, balance, override}) {
    const {employee} = this.props;

    const serverPolicyEmployee = await request(
      "timeoff/policies/" + policy + "/employees",
      "POST",
      {
        EMPLOYEE_ID: employee.ID,
        BALANCE: parseInt(balance),
        OVERRIDE_APPROVAL: override === "1" ? 1 : 0,
      }
    );

    this.props.addState(serverPolicyEmployee);
    this.modal.close();
  }

  async saveEmployeePolicy({policy, balance, override}) {
    const {employee} = this.props;

    const serverPolicyEmployee = await request(
      "timeoff/policies/" + policy + "/employees/" + employee.ID,
      "PATCH",
      {
        BALANCE: parseInt(balance),
        OVERRIDE_APPROVAL: override === "1" ? 1 : 0,
      }
    );

    this.props.updateState(serverPolicyEmployee.ID, serverPolicyEmployee);
    this.modal.close();
  }

  async deleteEmployeePolicy() {
    const {policy} = this.state;

    await request(
      "timeoff/policies/" + policy.POLICY_ID + "/employee/" + policy.ID,
      "DELETE",
      {}
    );

    this.props.updateState(policy.ID);
    this.modal.close();
  }

  render() {
    const {policies} = this.props;
    const {policy} = this.state;

    const exclude = policies.map((item) => item.POLICY_ID);

    return (
      <Modal
        buttonLabel={policy ? "Save" : "Add"}
        deleteLabel={policy ? "Remove" : null}
        label={policy ? "Edit Employee Policy" : "Add Employee Policy"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deleteEmployeePolicy()}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={
            policy
              ? this.saveEmployeePolicy.bind(this)
              : this.createEmployeePolicy.bind(this)
          }
          initialValues={{
            policy: policy?.POLICY_ID ?? null,
            balance: policy?.BALANCE ?? "0",
            override: policy?.OVERRIDE_APPROVAL ?? "0",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PolicyDropdown
                  name="policy"
                  disabled={!!policy}
                  exclude={policy ? [] : exclude}
                  options={formikOptions}
                />

                {!policy?.UNLIMITED && (
                  <FormInput
                    name="balance"
                    label="Employee Hours"
                    options={formikOptions}
                  />
                )}

                <FormBoolean
                  name="override"
                  options={formikOptions}
                  label="Override Approvals"
                  tooltip="If override is enabled, this employee will have their time off requests auto approved"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PolicyEmployeeModal.propTypes = {
  policies: PropTypes.array.isRequired,
  employee: PropTypes.object.isRequired,

  addState: PropTypes.func,
  updateState: PropTypes.func,
};

export default PolicyEmployeeModal;
