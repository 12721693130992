import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";

class TextSendTestModal extends Component {
  state = {loading: false};

  open() {
    this.setState({loading: false}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async send({phone}) {
    this.setState({loading: true});
    await this.props.sendText(phone);
    this.setState({loading: false});
    this.modal.close();
  }

  render() {
    const {label} = this.props;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={"Send"}
        formikOnClick={() => this.formikRef}
        label={label ?? "Send Test Text"}
      >
        <Formik
          onSubmit={this.send.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            phone: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="phone" label="Phone" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TextSendTestModal;
