import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {getEmployeeOnboardLink} from "../../../utils/payroll-helper";
import {CSVLink} from "react-csv";
import {DownloadIcon} from "@heroicons/react/solid";
import csvMapper from "../csv-mapper";
import {DO_NOT_CONVERT_FIELDS, DO_NOT_MAP_FIELDS} from "./mapping-tab";
import {SQUARE_DATA_CONVERSIONS} from "../../../utils/payroll-import-helper";

class DownloadSquareImportTab extends Component {
  state = {csvData: null, csvHeaders: null};

  componentDidMount() {
    let {
      firstPaycheckStartRow,
      paycheckIncrementer,
      exportData,
      paycheckToCheckMapping,
      checkTemplateData,
      matchedEmployees,
      matchedWorkplaces,
    } = this.props.payroll.importData;
    let {data} = checkTemplateData;
    let csvHeaders = Object.keys(data[0]);

    let csvData = [];

    for (let row = firstPaycheckStartRow; row < exportData.length; row += paycheckIncrementer) {
      let entry = {};

      //Employee ID
      let {rowOffset, colOffset} = paycheckToCheckMapping["Employee Name"];
      let input = exportData[row + rowOffset][colOffset];
      entry["Employee ID"] = (matchedEmployees[input]) ? matchedEmployees[input] : "";

      //Workplace ID
      let {rowOffset: workplaceRowOffset, colOffset: workplaceColOffset} = paycheckToCheckMapping["Workplace ID"];
      let workplaceAddress = SQUARE_DATA_CONVERSIONS["Workplace ID"](exportData[row + workplaceRowOffset][workplaceColOffset]);
      entry["Workplace ID"] = (matchedWorkplaces[workplaceAddress]) ? matchedWorkplaces[workplaceAddress] : ""

      for (let headerColumn of csvHeaders) {
        if (!DO_NOT_MAP_FIELDS.includes(headerColumn) && !DO_NOT_CONVERT_FIELDS.includes(headerColumn)) {
          if (!paycheckToCheckMapping[headerColumn] || paycheckToCheckMapping[headerColumn]?.rowOffset === undefined) {
            entry[headerColumn] = ""
          } else {
            let {rowOffset, colOffset} = paycheckToCheckMapping[headerColumn];
            let input = exportData[row + rowOffset][colOffset]

            if (SQUARE_DATA_CONVERSIONS[headerColumn]) {
              input = SQUARE_DATA_CONVERSIONS[headerColumn](input)
            }

            entry[headerColumn] = input;
          }
        }
      }

      csvData.push(entry);
    }

    this.setState({csvHeaders, csvData});
  }

  render() {
    let {csvData, csvHeaders} = this.state;

    return <div className={"px-6"}>

      {csvData &&
        <CSVLink
          filename={`WE DID IT`}
          data={csvData}
          headers={csvHeaders}
        >
          <button
            type="button"
            className="mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
            // onClick={this.downloadImport}
          >
            Download
          </button>
        </CSVLink>
      }
    </div>
  }
}

export default setupReduxConnection(["payroll"])(
  withRouter(DownloadSquareImportTab)
);
