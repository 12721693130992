import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class ProductDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = [], unique} = this.props;

    const serverProducts = await request("products/soft", "GET");
    serverProducts.sort((a, b) => a.NAME.localeCompare(b.NAME));

    const data = serverProducts
      .filter((item) => !exclude.includes(item.ID))
      .map((item) => {
        return {
          id: unique ? item.UNIQUE_ID : item.ID,
          label: item.NAME,
        };
      });

    this.setState({data, raw: serverProducts});
  }

  fetchProduct(useRaw) {
    let {data, raw} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    if (useRaw) {
      return raw.find((item) => item.ID === parseInt(value));
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;

    return <ComboBox label="Product" {...this.props} data={data} />;
  }
}

export default ProductDropdown;
