import React, {Component} from "react";
import FormElement from "./../form/form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

class FormInput extends Component {
  render() {
    const {
      name,
      placeholder,
      type = "text",
      autoComplete = "off",
      disabled = false,
      mask = null,
      prefix = null,
    } = this.props;

    return (
      <FormElement {...this.props}>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          if (mask) {
            return (
              <MaskedInput
                mask={mask}
                key={name}
                id={name}
                type={type}
                name={name}
                value={value}
                onBlur={onBlur}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);

                  setTimeout(() => {
                    onChangeSoft && onChangeSoft(e.target.value);
                  }, 1);
                }}
                placeholder={placeholder}
                autoComplete={autoComplete}
                className={classNames(
                  disabled ? "text-neutral-text" : undefined,
                  error
                    ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                    : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                  "block w-full focus:outline-none sm:text-sm rounded-md",
                  extraStyle
                )}
                aria-describedby="email-error"
                aria-invalid="true"
              />
            );
          }

          return (
            <div className="flex flex-row items-center">
              {!!prefix && (
                <div className=" text-neutral-text text-sm font-bold mx-2">
                  {prefix}
                </div>
              )}

              <input
                key={name}
                id={name}
                type={type}
                name={name}
                value={value}
                onBlur={onBlur}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);

                  setTimeout(() => {
                    onChangeSoft && onChangeSoft(e.target.value);
                  }, 1);
                }}
                placeholder={placeholder}
                autoComplete={autoComplete}
                className={classNames(
                  disabled ? "text-neutral-text" : undefined,
                  error
                    ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                    : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                  "block w-full focus:outline-none sm:text-sm rounded-md",
                  extraStyle
                )}
                aria-describedby="email-error"
                aria-invalid="true"
              />
            </div>
          );
        }}
      </FormElement>
    );
  }
}

FormInput.propTypes = {
  ...FormElement.propTypes,

  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default FormInput;
