import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../../page/page-layout";
import SideNav from "../../../components/side-nav";
import {setupReduxConnection} from "../../../redux";
import {PERMISSIONS} from "../../../utils/constants";

class RecordsSideNav extends Component {
  render() {
    let recordsInfo = [
      {
        live: true,
        name: "Shops",
        children: [
          {
            live: true,
            name: "Locations",
            href: "/admin/locations",
            paths: ["admin/locations", "admin/location"],
          },
          {
            live: true,
            name: "Companies",
            href: "/admin/companies",
            paths: ["admin/locations", "admin/location"],
          },
          {
            live: true,
            name: "Franchises",
            href: "/admin/franchises",
            paths: ["admin/locations", "admin/location"],
          },
        ],
      },
    ];

    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav title="Records" navigation={recordsInfo} />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(RecordsSideNav);
