import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class RadioDropdown extends Component {
  state = {currentValue: this.props.defaultValue};

  componentDidMount() {
    let {name, data, formikOptions} = this.props;

    // if (formikOptions?.values[name]) {
    //   this.setState({
    //     currentEntry: data.find((item) => {
    //       return item.value === formikOptions?.values[name];
    //     }),
    //   });
    // } else {
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.data && prevProps.data !== this.props.data) {
      this.setState({value: this.props.data[0]["value"]});
    }
  }

  selectOption = (item) => {
    let {name, formikOptions} = this.props;

    this.setState({currentValue: item.value});

    if (formikOptions) {
      formikOptions.setFieldValue(name, item.value);
    } else {
      this.props.onChange(item.value);
    }
  };

  render() {
    let {data, label, className} = this.props;
    let {currentValue} = this.state;

    let selectedLabel = data.find((entry) => entry.value === currentValue)?.label;

    return (
      <div className={classNames(className, "block text-sm font-medium text-gray-700")}>
        <div>
          {label}
        </div>

        <Menu as="div" className="relative inline-block text-left z-2">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
              {selectedLabel}
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute left=0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div>
                {data.map((item) => (
                  <Menu.Item
                    className={`flex flex-row py-1 text-sm hover:bg-gray-100 items-center ${
                      currentValue === item.value ? "bg-gray-100" : ""
                    }`}
                    onClick={() => this.selectOption(item)}
                  >
                    <div>
                      <div className="w-8">
                        {currentValue === item.value && (
                          <CheckIcon className="ml-2 h-4 w-4 text-purple-600" />
                        )}
                      </div>
                      <span>{item.label}</span>
                    </div>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  }
}

export default RadioDropdown;
