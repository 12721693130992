import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class GrayBadge extends Component {
  render() {
    const {className, darkGrey} = this.props;
    let color = "bg-gray-100 text-gray-800";

    if (darkGrey) {
      color = "bg-gray-300";
    }

    return (
      <span
        {...this.props}
        className={classNames(
          "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium text-gray-800",
          color,
          className
        )}
      >
        {this.props.children}
      </span>
    );
  }
}

export default GrayBadge;
