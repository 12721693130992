import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";

class BarcodeModal extends Component {
  state = {product: null};

  open(product = null) {
    this.setState({product}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveProduct({barcode}) {
    const {product} = this.state;

    request("product/barcode/" + product.ID, "POST", {BARCODE: barcode?.trim()})
      .then((serverProduct) => {
        this.props.updateProduct &&
          this.props.updateProduct(serverProduct.BARCODE);
        this.modal.close();
      })
      .catch((err) => {
        console.log(err);
        this.modal.close();
      });
  }

  render() {
    const {product} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Product Barcode"
        description="Edit the product's barcode"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            barcode: product?.BARCODE,
          }}
          validationSchema={Yup.object({
            barcode: Yup.string().nullable(),
          })}
          onSubmit={(values) => this.saveProduct(values)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Barcode"
                  name="barcode"
                  options={formikOptions}
                  tooltip={{
                    data:
                      "If no numbers are available on your barcode, you can use the Dashboard App's scan " +
                      "feature to find the value of the barcode to input.",
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default BarcodeModal;
