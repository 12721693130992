export const DEFAULT_GIFT_CARDS = new Set([
  "gift-cards/GiftCard1.png",
  "gift-cards/GiftCard2.png",
  "gift-cards/GiftCard3.png",
  "gift-cards/GiftCard4.png",
  "gift-cards/GiftCard5.png",
  "gift-cards/GiftCard6.png",
  "gift-cards/GiftCard7.png",
  "gift-cards/GiftCard8.png",
  "gift-cards/GiftCard9.png",
  "gift-cards/GiftCard10.png",
  "gift-cards/GiftCard11.png",
  "gift-cards/GiftCard12.png",
  "gift-cards/GiftCard13.png",
  "gift-cards/GiftCard14.png",
  "gift-cards/Dripos_Gift_Card_1.png",
  "gift-cards/Dripos_Gift_Card_2.png",
  "gift-cards/Dripos_Gift_Card_3.png",
  "gift-cards/Dripos_Gift_Card_4.png",
  "gift-cards/Dripos_Gift_Card_5.png",
  "gift-cards/Dripos_Gift_Card_6.png",
  "gift-cards/Dripos_Gift_Card_7.png",
  "gift-cards/Dripos_Gift_Card_8.png",
  "gift-cards/Dripos_Gift_Card_9.png",
  "gift-cards/Dripos_Gift_Card_10.png",
  "gift-cards/Dripos_Gift_Card_11.png",
  "gift-cards/Dripos_Gift_Card_12.png",
  "gift-cards/Dripos_Gift_Card_13.png",
  "gift-cards/Dripos_Gift_Card_14.png",
  "gift-cards/Dripos_Gift_Card_15.png",
  "gift-cards/Dripos_Gift_Card_16.png",
  "gift-cards/Dripos_Gift_Card_17.png",
  "gift-cards/Dripos_Gift_Card_18.png",
  "gift-cards/Dripos_Gift_Card_19.png",
  "gift-cards/Dripos_Gift_Card_20.png",
  "gift-cards/Dripos_Gift_Card_21.png",
  "gift-cards/Dripos_Gift_Card_22.png",
  "gift-cards/Dripos_Gift_Card_23.png",
  "gift-cards/Dripos_Gift_Card_24.png",
  "gift-cards/Dripos_Gift_Card_25.png",
  "gift-cards/Dripos_Gift_Card_26.png",
  "gift-cards/Dripos_Gift_Card_27.png",
]);

export const TEXT_CAMPAIGN_TYPES = {
  ONE_OFF: 0,
  BIRTHDAY: 1,
  WELCOME: 2,
};
