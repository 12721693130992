import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../utils/request";

class FormProductSelect extends Component {
  state = {data: [], isLoading: false};

  componentDidMount() {
    this.setState({isLoading: true}, () => {
      this.syncData().catch((err) => {
        console.log("Problem fetching employee data", err);
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {company} = this.props;
    const {isLoading} = this.state;
    const {company: oldCompany} = prevProps;

    if (company !== oldCompany) {
      this.setState({isLoading: true}, () => {
        this.syncData();
      });
    }
  }

  async syncData() {
    const {exclude = [], unique, company} = this.props;

    let serverProducts;

    serverProducts = await request("products/soft", "GET");
    serverProducts.sort((a, b) => a.NAME.localeCompare(b.NAME));

    const data = serverProducts
      .filter((item) => !item.DATE_ARCHIVED && item.CATEGORY_ID)
      .filter((item) => !exclude.includes(item.ID))
      .map((item) => {
        return {
          value: unique ? item.UNIQUE_ID : item.ID,
          label: item.NAME,
        };
      });

    this.setState({data, raw: serverProducts, isLoading: false});
  }

  fetchProduct(useRaw) {
    let {data, raw} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    if (useRaw) {
      return raw.find((item) => item.ID === parseInt(value));
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;

    return <FormSelect {...this.props} data={data} />;
  }
}

export default FormProductSelect;
