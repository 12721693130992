import React, {Component} from "react";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../components/loading-spinner";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";

class SetupCashAccountPage extends Component {
  state = {loading: false};

  setupCash = async () => {
    this.setState({loading: true});

    await request("banking/enable-cash", "POST", null);

    setTimeout(() => {
      this.props.updateLocationFAID();
      this.props.router.navigate("/transactions");
    }, 11000)
  };

  render() {
    let {loading} = this.state;

    return (
      <Card>
        <div className="p-6 items-center">
          <div>
            promo text promo text and more promo text to convince you to sign
            up!
          </div>

          <div></div>

          <div className="mt-4">
            {!loading && (
              <div className="flex w-full justify-center">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => this.setupCash()}
                >
                  Setup
                </button>
              </div>
            )}

            {loading && <LoadingSpinner />}
          </div>
        </div>
      </Card>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(SetupCashAccountPage));
