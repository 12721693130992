import React, {Component} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/outline";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Tooltip from "../tooltip/tooltip";

class FormElement extends Component {
  renderButtonHint(value) {
    const {buttonHint} = this.props;

    if (!buttonHint) {
      return <div />;
    }

    if (buttonHint.hideNull && !value) {
      return <div />;
    }

    return (
      <div
        onClick={() => buttonHint.onClick(value)}
        className="text-sm cursor-pointer font-medium text-indigo-600"
      >
        {buttonHint.label}
      </div>
    );
  }

  render() {
    const {
      id,
      options,
      label,
      name,
      value,
      addon,
      onBlur,
      onChange,
      className,
      style = {},
      leadingIcon,
      trailingIcon,
      onChangeSoft,
      ignoreShadow,
      buttonLabel,
      buttonOnClick,
      labelClassName,
      buttonHint,
      tooltip,
      flex,
      hint,
      helpText,
      ignoreMargin,
      maxLength,
    } = this.props;

    let elementChange = options ? options.handleChange : onChange;
    let elementValue = options ? options.values[name] : value;
    let elementBlur = options ? options.handleBlur : onBlur;

    const valueLength = elementValue?.length ?? 0;
    let remainingCharacters = maxLength ? maxLength - valueLength : null;
    remainingCharacters = remainingCharacters < 0 ? 0 : remainingCharacters;

    if (
      options === null &&
      (onChange === null || typeof onChange === "undefined")
    ) {
      elementChange = () => {};
    }

    let elementError = options
      ? options.touched[name] && options.errors[name]
      : null;

    if (this.props.error) {
      elementError = this.props.error;
    }

    return (
      <div
        id={id}
        style={style}
        className={classNames(
          !ignoreMargin && "mt-2",
          flex && "flex-1",
          className
        )}
      >
        {label && (
          <div className="flex flex-row justify-between">
            <div className="flex flex-row align-center">
              <label
                htmlFor={name}
                className={classNames(
                  labelClassName,
                  "block text-sm font-medium text-gray-700"
                )}
              >
                {label}
                {maxLength && ` (${remainingCharacters} characters remaining)`}
              </label>

              <div
                className="flex flex-row align-center"
                style={{marginTop: -3}}
              >
                {tooltip && (
                  <div style={{height: 1, paddingBottom: 2}}>
                    {typeof tooltip === "string" ? (
                      <Tooltip className="ml-2" data={tooltip} />
                    ) : (
                      <Tooltip className="ml-2" {...tooltip} />
                    )}
                  </div>
                )}

                {hint && (
                  <div
                    style={{marginTop: 2}}
                    className="flex ml-2 bg-gray-200 px-2 -py-1 rounded-md text-xs text-gray-500 items-center font-medium"
                  >
                    {hint}
                  </div>
                )}
              </div>
            </div>

            {this.renderButtonHint(elementValue)}
          </div>
        )}

        <div
          className={classNames(
            "relative rounded-md",
            !ignoreMargin && "mt-1",
            ignoreShadow ? "" : "shadow-sm",
            buttonLabel ? "flex space-x-4" : ""
          )}
        >
          {addon && (
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-neutral-border bg-gray-50 text-neutral-text sm:text-sm">
              {addon}
            </span>
          )}

          {leadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {leadingIcon}
            </div>
          )}

          {this.props.children &&
            this.props.children(
              elementValue,
              elementError,
              elementChange,
              elementBlur,
              classNames(
                leadingIcon ? "pl-10" : "",
                trailingIcon || elementError ? "pr-10" : ""
              ),
              onChangeSoft,
              this.props
            )}

          {buttonLabel && (
            <button
              type="button"
              onClick={buttonOnClick}
              className={classNames(
                elementValue?.length > 0
                  ? "text-white bg-primary-bg"
                  : "text-gray-600 bg-gray-200",
                "text-sm font-medium rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-border"
              )}
            >
              {buttonLabel}
            </button>
          )}

          {elementError ? (
            <div
              className={classNames(
                buttonLabel ? "right-20" : "right-0",
                "absolute inset-y-0 pr-3 flex items-center pointer-events-none"
              )}
            >
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : (
            trailingIcon && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                {trailingIcon}
              </div>
            )
          )}
        </div>

        {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}

        {elementError && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {elementError}
          </p>
        )}
      </div>
    );
  }
}

FormElement.propTypes = {
  id: PropTypes.string,

  onChange: PropTypes.func,
  onChangeSoft: PropTypes.func,

  label: PropTypes.string.isRequired,

  name: PropTypes.string,
  options: PropTypes.object,

  addon: PropTypes.string,
  leadingIcon: PropTypes.object,
  trailingIcon: PropTypes.object,

  ignoreShadow: PropTypes.bool,

  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,

  labelClassName: PropTypes.string,
  className: PropTypes.string,

  style: PropTypes.object,

  hint: PropTypes.string,
  tooltip: Tooltip.propTypes,

  flex: PropTypes.bool,

  ignoreMargin: PropTypes.bool,
  helpText: PropTypes.string,

  buttonHint: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default FormElement;
