import React, {Component} from "react";
import FormElement from "./../form/form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import {PhoneIcon} from "@heroicons/react/outline";
import * as Yup from "yup";

export const PHONE_YUP = Yup.string().matches(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  "Please enter a valid phone number"
);

class FormPhone extends Component {
  render() {
    const {name, placeholder, disabled} = this.props;

    return (
      <FormElement
        {...this.props}
        leadingIcon={
          <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        }
      >
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <InputMask
              mask="999 999 9999"
              maskChar={null}
              type="text"
              id={name}
              name={name}
              value={value}
              onBlur={onBlur}
              disabled={disabled}
              autoComplete="phone"
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e);

                setTimeout(() => {
                  onChangeSoft && onChangeSoft(e.target.value);
                }, 1);
              }}
              className={classNames(
                error
                  ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                disabled ? "text-gray-500" : "",
                "block w-full pr-10 focus:outline-none sm:text-sm rounded-md",
                extraStyle
              )}
              aria-describedby="email-error"
              aria-invalid="true"
            />
          );
        }}
      </FormElement>
    );
  }
}

FormPhone.propTypes = {
  ...FormElement.propTypes,

  placeholder: PropTypes.string,
};

export default FormPhone;
