import React, {Component} from "react";
import {
  FormBoolean,
  FormSelect,
  FormInput,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";

class TicketTypeDetailsModal extends Component {
  state = {type: null};

  open(type = null) {
    this.setState({type}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveTicketType({creation, confirmation}) {
    const {type: oldType} = this.state;

    let serverTicketType = {
      ORDER_CREATION_TEXT: creation,
      ORDER_CONFIRMATION_TEXT: confirmation,
    };

    let serverCategory = await request(
      "types/order/" + oldType.ID,
      "PATCH",
      serverTicketType
    );

    this.props.updateState({type: {...oldType, ...serverCategory}});
    this.modal.close();
  }

  render() {
    const {type} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Ticket Notifications"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveTicketType.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            creation: type?.ORDER_CREATION_TEXT,
            confirmation: type?.ORDER_CONFIRMATION_TEXT,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormTextArea
                  label="Message on Order Placed"
                  name="creation"
                  options={formikOptions}
                />

                <FormTextArea
                  label="Message on Order Completed"
                  name="confirmation"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TicketTypeDetailsModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default TicketTypeDetailsModal;
