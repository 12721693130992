import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import FormRow from "../../../components/form-row";
import * as Yup from "yup";
import ColorDropdown from "../../../features/team/color-dropdown";
import ComboBox from "../../../components/combobox";
import {APPROVAL_NOTIFICATIONS} from "../../../modals/team/roles/role-modal";

class RoleForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({name, color, tips, notify, notifyClock}) {
    return {
      role: {
        NAME: name,
        COLOR: color,
        TIPS_DISABLED: parseInt(tips),
        APPROVAL_NOTIFICATIONS: notify,
        CLOCK_NOTIFICATIONS: notifyClock,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, role} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          name: role?.NAME ?? "",
          color: role?.COLOR ?? "57A0D2",
          tips: role?.TIPS_DISABLED ?? "0",
          notify: role?.APPROVAL_NOTIFICATIONS ?? null,
          notifyClock: role?.CLOCK_NOTIFICATIONS ?? null,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Please input a role name"),
        })}
      >
        {(formikOptions) => {
          return (
            <div className="flex justify-center pb-10">
              <div className="w-full px-6 md:px-6">
                <FormRow>
                  <FormInput
                    label="Role Name"
                    options={formikOptions}
                    name="name"
                    flex
                  />

                  <ColorDropdown
                    label="Color"
                    tooltip={{
                      label: "Schedule Color",
                      data: "Shifts with this role will appear with this color on the scheduling page.",
                    }}
                    name="color"
                    options={formikOptions}
                  />
                </FormRow>

                <FormRow>
                  <FormBoolean
                    label="Tips Disabled"
                    options={formikOptions}
                    tooltip="Tips are divided equally among employees who are working when the tip was collected. If tips are disabled for a role, then any employee who is working a shift with that role will not receive a share of the tips collected during their shift."
                    name="tips"
                    flex
                  />

                  <ComboBox
                    label={"Notify of Trade and Time Off Requests"}
                    tooltip="Send email notifications to employees with this role when trade and time off requests are submitted."
                    data={APPROVAL_NOTIFICATIONS}
                    name={"notify"}
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <FormRow>
                  <ComboBox
                    label={"Notify of Late Clock Ins"}
                    tooltip="Send email or text notifications when employees clock in after the late threshold."
                    data={APPROVAL_NOTIFICATIONS}
                    name={"notifyClock"}
                    options={formikOptions}
                    flex
                  />

                  <div className="flex-1"></div>
                </FormRow>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

RoleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  role: PropTypes.object,
};

export default RoleForm;
