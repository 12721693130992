import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import TicketSettingsModal from "../../../modals/shop/shop-settings/ticket-settings-modal";

class TicketSettingsTab extends Component {
  render() {
    let {
      AUTO_COMPLETE_REGISTER_ORDERS,
      AUTO_COMPLETE_KIOSK_ORDERS,
      AUTO_COMPLETE_MOBILE_ORDERS,
      AUTO_COMPLETE_WEB_ORDERS,
      AUTO_COMPLETE_TAB_ORDERS,
      AUTO_COMPLETE_CUSTOM_PRODUCTS,
    } = this.props.shop.settings;

    return (
      <div>
        <TicketSettingsModal ref={(e) => (this.orderSystemModal = e)} />

        <TwoColumnList
          label="Auto Complete Settings"
          description="Configure your auto-complete settings for your shop"
          data={[
            {
              label: "Register Orders",
              value: AUTO_COMPLETE_REGISTER_ORDERS,
              type: "bool",
              tooltip: "Auto complete all tickets made on the register.",
            },
            {
              label: "Kiosk Orders",
              value: AUTO_COMPLETE_KIOSK_ORDERS,
              type: "bool",
              tooltip: "Auto complete all tickets made on the kiosk.",
            },
            {
              label: "Mobile App Orders",
              value: AUTO_COMPLETE_MOBILE_ORDERS,
              type: "bool",
              tooltip: "Auto complete all tickets made on the mobile app.",
            },
            {
              label: "Online Orders",
              value: AUTO_COMPLETE_WEB_ORDERS,
              type: "bool",
              tooltip: "Auto complete all tickets made on the order website.",
            },
            {
              label: "Saved Orders",
              value: AUTO_COMPLETE_TAB_ORDERS,
              type: "bool",
              tooltip: "Auto complete all tickets that were saved orders.",
            },
            {
              label: "Custom Products",
              value: AUTO_COMPLETE_CUSTOM_PRODUCTS,
              type: "bool",
              tooltip:
                "Auto complete all items that are custom products. This will not autocomplete the whole order if other items on the order are not autocompleted.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.orderSystemModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TicketSettingsTab);
