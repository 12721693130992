import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {
  PageHeadings,
  Table,
  Filter,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ECOMMERCE_ORDER_STATUES_LABEL_MAP} from "../../../utils/constants";
import EcommerceStatusModal from "../../../modals/sales/ecommerce/ecommerce-status-modal";

class EcommerceTicketsPage extends Component {
  state = {
    locations: [],
    isLoading: true,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const locations = await request("employees/locations", "GET");

    this.setState({isLoading: false, locations});
  }

  render() {
    let {isLoading, locations} = this.state;
    let {location} = this.props.shop;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <EcommerceStatusModal
          updateState={(e) => this.tableRef.refresh()}
          ref={(e) => (this.statusModal = e)}
        />

        <PageHeadings
          description="Filter to find ecommerce orders"
          label="Ecommerce Orders"
        />

        <Filter
          searchable
          searchParams
          className="mt-4"
          defaultFilters={[
            {filter: "status", id: "RECEIVED"},
            {filter: "status", id: "PRODUCING"},
            {filter: "status", id: "AWAITING_PACKAGING"},
            {filter: "status", id: "AWAITING_SHIPPING"},
            ...locations.map((item) => {
              return {filter: "location", id: item.ID};
            })
          ]}
          csv={{
            file: "ecommerce",
            headers: [
              {label: "Name", key: "NAME"},
              {label: "Phone", key: "PHONE"},
              {label: "Email", key: "EMAIL"},
            ],
          }}
          data={[
            {
              id: "location",
              label: "Locations",
              options: locations.map((item) => {
                return {label: item.NAME, id: item.ID};
              }),
            },
            {
              id: "status",
              label: "Order Status",
              options: [
                {label: "Received", id: "RECEIVED"},
                {label: "Awaiting Packaging", id: "PRODUCING"},
                {label: "Awaiting Packaging", id: "AWAITING_PACKAGING"},
                {label: "Awaiting Shipping", id: "AWAITING_SHIPPING"},
                {label: "Fulfilled", id: "FULFILLED"},
                {label: "On Hold", id: "ON_HOLD"},
              ],
            },
          ]}
        >
          {(filters, search) => {
            return (
              <Table
                ref={(e) => (this.tableRef = e)}
                selectable
                selectButtons={[
                  {
                    label: "Update Status",
                    onClick: (data) => {
                      this.statusModal.open(data.map((item) => item.UNIQUE_ID));
                    },
                  },
                ]}
                select={() => {
                  console.log("HI");
                }}
                route="ecommerce/dumb"
                search={search}
                filters={filters}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (ticket) =>
                      this.props.router.navigate("/order/" + ticket.UNIQUE_ID),
                  },
                  {
                    label: (
                      <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
                    ),
                    onClick: (ticket) =>
                      this.props.router.navigateNewTab(
                        "/ticket/" + ticket.UNIQUE_ID
                      ),
                  },
                ]}
                columns={[
                  {label: "Name", value: "NAME"},
                  {
                    label: "Status",
                    value: "ECOMMERCE",
                    format: (ecommerce) => {
                      return ECOMMERCE_ORDER_STATUES_LABEL_MAP[
                        ecommerce?.ORDER_STATUS
                      ];
                    },
                  },
                  {
                    label: "Date Created",
                    value: "DATE_CREATED",
                    format: (val, row) =>
                      moment(val).format("h:mma ddd MMM Do"),
                  },
                ]}
                pagination
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop"])(
  withRouter(EcommerceTicketsPage)
);
