import React, {Component} from "react";
import {MailIcon} from "@heroicons/react/outline";
import FormInput from "./form-input";

class FormEmail extends Component {
  render() {
    return (
      <FormInput
        {...this.props}
        type="email"
        autoComplete="email"
        leadingIcon={
          <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        }
      />
    );
  }
}

FormEmail.propTypes = {
  ...FormInput.propTypes,
};

export default FormEmail;
