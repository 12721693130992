import PropTypes from "prop-types";
import React from "react";
import LoadingSpinner from "../../components/loading-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {setupReduxConnection} from "../../redux";

const SchedulerEvent = ({
  loading,
  title,
  timeString,
  subtitle,
  description,
  customEvent,
  allDay,
  acknowledged,
  confirmSetting,
  duration,
  location,
}) => {
  if (loading) {
    return (
      <div className="p-1 flex flex-col items-center h-10 justify-center">
        <LoadingSpinner size={20} color={"#000000"} />
      </div>
    );
  }

  if (customEvent) {
    return (
      <div className="p-1 flex flex-col items-center h-14 justify-center inline-block">
        <p className="font-bold text-[10px]">{title}</p>
        <div className={"text-[10px] truncate"}>{description}</div>
      </div>
    );
  }

  if (allDay) {
    timeString = "All Day";
  }

  return (
    <div className="flex flex-col items-center h-10 justify-center display relative truncate">
      {confirmSetting === "1" && acknowledged === 1 && (
        <div>
          <FontAwesomeIcon
            className="absolute top-0 right-0 h-3 w-3"
            icon="square-check"
            data-tip
            data-for="checkMark"
          />

          <ReactTooltip id="checkMark">Acknowledged by Employee</ReactTooltip>
        </div>
      )}
      <div className="flex flex-row">
        <p className="font-bold text-[10px]">{timeString}</p>
        {duration > 0 && !allDay && (
          <p className="font-bold text-[8px] ml-0.5">{`(${duration}h)`}</p>
        )}
      </div>

      {location && <div className={"text-[10px]"}>{location}</div>}

      <div className={"text-[10px]"}>{subtitle}</div>
    </div>
  );
};

SchedulerEvent.propTypes = {
  subtitle: PropTypes.string.isRequired,
  timeString: PropTypes.string.isRequired,
};

export default SchedulerEvent;
