import {DELIVERY_STAGE_VERBIAGE, REGULAR_STAGE_VERBIAGE} from "./constants";

export function getVerbiageArray(ticketType, verbiages) {
  let verbiageArray = getDefaultVerbiageArray(ticketType);

  for (let statusIndex in verbiageArray) {
    const currentOSV = verbiages.find((item) => {
      return (
        item.STAGE_INDEX === parseInt(statusIndex) &&
        item.ORDER_TYPE_ID === ticketType.ID
      );
    });

    if (currentOSV?.LABEL) {
      verbiageArray[statusIndex].LABEL = currentOSV.LABEL;
    }

    if (currentOSV?.DESCRIPTION) {
      verbiageArray[statusIndex].DESCRIPTION = currentOSV.DESCRIPTION;
    }
  }

  return verbiageArray;
}

export function getDefaultVerbiageArray(ticketType) {
  let {TYPE} = ticketType;

  if (TYPE === "DELIVERY") {
    return DELIVERY_STAGE_VERBIAGE;
  }
  return REGULAR_STAGE_VERBIAGE;
}
