import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import Notification from "../../components/notification";

class NotificationService extends Component {
  state = {offset: 0};

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (e) => {
    const {offset} = this.state;

    const newOffset = Math.min(64 - (64 - window.scrollY), 64);

    if (offset !== newOffset) {
      this.setState({offset: newOffset});
    }
  };

  render() {
    const {data} = this.props.notification;
    const {offset} = this.state;

    return (
      <div
        style={{marginTop: 64 - offset}}
        aria-live="assertive"
        className="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-4 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {data.map((item) => (
            <Notification {...item} />
          ))}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["notification"])(NotificationService);
