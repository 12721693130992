import {Component} from "react";
import {CELL_TYPES} from "./reporting-constants";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React from "react";

class ReportValueCell extends Component {
  render() {
    let {
      firstIndex,
      secondIndex,
      thirdIndex,
      style,
      number,
      dollarAmount,
      percentage,
      format,
    } = this.props;
    let {numbers} = this.props.row;

    let value;

    if (thirdIndex) {
      value = numbers[firstIndex][secondIndex][thirdIndex];
    } else if (secondIndex) {
      value = numbers[firstIndex][secondIndex];
    } else {
      value = numbers[firstIndex];
    }

    if (value === null) {
      return <div></div>;
    }

    if (!value && dollarAmount) {
      value = 0;
    } else if (!value && !number) {
      value = "";
    }

    let text = value;

    if (dollarAmount) {
      text = toDollars(value, true);
    }

    if (percentage) {
      text = `${(text * 100).toFixed(1)}%`;
    }

    if (style.includes(CELL_TYPES.NEGATIVE) && text) {
      text = `(${text})`;
    }

    if (format) {
      let values = numbers;
      if (secondIndex) {
        value = numbers[firstIndex];
      }
      return (
        <div
          className={classNames(
            "float-right text-black",
            style.includes(CELL_TYPES.FLOAT_LEFT) && "float-left"
          )}
        >
          {format(value, values)}
        </div>
      );
    }

    return (
      <div
        className={classNames(
          "float-right",
          style.includes(CELL_TYPES.FLOAT_LEFT) && "float-left",
          style.includes(CELL_TYPES.BOLD) && "font-bold",
          style.includes(CELL_TYPES.BOLD) &&
            !style.includes(CELL_TYPES.SECONDARY) &&
            "text-black",
          style.includes(CELL_TYPES.SECONDARY) && "text-gray-500",
          !style.includes(CELL_TYPES.SECONDARY) && "text-gray-900"
        )}
      >
        {text}
      </div>
    );
  }
}

export default ReportValueCell;
