import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {Formik} from "formik";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {RUN_MODES} from "../../../redux/payroll";

class ChoosePaydayComponent extends Component {
  state = {
    options: null,
    loading: true,
    periodStart: null,
    periodEnd: null,
    selection: null,
    customPayday: null,
    paymentMethod: PAYMENT_METHOD_TYPES.DIRECT_DEPOSIT.value,
  };

  async componentDidMount() {
    let options = await request("payroll/payday-options");

    let defaultPayday = options.find(
      (option) => option.PROCESSING_PERIOD !== 1
    ).DATE;

    this.props.onPaydayChange(defaultPayday);

    this.setState({
      loading: false,
      options,
      selection: defaultPayday,
    });
  }

  render() {
    let {
      chooseWorkPeriod,
      onPeriodStartChange,
      onPeriodEndChange,
      onPaydayChange,
      onPaymentMethodChange,
      title,
      description,
      loading: parentLoading,
      chooseManual,
    } = this.props;

    let {
      options,
      loading,
      periodStart,
      periodEnd,
      selection,
      customPayday,
      paymentMethod,
    } = this.state;

    if (loading || parentLoading) {
      return <LoadingSpinner/>;
    }

    let displayOptions = options.map((item) => {
      return (
        <li>
          {moment(item.DATE).format("dddd, MMM Do")}
          {`: ${item.PROCESSING_PERIOD} Day Processing ${
            item.PROCESSING_PERIOD === 1 ? ` - $50 Fee` : ""
          }`}
        </li>
      );
    });

    displayOptions.push(<li>Custom Date</li>);

    let oneDaySelected = options.find(option => option.DATE === selection)?.PROCESSING_PERIOD === 1

    let selectOptions = options.map((item) => ({
      label: `${moment(item.DATE).format("dddd, MMM Do")} ${
        item.PROCESSING_PERIOD === 1 ? " - $50 Fee" : ""
      }`,
      value: item.DATE,
    }));

    selectOptions.push({
      label: "Custom Date",
      value: "CUSTOM",
    });

    if (!title) {
      title = "Payday";
    }

    if (!description) {
      description = "Choose between the following to pay your employees:";
    }

    return (
      <div className="p-6 space-y-4">
        {chooseWorkPeriod && (
          <div>
            <div className="font-bold text-lg">Method of Payment</div>

            <div className="w-96">
              {chooseManual && (
                <FormSelect
                  value={paymentMethod}
                  data={Object.keys(PAYMENT_METHOD_TYPES).map(
                    (type) => PAYMENT_METHOD_TYPES[type]
                  )}
                  onChange={(paymentMethod) => {
                    this.setState({paymentMethod});

                    onPaymentMethodChange(paymentMethod);
                  }}
                />
              )}
            </div>

            <div className="font-bold text-lg mt-4">Work Period</div>
            <div className="flex flex-row">
              <FormDateTimeSelect
                hideTime
                value={periodStart}
                buttonText={(epoch) => {
                  if (!epoch) {
                    return "--/--/--";
                  }
                  return moment(epoch).format("M/D/YY");
                }}
                onChange={(e) => {
                  let formattedPeriodStart = moment(e).format("YYYY-MM-DD");
                  this.setState({periodStart: formattedPeriodStart});

                  onPeriodStartChange(formattedPeriodStart);
                }}
              />

              <div className="mx-2 mt-4">-</div>

              <FormDateTimeSelect
                hideTime
                value={periodEnd}
                buttonText={(epoch) => {
                  if (!epoch) {
                    return "--/--/--";
                  }
                  return moment(epoch).format("M/D/YY");
                }}
                onChange={(e) => {
                  let formattedPeriodEnd = moment(e).format("YYYY-MM-DD");

                  this.setState({periodEnd: formattedPeriodEnd});

                  onPeriodEndChange(formattedPeriodEnd);
                }}
              />
            </div>
          </div>
        )}

        <div>
          <div className="font-bold text-lg">{title}</div>
          {paymentMethod !== PAYMENT_METHOD_TYPES.MANUAL.value && (
            <div>
              <div>{description}</div>

              <ul className={"list-disc ml-6 py-2 space-y-1"}>
                {displayOptions}
              </ul>

              <div className="w-96">
                <FormSelect
                  label={title}
                  name={"payday"}
                  value={selection}
                  onChange={(e) => {
                    this.setState({selection: e});

                    if (e !== "CUSTOM") {
                      onPaydayChange(e);
                    }
                  }}
                  data={selectOptions}
                />
              </div>

              {oneDaySelected && <div className="text-red-500 mt-1">Because one-day processing has been selected, employees will be paid in the afternoon on payday</div>}
            </div>
          )}

          {(selection === "CUSTOM" ||
            paymentMethod === PAYMENT_METHOD_TYPES.MANUAL.value) && (
            <FormDateTimeSelect
              label={"Payment Date"}
              value={customPayday}
              tooltip={{
                label: "Payment Date",
                value:
                  "Enter the date you’d like your employees to receive payment",
              }}
              hideTime
              buttonText={(epoch) => {
                if (!epoch) {
                  return "--/--/--";
                }
                return moment(epoch).format("M/D/YY");
              }}
              onChange={(e) => {
                let customPayday = moment(e).format("YYYY-MM-DD");
                this.setState({customPayday});

                onPaydayChange(customPayday);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export const PAYMENT_METHOD_TYPES = {
  DIRECT_DEPOSIT: {
    label: "Employee Preference",
    value: "DIRECT_DEPOSIT",
  },
  MANUAL: {
    label: "Manual",
    value: "MANUAL",
  },
};

export default setupReduxConnection(["payroll"])(ChoosePaydayComponent);
