import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import FilterDropdown from "../../components/filter-dropdown";

class ProductTagsDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverTags = await request("tags", "GET");
    const data = serverTags
      .filter((tag) => tag.DATE_ARCHIVED === null)
      .map((item) => {
        return {
          value: item.ID,
          name: item.NAME,
        };
      });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return (
      <FormElement
        hint="Pick Multiple"
        id={this.id}
        {...this.props}
        iconPadding
        ignoreShadow
        tooltip={{data: "Optional tags that can be used for filtering reports"}}
      >
        {() => {
          return (
            <FilterDropdown
              label="Product Tags"
              data={data}
              plural={"Tags"}
              noMl
              {...this.props}
            />
          );
        }}
      </FormElement>
    );
  }
}

export default ProductTagsDropdown;
