import moment from "moment";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

export const TIMEFRAMES = {
  SUMMARY: "TIMESPAN",
  DAILY: "DAY",
  WEEKLY: "WEEK",
  MONTHLY: "MONTH",
};

export const CELL_TYPES = {
  BOLD: "bold",
  REGULAR: "regular",
  SECONDARY: "secondary",
  NEGATIVE: "negative",
  FLOAT_LEFT: "float-left",
};

export const SALES_SUMMARY_ROWS = [
  {
    label: "Gross Sales",
    selector: "GROSS_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Product Sales",
    selector: "PRODUCT_SALES",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Auxiliary Sales",
    selector: "OTHER_SALES",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Discounts",
    selector: "DISCOUNTS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    selector: "NET_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Tips",
    selector: "TIPS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Card Tips",
    selector: "CARD_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Cash Tips",
    selector: "CASH_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Collected Fees",
    selector: "FEES_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Tax",
    selector: "TAXES",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Total Collected",
    selector: "TOTAL_COLLECTED",
    type: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Refunds",
    selector: "REFUNDS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Processing",
    selector: "PROCESSING_FEES",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    selector: "NET_TOTAL",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const PAYMENT_METHODS_COLUMNS = [
  {
    label: "Total Collected",
    columnSelector: "TOTAL_COLLECTED",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
  },
  {
    label: "(Refund Amount)",
    columnSelector: "REFUND_AMOUNT",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Payment",
    columnSelector: "NET_PAYMENT",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    columnType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const PRODUCT_SALES_COLUMNS = [
  {label: "Category", columnSelector: "CATEGORY_NAME"},
  {label: "Products Sold", columnSelector: "ORDER_COUNT"},
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Item Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    columnType: CELL_TYPES.NEGATIVE,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
  },
  {label: "Tax", columnSelector: "TAXES", dollarAmount: true, style: []},
  {
    label: "Total Collected",
    columnSelector: "TOTAL_COLLECTED",
    dollarAmount: true,
    style: [],
  },
  {label: "Refunds", columnSelector: "REFUNDS", dollarAmount: true, style: []},
  {
    label: "Net Payment",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
  },
];

export const CATEGORY_SALES_COLUMNS = [
  {label: "Quantity Sold", columnSelector: "ORDER_COUNT"},
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "(Item Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {label: "Tax", columnSelector: "TAXES", dollarAmount: true},
  {
    label: "Total Collected",
    columnSelector: "TOTAL_COLLECTED",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Net Payment",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const MODIFIER_SALES_COLUMNS = [
  {label: "Order Count", columnSelector: "orderCount"},
  {label: "Price", columnSelector: "price", dollarAmount: true},
  {
    label: "Total Modifier Sales",
    columnSelector: "totalSales",
    dollarAmount: true,
  },
];

export const INGREDIENT_SALES_COLUMNS = [
  {label: "Ingredient SKU", columnSelector: "sku"},
  {label: "Unit Cost", columnSelector: "cost", dollarAmount: true},
  {label: "Sold Amount", columnSelector: "soldAmount", number: true},
  {
    label: "Total Cost",
    columnSelector: "totalCost",
    dollarAmount: true,
  },
];

export const INVENTORY_SALES_COLUMNS = [
  {label: "Amount Added", columnSelector: "added", number: true},
  {label: "Amounted Removed", columnSelector: "removed", number: true},
];

export const PLATFORM_SALES_COLUMNS = [
  {
    label: "Order Count",
    columnSelector: "PAYMENT_COUNT",
  },
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Card Tips",
    columnSelector: "CARD_TIPS",
    dollarAmount: true,
  },
  {
    label: "Fees",
    columnSelector: "FEES_COLLECTED",
    dollarAmount: true,
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
  },
  {
    label: "Total Collected",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];
export const GIFT_CARD_SALES_COLUMNS = [
  {
    label: "Cards Sold",
    columnSelector: "ORDER_COUNT",
  },
  {
    label: "Card Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
  },
  {
    label: "Cards Total Collected",
    columnSelector: "TOTAL_COLLECTED",
    dollarAmount: true,
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
  },
  {
    label: "Card Net Payment",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
  },
];

export const TIME_CARD_COLUMNS = [
  {
    label: "Hours Scheduled",
    columnSelector: "AMOUNT_HOURS_SCHEDULED",
  },

  {
    label: "Tips Earned",
    columnSelector: "AMOUNT_TIPS",
    dollarAmount: true,
  },
  {
    label: "Average Wage",
    columnSelector: "AVERAGE_WAGE",
    dollarAmount: true,
  },
];

export const TAXABLE_COLUMNS = [
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
  },
  {
    label: "Taxable Sales",
    columnSelector: "TAXABLE",
    dollarAmount: true,
  },
  {
    label: "Non-Taxable Sales",
    columnSelector: "NON_TAXABLE",
    dollarAmount: true,
  },
  {
    label: "Third Party Sales",
    columnSelector: "THIRD_PARTY_NET_SALES",
    dollarAmount: true,
  },
  {
    label: "Tax Collected",
    columnSelector: "TAXES",
    dollarAmount: true,
  },
];

export const TAX_COLUMNS = [
  {label: "Tax Collected", columnSelector: "AMOUNT", dollarAmount: true},
];

export const DISCOUNT_SUMMARY_COLUMNS = [
  {
    label: "Quantity Used",
    columnSelector: "COUNT",
  },
  {
    label: "Total Discount",
    columnSelector: "AMOUNT_DISCOUNT",
    dollarAmount: true,
  },
];

export const LABOR_VS_SALES_COLUMNS = [
  {
    label: "Location",
    columnSelector: "LOCATION_NAME",
  },
  {
    label: "Labor Cost",
    columnSelector: "LABOR_COST",
    dollarAmount: true,
  },
  {
    label: "Sales",
    columnSelector: "SALES",
    dollarAmount: true,
  },
  {
    label: "Percentage",
    columnSelector: "LABOR_VS_SALES_PERCENTAGE",
    percentage: true,
  },
];

export const CASH_DRAWERS_COLUMNS = [
  {
    label: "Close Time",
    columnSelector: "DATE_CLOSE",
    csvFormat: (value, row) => {
      return moment(value).format("MMM DD, hh:mm A");
    },
    format: (value) => {
      if (value) {
        return moment(value).format("MMM DD, hh:mm A");
      }
    },
  },
  {
    label: "Device",
    columnSelector: "DEVICE_NAME",
  },
  {
    label: "Opening Amount",
    columnSelector: "AMOUNT_OPEN",
    dollarAmount: true,
  },
  {
    label: "Cash Sales",
    columnSelector: "TOTAL_COLLECTED",
    dollarAmount: true,
  },
  {
    label: "Paid In/Out",
    columnSelector: "PAYIN_TOTAL",
    dollarAmount: true,
  },
  {
    label: "Expected Amount",
    columnSelector: "AMOUNT_EXPECTED",
    dollarAmount: true,
  },
  {
    label: "Closing Amount",
    columnSelector: "AMOUNT_CLOSE",
    dollarAmount: true,
  },
  {label: "Difference", columnSelector: "DIFFERENCE", dollarAmount: true},
  {
    label: "Deposit",
    columnSelector: "AMOUNT_DEPOSIT",
    dollarAmount: true,
    tooltip: "Closing amount less the opening amount.",
  },
  {
    label: "Status",
    columnSelector: "DATE_OPEN",
    csvFormat: (value, row) => {
      if (!row.ID) {
        return <div></div>;
      }

      if (row?.DATE_CLOSE) {
        return "Closed";
      } else {
        return "Open";
      }
    },
    format: (value, row) => {
      if (!row.ID) {
        return <div></div>;
      }

      if (row?.DATE_CLOSE) {
        return <DangerBadge>Closed</DangerBadge>;
      } else {
        return <SuccessBadge>Open</SuccessBadge>;
      }
    },
  },
];

export const CASH_FLOW_COLUMNS = [
  {
    label: "Starting Cash",
    columnSelector: "AMOUNT_OPEN",
    dollarAmount: true,
  },
  {
    label: "Pay In / Out",
    columnSelector: "PAYIN_TOTAL",
    dollarAmount: true,
  },
  {
    label: "Cash Sales",
    columnSelector: "CASH_SALES",
    dollarAmount: true,
  },
  {
    label: "Expected in Drawer",
    columnSelector: "AMOUNT_EXPECTED",
    dollarAmount: true,
  },
  {
    label: "Cash Retained",
    columnSelector: "CASH_REMAINING",
    dollarAmount: true,
  },
  {
    label: "Amount Close",
    columnSelector: "AMOUNT_CLOSE",
    dollarAmount: true,
  },
  {
    columnSelector: "OPEN_CLOSE_DIFFERENCE",
    label: "Excess Cash on Hand",
    dollarAmount: true,
  },
  {
    columnSelector: "BANK_PAYIN_TOTAL",
    label: "Bank Pay In / Out",
    dollarAmount: true,
  },
  {
    label: "Expected Deposit",
    columnSelector: "EXPECTED_DEPOSIT",
    dollarAmount: true,
  },
  {
    label: "Actual Deposit",
    columnSelector: "AMOUNT_DEPOSIT",
    dollarAmount: true,
  },
  {
    label: "Deposit Differential",
    columnSelector: "DEPOSIT_DIFFERENTIAL",
    dollarAmount: true,
  },
];

export const INVOICING_COLUMNS = [
  {
    label: "Invoice",
    columnSelector: "FIRST_NAME",
    style: [CELL_TYPES.FLOAT_LEFT],
    format: (_, invoice) =>
      !!invoice.INVOICE_NUMBER ? (
        <div className={"flex flex-col justify-start"}>
          <div className={"font-bold"}>
            {invoice.FIRST_NAME} {invoice.LAST_NAME}
          </div>

          <div className={"font-md text-sm"}>#{invoice.INVOICE_NUMBER}</div>
        </div>
      ) : (
        <div></div>
      ),

    csvFormat: (_, invoice) =>
      invoice.FIRST_NAME +
      " " +
      invoice.LAST_NAME +
      " (" +
      invoice.INVOICE_NUMBER +
      ")",
  },
  {
    label: "Location",
    columnSelector: "LOCATION_NAME",
  },
  {
    label: "Date Paid",
    columnSelector: "PAID_DATE",
  },
  {
    label: "Taxable Sales",
    columnSelector: "TAXABLE_SALES",
    dollarAmount: true,
  },
  {
    label: "Nontaxable Sales",
    columnSelector: "UNTAXABLE_SALES",
    dollarAmount: true,
  },
  {
    label: "Discounts",
    columnSelector: "AMOUNT_DISCOUNT",
    dollarAmount: true,
    format: (val) => "(" + toDollars(val, true) + ")",
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
  },
  {
    label: "Tips",
    columnSelector: "AMOUNT_TIP",
    dollarAmount: true,
  },
  {
    label: "Service Fees",
    columnSelector: "AMOUNT_FEES",
    dollarAmount: true,
  },
  {
    label: "Taxes",
    columnSelector: "AMOUNT_TAX",
    dollarAmount: true,
  },
  {
    label: "Refunds",
    columnSelector: "REFUNDS",
    format: (val) => "(" + toDollars(val, true) + ")",
  },
  {
    label: "Processing Fees",
    columnSelector: "AMOUNT_PROCESSING",
    format: (val) => "(" + toDollars(val, true) + ")",
  },
  {
    label: "Total",
    columnSelector: "TOTAL",
    dollarAmount: true,
  },
];

export const CUSTOM_PAYMENT_TYPE_TIPS_COLUMNS = [
  {
    label: "Tip",
    columnSelector: "AMOUNT_TIPS",
    dollarAmount: true,
  },
];

export const STATE_OVERTIME_RULES = {
  AL: {WEEKLY: true},
  AK: {
    WEEKLY: true,
    DAILY: true,
  },
  AZ: {
    WEEKLY: true,
  },
  AR: {WEEKLY: true},
  CA: {
    DAILY: true,
    DAILY_DOUBLE: true,
    SEVENTH_DAY: true,
    WEEKLY: true,
  },
  CO: {
    DAILY: true,
    WEEKLY: true,
  },
  CT: {WEEKLY: true},
  DE: {WEEKLY: true},
  DC: {WEEKLY: true},
  FL: {WEEKLY: true},
  GA: {WEEKLY: true},
  HI: {WEEKLY: true},
  ID: {WEEKLY: true},
  IL: {WEEKLY: true},
  IN: {WEEKLY: true},
  IA: {WEEKLY: true},
  KS: {WEEKLY: true},
  KY: {
    SEVENTH_DAY: true,
    WEEKLY: true,
  },
  LA: {WEEKLY: true},
  ME: {WEEKLY: true},
  MD: {WEEKLY: true},
  MA: {WEEKLY: true},
  MI: {WEEKLY: true},
  MN: {WEEKLY: true},
  MS: {WEEKLY: true},
  MO: {WEEKLY: true},
  MT: {WEEKLY: true},
  NE: {WEEKLY: true},
  NV: {
    DAILY: true,
    WEEKLY: true,
  },
  NH: {WEEKLY: true},
  NJ: {WEEKLY: true},
  NM: {WEEKLY: true},
  NY: {WEEKLY: true},
  NC: {WEEKLY: true},
  ND: {WEEKLY: true},
  OH: {WEEKLY: true},
  OK: {WEEKLY: true},
  OR: {WEEKLY: true},
  PA: {WEEKLY: true},
  RI: {WEEKLY: true},
  SC: {WEEKLY: true},
  SD: {WEEKLY: true},
  TN: {WEEKLY: true},
  TX: {WEEKLY: true},
  UT: {WEEKLY: true},
  VT: {WEEKLY: true},
  VA: {WEEKLY: true},
  WA: {WEEKLY: true},
  WV: {WEEKLY: true},
  WI: {WEEKLY: true},
  WY: {WEEKLY: true},
};

export const PAYOUT_ROWS = [
  {
    label: "Gross Sales",
    selector: "GROSS_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Discounts",
    selector: "DISCOUNTS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    selector: "NET_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Tips",
    selector: "CARD_TIPS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Collected Fees",
    selector: "FEES_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Taxes",
    selector: "TAXES",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Total Collected",
    selector: "TOTAL_COLLECTED",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Refunds",
    selector: "REFUND_AMOUNT",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Fees",
    selector: "PROCESSING_FEES",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    selector: "NET_TOTAL",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Payout Fee",
    selector: "PAYOUT_FEE",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Payout",
    selector: "PAYOUT_TOTAL",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const EXTRA_DATA_COLUMN = {
  label: "Other Info",
  key: "EXTRA_DATA",
};
