export const REDEEM_RULE_TYPES = {
  TICKET: 0,
  PRODUCT: 1,
  CATEGORY: 2,
  ALL_PRODUCTS: 3,
};

export const EARN_RULE_TYPES = {
  TICKET: 0,
  PRODUCT: 1,
  CATEGORY: 2,
}
