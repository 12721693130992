import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class GiftCardsTable extends Component {
  render() {
    const {cards} = this.props;

    return (
      <Table
        pagination
        data={cards}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) =>
              this.props.router.navigate("/gifts/" + row.UNIQUE_ID),
          },
        ]}
        columns={[
          {
            value: "BALANCE",
            label: "Balance",
            format: (val) => "$" + toDollars(val),
          },
          {
            width: 1,
            value: "DATE_UPDATED",
            label: "Last Used",
            format: (value) => {
              return moment(value).format("MM/DD/YY");
            },
          },
          {
            width: 1,
            value: "DATE_CREATED",
            label: "Date Purchased",
            format: (value) => {
              return moment(value).format("MM/DD/YY");
            },
          },
        ]}
      />
    );
  }
}

GiftCardsTable.propTypes = {
  cards: PropTypes.array.isRequired,
};

export default withRouter(GiftCardsTable);
