import moment from "moment/moment";

export function fetchUnpaidBreaksTotalMinutes(breakArr) {
  if (!breakArr || breakArr.length === 0) {
    return 0;
  }

  return breakArr.reduce((accum, item) => {
    if (!item.IS_PAID) {
      const minuteDiff = moment(item.DATE_END).diff(
        moment(item.DATE_START),
        "minutes"
      );

      if (!isNaN(minuteDiff)) {
        accum += Math.max(0, minuteDiff);
      }
    }

    return accum;
  }, 0);
}
