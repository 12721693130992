import React, {Component} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {REAL_HISTORY} from "../index";

let Link = null;

try {
  require.resolve("react-router-dom");

  Link = require("react-router-dom").Link;
} catch (e) {}

class Breadcrumbs extends Component {
  render() {
    const {data: breadcrumbs} = this.props;

    let lastHistory = null;
    if (REAL_HISTORY.length > 0) {
      lastHistory = REAL_HISTORY.slice(-breadcrumbs.length);
    }

    return (
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <Link
            to={breadcrumbs[breadcrumbs.length - 1].url}
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Back
          </Link>
        </nav>

        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {breadcrumbs.map((item, index) => {
              let {label, url} = item;

              if (lastHistory) {
                for (let history of lastHistory) {
                  if (history.startsWith(url) && url.length !== 1) {
                    url = history;
                  }
                }
              }

              return (
                <li>
                  <div className="flex items-center">
                    {index > 0 && (
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}

                    <Link
                      to={url}
                      className={classNames(
                        index > 0 && "ml-4",
                        "text-sm font-medium text-gray-500 hover:text-gray-700"
                      )}
                    >
                      {label}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumbs;
