import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import LocationDropdown from "../../../features/reporting/reporting-filter/location-dropdown";

class ExtraPayModal extends Component {
  state = {
    payrollId: null,
    employeeId: null,
    name: null,
    employeeLocations: null,
    employeeHomeLocationId: null,
    type: "REGULAR"
  };

  openNew(payrollId, employeeId, employeeHomeLocationId, type, name) {
    this.setState(
      {
        payrollId,
        employeeId,
        employeeHomeLocationId,
        type,
        name,
        pay: {HOURS: "", AMOUNT: ""},
      },
      this.modal.open()
    );
  }

  open(payrollId, employeeId, employeeHomeLocationId, type, name, pay) {
    this.setState(
      {payrollId, employeeId, type, name, pay},
      this.modal.open()
    );
  }

  delete = async () => {
    let {employeeHomeLocationId} = this.state;

    await this.submitRegularPay(0, 0, employeeHomeLocationId);
    await this.props.refresh();

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {hours, amount, location} = values;

    await this.submitRegularPay(hours, amount, location);
    await this.props.refresh();

    this.modal.close();
  };

  submitRegularPay = async (hours, amount, location) => {
    let {payrollId, employeeId, pay, type} = this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: employeeId,
      LOCATION_ID: location,
      HOURS: hours,
      TYPE: PAY_TYPES[type].CHECK_TYPE,
      AMOUNT: decimalToDollars(amount),
      ID: pay?.ID,
    };

    await request(`payroll/run-payroll/set-regular`, "POST", payload);
  };

  render() {
    let {name, pay, type} = this.state;
    let {dbaLocations} = this.props;
    let {employeeHomeLocationId} = this.state;

    return (
      <Modal
        label={`${PAY_TYPES[type].LABEL} Pay for ${name}`}
        buttonLabel="Save"
        deleteLabel="Delete"
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            hours: pay ? pay.HOURS : "",
            amount: pay ? toDollars(pay.AMOUNT) : "",
            location: pay?.LOCATION_ID
              ? pay.LOCATION_ID
              : employeeHomeLocationId,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label={"Hours"}
                  name={"hours"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Amount (in $)"}
                  name={"amount"}
                  options={formikOptions}
                />

                {dbaLocations?.length > 1 && (
                  <FormSelect
                    label={"Location"}
                    name={"location"}
                    options={formikOptions}
                    data={dbaLocations.map((loc) => ({
                      label: loc.NAME,
                      value: loc.ID,
                    }))}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ExtraPayModal;

export const PAY_TYPES = {
  REGULAR: {
    VALUE: "REGULAR",
    LABEL: "Regular",
    CHECK_TYPE: "regular"
  },
  OVERTIME: {
    LABEL: "Overtime",
    VALUE: "OVERTIME",
    CHECK_TYPE: "overtime"
  },
  DOUBLE_OVERTIME: {
    LABEL: "Double Overtime",
    VALUE: "DOUBLE_OVERTIME",
    CHECK_TYPE: "double_overtime"
  }
}
