import React, {Component} from "react";
import {
  FormInput,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import LocationsDropdown from "../../dropdowns/team/locations-dropdown";
import FormRow from "../../components/form-row";
import FormDropZone from "../../components/form-drop-zone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import * as Yup from "yup";
import ImageFormDropZone from "../../components/image-form-drop-zone";

class NewsDetailsModal extends Component {
  state = {news: null};

  open(news = null) {
    this.setState({news}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createNews({
    title,
    logo,
    subtitle,
    content,
    location,
    release,
    expires,
  }) {
    const memoPayload = {
      TITLE: title,
      SUBTITLE: subtitle,
      CONTENT: content,
      LOGO: logo,
      PINNED: true,
      LOCATION_ID: location,
      DATE_POSTED: release,
      DATE_EXPIRES: expires,
    };

    const serverNews = await request("dashboard/news", "POST", memoPayload);

    this.props.addState(serverNews);
    this.modal.close();
  }

  async saveNews({title, logo, subtitle, content, location, release, expires}) {
    const {news} = this.state;

    const memoPayload = {
      TITLE: title,
      SUBTITLE: subtitle,
      CONTENT: content,
      LOGO: logo,
      LOCATION_ID: location,
      DATE_POSTED: release,
      DATE_EXPIRES: expires,
    };

    const serverNews = await request(
      "dashboard/news/" + news.ID,
      "PATCH",
      memoPayload
    );

    this.props.updateState(serverNews);
    this.modal.close();
  }

  render() {
    const {news} = this.state;

    const schema = Yup.object({
      title: Yup.string().required("Title is required."),
      location: Yup.string().nullable(),
      subtitle: Yup.string().required("Subtitle is required."),
      release: Yup.string().required("Release is required."),
      content: Yup.string().required("Content is required."),
    });

    return (
      <Modal
        large
        buttonLabel={news ? "Save" : "Create"}
        label={news ? "Edit News" : "Create News"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            news ? this.saveNews.bind(this) : this.createNews.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            title: news?.TITLE ?? "",
            logo: news?.LOGO ?? "",
            location: news?.LOCATION_ID ?? null,
            subtitle: news?.SUBTITLE ?? "",
            content: news?.CONTENT ?? "",
            release: news?.DATE_POSTED ?? null,
            expires: news?.DATE_EXPIRES ?? null,
          }}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Title"
                  name="title"
                  options={formikOptions}
                  flex
                />

                <LocationsDropdown
                  options={formikOptions}
                  name="location"
                  allowNull
                />

                <FormInput
                  label="Subtitle"
                  name="subtitle"
                  options={formikOptions}
                  flex
                />

                <FormRow>
                  <FormDateTimeSelect
                    label="Release Date"
                    name="release"
                    options={formikOptions}
                    flex
                  />

                  <FormDateTimeSelect
                    label="Expiration Date"
                    name="expires"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <ImageFormDropZone
                  name="logo"
                  label="News Post Logo"
                  options={formikOptions}
                  tooltip={{
                    label: "Finding Menu CSV",
                    url: "test",
                    data: "Blah blah blah",
                  }}
                />

                <FormTextArea
                  style={{minHeight: 200}}
                  options={formikOptions}
                  label="Content"
                  name="content"
                  hint={"Optional"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default NewsDetailsModal;
