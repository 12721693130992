import React, {Component} from "react"
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class CompanyDropdown extends Component{
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching company data", err);
    });
  }

  async syncData() {
    const serverCompanies = await request("admin/companies", "GET");
    const data = serverCompanies.map((item) => {
      return {
        id: item.ID,
        label: item.NAME,
      };
    });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return (
      <ComboBox autoComplete="off" label="Company" {...this.props} data={data} />
    );
  }
}

CompanyDropdown.propTypes = {
  ...ComboBox.propTypes,
}

export default CompanyDropdown;
