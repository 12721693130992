import React, {Component} from "react";
import PropTypes from "prop-types";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";

class CouponCard extends Component {
  render() {
    const {handleCard, coupon} = this.props;

    return (
      <TwoColumnList
        label="Coupon Information"
        description="What code is the customer going to enter"
        buttons={[
          {
            label: "Edit Information",
            onClick: handleCard,
          },
        ]}
        data={[
          {
            label: "Code",
            value: coupon.CODE,
            span: true,
          },
          {
            label: "Number of Redemptions",
            value: coupon.NUM_REDEEMS,
          },
          {
            label: "Max Number of Redemptions",
            value: coupon.MAX_REDEEMS ?? "-",
            tooltip: {
              data: "The total number of times this coupon can be redeemed.",
            },
          },
          {
            label: "Max Number of Redemptions for an Individual",
            value: coupon?.MAX_ACCOUNT_REDEEMS ?? "-",
            tooltip: {
              data: "The total number of times this coupon can be redeemed by a single patron.",
            },
          },
        ]}
      />
    );
  }
}

CouponCard.propTypes = {
  handleCard: PropTypes.func.isRequired,
  coupon: PropTypes.object.isRequired,
};

export default CouponCard;
