import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";
import PolicyDropdown from "../../../dropdowns/team/policy-dropdown";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class PolicyEmployeeModal extends Component {
  state = {category: null};

  open(category = null) {
    this.setState({category}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createPolicyCategory({name, notice}) {
    const {categories} = this.props;

    const seq = Math.max(categories.map((item) => item.SEQ || 0)) + 1;

    const categoryPayload = {
      ID: "toc_" + randomString(24),

      NAME: name,
      ADVANCED_NOTICE: notice,
      SEQ: seq,
    };

    this.props.addState(categoryPayload);
    this.modal.close();
  }

  async savePolicyCategory({name, notice}) {
    const {category} = this.state;

    const categoryPayload = {
      ...category,

      NAME: name,
      ADVANCED_NOTICE: notice,
    };

    this.props.updateState(categoryPayload.ID, categoryPayload);
    this.modal.close();
  }

  async deletePolicyCategory() {
    const {category} = this.state;

    this.props.updateState(category.ID);
    this.modal.close();
  }

  render() {
    const {category} = this.state;

    return (
      <Modal
        buttonLabel={category ? "Save" : "Add"}
        deleteLabel={category ? "Remove" : null}
        label={category ? "Edit Policy Category" : "Add Policy Category"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deletePolicyCategory()}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={
            category
              ? this.savePolicyCategory.bind(this)
              : this.createPolicyCategory.bind(this)
          }
          initialValues={{
            name: category?.NAME ?? "",
            notice: category?.ADVANCED_NOTICE ?? "0",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" options={formikOptions} label="Name" />

                <FormInput
                  name="notice"
                  label="Notice Needed"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PolicyEmployeeModal.propTypes = {
  categories: PropTypes.array.isRequired,

  addState: PropTypes.func,
  updateState: PropTypes.func,
};

export default PolicyEmployeeModal;
