import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import moment from "moment";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import FormDateTimeSelect from "../../components/form-date-time-select";

class TipBucketModal extends Component {
  state = {tipBucket: {}};

  open(tipBucket) {
    this.slide.open();
    this.setState({tipBucket});
  }

  updateTipBucket = async (values) => {
    let {amount, date} = values;
    let {ID} = this.state.tipBucket;

    if (amount) {
      await request(`tips/bucket/update/${ID}`, "PATCH", {
        AMOUNT: decimalToDollars(amount),
        DATE_CREATED: date,
      });
    }

    this.props.updateState && this.props.updateState();
    this.slide.close();
  };

  render() {
    let {DATE_CREATED, AMOUNT} = this.state.tipBucket;

    return (
      <Modal
        label="Edit Tip Bucket"
        buttonLabel="Save"
        ref={(e) => (this.slide = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.updateTipBucket}
          initialValues={{
            amount: toDollars(AMOUNT),
            date: DATE_CREATED,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <FormDateTimeSelect
                    name="date"
                    label="Date Created"
                    options={formikOptions}
                  />

                  <FormInput
                    name="amount"
                    label="Amount"
                    options={formikOptions}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TipBucketModal;
