import React, {Component} from "react";
import {request} from "../utils/request";
import MultiSelectCombobox from "./multi-select-combobox";

class MultiEmployeeSelect extends Component {
  constructor(props) {
    super();
    this.state = {data: []};
  }

  async componentDidMount() {
    await this.syncData();
  }

  async syncData() {
    const {exclude = [], allowNull} = this.props;
    const serverEmployees = await request("employees/v2", "GET");

    const data = Object.values(
      serverEmployees.reduce((dict, item) => {
        if (!dict[item.LOCATION_ID]) {
          dict[item.LOCATION_ID] = [item];
        } else {
          dict[item.LOCATION_ID].push(item);
        }

        return dict;
      }, {})
    ).map((item) => {
      return {
        title: item[0].LOCATION_NAME,
        data: item
          .filter((item) => !exclude.includes(item.ID))
          .map((item) => {
            return {id: item.ID, label: item.FULL_NAME, unique: item.UNIQUE_ID};
          }),
      };
    });

    this.setState({data});
  }

  render() {
    const {data} = this.state;
    const {options, name} = this.props;

    return (
      <MultiSelectCombobox
        data={data}
        headerIcon={"fa-solid fa-store"}
        options={options}
        name={name}
        {...this.props}
      />
    );
  }
}

export default MultiEmployeeSelect;
