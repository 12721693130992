import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormTextArea,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";
import {request} from "../../../utils/request";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import ProductTagsDropdown from "../../../dropdowns/sales/product-tags-dropdown";
import * as Yup from "yup";
import ColorDropdown from "../../../features/team/color-dropdown";
import {defaultProductImages} from "../../../pages/sales/menu/products/create-product-page";
import PropTypes from "prop-types";
import FormRow from "../../../components/form-row";

class ProductDetailsModal extends Component {
  state = {product: null, isEnable: false};

  open(product = null, isEnable = false) {
    this.setState({product, isEnable}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveProduct = async ({
    name,
    category,
    description,
    instructions,
    oneClick,
    tax,
    logo,
    pos,
    kiosk,
    mobile,
    third,
    autoComplete,
    taxOverride,
    advancedTime,
    alcoholic,
    tags,
    color,
    minQty,
    maxQty,
  }) => {
    let logoLow = defaultProductImages.includes(logo) ? logo : `LOW_${logo}`;

    let productPayload = {
      NAME: name,
      LOGO: logo,
      LOGO_LOW: logoLow,
      CATEGORY_ID: category,
      DESCRIPTION: description,
      INSTRUCTIONS: instructions,
      ENABLED: parseInt(mobile),
      ONE_CLICK: parseInt(oneClick),
      ALCOHOLIC: parseInt(alcoholic),
      POS_ENABLED: parseInt(pos),
      TAX_DISABLED: parseInt(tax),
      KIOSK_ENABLED: parseInt(kiosk),
      THIRD_PARTY_ENABLED: parseInt(third),
      ADVANCED_TIME: advancedTime,
      AUTO_COMPLETE: autoComplete,
      TAX_OVERRIDE: taxOverride,
      ICON_COLOR: color,
      MIN_QTY: parseInt(minQty),
      MAX_QTY: parseInt(maxQty),
    };

    let {product} = this.state;

    const serverProduct = await request(
      "products/" + product.ID,
      "PATCH",
      productPayload
    );

    if (typeof this.props.initialTags !== "undefined") {
      await request("tags/product/" + product.ID, "PATCH", {tags});
    }

    await this.props.updateProduct(serverProduct);
    this.modal.close();
  };

  render() {
    const {product, isEnable} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Product"
        description="Edit the configuration of this product"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: product?.NAME,
            category: product?.CATEGORY_ID,
            description: product?.DESCRIPTION,
            instructions: product?.INSTRUCTIONS,
            oneClick: product?.ONE_CLICK,
            alcoholic: product?.ALCOHOLIC,
            tax: product?.TAX_DISABLED,
            pos: product?.POS_ENABLED,
            kiosk: product?.KIOSK_ENABLED,
            third: product?.THIRD_PARTY_ENABLED,
            mobile: product?.ENABLED,
            advancedEnabled: product?.ADVANCED_TIME !== null ? "1" : "0",
            advancedTime: product?.ADVANCED_TIME,
            autoComplete: product?.AUTO_COMPLETE,
            taxOverride: product?.TAX_OVERRIDE,
            logo: product?.LOGO,
            color: product?.ICON_COLOR,
            minQty: product?.MIN_QTY,
            maxQty: product?.MAX_QTY,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Please input a name for your product"),
            category: Yup.string()
              .nullable()
              .required("Please select a valid category"),
            minQty: Yup.number()
              .integer()
              .positive("Minimum quantity must be positive.")
              .nullable()
              .typeError("Min quantity must be a number."),
            maxQty: Yup.number()
              .integer()
              .positive("Maximum quantity must be positive.")
              .nullable()
              .typeError("Min quantity must be a number."),
            description: isEnable
              ? Yup.string().nullable()
              : Yup.string()
                  .nullable()
                  .test(
                    "description-length-check",
                    "Description must be less than 256 characters",
                    (val) => !val || val.length < 256
                  ),
          })}
          onSubmit={this.saveProduct}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {!isEnable && (
                  <>
                    <FormInput
                      label="Name"
                      name="name"
                      options={formikOptions}
                    />

                    <CategoryDropdown name="category" options={formikOptions} />
                  </>
                )}

                <FormBoolean
                  label="Point of Sale Enabled"
                  options={formikOptions}
                  name="pos"
                  tooltip="Will this product show up on the point of sale"
                  flex
                />

                <FormBoolean
                  label="Mobile Enabled"
                  options={formikOptions}
                  name="mobile"
                  tooltip="Can customers order this product on the mobile ordering app and website"
                  flex
                />

                <FormBoolean
                  label="Kiosk Enabled"
                  options={formikOptions}
                  tooltip="Can customers order this product on kiosks"
                  name="kiosk"
                />

                <FormBoolean
                  label="Third Party Enabled"
                  options={formikOptions}
                  tooltip="Will this product show up on third party order websites"
                  name="third"
                />

                {!isEnable && (
                  <>
                    <FormRow>
                      <FormBoolean
                        label="Tax Disabled"
                        options={formikOptions}
                        name="tax"
                        tooltip="Is tax disabled for this product"
                        flex
                      />

                      <FormBoolean
                        label="Tax Override"
                        options={formikOptions}
                        tooltip="If this is set to yes, then taxes will be applied to this product even if it is ordered with a tax disabled order type. If taxes are explicitly toggled off with the tax toggle on the POS, then taxes will not be charged."
                        name="taxOverride"
                        flex
                      />
                    </FormRow>

                    <FormBoolean
                      label="One Click"
                      options={formikOptions}
                      tooltip="Will clicking on this item on the POS add it directly to the ticket without opening the modifiers screen"
                      name="oneClick"
                    />

                    <FormBoolean
                      label="Is Alcohol"
                      options={formikOptions}
                      tooltip="Is this product, or does this product contain alcohol"
                      name="alcoholic"
                    />

                    <FormBoolean
                      label="Auto complete product"
                      options={formikOptions}
                      tooltip="If a product has this field set to yes, then this product will be automatically completed on purchase. This setting only applies to orders placed on the register."
                      name="autoComplete"
                    />

                    <FormBoolean
                      label="Require advanced notice"
                      options={formikOptions}
                      tooltip="Does this product need advanced notice when ordering"
                      name="advancedEnabled"
                    />

                    {values.advancedEnabled === "1" && (
                      <FormInput
                        label="Days in Advanced Notice"
                        options={formikOptions}
                        tooltip="How many days in advance must notice be given to order this product"
                        name="advancedTime"
                      />
                    )}

                    <FormInput
                      label="Minimum Quantity"
                      options={formikOptions}
                      tooltip="The minimum quantity that must be ordered for mobile and web orders. This does not impact POS orders. Remove this field for no minimum."
                      placeholder="2"
                      name="minQty"
                    />

                    <FormInput
                      label="Maximum Quantity"
                      options={formikOptions}
                      tooltip="The maximum quantity that can be ordered for a single mobile or web order. This does not impact POS orders. Default is 50."
                      placeholder="15"
                      name="maxQty"
                    />

                    <ProductTagsDropdown
                      onChange={(tagIds) => setFieldValue("tags", [...tagIds])}
                      label="Product Tags"
                      name="tags"
                      productId={product.ID}
                      initialValues={this.props.initialTags}
                      options={formikOptions}
                    />

                    <FormTextArea
                      label={`Description`}
                      name="description"
                      options={formikOptions}
                    />

                    <FormTextArea
                      label="Order Instructions"
                      name="instructions"
                      options={formikOptions}
                    />

                    <ColorDropdown
                      removable={true}
                      name={"color"}
                      label={"Color Background for the POS"}
                      options={formikOptions}
                      tooltip={
                        "Optionally select an icon to display on the register screen of the POS. The icon will have the selected background color and a two letter abbreviation of the product."
                      }
                    />

                    <ImageFormDropZone
                      name="logo"
                      label="Product Image"
                      options={formikOptions}
                    />
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ProductDetailsModal.propTypes = {
  isEnable: PropTypes.bool,
};

export default ProductDetailsModal;
