import React, {Component} from "react";
import PropTypes from "prop-types";
import ButtonRow from "../buttons/button-row";
import Breadcrumbs from "../breadcrumbs";
import StatusLabel from "../status-label";

class PageHeadings extends Component {
  renderBreadcrumbs() {
    const {breadcrumbs = null} = this.props;

    if (breadcrumbs === null || breadcrumbs.length === 0) return;

    return <Breadcrumbs data={breadcrumbs} />;
  }

  renderHead() {
    const {center, label, description, logo, status} = this.props;

    if (center) {
      return (
        <div className="mx-auto text-center">
          <div className="py-24">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
              {label}
            </h1>

            <p className="mt-4 max-w-3xl mx-auto text-base text-gray-500">
              {description}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="mt-2 md:flex md:items-center md:justify-between">
        {logo && (
          <div className="mr-3">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className={`${
                    this.props.logoCover ? "object-cover " : ""
                  }h-16 w-16 rounded-full`}
                  src={logo}
                  alt=""
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {label}
          </h2>

          {description && (
            <p className="text-sm font-medium text-gray-500">{description}</p>
          )}
        </div>

        <div className="flex flex-row gap-x-4 mt-2 sm:mt-0">
          {status && <StatusLabel {...status} />}

          <ButtonRow {...this.props} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderBreadcrumbs()}

        {this.renderHead()}
      </div>
    );
  }
}

PageHeadings.propTypes = {
  ...ButtonRow.propTypes,

  logo: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  center: PropTypes.bool,

  status: StatusLabel.propTypes,

  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default PageHeadings;
