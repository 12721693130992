import React, {Component} from "react";
import {
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";

class PayrollRunErrorModal extends Component {
  state = {runErrors: []};

  open(runErrors) {
    this.setState({runErrors});
    this.modal.open();
  }

  render() {
    let {runErrors} = this.state;

    return (
      <Modal
        label="Run Error"
        ref={(e) => (this.modal = e)}
      >
        {runErrors.map(error => {
          let {fullName, employee, message} = error;
          return (
            <div className="mb-3">
              <div>{fullName}</div>
              <div>{employee}</div>
              <div>{message}</div>
            </div>
          )
        })}
      </Modal>
    );
  }
}

export default PayrollRunErrorModal;
