import React, {Component} from "react";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import moment from "moment-timezone";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {showErrorNotification} from "../../utils/notification-helper";

export const CONFLICT_TYPE = {
  TIME_OFF: "Time Off",
  UNAVAILABILITY: "Unavailability",
};

class CopyWeekScheduleModal extends Component {
  state = {isLoading: false, startWeek: null, conflicts: []};

  async open() {
    let startWeek = await this.props.getCalendarWeekStartEpoch();

    this.setState({startWeek}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.slide.open();
    });
  }

  close = () => {
    this.setState({startWeek: null, open: false}, this.slide.close());
  };

  handleFormSubmission = async ({copyToWeek}) => {
    const {locationIdArr} = this.props;

    const copyToDateEpoch = moment(copyToWeek).valueOf();

    try {
      await request("scheduling/locations/copy", "POST", {
        START_EPOCH: this.state.startWeek,
        END_EPOCH: copyToDateEpoch,
        LOCATION_ID_ARR: locationIdArr,
      });
    } catch (err) {
      return showErrorNotification(
        "Copy Week Error",
        "Error copying week. Please refresh the page and try again."
      );
    }

    this.props.afterUpdate(copyToDateEpoch);

    this.setState({open: false}, () => this.slide.close());
  };

  renderConflicts() {
    const {conflicts} = this.state;

    return (
      <div>
        {conflicts.map((conflict) => {
          const {
            SHIFT: shift,
            TYPE: type,
            DATE_START: dateStart,
            DATE_END: dateEnd,
          } = conflict;

          const shiftDetails = `${shift.FULL_NAME}'s Shift on ${moment(
            shift.DATE_START
          ).format("MMM D, h:mm A")}`;
          const conflictDetails = `${type} from ${dateStart} to ${dateEnd}`;

          return (
            <div
              className={"mt-2 text-red-400 text-sm italic"}
            >{`${shiftDetails} -> ${conflictDetails}`}</div>
          );
        })}
        );
      </div>
    );
  }

  render() {
    let {startWeek, conflicts} = this.state;

    let weekArr = [];
    for (let weeksAway = 1; weeksAway < 5; weeksAway++) {
      let weekStartMoment = moment(startWeek).add(weeksAway, "weeks");

      weekArr.push({
        label: `${
          weeksAway === 1 ? "Next Week" : `In ${weeksAway} Weeks`
        }: ${weekStartMoment.format("M/DD")} - ${weekStartMoment
          .add(6, "days")
          .format("M/D")}`,
        value: moment(startWeek).add(weeksAway, "week").valueOf(),
      });
    }

    return (
      <Modal
        buttonLabel="Copy"
        label="Copy Weekly Schedule"
        description={`${moment(startWeek).format("MMM D")} - ${moment(startWeek)
          .add(6, "days")
          .format("MMM D")}`}
        ref={(e) => (this.slide = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.handleFormSubmission}
          initialValues={{
            copyToWeek: moment(startWeek).add(1, "week").valueOf(),
          }}
          enableReinitialize={true}
          // validationSchema={this.validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form
                onSubmit={handleSubmit}
                className="w-full flex justify-center"
              >
                <FormSelect
                  className={"w-56"}
                  label={"Copy To Week"}
                  name={"copyToWeek"}
                  data={weekArr}
                  options={formikOptions}
                />
                {conflicts.length > 0 && this.renderConflicts()}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["scheduling", "shop"])(
  withRouter(CopyWeekScheduleModal)
);
