import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormTextArea,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import {showErrorAlert} from "../../../utils/alert-helper";

class TaxModal extends Component {
  state = {tax: null};

  open(tax = null) {
    this.setState({tax}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createTax({name, type, rate, notes, inclusive, isDefault}) {
    const taxPayload = {
      NAME: name,
      TYPE: type,
      RATE: rate,
      NOTES: notes,
      IS_DEFAULT: parseInt(isDefault),
      IS_INCLUSIVE: parseInt(inclusive),
    };

    const serverPayload = await request("tax", "POST", taxPayload);

    this.props.addState(serverPayload);
  }

  async saveTax({name, type, rate, notes, inclusive, isDefault}) {
    const {tax} = this.state;

    const taxPayload = {
      NAME: name,
      TYPE: type,
      RATE: rate,
      NOTES: notes,
      IS_DEFAULT: isDefault,
      IS_INCLUSIVE: inclusive,
    };

    if (isDefault === "0") {
      return showErrorAlert(
        "Default Rate Error",
        "You can't mark the default tax rates as not default. You can only make a non defaulted rate the default rate."
      );
    }

    const serverCategory = await request("tax/" + tax.ID, "PATCH", taxPayload);

    this.props.updateState(serverCategory.ID, serverCategory);
    this.modal.close();
  }

  render() {
    const {tax} = this.state;

    return (
      <Modal
        buttonLabel={tax ? "Save" : "Create"}
        label={tax ? "Edit Tax" : "Create Tax"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={tax ? this.saveTax.bind(this) : this.createTax.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: tax?.NAME ?? "",
            rate: tax?.RATE ?? "5.5",
            type: tax?.TYPE ?? "SALES",
            inclusive: tax?.IS_INCLUSIVE ?? "0",
            isDefault: tax?.IS_DEFAULT ?? "1",
            notes: tax?.NOTES ?? "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Tax name is required"),
            rate: Yup.number().required("Tax rate is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    flex
                    name="name"
                    label="Name"
                    placeholder="Hot Coffee"
                    options={formikOptions}
                  />

                  <FormSelect
                    flex
                    name="type"
                    label="Type"
                    options={formikOptions}
                    data={[{value: "SALES", label: "Sales"}]}
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    name="rate"
                    label="Rate"
                    tooltip="The percentage tax rate"
                    options={formikOptions}
                    flex
                  />

                  <FormSelect
                    flex
                    label="Exclusivity"
                    name="inclusive"
                    options={formikOptions}
                    data={[
                      {value: "0", label: "Exclusive"},
                      {value: "1", label: "Inclusive"},
                    ]}
                  />
                </FormRow>

                <FormBoolean
                  tooltip="Will this tax rate be used as default"
                  label="Is Default Rate"
                  name="isDefault"
                  options={formikOptions}
                />

                <FormTextArea
                  label="Notes"
                  name="notes"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TaxModal;
