import React, {Component} from "react";
import StepNavCard from "../../../../features/step-nav-card";
import {onboardCodeToEmployee} from "../../../../utils/payroll-helper";
import LoadingSpinner from "../../../../components/loading-spinner";
import PayrollEmployeeFormSetupComponent from "../../../../features/payroll/onboard/employee/payroll-employee-form-setup-component";
import PayrollEmployeeTaxAuthSetupComponent from "../../../../features/payroll/onboard/employee/payroll-employee-tax-auth-setup-component";
import {withRouter} from "../../../../utils/navigation";
import AlertService from "../../../../layouts/services/alert-service";
import PayrollWelcomeSetupComponent
  from "../../../../features/payroll/onboard/employee/payroll-welcome-setup-component";

class PayrollEmployeeOnboardPage extends Component {
  state = {isLoading: true, employee: null, defaultStep: 0};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let employee = await onboardCodeToEmployee(ID);

    let {STARTED} = employee;

    let defaultStep = 0;
    if (STARTED) {
      defaultStep = 2;
    }

    this.setState({isLoading: false, employee, defaultStep});
  }

  render() {
    let {isLoading, employee, defaultStep} = this.state;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    let {FULL_NAME} = employee;

    return (
      <div className="px-10">
        <AlertService />

        <StepNavCard
          router={this.props.router}
          defaultStep={defaultStep}
          label={`Payroll Setup for ${FULL_NAME}`}
          steps={[
            {
              label: "Welcome",
              component: PayrollWelcomeSetupComponent,
            },
            {
              label: "Basic Information",
              component: PayrollEmployeeFormSetupComponent,
            },
            {
              label: "Tax and Authorization Setup",
              hideBack: true,
              component: PayrollEmployeeTaxAuthSetupComponent,
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(PayrollEmployeeOnboardPage);
