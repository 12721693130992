import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class TaxDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching tax data", err);
    });
  }

  async syncData() {
    const {unique} = this.props;

    const serverTaxRates = await request("tax", "GET");
    const data = serverTaxRates.map((item) => {
      return {
        id: unique ? item.UNIQUE_ID : item.ID,
        label: item.NAME,
      };
    });

    this.setState({data});
  }

  render() {
    const {data} = this.state;
    const {exclude = []} = this.props;

    return (
      <ComboBox
        label="Tax Rates"
        {...this.props}
        data={data.filter((item) => {
          return !exclude.includes(item.id);
        })}
      />
    );
  }
}

export default TaxDropdown;
