import React, {Component} from "react";
import {getURL, isQE, request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Loading,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import CustomerModal from "../../../modals/operations/invoices/contact-modal";
import InvoiceCard from "../../../features/operations/invoices/invoice-card";
import InvoiceLinesCard from "../../../features/operations/invoices/invoice-lines-card";
import InvoiceModal from "../../../modals/operations/invoices/invoice-modal";
import InvoiceLinesModal from "../../../modals/operations/invoices/invoice-lines-modal";
import {saveAs} from "file-saver";
import InvoicePdfTemplate from "../../../features/operations/invoices/templates/invoice-pdf-template";
import PayInvoiceModal from "../../../modals/operations/invoices/pay-invoice-modal";
import InvoiceRecipientsCard from "../../../features/operations/invoices/invoice-recipients-card";
import SendInvoiceModal from "../../../modals/operations/invoices/send-invoice-modal";
import RemindInvoiceModal from "../../../modals/operations/invoices/remind-invoice-modal";
import InvoiceRemindersCard from "../../../features/operations/invoices/invoice-reminders-card";
import DangerBadge from "../../../components/badges/danger-badge";
import {showSuccessNotification} from "../../../utils/notification-helper";
import InvoiceRecipientModal from "../../../modals/operations/invoices/invoice-recipient-modal";

class InvoicePage extends Component {
  state = {invoice: null, ticket: null};

  componentDidMount() {
    this.syncState();
  }

  async resyncPage() {
    this.setState({invoice: null, ticket: null}, () => {
      this.syncState();
    });
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let invoice = await request("invoices/" + id, "GET", null);

    this.setState({invoice, ticket: invoice.TICKET});
  }

  async sendReminder() {
    const {ID: id} = this.props.router.params;

    await request("invoices/" + id + "/reminder", "POST", {});
  }

  removeCustomer() {
    const {customer} = this.state;

    showLoadingConfirmAlert(
      "Delete Customer",
      "Are you sure you want to delete this customer?"
    ).then(async (close) => {
      await request("customers/" + customer.ID, "DELETE", {});

      close();

      this.props.router.navigate("/customers");
    });
  }

  refundInvoice() {
    const {invoice} = this.state;

    showLoadingConfirmAlert(
      "Refund Invoice",
      "Are you sure you want to refund this invoice?"
    ).then(async (close) => {
      await request("invoices/" + invoice.ID + "/refund", "POST");
      await this.syncState();
      close();

      showSuccessNotification(
        "Invoice Refunded",
        "Invoice #" + invoice.INVOICE_NUMBER + " was successfully refunded."
      );
    });
  }

  deleteInvoice() {
    const {invoice} = this.state;

    showLoadingConfirmAlert(
      "Delete Invoice",
      "Are you sure you want to delete this invoice?"
    ).then(async (close) => {
      await request("invoices/" + invoice.ID, "DELETE");

      await this.syncState();
      close();

      showSuccessNotification(
        "Invoice Delete",
        "Invoice #" + invoice.INVOICE_NUMBER + " was successfully deleted."
      );

      this.props.router.navigate("/invoices");
    });
  }

  render() {
    const {invoice, ticket} = this.state;

    if (invoice === null) {
      return <Loading />;
    }
    const invoiceTabs = [
      {id: "lines", label: "Invoice Lines"},
      {id: "recipients", label: "Additional Recipients"},
    ];

    if (invoice.REMINDERS.length > 0) {
      invoiceTabs.push({id: "reminders", label: "Invoice Reminders"});
    }

    const dropdownActions = [
      {
        label: "Duplicate",
        onClick: () => {
          window.open("/invoice?duplicate=" + invoice.UNIQUE_ID, "_blank");
        },
      },
    ];

    if (invoice.STATUS === "DRAFT" || invoice.STATUS === "OPEN") {
      dropdownActions.push({
        label: "Delete",
        onClick: () => this.deleteInvoice(),
      });
    }

    const headerButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: dropdownActions,
          },
          {
            items: [
              {
                label: "Download PDF",
                onClick: () =>
                  saveAs(
                    getURL() + "invoices/" + invoice.UNIQUE_ID + "/pdf",
                    "pdf"
                  ),
              },
              {
                label: "View Payment Page",
                onClick: () => {
                  if (isQE()) {
                    window.open(
                      "https://qpay.dripos.com/" + invoice.UNIQUE_ID,
                      "_blank"
                    );
                  } else {
                    window.open(
                      "https://pay.dripos.com/" + invoice.UNIQUE_ID,
                      "_blank"
                    );
                  }
                },
              },
            ],
          },
        ],
      },
    ];

    if (invoice.STATUS === "PAID") {
      headerButtons.splice(0, 0, {
        label: "View Receipt",
        onClick: () => {
          window.open("https://pay.dripos.com/receipt/" + invoice.UNIQUE_ID);
        },
      });

      headerButtons.splice(0, 0, {
        label: "Refund Invoice",
        onClick: () => this.refundInvoice(),
      });
    } else if (invoice.STATUS === "DRAFT") {
      headerButtons.splice(0, 0, {
        label: "Send Invoice",
        onClick: () => {
          this.sendModal.open(invoice);
        },
      });
    } else {
      headerButtons.splice(0, 0, {
        label: "Pay Invoice",
        onClick: () => {
          setTimeout(() => this.payModal.open(invoice), 250);
        },
      });

      headerButtons.splice(0, 0, {
        label: "Send Reminder",
        onClick: () => {
          this.remindModal.open(invoice);
        },
      });
    }

    return (
      <div>
        <InvoiceModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.invoiceModal = e)}
        />

        <SendInvoiceModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.sendModal = e)}
        />

        <InvoiceLinesModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.invoiceLinesModal = e)}
        />

        <PayInvoiceModal
          resyncPage={() => this.resyncPage()}
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.payModal = e)}
        />

        <RemindInvoiceModal ref={(e) => (this.remindModal = e)} />

        <CustomerModal
          updateState={(customer) => this.setState({customer})}
          ref={(e) => (this.customerModal = e)}
        />

        <InvoiceRecipientModal
          ref={(e) => (this.recipientModal = e)}
          updateState={(invoice) => this.setState({invoice})}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row "}>
              <div className={"mr-2"}>
                {invoice.CONTACT.FIRST_NAME +
                  " " +
                  invoice.CONTACT.LAST_NAME +
                  " #" +
                  invoice.INVOICE_NUMBER}
              </div>

              {invoice.STATUS === "REFUNDED" && (
                <DangerBadge className={"my-1"}>Refunded</DangerBadge>
              )}

              {invoice.DATE_ARCHIVED && (
                <DangerBadge className={"my-1"}>Archived</DangerBadge>
              )}
            </div>
          }
          buttons={headerButtons}
          breadcrumbs={[
            {label: "Invoicing", url: "/invoicing"},
            {label: "Invoice List", url: "/invoices"},
          ]}
        />

        <InvoiceCard
          invoice={invoice}
          hideEdit={invoice.STATUS === "PAID"}
          handleEdit={() => this.invoiceModal.open(invoice)}
        />

        <Tab data={invoiceTabs}>
          {(id) => {
            if (id === "lines") {
              return (
                <InvoiceLinesCard
                  ticket={invoice.TICKET}
                  lines={invoice.TICKET.ITEMS}
                  hideEdit={invoice.STATUS === "PAID"}
                  handleEdit={() => this.invoiceLinesModal.open(invoice)}
                />
              );
            }

            if (id === "recipients") {
              return (
                <InvoiceRecipientsCard
                  invoice={invoice}
                  handleEdit={() => this.recipientModal.open(invoice)}
                  updateState={(invoice) => this.setState({invoice})}
                />
              );
            }

            if (id === "reminders") {
              return (
                <InvoiceRemindersCard
                  hideEdit
                  invoice={invoice}
                  handleEdit={() => this.invoiceLinesModal.open(invoice)}
                />
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InvoicePage);
