import React, {Component} from "react";
import FilterDropdown from "./filter-dropdown";
import {request} from "../utils/request";

class InvoiceStatusDropdown extends Component {
  state = {statuses: [], initialValues: []};

  async componentDidMount() {
    const statuses = await request("invoices/status", "GET");

    const initialValues = statuses.map((status) => status.STATUS);

    this.setState({statuses, initialValues});
  }

  render() {
    const {statuses, initialValues} = this.state;

    return (
      <FilterDropdown
        cannotBeEmpty={true}
        initialText={"Select Status"}
        plural={"Statuses"}
        onChange={this.props.onChange}
        className={this.props.className}
        initialValues={initialValues}
        enableReinitialize
        data={statuses.map((status) => {
          return {
            name: status.STATUS.capitalize(),
            value: status.STATUS,
          };
        })}
      />
    );
  }
}

export default InvoiceStatusDropdown;
