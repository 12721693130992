import {
  FormBoolean,
  Modal,
  FormInput,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class MobileOrderingModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {
      mobileOrdersTempDisabled,
      scheduledOrderDisclaimer,
      asapEnabled,
      schedulingEnabled,
      inStorePickupEnabled,
      mobileHidePricePreview,
      orderEstimate,
      deliveryEstimate,
      minOrder,
      loginRequired,
      showOrderTracker,
      receiptMessage,
      displayReceiptBottom,
    } = values;

    let payload = {
      MOBILE_ORDERS_TEMP_DISABLED: mobileOrdersTempDisabled,
      ASAP_ENABLED: asapEnabled,
      SCHEDULING_ENABLED: schedulingEnabled,
      IN_STORE_PICKUP_ENABLED: inStorePickupEnabled,
      MOBILE_HIDE_PRICE_PREVIEW: mobileHidePricePreview,
      ORDER_ESTIMATE: orderEstimate,
      DELIVERY_ESTIMATE: deliveryEstimate,
      REQUIRE_MOBILE_LOGIN: loginRequired,
      SCHEDULED_ORDER_DISCLAIMER: scheduledOrderDisclaimer,
      ORDER_TRACKER_ENABLED: showOrderTracker,
      RECEIPT_CUSTOMER_MESSAGE: receiptMessage,
      DISPLAY_RECEIPT_MESSAGE_BOTTOM: displayReceiptBottom,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});
    await request("location", "PATCH", {
      MIN_ORDER: decimalToDollars(minOrder),
    });

    let settings = await request("settings/shop", "GET");
    let {location} = this.props.shop;
    location.MIN_ORDER = decimalToDollars(minOrder);

    this.props.updateShop({settings});
    this.props.updateShop({location});
    this.modal.close();
  };

  render() {
    let {
      MOBILE_ORDERS_TEMP_DISABLED,
      ASAP_ENABLED,
      SCHEDULING_ENABLED,
      IN_STORE_PICKUP_ENABLED,
      ORDER_ESTIMATE,
      DELIVERY_ESTIMATE,
      MOBILE_HIDE_PRICE_PREVIEW,
      REQUIRE_MOBILE_LOGIN,
      SCHEDULED_ORDER_DISCLAIMER,
      ORDER_TRACKER_ENABLED,
      RECEIPT_CUSTOMER_MESSAGE,
      DISPLAY_RECEIPT_MESSAGE_BOTTOM,
    } = this.props.shop.settings;
    let {MIN_ORDER} = this.props.shop.location;

    return (
      <Modal
        buttonLabel={"Save"}
        label="Mobile Ordering Settings"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            mobileOrdersTempDisabled: MOBILE_ORDERS_TEMP_DISABLED,
            asapEnabled: ASAP_ENABLED,
            schedulingEnabled: SCHEDULING_ENABLED,
            inStorePickupEnabled: IN_STORE_PICKUP_ENABLED,
            mobileHidePricePreview: MOBILE_HIDE_PRICE_PREVIEW,
            scheduledOrderDisclaimer: SCHEDULED_ORDER_DISCLAIMER,
            showOrderTracker: ORDER_TRACKER_ENABLED,
            deliveryEstimate: DELIVERY_ESTIMATE,
            loginRequired: REQUIRE_MOBILE_LOGIN,
            orderEstimate: ORDER_ESTIMATE,
            minOrder: toDollars(MIN_ORDER),
            receiptMessage: RECEIPT_CUSTOMER_MESSAGE,
            displayReceiptBottom: DISPLAY_RECEIPT_MESSAGE_BOTTOM,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="mobileOrdersTempDisabled"
                  label="Disable Mobile Ordering (Temporarily)"
                  options={formikOptions}
                />

                <FormBoolean
                  name="loginRequired"
                  label="Require Mobile Ordering Login"
                  options={formikOptions}
                />

                <FormBoolean
                  name="asapEnabled"
                  label="Allow ASAP Orders"
                  options={formikOptions}
                />

                <FormBoolean
                  name="schedulingEnabled"
                  label="Allow Scheduled Orders"
                  options={formikOptions}
                />

                <FormBoolean
                  name="inStorePickupEnabled"
                  label="Allow Pay at Store"
                  options={formikOptions}
                />

                <FormBoolean
                  name="mobileHidePricePreview"
                  label="Hide Price Previews"
                  options={formikOptions}
                />

                <FormBoolean
                  name="showOrderTracker"
                  label="Show Order Tracker"
                  options={formikOptions}
                />

                <FormInput
                  name="minOrder"
                  label="Minimum Order"
                  options={formikOptions}
                />

                <FormInput
                  name="orderEstimate"
                  label="Order Estimate (in Minutes)"
                  options={formikOptions}
                />

                <FormInput
                  name="deliveryEstimate"
                  label="Delivery Estimate (in Minutes)"
                  options={formikOptions}
                />

                <FormBoolean
                  name="displayReceiptBottom"
                  label="Display Receipt Message at Bottom of Receipt"
                  tooltip="Enabling this setting will display the custom message at the end of the receipt, rather than at the beginning."
                  options={formikOptions}
                />

                <FormTextArea
                  name="receiptMessage"
                  label="Receipt Custom Message"
                  tooltip="A custom message to display on the receipt screen."
                  options={formikOptions}
                  onKeyPress={(event) => {
                    if (event.key === "enter") {
                      setFieldValue(
                        "receiptMessage",
                        values.receiptMessage + "\n"
                      );
                    }
                  }}
                />

                <FormTextArea
                  name="scheduledOrderDisclaimer"
                  label="Scheduled Order Disclaimer"
                  tooltip="A disclaimer that will show if a patron tries to schedule order for a day in advanced."
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  MobileOrderingModal
);
