import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";

class ConfirmInvoiceModal extends Component {
  state = {invoice: null};

  open(invoice = null) {
    this.setState({invoice}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createInvoice(status, button, disableButton) {
    const {
      invoice,
      lines,
      serviceFees,
      discounts,
      recipients,
      reminders = [],
    } = this.state.invoice;

    button && button.startLoading();
    disableButton && disableButton.disable();

    const invoicePayload = {
      STATUS: status,
      INVOICE_NUMBER: invoice.INVOICE_NUMBER,

      CONTACT_ID: invoice.CONTACT_ID,
      NOTES: invoice.NOTES,

      DATE_SCHEDULE: null,
      DATE_SERVICE: invoice.DATE_SERVICE,
      DATE_DUE: invoice.DATE_DUE,

      ITEMS: [
        ...lines.map((line, index) => ({...line, SEQ: index})),
        ...serviceFees.map((fee, index) => ({
          ...fee,
          SEQ: index + lines.length,
        })),
      ],
      DISCOUNTS: discounts,
      REMINDERS: reminders,
      RECIPIENTS: recipients,
      REQUEST_TIP: invoice.REQUEST_TIP,
      TICKET_DATE_SCHEDULED: invoice.TICKET_DATE_SCHEDULED,
    };

    const serverInvoice = await request("invoices", "POST", invoicePayload);

    this.props.router.navigate("/invoice/" + serverInvoice.UNIQUE_ID);

    this.modal && this.modal.close();
  }

  render() {
    const {invoice: invoiceObj = {}} = this.state;
    const {invoice, lines, serviceFees} = invoiceObj || {};

    const total =
      lines?.reduce((accum, item) => accum + item.TOTAL, 0) +
      serviceFees?.reduce((accum, item) => accum + item.TOTAL, 0);

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modal.close()}
        deleteType="gray"
        deleteLabel="Cancel"
        buttons={[
          {
            type: "gray",
            label: "Save as Draft",
            ref: (e) => (this.draftButton = e),
            onClick: () =>
              this.createInvoice("DRAFT", this.draftButton, this.sendButton),
          },
          {
            label: "Send Now",
            ref: (e) => (this.sendButton = e),
            onClick: () =>
              this.createInvoice("OPEN", this.sendButton, this.draftButton),
          },
        ]}
        label="Send Invoice"
        ref={(e) => (this.modal = e)}
      >
        <div className="font-medium">
          Creating an invoice for{" "}
          <span className="font-bold">${toDollars(total)}</span> to{" "}
          <span className="font-bold">
            {invoice?.CONTACT?.FIRST_NAME} {invoice?.CONTACT?.LAST_NAME}
          </span>
        </div>

        <div className="mt-4 text-sm text-gray-700">
          Would you like to <span className="font-bold">Save as Draft</span> and
          send the invoice at another date or would you like to{" "}
          <span className="font-bold">Send Now</span> and notify the customer
          immediately?
        </div>
      </Modal>
    );
  }
}

export default withRouter(ConfirmInvoiceModal);
