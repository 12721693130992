import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {
  Loading,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import CouponsTable from "../../../../tables/marketing/deals/coupons-table";

class PromotionsPage extends Component {
  state = {
    isLoading: true,
    coupons: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let coupons = await request("promotions/coupons", "GET");

    coupons.sort((a, b) => b.ID - a.ID);
    this.setState({isLoading: false, coupons});
  }

  render() {
    const {isLoading, coupons} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const activeCoupons = coupons?.filter((item) => {
      return (
        item?.PROMOTION?.ENABLED === 1 && !item?.PROMOTION?.TEXT_CAMPAIGN_ID
      );
    });

    const textMarketingCoupons = coupons?.filter((item) => {
      return (
        item?.PROMOTION?.ENABLED === 1 && !!item?.PROMOTION?.TEXT_CAMPAIGN_ID
      );
    });

    const inactiveCoupons = coupons?.filter((item) => {
      return item?.PROMOTION?.ENABLED === 0;
    });

    return (
      <div>
        <PageHeadings
          label="Coupons"
          description="Coupons that your customers can redeem"
          button={{
            label: "Create Coupon",
            onClick: () => this.props.router.navigate("/coupon"),
          }}
        />

        <Tab
          data={[
            {
              id: "active",
              label: "Manually Created Coupons",
              badge: activeCoupons ? "" + activeCoupons.length : "",
            },
            {
              id: "text",
              label: "Text Campaign Coupons",
              badge: textMarketingCoupons
                ? "" + textMarketingCoupons.length
                : "",
            },
            {
              id: "inactive",
              label: "Inactive Coupons",
              badge: inactiveCoupons ? "" + inactiveCoupons.length : "",
            },
          ]}
        >
          {(id) => {
            let finalCoupons = null;
            if (coupons) {
              if (id === "active") {
                finalCoupons = activeCoupons;
              } else if (id === "text") {
                finalCoupons = textMarketingCoupons;
              } else if (id === "inactive") {
                finalCoupons = inactiveCoupons;
              }
            }

            return <CouponsTable className="mt-4" coupons={finalCoupons} />;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(PromotionsPage));
