import {Component} from "react";
import FormDropZone from "./form-drop-zone";

class ImageFormDropZone extends Component {
  render() {
    let {options, name, label, tooltip, type, endpoint, ...extra} = this.props;
    endpoint = endpoint ?? "shop/image/";

    return (
      <FormDropZone
        options={options}
        endpoint={endpoint}
        name={name}
        label={label}
        tooltip={tooltip}
        type={type}
        {...extra}
      />
    );
  }
}

export default ImageFormDropZone;
