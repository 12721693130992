import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import BreakOptionModal from "../../../modals/team/breaks/break-option-modal";
import {Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";

class BreakOptionsPage extends Component {
  state = {data: null};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching breaks data", err);
    });
  }

  async syncData() {
    try {
      const serverBreakOptions = await request("team/break-option", "GET");

      this.setState({data: serverBreakOptions});
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <BreakOptionModal
          updateState={(option, del) => {
            let {data} = this.state;

            const idx = data.findIndex((item) => item.ID === option.ID);

            if (idx !== -1) {
              if (del) {
                data.splice(idx, 1);
              } else {
                data.splice(idx, 1, option);
              }
            } else {
              data = [...data, option];
            }

            this.setState({data});
          }}
          ref={(e) => (this.breakOptionModal = e)}
        />
        <PageHeadings
          label="Break Options"
          description="View and edit location break options"
          button={{
            label: "Create Break Option",
            onClick: () => this.breakOptionModal.open(),
          }}
        />

        <Table
          className={"mt-2"}
          {...this.props}
          data={data}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.breakOptionModal.open(row),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
              format: (value) => {
                return value;
              },
            },
            {
              value: "DATE_CREATED",
              label: "Date Created",
              format: (value) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
            {
              value: "AMOUNT_MINUTES",
              label: "Length in Minutes",
              format: (value) => {
                return value;
              },
            },
            {
              value: "IS_PAID",
              label: "Is Paid",
              format: (value) => {
                return value ? "Yes" : "No";
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(BreakOptionsPage));
