import React, {Component} from "react";
import {CSVLink} from "react-csv";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";

class DownloadADPImportTab extends Component {
  state = {csvData: null, csvHeaders: null};

  componentDidMount() {
    this.importADP();
  }

  importADP() {
    let {checkTemplateData, paycheckToCheckMapping, adpExport} = this.props.payroll.importData;
    let {data} = checkTemplateData;

    let csvHeaders = Object.keys(data[0]);
    let csvData = [];

    for (let {name, data} of adpExport) {
      let entry = {};

      entry["Employee Name"] = name;

      for (let {label, numbers} of data) {
        let mapping = paycheckToCheckMapping[label];

        if (mapping) {
          entry[mapping.key] = (((entry[mapping.key]) ? Number(entry[mapping.key]) : 0) + Number((numbers[mapping.index])?.replace(",", ""))).toFixed(2);
        }
      }

      csvData.push(entry);
    }

    this.setState({csvHeaders, csvData});
  }

  render() {
    let {csvData, csvHeaders} = this.state;

    return <div className={"px-6"}>
      {csvData &&
        <CSVLink
          filename={`WE DID IT`}
          data={csvData}
          headers={csvHeaders}
        >
          <button
            type="button"
            className="mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
            onClick={this.downloadImport}
          >
            Download
          </button>
        </CSVLink>
      }
    </div>
  }
}

export default setupReduxConnection(["payroll"])(
  withRouter(DownloadADPImportTab)
);
