import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {request} from "../../utils/request";
import {setStats} from "../../redux/scheduling-stats";

class LaborSalesDisplay extends Component {
  render() {
    let {projectedTotals} = this.props.schedulingStats;
    let {cost, day} = this.props;

    if (projectedTotals && (cost / projectedTotals[day]) && (cost / projectedTotals[day]) < 100) {
      return (
        <div className="flex text-xs font-normal text-gray-600">{`${Math.round(
          (cost / projectedTotals[day]) * 100
        )}%`}</div>
      );
    }

    return <div></div>;
  }
}

export default setupReduxConnection(["schedulingStats"])(
  withRouter(LaborSalesDisplay)
);
