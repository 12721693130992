import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {
  Crosshair,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import ReportingGraphCrosshair from "../../../features/reporting/reporting-graph-crosshair";
import LoadingSpinner from "../../../components/loading-spinner";

class ProductBugsPage extends Component {
  state = {
    summary: null,
    bugsCompleted: null,
    completedPercentage: null,
    features: null,
    products: null,
  };

  async componentDidMount() {
    this.updateBugs()
  }

  updateBugs = async () => {
    let data = await request("admin/product/bugs");
    let {summary, features, products} = data;

    this.setState({summary, features, products});

    setTimeout(() => this.updateBugs(), 180000);
  }

  renderTopThree(items) {
    const toRender = Object.values(items);

    return toRender.map((item, index) => {
      return <div className={"font-xs"}>{index + 1 + ". " + item}</div>;
    });
  }

  render() {
    let {summary, bugsCompleted, completedPercentage, features, products} =
      this.state;

    return (
      <div className="p-6">
        <PageHeadings label={"Bug Breakdown"} />

        <Tab
          className="mt-2"
          data={[
            {label: "Summary", id: "summary"},
            {label: "Causes", id: "causes"},
          ]}
        >
          {(id) => {
            if (id === "causes") {
              return (
                <div className={"absolute top-1/2 left-1/2"}>
                  {"Currently under construction 🚧"}
                </div>
              );
            }

            if (id === "summary") {
              if (!summary) {
                return (
                  <div className={"absolute top-1/2 left-1/2"}>
                    <LoadingSpinner />
                  </div>
                );
              }

              let thisWeekPercentage,
                lastWeekPercentage,
                percentChange,
                thisWeekCompleted;
              thisWeekPercentage =
                Math.round(summary[summary.length - 1].COMPLETION_PERCENTAGE);
              thisWeekCompleted =
                summary[summary.length - 1].NUM_BUGS_COMPLETED;
              if (summary.length > 1) {
                lastWeekPercentage =
                  summary[summary.length - 2].COMPLETION_PERCENTAGE;
                percentChange = Math.round(
                  thisWeekPercentage - lastWeekPercentage
                );
              }

              return (
                <Card className={"p-6"}>
                  <div className={"flex flex-row justify-around"}>
                    {/*Steven: total %*/}
                    <div className="flex flex-col justify-center items-center">
                      <div className="mb-3 text-xl font-medium">
                        Bugs Completed (%)
                      </div>
                      <FlexibleWidthXYPlot
                        width={window.innerWidth / 4}
                        height={window.innerHeight / 2}
                        xDomain={[0, summary.length + 1]}
                        yDomain={[0, 100]}
                      >
                        <HorizontalGridLines />

                        <VerticalBarSeries
                          color={"rgb(79 70 229)"}
                          data={summary.map((item, index) => ({
                            x: index + 1,
                            y: item.COMPLETION_PERCENTAGE,
                          }))}
                          onValueMouseOver={(value) =>
                            this.setState({completedPercentage: value})
                          }
                          onSeriesMouseOut={() =>
                            this.setState({completedPercentage: null})
                          }
                        />

                        {completedPercentage && (
                          <Hint value={completedPercentage}>
                            <div
                              className={
                                "bg-slate-700 text-xs text-white p-2 rounded-sm"
                              }
                            >
                              {"Percentage: " + completedPercentage.y + "%"}
                            </div>
                          </Hint>
                        )}

                        <YAxis
                          tickTotal={5}
                          tickFormat={(t) => {
                            return `${t}%`;
                          }}
                        />
                      </FlexibleWidthXYPlot>
                    </div>

                    <div
                      className={"flex flex-col justify-center items-center"}
                    >
                      <div className="mb-3 text-xl font-medium">
                        Weekly Bugs Completed
                      </div>

                      <FlexibleWidthXYPlot
                        width={window.innerWidth / 4}
                        height={window.innerHeight / 2}
                        xDomain={[0, summary.length + 1]}
                        yDomain={[0, 30]}
                      >
                        <HorizontalGridLines />

                        <VerticalBarSeries
                          color={"rgb(79 70 229)"}
                          data={summary.map((item, index) => {
                            return {
                              x: index + 1,
                              y: item.NUM_BUGS_COMPLETED,
                            };
                          })}
                          onValueMouseOver={(value) =>
                            this.setState({bugsCompleted: value})
                          }
                          onSeriesMouseOut={() =>
                            this.setState({bugsCompleted: null})
                          }
                        />

                        {bugsCompleted && (
                          <Hint value={bugsCompleted}>
                            <div
                              className={
                                "bg-slate-700 text-xs text-white p-2 rounded-sm"
                              }
                            >
                              {"Completed: " + bugsCompleted.y}
                            </div>
                          </Hint>
                        )}

                        <YAxis tickTotal={5} />
                      </FlexibleWidthXYPlot>
                    </div>

                    <div className={"flex flex-col text-center justify-evenly"}>
                      <div className={"flex flex-col"}>
                        <div className="text-2xl">
                          {thisWeekPercentage +
                            "% 🪲🔨 " +
                            "(" +
                            thisWeekCompleted +
                            " this week)"}
                        </div>
                        {percentChange &&
                          (percentChange > 0 ? (
                            <div className="text-sm text-green-500 font-semibold">
                              {"+" + percentChange + "% from last week"}
                            </div>
                          ) : (
                            <div className="text-sm text-red-500 font-semibold">
                              {percentChange + "% from last week"}
                            </div>
                          ))}
                      </div>

                      <div>
                        <div className="text-xl font-semibold">
                          Top Features
                        </div>
                        {features && this.renderTopThree(features)}
                      </div>

                      <div>
                        <div className="text-xl font-semibold">
                          Top Products
                        </div>
                        {products && this.renderTopThree(products)}
                      </div>
                    </div>
                  </div>
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default ProductBugsPage;
