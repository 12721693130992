import React, {Component} from "react";
import FormElement from "./form-element";
import PropTypes from "prop-types";

class FormRadio extends Component {
  render() {
    const {name, data} = this.props;

    return (
      <FormElement {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <div className="mt-2 space-y-5">
              {data.map((item) => {
                return (
                  <div className="relative flex items-start cursor-pointer">
                    <div className="absolute flex items-center h-5">
                      <input
                        name={name}
                        type="radio"
                        id={name + "-form-radio-" + item.value}
                        aria-describedby="privacy-public-description"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        checked={value === item.value}
                        onChange={(e) => {
                          onChange(e);

                          setTimeout(() => {
                            onChangeSoft && onChangeSoft(e.target.value);
                          }, 1);
                        }}
                      />
                    </div>

                    <div className="pl-7 text-sm">
                      <label
                        htmlFor={name + "-form-radio-" + item.value}
                        className="font-medium text-gray-900"
                      >
                        {item.label}
                      </label>

                      <p
                        id="privacy-public-description"
                        className="text-gray-500"
                      >
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      </FormElement>
    );
  }
}

FormRadio.propTypes = {
  ...FormElement.propTypes,

  data: PropTypes.array.isRequired,
};

export default FormRadio;
