import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {
  AdvancedFilter,
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TicketsExportModal from "../../../modals/sales/tickets/tickets-export-modal";

class TicketsPage extends Component {
  state = {
    type: null,
    types: [],
    otherPayments: [],
    isLoading: true,
    stripePayouts: [{label: "None", value: null}],
  };

  componentDidMount() {
    this.syncState();
    this.fetchPayouts();
  }

  fetchPayouts = async () => {
    let stripePayouts = (await request("payouts", "GET"))
      .reverse()
      .map((payout) => ({
        label: `${moment(payout.DATE_ARRIVED).format("MM/DD/YY")} - ${toDollars(
          payout.TOTAL,
          true
        )}`,
        value: payout.UNIQUE_ID,
      }));

    stripePayouts.unshift({label: "None", value: null});

    this.setState({stripePayouts});
  };

  async syncState() {
    let types = await request("types/order", "GET");
    let otherPayments = await request("other/payments", "GET");

    this.setState({isLoading: false, types, otherPayments});
  }

  render() {
    let {isLoading, types, otherPayments, stripePayouts} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <TicketsExportModal ref={(e) => (this.exportModal = e)} />

        <PageHeadings
          label="Tickets"
          description="Filter to find current and future tickets"
          button={{
            label: "Export Data",
            onClick: () => this.exportModal.open(),
          }}
        />

        <AdvancedFilter
          className="mt-2"
          defaultOpen
          data={[
            {
              type: "array",
              data: [
                {
                  label:
                    "Search by Name, Phone, Ticket Number, Last 4, $ Amount",
                  placeholder: "Search",
                  type: "search",
                  id: "search",
                },
                {label: "Start Date", type: "datetime", id: "start"},
                {label: "End Date", type: "datetime", id: "end"},
                {
                  label: "Payout",
                  type: "select",
                  data: stripePayouts,
                  id: "payout",
                },
              ],
            },
            {
              id: "payment",
              type: "check",
              label: "Payment Types",
              data: [
                {label: "Cash", value: "CASH"},
                {label: "Card Present", value: "CARD_PRESENT"},
                {label: "Card Not Present", value: "CARD_NOT_PRESENT"},
                {label: "Physical Gift Card", value: "GIFT_CARD"},
                {label: "Digital Gift Card", value: "E_GIFT_CARD"},
                {label: "Reward", value: "REWARD"},
                {label: "Dripos Balance", value: "BALANCE"},
                {label: "Billing Account", value: "BILLING"},
                ...otherPayments.map((item) => {
                  return {label: item.NAME, value: item.ID};
                }),
              ],
            },
            {
              id: "type",
              type: "check",
              label: "Ticket Types",
              data: types.map((item) => {
                return {label: item.NAME, value: item.ID};
              }),
            },
            {
              type: "check",
              id: "platform",
              label: "Platforms",
              data: [
                {label: "Mobile", value: "mobile"},
                {label: "Web", value: "web"},
                {
                  label: "Point of Sale",
                  value: "pos",
                },
                {label: "Kiosk", value: "kiosk"},
              ],
            },
          ]}
        >
          {(filters, search) => {
            return (
              <div className="p-6">
                <Table
                  route="tickets/dumb"
                  search={search}
                  filters={filters}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (ticket) =>
                        this.props.router.navigate(
                          "/ticket/" + ticket.UNIQUE_ID
                        ),
                    },
                    {
                      label: (
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
                      ),
                      onClick: (ticket) =>
                        this.props.router.navigateNewTab(
                          "/ticket/" + ticket.UNIQUE_ID
                        ),
                    },
                  ]}
                  columns={[
                    {
                      label: "Ticket Number",
                      value: "TICKET_NUMBER",
                    },
                    {
                      label: "Amount",
                      value: "PAYMENT_INTENT",
                      format: (payment) => "$" + toDollars(payment.TOTAL),
                    },
                    {label: "Name", value: "NAME"},
                    {
                      label: "Ticket & Platform",
                      value: "TICKET_TYPE_NAME",
                      format: (name, row) => {
                        return `${name} - ${
                          row.PLATFORM ? row.PLATFORM.capitalize() : "POS"
                        }`;
                      },
                    },
                    {
                      label: "Date Created",
                      value: "DATE_CREATED",
                      format: (val, row) =>
                        moment(val).format("h:mma ddd MMM Do"),
                    },
                  ]}
                  pagination
                />
              </div>
            );
          }}
        </AdvancedFilter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(TicketsPage));
