import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ImageFormDropZone from "../../components/image-form-drop-zone";
import {showConfirmAlert} from "../../utils/alert-helper";

class LogoDropZoneModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  save = async ({logo}) => {
    showConfirmAlert(
      "Are you sure you want to add this logo to these products?",
      "This action will override these products' existing logos."
    )
      .then(() => {
        this.props.updateState && this.props.updateState(logo);
        this.modal.close();
      })
      .catch(() => {
        this.modal.close();
      });
  };

  render() {
    return (
      <Modal
        buttonLabel="Add"
        label="Add Logo"
        description="Add a logo to your products"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            logo: null,
          }}
          onSubmit={this.save}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone
                  name="logo"
                  label="Product Image"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default LogoDropZoneModal;
