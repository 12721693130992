import React, {Component} from "react";
import moment from "moment";
import {getShortURL, request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import io from "socket.io-client";
import {Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {numberWithCommas} from "../../../utils/util";
import TokenRequestModal from "./modals/token-request-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class TokenRequestsPage extends Component {
  state = {
    requests: null,
  };

  componentDidMount() {
    this.loadData();
    this.setupSocket();
  }

  setupSocket() {
    let socket = io.connect(getShortURL(), {
      query: "b64=1",
    });

    socket.on("connect", () => {
      socket.emit("admin", {
        TOKEN: window.localStorage["TOKEN"],
      });
    });

    socket.on("TORE_INSERT", (data) => {
      const {requests} = this.state;

      this.setState({requests: [data, ...requests]});

      console.log("Insert", data);
    });

    socket.on("TORE_UPDATE", (data) => {
      const {requests} = this.state;

      const index = requests.findIndex((item) => {
        return item.ID === data.ID;
      });

      requests.splice(index, 1, data);

      this.setState({requests});
      console.log("Update", data);
    });
  }

  async loadData() {
    request("tore", "GET").then((requests) => {
      this.setState({requests});
    });
  }

  render() {
    const {requests} = this.state;

    return (
      <div className="p-6">
        <TokenRequestModal ref={(e) => (this.tokenRequestModal = e)} />

        <Card
          label="Token Requests"
          button={{
            label: "Create Request",
            onClick: () => this.tokenRequestModal.open(),
          }}
        >
          <Table
            pagination
            data={requests}
            columns={[
              {
                width: 1,
                value: "REQUEST_ACCOUNT",
                label: "Requester",
                format: (value, row) => (
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getObjectImage(value, "LOGO", "appicon.png")}
                        alt=""
                      />
                    </div>

                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {value.FULL_NAME}
                      </div>

                      <div className="text-sm text-gray-500">
                        {getFormattedPhone(value.PHONE)}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                width: 1,
                value: "ACCOUNT",
                label: "Account",
                format: (value, row) => (
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getObjectImage(value, "LOGO", "appicon.png")}
                        alt=""
                      />
                    </div>

                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {value.FULL_NAME}
                      </div>

                      <div className="text-sm text-gray-500">
                        {getFormattedPhone(value.PHONE)}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                width: 1,
                value: "TYPE",
                label: "Type",
              },
              {
                width: 1,
                value: "CONTENT",
                label: "Content",
              },
              {
                width: 1,
                value: "TOKEN",
                label: "Token",
              },
              {
                width: 1,
                value: "DATE_CREATED",
                label: "Created",
                format: (value, row) => {
                  return moment(value).format("MMM Do hh:mmA");
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default TokenRequestsPage;
