import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../components/loading-spinner";

class CreateStripeLoadingModal extends Component {
  open(){
    this.modal.open()
  }

  render() {
    return <Modal
      buttonLabel={"Ok"}
      label={"Creating Stripe Account"}
      ref={(e) => (this.modal = e)}
      formikOnClick={() => this.formikRef}
    >
      <LoadingSpinner/>
    </Modal>
  }
}

export default CreateStripeLoadingModal;
