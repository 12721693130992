const defaultState = {
  data: [],
};

const UPDATE_NOTIFICATION_DATA = "UPDATE_NOTIFICATION_DATA";

export function updateNotificationData(payload) {
  return {type: UPDATE_NOTIFICATION_DATA, payload};
}

export function addNotificationData(payload) {
  return (dispatch, getState) => {
    const {data} = getState().notification;

    dispatch(updateNotificationData([payload, ...data]));
  }
}

export function updateNotification(id, payload) {
  return (dispatch, getState) => {
    const {data} = getState().notification;
    const dataIndex = data.findIndex((item) => id === item.id);

    if (dataIndex !== -1) {
      data.splice(dataIndex, 1, {...data[dataIndex], ...payload});

      dispatch(updateNotificationData(data));
    }
  }
}

export function removeNotification(payload) {
  return (dispatch, getState) => {
    const {data} = getState().notification;
    const dataIndex = data.findIndex((item) => payload.id === item.id);

    if (dataIndex !== -1) {
      data.splice(dataIndex, 1);

      payload.onDelete && payload.onDelete();

      dispatch(updateNotificationData(data));
    }
  }
}

export const notificationReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_NOTIFICATION_DATA:
      return {...state, data: payload};
  }
};
