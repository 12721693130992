import React, {Component, Fragment} from "react";
import {Dialog, Disclosure, Menu, Popover, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  DownloadIcon,
  MailIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import {
  classNames,
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {CSVLink} from "react-csv";
import moment from "moment";
import {request} from "../../utils/request";

class Filter extends Component {
  state = {
    downloadData: null,
    isDownloading: false,
    search: null,
    sort: null,
    filters: [],
    open: false,
  };

  componentDidMount() {
    const {defaultFilters, data} = this.props;

    const url = new URL(window.location.href);
    if (url.searchParams.has("search")) {
      this.setState({search: url.searchParams.get("search")});
    }

    if (defaultFilters && Array.isArray(defaultFilters)) {
      if (typeof defaultFilters === "object") {
        this.setState({filters: defaultFilters});
      } else {
        const dataDict = parseIdDict(data, "id");

        this.setState({
          filters: defaultFilters.map((item) => {
            return dataDict[item];
          }),
        });
      }
    }
  }

  renderCSV() {
    const {csv = null} = this.props;
    const {isDownloading, downloadData, filters, search} = this.state;

    if (!csv) {
      return <div />;
    }

    const {file, route, data = [], headers} = csv;

    let csvData = data || [];
    let csvHeaders = {};

    if (downloadData) {
      csvData = downloadData;
    }

    if (headers) {
      csvData = csvData.map((item) => {
        const obj = {};

        for (let head of headers) {
          const value = item[head.key];

          if (head.type === "date") {
            obj[head.key] = value ? moment(value).format("MM/DD/YY") : "";
          } else if (head.type === "datetime") {
            obj[head.key] = value
              ? moment(value).format("hh:mma MM/DD/YY")
              : "";
          } else if (head.type === "bool") {
            obj[head.key] = value ? "yes" : "no";
          } else if (head.type === "dollars") {
            obj[head.key] = toDollars(value, true);
          } else {
            obj[head.key] = value;
          }
        }

        return obj;
      });

      csvHeaders = headers;
    }

    if (route && downloadData === null) {
      return (
        <div
          onClick={() => {
            this.setState({downloadData: null, isDownloading: true});

            request(route, "POST", {
              FILTERS: filters,
              SEARCH: search,
              DOWNLOAD: true,
            })
              .then((data) => {
                this.setState(
                  {downloadData: data, isDownloading: false},
                  () => {
                    setTimeout(() => {
                      this.csvRef.link.click();

                      this.setState({downloadData: null});
                    }, 1);
                  }
                );
              })
              .catch(() => {
                this.setState({isDownloading: false});
              });
          }}
        >
          {isDownloading ? (
            <div className="ml-5 text-sm font-medium flex flex-row items-center cursor-pointer rounded text-indigo-500 hover:text-indigo-700">
              Loading ...
            </div>
          ) : (
            <div className="ml-5 text-sm font-medium flex flex-row items-center cursor-pointer rounded text-indigo-500 hover:text-indigo-700">
              <div className="mr-1">Export</div>
              <DownloadIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
        </div>
      );
    }

    return (
      <CSVLink
        ref={(e) => (this.csvRef = e)}
        data={csvData}
        headers={csvHeaders}
        filename={file + ".csv"}
      >
        <div className="ml-5 text-sm font-medium flex flex-row items-center cursor-pointer rounded text-indigo-500 hover:text-indigo-700">
          <div className="mr-1">Export</div>
          <DownloadIcon className="h-4 w-4" aria-hidden="true" />
        </div>
      </CSVLink>
    );
  }

  render() {
    const {open, filters = [], search} = this.state;
    const {searchParams, searchable, className = "", data = []} = this.props;

    const setOpen = (open) => this.setState({open});

    const filterDict = parseIdDict(data, "id");
    const selectedFilterDict = filters.reduce((dict, item) => {
      if (!dict[item.filter]) {
        dict[item.filter] = [item.id];
      } else {
        dict[item.filter].push(item.id);
      }

      return dict;
    }, {});

    const filterArr = Object.keys(selectedFilterDict).map((filter) => {
      return {
        filter: filter,
        onFilter: filterDict[filter]?.onFilter,
        data: selectedFilterDict[filter],
      };
    });

    return (
      <div className={classNames(className)}>
        {/* Mobile filter dialog */}
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 sm:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="bg-white ml-auto relative max-w-xs w-full h-full shadow-xl py-3 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>

                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>

                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {data.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.label}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({open}) => {
                        const filtersSelected = filters.filter((item) => {
                          return item.filter === section.id;
                        });

                        return (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                                <div className="flex">
                                  <span className="font-medium text-gray-900">
                                    {section.label}
                                  </span>

                                  {filtersSelected.length > 0 ? (
                                    <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                      {filtersSelected.length}
                                    </span>
                                  ) : null}
                                </div>

                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "-rotate-180" : "rotate-0",
                                      "h-5 w-5 transform"
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>

                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => {
                                  const filterIndex = filters.findIndex(
                                    (item) => {
                                      return item.id === option.id;
                                    }
                                  );

                                  const isSelected = filterIndex !== -1;

                                  return (
                                    <div
                                      key={option.id}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                        name={`${section.id}[]`}
                                        checked={isSelected}
                                        type="checkbox"
                                        onChange={() => {
                                          if (isSelected) {
                                            filters.splice(filterIndex, 1);
                                          } else {
                                            filters.push({
                                              ...option,
                                              filter: section.id,
                                            });
                                          }

                                          this.setState({filters});
                                        }}
                                      />

                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 text-sm text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </Disclosure.Panel>
                          </>
                        );
                      }}
                    </Disclosure>
                  ))}
                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition>

        <div className="mx-auto text-center">
          <section
            aria-labelledby="filter-heading"
            className="border-t border-gray-200 py-3 pr-2"
          >
            <h2 id="filter-heading" className="sr-only">
              Product filters
            </h2>

            <div className="flex items-center justify-between">
              {searchable ? (
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>

                  <input
                    type="text"
                    value={search}
                    onChange={(e) =>
                      this.setState({search: e.target.value}, () => {
                        if (searchParams) {
                          const url = new URL(window.location.href);

                          url.searchParams.delete("sort");
                          url.searchParams.delete("sortDesc");
                          url.searchParams.set("search", e.target.value);

                          if (e.target.value.length === 0) {
                            url.searchParams.delete("search");
                          }

                          window.history.pushState({}, null, url);
                        }
                      })
                    }
                    className="bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search..."
                  />
                </div>
              ) : (
                <div />
              )}

              <button
                type="button"
                className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                onClick={() => setOpen(true)}
              >
                Filters
              </button>

              <div className="flex flex-row items-center">
                <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
                  {data.map((section, sectionIdx) => {
                    const filtersSelected = filters.filter((item) => {
                      return item.filter === section.id;
                    });

                    return (
                      <Popover
                        as="div"
                        key={section.label}
                        id="desktop-menu"
                        className="relative z-10 inline-block text-left"
                      >
                        <div>
                          <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            <span>{section.label}</span>
                            {filtersSelected.length > 0 ? (
                              <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                {filtersSelected.length}
                              </span>
                            ) : null}

                            <ChevronDownIcon
                              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <form className="space-y-4">
                              {section.options.map((option, optionIdx) => {
                                const filterIndex = filters.findIndex(
                                  (item) => {
                                    return item.id === option.id;
                                  }
                                );

                                const isSelected = filterIndex !== -1;

                                return (
                                  <div
                                    key={option.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                      name={`${section.id}[]`}
                                      checked={isSelected}
                                      type="checkbox"
                                      onChange={() => {
                                        if (isSelected) {
                                          filters.splice(filterIndex, 1);
                                        } else {
                                          filters.push({
                                            ...option,
                                            filter: section.id,
                                          });
                                        }

                                        this.setState({filters});
                                      }}
                                    />

                                    <label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                );
                              })}
                            </form>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    );
                  })}
                </Popover.Group>

                {this.renderCSV()}
              </div>
            </div>
          </section>
        </div>

        {this.props.children && this.props.children(filterArr, search)}
      </div>
    );
  }
}

Filter.propTypes = {
  defaultFilters: PropTypes.array,

  sort: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      Ro: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default Filter;
