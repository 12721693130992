import React, {Component} from "react";
import moment from "moment";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  getPayrollEmployeeOnboardingLink,
  onboardCodeToEmployee,
} from "../../../../utils/payroll-helper";
import {withRouter} from "../../../../utils/navigation";
import LoadingSpinner from "../../../../components/loading-spinner";
import PayrollEmployeePortalInformationModal from "../../../../modals/payroll/payroll-employee-portal-information-modal";

class PayrollEmployeeInformationPage extends Component {
  state = {isLoading: true, data: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let {STARTED} = await this.refresh();

    if (!STARTED) {
      this.props.router.navigate(`/employee-payroll/onboard/${ID}`);
    }
  }

  refresh = async () => {
    this.setState({isLoading: true});

    const {ID} = this.props.router.params;
    let data = await onboardCodeToEmployee(ID);

    this.setState({isLoading: false, data});

    return data;
  };

  openHosted() {
    const {ID} = this.props.router.params;
    this.setState({isLoading: true});

    getPayrollEmployeeOnboardingLink(ID).then((res) => {
      const handler = window.Check.create({
        link: res.url,
      });
      handler.open();
      setTimeout(() => this.setState({isLoading: false}), 3000);
    });
  }

  render() {
    let {data, isLoading} = this.state;

    if (isLoading) {
      return (
        <div className="py-10">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <div>
        <PayrollEmployeePortalInformationModal
          refresh={this.refresh}
          checkEmployee={data?.CHECK_EMPLOYEE}
          ref={(e) => (this.informationModal = e)}
        />

        {data?.CHECK_EMPLOYEE?.onboard.status !== "completed" && (
          <div role="alert">
            <div className="mt-3 bg-red-500 text-white font-bold px-4 py-2">
              Incomplete
            </div>
            <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>
                Please click on "Open Sensitive Information & Tax Settings" and
                fill out the required information in order to get paid!
              </p>
            </div>
          </div>
        )}

        <TwoColumnList
          label={"Personal Information"}
          data={[
            {
              label: "Name",
              value: `${data?.CHECK_EMPLOYEE?.first_name} ${data?.CHECK_EMPLOYEE?.last_name}`,
            },
            {
              label: "Date of Birth",
              value: moment(data?.CHECK_EMPLOYEE?.dob).format("M/D/YYYY"),
            },
            {
              label: "Address",
              value: `${data?.CHECK_EMPLOYEE.residence.line1}${
                data?.CHECK_EMPLOYEE.residence.line2
                  ? ` ${data?.CHECK_EMPLOYEE.residence.line2}`
                  : ""
              }, ${data?.CHECK_EMPLOYEE.residence.city}, ${
                data?.CHECK_EMPLOYEE.residence.state
              } ${data?.CHECK_EMPLOYEE.residence.postal_code}`,
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.informationModal.open(),
            },
            {
              label: "Open Sensitive Information & Tax Settings",
              onClick: () => this.openHosted(),
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(PayrollEmployeeInformationPage);
