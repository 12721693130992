import React, {Component} from "react";
import FormSelect from "./form-select";

class FormBoolean extends Component {
  render() {
    return (
      <FormSelect
        {...this.props}
        data={[
          {label: "Yes", value: "1"},
          {label: "No", value: "0"},
        ]}
      />
    );
  }
}

FormBoolean.propTypes = {
  ...FormBoolean.propTypes,
};

export default FormBoolean;
