import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import * as Yup from "yup";
import {showSuccessAlert} from "../../utils/alert-helper";

class EmailModal extends Component {
  componentDidMount() {
    const {EMAIL} = this.props.account;

    if(!EMAIL || EMAIL.length === 0) {
      this.modal.open();
    }
  }

  updateAccount = async ({email}) => {
    const {ID} = this.props.account;

    await request("partner/account/" + ID + "/email", "POST", {EMAIL: email})

    this.props.refresh(email);
    this.modal.close();

    showSuccessAlert("Email Saved", "Your email information has been saved!");
  };

  render() {
    return (
      <Modal
        label="Add Email Address"
        description="You do not have an email associated with your account yet! Please save an email address."
        buttonLabel="Add"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          initialValues={{
            email: null,
          }}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.updateAccount}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .nullable()
              .required("Email is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-row justify-between">
                  <FormInput
                    className="flex-1 mr-2"
                    placeholder={"xyz@dripos.com"}
                    label="Email"
                    name="email"
                    options={formikOptions}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmailModal;
