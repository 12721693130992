import React, {Component} from "react";
import {Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import MessageDate from "../../components/messages/message-date";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getChatEmployees} from "../../utils/messaging/message-helper";
import {HIT_ICON} from "../../layouts/admin/admin-search";
import Fuse from "fuse.js";
import {setupReduxConnection} from "../../redux";
import Select from "react-dropdown-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {TYPE_LABEL} from "../../layouts/header/global-search";
import {request} from "../../utils/request";
import {showErrorNotification} from "../../utils/notification-helper";

class ChatParticipantsModal extends Component {
  state = {options: [], lastSearch: ""};

  constructor(props) {
    super(props);

    this.requestStack = [];
  }

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  async handleFormSubmit() {
    const {conversation} = this.props;

    const conversationPayload = {
      NAME: conversation.NAME ?? null,
      DEPARTMENT_IDS: conversation.DEPARTMENTS.map(
        ({DEPARTMENT_ID}) => DEPARTMENT_ID
      ),
      EMPLOYEE_IDS: conversation.PARTICIPANTS.map(
        ({EMPLOYEE_ID}) => EMPLOYEE_ID
      ),
      LOCATION_IDS: conversation.LOCATIONS.map(({LOCATION_ID}) => LOCATION_ID),
      ROLE_IDS: conversation.ROLES.map(({ROLE_ID}) => ROLE_ID),
    };

    try {
      await request(
        "messaging/conversations/" + conversation.ID,
        "PATCH",
        conversationPayload
      );
    } catch (err) {
      this.modal.close();

      return showErrorNotification(
        "Error Updating Chat Members",
        "We encountered an error trying to update chat members. Please refresh and try again."
      );
    }

    this.modal.close();
  }

  search(search, ignoreStack) {
    let {conversation} = this.props;
    const {employees = []} = this.props.shop;
    let {lastSearch, options} = this.state;

    if (this.requestStack.length > 1) return;

    if (lastSearch === search) {
      this.requestStack.shift();

      if (this.requestStack.length > 0) {
        this.requestStack = [this.requestStack.pop()];

        this.requestStack[0]();
      }

      return options;
    }

    if (search === "") {
      return this.setState({lastSearch: search, options: []}, () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      });
    }

    if (this.selectRef) {
      this.selectRef.state.cursor = 0;
    }

    const items = [
      ...employees.map((item) => {
        if (
          conversation.PARTICIPANTS.findIndex(
            ({ID, EMPLOYEE_ID}) => ID === item.ID || EMPLOYEE_ID === item.ID
          ) !== -1
        ) {
          return;
        }

        return {
          ...item,
          type: "employees",
          name: item.FULL_NAME,
          icon: HIT_ICON["employees"],
        };
      }),
    ];

    options = new Fuse(items, {
      keys: ["name"],
      useExtendedSearch: true,
      threshold: 0.1,
    })
      .search(search)
      .map(({item}) => item);

    this.setState(
      {
        lastSearch: search,
        options,
      },
      () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      }
    );
  }

  renderTop() {
    const {lastSearch, options} = this.state;

    return (
      <div className={"flex flex-1 w-full"}>
        <div
          className={
            "flex flex-none text-sm font-semibold text-indigo-500 mr-1"
          }
        >
          {"Add Participant:"}
        </div>

        <div className="max-w-lg w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>

          <Select
            noDataRenderer={() => {
              if (lastSearch === "") {
                return (
                  <div className="text-center p-6 text-sm">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Type to start searching
                    </h3>

                    <p className="mt-1 text-sm text-gray-500">
                      Type the name of an employee to add a participant to the
                      conversation
                    </p>
                  </div>
                );
              }

              return (
                <div className="text-center p-6 text-sm">
                  <FontAwesomeIcon
                    icon="cloud-question"
                    className="mx-auto h-12 w-12 text-gray-400"
                  />

                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No records found
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    We could not find anything with the search you inputted.
                  </p>
                </div>
              );
            }}
            className="global-search block w-full z-20"
            onDropdownClose={() => this.setState({lastSearch: ""})}
            dropdownHandleRenderer={() => {
              return <div />;
            }}
            ref={(e) => (this.selectRef = e)}
            onChange={(obj) => {
              if (obj?.length === 0) {
                return;
              }

              this.props.addParticipant(obj[0]);
            }}
            inputRenderer={({state, methods, inputRef}) => {
              return (
                <form
                  className="flex flex-1 relative w-full flex flex-row justify-start"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    className="w-full border-transparent rounded-md bg-white sm:text-sm"
                    style={{
                      outline: "none",
                      padding: "0px",
                      "--tw-ring-color": "transparent",
                    }}
                    onChange={methods.setSearch}
                    value={state.search}
                    autoComplete="off"
                    type="search"
                    ref={inputRef}
                    placeholder={"Type an employee name to search"}
                    id={"participant-search"}
                  />
                </form>
              );
            }}
            itemRenderer={({item, state, itemIndex, methods}) => {
              const isSelected = state.cursor === itemIndex;
              const {name, icon, type, extra} = item;

              return (
                <div
                  onClick={() => methods.addItem(item)}
                  className={classNames(
                    isSelected && "bg-indigo-500 text-white",
                    "group px-4 py-2 hover:bg-indigo-500 hover:text-white flex flex-row items-center"
                  )}
                >
                  {icon && (
                    <div className="mr-3">
                      <FontAwesomeIcon icon={icon} className="h-6 w-6 mt-1" />
                    </div>
                  )}

                  <div className="flex flex-col flex-1 text-sm">
                    <div className="font-medium">{name}</div>

                    <div
                      className={classNames(
                        isSelected
                          ? "text-white"
                          : "text-gray-500 group-hover:text-white",
                        "text-sm"
                      )}
                    >
                      {TYPE_LABEL[type]}
                    </div>
                  </div>
                </div>
              );
            }}
            searchFn={({state}) => {
              const {search} = state;

              this.search(search);

              return options;
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const {conversation, employees} = this.props;

    if (!conversation) {
      return <div></div>;
    }

    const employeeData = getChatEmployees(conversation.PARTICIPANTS, employees);

    return (
      <Modal
        className={"overflow-auto"}
        large
        whiteBackground
        label={"Chat Members"}
        hideClose
        buttonLabel={"Save"}
        buttonOnClick={() => this.handleFormSubmit()}
        ref={(e) => (this.modal = e)}
      >
        {this.renderTop()}

        <div className={"overflow-scroll"} style={{height: "65vh"}}>
          <Table
            data={employeeData}
            columns={[
              {
                value: "FULL_NAME",
                label: "NAME",
                format: (value, row) => {
                  return (
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={getObjectImage(
                            row,
                            "LOGO",
                            "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
                          )}
                          alt=""
                        />
                      </div>

                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {row.FULL_NAME}
                        </div>

                        <div className="text-sm text-gray-500">
                          {row.ROLES?.map((item) => item.ROLE_NAME).join(", ")}
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              {
                value: "LOCATION_NAME",
                label: "HOME LOCATION",
              },
            ]}
            actionButtons={[
              {
                label: "Remove",
                onClick: (row) => this.props.removeParticipant(row),
              },
            ]}
            ref={(e) => (this.tableRef = e)}
          />
        </div>
      </Modal>
    );
  }
}

MessageDate.propTypes = {
  employees: PropTypes.array.isRequired,
  conversation: PropTypes.object.isRequired,
  removeParticipant: PropTypes.func.isRequired,
};

export default setupReduxConnection(["user", "shop"])(
  withRouter(ChatParticipantsModal)
);
