import React, {Component} from "react";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import CompanyFileModal from "../../../modals/team/employee/onboarding/company-file-modal";
import {request} from "../../../utils/request";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";
import {
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/notification-helper";
import GrayBadge from "../../../components/badges/gray-badge";

class CompanyDocumentsPage extends Component {
  state = {documents: [], signatures: []};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("company-documents/files", "GET").then((documents) => {
      this.setState({documents});
    });
  }

  deleteDocument(row) {
    showLoadingConfirmAlert(
      "Delete Document",
      "Are you sure you want to delete this document?"
    ).then(async (close) => {
      request("company-documents/" + row.ID, "DELETE")
        .then(async () => {
          await this.syncState();
          close();

          showSuccessNotification(
            "Document Deleted",
            "We have deleted the document"
          );
        })
        .catch((err) => {
          close();
          const processName = err[0] ? err[0].NAME : "a process";

          showErrorNotification(
            "Error deleting company document",
            "The document is currently in use by " +
              processName +
              " and cannot be deleted"
          );
        });
    });
  }

  render() {
    const {documents} = this.state;

    return (
      <>
        <CompanyFileModal
          ref={(e) => (this.fileModal = e)}
          syncState={() => this.syncState()}
        />

        <div>
          <PageHeadings
            label={
              <div className={"flex flex-row items-center"}>
                <div>Company Documents</div>

                <GrayBadge darkGrey className={"ml-3"}>
                  Beta
                </GrayBadge>
              </div>
            }
            buttons={[
              {
                label: "New Document",
                onClick: () => this.fileModal.open(),
              },
            ]}
          />

          <Table
            classNames={"mt-4"}
            data={documents}
            actionButtons={[
              {
                label: "Configure",
                onClick: (row) =>
                  this.props.router.navigate(
                    "/onboarding/company-document/" + row.UNIQUE_ID
                  ),
              },
              {
                label: "Delete",
                onClick: (row) => this.deleteDocument(row),
              },
            ]}
            columns={[
              {
                value: "FILE_NAME",
                label: "File Name",
              },
              {
                value: "DATE_UPLOADED",
                label: "Date Uploaded",
                format: (value) => moment(value).format("M/D/YY"),
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default withRouter(CompanyDocumentsPage);
