import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import {
  getPayrollEmployee,
  getPayrollEmployeeOnboardingLink,
  onboardCodeToEmployee,
} from "../../../../utils/payroll-helper";
import {withRouter} from "../../../../utils/navigation";

class PayrollEmployeeTaxAuthSetupComponent extends Component {
  state = {isLoading: true, status: null};

  async componentDidMount() {
    this.openHosted();
  }

  openHosted() {
    const {ID} = this.props.router.params;

    this.setState({isLoading: true});

    getPayrollEmployeeOnboardingLink(ID).then((res) => {
      const handler = window.Check.create({
        link: res.url,
        onClose: this.onClose,
      });
      handler.open();
    });
  }

  onClose = () => {
    const {ID} = this.props.router.params;

    getPayrollEmployee(ID).then((res) => {
      let {status} = res.onboard;

      this.setState({isLoading: false, status});
    });
  };

  render() {
    let {isLoading, status} = this.state;

    if (isLoading) {
      return (
        <div className=" h-96 flex flex-col justify-center">
          <LoadingSpinner />
        </div>
      );
    }

    if (status !== "completed") {
      return (
        <div className="h-96 flex flex-col justify-center items-center ">
          <div className="font-bold text-gray-800">Onboarding Incomplete</div>
          <div className="font-bold text-sm text-gray-600">
            You may resume now or visit this page later to finish
          </div>

          <button
            type="button"
            className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              this.openHosted();
            }}
          >
            Resume Payroll Onboarding
          </button>
        </div>
      );
    }

    return (
      <div className="h-96 flex flex-col justify-center items-center font-bold">
        <div>
          Good work! You've completed the payroll onboarding, and can close this
          page!
        </div>
      </div>
    );
  }
}

export default withRouter(PayrollEmployeeTaxAuthSetupComponent);
