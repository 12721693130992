import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {
  Loading,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import PromotionsTable from "../../../../tables/marketing/deals/promotions-table";

class PromotionsPage extends Component {
  state = {
    isLoading: true,
    promotions: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let promotions = await request("promotions", "GET");

    this.setState({isLoading: false, promotions});
  }

  render() {
    const {isLoading, promotions} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const activePromotions = promotions?.filter((item) => {
      return item.ENABLED === 1;
    });

    const inactivePromotions = promotions?.filter((item) => {
      return item.ENABLED === 0;
    });

    return (
      <div>
        <PageHeadings
          label="Promotions"
          description="Promotional events for all of your customers"
          button={{
            label: "Create Promotion",
            onClick: () => this.props.router.navigate("/promotion"),
          }}
        />

        <Tab
          data={[
            {
              id: "active",
              label: "Active Promotions",
              badge: activePromotions ? "" + activePromotions.length : "",
            },
            {
              id: "inactive",
              label: "Inactive Promotions",
              badge: inactivePromotions ? "" + inactivePromotions.length : "",
            },
          ]}
        >
          {(id) => {
            let finalPromotions = null;
            if (promotions) {
              if (id === "active") {
                finalPromotions = activePromotions;
              } else if (id === "inactive") {
                finalPromotions = inactivePromotions;
              }
            }

            return (
              <PromotionsTable className="mt-4" promotions={finalPromotions} />
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(PromotionsPage));
