export const CUSTOM_BENEFIT_TYPES = {
  "125_medical": "125 Medical",
  "125_medical_other": "125 Medical (Other)",
  "125_vision": "125 Vision",
  "125_dental": "125 Dental",
  "125_disability": "125 Disability",
  "125_accident": "125 Accident",
  "125_cancer": "125 Cancer",
  "125_critical_illness": "125 Critical Illness",
  "125_hospital": "125 Hospital",
  "125_life": "125 Life",
  "401k": "401k",
  "403b": "403b",
  "457": "457",
  "roth_401k": "Roth 401k",
  "roth_403b": "Roth 403b",
  "roth_457": "Roth 457",
  "fsa_medical": "FSA Medical",
  "fsa_dependent_care": "FSA Dependent Care",
  "hsa": "HSA",
  "simple_ira": "Simple IRA",
};
