import React, {Component} from "react";
import {
  PageHeadings,
  Table,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import GiftCardModal from "../../../modals/marketing/gift-cards/gift-card-modal";
import {setupReduxConnection} from "../../../redux";
import ImportGiftCardsModal from "../../../modals/marketing/import/import-gift-cards-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {saveAs} from "file-saver";
import {CSVLink} from "react-csv";

class GiftCardsPage extends Component {
  state = {
    cards: null,
    downloadData: "",
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let cards = await request("cards", "GET");

    cards.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED);
    cards = cards.map((gc) => ({
      ...gc,
      SORT_NAME:
        gc?.FULL_NAME?.trim()?.length > 0
          ? gc.FULL_NAME.trim()
          : gc.ACCOUNT_ID
          ? "zzzzzzzz"
          : "zzzzzzzzzzz",
    }));

    this.setState({cards});
  }

  render() {
    const {cards, downloadData} = this.state;
    const {location} = this.props.shop;
    const {permissions} = this.props.user;
    const hasEditPermission =
      permissions.indexOf("ADMIN_FULL") !== -1 ||
      permissions.indexOf("GIFT_CARDS_EDIT_FULL") !== -1;

    const actionButtons = [];

    if (hasEditPermission) {
      actionButtons.push(
        {
          label: "Export Gift Cards",
          onClick: () => {
            request("cards/export", "POST", null).then((data) => {
              this.setState({downloadData: data}, () =>
                this.csvRef.link.click()
              );
            });
          },
        },
        {
          label: "Import Gift Cards",
          onClick: () => {
            this.importModal.open();
          },
        },
        {
          label: "Create Gift Card",
          onClick: () => {
            this.giftCardModal.open();
          },
        }
      );
    }

    return (
      <div>
        <CSVLink
          ref={(e) => (this.csvRef = e)}
          data={downloadData}
          filename={`gift-cards-${location.NAME.replaceAll(
            " ",
            "-"
          )}-${moment().format("MM/DD/YY")}.csv`}
          headers={[
            {label: "Phone Number", key: "PHONE"},
            {label: "Email", key: "EMAIL"},
            {label: "Name", key: "FULL_NAME"},
            {label: "Gift Card Number", key: "PAN_ID"},
            {label: "Balance", key: "BALANCE"},
            {label: "Total", key: "TOTAL"},
            {label: "Date Created", key: "DATE_CREATED"},
            {label: "Reloaded Date", key: "DATE_RELOADED"},
          ]}
        />

        <GiftCardModal
          ref={(e) => (this.giftCardModal = e)}
          updateState={() => this.syncState()}
          onCreate={(row) =>
            this.props.router.navigate("/gifts/" + row.UNIQUE_ID)
          }
        />

        <ImportGiftCardsModal
          ref={(e) => (this.importModal = e)}
          updateState={(gifts) => {
            this.setState({cards: [...gifts, ...cards]});
          }}
        />

        <PageHeadings
          label="Gift Cards"
          description="View all physical and digital gift cards that have been issued"
          buttons={actionButtons}
        />

        <Filter
          className="mt-2"
          searchable
          data={[
            {
              id: "balance",
              label: "Filter",
              options: [{id: "hide-empty", label: "Hide Empty"}],
              onFilter: (options, data) => {
                if (options.length === 0) {
                  return data;
                }

                if (
                  options.findIndex((option) => option === "hide-empty") !== -1
                ) {
                  return data.filter((gc) => gc.BALANCE > 0);
                }

                return data;
              },
            },
          ]}
        >
          {(filters, search) => (
            <Table
              sortable
              pagination
              data={cards}
              filters={filters}
              search={search}
              searchFields={["FULL_NAME", "PHONE", "PAN_ID", "PHONE"]}
              actionButtons={[
                {
                  label: "View",
                  onClick: (row) =>
                    this.props.router.navigate("/gifts/" + row.UNIQUE_ID),
                },
              ]}
              columns={[
                {
                  value: "SORT_NAME",
                  label: "Patron",
                  format: (name, row) =>
                    row.ACCOUNT_ID ? (
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {row?.FULL_NAME ? row?.FULL_NAME : "Unnamed Patron"}
                        </div>

                        {row.PHONE ? (
                          <div className="text-sm text-gray-500">
                            {getFormattedPhone(row.PHONE)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      "-"
                    ),
                },
                {
                  value: "PAN_ID",
                  label: "Pan",
                  format: (pan) => (pan ? pan : "-"),
                },
                {
                  value: "BALANCE",
                  label: "Balance",
                  format: (balance) => "$" + toDollars(balance),
                },
                {
                  width: 1,
                  value: "DATE_CREATED",
                  label: "Date created",
                  mobile: "lg:table-cell",
                  format: (value, _) => {
                    return moment(value).format("MM/DD/YY");
                  },
                },
                {
                  width: 1,
                  value: "DATE_UPDATED",
                  label: "Last used",
                  mobile: "lg:table-cell",
                  format: (value, _) => {
                    return value ? moment(value).format("MM/DD/YY") : "-";
                  },
                },
              ]}
            />
          )}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(
  withRouter(GiftCardsPage)
);
