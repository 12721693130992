import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import FormDropZone from "../../components/form-drop-zone";
import {request} from "../../utils/request";
import StatusModal from "../../components/status-modal";
import ImageFormDropZone from "../../components/image-form-drop-zone";

class ImportMenuModal extends Component {
  state = {rate: null};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async sendImport({type, menu, modifier}) {
    this.statusModal.open(
      "Upload Started",
      "You can leave this tab, but in this tab don't navigate from this current page. The page will reload once the menu has been fully uploaded."
    );

    if (type === "SQUARE") {
      await request("import/square/menu", "POST", {
        MENU: menu,
        MODIFIER: modifier,
      });
    }

    window.location.reload(true);
  }

  renderContent(formikOptions) {
    const {type} = formikOptions.values;

    if (type === "SQUARE") {
      return (
        <div>
          <ImageFormDropZone
            name="menu"
            type="CSV"
            tooltip={{
              label: "Finding Menu CSV",
              data: "If you require help with this step, please contact support at (781) 583-3699",
            }}
            label="Menu CSV"
            options={formikOptions}
          />

          <ImageFormDropZone
            name="modifier"
            type="CSV"
            tooltip={{
              label: "Finding Menu CSV",
              data: "If you require help with this step, please contact support at (781) 583-3699",
            }}
            label="Modifiers CSV"
            options={formikOptions}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    const {rate} = this.state;

    return (
      <Modal
        label="Import Menu"
        buttonLabel="Import"
        description="Pull a menu from your old platform and use it here"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          onSubmit={this.sendImport.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.lazy(({type}) => {
            const defaultSchema = {};

            if (type === "SQUARE") {
              defaultSchema.menu = Yup.array().required("Menu has to be set");

              defaultSchema.modifier = Yup.array().required(
                "Modifier has to be set"
              );
            }

            return Yup.object(defaultSchema);
          })}
          initialValues={{
            type: "SQUARE",
            menu: "",
            modifier: "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <StatusModal ref={(e) => (this.statusModal = e)} />

                <FormSelect
                  tooltip={{
                    data: "Choose which platform you are importing your menu from.",
                  }}
                  label="Old Platform"
                  name="type"
                  options={formikOptions}
                  data={[
                    {label: "Square", value: "SQUARE"},
                    {label: "Toast", value: "TOAST"},
                    {label: "Clover", value: "CLOVER"},
                  ]}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ImportMenuModal.propTypes = {
  onCreate: PropTypes.func,
  onSave: PropTypes.func,

  role: PropTypes.object,
};

export default ImportMenuModal;
