import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {setupReduxConnection} from "../../redux";
import {PERMISSIONS} from "../../utils/constants";

class FinanceSideNav extends Component {
  render() {
    let {TYPE, FA_ID, CHECK_COMPANY_ID, PAYROLL_APPROVED, PAYROLL_MAIN} =
      this.props.shop.location;
    let {TAX_WITHHOLDING} = this.props.shop.settings;

    let cashMenu = {
      live: true,
      id: "instant-cash-account",
      name: "Cash Account",
      href: "/transactions",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      children: [
        {
          live: true,
          name: "Transactions",
          href: "/transactions",
          paths: ["transactions"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
        {
          live: true,
          name: "Manage Cards",
          href: "/cards",
          paths: ["cards"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
        {
          live: true,
          name: "Payouts",
          href: "/cash/payouts",
          paths: ["cash/payouts"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
        {
          live: true,
          name: "Settings",
          href: "/cash/settings",
          paths: ["cash/settings"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
      ],
    };

    if (!FA_ID) {
      cashMenu = {
        live: true,
        id: "payouts",
        name: "Payouts",
        href: "/payouts",
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        children: [
          {
            live: true,
            name: "Settings",
            href: "/payouts/settings",
            paths: ["/payouts/settings"],
            permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
          },
        ],
      };

      if (TAX_WITHHOLDING === "1") {
        cashMenu.children.unshift({
          live: true,
          name: "Tax Withholding",
          href: "/payouts/tax-withholding",
          paths: ["/payouts/tax-withholding"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        });
      }
      // {
      //   live: true,
      //   id: "instant-cash-account",
      //   name: "Setup Cash Account",
      //   href: "/cash/setup",
      //   permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      // },
    }

    let payrollMenu = {
      live: true,
      id: "menu",
      name: "Payroll",
      href: "/payroll",
      permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      children: [
        {
          live: true,
          name: "Employees",
          href: "/payroll/employees",
          paths: ["/payroll/employees"],
          permissions: [],
        },
        {
          live: true,
          name: "Contractors",
          href: "/payroll/contractors",
          paths: ["/payroll/contractors"],
          permissions: [],
        },
        {
          live: true,
          name: "Benefits",
          href: "/payroll/benefits",
          paths: ["/payroll/benefits"],
          permissions: [],
        },
        {
          live: true,
          name: "History",
          href: "/payrolls",
          paths: ["/payrolls"],
          permissions: [],
        },
        {
          live: true,
          name: "Company Tax Documents",
          href: "payroll/company-tax-documents",
          paths: ["/payroll/company-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Employee Tax Documents",
          href: "payroll/employee-tax-documents",
          paths: ["/payroll/employee-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Contractor Tax Documents",
          href: "payroll/contractor-tax-documents",
          paths: ["/payroll/contractor-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Journal",
          href: "payroll/journal",
          paths: ["/payroll/journal"],
          permissions: [],
        },
        {
          live: true,
          name: "Settings",
          href: "payroll/settings",
          paths: ["/payroll/settings"],
          permissions: [],
        },
      ],
    };

    if (CHECK_COMPANY_ID && !PAYROLL_MAIN) {
      payrollMenu = {
        live: true,
        id: "menu",
        name: "Payroll Employees",
        href: "/payroll/employees",
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      };
    }

    if (CHECK_COMPANY_ID && !PAYROLL_APPROVED && PAYROLL_MAIN) {
      payrollMenu = {
        live: true,
        id: "menu",
        name: "Setup Payroll",
        href: "/payroll",
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
        children: [
          {
            live: true,
            name: "Employees",
            href: "/payroll/employees",
            paths: ["/payroll/employees"],
            permissions: [],
          },
          {
            live: true,
            name: "Contractors",
            href: "/payroll/contractors",
            paths: ["/payroll/contractors"],
            permissions: [],
          },
          {
            live: true,
            name: "Benefits",
            href: "/payroll/benefits",
            paths: ["/payroll/benefits"],
            permissions: [],
          },
          {
            live: true,
            name: "Settings",
            href: "payroll/settings",
            paths: ["/payroll/settings"],
            permissions: [],
          },
        ],
      };
    }

    let loansMenu = {
      live: true,
      id: "capital",
      name: "Dripos Capital",
      href: "/capital",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
    };

    let billingMenu = {
      live: true,
      id: "billing",
      name: "Billing",
      href: "/billing",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
    };

    if (!CHECK_COMPANY_ID) {
      payrollMenu = {
        live: true,
        id: "menu",
        name: "Setup Payroll",
        href: "/payroll",
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      };
    }

    let title = "Finance";
    let optionsArr = [cashMenu, payrollMenu, billingMenu, loansMenu];

    if (TYPE === "PAYROLL") {
      title = "Payroll";
      optionsArr = [payrollMenu];
    }

    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title={title}
              permissions={[PERMISSIONS.FINANCE.FULL.id]}
              navigation={optionsArr}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(FinanceSideNav);
