import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";
import {withRouter} from "../../utils/navigation";

class DevicesTable extends Component {
  render() {
    const {devices} = this.props;

    return (
      <>
        <Table
          pagination
          className="mt-4"
          data={devices}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) =>
                this.props.router.navigate("/device/" + row.ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "INFO",
              label: "Device Info",
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, _) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Last Used",
              mobile: "lg:table-cell",
              format: (value) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
        />
      </>
    );
  }
}

DevicesTable.propTypes = {
  devices: PropTypes.array.isRequired,
};

export default withRouter(DevicesTable);
