import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../../utils/alert-helper";
import {Formik} from "formik";
import ServiceFeeModal from "../../../../modals/sales/fees/service-fee-modal";

class ServiceFeePage extends Component {
  state = {
    fee: null,
    types: null,
    ticketTypes: null,
  };

  componentDidMount() {
    this.syncState();
  }

  syncState = () => {
    const {ID: id} = this.props.router.params;

    request("fees/" + id, "GET").then(async (fee) => {
      let types = await request("types/order", "GET");

      this.setState({
        fee,
        types,
        ticketTypes: fee.TICKET_TYPES,
      });
    });
  };

  delete = async (category) => {
    showLoadingConfirmAlert(
      "Delete Service Fee",
      "Are you sure you want to delete this service fee?"
    ).then(async (close) => {
      await request("fees/" + category.ID, "DELETE", null);

      close();

      this.props.router.navigate("/fees");
    });
  };

  async updateSplit({types}) {
    const {fee} = this.state;

    if (types.length === 0 && fee.TICKET_TYPES.length === 0) return;

    if (types.length > 0) {
      const typeSet = new Set();

      for (let item of types) {
        if (item.TICKET_TYPE_ID === null) {
          return showErrorAlert(
            "Ticket Type Invalid",
            "You must set a ticket type"
          );
        }

        typeSet.add(item.TICKET_TYPE_ID);
      }

      if (typeSet.size !== types.length) {
        return showErrorAlert(
          "Duplicate Ticket Type",
          "You can only select a ticket type once"
        );
      }
    }

    this.splitButton.startLoading();

    try {
      const serverTypes = await request("fees/" + fee.ID + "/types", "POST", {
        TICKET_TYPES: types,
      });

      this.splitButton.stopLoading();

      this.setState({
        ticketTypes: serverTypes,
        fee: {...fee, TICKET_TYPES: serverTypes},
      });
    } catch (e) {
      return this.splitButton.stopLoading();
    }
  }

  render() {
    const {fee, types, ticketTypes} = this.state;

    if (fee === null) {
      return <Loading />;
    }

    return (
      <div>
        <ServiceFeeModal
          ref={(e) => (this.feeModal = e)}
          updateState={(_, fee) => this.setState({fee})}
        />

        <PageHeadings
          label={fee?.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Service Fee",
                      onClick: () => this.delete(fee),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Service Fees Page", url: "/fees"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.feeModal.open(fee),
            },
          ]}
          label={fee.NAME + " Information"}
          description="Information about this service fee"
          data={[
            {
              label: "Name",
              value: fee.NAME,
            },
            {
              label: "Calculate on Subtotal",
              value: fee.IS_PRE_TAX,
              type: "bool",
              tooltip: {
                label: "Calculate on Subtotal",
                data:
                  "If set to yes, the service fee will be calculated on the  pre-tax amount. Otherwise, the service" +
                   " fee will be calculated on the order total.",
              },
            },
            {
              label: "Amount",
              value: fee.AMOUNT,
              type: fee.TYPE === "FLAT" && "dollars",
            },
            {
              label: "Type",
              value: fee.TYPE,
            },
            {
              label: "Tax Rate",
              value: fee.TAX_RATE_NAME ?? "None",
              hint: "Optional",
            },
            {
              label: "Minimum Subtotal Amount",
              value: fee.MIN_AMOUNT,
              type: "dollar",
              hint: "Optional",
            },
            {
              label: "Maximum Subtotal Amount",
              value: fee.MAX_AMOUNT,
              type: "dollar",
              hint: "Optional",
            },
          ]}
        />

        <Tab className="mt-2" data={[{label: "Ticket Types", id: "types"}]}>
          {(id) => {
            if (id === "types") {
              return (
                <Formik
                  onSubmit={this.updateSplit.bind(this)}
                  innerRef={(e) => (this.formikRef = e)}
                  enableReinitialize
                  initialValues={{
                    types: ticketTypes,
                  }}
                >
                  {(formikOptions) => {
                    const {setFieldValue, values, handleSubmit} = formikOptions;
                    const typesValue = values.types;

                    return (
                      <Card
                        label="Automatic Ticket Types"
                        description="Which ticket types will this fee automatically be added to"
                        button={{
                          label: "Save Types",
                          ref: (e) => (this.splitButton = e),
                          onClick: handleSubmit,
                        }}
                      >
                        <Table
                          className="mt-2"
                          data={typesValue}
                          actionTextClassName="mr-4"
                          actionTexts={[
                            {
                              label: "Add Ticket Type",
                              onClick: () => {
                                const splits = [...typesValue];

                                splits.push({
                                  TICKET_TYPE_ID: null,
                                  ENABLED: 1,
                                });

                                setFieldValue("types", splits);
                              },
                            },
                          ]}
                          actionButtons={[
                            {
                              icon: "trash",
                              onClick: (_, index) => {
                                const types = [...typesValue];

                                types.splice(index, 1);

                                setFieldValue("types", types);
                              },
                            },
                          ]}
                          columns={[
                            {
                              value: "TICKET_TYPE_ID",
                              label: "Ticket Type",
                              type: "select",
                              data: [
                                {label: "Select Ticket Type", value: null},
                                ...types.map((item) => {
                                  return {
                                    label: item.NAME,
                                    value: "" + item.ID,
                                  };
                                }),
                              ],
                              editable: true,
                              onChange: (index, val) => {
                                const row = typesValue[index];

                                typesValue.splice(index, 1, {
                                  ...row,
                                  TICKET_TYPE_ID: parseInt(val),
                                });

                                setFieldValue("splits", typesValue);
                              },
                            },
                            {
                              value: "ENABLED",
                              label: "Enabled",
                              type: "select",
                              data: [
                                {label: "Yes", value: 1},
                                {label: "No", value: 0},
                              ],
                              editable: true,
                              onChange: (index, val) => {
                                const row = typesValue[index];

                                typesValue.splice(index, 1, {
                                  ...row,
                                  ENABLED: parseInt(val),
                                });

                                setFieldValue("types", typesValue);
                              },
                            },
                          ]}
                        />
                      </Card>
                    );
                  }}
                </Formik>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ServiceFeePage));
