import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class SubscriptionListDetailsModal extends Component {
  state = {list: null};

  open(list = null) {
    this.setState({list});
    this.modal.open();
  }

  async saveList({name}) {
    const {list} = this.state;

    if (list) {
      await request("text/marketing/list/" + list.ID, "PATCH", {
        NAME: name,
      });
    } else {
      await request("text/marketing/list", "POST", {
        NAME: name,
      });
    }

    this.props.onSubmit && this.props.onSubmit();
    this.modal.close();
  }

  render() {
    const {list} = this.state;

    return (
      <Modal
        buttonLabel={list ? "Save" : "Create"}
        label={list ? "Update Subscriber List" : "Create Subscriber List"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveList.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required(),
          })}
          initialValues={{
            notes: list?.NAME ? list.NAME : null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="name"
                  label="Name"
                  tooltip={{
                    data: "A name to describe which patrons are on this list. The patrons will not see this.",
                  }}
                  options={formikOptions}
                  placeholder={"Frequent Customers"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SubscriptionListDetailsModal;
