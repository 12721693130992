import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import SuccessBadge from "../../../../components/badges/success-badge";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import SubscriptionListModal from "../../../../modals/marketing/subscription-lists/subscription-list-modal";

class SubscriptionListPage extends Component {
  state = {isLoading: true, list: null, defaultList: null};

  componentDidMount() {
    this.setState({isLoading: true});
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const list = await request("text/marketing/list", "GET", {LIST_ID: id});
    const defaultList = await request("text/marketing/list", "GET");

    this.setState({list, isLoading: false, defaultList});
  }

  async deleteSubscriptionList() {
    const {list} = this.state;

    await request("text/marketing/list/" + list.ID, "DELETE", null);

    this.props.router.navigate("/subscriber-lists");
  }

  renderDefault() {
    const {list} = this.state;

    return (
      <>
        <SubscriptionListModal
          ref={(e) => (this.subscriptionListModal = e)}
          onSubmit={(selectedList) =>
            this.props.router.navigate("/subscriber-list/" + selectedList)
          }
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{list.NAME}</div>
              <SuccessBadge className="ml-2 mt-2 h-1/2">Default</SuccessBadge>
            </div>
          }
          description={`View and manage this subscription list`}
        />

        <Card
          label="All Subscribers"
          description="View and manage all of the patrons that have subscribed to text marketing"
          tooltip={{
            data:
              "The default subscription list contains all of the patrons who have subscribed to text" +
              " marketing. You cannot manually add patrons to this list, they must instead opt-in to receive texts.",
            label: "Default List",
          }}
        >
          <Table
            pagination
            selectable
            rowsPerPage
            selectButtons={[
              {
                label: "Add to list",
                onClick: (data) => {
                  this.subscriptionListModal.open(
                    data.map((patron) => patron.ID)
                  );
                },
              },
            ]}
            select={() => {
              console.log("HI");
            }}
            data={list.Patrons}
            ref={(e) => (this.tableRef = e)}
            actionButtons={[
              {
                label: "View Patron",
                onClick: (row) =>
                  this.props.router.navigate("/patron/" + row.ID),
              },
            ]}
            columns={[
              {
                value: "FULL_NAME",
                label: "Name",
                format: (value, row) => {
                  return (
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {value || "-"}
                      </div>
                      <div className="text-sm text-gray-500">{row.EMAIL}</div>
                    </div>
                  );
                },
              },
              {
                value: "PHONE",
                label: "Phone Number",
                format: (value, _) => getFormattedPhone(value),
              },
            ]}
          />
        </Card>
      </>
    );
  }

  renderBody() {
    const {list, defaultList} = this.state;

    const notAddedPatrons = defaultList.Patrons.filter(
      (patron) => !list?.Patrons?.find((_patron) => _patron.ID === patron.ID)
    );

    return (
      <>
        <PageHeadings
          label={list.NAME}
          description={`View and manage this subscription list`}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete List",
                      onClick: () => this.deleteSubscriptionList(),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <Card
          label="Subscriber List"
          description="View the patrons that are attached to this subscription list"
        >
          <Table
            pagination
            data={list.Patrons || []}
            ref={(e) => (this.tableRef = e)}
            actionButtons={[
              {
                label: "View Patron",
                onClick: (row) =>
                  this.props.router.navigate("/patron/" + row.ID),
              },
              {
                icon: "trash",
                onClick: (row) => {
                  request("text/marketing/unsubscribe", "POST", {
                    PATRON_ID: row.PATRON_ID,
                    LIST_ID: list.ID,
                  }).then(() => {
                    this.setState({isLoading: true}, () => {
                      this.syncState();
                    });
                  });
                },
              },
            ]}
            columns={[
              {
                value: "FULL_NAME",
                label: "Name",
                format: (value, row) => {
                  return (
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {value || "-"}
                      </div>
                      <div className="text-sm text-gray-500">{row.EMAIL}</div>
                    </div>
                  );
                },
              },
              {
                value: "PHONE",
                label: "Phone Number",
                format: (value, _) => getFormattedPhone(value),
              },
            ]}
          />
        </Card>

        <Card
          label="All Subscribers"
          description="View all the subscribed patrons and add them to this list"
        >
          <Table
            pagination
            selectable
            rowsPerPage
            selectButtons={[
              {
                label: "Add to list",
                onClick: (data) => {
                  request("text/marketing/list/patrons/", "POST", {
                    LIST_ID: list.ID,
                    PATRONS: data.map((_patron) => _patron.PATRON_ID),
                  }).then(() => this.syncState());
                },
              },
            ]}
            data={notAddedPatrons}
            ref={(e) => (this.tableRef = e)}
            actionButtons={[
              {
                label: "View Patron",
                onClick: (row) =>
                  this.props.router.navigate("/patron/" + row.ID),
              },
            ]}
            columns={[
              {
                value: "FULL_NAME",
                label: "Name",
                format: (value, row) => {
                  return (
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {value || "-"}
                      </div>
                      <div className="text-sm text-gray-500">{row.EMAIL}</div>
                    </div>
                  );
                },
              },
              {
                value: "PHONE",
                label: "Phone Number",
                format: (value, _) => getFormattedPhone(value),
              },
            ]}
          />
        </Card>
      </>
    );
  }

  render() {
    const {isLoading, list} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return list.IS_DEFAULT ? this.renderDefault() : this.renderBody();
  }
}

export default withRouter(SubscriptionListPage);
