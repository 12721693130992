import React, {Component} from "react";
import FormElement from "./form-element";
import PropTypes from "prop-types";

class FormCheck extends Component {
  render() {
    const {name, label, description} = this.props;

    return (
      <FormElement {...this.props} label={undefined} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id={name}
                  name={name}
                  type="checkbox"
                  checked={value}
                  aria-describedby={name + "-description"}
                  className="focus:ring-primary-border h-4 w-4 text-primary-text border-neutral-border rounded"
                  onChange={(e) => {
                    onChange(e);

                    setTimeout(() => {
                      onChangeSoft && onChangeSoft(e.target.checked);
                    }, 1);
                  }}
                />
              </div>

              <div className="ml-6 text-sm">
                <label htmlFor={name} className="font-medium text-gray-700">
                  {label}
                </label>

                <p id={name + "-description"} className="text-neutral-text">
                  {description}
                </p>
              </div>
            </div>
          );
        }}
      </FormElement>
    )
  }
}

FormCheck.propTypes = {
  ...FormElement.propTypes,

  description: PropTypes.string,
}

export default FormCheck;
