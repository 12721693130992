import React, {Component} from "react";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import {request} from "../../utils/request";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ExpandableListComponent from "../../components/expandable-list-component";
import LoadingSpinner from "../../components/loading-spinner";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GrayBadge from "../../components/badges/gray-badge";
import {getTicketTransactionDate} from "../../utils/tip-helper";

class TipBreakdownModal extends Component {
  state = {timesheet: null, loading: false, summary: null, error: null};

  open(timesheet = null) {
    this.setState({timesheet}, () => {
      this.modal.open();

      this.syncState();
    });
  }

  close() {
    this.modal.close();
  }

  async syncState() {
    this.setState({loading: true});
    const {timesheet} = this.state;
    let serverData;

    try {
      serverData = await request(
        "team/time/tip-summary/" + timesheet.ID,
        "GET"
      );
    } catch (error) {
      return this.setState({error});
    }

    const serverEmployees = await request("employees/v2", "GET");

    this.setState({
      summary: serverData,
      employees: serverEmployees,
      loading: false,
    });
  }

  formatSummary(summary) {
    const {REQUIRE_TIME_CARD_BLESSINGS} = this.props.shop.settings;

    if (!summary) return;

    const tipSummary = summary?.TIP_SUMMARY;
    const creditDebit = summary?.CREDIT_DEBIT_SUMMARY;

    if (!tipSummary) {
      alert(
        "No records found for this time card. Please reload the webpage or contact support."
      );
    }

    const tipAmount = Object.values(tipSummary).reduce((total, ticket) => {
      if (!isNaN(parseInt(ticket.AMOUNT))) {
        return total + ticket.AMOUNT;
      }
      return total;
    }, 0);

    const toReturn = [
      {
        label: "Tip Summary (This Card)",
        amount: tipAmount,
        expandableComponent: this.renderTips(summary, tipAmount),
      },
    ];

    if (REQUIRE_TIME_CARD_BLESSINGS === "1" && creditDebit) {
      const creditDebitAmount = creditDebit.PAYOUT_TIMESHEETS.reduce(
        (total, _timesheet) => total + _timesheet.AMOUNT,
        0
      );

      toReturn.push({
        label: "Credit Debit Summary",
        amount: creditDebitAmount,
        expandableComponent: this.renderTipCredit(summary, creditDebitAmount),
      });
    }

    return toReturn;
  }

  renderError() {
    return (
      <div>
        Tip data unavailable for this time card. Please refresh the page or
        contact support if you believe this is a mistake.
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  renderDollarString(dollar) {
    let textColor = "";

    if (dollar > 0) {
      textColor = "text-green-600";
    } else if (dollar < 0) {
      textColor = "text-red-600";
    }

    return (
      <div className={`flex ${textColor} ml-1`}>
        {toDollars(Math.abs(dollar), true)}
      </div>
    );
  }

  renderTipCredit(summary, creditDebitTotal) {
    const {employees} = this.state;

    let toReturn = summary.CREDIT_DEBIT_SUMMARY.PAYOUT_TIMESHEETS.map(
      (item) => {
        const cardEmployee = employees.find(
          (_employee) => _employee.ID === item.TIMESHEET.EMPLOYEE_ID
        );
        const amount = item.AMOUNT;

        const data = {id: item.TIMESHEET.ID};
        const queryString = new URLSearchParams(data).toString();

        return (
          <div className="flex ml-3">
            <ExpandableListComponent
              header={
                <div className={"flex flex-row w-full justify-between"}>
                  <div
                    className={"flex flex-row justify-start items-center"}
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/cards/?${queryString}`,
                        "_blank"
                      )
                    }
                  >
                    {`${cardEmployee.FULL_NAME}'s Time Card`}

                    <FontAwesomeIcon
                      className="ml-2 h-3 w-3"
                      icon={"external-link"}
                      color={"indigo-500"}
                      light
                    />

                    <div className={"ml-2 text-gray-800"}>
                      {`(${item.TIMESHEET.ROLE_NAME}, ${moment(
                        item.TIMESHEET.DATE_START
                      ).format("M/D h:mm A")})`}
                    </div>
                  </div>

                  <div>{this.renderDollarString(amount)}</div>
                </div>
              }
              headerStyle={"mt-1 flex flex-auto text-indigo-500"}
              data={this.renderTimesheetEvents(item.LOGS)}
            />
          </div>
        );
      }
    );

    toReturn.push(
      <div
        className={
          "mt-1 flex flex-row justify-between ml-9 text-gray-800 border-t"
        }
      >
        <div>Total Credit Debit Applied</div>

        <div>{this.renderDollarString(creditDebitTotal)}</div>
      </div>
    );

    return toReturn;
  }

  renderTimesheetEvents(logs) {
    const {employees} = this.state;

    if (logs.length === 0) {
      return [];
    }

    return logs.map((_log) => {
      const timesheet = _log.TIMESHEET;
      const cardEmployee = employees.find(
        (_employee) => _employee.ID === timesheet.EMPLOYEE_ID
      );

      return (
        <div className="ml-7 text-gray-500 text-sm">
          {`${this.fetchTypeString(_log.TYPE)}: ${
            cardEmployee.FULL_NAME
          }'s Time Card (Start Time: ${moment(timesheet.DATE_START).format(
            "M/D, h:mm A"
          )})`}
        </div>
      );
    });
  }

  fetchTypeString(type) {
    return type.substring(0, 1) + type.substring(1).toLowerCase();
  }

  renderTips(summary, tipAmount) {
    let toReturn = Object.keys(summary.TIP_SUMMARY).map((key) => {
      const {TICKET, BUCKET, AMOUNT} = summary.TIP_SUMMARY[key];

      if (TICKET && AMOUNT) {
        return this.renderTicketTips(TICKET, AMOUNT);
      } else if (BUCKET && AMOUNT) {
        return this.renderBucketTips(BUCKET, AMOUNT);
      }
    });

    toReturn.push(
      <div
        className={
          "mt-1 flex flex-row justify-between ml-9 text-gray-800 border-t"
        }
      >
        <div>Total Tips</div>

        <div>{this.renderDollarString(tipAmount)}</div>
      </div>
    );

    return toReturn;
  }

  renderBucketTips(bucket, amount) {
    const bucketDate = moment(bucket.DATE_CREATED).format("M/D h:mm A");

    return (
      <div className={"mt-1 flex flex-row justify-between ml-9 text-gray-800"}>
        <div className={"flex flex-row justify-start items-center"}>
          <div className="text-indigo-500">{`Tip Bucket`}</div>

          <div className="ml-2">{`(${bucketDate})`}</div>
        </div>

        <div>{`${toDollars(amount, true)}`}</div>
      </div>
    );
  }

  renderTicketTips(ticket, amount) {
    const ticketDate = moment(getTicketTransactionDate(ticket)).format(
      "M/D, h:mm A"
    );

    return (
      <div className={"mt-1 flex flex-row justify-between ml-9 text-gray-800"}>
        <div
          className={
            "flex flex-row justify-start items-center text-indigo-500 cursor-pointer"
          }
          onClick={() =>
            window.open(
              `${window.location.origin}/ticket/${ticket.UNIQUE_ID}`,
              "_blank"
            )
          }
        >
          <div>{`${
            ticket.ID ? "Ticket #" + ticket.TICKET_NUMBER : "Offline Ticket"
          }`}</div>

          <FontAwesomeIcon
            className="ml-2 h-3 w-3"
            icon={"external-link"}
            color={"indigo-500"}
            light
          />

          <div className="ml-2 text-gray-800">{`(${ticketDate})`}</div>
        </div>

        <div>{`${toDollars(amount, true)}`}</div>
      </div>
    );
  }

  renderCard({label, amount, stateAttribute, expandableComponent}) {
    return (
      <ExpandableListComponent
        header={
          <div className="flex flex-row justify-between">
            <div className="mr-1">{label}</div>
          </div>
        }
        data={expandableComponent}
      />
    );
  }

  renderBetaBadge() {
    return (
      <div className="text-gray-700 text-md">
        Tip Payout Summary
        <GrayBadge className="ml-1">Beta</GrayBadge>
      </div>
    );
  }

  render() {
    const {summary, loading, error} = this.state;
    const {REQUIRE_TIME_CARD_BLESSINGS} = this.props.shop.settings;
    let creditDebit;

    const summaryCards = this.formatSummary(summary);

    if (summaryCards) {
      const currentTipTotalFromTimesheet = summaryCards.reduce(
        (total, item) => total + item.amount,
        0
      );
      creditDebit = currentTipTotalFromTimesheet - summary.TOTAL;
    }

    return (
      <Modal
        buttonOnClick={() => this.close()}
        label={this.renderBetaBadge()}
        description=""
        ref={(e) => (this.modal = e)}
        large
      >
        {error ? (
          this.renderError()
        ) : (
          <div>
            {(loading || !summary) && this.renderLoading()}

            {!loading && !!summary && (
              <div className={"space-y-2"}>
                {summaryCards.map((card) => this.renderCard(card))}

                {REQUIRE_TIME_CARD_BLESSINGS === "1" &&
                  summary.CREDIT_DEBIT_SUMMARY && (
                    <div className={"flex flex-row justify-between"}>
                      <div>{`Total Paid (${moment(
                        summary.CREDIT_DEBIT_SUMMARY.TIMESHEET.DATE_BLESSED
                      ).format("M/D, h:mm A")})`}</div>
                      <div>{this.renderDollarString(summary.TOTAL)}</div>
                    </div>
                  )}

                {REQUIRE_TIME_CARD_BLESSINGS === "1" &&
                  !isNaN(creditDebit) &&
                  creditDebit !== 0 && (
                    <div className="text-gray-500 italic">
                      {`$${toDollars(Math.abs(creditDebit))} will be ${
                        creditDebit > 0 ? "added to" : "subtracted from"
                      } the next tip payout`}
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(TipBreakdownModal));
