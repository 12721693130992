import React, {Component} from "react";
import {
  Button,
  Card,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {showSuccessNotification} from "../../utils/notification-helper";

class VoidTab extends Component {
  state = {
    payrollItemId: "",
    loaded: false,
    amountMinutes: 0,
    fullName: "",
  };

  async loadTimesheetStats() {
    let {payrollItemId} = this.state;

    let {AMOUNT_MINUTES, FULL_NAME} = await request(
      `payroll/void/${payrollItemId}`,
      "GET"
    );

    this.setState({
      loaded: true,
      amountMinutes: AMOUNT_MINUTES,
      fullName: FULL_NAME,
    });
  }

  async voidTimesheets() {
    let {payrollItemId} = this.state;

    await request(
      `payroll/void/${payrollItemId}`,
      "POST"
    );

    this.setState({loaded: false, payrollItemId: ""});

    showSuccessNotification(
      "Timesheets Voided",
      "Timeshees have been successfully voided"
    );
  }

  render() {
    const {loaded, amountMinutes, fullName, payrollItemId} = this.state;

    return (
      <div>
        <Card>
          <div className={"p-6 flex-col flex w-72 space-y-3"}>
            <FormInput
              label={"Check Payroll Item ID"}
              value={payrollItemId}
              onChange={(e) => {
                this.setState({payrollItemId: e.target.value, loaded: false});
              }}
            />

            {!loaded && (
              <Button
                color="primary"
                label={"Load"}
                onClick={() => this.loadTimesheetStats()}
              />
            )}

            {loaded && (
              <div className={"font-semibold"}>
                {fullName} has {(amountMinutes / 60).toFixed(2)} hours of
                voidable timesheets
              </div>
            )}
            {loaded && (
              <Button
                type="error"
                label={"Void"}
                onClick={() => this.voidTimesheets()}
              />
            )}
          </div>
        </Card>
      </div>
    );
  }
}

  export
  default
  VoidTab;
