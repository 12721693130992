import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormTextArea,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import {showErrorAlert} from "../../../utils/alert-helper";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TaxRateDropdown from "../../../dropdowns/sales/tax-rate-dropdown";
import {
  ECOMMERCE_ORDER_STATUES,
  ECOMMERCE_ORDER_STATUES_LABEL_MAP,
} from "../../../utils/constants";

class EcommerceStatusModal extends Component {
  state = {tickets: null};

  open(tickets = null) {
    this.setState({tickets}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async setEcommerceStatus({status}) {
    const {tickets} = this.state;

    await request("ecommerce/orders/bulk/status", "PATCH", {
      TICKET_UNIQUE_ID_ARRAY: tickets,
      ORDER_STATUS: status,
    });

    this.props.updateState(status);
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel={"Update"}
        label={"Update Order Status"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize
          onSubmit={this.setEcommerceStatus.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            status: "RECEIVED",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="status"
                  label="Status"
                  options={formikOptions}
                  data={Object.values(ECOMMERCE_ORDER_STATUES).map((item) => {
                    return {
                      label: ECOMMERCE_ORDER_STATUES_LABEL_MAP[item],
                      value: item,
                    };
                  })}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EcommerceStatusModal;
