import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class SuccessBadge extends Component {
  render() {
    return (
      <span {...this.props} className={classNames(this.props.className, "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800")}>
        {this.props.children}
      </span>
    );
  }
}

export default SuccessBadge;
