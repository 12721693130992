import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";

class CreateBankTemplateModal extends Component {
  createTemplate(values) {
    const {name} = values;

    request("storebanks/templates/", "POST", {NAME: name}).then(() => {
      this.modal.close();
      this.props.updateTemplates();
    });
  }

  open() {
    this.modal.open();
  }

  render() {
    return (
      <Modal
        label={"Create Bank Template"}
        buttonLabel={"Create"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={(values) => this.createTemplate(values)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name={"name"}
                  label={"Template Name"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CreateBankTemplateModal;
