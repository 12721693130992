import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";

class AdvancedLoyaltyModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {onePer} = values;

    let payload = {
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET: onePer,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    const {PARTNER_SETTINGS: settings} = await request(
      "settings/shop/all",
      "GET"
    );

    this.props.updateShop({settings});
    this.props.onSubmit && this.props.onSubmit();
    this.modal.close();
  };

  render() {
    let {ADVANCED_LOYALTY_ONE_RULE_PER_TICKET} = this.props.shop.settings;
    let {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE
      ? LOYALTY_POINTS_VERBIAGE
      : "points";

    return (
      <Modal
        label="Loyalty Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            onePer: ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="onePer"
                  label="One Earn Rule Per Payment"
                  hint="Company Setting"
                  options={formikOptions}
                  tooltip={{
                    label: "One Rule Per Payment",
                    data: `If enabled, only one earn rule will be applied per payment. The rule that generates the maximum amount of ${terminology} will be applied. Otherwise, the patron will instead earn the sum of the ${terminology} for each satisfied earn rule.`,
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  AdvancedLoyaltyModal
);
