import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";
import {withRouter} from "../../../utils/navigation";

class CouponsTable extends Component {
  render() {
    const {coupons} = this.props;

    return (
      <Table
        {...this.props}
        pagination
        data={coupons}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => {
              this.props.router.navigate("/coupon/" + row.UNIQUE_ID);
            },
          },
        ]}
        columns={[
          {
            value: "NAME",
            label: "Promotion Name",
            format: (_, coupon) => coupon?.PROMOTION?.NAME,
          },
          {
            value: "CODE",
            label: "Code",
          },
          {
            width: 1,
            value: "DATE_CREATED",
            label: "Created",
            format: (value, row) => {
              return moment(value).format("MM/DD/YY");
            },
          },
        ]}
      />
    );
  }
}

CouponsTable.propTypes = {
  coupons: PropTypes.array.isRequired,
};

export default withRouter(CouponsTable);
