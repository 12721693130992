import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class ShippingBoxModal extends Component {
  state = {box: null};

  open(box = null) {
    this.setState({box}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createBox({name, length, width, height}) {
    const boxPayload = {
      NAME: name,
      LENGTH: length,
      WIDTH: width,
      HEIGHT: height,
    };

    const serverBox = await request(
      "ecommerce/shippingboxes",
      "POST",
      boxPayload
    );

    this.props.addState(serverBox);
    this.modal.close();
  }

  async saveBox({name, length, width, height}) {
    const {box} = this.state;
    const boxPayload = {
      NAME: name,
      LENGTH: length,
      WIDTH: width,
      HEIGHT: height,
    };

    const serverBox = await request(
      "ecommerce/shippingboxes/" + box.ID,
      "POST",
      boxPayload
    );

    this.props.updateState(serverBox.ID, serverBox);
    this.modal.close();
  }

  async removeBox() {
    const {box} = this.state;

    let serverPrize = await request(
      "ecommerce/shippingboxes/" + box.ID,
      "DELETE",
      {}
    );

    this.props.updateState(box.ID);
    this.modal.close();
  }

  render() {
    const {box} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={box ? "Save" : "Add"}
        label={box ? "Edit Box" : "Create Box"}
        deleteOnClick={() => this.removeBox()}
        formikOnClick={() => this.formikRef}
        deleteLabel={box && "Delete"}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: box?.NAME ?? "",
            length: box?.LENGTH ?? "",
            width: box?.WIDTH ?? "",
            height: box?.HEIGHT ?? "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            length: Yup.string().required("Length is required"),
            width: Yup.string().required("Width is required"),
            height: Yup.string().required("Height is required"),
          })}
          onSubmit={box ? this.saveBox.bind(this) : this.createBox.bind(this)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="name"
                  options={formikOptions}
                  label="Name"
                  placeholder="Large Box"
                />

                <FormInput
                  name="width"
                  options={formikOptions}
                  label="Width in Inches"
                  placeholder="4.5"
                />

                <FormInput
                  name="height"
                  options={formikOptions}
                  label="Height in Inches"
                  placeholder="11.5"
                />

                <FormInput
                  name="length"
                  options={formikOptions}
                  label="Length in Inches"
                  placeholder="1.5"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(ShippingBoxModal);
