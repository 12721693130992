import React, {Component} from "react";
import {
  classNames,
  minutesToHours,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import AdditionalTipsModal from "../../../modals/payroll/earnings/additional-tips-modal";
import SupplementalModal, {
  SUPPLEMENTAL_TYPES,
} from "../../../modals/payroll/earnings/supplemental-modal";
import ReimbursementModal from "../../../modals/payroll/earnings/reimbursement-modal";
import TimeCardSummaryExpansionComponent from "../../reporting/reports/time-card-summary-expansion-component";
import {Table, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {STATE_OVERTIME_RULES} from "../../reporting/reports/reporting-constants";
import {SearchIcon} from "@heroicons/react/solid";
import RadioDropdown from "../../../components/form-elements/radio-dropdown";
import Fuse from "fuse.js";
import ExtraPayModal, {
  PAY_TYPES,
} from "../../../modals/payroll/earnings/extra-pay-modal";

class PayrollRunTable extends Component {
  state = {search: "", selectedLocation: "all", selectedEmployees: "with-pay"};

  getBreakdownColumns() {
    let {
      checkPayrollId,
      extras,
      hideEarned,
      prefixColumns = [],
      hideExtraAdditions,
      dbaLocations,
    } = this.props;

    let {PAYROLL_PAY_CALCULATED_TIPS, RUN_PAYROLL_SHOW_COMMISSION} =
      this.props.shop.settings;

    let grossPayTitle = hideEarned ? "Reimbursements" : "Gross Pay";

    let DAILY_DOUBLE =
      STATE_OVERTIME_RULES[this.props.shop.location.STATE].DAILY_DOUBLE;

    let columns = [
      {
        label: "Employee Name",
        value: "NAME",
        format: (value, row) => (
          <div className="text-gray-800">{row.FULL_NAME ?? "Total"}</div>
        ),
        sortable: true,
        sortFunction: (a, b) => {
          if (!a?.FULL_NAME || b.FULL_NAME) {
            return 0;
          }
          return a?.FULL_NAME > b?.FULL_NAME ? -1 : 1;
        },
      },
    ];

    let timeWorked = {
      label: "Regular",
      value: "AMOUNT_MINUTES",
      format: (value, row) => {
        if (row.TYPE === "SAL") {
          return "Salary";
        }

        if (row.PAY_TYPE === "OTHER") {
          let regularHoursTotal = extras[row.ID]?.REGULAR_HOURS_TOTAL;
          let regularAmountTotal = extras[row.ID]?.REGULAR_AMOUNT_TOTAL;

          return this.renderExtraPay(
            row,
            checkPayrollId,
            PAY_TYPES.REGULAR.VALUE,
            regularHoursTotal,
            regularAmountTotal
          );
        }

        return (
          <div className="text-left text-gray-800">
            <div className="text-medium">
              {minutesToHours(row?.PAY_SUMMARY?.AMOUNT_REGULAR_MINUTES)} hrs
            </div>

            <div className="text-gray-500">
              {toDollars(row?.PAY_SUMMARY?.AMOUNT_REGULAR_GROSS, true)}
            </div>
          </div>
        );
      },
    };

    let overtimeWorkedArr = [
      {
        label: "Overtime",
        value: "AMOUNT_MINUTES",
        format: (value, row) => {
          if (row.TYPE === "SAL") {
            return "";
          }

          if (row.PAY_TYPE === "OTHER") {
            let overtimeHoursTotal = extras[row.ID]?.OVERTIME_HOURS_TOTAL;
            let overtimeAmountTotal = extras[row.ID]?.OVERTIME_AMOUNT_TOTAL;

            return this.renderExtraPay(
              row,
              checkPayrollId,
              PAY_TYPES.OVERTIME.VALUE,
              overtimeHoursTotal,
              overtimeAmountTotal
            );
          }

          let minutes = row.PAY_SUMMARY?.AMOUNT_OVERTIME_MINUTES ?? 0;

          return (
            <div
              className={classNames("text-left", !minutes && "text-gray-300")}
            >
              <div
                className={classNames(
                  "text-medium",
                  minutes && "text-gray-800"
                )}
              >
                {minutesToHours(minutes)} hrs
              </div>

              <div className={classNames(minutes && "text-gray-500")}>
                {toDollars(row?.PAY_SUMMARY?.AMOUNT_OVERTIME_GROSS, true)}
              </div>
            </div>
          );
        },
      },
    ];

    if (DAILY_DOUBLE) {
      overtimeWorkedArr.push({
        label: "Double Overtime",
        value: "AMOUNT_MINUTES",
        format: (value, row) => {
          if (row.TYPE === "SAL") {
            return "";
          }

          let minutes = row.PAY_SUMMARY?.AMOUNT_DOUBLE_OVERTIME_MINUTES ?? 0;

          if (row.PAY_TYPE === "OTHER") {
            let doubleOvertimeHoursTotal =
              extras[row.ID]?.DOUBLE_OVERTIME_HOURS_TOTAL;
            let doubleOvertimeAmountTotal =
              extras[row.ID]?.DOUBLE_OVERTIME_AMOUNT_TOTAL;

            return this.renderExtraPay(
              row,
              checkPayrollId,
              PAY_TYPES.DOUBLE_OVERTIME.VALUE,
              doubleOvertimeHoursTotal,
              doubleOvertimeAmountTotal
            );
          }

          return (
            <div
              className={classNames("text-left", !minutes && "text-gray-300")}
            >
              <div
                className={classNames(
                  "text-medium",
                  minutes && "text-gray-800"
                )}
              >
                {minutesToHours(minutes)} hrs
              </div>

              <div className={classNames(minutes && "text-gray-500")}>
                {toDollars(
                  row?.PAY_SUMMARY?.AMOUNT_DOUBLE_OVERTIME_GROSS,
                  true
                )}
              </div>
            </div>
          );
        },
      });
    }

    let ptoHours = {
      label: "PTO Hours",
      value: "PTO_MINUTES",
      format: (value, row) => {
        let ptoHours = row?.PAY_SUMMARY?.AMOUNT_PTO_HOURS;
        return (
          <div className={classNames(!ptoHours && "text-gray-300")}>
            {row?.PAY_SUMMARY?.AMOUNT_PTO_HOURS ?? 0}
          </div>
        );
      },
    };

    let payEarned = {
      label: "Total",
      value: "AMOUNT_PAYED",
      format: (value, row) => {
        if (row.TYPE === "SAL") {
          return `${toDollars(row.RATE, true)}/yr`;
        }

        let regularHoursTotal = extras[row.ID]?.REGULAR_HOURS_TOTAL ?? 0;
        let regularAmountTotal = extras[row.ID]?.REGULAR_AMOUNT_TOTAL ?? 0;

        return (
          <div className="text-left text-gray-800 font-medium">
            <div className="text-medium">
              {minutesToHours(
                row?.PAY_SUMMARY?.AMOUNT_MINUTES ?? 0 + regularHoursTotal * 60
              )}{" "}
              hrs
            </div>

            <div className="text-gray-500">
              {toDollars(
                row?.PAY_SUMMARY?.AMOUNT_STANDARD_PAY ??
                  0 + regularAmountTotal ??
                  0,
                true
              )}
            </div>
          </div>
        );
      },
    };

    let tips = {
      width: 1,
      label: "Tips",
      value: "AMOUNT_TIPS",
      format: (value, row) => {
        if (!row.ACCOUNT) {
          return (
            <div className="text-gray-800">
              {toDollars(extras["TOTALS"].AMOUNT_TIPS, true)}
            </div>
          );
        }

        return (
          <div>
            {PAYROLL_PAY_CALCULATED_TIPS === "1" && !hideEarned && (
              <div className="text-gray-800" style={{marginBottom: 3}}>
                {toDollars(row?.PAY_SUMMARY?.AMOUNT_TIPS ?? 0, true)}
              </div>
            )}

            {extras[row.ID]?.ADDITIONAL_TIPS?.map((additionalTip) => {
              let type = additionalTip.TYPE === "paycheck_tips" ? "PT" : "CT";
              return (
                <div
                  className="text-indigo-500 font-semibold cursor-pointer"
                  onClick={() =>
                    this.additionalTipsModal.open(
                      checkPayrollId,
                      row.PAYROLL.id,
                      row.LOCATION_ID,
                      row.FULL_NAME,
                      additionalTip
                    )
                  }
                >
                  {`${type}: ${toDollars(additionalTip.AMOUNT, true)}`}
                </div>
              );
            })}

            {!hideExtraAdditions && (
              <span
                className="text-indigo-500 font-semibold cursor-pointer"
                onClick={() => {
                  this.additionalTipsModal.openNew(
                    checkPayrollId,
                    row.PAYROLL.id,
                    row.LOCATION_ID,
                    row.FULL_NAME
                  );
                }}
              >
                + Additional Tips
              </span>
            )}
          </div>
        );
      },
    };

    let grossPay = {
      width: 1,
      label: grossPayTitle,
      value: "GROSS_PAY",
      format: (value, row) => {
        grossPay = row?.PAY_SUMMARY?.AMOUNT_TOTAL_GROSS ?? 0;

        let extrasGrossPay = extras[row.ID]?.AMOUNT_GROSS_PAY ?? 0;

        return (
          <div>
            {row.ACCOUNT && !hideEarned && (
              <div className="text-gray-800 font-medium">
                {toDollars(grossPay + extrasGrossPay, true)}
              </div>
            )}

            {extras[row.ID]?.REIMBURSEMENTS.map((reimbursement) => {
              return (
                <span
                  className="block text-indigo-500 font-semibold cursor-pointer"
                  onClick={() =>
                    this.reimbursementModal.open(
                      checkPayrollId,
                      row.PAYROLL.id,
                      row.FULL_NAME,
                      reimbursement
                    )
                  }
                >
                  R: {toDollars(reimbursement.AMOUNT, true)}
                </span>
              );
            })}

            {!hideExtraAdditions && (
              <span
                className="block text-indigo-500 font-semibold cursor-pointer"
                onClick={() =>
                  this.reimbursementModal.openNew(
                    checkPayrollId,
                    row.PAYROLL.id,
                    row.FULL_NAME
                  )
                }
              >
                {"+ Reimbursement"}
              </span>
            )}

            <div className="my-1">
              Pay{" "}
              {row?.PAYROLL.payment_method_preference === "direct_deposit" &&
              !row.IS_MANUAL
                ? "by Direct Deposit"
                : "Manually"}
            </div>
          </div>
        );
      },
    };

    let bonus = {
      width: 1,
      label: "Supplemental",
      value: "BONUS",
      format: (value, row) => {
        let bonuses = extras[row.ID]?.BONUSES;
        let commissions = extras[row.ID]?.COMMISSIONS;

        return (
          <div className="space-y-1 flex flex-col">
            {bonuses?.length > 0 && (
              <span
                className="text-indigo-500 font-semibold cursor-pointer"
                onClick={() =>
                  this.supplementalModal.open(
                    checkPayrollId,
                    row.PAYROLL.id,
                    row.FULL_NAME,
                    bonuses[0],
                    SUPPLEMENTAL_TYPES.BONUS.value
                  )
                }
              >
                B: {toDollars(bonuses[0].AMOUNT, true)}
              </span>
            )}

            {!bonuses?.length && !hideExtraAdditions && (
              <span
                className="text-indigo-500 font-semibold cursor-pointer"
                onClick={() =>
                  this.supplementalModal.openNew(
                    checkPayrollId,
                    row.PAYROLL.id,
                    row.FULL_NAME,
                    SUPPLEMENTAL_TYPES.BONUS.value
                  )
                }
              >
                {"+ Bonus"}
              </span>
            )}

            {commissions?.length > 0 && (
              <span
                className="text-indigo-500 font-semibold cursor-pointer"
                onClick={() =>
                  this.supplementalModal.open(
                    checkPayrollId,
                    row.PAYROLL.id,
                    row.FULL_NAME,
                    commissions[0],
                    SUPPLEMENTAL_TYPES.COMMISSION.value
                  )
                }
              >
                C: {toDollars(commissions[0].AMOUNT, true)}
              </span>
            )}

            {RUN_PAYROLL_SHOW_COMMISSION === "1" &&
              !commissions?.length &&
              !hideExtraAdditions && (
                <span
                  className="text-indigo-500 font-semibold cursor-pointer"
                  onClick={() =>
                    this.supplementalModal.openNew(
                      checkPayrollId,
                      row.PAYROLL.id,
                      row.FULL_NAME,
                      SUPPLEMENTAL_TYPES.COMMISSION.value
                    )
                  }
                >
                  {"+ Commission"}
                </span>
              )}
          </div>
        );
      },
    };

    if (hideEarned) {
      return [...prefixColumns, ...columns, bonus, grossPay];
    }

    if (hideExtraAdditions) {
      return [
        ...prefixColumns,
        ...columns,
        timeWorked,
        ...overtimeWorkedArr,
        ptoHours,
        payEarned,
        tips,
        grossPay,
      ];
    }

    return [
      ...prefixColumns,
      ...columns,
      timeWorked,
      ...overtimeWorkedArr,
      ptoHours,
      payEarned,
      tips,
      bonus,
      grossPay,
    ];
  }

  renderExtraPay(row, checkPayrollId, type, hours, amount) {
    let {extras} = this.props;

    if (amount > 0) {
      return (
        <div
          className="text-left text-indigo-500 cursor-pointer font-medium"
          onClick={() => {
            let existingEntry = extras[row.ID][type][0];

            this.extraPayModal.open(
              checkPayrollId,
              row.PAYROLL.id,
              row.LOCATION_ID,
              type,
              row.FULL_NAME,
              existingEntry
            );
          }}
        >
          <div className="text-medium">{hours} hrs</div>

          <div className="text-indigo-500 cursor-pointer">
            {toDollars(amount, true)}
          </div>
        </div>
      );
    }

    return (
      <span
        className="text-indigo-500 font-semibold cursor-pointer"
        onClick={() => {
          this.extraPayModal.openNew(
            checkPayrollId,
            row.PAYROLL.id,
            row.LOCATION_ID,
            type,
            row.FULL_NAME
          );
        }}
      >
        + Add
      </span>
    );
  }

  renderTabs() {
    let {dbaLocations, hideHeader, hideEmployeeSelector} = this.props;

    let tabs = dbaLocations
      .filter((loc) => loc.TYPE === "STORE")
      .map((loc) => ({
        label: loc.NAME,
        value: loc.ID,
      }));

    tabs.unshift({
      label: "All Locations",
      value: "all",
    });

    return (
      <div>
        <div className="mb-4 z-0">
          {!hideHeader && (
            <div className="flex flex-row rounded-md space-x-4 align-bottom pt-3">
              <div className="relative w-64 bottom-0">
                <div className="absolute top-2.5 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder={"Search for name"}
                  onChange={(e) => this.setState({search: e.target.value})}
                />
              </div>

              {dbaLocations?.length > 1 && (
                <div className="align-bottom">
                  <RadioDropdown
                    defaultValue={"all"}
                    data={tabs}
                    onChange={(val) => {
                      this.setState({selectedLocation: val});
                    }}
                  />
                </div>
              )}

              {!hideEmployeeSelector && (
                <RadioDropdown
                  className={"-mt-5"}
                  label={"Employees"}
                  defaultValue={"with-pay"}
                  data={[
                    {label: "All Active", value: "all"},
                    {label: "With Pay", value: "with-pay"},
                  ]}
                  onChange={(val) => {
                    this.setState({selectedEmployees: val});
                  }}
                />
              )}
            </div>
          )}
        </div>

        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    let {search, selectedLocation, selectedEmployees} = this.state;
    let {breakdown, expandSummary, extras, hideEmployeeSelector, breakOptions} =
      this.props;

    let expandable = false;
    if (expandSummary) {
      expandable = (row) => (
        <TimeCardSummaryExpansionComponent
          row={row}
          breakOptions={breakOptions}
        />
      );
    }

    if (search.length > 0) {
      breakdown = new Fuse(breakdown, {
        keys: ["FULL_NAME"],
        useExtendedSearch: true,
        threshold: 0.1,
      })
        .search(search)
        .map(({item}) => item);
    }

    if (selectedLocation !== "all") {
      breakdown = breakdown.filter(
        (breakdownObj) => breakdownObj.LOCATION_ID === selectedLocation
      );
    }

    if (selectedEmployees !== "all" && !hideEmployeeSelector) {
      breakdown = breakdown.filter(
        (breakdownObj) =>
          breakdownObj?.PAY_SUMMARY?.AMOUNT_TOTAL_GROSS ??
          0 + extras[breakdownObj.ID]?.AMOUNT_GROSS_PAY ??
          0 > 0
      );
    }

    return (
      <Table
        data={breakdown}
        columns={this.getBreakdownColumns()}
        expandable={expandable}
      />
    );
  }

  render() {
    let {refreshExtras, dbaLocations} = this.props;

    return (
      <div>
        <AdditionalTipsModal
          ref={(e) => (this.additionalTipsModal = e)}
          refresh={refreshExtras}
          dbaLocations={dbaLocations}
        />

        <SupplementalModal
          ref={(e) => (this.supplementalModal = e)}
          refresh={refreshExtras}
          dbaLocations={dbaLocations}
        />

        <ReimbursementModal
          ref={(e) => (this.reimbursementModal = e)}
          refresh={refreshExtras}
          dbaLocations={dbaLocations}
        />

        <ExtraPayModal
          ref={(e) => (this.extraPayModal = e)}
          refresh={refreshExtras}
          dbaLocations={dbaLocations}
        />

        {this.renderTabs()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "payroll"])(PayrollRunTable);
