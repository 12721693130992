import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {PERMISSIONS} from "../../utils/constants";
import {
  faCandyBar,
  faMoneyFromBracket,
  faPlateUtensils,
  faWallet,
  faWheatSlash,
} from "@fortawesome/pro-light-svg-icons";

export const SALES_ROUTES = [
  {
    live: true,
    id: "menu",
    name: "Menu",
    href: "/menu",
    permissions: [PERMISSIONS.SALES.MENU_FULL.id],
    children: [
      {
        live: true,
        icon: faPlateUtensils,
        searchable: true,
        name: "Products",
        href: "/products",
        permissions: [],
        paths: ["/products", "/product"],
      },
      {
        live: true,
        icon: faCandyBar,
        searchable: true,
        name: "Categories",
        href: "/categories",
        permissions: [],
        paths: ["/categories", "/category"],
      },
      {
        live: true,
        icon: faWheatSlash,
        searchable: true,
        name: "Modifiers",
        permissions: [],
        href: "/modifiers",
      },
      {
        live: true,
        icon: faMoneyFromBracket,
        searchable: true,
        name: "Tax Rates",
        permissions: [],
        href: "/taxes",
        paths: ["/taxes", "/tax"],
      },
      {
        live: true,
        id: "discounts",
        href: "/discounts",
        name: "Discounts",
        paths: ["/discounts", "/discount"],
        permissions: [PERMISSIONS.SALES.DISCOUNTS_FULL.id],
      },
      {
        live: true,
        icon: faWallet,
        searchable: true,
        name: "Service Fees",
        permissions: [],
        href: "/fees",
        paths: ["/fees", "/fee"],
      },
    ],
  },
  {
    live: true,
    id: "tickets",
    href: "/tickets",
    name: "Tickets",
    permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
    paths: ["/tickets", "/ticket"],
    children: [
      {
        live: true,
        name: "Scheduled",
        permissions: [],
        href: "/scheduled",
      },
      {
        live: true,
        name: "Checks",
        permissions: [],
        href: "/checks",
      },
    ],
  },
  {
    live: true,
    id: "flow",
    name: "Checkout Flow",
    permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
    children: [
      {
        live: true,
        searchable: true,
        name: "Ticket Types",
        href: "/flow/tickets",
        paths: ["/flow/tickets", "/flow/ticket"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Payment Types",
        href: "/flow/payments",
        paths: ["/flow/payments", "/flow/payment"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Receipts",
        href: "/receipts",
        paths: ["/receipts", "/receipt"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Checkout Prompts",
        href: "/prompts",
        paths: ["/prompts", "/prompt"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Gratuity",
        href: "/gratuity",
        paths: ["/gratuity"],
        permissions: [],
      },
    ],
  },
  {
    live: true,
    id: "tags",
    href: "/tags",
    name: "Tags",
    paths: ["/tags", "/tag"],
    permissions: [PERMISSIONS.SALES.TAGS_FULL.id],
  },
  {
    live: true,
    id: "third",
    href: "/third",
    name: "Third Party Delivery",
    permissions: [PERMISSIONS.SALES.MENU_FULL.id],
  },
  {
    live: true,
    id: "tables",
    name: "Table Service",
    permissions: [PERMISSIONS.SALES.TABLE_FULL.id],
    children: [
      {
        live: true,
        searchable: true,
        name: "Tables",
        href: "/tables",
        paths: ["/tables"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Revenue Centers",
        href: "/centers",
        paths: ["/centers", "/center"],
        permissions: [],
      },
    ],
  },
  {
    live: true,
    badge: "Beta",
    id: "ecommerce",
    name: "Ecommerce",
    permissions: [PERMISSIONS.SALES.ECOMMERCE_FULL.id],
    children: [
      {
        live: true,
        searchable: true,
        name: "Orders",
        href: "/orders",
        paths: ["/orders", "/order"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Boxes",
        href: "/boxes",
        paths: ["/boxes", "/box"],
        permissions: [],
      },
      {
        live: true,
        searchable: true,
        name: "Addresses",
        href: "/addresses",
        paths: ["/addresses"],
        permissions: [],
      },
    ],
  },
];

class SalesSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title="Sales"
              permissions={[PERMISSIONS.SALES.FULL.id]}
              navigation={SALES_ROUTES}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default SalesSideNav;
