import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";

class InvoiceEmailModal extends Component {
  open() {
    this.modal.open();
  }

  createEmail({email}) {
    request("invoices/email", "POST", {EMAIL: email}).then(async () => {
      await this.props.syncState();
      this.modal.close();
    });
  }

  render() {
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel="Add"
        formikOnClick={() => this.formikRef}
        label="Add Email"
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{email: null}}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(),
          })}
          onSubmit={(values) => {
            return this.createEmail(values);
          }}
        >
          {(formikOptions) => {
            return (
              <form>
                <FormInput name="email" label="Email" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default InvoiceEmailModal;
