/** Buttons **/
import Button from "./components/buttons/button";
import ButtonRow from "./components/buttons/button-row";
import ButtonGroup from "./components/buttons/button-group";
import ButtonList from "./components/buttons/button-list";

export {Button, ButtonRow, ButtonGroup, ButtonList};

/** Cards **/
import Card from "./components/card/card";
import Cards from "./components/card/cards";
import DescriptionList from "./components/card/description-list";
import TwoColumnList from "./components/card/two-column-list";

export {Card, Cards, DescriptionList, TwoColumnList};

/** Filters **/
import AdvancedFilter from "./components/filters/advanced-filter";
import Filter from "./components/filters/filter";

export {AdvancedFilter, Filter};

/** Forms **/
import FormTextArea from "./components/form/form-text-area";
import FormElement from "./components/form/form-element";
import FormToggle from "./components/form/form-toggle";
import FormRadio from "./components/form/form-radio";
import FormCheck from "./components/form/form-check";

export {FormTextArea, FormElement, FormRadio, FormCheck, FormToggle};

/** Headings **/
import PageHeadings from "./components/headings/page-headings";
import SectionHeading from "./components/headings/section-heading";

export {PageHeadings, SectionHeading};

/** Inputs **/
import FormCurrency from "./components/input/form-currency";
import FormDate from "./components/input/form-date";
import FormEmail from "./components/input/form-email";
import FormInput from "./components/input/form-input";
import FormName from "./components/input/form-name";
import FormPhone from "./components/input/form-phone";
import FormToken from "./components/input/form-token";

export {
  FormCurrency,
  FormDate,
  FormEmail,
  FormInput,
  FormName,
  FormPhone,
  FormToken,
};

/** Modals **/
import Modal from "./components/modals/modal";
import SlideOver from "./components/modals/slide-over";

export {Modal, SlideOver};

/** Selects **/
import FormSelect from "./components/select/form-select";
import FormBoolean from "./components/select/form-boolean";

export {FormSelect, FormBoolean};

/** Tables **/
import Table from "./components/table/table";

export {Table};

/** Dropdowns **/
import Dropdown from "./components/dropdown/dropdown";
import ComboBox from "./components/dropdown/combobox";

export {Dropdown, ComboBox};

import EmptyBox from "./components/empty/empty-box";

export {EmptyBox};

/** Uncategorized **/
import Attachments from "./components/attachments";
import Breadcrumbs from "./components/breadcrumbs";
import Tooltip from "./components/tooltip/tooltip";
import Loading from "./components/loading";
import Tab from "./components/tab";

export {Attachments, Breadcrumbs, Loading, Tab, Tooltip};

/** Component **/
import TailComponent from "./components/tail-component";

export {TailComponent};

export let REAL_HISTORY = [];

export function setupRealHistory(history) {
  REAL_HISTORY = history;
}
