import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  getCompanyDefinedAttributes,
  getEmployeeOnboardLink,
  remindPayrollEmployeeToOnboard,
  setEmployeeSettings,
} from "../../utils/payroll-helper";
import {CheckIcon} from "@heroicons/react/solid";
import LoadingSpinner from "../../components/loading-spinner";
import {ExclamationIcon} from "@heroicons/react/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DangerBadge from "../../components/badges/danger-badge";

class PayrollEmployeeSettingsModal extends Component {
  state = {
    isLoading: true,
    payrollEmployee: {},
    companyDefinedAttributes: null,
    emailSent: false,
  };

  open(payrollEmployee) {
    if (payrollEmployee.PAYROLL.id) {
      getCompanyDefinedAttributes(payrollEmployee.PAYROLL.id).then((r) =>
        this.setState({companyDefinedAttributes: r, isLoading: false})
      );

      this.setState(
        {
          payrollEmployee,
          companyDefinedAttributes: null,
          emailSent: false,
          isLoading: true,
        },
        () => this.slide.open()
      );
    } else {
      //Employee has not started, so there's no Check employee entry
      this.setState(
        {
          payrollEmployee,
          companyDefinedAttributes: [],
          emailSent: false,
          isLoading: false,
        },
        () => this.slide.open()
      );
    }
  }

  handleSubmit = async (values) => {
    let {ID} = this.state.payrollEmployee;

    let {include, payment} = values;

    let payload = {
      INCLUDE_IN_REGULAR: include,
      PREFERRED_PAYMENT_METHOD: payment,
    };

    delete values.include;
    delete values.payment;

    payload = {...payload, ...values};

    await setEmployeeSettings(ID, payload);
    await this.props.onSave();

    this.slide.close();
  };

  _convertCheckStep(step) {
    const finalStep = step.replace("_", " ");

    return (
      finalStep.charAt(0).toUpperCase() +
      finalStep.substring(1, finalStep.length)
    );
  }

  renderOnboardingStatus() {
    let {payrollEmployee} = this.state;

    const status = payrollEmployee?.PAYROLL?.onboard?.status;

    if (status === "completed") {
      return <div></div>;
    }

    return (
      <div className="text-sm font-medium text-gray-500 space-y-1">
        <div className="text-sm font-medium text-gray-700">
          Onboarding Status
        </div>

        <div className={"ml-3"}>
          {status === "not_started" && (
            <div className={"flex flex-row items-center"}>
              <DangerBadge className={"mr-2"}>
                <FontAwesomeIcon icon={"x"} className="h-3 w-3" />
              </DangerBadge>
              Not Started
            </div>
          )}

          {(status === "blocking" || status === "needs_attention") && (
            <div className="space-y-1">
              {payrollEmployee?.PAYROLL.onboard.remaining_steps.map((_step) => {
                return (
                  <div className={"flex flex-row items-center"}>
                    <DangerBadge className={"mr-2"}>
                      <FontAwesomeIcon icon={"x"} className="h-3 w-3" />
                    </DangerBadge>
                    {this._convertCheckStep(_step)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  getInitialCompanyDefined() {
    let companyDefined = this.state.companyDefinedAttributes;

    let toReturn = {};
    if (companyDefined) {
      for (let attribute of companyDefined) {
        let {name, value} = attribute;
        toReturn[name] = value;
      }
    }

    return toReturn;
  }

  //SECONDARY FORM INPUT LABEL
  renderCompanyDefinedAttribute(companyDefinedAttribute, formikOptions) {
    let {name, type, label, options} = companyDefinedAttribute;

    if (type === "boolean") {
      return (
        <FormSelect
          name={name}
          label={label}
          data={[
            {label: "Yes", value: "true"},
            {label: "No", value: "false"},
          ]}
          options={formikOptions}
        />
      );
    }

    if (type === "percent" || type === "string" || type === "number") {
      return <FormInput label={label} name={name} options={formikOptions} />;
    }

    if (type === "select") {
      return (
        <FormSelect
          label={label}
          name={name}
          options={formikOptions}
          data={options}
        />
      );
    }
  }

  render() {
    let {isLoading, payrollEmployee, emailSent, companyDefinedAttributes} =
      this.state;

    let include = payrollEmployee.INCLUDE_IN_REGULAR;
    let payment = payrollEmployee.PAYROLL?.payment_method_preference;
    let fullName = payrollEmployee.FULL_NAME;
    let firstName = fullName?.split(" ")[0];

    let isStarted = payrollEmployee?.PAYROLL?.onboard?.status !== "not_started";

    let employeeInfoMissing =
      payrollEmployee?.onboard?.status === "not_started" ||
      payrollEmployee?.onboard?.remaining_steps.filter(
        (step) => step !== "company_defined_attributes"
      ).length > 0;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`${fullName}'s Payroll Settings`}
        buttonLabel="Save"
        buttonOnClick={() => this.formikRef.submitForm()}
      >
        {isLoading && (
          <div className="py-10">
            <LoadingSpinner />
          </div>
        )}

        {!isLoading && (
          <div>
            <div className="mb-4">
              {employeeInfoMissing && (
                <div className="flex flex-row items-center align-middle justify-center text-sm font-semibold mb-2 text-gray-600">
                  <ExclamationIcon className="h-4 w-4 mr-1 " />

                  <div>{`${firstName} still has information to fill out!`}</div>
                </div>
              )}

              <div className="flex flex-row items-center justify-around">
                {!emailSent && (
                  <button
                    type="button"
                    className="w-36 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                    onClick={async () => {
                      this.setState({emailSent: true});
                      await remindPayrollEmployeeToOnboard(payrollEmployee.ID);
                    }}
                  >
                    <div>
                      <div>Send Payroll Link</div>

                      <div className="text-xs text-gray-500">via Email</div>
                    </div>
                  </button>
                )}

                {emailSent && (
                  <div className="flex flex-row h-full items-center">
                    <CheckIcon className={"h-4 w-4 items-center mr-1"} /> Sent
                  </div>
                )}

                <button
                  type="button"
                  className="w-36 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                  onClick={async () => {
                    await getEmployeeOnboardLink(payrollEmployee.ID).then(
                      (res) => {
                        window.open(res, "_blank").focus();
                      }
                    );
                  }}
                >
                  <div>Open Payroll Link</div>

                  <div className="text-xs text-gray-500">as {firstName}</div>
                </button>
              </div>
            </div>

            {this.renderOnboardingStatus()}

            <Formik
              enableReinitialize
              initialValues={{
                include,
                payment,
                ...this.getInitialCompanyDefined(),
              }}
              onSubmit={this.handleSubmit}
              innerRef={(e) => (this.formikRef = e)}
            >
              {(formikOptions) => {
                let {include} = formikOptions.values;

                return (
                  <div>
                    <div>
                      <div className="mb-5">
                        <FormSelect
                          label="Include in Regular Payruns"
                          name="include"
                          data={[
                            {label: "Yes", value: 1},
                            {label: "No", value: 0},
                          ]}
                          options={formikOptions}
                        />

                        {!!include && isStarted && (
                          <FormSelect
                            label="Default Payment Method"
                            name="payment"
                            data={[
                              {
                                label: "Direct Deposit",
                                value: "direct_deposit",
                              },
                              {label: "Manual", value: "manual"},
                            ]}
                            options={formikOptions}
                          />
                        )}
                      </div>

                      {companyDefinedAttributes &&
                        companyDefinedAttributes.filter(
                          (attribute) => !Boolean(attribute.value)
                        ).length > 0 && (
                          <div className="flex flex-col items-center text-red-600 text-sm font-semibold">
                            <div className="flex flex-row items-center">
                              <ExclamationIcon className="h-4 w-4 mr-1 text-red-600" />

                              <div className="">
                                Below fields must be completed
                              </div>
                            </div>
                            <div>for {firstName} to be paid</div>
                          </div>
                        )}

                      {companyDefinedAttributes?.map(
                        (companyDefinedAttribute) => {
                          return this.renderCompanyDefinedAttribute(
                            companyDefinedAttribute,
                            formikOptions
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </Modal>
    );
  }
}

export default PayrollEmployeeSettingsModal;
