import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import IngredientDropdown from "../../../dropdowns/operations/ingredient-dropdown";
import {showConfirmAlert} from "../../../utils/alert-helper";

class ModifierIngredientModal extends Component {
  state = {ingredient: null, seq: 1};

  open(ingredient = null, seq = 1) {
    this.setState({ingredient, seq}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async deleteModifierIngredient() {
    const {option} = this.props;
    const {ingredient} = this.state;

    if (!option.ID) {
      this.props.updateState(ingredient.ID);

      return this.modal.close();
    }

    showConfirmAlert(
      "Archive this Ingredient",
      "Are you sure you wanted to delete this product ingredient"
    ).then(async () => {
      await request("ingredients/modifier/" + ingredient.ID, "DELETE");

      this.props.updateState(ingredient.ID);
      return this.modal.close();
    });
  }

  async addModifierIngredient({ingredient, quantity, ingredientName, ingredientUnitName}) {
    const {modifier, option} = this.props;
    const {seq} = this.state;

    const modifierPayload = {
      OPTION_ID: option.ID,
      MODIFIER_ID: modifier.ID,
      INGREDIENT_ID: ingredient,
      QUANTITY: parseFloat(quantity),
      SEQ: seq + 1,

      INGREDIENT_UNIT_NAME: ingredientUnitName,
      INGREDIENT_NAME: ingredientName,
    };

    if (!option.ID) {
      this.props.addState(modifierPayload);

      return this.modal.close();
    }

    let serverCategory = await request(
      "ingredients/modifier/",
      "POST",
      modifierPayload
    );

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveModifierIngredient({quantity, enabled, ingredientName, ingredientUnitName}) {
    const {ingredient} = this.state;
    const {option} = this.props;

    let serverPaymentType = {
      INGREDIENT_UNIT_NAME: ingredientUnitName,
      INGREDIENT_NAME: ingredientName,
      QUANTITY: parseFloat(quantity),
      ENABLED: parseInt(enabled),
      SEQ: ingredient.SEQ,
    };

    if (!option.ID) {
      this.props.updateState(serverPaymentType.ID, {
        ...ingredient,
        ...serverPaymentType,
      });

      return this.modal.close();
    }

    let serverIngredient = await request(
      "ingredients/modifier/" + ingredient.ID,
      "PATCH",
      serverPaymentType
    );

    this.props.updateState(serverIngredient.ID, serverIngredient);
    this.modal.close();
  }

  render() {
    const {ingredient} = this.state;

    return (
      <Modal
        deleteOnClick={this.deleteModifierIngredient.bind(this)}
        deleteLabel={ingredient && "Archive"}
        buttonLabel={ingredient ? "Save" : "Add"}
        label={
          ingredient ? "Edit Modifier Ingredient" : "Add Modifier Ingredient"
        }
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            ingredient
              ? this.saveModifierIngredient.bind(this)
              : this.addModifierIngredient.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            ingredientUnitName: ingredient?.INGREDIENT_UNIT_NAME,
            ingredientName: ingredient?.INGREDIENT_NAME,
            ingredient: ingredient?.INGREDIENT_ID,
            quantity: ingredient?.QUANTITY ?? "0",
            enabled: ingredient?.ENABLED ?? 1,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <IngredientDropdown
                  name="ingredient"
                  options={formikOptions}
                  onChangeSoft={({NAME, INGREDIENT_UNIT_NAME}) => {
                    setFieldValue("ingredientUnitName", INGREDIENT_UNIT_NAME);
                    setFieldValue("ingredientName", NAME);
                  }}
                />

                <FormInput
                  options={formikOptions}
                  placeholder="2.5"
                  label="Quantity"
                  name="quantity"
                />

                {ingredient && (
                  <FormBoolean
                    options={formikOptions}
                    label="Enabled"
                    name="enabled"
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ModifierIngredientModal.propTypes = {
  updateState: PropTypes.func,
  addState: PropTypes.func,
};

export default ModifierIngredientModal;
