import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";
import StatusModal from "../../../components/status-modal";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import {showSuccessNotification} from "../../../utils/notification-helper";

class ImportGiftCardsModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async sendImport({type, cards}) {
    this.statusModal.open(
      "Upload Started",
      "You can leave this tab, but in this tab don't navigate from this current page. The page will reload once the menu has been fully uploaded."
    );

    let giftCards = [];
    try {
      if (type === "SQUARE") {
        giftCards = await request("import/square/cards", "POST", {
          GIFT_CARDS: cards,
        });
      } else if (type === "TOAST") {
        giftCards = await request("import/toast/cards", "POST", {
          GIFT_CARDS: cards,
        });
      }
    } catch (e) {
      if (e.error === "GIFT_CARDS_INVALID") {
        this.formikRef.setFieldError("cards", "Gift card format is incorrect");
      }

      return this.modal.stopLoading();
    }

    this.props.updateState(giftCards);
    this.modal.close();

    showSuccessNotification(
      "Gift Cards Imported",
      "Gift cards have been successfully imported"
    );
  }

  renderContent(formikOptions) {
    const {type} = formikOptions.values;

    if (type === "SQUARE") {
      return (
        <div>
          <ImageFormDropZone
            name="cards"
            type="CSV"
            tooltip={{
              label: "Finding Gift Cards CSV",
              data: "If you require help with this step, please contact support at (781) 583-3699",
            }}
            label="Gift Cards CSV"
            options={formikOptions}
          />
        </div>
      );
    }

    if (type === "TOAST") {
      return (
        <div>
          <ImageFormDropZone
            name="cards"
            type="CSV"
            tooltip={{
              data: [
                {
                  label: "Finding Gift Cards CSV",
                  data: "Learn about required csv columns on next page. If you require help with this step, please contact support at (781) 583-3699",
                },
                {
                  label: "Required CSV Columns",
                  data: "Card Number, Current Balance ($)",
                },
                {
                  label: "Optional CSV Columns",
                  data: "Initial Amount ($), First Trans. Date",
                },
              ],
            }}
            label="Gift Cards CSV"
            options={formikOptions}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    return (
      <Modal
        label="Import Gift Cards"
        buttonLabel="Import"
        description="Pull gift cards from your old platform and use it here"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          onSubmit={this.sendImport.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.lazy(({type}) => {
            const defaultSchema = {};

            if (type === "SQUARE") {
              defaultSchema.cards = Yup.array()
                .min(1, "Gift cards has to be set")
                .required("Gift cards has to be set");
            }

            return Yup.object(defaultSchema);
          })}
          initialValues={{
            type: "SQUARE",
            cards: [],
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <StatusModal ref={(e) => (this.statusModal = e)} />

                <FormSelect
                  tooltip={{
                    data: "Choose which platform you are importing your gift cards from.",
                  }}
                  label="Old Platform"
                  name="type"
                  options={formikOptions}
                  data={[
                    {label: "Square", value: "SQUARE"},
                    {label: "Toast", value: "TOAST"},
                  ]}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ImportGiftCardsModal;
