import React, {Component} from "react";
import {FormSelect, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {showErrorAlert} from "../../utils/alert-helper";
import ReportingFilter from "../../features/reporting/reporting-filter/reporting-filter";
import ReportingGraph, {REPORTING_GRAPH_TYPES} from "../../features/reporting/reporting-graph";
import {request} from "../../utils/request";
import ProductTagsDropdown from "../../dropdowns/sales/product-tags-dropdown";

class TicketCompletionReportPage extends Component {
  state = {completionReport: null, type: "average"};

  componentDidMount() {
    const {REPORTING_MODE} = this.props.shop.settings;

    if (REPORTING_MODE === "LEGACY") {
      showErrorAlert("Legacy Not Supported", "The comparison sales report is only available Audit reporting. Switch to Audit in your shop settings or reach out to success to learn more!")
    }
  }

  onGenerate = async () => {
    let {locationIdArray, startEpoch, endEpoch} = this.props.reporting;

    this.setState({showGraph: false, loading: true});

    let completionReport = await request("report/completion", "POST", {
      START_EPOCH: startEpoch,
      END_EPOCH: endEpoch,
      LOCATION_ID_ARRAY: locationIdArray,
    });

    this.setState({completionReport});
  }

  render() {
    const {completionReport, type} = this.state;
    const {REPORTING_MODE} = this.props.shop.settings;

    if (REPORTING_MODE === "LEGACY") {
      return <div><PageHeadings label={"Ticket Completion Report"}/></div>;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Ticket Completion Report"} description={"Understand your efficiency"}/>
        </div>

        <ReportingFilter
          onGenerate={this.onGenerate}
          locationPicker={true}
          hideCSVLink={true}
          // customFields={
          //   <div className={"flex-row flex space-x-3"}>
          //     <FormSelect
          //       label={""}
          //       className={"w-36"}
          //       value={type}
          //       data={[
          //         {
          //           label: "Average",
          //           value: "average",
          //         },
          //         {
          //           label: "Ticket Timer",
          //           value: "timer"
          //         }
          //       ]}
          //       onChange={(newValue) => this.setState({type: newValue})}
          //     />
          //   </div>
          // }
        />

        {completionReport && <div className={"bg-white p-4 rounded-none shadow-sm mb-6"}>
          <ReportingGraph
            height={300}
            dataKey={"AVG_COMPLETION_TIME"}
            datasets={[
              {
                id: "base",
                rawData: completionReport,
                stroke: "#4e46e5",
                strokeWidth: 2,
                fill: "#ccc9f7",
                fillOpacity: 0.7,
                stopNow: true
              }]}
            type={REPORTING_GRAPH_TYPES.AREA.id}
            ignoreWeekdayLabels={true}
            toDollars={false}
            tooltipLabel={" Seconds"}
          />
        </div>}
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop", "reporting"])(
  TicketCompletionReportPage
);
