import React, {Component} from "react";
import Modifier from "../../../features/product/modifier";

class ModifierModal extends Component {
  open(...props) {
    this.modal.open(...props);
  }

  render() {
    return <Modifier modal {...this.props} ref={(e) => (this.modal = e)} />;
  }
}

export default ModifierModal;
