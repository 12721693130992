import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";

class PaymentTypeDetailsModal extends Component {
  state = {type: null};

  open(type = null) {
    this.setState({type}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createTicketType({name, tax}) {
    let paymentTypePayload = {
      NAME: name,
      TAX_DISABLED: tax,
    };

    let serverPaymentType = await request(
      "other/payments",
      "POST",
      paymentTypePayload
    );

    this.props.addState(serverPaymentType);
    this.modal.close();
  }

  async saveTicketType({name, tax}) {
    const {type: oldType} = this.state;

    let serverPaymentType = {
      NAME: name,
      TAX_DISABLED: tax,
    };

    let serverCategory = await request(
      "other/payments/" + oldType.ID,
      "PATCH",
      serverPaymentType
    );

    this.props.updateState({type: {...oldType, ...serverCategory}});
    this.modal.close();
  }

  render() {
    const {type} = this.state;

    return (
      <Modal
        buttonLabel={type ? "Save" : "Add"}
        label={type ? "Edit Payment Type" : "Create Payment Type"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            type
              ? this.saveTicketType.bind(this)
              : this.createTicketType.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: type?.NAME,
            tax: type?.TAX_DISABLED,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />

                <FormBoolean
                  label="Tax Disabled"
                  name="tax"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PaymentTypeDetailsModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default PaymentTypeDetailsModal;
